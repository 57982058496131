import { Col, Row } from 'reactstrap';

const NavbarSearchFooter = ({matters, contacts, onSetView, view}) => (
    <div className="results-section border-top">
        <Row>
            <Col lg={6} className="">
                <h4 className=" mb-0">
                    {view === 'search' ? (
                        <span>Total Results ({matters.length + contacts.length})</span>
                    ) : null}    
                </h4>
            </Col>
            <Col lg={6} className="text-right">
                <h4 
                    onClick={() => onSetView(view === 'search' ? 'history' : 'search')} 
                    className="d-inline-block cursor-pointer mb-0"
                >
                    {view === 'search' ? (
                        <span>View History  <i className="fas fa-caret-down " /></span>
                    ) : (
                        <span><i className="fas fa-arrow-left mr-2" style={{position: 'relative', top: 1}}/> Back To Search</span>
                    )}
                </h4>
            </Col>
        </Row>
    </div>
)

export default NavbarSearchFooter;