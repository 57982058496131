import { Input } from 'reactstrap';

const SendEmail = ({subject, setSubject, setEditingRecipients}) => {

    return (
        <div className='archk-send-email-subject' onClick={() => setEditingRecipients(false)}>
            <b>Subject: </b>
            <Input 
                className=''
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
            />
        </div>
    )
          
}

export default SendEmail