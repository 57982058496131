import { useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'

import api from 'api';

import { getUrlParameter } from "utils/urls";

const MattersAll = ({selected_division, viewing_user}) => {
    const [ now ] = useState(Math.floor(new Date() / 1000));
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};
        
        const type = getUrlParameter('type');
        if(type === 'pending') params.filter.status = 'pending'
        if(type === 'expired') params.filter.status = 'expired'
        // if(type === 'missed') {
        //     params.filter.unix_start = { $lt: now }
        //     params.finished_by = null
        // }


        const query = await api.matter_document_uploads.search({ ...params, isCSV })

        
        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div><Link to={`/matters/${row.matter}`} className="text-dark font-weight-bold"><ObjectFinder collection="matters" _id={row.matter} /></Link></div>
                    </div>
                )
            },
        },
        {
            dataField: "matter_document",
            text: 'Category',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : <ObjectFinder collection="matter_documents" _id={row.matter_document} />
            )
        },
        {
            dataField: "status",
            text: 'Status',
            formatter: (cell, row) => (
                <div className={`${(row.unix_start < now) && !row.finished_by ? 'text-danger' : ''}`}>
                    {row.status === 'pending' ? (
                        <span><i className="fas fa-question-circle mr-2 text-info" /> Pending</span>
                    ) : row.status === 'approved' ? (
                        <span><i className="fas fa-thumbs-up mr-2 text-success" /> Approved</span>
                    ) : row.status === 'missing' ? (
                        <span><i className="fa-solid fa-person-circle-question mr-2 text-warning" /> Missing</span>
                    ) : row.status === 'expired' ? (
                        <span><i className="fas fa-clock mr-2 text-danger" /> Expired</span>
                    ) : ''}
                </div>
            )
        },
        {
            dataField: "status_marked_by",
            text: 'Status Marked By',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                row.status_marked_by ? <ObjectFinder collection="users" _id={row.status_marked_by} /> : ''

            )
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                ...(row.matter || row.contacts.length ? [{ 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: row.matter ? `/matters/${row.matter}?docsTab=Categories&tab=Documents` : `/contacts/${row.contacts[0]}`,
                                }] : []),
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} />

    const title = getUrlParameter('type') === 'pending' ? 'Pending Documents' : 
        getUrlParameter('type') === 'expired' ? 'Expired Documents' : 
        'All Assigned Documents'
    const description = getUrlParameter('type') === 'pending' ? 'Showing Pending Documents Assigned To Me' : 
        getUrlParameter('type') === 'expired' ? 'Showing Expired Documents Assigned To Me' : 
        'Showing All Documents Assigned To Me For This Division'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            actionComponent={(
                <div>
                    {/* <Link to="/matters/create" className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</Link> */}
                </div>
            )}
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Status..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
