import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import SearchCollections from 'components/system/Search/Collections';

import api from 'api'
import { setSelectedMatter } from 'store/functions/matters'

import { toast } from 'react-toastify'

const ModalAddParty = ({showModal, toggleModal, party, currentContact, matter}) => {

    const [val, setVal] = useState(currentContact ? currentContact._id : null)

    const onAdd = useCallback(async () => {
        if(!val) return;
        const data = await api.matters.partyAdd({ matter: matter._id, workflow_contact: party._id, contact: val })
        if(!data.data) return toast.error(`Something's not right, please try again.`)

        setSelectedMatter(matter._id)
        toggleModal()
    }, [party._id, val, matter._id, toggleModal])

    const onRemove = useCallback(async () => {
        if(!val) return;
        const data = await api.matters.partyRemove({ matter: matter._id, workflow_contact: party._id, contact: currentContact._id })
        if(!data.data) return toast.error(`Something's not right, please try again.`)

        setSelectedMatter(matter._id)
        toggleModal()
    }, [party._id, val, matter._id, toggleModal, currentContact])
    
    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="sm"
        >

           <div className="modal-header">
               <h5 className="modal-title">Assign Party: {party.name}</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body bg-secondary border-bottom">
                <p className='text-sm mb-0'>
                    {currentContact ? `This party is currently assigned to ${currentContact.display_name}. To change this select a new contact below.` : `This party is currently not assigned to anyone. Select a contact below to change that.`}
                </p>
           </div
           >
           <div className="modal-body">
                <SearchCollections
                    collection="contacts" 
                    value={val}
                    onChange={(obj) => setVal(obj.value)}
                    filter={{
                        // workflow: workflowCopy._id
                    }}
                /> 
           </div>

           <div className="modal-footer">
                {currentContact ? (
                    <button className="btn btn-sm btn-outline-warning" onClick={onRemove}>
                        Remove Assignment
                    </button>
                ) : ''}
                <button className="btn btn-sm btn-success" onClick={onAdd}>
                    Assign Contact
                </button>
           </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalAddParty);