import { connect } from 'react-redux';

import { Row, Col, Nav, NavItem, NavLink, Input } from 'reactstrap';

import { useCallback, useEffect, useState } from 'react'
import api from 'api';

const AI = ({ selected_matter, selected_division, setShowAI, setSelectedThread }) => {

  

    return (
        <div className="archk-ai-main-header ">
            <Row>
                <Col md={4} className='align-self-center'>
                    <h2 className="mb-0 text-uppercase">Adaptive Intelligence</h2>
                </Col>
                <Col md={4} className='align-self-center text-center'>
                    <h2 className="mb-0">{selected_matter.name}</h2>
                </Col>
                <Col md={4} className='align-self-center text-right'>
                    <button
                        onClick={() => setShowAI(false)}
                        className='btn btn-outline-warning btn-sm'
                        style={{}}
                    >
                        <i className="fas fa-arrow-left mr-2" />Close 
                    </button>

                    <button 
                        style={{}}
                        className='btn btn-success btn-sm'
                        onClick={() => setSelectedThread('')}
                    >
                        Add Thread <i className="fas fa-plus ml-2" />
                    </button>
                </Col>
            </Row>
        </div>
    )
}


const mapStateToProps = state => {
	return {
        selected_matter: state.matters.selected_matter,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AI);