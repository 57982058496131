import A from 'components/markup/links/A';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Alert, Container } from "reactstrap";

import api from 'api';
import formatText from 'utils/formatText';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import InternalSidebarAdmin from 'components/markup/layout/InternalSidebarAdmin';
import Circle from 'components/markup/loading/Circle';

import Charge from './Charge';
import Header from './Header';
import Invoices from './Invoices';
import Methods from './Methods';
import PaymentEvents from './PaymentEvents';
import Payments from './Payments';
import PaymentSubscriptions from './PaymentSubscriptions';
import Schedule from './Schedule';

const ContactsAll = ({match}) => {

    const [ tab, setTab ] = useState('Payments');
    const [ data, setData ] = useState(null);
    const [ error, setError ] = useState(false)

    const fetchData = useCallback(async () => {
        setData(null)
        const _data = await api.matters.billing(match.params.matter_id)
        if(!_data.success) return setError(_data.message)
        setData(_data.data)
    }, [match.params.matter_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(error) {
        return (
            <Container fluid >
                <Alert color="warning"><i className="fas fa-info-circle mr-2" /> The contact you are searching for could not be found..</Alert>
            </Container>
        )
    }
   
    if(!data) return <Circle className="py-5" />


    const description = data.contacts.map((contact, i) => {
        const name = formatText().contactIdentifier(contact);
        const spacer = i === 0 ? '' : ', '
        return spacer + name;
    })

    return (

        <>

        <Helmet>
            <title>{data.matter.name}</title>
            <meta name="description" content={data.matter.name} />
        </Helmet>


        <HeaderNavigation 
            title={<A href={`/matters/${data.matter.id}`}>{data.matter.name}</A>}
            description={description}
            actionComponent={(
               <div>
                    <Link to={match.params.contact_id === '-' ? '/billing' : `/billing/${match.params.contact_id}`} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> {match.params.contact_id === '-' ? 'Search Contacts' : 'All Matters'}
                    </Link>
               </div>
            )}
        />

        <Header data={data} />

        <Container fluid>
    
          

            <InternalSidebarAdmin 
                tab={tab}
                setTab={setTab}
                width={165}
                className="ml--4 mt--2"
                tabs={[
                    { value: 'Payments', name: 'Payments' },
                    { value: 'Invoices', name: 'Invoices' },
                    { value: 'Cards & ACH', name: 'Cards & ACH' },
                    { value: 'Recurring Charges', name: 'Recurring Charges' },
                    { value: 'Charge Payment', name: 'Charge Payment' },
                    { value: 'Trust Payments', name: 'Trust Payments' },
                    { value: 'Schedule', name: 'Schedule' },
                    { value: 'Events', name: 'Events' },
                ]}
            >  
                <div className='py-3 pl-3'>
                    {tab === 'Payments' ? (
                        <Payments 
                            fetchData={fetchData} 
                            matter={data.matter} 
                            payments={data.payments} 
                            setTab={setTab} 
                            payment_methods={data.payment_methods}
                        />
                    ) : tab === 'Invoices' ? (
                        <Invoices 
                            fetchData={fetchData} 
                            invoices={data.invoices} 
                            matter={data.matter} 
                            setTab={setTab} 
                        />
                    ) : tab === 'Cards & ACH' ? (
                        <Methods 
                            matter={data.matter} 
                            fetchData={fetchData} 
                            payment_methods={data.payment_methods} 
                            setTab={setTab} 
                        />
                    ) : tab === 'Events' ? (
                        <PaymentEvents 
                            matter={data.matter} 
                            payment_events={data.payment_events} 
                        />
                    ) : tab === 'Charge Payment' ? (
                        <Charge 
                            matter={data.matter} 
                            payment_methods={data.payment_methods} 
                            payment_subscription={data.payment_subscription} 
                            fetchData={fetchData}
                            setTab={setTab}
                        />
                    ) : tab === 'Recurring Charges' ? (
                        <PaymentSubscriptions 
                            matter={data.matter} 
                            payment_methods={data.payment_methods} 
                            payment_subscription={data.payment_subscription} 
                            fetchData={fetchData}
                        />
                    ) : tab === 'Schedule' ? (
                        <Schedule 
                            matter={data.matter} 
                            payment_methods={data.payment_methods} 
                            payment_subscription={data.payment_subscription} 
                            schedule={data.schedule}
                        />
                    ) : ''}
                </div>
            </InternalSidebarAdmin>


        </Container>

        </>
    )

}

export default ContactsAll