import Circle from 'components/markup/loading/Circle';

import image from './image.svg';

import MessageList from './MessageList';

const TextsMain = ({thread, active}) => {

    if(!thread && !active) return (
        <div className="archk-messaging-main archk-messaging-main-empty">
            <div className="archk-messaging-main-wrapper">
                <div className="archk-messaging-main-wrapper-inner text-center">
                    <img id="test123" src={image} alt="icon" style={{width: 200, }} className="pt-9"  />
                </div>
            </div>
        </div>
    )
    

    return (

        <div className="archk-messaging-main ">

            <div className="archk-messaging-main-wrapper">
                {!thread ? <Circle className="my-5" /> : (
                    <div className="archk-messaging-main-wrapper-inner">
                        {!thread.texts.length ? (
                            <p className='text-center py-3'><i className="fas fa-info-circle text-info mr-2" /> No Texts Found</p>
                        ) : (
                            <div className='pb-4'>
                                <MessageList thread={thread} />
                            </div>
                        )}
                    </div>
                )}
            </div>

        </div>
    )

}

export default TextsMain