import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { Card, Container, Modal, Alert, Badge } from "reactstrap";

import Table, { downloadCSV } from 'components/functional/tables/Standard';
import NameCircle from 'components/markup/misc/NameCircle';

import ModalToggler from 'components/functional/modals/Toggler'

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import { toggleStandardLoader } from 'store/functions/system/system'
import { toast } from 'react-toastify'


import formatText from 'utils/formatText';
import api from 'api';

import ContactsEdit from 'views/dashboard/pages/contacts/components/Edit'
import CallContactBadge from 'components/system/CallContact/Badge'
import TableIcons from 'components/markup/icons/TableIcons';

const ContactsAll = ({match, device}) => {

    const type = match.params.type;

    const [ redirect, setRedirect ] = useState();
    const [ showModal, setShowModal ] = useState();
    const [ selectedRows, setSelectedRows ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)

    const onSaved = useCallback((contact) => {
        setRedirect(`/clients/${contact._id}`)
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(type === 'new') params.filter.created_at =  { $gte: Math.floor(new Date() / 1000) - ( 86400 * 3 ) }

        const query = await api.contacts.search({ ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // similar to query however it does not pass in a limit or skip
    // and once the data is fetched it will trigger the browser to download
    // the newly created file
    const onDownloadCSV = async (params) => {
        const data = await fetchData(params, true);
        downloadCSV(data, { name: 'contacts' });
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onDelete = () => {

        if(selectedRows && selectedRows.length) {

            toggleStandardLoader(true);

            let counter = 0;
            let passed  = 0;

            // for each contact run the endpoint to delete them
            selectedRows.forEach(async contact => {

                const deleted = await api.contacts.delete(contact._id);
                
                // see how many passed deletion
                if(deleted.success) passed++;
                counter++;

                // when all deletion events ran
                if(counter === selectedRows.length) {

                    // matched passed deletion to total contacts, if they match we have total success
                    if(passed === selectedRows.length) {
                        toast.success('Contacts Deleted Successfully');
                    } else {
                        toast.error('Some or all of the specified contacts were not deleted.')
                    }

                    // kill the modal, stop the load, fire the onFinish prop function
                    setShowModal(false)
                    toggleStandardLoader(false);
                    query();

                }

            })

        }

    }

    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <Link className="text-capitalize" to={`/clients/${row._id}`}>
                        <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                        {row.display_name}
                    </Link>
                )
                
            },
        },
        {
            dataField: "email",
            text: 'Contact',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-contacts">Contacts</div>
            },
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                <div>
                    {row.email && <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>}
                    <div>
                        {/* {row.phone && <div key={row.created_at} > <i className="text-muted fas fa-mobile mr-2" /> {formatText(row.phone).phone()} </div>} */}
                        {row.phone && (
                            <div>
                                <CallContactBadge contact={row._id} phone={row.phone}>
                                    <span className="cursor-pointer">{formatText(row.phone).phone()}</span>
                                </CallContactBadge>
                            </div>
                        )}
                    </div>
                </div>
            )
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                      <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-trash',
                                    color: 'danger', 
                                    onClick: () => {
                                        setSelectedRows([row])
                                        setShowModal(true)
                                    }
                                },
                                
                              
                                { 
                                    icon: 'fas fa-link',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/contacts/${row.id}`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    if(redirect) return <Redirect to={redirect} />

    const title = type === 'new' ? 'New Contacts' : 'All Contacts'
    const description =  type === 'new' ? 'Contacts Created in The Last 72 Hours' : 'Showing All System Contacts'


    return (

        <>

        <HeaderNavigation 
            title={title}
            description={description}
            leftColSize={6}
            actionComponent={(
               <div>
                    <Link to="/matters" className="btn btn-info"><i className="fas fa-search mr-2 " /> Search Matters</Link>
                    <ModalToggler component={ContactsEdit} onSaved={onSaved} showModal={window.location.href.includes('create') ? true : false} modalTitle="Update Client">
                        <button className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</button>
                    </ModalToggler>
               </div>
            )}
        />

        <Container fluid>
    
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={title} />
            </Helmet>

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Contacts..."
                    downloadCSV={onDownloadCSV}
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    onSelect={setSelectedRows}
                    onDeletePressed={() => setShowModal(true)}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                size="lg"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Delete Contacts</h5>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setShowModal(false)} >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body border-bottom">
                    <p className="text-sm mb-0">
                        <i className="fas fa-exclamation-triangle mr-2 text-danger " />
                        You are about to delete the following contacts, are you sure you wish to proceed?
                    </p>
                </div>

                <div className="table-responsive px-3 bg-secondary">
                    <table className="table mb-0">

                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th className="text-right">Phone</th>
                            </tr>
                        </thead>

                        <tbody>
                            {selectedRows.length && selectedRows[0] !== false ? selectedRows.map(contact => (
                                <tr key={contact._id}>
                                    <td>{contact.display_name}</td>
                                    <td>{contact.email ? contact.email : '-'}</td>
                                    <td className="text-right">{contact.phone ? formatText(contact.phone).phone() : '-'}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>You have not selected any contacts</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

                <div className="modal-footer text-right">
                    <button onClick={() => setShowModal(false)} className="btn btn-neutral">
                        <i className="fas fa-arrow-left mr-2 " /> Cancel
                    </button>
                    <button disabled={selectedRows.length ? false : true} onClick={onDelete} className="btn btn-danger">
                        <i className="fas fa-trash mr-2 " /> Delete Contacts
                    </button>
                </div>

            </Modal>
    
        </Container>

        </>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 120
    },
    badgeMobile: {
        display: 'inline-block',
        width: 30,
        marginRight: 10
    }
}

const mapStateToProps = state => {
	return {
	    device: state.device,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
