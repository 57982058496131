import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, FormGroup } from "reactstrap";

import api from 'api'

import ApiError from 'components/markup/layout/ApiError'
import Circle from 'components/markup/loading/Circle'
import A from 'components/markup/links/A'
import ObjectFinder from 'components/system/Objects/Finder';
import ModalToggler from 'components/functional/modals/Toggler'

import formatText from 'utils/formatText'

import { toast } from 'react-toastify'
import { dialNumber, enableDialing } from 'store/functions/call_center'

import PhoneInput from 'react-phone-input-2'
            
import ContactsEdit from 'views/dashboard/pages/contacts/components/Edit'



const ModalCallNumber = ({showModal, toggleModal, matter, contact, divisions, call_center, viewing_user, phone}) => {
    const deviceError = call_center.browser_dialing === 'error';

    const [err, setErr] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isLookingUp, setIsLookingUp] = useState(!matter && !contact)
    const [notFound, setNotFound] = useState(false)
    const [numberToLookup, setNumberToLookup] = useState(false)
    const [data, setData] = useState(null)
    const [selectedDivision, setSelectedDivision] = useState(divisions[0]._id);
    const [selectedMatter, setSelectedMatter] = useState(matter);
    const [selectedContact, setSelectedContact] = useState(contact);
    const [number, setNumber] = useState(null);
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
  
    const _contact = data ? data.contacts.find(c => c._id === selectedContact) : '';

    const filterDivisions = divisions.filter(d => viewing_user.divisions.includes(d._id))

    const onSearchNumber = useCallback(async () => {
        setNotFound(false)
        const found = await api.contacts.findByPhone(numberToLookup)
        if(found.data) {
            setIsLookingUp(false)
    
            const _data = await api.call_center.contactInfo(found.data._id)
            if(!_data.success) setErr(_data.message)
    
            setData(_data.data)
    
            const { contacts } = _data.data;
            if(contacts.length === 1) {
                setNumber(contacts[0].phone)
                setSelectedContact(contacts[0]._id)
                setName(contacts[0].display_name)
                setAddress(contacts[0].city && contacts[0].state ? contacts[0].city + ', ' + contacts[0].state : '')
            }
        } else {
            setNotFound(true)
        }
    }, [numberToLookup])

    const numberMismatch = useCallback(() => {
        if(phone && _contact) {
            if(phone !== _contact.phone && phone !== _contact.phone_2 && phone !== _contact.phone_3) return true
        }
        return false;
    }, [phone, _contact])

    const onCall = useCallback(async () => {
        if(!selectedContact) return toast.info(`A contact must be selected before making a call.`)
        if(!number) return toast.info(`A number must be selected before making a call.`)
        if(data.matters.length && !selectedMatter) return toast.info(`A matter must be selected before making a call.`)
        if(!data.matters.length && !selectedDivision) return toast.info(`A division must be selected before placing a call to a contact that does not have any assigned matters.`)

        if(!selectedDivision) toast.error('Internal error, please contact support')

        await enableDialing();

        dialNumber({
            name,
            address,
            number,
            contact: selectedContact,
            division: selectedDivision,
            matter: selectedMatter ? selectedMatter : '',
        })
        toggleModal()
    }, [selectedContact, selectedMatter, number, name, address, data, selectedDivision, toggleModal])

    const fetchData = useCallback(async () => {
        setData(null)
        if(!matter && !contact) return;
        const func = matter ? 'matterInfo' : 'contactInfo'
        const id = matter ? matter : contact;

        const _data = await api.call_center[func](id)
        if(!_data.success) setErr(_data.message)

        setData(_data.data)

        const { matters, contacts } = _data.data;
        if(matters.length === 1) {
            setSelectedMatter(matters[0]._id)
            setSelectedDivision(matters[0].division)
        }
        if(contacts.length === 1) {
            setNumber(contacts[0].phone)
            setSelectedContact(contacts[0]._id)
            setName(contacts[0].display_name)
            setAddress(contacts[0].city && contacts[0].state ? contacts[0].city + ', ' + contacts[0].state : '')
        }
    }, [matter, contact])

    
    useEffect(() => {
        if(showModal) {
            fetchData()
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [showModal, fetchData])

    useEffect(() => {
        if(showModal) {
            setErr(null)
            setIsLookingUp(!matter && !contact)
            setNotFound(false)
            setNumberToLookup(false)
            setData(null)
            setSelectedDivision(divisions[0]._id);
            setSelectedMatter(matter);
            setSelectedContact(contact);
            setNumber(null);
            setName(null);
            setAddress(null);
        }
       
    }, [contact, divisions, matter, showModal])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

           <div className="modal-header">
               <h5 className="modal-title">{deviceError ? 'Device Error' : 'Call A Number'}</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body">

                {numberMismatch() ? (
                    <div className='alert alert-warning mb-2 z-depth-1 rounded'>
                        The number you have selected to call "{formatText(phone).phone()}"{' '}
                        was not found on the corresponding contact record.{' '}
                        Either <span className='text-underline'><A className="text-white " href={`/contacts/${_contact._id}`}>update the contact record</A></span> or select from one of the numbers below to call.</div>
                ) : ''}

                {err ? (
                    <ApiError err={err} />
                ) : loading ? (
                    <div />
                ) : isLookingUp ? (
                    <div>
                        <Form onSubmit={e => e.preventDefault()}>
                            <label className='form-control-label'>Select A Number To Lookup</label>
                            <FormGroup>
                                <PhoneInput
                                    country={'us'}
                                    specialLabel=""
                                    value={numberToLookup}
                                    onChange={phone => setNumberToLookup(phone)}
                                />    
                            </FormGroup>

                            {notFound ? (
                                <p className='text-sm mb-0 text-warning'>
                                    No Contact Could Be Found With This Number{' '}
                                    <span className='text-underline cursor-pointer'>
                                    <ModalToggler component={ContactsEdit} onSaved={onSearchNumber} modalTitle="Update Client" startingTab="Communication" startingPhone={numberToLookup}>
                                        Click Here If You Would Like To Create A New Contact
                                    </ModalToggler>
                                    </span>
                                    
                                </p>
                            ) : ''}
                        </Form>
                    </div>
                )  : !data ? (
                    <Circle />
                ) : deviceError ? (
                    <p className='text-sm font-weight-bold mb-0'>
                        <i className="fas fa-exclamation-triangle mr-2 text-danger" />
                        Please Refresh Your Page To Continue Making Calls.
                    </p>
                ) : (
                    <div>
                        {data.matters.length ? (
                            <Form onSubmit={e => e.preventDefault()}>
                                <p className='text-sm mb-0 font-weight-bold text-uppercase'>Select The Matter You Are Calling In Reference Too:</p>
                                {data.matters.map(m => (
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-custom-phone-matter-select-${m._id}`}
                                            name="archk-custom-phone-matter-select"
                                            type="radio"
                                            checked={selectedMatter === m._id}
                                            onClick={e => {
                                                setSelectedMatter(m._id)
                                                setSelectedDivision(m.division)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-custom-phone-matter-select-${m._id}`}>
                                            {m.name} - <ObjectFinder collection="divisions" _id={m.division} /> - {m.current_step_name}
                                        </label>
                                    </div>
                                ))}
                            </Form>
                        ) : (
                            <Form onSubmit={e => e.preventDefault()}>
                                <p className='text-sm mb-0 font-weight-bold text-uppercase'>Select The Division You Are Calling In Reference Too:</p>
                                {filterDivisions.map(m => (
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-custom-phone-division-select-${m._id}`}
                                            name="archk-custom-phone-division-select"
                                            type="radio"
                                            checked={selectedDivision === m._id}
                                            onClick={e => setSelectedDivision(m._id)}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-custom-phone-division-select-${m._id}`}>
                                            {m.name}
                                        </label>
                                    </div>
                                ))}
                            </Form>
                        )}

                        {data.contacts.length ? (
                            <Form onSubmit={e => e.preventDefault()}>
                                <p className='text-sm mb-0 font-weight-bold text-uppercase'>Select The Contact To Call:</p>
                                {data.contacts.map(c => (
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-custom-phone-contact-select-${c._id}`}
                                            name="archk-custom-phone-contact-select"
                                            type="radio"
                                            checked={selectedContact === c._id}
                                            onClick={e => {
                                                setSelectedContact(c._id)
                                                setNumber(c.phone)
                                                setName(c.display_name)
                                                setAddress(c.city && c.state ? c.city + ', ' + c.state : '')
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-custom-phone-contact-select-${c._id}`}>
                                            {!c.phone && !c.phone_2 && !c.phone_3 ? (
                                                <i className="fa-solid fa-phone-slash text-warning mr-2" />
                                            ) : (
                                                <i className="fa-solid fa-phone text-success mr-2" />
                                            )}
                                            {c.display_name ? c.display_name : c.phone ? c.phone : c.email}
                                           
                                        </label>
                                    </div>
                                ))}
                            </Form>
                        ) : ''}

                        {_contact && (_contact.phone || _contact.phone_2 || _contact.phone_3) ? (
                            <Form onSubmit={e => e.preventDefault()}>
                                <p className='text-sm mb-0 font-weight-bold text-uppercase'>Select The Number To Call:</p>

                                {_contact.phone ? (
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-custom-phone-number-select-phone`}
                                            name="archk-custom-phone-number-select"
                                            type="radio"
                                            checked={number === _contact.phone}
                                            onClick={e => setNumber(_contact.phone)}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-custom-phone-number-select-phone`}>
                                            {formatText(_contact.phone).phone()} - Main Number
                                        </label>
                                    </div>
                                ) : ''}
                                {_contact.phone_2 ? (
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-custom-phone-number-select-phone-2`}
                                            name="archk-custom-phone-number-select"
                                            type="radio"
                                            checked={number === _contact.phone_2}
                                            onClick={e => setNumber(_contact.phone_2)}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-custom-phone-number-select-phone-2`}>
                                            {formatText(_contact.phone_2).phone()}
                                        </label>
                                    </div>
                                ) : ''}
                                {_contact.phone_3 ? (
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            id={`archk-custom-phone-number-select-phone-3`}
                                            name="archk-custom-phone-number-select"
                                            type="radio"
                                            checked={number === _contact.phone_3}
                                            onClick={e => setNumber(_contact.phone_3)}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-custom-phone-number-select-phone-3`}>
                                            {formatText(_contact.phone_3).phone()} 
                                        </label>
                                    </div>
                                ) : ''}
                            </Form>
                        ) : _contact ? (
                            <p className='text-sm text-warning mb-0 border rounded p-3 text-center bg-secondary'>
                                <i className="fas fa-info-circle" /> This contact does not have a valid phone number on file.
                            </p>
                        ) : ''}

                    </div>
                )}
           </div>

           {/* <div className='modal-body border-top bg-secondary'>
                <label className="custom-toggle custom-toggle-primary">
                    <input type="checkbox" checked="" />
                    <span className="custom-toggle-slider rounded-circle" data-label-off="OFF" data-label-on="ONnnnnnnn"></span>
                </label>
           </div> */}

           <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>

                {deviceError ? (
                    <a className="btn btn-success" href={window.location.href}>
                        <i className="fa-solid fa-arrows-rotate mr-2" /> Refresh Page
                    </a>
                ) : isLookingUp ? (
                    <button className='btn btn-info' onClick={onSearchNumber}>
                        Lookup Number
                    </button>
                ) : (
                    <button className="btn btn-success" onClick={onCall}>
                        <i className="fa-solid fa-phone mr-2" /> Call Number
                    </button>
                )}
               
           </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        divisions: state.divisions,
        call_center: state.call_center,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalCallNumber);