import React, { useState, useEffect, useCallback } from 'react';
import { CardHeader, CardBody, CardFooter, CardTitle, FormGroup, Input } from 'reactstrap';

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import api from 'api';

const SettingsInfoNotifications = ({company, setCompany}) => {

    const [apiErr, setApiErr] = useState(0);
    const [state, setState] = useState({
        email_2                      : '',
        email_3                      : '',
        email_reply_to               : ''
    })

    const onUpdate = async () => {
        toggleStandardLoader(true)

        const updated = await api.companies.update(company._id, state)
        if(updated.success) {
            toast.success(false, 'Company settings updated successfully.')
            setCompany()
        } else {
            setApiErr(updated.message)
        }

        toggleStandardLoader(false)
    }

    const onInputChange = useCallback((field, value) => {
        const _state = JSON.parse(JSON.stringify(state));
        _state[field] = value
        setState(_state)
    }, [state])

    useEffect(() => {
        const _company = JSON.parse(JSON.stringify(company));

        setState({
            email_2           : _company.email_2 || '',
            email_3           : _company.email_3 || '',
            email_reply_to    : _company.email_reply_to || '',
        })
    }, [company])

    return (
        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Notification Email Addresses</CardTitle>
            </CardHeader>

            <CardHeader>
                <p className="text-sm mb-0">Your reply to email address is where all email replies get sent from contacts sending messages to your IntuiDesk account.</p>
            </CardHeader>

            <CardBody>

                <FormGroup>
                    <label className="text-sm mb-0">Reply To Email Address</label>
                    <Input 
                        type="text"
                        value={state.email_reply_to}
                        onChange={(e) => onInputChange('email_reply_to', e.target.value)}
                    />
                </FormGroup>
                
            </CardBody>
            

            <CardHeader>
                <p className="text-sm">By default all IntuiDesk notifications will be sent to the email address on file: <b> {company.email}</b></p>
                <p className="text-sm mb-0">You may optionally add 2 more email addresses below to receive system notifications, alerts, etc.</p>
            </CardHeader>

            <CardBody>

                <FormGroup>
                    <label className="text-sm mb-0">Notification Email Address 2</label>
                    <Input 
                        type="text"
                        value={state.email_2}
                        onChange={(e) => onInputChange('email_2', e.target.value)}
                    />
                </FormGroup>
                
                <FormGroup>
                    <label className="text-sm mb-0">Notification Email Address 3</label>
                    <Input 
                        type="text"
                        value={state.email_3}
                        onChange={(e) => onInputChange('email_3', e.target.value)}
                    />
                </FormGroup>

            </CardBody>
            
            {apiErr ? (
                <CardFooter>
                    {apiErr.map((e, i) => <p key={i} className="text-sm text-danger font-weight-bold mb-0">{e}</p>)}
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onUpdate} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        
        </div>

    )
}

export default SettingsInfoNotifications;