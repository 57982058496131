import { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from 'reactstrap';

import api from 'api';

import { toast } from 'react-toastify';



const MatterViewMainDocuments = ({matter}) => {

    const [identifier, setIdentifier] = useState(matter.identifier || '')
    const [name, setName] = useState(matter.name)

    const onSave = useCallback(async () => {
        const result = await api.matters.update(matter._id, { identifier, name })
        if(!result.success) return toast.error(`Something went wrong saving your changes, please try again.`)
        toast.success(`Matter Identifiers Updated Successfully`)
    }, [matter._id, name, identifier])
  
    return (
        <div className="section pt-0" >
            <Card>

                <CardHeader>
                    <CardTitle className='mb-0'>Matter Name</CardTitle>
                </CardHeader>

                <CardBody>
                    <FormGroup>
                        <label className='form-control-label'>This will update the search value as well as the display name of the matter</label>

                        <Input 
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormGroup>
                </CardBody>
               
                <CardHeader>
                    <CardTitle className='mb-0'>Matter Identifier</CardTitle>
                </CardHeader>

                <CardBody>
                    <FormGroup>
                        <label className='form-control-label'>A matter identifier is used across the system to reference this matter by an ID outside of IntuiDesk</label>

                        <Input 
                            value={identifier}
                            onChange={e => setIdentifier(e.target.value)}
                        />
                    </FormGroup>
                </CardBody>

                <CardFooter className='text-right'>
                    <button onClick={onSave} className='btn btn-success'>Save</button>
                </CardFooter>

            </Card>
        </div>

    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocuments);