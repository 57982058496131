import keys from 'keys';
import Axios from 'axios';
import fileDownloader from 'js-file-download';

import { toast } from 'react-toastify';

const downloadBkDocument = async (doc) => new Promise (async resolve => {
    try {

        const result = await Axios({
            method: 'get',
            url: keys.API_URL + `/v1/modules/bk/bk_documents/${doc.bk_document}/download`,
            responseType:'arraybuffer',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
        })

        if(result.headers && result.headers['content-type'].includes('application/json')) {
            resolve(false);
            try {
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                var body = JSON.parse(decodedString);
                if(body.message && body.message.length) {
                    return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                }
            } catch(e) {
                return toast.error(`Something went wrong downloading this document`)
            }
        }

        fileDownloader(result.data, doc.name) 
        resolve(true);
    } catch(e) {
        resolve(false);
        return toast.error(`Something went wrong downloading this court document`)
    }
})
 
export default downloadBkDocument