import { useCallback, useState } from 'react';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';

const baseState = {
    description   : '',
    amount        : '',
}

const ModalCustomFields = ({invoice, onAddArrayItem, onRemoveArrayItem}) => {

    const [discount, setDiscount] = useState(baseState);

    const onAddDiscount = useCallback(() => {
        if(discount.amount  === '' || discount.amount < 0) return toast.info('Discount amount must be greater or equal to 0.')
        if(!discount.description) return toast.info('Discount description cannot be left blank.')

        onAddArrayItem('discounts', { 
            ...discount,
            amount: parseFloat(discount.amount),
        })
        setDiscount(baseState)
    }, [discount, onAddArrayItem])

    const onSetDiscount = useCallback((field, val) => {
        const _discount = JSON.parse(JSON.stringify(discount))
        _discount[field] = val
        setDiscount(_discount)
    }, [discount])

    const disabled = discount.amount === '' || !discount.description;

    return (

        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Add Discount</CardTitle>
                </CardHeader>
                
                <CardBody>


                    <FormGroup>
                        <label className='form-control-label'>Item Amount*</label>
                        <Input 
                            type="number"
                            value={discount.amount}
                            onChange={(e) => onSetDiscount('amount', e.target.value)}
                        />
                    </FormGroup>

                  
                    <FormGroup>
                        <label className='form-control-label'>Item Description*</label>
                        <Input 
                            type="text"
                            value={discount.description}
                            onChange={(e) => onSetDiscount('description', e.target.value)}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <button 
                        className={`btn btn-${disabled ? 'outline-info' : 'success'} btn-sm`}
                        onClick={onAddDiscount}
                        disabled={disabled}
                    >
                        <i className="fas fa-plus mr-2" /> Add Discount
                    </button>
                    {disabled ? <span>* Above Fields Required</span> : ''}
                </CardFooter>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Current Discounts</CardTitle>
                </CardHeader>
                
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        {invoice.discounts.length ? invoice.discounts.map((obj, i) => (
                            <tr key={i}>
                                <td>{obj.description}</td>
                                <td>{formatCurrency(obj.amount)}</td>
                                <td>
                                    <i 
                                        className="fas fa-trash text-danger cursor-pointer"  
                                        onClick={() => onRemoveArrayItem('discounts', i)}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>*No Discounts Have Been Added</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Card>

          
        </div>

    )
}

export default ModalCustomFields