
import { useCallback, useEffect, useRef, useState, Component } from 'react';

import { Col, Row } from "reactstrap";

import AvatarImage from "components/functional/images/AvatarImage";
import NameCircle from 'components/markup/misc/NameCircle';

import Attachments from './Attachments';

import reactHTMLParser from 'html-react-parser';

import api from 'api';

import { toast } from 'react-toastify';

import Dots from 'components/markup/loading/Dots';

import useOnScreen from 'utils/hooks/useOnScreen';

class EmailBody extends Component {

    state = {
        body: this.props.body
    }

    componentDidCatch = (e) => {
       this.setState({body: "<span class='text-danger'>COULD NOT DISPLAY EMAIL</span>"})
    }

    render() {
        return <div dangerouslySetInnerHTML={{__html: this.state.body}} />
    }
}

const TextsMessageList = ({totalEmails, currentYear, renderDay, day, year, inbound, renderTimeAndIdentifier, time, email, isLast, setAllAttachments, i}) => {

    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    
    const [attachments, setAttachments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [body, setBody] = useState(email.body.replace(/<head>[\s\S]*?<\/head>/, '<head></head>'));
    const [hasMore] = useState(body.match(/<blockquote[\s\S]*?<\/blockquote>/) ? true : false)
    const [showFull, setShowFull] = useState(false)

    const addresses = email.to.concat(email.cc)

    const loadAttachments = useCallback(async () => {
        setLoading(true)
        const data = await api.emails.getAttachments(email._id)
        setLoading(false)
        if(!data.data) return toast.info(`Attachments could not be loaded`);
        let _body = JSON.parse(JSON.stringify(body))

        let err = false;

        data.data.forEach((a, i) => {

            let type = a.content_type
            if(type && type.includes('image/png')) {
                type = 'image/png'
                data.data[i].content_type = 'image/png'
            }
            if(type && type.includes('image/jpeg')) {
                type = 'image/jpeg'
                data.data[i].content_type = 'image/jpeg'
            }
            if(type && type.includes('application/pdf')) {
                type = 'application/pdf'
                data.data[i].content_type = 'application/pdf'
            }

            if(!a.data) err = true
            if(a.data && a.is_inline) {
                const replace = `cid:${a.content_id}`;
                const re = new RegExp(replace,"g");

                _body = _body.replace(re, `data:${a.type};base64, ${a.data}`)
            }
        })

        if(err) {
            console.log('One more attachments could not be downloaded, to view them you will need to open the original email inside of your email provider client.')
        } else {
            setAttachments(data.data)
            setAllAttachments(data.data)
        }
        setBody(_body.replace(/<head>[\s\S]*?<\/head>/, '<head></head>'))

    }, [email._id, body, setAllAttachments])

    
    useEffect(() => {
        if(isVisible && email.attachments.length && !attachments && !loading) {
            loadAttachments()
        }
    }, [attachments, isVisible, loadAttachments, loading, email.attachments.length])

    useEffect(() => {
        const num = i + 1;
        if(num >= totalEmails - 2) {
            if(email.attachments.length && !attachments && !loading) {
                loadAttachments()
            }
        }
    }, [attachments, email, email.attachments.length, i, totalEmails, loadAttachments, loading])

    return (
        <div id={`archk-email-${email._id}`} ref={ref} className="archk-email-rendering" key={email._id}>

            {i === 0 ? (
                <div className='text-muted text-center border-bottom py-3 bg-white '>Start Of Message History</div>
            ) : ''}

            {renderDay ? (
                <div className='text-center py-3 text-uppercase'>
                    <b className='text-dark '>
                        {day}{year !== currentYear ? ', ' + year : ''}
                    </b>
                </div>
            ) : ''}

            <Row className='ml-0 mr-0'>
                {inbound ? (
                    <div className="col-auto  text-center pt-7 mt--3" style={{width: 40}}>
                        {renderTimeAndIdentifier ? (
                            <div className='pt-1 pl-3'>
                                <NameCircle width={38} initials={"JM"} style={{marginRight: 5}} />
                            </div>
                        ) : ''}
                    </div>
                ) : ''}
                
                <Col className='px-0'>
                    <div 
                        className={
                            `archk-text-message pb-1 archk-text-message-${inbound ? 'incoming' : 'outgoing'} ` +
                            `${!renderTimeAndIdentifier ? 'pt-0' : ''}`
                        }
                    >

                    {renderTimeAndIdentifier ? (

                        <p className="text-sm mb-0 mt-4">
                            {email.from[0].name ? email.from[0].name : email.from[0].email}
                            {' To '}
                            { email.to[0].name ? email.to[0].name : email.to[0].email }
                            {' - '} 
                            {time} 
                            {' '}
                            {addresses.length > 1 ? (
                                <span> 
                                    - Also Copied: {addresses.map((t, i) => (
                                        i === 0 ? '' : (
                                            <span>{i === 1 ? '' : ', '} {t.email}</span>
                                        )
                                    ))}
                                </span>
                            ) : ''}
                        </p> 
                    ) : ''}

                    <div className="archk-text-message-wrapper">

                        <div className='archk-email-body'>
                            <p className='text-sm mb-0'>
                                <EmailBody 
                                    body={showFull ? body : body.replace(/<blockquote[\s\S]*?<\/blockquote>/, '<blockquote></blockquote>')}
                                />
                            </p>
                        </div>

                        {hasMore && !showFull ? (
                            <p  className='text-sm mb-0 text-center border-top pt-3'>
                                <span className='cursor-pointer' onClick={() => setShowFull(true)}><i className="fas fa-info-circle text-info" /> Load Full Email</span>
                            </p>
                        ) : ''}
                    </div>

                    {loading ? <small>Loading Attachments<Dots /> </small> : attachments && attachments.length ? (
                        <div className="archk-messaging-spacer position-relative border-bottom">
                        <Attachments attachments={attachments.filter(a => !a.is_inline)} />
                        </div>
                    ) :null}


                    </div>
                </Col>

                {!inbound ? (
                    <div className="col-auto  text-left pr-0 pt-7 mt--3 pl-0 ml--3" style={{width: 65}}>
                        {renderTimeAndIdentifier ? (
                            <div className='pt-1 pr-4'>
                                {/* <NameCircle width={38} initials={thread.thread.initials} style={{}} /> */}
                                <AvatarImage src="test" style={{width: 38}} />
                            </div>
                        ) : ''}
                    </div>
                ) : ''}

            </Row>

            {isLast ? <div id="archk-text-message-end" /> : ''}
        </div>
    )

 
}

export default TextsMessageList