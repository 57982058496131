import { useCallback } from 'react'
import { Document, Page } from 'react-pdf';

const imageFiles = [
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/tiff',
]

const audioFiles = [
    'audio/basic',
    'audio/L24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/ac3',
    'audio/webm',
    'audio/amr-nb',
    'audio/amr',
]

const videoFiles = [
    'video/mpeg',
    'video/mp4',
    'video/quicktime',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
    'video/3gpp-tt',
    'video/H261',
    'video/H263',
    'video/H263-1998',
    'video/H263-2000',
    'video/H264',
]

const textFiles = [
    'text/vcard',
    'text/x-vcard',
    'text/csv',
    'text/rtf',
    'text/richtext',
    'text/calendar',
    'text/directory',
]

const applicationFiles = [
    'application/pdf',
    'application/vcard',
    'application/vnd.apple.pkpass',
]

const imageClasses = 'archk-text-message-attachment mr-2 archk-text-message-image rounded z-depth-3 mt-2'
const fileClasses = 'archk-text-message-attachment archk-text-message-file mr-2 rounded z-depth-3 mt-2 bg-gradient-warning'
const errorClasses = 'archk-text-message-attachment archk-text-message-file mr-2 rounded z-depth-3 mt-2 bg-gradient-danger'

const TextsMainAttachments = ({attachments, sidebar}) => {

    const onDownload = useCallback((attachment) => {
        var a = document.createElement("a"); //Create <a>
        a.href = `data:${attachment.content_type};base64,${attachment.data}`; //Image Base64 Goes here
        a.download = attachment.filename; //File name Here
        a.click(); //Downloaded file
    }, [])

   if(!attachments.length) return <></>

   return (
        <div className='archk-text-message-attachment-wrapper archk-support-ticket-email-attachment-wrapper archk-support-ticket-email-sidebar'>
            {attachments.map((a, i) => {

                if(!a.filename) return <></>
                const filename = <div className='archk-attachment-name'><span onClick={() => onDownload(a)} className='cursor-pointer'><i className="fas fa-paperclip mr-2" /> {a.filename}</span></div>;

                if(imageFiles.includes(a.content_type)) return (
                    <span key={i}>
                        {filename}
                        <span onClick={() => onDownload(a)} className={imageClasses} style={{backgroundImage: `url(data:${a.content_type};base64,${a.data})`}} />
                    </span>
                )
                    
                if(applicationFiles.includes(a.content_type)) return (
                    <span key={i} className='mb-3 d-block '>
                        {filename}
                        <span onClick={() => onDownload(a)} className='cursor-pointer border d-inline-block rounded z-depth-1' style={{width: sidebar ? 177 : 302, overflow: 'hidden'}} > 
                            <Document  file={`data:${a.content_type};base64,${a.data}`} >
                                <Page width={sidebar ? 175 : 300} pageNumber={1} />
                            </Document>
                        </span>
                    </span>
                )
                    
                if(videoFiles.includes(a.content_type)) return (
                    <span key={i}>
                        {filename}
                        <span onClick={() => onDownload(a)} className={fileClasses}>
                            <i className="fa-solid fa-file-video display-1 text-white" />
                        </span>
                    </span>
                ) 

                if(audioFiles.includes(a.content_type)) return (
                    <span key={i}>
                        {filename}
                        <span onClick={() => onDownload(a)} className={fileClasses}>
                            <i className="fa-solid fa-volume-high display-1 text-white" />
                        </span>
                    </span>
                )

                if(textFiles.includes(a.content_type)) return (
                    <span key={i}>
                        {filename}
                        <span onClick={() => onDownload(a)} className={fileClasses}>
                            <i className="fa-solid fa-file-csv display-1 text-white" />
                        </span>
                    </span>
                )

                return (
                    <span key={i}>
                        {filename}
                        <span onClick={() => onDownload(a)} className={errorClasses}>
                            <i className="fa-solid fa-ban display-1 text-white" />
                        </span>
                    </span>
                )
            })}
        </div>
   )
 
}

export default TextsMainAttachments