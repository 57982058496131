import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';
import StandardLoader from 'components/markup/layout/StandardLoader';

import api from 'api';

import ImageRenderer from 'components/functional/images/ImageRenderer';
import ObjectFinder from 'components/system/Objects/Finder';
import moment from 'moment';
import { openDocumentCenter } from 'store/functions/document_center';

import { toast } from 'react-toastify';
import { onDownloadDocument } from 'utils/documents';

const DocumentRow = ({ doc, color, onRemoveDocument, upload }) => {

    const [d, setD] = useState(doc);

    return (
        <tr key={d._id} className="bg-white">
            <td>
                <ImageRenderer doc={d} onNameUpdated={(d) => setD(d)}>
                    <span className='pl-4 cursor-pointer'><span className='pl-3'>{d.name}</span></span> 
                </ImageRenderer>
            </td>
            <td>
                {
                    d.upload_type === 'user' ? <ObjectFinder collection="users" _id={d.uploaded_by}  />  :
                    d.upload_type === 'contact' ? <ObjectFinder collection="contacts" _id={d.uploaded_by}  />  :
                    ''
                }
            </td>
            <td className='text-right'>{moment.unix(d.created_at).format('MM/DD/YYYY h:mm A')}</td>

            <td className='text-right'>
                <button onClick={() => onDownloadDocument(d)} className='btn btn-outline-info btn-sm'><i className="fas fa-download" /></button>
                <button onClick={() => onRemoveDocument(upload._id, d._id)} className='btn btn-sm btn-danger'><i className="fas fa-trash" /></button>
            </td>
        </tr>
    )
}

const MattersViewDocumentsMissing = ({matterDocuments, viewing_user, socket, matter}) => {

    const [documents] = useState(matter.workflow_step.matter_documents)
    const [matterDocumentUploads, setMatterDocumentUploads] = useState([])

    const onStatusChange = useCallback(async (upload, value) => {
        const updated = await api.matter_document_uploads.update(upload, { status: value })
        if(!updated.success) return toast.error('Please Try Again')

        const docs = JSON.parse(JSON.stringify(matterDocumentUploads))
        const index = docs.findIndex(d => d._id === upload);
        if(index !== -1) {
            docs[index].status = value
            setMatterDocumentUploads(docs)
        }
    }, [matterDocumentUploads])

    const onRemoveDocument = useCallback(async (matter_document_upload, document_id) => {
        const docs = JSON.parse(JSON.stringify(matterDocumentUploads));
        const uploadIndex = docs.findIndex(d => d._id === matter_document_upload);

        if(uploadIndex !== -1) {
            const doc = docs[uploadIndex];
            if(doc) {
                const newArr = doc.documents.filter(dd => dd._id !== document_id);
                docs[uploadIndex].documents = newArr;
                setMatterDocumentUploads(docs)
            }
        }
        
        const removed = await api.matter_document_uploads.pull(matter_document_upload, document_id)
        if(!removed.success) return toast.error(`Could not update document at this time. Please try again.`);

    }, [matterDocumentUploads])

    const onMarkComplete = useCallback(async (matter_document, value) => {
        const created = await api.matter_document_uploads.create({ 
            status: value,
            matter: matter._id,
            matter_document
        })
        if(!created.success) return toast.error('Please Try Again')

        const docs = JSON.parse(JSON.stringify(matterDocumentUploads))
        docs.push(created.data)
        setMatterDocumentUploads(docs)

    }, [matterDocumentUploads, matter._id])

    const fetchData = useCallback(async (obj) => {
        const data = await api.matter_document_uploads.find(matter._id)
        if(data.data) setMatterDocumentUploads(data.data)
    }, [matter._id, ])

    const onUpload = useCallback((obj) => {
        if(obj && obj.uploaded_by === viewing_user._id) {
            fetchData()
        } else if(matterDocumentUploads.some(d => d._id === obj._id)) {
            fetchData()
        }
    }, [viewing_user._id, matterDocumentUploads, fetchData])

    

    useEffect(() => {
        socket.on('MATTER_DOCUMENT_UPLOADS.UPDATED', onUpload)

        return () => {
            socket.off('MATTER_DOCUMENT_UPLOADS.UPDATED', onUpload)
        }
    }, [onUpload, socket])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!matterDocuments) return <StandardLoader />

    return (
        <Card className='card-no-shadow'>

            <div className="table-responsive ">
                <table className="table table-not-bordered">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Uploaded By</th>
                            {/* <th className="text-right mr-5"><div className="mr-4">Uploaded By</div></th> */}
                            <th className="text-right">Upload Date</th>
                            <th className="text-right"></th>
                        </tr>
                    </thead>

                    <tbody>

                        {documents.map((document, i) => {
                            const upload = matterDocumentUploads.find(d => d.matter_document === document.matter_document._id)
                            const color = i & 1 ? 'bg-secondary' : 'bg-secondary'

                            return (
                                <React.Fragment key={document._id}>
                                <tr className={color}>
                                    <td>
                                        <span 
                                            className='cursor-pointer text-dark font-weight-bold text-uppercase' 
                                            onClick={() => openDocumentCenter({matter: matter._id, matter_document: document.matter_document._id})}
                                        >
                                            <i className="fas fa-upload mr-2 text-info" /> {document.matter_document.name} 
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <ReactSelect
                                            placeholder="Status"
                                            onChange={(obj) => upload ? onStatusChange(upload._id, obj.value) : onMarkComplete(document.matter_document._id, obj.value)}
                                            options={[
                                                { label: <span><i className="fas fa-times-circle mr-2 text-purple" /> NA</span>, value: 'na' },
                                                { label:  <span><i className="fas fa-question-circle mr-2 text-info" /> Pending</span>, value: 'pending' },
                                                { label: <span><i className="fas fa-thumbs-up mr-2 text-success" /> Approved</span>, value: 'approved' },
                                                { label: <span><i className="fa-solid fa-person-circle-question mr-2 text-warning" /> Missing</span>, value: 'missing' },
                                                { label: <span><i className="fas fa-clock mr-2 text-danger" /> Expired</span>, value: 'expired' },
                                            ]}
                                            value={upload ? upload.status : 'missing'}
                                        />
                                    </td>
                                </tr>
                                {upload && upload.documents && upload.documents.length ? upload.documents.map(d => (
                                    <DocumentRow 
                                        key={d._id}
                                        doc={d}
                                        color={color}
                                        onRemoveDocument={onRemoveDocument}
                                        upload={upload}
                                    />
                                )) : (
                                    <tr className="bg-white">
                                        <td>
                                            <span 
                                                className='cursor-pointer pl-4' 
                                                onClick={() => openDocumentCenter({matter: matter._id, matter_document: document.matter_document._id})}
                                            >
                                                <span className='pl-3 text-warning'>No Docs Found - Click To Upload </span>
                                            </span>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                                </React.Fragment>
                            )
                        })}

                    </tbody>
                </table>
            </div>

            {/* <CardFooter className='text-right'>
                <button className="btn btn-sm btn-success">
                    <i className="fas fa-save mr-2 " /> Send Reminder
                </button>
            </CardFooter> */}
            
        </Card>

    );

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,  
        matter: state.matters.selected_matter,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(MattersViewDocumentsMissing);