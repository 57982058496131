import { useCallback, useEffect, useState } from 'react';

import { FormGroup, Input, Modal } from "reactstrap";
import { formatCurrency } from 'utils/currency';

import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';

const ModalCustomFields = ({showModal, toggleModal, payment, onRefund}) => {

    const canRefund = payment.refunded_amount !== payment.amount

    const [err, setErr] = useState(false)
    const [amount, setAmount] = useState(payment.amount - payment.refunded_amount)

    const refund = useCallback(async () => {
        setErr(false);
        const _amount = typeof amount === 'number' ? amount.toFixed() : amount
        const balance = payment.amount - payment.refunded_amount

        if(_amount > balance) return toast.info('Amount to refund must be less than the remaining amount on the payment');
        if(_amount < 0) return toast.info('Amount to refund must be greater than 0');

        const split = _amount.split('.');
        if(split[1] && split[1].length > 2) return toast.info('Amount to refund must not have more than 2 decimal points');

        toggleStandardLoader(true)
        const payload = {
            matter            : payment.matter,
            refund_for        : payment._id,    
            amount            : parseFloat(amount),
            type              : payment.type,
        }
        if(payment.payment_method) payload.payment_method = payment.payment_method
        const charged = await api.payments.refund(payload)
        toggleStandardLoader(false);

        if(charged.data && charged.data.data && charged.data.data.payment) {
            if(charged.data.data.payment.status === 'refunded') {
                toggleModal()
                onRefund()
    
                return toast.success('Payment Refunded Successfully');
            } else {
                setErr(<span>Refund Failed - Message From Processor: <br />"<b className='text-underline'>{charged.data.data.payment.payment_status_message}</b>"</span>)
            }
           
        } else {
            if(!charged.success) return toast.error('Something went wrong processing this refund, please try again');
        }

    }, [amount, payment, toggleModal, onRefund])

    useEffect(() => {
        if(showModal) setAmount(payment.amount - payment.refunded_amount)
    }, [showModal, payment.amount, payment.refunded_amount])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Refund Payment</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom bg-secondary">
                <p className='text-sm'>Refunding a payment cannot be undone, funds should reflect on the account holders statement within 2 - 3 business days.</p>
                
                <p className='text-sm mb-0'>
                    <span style={styles.span}>Payment Amount:</span>{' '}
                    <b>{formatCurrency(payment.amount)}</b>
                </p>
                <p className='text-sm mb-0'>
                    <span style={styles.span}>Refunded So Far:</span>{' '}
                    <b className='text-danger'>{formatCurrency(payment.refunded_amount)}</b>
                </p>
                <p className='text-sm mb-0'>
                    <span style={styles.span}>Available for Refund:</span>{' '}
                    <b className='text-success'>{formatCurrency(payment.amount - payment.refunded_amount)}</b>
                </p>
            </div>

            <div className="modal-body">
                <FormGroup>
                    <label className='form-control-label'>Refund Amount</label>
                    <Input 
                        disabled={!canRefund}
                        type="number"
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                    />
                </FormGroup>
            </div>

            {err ? (
                <div className="modal-body bg-secondary border-top">
                    <p className='text-sm mb-0 text-warning font-weight-bold'>{err}</p>
                </div>
            ) : ''}
           
            {!canRefund ? (
                <div className="modal-body bg-secondary border-top">
                    <p className='text-sm mb-0 text-warning font-weight-bold'>This payment has already been refunded in full.</p>
                </div>
            ) : ''}

            <div className="modal-footer">
                <button className="btn btn-outline-success" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> Cancel
                </button>
                <button disabled={!canRefund} className="btn btn-danger" onClick={refund}>
                    <i className="fas fa-exclamation-triangle mr-2" /> Refund
                </button>
            </div>

        </Modal>

    )
}

const styles = {
    span: {
        display: 'inline-block',
        width: 120
    }
}

export default ModalCustomFields