import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';



const ModuleBKOverview = ({ data, onInputChange }) => {

    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Financial Details</CardTitle>
                </CardHeader>

                    <CardFooter>

                    <Row>
                        {data.chapter === 13 ? (
                            <Col>
                                <p className='text-sm mb-0 font-weight-bold'>Plan Length</p>

                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        className="custom-control-input"
                                        name="archk_ch_13_plan_length_select"
                                        id={`archk_ch_13_plan_length_36`}
                                        type="radio"
                                        checked={data.plan_length === 36}
                                        onClick={e => onInputChange('plan_length', 36)}
                                    />
                                    <label className="custom-control-label" htmlFor={`archk_ch_13_plan_length_36`}>
                                        36 Months
                                    </label>
                                </div>
                            
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        className="custom-control-input"
                                        name="archk_ch_13_plan_length_select"
                                        id={`archk_ch_13_plan_length_60`}
                                        type="radio"
                                        checked={data.plan_length === 60}
                                        onClick={e => onInputChange('plan_length', 60)}
                                    />
                                    <label className="custom-control-label" htmlFor={`archk_ch_13_plan_length_60`}>
                                        60 Months
                                    </label>
                                </div>
                            </Col>
                        ) : ''}

                        <Col xs={data.chapter === 13 ? 4 : 6}>
                            <p className='text-sm mb-0 font-weight-bold'>Tax Refunds</p>

                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_tax_refunds_select"
                                    id={`archk_tax_refunds_100`}
                                    type="radio"
                                    checked={data.tax_refunds === '100'}
                                    onClick={e => onInputChange('tax_refunds', '100')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_tax_refunds_100`}>
                                    100%
                                </label>
                            </div>
                           
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_tax_refunds_select"
                                    id={`archk_tax_refunds_0`}
                                    type="radio"
                                    checked={data.tax_refunds === '0'}
                                    onClick={e => onInputChange('tax_refunds', '0')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_tax_refunds_0`}>
                                    0%
                                </label>
                            </div>
                         
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_tax_refunds_select"
                                    id={`archk_tax_refunds_prorated`}
                                    type="radio"
                                    checked={data.tax_refunds === 'prorated'}
                                    onClick={e => onInputChange('tax_refunds', 'prorated')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_tax_refunds_prorated`}>
                                    Prorated
                                </label>
                            </div>
                           
                        </Col>


                        <Col xs={data.chapter === 13 ? 4 : 6}>
                            <p className='text-sm mb-0 font-weight-bold'>General Unsecured</p>

                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_general_unsecured_select"
                                    id={`archk_general_unsecured_0_unsecured_base`}
                                    type="radio"
                                    checked={data.general_unsecured === '0_unsecured_base'}
                                    onClick={e => onInputChange('general_unsecured', '0_unsecured_base')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_general_unsecured_0_unsecured_base`}>
                                    $0 Unsecured Base
                                </label>
                            </div>

                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_general_unsecured_select"
                                    id={`archk_general_unsecured_100_dividend`}
                                    type="radio"
                                    checked={data.general_unsecured === '100_dividend'}
                                    onClick={e => onInputChange('general_unsecured', '100_dividend')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_general_unsecured_100_dividend`}>
                                    100% Dividend
                                </label>
                            </div>
                           
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_general_unsecured_select"
                                    id={`archk_general_unsecured_lqa_dividend`}
                                    type="radio"
                                    checked={data.general_unsecured === 'lqa_dividend'}
                                    onClick={e => onInputChange('general_unsecured', 'lqa_dividend')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_general_unsecured_lqa_dividend`}>
                                    LQA Dividend
                                </label>
                            </div>
                        </Col>

                    </Row>
             
                </CardFooter>

                {data.chapter === 13 ? (
                    <CardFooter className='bg-secndary'>

                        <FormGroup>
                            <label className='form-control-label'>Monthly Plan Payment</label>
                            <Input 
                                type="number"
                                value={data.plan_payment_amount}
                                onChange={e => onInputChange('plan_payment_amount', parseInt(e.target.value))}
                            />
                        </FormGroup>

                        <ReactSelect
                            title={<span>Payment Plan Frequency</span>}
                            formGroup={true}
                            onChange={(obj) => onInputChange('plan_payment_frequency', obj.value)}
                            options={[
                                { value: 'weekly', label: 'Weekly' },
                                { value: 'biweekly', label: 'Bi-Weekly' },
                                { value: 'monthly', label: 'Monthly' },
                            ]}
                            value={data.plan_payment_frequency}
                        /> 

                        <hr />

                        <FormGroup>
                            <label className='form-control-label'>Payment Via Wage Order</label>
                            <Input 
                                type="number"
                                value={data.wage_order_amount}
                                onChange={e => onInputChange('wage_order_amount', parseInt(e.target.value))}
                            />
                        </FormGroup>

                        <div className='text-center my-3 text-warning'>
                            <i className="fas fa-arrow-up mr-2" /> OR <i className="fas fa-arrow-down ml-2" />
                        </div>

                        <FormGroup>
                            <label className='form-control-label'>Direct Pay To Trustee</label>
                            <Input 
                                type="number"
                                value={data.direct_pay_amount}
                                onChange={e => onInputChange('direct_pay_amount', parseInt(e.target.value))}
                            />
                        </FormGroup>
                    </CardFooter>
                ) : ''}

                {data.chapter === 13 && data.direct_pay_amount ? (
                    <CardFooter>
                        <FormGroup>
                            <label className='form-control-label'>Direct Pay Creditor Notes</label>
                            <Input 
                                type="textarea"
                                style={{minHeight: 60}}
                                value={data.direct_pay_notes}
                                onChange={e => onInputChange('direct_pay_notes', e.target.value)}
                            />
                        </FormGroup>
                    </CardFooter>
                ) : ''}
                
            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


