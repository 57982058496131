import { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { setSelectedDivision } from 'store/functions/state';
import { getHardRefreshLink } from 'utils/urls';

import HeaderBottom from './HeaderBottom';
import HeaderTop from './HeaderTop';

const NavbarDesktop = ({toggleSidenav}) => {
    const [redirect, setRedirect] = useState('');

    const onSetDivision = useCallback((division) => {
        setRedirect(getHardRefreshLink())
        setSelectedDivision(division._id)
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (

        <div id="desktop-navbar-top" className="bg-secondary">
            <HeaderTop 
                onSetDivision={onSetDivision}
                toggleSidenav={toggleSidenav}
            />
            <HeaderBottom />
        </div>

    )

}

export default NavbarDesktop;