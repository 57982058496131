import onUrlChange from './onUrlChange'
import onTimeOnPageAdded from './onTimeOnPageAdded'
import isMobile from './isMobile'
import getBrowser_type from './getBrowserType'

import api from 'api'

const mobile = isMobile();
const browser_type = getBrowser_type();

const track = async () => {

    let events = {}

    const resetEvents = () => {
        events = {
            clicks: 0,
            keystrokes: 0,
            pages: [],
            seconds_with_focus: 0,
            mobile,
            browser_type,
            down_link: navigator && navigator.connection ? navigator.connection.downlink : undefined,
            rtt: navigator && navigator.connection ? navigator.connection.rtt : undefined,
            effectiveType: navigator && navigator.effectiveType ? navigator.connection.effectiveType : undefined,
        }
    }

    resetEvents()

    if(navigator && navigator.connection) {
        navigator.connection.addEventListener('change', () => {
            events.down_link = navigator.connection.downlink || undefined
            events.rtt = navigator.connection.rtt || undefined
            events.effective_type = navigator.connection.effectiveType || undefined
        });
    }   
    

    
    window.addEventListener('click', (e) => {
        events.clicks++
    })

    window.addEventListener('keypress', (e) => {
        events.keystrokes++
    })

    onTimeOnPageAdded(time => {
        events.seconds_with_focus += time
    })

    onUrlChange(url => {
        events.pages.push(url)
    })

    // setInterval(() => {
    //     // only create a request if we have an event to track
    //     if(events.clicks || events.keystrokes || events.pages.length || events.seconds_with_focus) {
    //         api.user_activities.create(events)
    //     }
    //     resetEvents()
    // }, 5000)


}


export default track;