import { useCallback, useState } from 'react';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from "reactstrap";

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';

import ReactSelect from 'components/functional/inputs/ReactSelect'

const baseState = {
    description   : '',
    type          : 'fees',
    amount        : '',
}

const ModalCustomFields = ({invoice, onAddArrayItem, onRemoveArrayItem}) => {

    const [credit, setExpense] = useState(baseState);

    const onAddExpense = useCallback(() => {
        if(credit.amount  === '' || credit.amount < 0) return toast.info('Expense amount must be greater or equal to 0.')
        if(credit.quantity  === '' || credit.quantity < 1) return toast.info('Expense quantity must be greater or equal to 1.')
        if(!credit.description) return toast.info('Expense description cannot be left blank.')

        onAddArrayItem('credits', { 
            ...credit,
            amount: parseFloat(credit.amount),
            quantity: parseFloat(credit.quantity),
        })
        setExpense(baseState)
    }, [credit, onAddArrayItem])

    const onSetExpense = useCallback((field, val) => {
        const _credit = JSON.parse(JSON.stringify(credit))
        _credit[field] = val
        setExpense(_credit)
    }, [credit])

    const disabled = credit.amount === '' || !credit.description;

    return (

        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Add Credit</CardTitle>
                </CardHeader>
                
                <CardBody>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Item Amount*</label>
                                <Input 
                                    type="number"
                                    value={credit.amount}
                                    onChange={(e) => onSetExpense('amount', e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <ReactSelect
                                title={"Credit To*"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => onSetExpense('type', obj.value)}
                                options={[
                                    { label: 'Fees', value: 'fees' },
                                    { label: 'Expenses', value: 'expenses' },
                                ]}
                                value={credit.type}
                            /> 
                        </Col>

                    </Row>
                  
                    <FormGroup>
                        <label className='form-control-label'>Item Description*</label>
                        <Input 
                            type="text"
                            value={credit.description}
                            onChange={(e) => onSetExpense('description', e.target.value)}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <button 
                        className={`btn btn-${disabled ? 'outline-info' : 'success'} btn-sm`}
                        onClick={onAddExpense}
                        disabled={disabled}
                    >
                        <i className="fas fa-plus mr-2" /> Add Credit
                    </button>
                    {disabled ? <span>* Above Fields Required</span> : ''}
                </CardFooter>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Current Credits</CardTitle>
                </CardHeader>
                
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        {invoice.credits.length ? invoice.credits.map((obj, i) => (
                            <tr key={i}>
                                <td>{obj.description}</td>
                                <td>{formatCurrency(obj.amount)}</td>
                                <td className='text-capitalize'>{obj.type.slice(0, obj.type.length - 1)}</td>
                                <td>
                                    <i 
                                        className="fas fa-trash text-danger cursor-pointer"  
                                        onClick={() => onRemoveArrayItem('credits', i)}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>*No Expenses Have Been Added</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Card>

          
        </div>

    )
}

export default ModalCustomFields