import { useCallback, useEffect, useState } from 'react';

import { Badge, Card, CardHeader, CardTitle } from "reactstrap";

import api from 'api';
import moment from 'moment';

import Circle from 'components/markup/loading/Circle';

import View from './View'

import ModalToggler from 'components/functional/modals/Toggler'

const UserSettingsMatter = ({status}) => {
    const [timeOffRequests, setTimeOffRequests] = useState(null)

    const fetchData = useCallback(async () => {
        const _timeOffRequests = await api.time_off_requests.me(status)
        if(_timeOffRequests.data) setTimeOffRequests(_timeOffRequests.data.documents)
    }, [status])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!timeOffRequests) return <Circle />

    return (
          
            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Pending Time Off Requests </CardTitle>
                </CardHeader>

                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>From</th>
                                <th>Until</th>
                                <th>Date Requested</th>
                                <th>Date Marked</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                        {timeOffRequests.length ?  timeOffRequests.map(t => (
                            <tr key={t._id}>
                                <td>
                                    <div>
                                        {moment.unix(t.unix_start).format("MM/DD/YYYY")}
                                    </div>
                                    <div>{moment.unix(t.unix_start).format("h:mm A")}</div>
                                </td>
                                <td>
                                    <div>{moment.unix(t.unix_end).format("MM/DD/YYYY")}</div>
                                    <div>{moment.unix(t.unix_end).format("h:mm A")}</div>   
                                </td>
                                <td>
                                    <div>{moment.unix(t.requested_at).format("MM/DD/YYYY")}</div>
                                    <div>{moment.unix(t.requested_at).format("h:mm A")}</div>   
                                </td>
                                <td>
                                    {t.status_marked_at ? (
                                        <div>
                                            <div>{moment.unix(t.unix_end).format("MM/DD/YYYY")}</div>
                                            <div>{moment.unix(t.unix_end).format("h:mm A")}</div>   
                                        </div>
                                    ) : '-'}
                                </td>
                                <td className='text-right'>
                                    <ModalToggler component={View} timeOffRequest={t}>    
                                        <Badge 
                                            style={{width: 100}}
                                            color={`${t.status === 'approved' ? 'success' : t.status === 'denied' ? 'danger' : 'info'}`}
                                            className="cursor-pointer"
                                        
                                        >
                                                {t.status}

                                        </Badge>
                                    </ModalToggler>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Time Of Requests Found</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

            </Card>


    );
}

export default UserSettingsMatter