import { memo } from 'react';
import moment from 'moment';

const MattersViewMainComponentsStepHistory = ({m}) => {

    return (
        <div>
            <div className='archk-main-content-message archk-main-content-message-user'>
                {m.content.map((message, i) => (
                    <pre  style={{whiteSpace: 'break-spaces'}}>{message.text.value}</pre>
                ))}
            </div>
            <p className='text-sm'>User - {moment.unix(m.created_at).format('MM/DD/YYYY h:mm A')}</p>
        </div>
    )

}

export default memo(MattersViewMainComponentsStepHistory)