import { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, Container, Input } from "reactstrap";

import A from 'components/markup/links/A';

import api from 'api';

import ApiError from "components/markup/layout/ApiError";
import TextButtonRow from 'components/markup/layout/TextButtonRow';
import LoadingTable from 'components/markup/loading/Table';

const SystemCustomViewAll = () => {
    const [ tableData, setTableData ] = useState(null)

    const [ error, setError ] = useState(false);
    const [ search, setSearch ] = useState('');

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(() => new Promise (async resolve => {
        const query = await api.custom_views.find()
        
        if(query.data) {
            return setTableData(query.data.filter(d => d.user))
        } else {
            setError(query.message)
        }
    }), [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
 
    if(error) return <ApiError err={error} />

    let results = tableData
    if(search && tableData) results = tableData.filter(d => d.name.toLowerCase().includes(search.toLocaleLowerCase()))

    return (

        <Container className="pt-4" fluid>

            <TextButtonRow 
                title={"All Custom Views"}
            />

            <Card className="card-color card-primary table-fixed">
                <CardHeader className="pl-2 pr-4 pb pt-3 pb-3 ml-1">
                    <Input 
                        className="ml-1"
                        style={{width: 190, maxWidth: '100'}}
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </CardHeader>
                {!results ?  <LoadingTable /> : (
                    <div className="table-responsive ">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Custom View</th>
                                    <th className="text-right">Links</th>
                                    <th className="text-right"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {results.length ? (
                                    results.map(obj => {

                                        let links = 0;

                                        if(obj.link_1) links++
                                        if(obj.link_2) links++
                                        if(obj.link_3) links++
                                        if(obj.link_4) links++

                                        return (
                                            <tr key={obj._id}>
                                                <td>
                                                    <A href={`/editor/custom_views/${obj._id}`} >
                                                        <span className="cursor-pointer">{obj.name}</span>
                                                    </A>
                                                </td>
    
                                                <td className="text-right">
                                                    {links}
                                                </td>
    
                                                <td className="text-right">
                                                    <A href={`/editor/custom_views/${obj._id}`} className="btn btn-info text-white">
                                                        <i className="fas fa-link mr-2" /> OPEN
                                                    </A>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No Results Found</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                )}

            </Card>

        </Container>
    )

}

export default SystemCustomViewAll