import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Card, CardFooter } from "reactstrap";

import ImageRenderer from 'components/functional/images/ImageRenderer';
import ModalToggler from 'components/functional/modals/Toggler';
import TableIcons from 'components/markup/icons/TableIcons';
import StandardLoader from 'components/markup/layout/StandardLoader';
import ObjectFinder from 'components/system/Objects/Finder';
import A from 'components/markup/links/A';

import { getTime } from 'utils/helpers';

import ModalSearch from './ModalSearch';

import api from 'api';

import SigningEdit from './Edit'

import { onDownloadDocument } from 'utils/documents';

const MattersViewMainDocumentsSigning = ({canDelete, matter, viewing_user, PRIVILEGES}) => {

    const canEdit = viewing_user.privileges.includes(PRIVILEGES['SIGNING.DOCUMENTS.WRITE'])
    const templates = matter.workflow_step.signing_templates;
    const [signingDocuments, setSigningDocuments] = useState(null)

    const fetchDocuments = useCallback(async () => {
        const documents = await api.signing_documents.findByMatter(matter._id)
        if(documents.data) setSigningDocuments(documents.data)
    }, [matter._id])

    useEffect(() => {
        fetchDocuments()
    }, [fetchDocuments])

    if(!signingDocuments) return <StandardLoader />

    return (
        <>
        <Card className='card-no-shadow'>

            <div className="table-responsive ">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Step Documents</th>
                            <th className="text-right">Last Action At</th>
                            <th>Status</th>
                            <th>Parties</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {templates.length ? (
                            templates.map(document => {

                                const foundDoc = signingDocuments.find(d => d.signing_template === document.signing_template)

                                return (
                                    <tr key={document._id}>
                                        <td>
                                            <ObjectFinder collection="signing_templates" _id={document.signing_template} />
                                        </td>


                                        <td className="text-right">
                                            <div>{foundDoc ? getTime(foundDoc.updated_at).format('M/D/YY h:mm A') : ''}</div>
                                        </td>
                                        <td className="text-uppercase">
                                            {!foundDoc ? (
                                                <Badge style={styles.badge} color="danger">Not Sent</Badge>
                                            ) : foundDoc.status === 'sent' ? (
                                                <Badge style={styles.badge} color="warning">Sent</Badge>
                                            ) : foundDoc.status === 'pending' ? (
                                                <Badge style={styles.badge} color="info">Pending</Badge>
                                            ) : foundDoc.status === 'void' ? (
                                                <Badge style={styles.badge} color="danger">Void</Badge>
                                            ) : foundDoc.status === 'signed' ? (
                                                <Badge style={styles.badge} color="success">Signed</Badge>
                                            )  : ''}
                                        </td>



                                        <td>
                                            {foundDoc ? foundDoc.parties.map((p, i) => (
                                                i === 0 ? <ObjectFinder key={i} collection="contacts" _id={p.contact} /> : <span>, <ObjectFinder key={i} collection="contacts" _id={p.contact} /></span>
                                            )) : ''}
                                        </td>

                                     
                                        <td className="text-right">
                                            {canEdit ? (
                                                 <TableIcons
                                                    icons={[
                                                        ...!foundDoc || (foundDoc && foundDoc.status === 'void') ? [{ 
                                                            icon: 'fas fa-paper-plane',
                                                            color: 'success', 
                                                            wrapper: A, 
                                                            href: `/editor/signing/${matter._id}/${document.signing_template}`, 
                                                            tooltip: 'Send'
                                                        }] : [  { 
                                                            icon: 'fas fa-edit',
                                                            color: 'info', 
                                                            wrapper: ModalToggler, 
                                                            component: SigningEdit,
                                                            _id: foundDoc._id,
                                                            fetchDocuments: fetchDocuments,
                                                            tooltip: 'Edit'
                                                        }],
                                                    ]}
                                                />
                                            ) : 'No Actions Available'}
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td>No Documents Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>

            <CardFooter>
                <p className="mb-0 text-sm"><small>Showing {templates.length} Results</small></p>
            </CardFooter>

        </Card>

        <Card className='card-no-shadow'>

            <div className="table-responsive ">
                <table className="table">
                    <thead>
                        <tr>
                            <th>All Documents</th>
                            <th className="text-right">Sent At</th>
                            <th>Status</th>
                            <th>Parties</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {signingDocuments.length ? (
                            signingDocuments.map(document => (
                                <tr key={document._id}>
                                    <td>{document.name}</td>


                                    <td className="text-right">
                                        <div>{document ? getTime(document.created_at).format('M/D/YY h:mm A') : ''}</div>
                                    </td>

                                    <td className="text-uppercase">
                                        {document.status === 'sent' ? (
                                            <Badge style={styles.badge} color="warning">Sent</Badge>
                                        ) : document.status === 'pending' ? (
                                            <Badge style={styles.badge} color="info">Pending</Badge>
                                        ) : document.status === 'void' ? (
                                            <Badge style={styles.badge} color="danger">Void</Badge>
                                        ) : document.status === 'signed' ? (
                                            <Badge style={styles.badge} color="success">Signed</Badge>
                                        )  : ''}
                                    </td>

                                    <td>
                                        {document.parties.map((p, i) => (
                                            i === 0 ? <ObjectFinder key={i} collection="contacts" _id={p.contact} /> : <span>, <ObjectFinder key={i} collection="contacts" _id={p.contact} /></span>
                                        ))}
                                    </td>

                                    <td className="text-right">
                                        {canEdit ? (
                                            <TableIcons
                                                icons={[
                                                    { 
                                                        icon: 'fas fa-edit',
                                                        color: 'info', 
                                                        wrapper: ModalToggler, 
                                                        component: SigningEdit,
                                                        _id: document._id,
                                                        tooltip: 'Edit',
                                                        fetchDocuments: fetchDocuments,

                                                    },
                                                ]}
                                            />
                                        ) : 'No Actions Available'}
                                    </td>
                                </tr>
                            )
                        )) : (
                            <tr>
                                <td>No Documents Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>

            <CardFooter>
                <p className="mb-0 text-sm"><small>Showing {signingDocuments.length} Results</small></p>
            </CardFooter>
            
        </Card>

        <div className='text-right my-4'>
            <A href={`/editor/signing/${matter._id}/new`} className='btn btn-info'>Send Custom</A>
            <ModalToggler component={ModalSearch} matter={matter}>
                <button className='btn btn-success'>Send Template</button>
            </ModalToggler>
            
        </div>
        </>
    );

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 90
    }
}


const mapStateToProps = state => {
	return {
	    device: state.device,  
        matter: state.matters.selected_matter,
        viewing_user: state.auth.viewing_user,
        PRIVILEGES: state.config.PRIVILEGES,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainDocumentsSigning);