import { Card, CardBody, CardHeader, CardTitle, Row, Col, Badge } from 'reactstrap';

import { formatCurrency } from 'utils/currency';

import ModalToggler from 'components/functional/modals/Toggler'

import Edit from './Edit'

const Payments = ({invoices, matter, fetchData}) => {

    return (
        <Card>
            <CardHeader className='py-3'>
                <Row>
                    <Col md={6} className="align-self-center">
                        <CardTitle className="mb-0">Matter Invoices</CardTitle>
                    </Col>
                    <Col md={6} className="align-self-center text-right">
                        <ModalToggler component={Edit} matter_id={matter._id} onSaved={fetchData} >
                            <button className='btn btn-info btn-sm'><i className="fas fa-plus mr-" /> Create Invoice</button>
                        </ModalToggler>
                    </Col>
                </Row>
            </CardHeader>

            {invoices.length ? (
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className='text-center'>Status</th>
                                <th className='text-right'>Total</th>
                                <th className='text-right'>Paid</th>
                                <th className='text-right'>Balance</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {invoices.map(i => (
                                <tr key={i._id} >
                                    <td>
                                        <ModalToggler component={Edit} matter_id={matter._id} invoice_id={i._id} onSaved={fetchData} >
                                            <span className='cursor-pointer'>{i.name}</span>
                                        </ModalToggler>
                                    </td>

                                    <td className='text-center'>
                                        {i.sent ? (
                                            <Badge color="success" style={{width: 80}}>Sent</Badge>
                                        ) : (
                                            <Badge color="info" style={{width: 80}}>Not Sent</Badge>
                                        )}
                                    </td>

                                    <td className={!i.sent ? 'text-muted text-right' : 'text-right'} >
                                        {formatCurrency(i.total)}
                                    </td>

                                    <td className={!i.sent ? 'text-muted text-right' : 'text-right'} >
                                        {formatCurrency(i.total_paid)}
                                    </td>

                                    <td className={!i.sent ? 'text-muted text-right' : 'text-right'} >
                                        {formatCurrency(i.total - i.total_paid)}
                                    </td>

                                    <td className='text-right'>
                                        <ModalToggler component={Edit} matter_id={matter._id} invoice_id={i._id} onSaved={fetchData} >
                                            <button className='btn btn-outline-success'><i className="fas fa-edit mr-2" /> Edit Invoice</button>
                                        </ModalToggler>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className='font-weight-bold text-dark'>
                                    TOTAL
                                </td>
                                <td></td>
                                <td className='font-weight-bold text-dark text-right'>
                                    {formatCurrency(matter.billing_total)}
                                </td>
                                <td className='font-weight-bold text-dark text-right'>
                                    {formatCurrency(matter.billing_paid)}
                                </td>
                                <td className='font-weight-bold text-dark text-right'>
                                    {formatCurrency(matter.billing_total - matter.billing_paid)}
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    <CardBody className='py-8'>
                        <p className="text-ss mb-0 text-center text-capitalize ">
                            <i className="fas fa-info-circle mr-3 text-info" /> 
                            No invoices have been created on this matter.
                        </p>
                    </CardBody>
                    
                </div>
            )}
        </Card>
    )

}

export default Payments