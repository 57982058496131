
import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';

import api from 'api';

import Details from './Details';
import Header from './Header';
import Navigation from './Navigation';
import Parties from './Parties';
import Related from './Related';
import Roles from './Roles';

import hasPrivilege from 'utils/hasPrivilege';

const MattersViewSidebarLeft = ({
    matter, 
    viewing_user, 
    fetchWorkflowContacts, 
    workflowContacts, 
    fetchWorkflowRoles, 
    workflowRoles, 
    fetchCustomFields, 
    customFields,
    setShowAI,
}) => {

    const [view, setView] = useState(viewing_user.settings.matter_default_sidebar)
    const [related, setRelated] = useState(null)

    const fetchRelated = useCallback(async () => {
        const data = await api.matters.related(matter._id)
        if(data) setRelated(data.data)
    }, [matter._id])

    useEffect(() => {
        fetchRelated()
    }, [fetchRelated])

    return (

        <div className="col-auto border-right  sidebar sidebar-left px-0 bg-white">

            <Navigation 
                view={view} 
                setView={setView} 
                setShowAI={setShowAI} 
            />
            
            <Header 
                view={view} 
            />

            {view === 'People' ? (
                <div>
                   
                    {hasPrivilege('WORKFLOWS.READ') ? (
                        <div>
                            <Parties 
                                fetchWorkflowContacts={fetchWorkflowContacts}
                                workflowContacts={workflowContacts}
                            />
                            
                            <Roles 
                                fetchWorkflowRoles={fetchWorkflowRoles}
                                workflowRoles={workflowRoles}
                            />
                        </div>
                    ) : ''}
                    
                    <Related related={related} />

                </div>
            ) : (
                <Details 
                    fetchCustomFields={fetchCustomFields}
                    customFields={customFields}
                />
            )}



        </div>
    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
        viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersViewSidebarLeft);
