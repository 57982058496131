import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Progress } from "reactstrap";

import Item from './Item';

import { v4 as uuidv4 } from 'uuid';

const OutstandingItemsEditChecklist = ({checklist, setChecklist}) => {

    const [name, setName] = useState('');
    const [addItem, setAddItem] = useState(false);

    const finished = checklist.filter(c => c.finished).length
    const total = checklist.length

    const percent = Math.floor((finished / total) * 100)

    const onAddItem = useCallback(() => {
        if(!name) return;

        const arr = JSON.parse(JSON.stringify(checklist));
        arr.push({ name, id: uuidv4() })
        setName('')
        setChecklist(arr)

        setTimeout(() => {
            const el = document.getElementById('archk-outstanding-items-input-add')
            if(el) el.focus();
        }, [250])
    }, [checklist, name, setChecklist])
    
    const onItemChange = useCallback((item) => {

        const arr = JSON.parse(JSON.stringify(checklist));
        const index = arr.findIndex(i => i.id === item.id);

        if(index !== -1) {
            arr[index] = item;
            setChecklist(arr)
        }
    }, [checklist, setChecklist])

    const onSetAddItem = useCallback((val) => {
        setAddItem(val)
        if(val) setName('')
        setTimeout(() => {
            const el = document.getElementById('archk-outstanding-items-input-add')
            if(el) el.focus();
        }, [250])
    }, [])

    return (

        <div className='pb-5'>
            {checklist.length ? (
                <div onClick={() => setAddItem(false)}>

                    <div className="progress-wrapper pt-3">
                        <div className={`progress-info mb-0 mt--2`}>
                            <div className="progress-label">
                                <span className={`bg-white font-weight-bold text-sm ml--4 text-dark text-capitalize`} style={{ position: 'relative', left: -4}}>
                                Checklist Fields
                                </span>
                            </div>
                            <div className="progress-percentage">
                                <span>{percent}%</span>
                            </div>
                        </div>
                        <Progress max="100" value={percent} color="success" />
                    </div>
                
                    <div className='py-3'>
                        {checklist.map(c => <Item key={c._id} item={c} onItemChange={onItemChange} />)}
                    </div>
                    
                </div>
            ) : null}

            {addItem ? (
                <div>
                    <hr className='mt- mb-3' />
                    <FormGroup>
                        <label className='form-control-label'>New Field Name</label>
                        <Input 
                            id="archk-outstanding-items-input-add"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </FormGroup>

                    <button onClick={onAddItem} className='btn btn-success btn-sm'>Add Item</button>
                    <button onClick={() => setAddItem(false)} className='btn btn-warning btn-sm'>Cancel</button>
                </div>
            ) : (
                <button onClick={() => onSetAddItem(true)} className='btn btn-outline-info btn-sm mt-3'>Add Checklist Field</button>
            )}

        </div>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        selected_matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(OutstandingItemsEditChecklist);