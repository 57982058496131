import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';

import PartyUpdate from './Update';

import formatText from 'utils/formatText'
import ModalSendEmail from 'components/system/Email/Modal';
import CallContact from 'components/system/CallContact'


const MattersViewSidebarLeftRoles = ({fetchWorkflowContacts, workflowContacts, matter}) => {

    const [showAll, setShowAll] = useState(false)

    useEffect(() => {
        if(!workflowContacts) fetchWorkflowContacts()
    }, [workflowContacts, fetchWorkflowContacts])

    if(!workflowContacts) return <Circle />

    return (

        <div>
            <div className="section mb-0 py-1 border-none bg-secondary">
                <h4 className="mb-0 ">
                    <span className="text-upercase">Parties</span>
                     
                    <span className="float-right cursor-pointer" onClick={() => setShowAll(!showAll)} >
                        {showAll ? (
                            <span><i className="fas fa-square-minus mr-2 text-warning " /> Less</span>
                        ) : (
                            <span><i className="fas fa-square-plus mr-2 text-info" /> More</span>
                        )}
                    </span>
                </h4>
            </div>

            <div className="section border-top mb-0 border-bottom">

                {workflowContacts.map((party, i) => {
                    const found = matter.parties.find(r => {
                        if(r.workflow_contact && r.workflow_contact._id === party._id) return true
                        return false;
                    })

                    if(!found && !showAll) return <span key={party._id}></span>
                    if(found && !found.contact && !showAll) return <span key={party._id}></span>

                    return (
                            <Row key={i} className={`cursor-pointer my-3 position-relative border-bottom`}>
                                <Col className="align-self-center">
                                <ModalToggler key={party._id} component={PartyUpdate} party={party} currentContact={found && found.contact ? found.contact : null}>

                                    <div className="cursor-pointer">
                                        <h4 className={`text-s mb--1 text-uppercase ${found && found.contact ? 'text-info' : 'text-dark'}`}>
                                            {/* {found && found.contact ? <i className="fas fa-info-circle text-info" /> : ''}  */}
                                            {' '}
                                            {party.name}
                                        </h4>
                                        {found && found.contact ? (
                                            <div>
                                                <h4 className="  mb--2">{found.contact.display_name}</h4>
                                                {found.contact.email ? <p className="text-sm mb--2">{found.contact.email}</p> : ''}
                                                {found.contact.phone ? <p className="text-sm mb--2">{formatText(found.contact.phone).phone()}</p> : ''}
                                            </div>
                                        ) : (
                                            <p className="text-warning text-sm mb-0">UNASSIGNED</p>
                                        )}
                                    </div>
                                    </ModalToggler> 

                                    <div className="py-2">

                                    {found && found.contact && found.contact.email ? (
                                        <ModalToggler 
                                            component={ModalSendEmail} 
                                            contact={found && found.contact ? found.contact._id : ''} 
                                            division={matter.division} 
                                            onSent={() => {}}
                                            matter={matter._id}
                                        >
                                            <i 
                                                className="fas fa-envelope text-warning cursor-pointer border-warning" 
                                                style={{
                                                    borderRadius: '100%', 
                                                    padding: '4px 4px'
                                                }} 
                                            />
                                        </ModalToggler>
                                    ) : ''}

                                    {found && found.contact && found.contact.phone ? (
                                        <ModalToggler component={CallContact} contact={found.contact._id} matter={matter._id} >
                                            <i 
                                                className="fas fa-headset text-success cursor-pointer border-success ml-2" 
                                                style={{
                                                    borderRadius: '100%', 
                                                    padding: '4px 4px'
                                                }} 
                                            />
                                        </ModalToggler>
                                    ) : ''}
                                    </div>

                                </Col>
                            </Row>
                    )
                })}
            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewSidebarLeftRoles);

