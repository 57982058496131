import { connect } from 'react-redux';
import ObjectFinder from 'components/system/Objects/Finder';
import { formatCurrency } from 'utils/currency';
import A from 'components/markup/links/A'

import moment from 'moment';

import { Link } from 'react-router-dom'

const MattersViewSidebarLeft = ({matter, view}) => {

    const overdue = matter.current_step_overdue_at > 0 && matter.current_step_overdue_at <= Math.floor(new Date() / 1000) ? true : false;

    return (
        <div>


           
            <div className="section border-bottom">
                <h5 className="mb--1 text-muted">ID: {matter.id} - <small><ObjectFinder collection="workflows" _id={matter.workflow} /></small></h5>
                <h2 className="mb--1"><Link to={`/matters/${matter._id}`}>{matter.name}</Link></h2>
                <h5 className="mb-0 text-muted">{matter.current_step_name}</h5>
                {overdue ? (
                    <>
                    <p className='text-warning text-sm mb--3'> Matter Overdue Since </p>
                    <p className='text-warning text-sm mb-0'>{moment.unix(matter.current_step_overdue_at).format('MMM Do, YYYY h:mm A')} </p>
                    </>
                ) : ''}
            </div>

            {matter.identifier ? (
                <div className="section border-bottom">
                    <h5 className="mb-0 text-muted">
                        IDENTIFIER: {matter.identifier.length > 15 ? (
                            <div className='text-dark'>{matter.identifier}</div>
                        ) : (
                            <span className='float-right text-dark'>{matter.identifier}</span>
                        )} 
                    </h5>
                </div>
            ) : ''}
            
            <div className="section border-bottom">

                <h5 className="mb-0 text-muted">
                    <A href={`/billing/-/${matter.id}`}>
                    VALUE: <span className='float-right text-dark'>{formatCurrency(matter.billing_total)}</span>
                    </A>
                </h5>
            </div>
            {/* {view === 'People' ? (
                <div>
                    <div className="section mb-0 py-1 border-none bg-secondary">
                        <h4 className="mb-0 ">
                            <span className='text-upperase'>Contacts</span>
                             
                            <span className="float-right cursor-pointer" onClick={() => onEdit()} >
                                <i className="fas fa-edit mr-2 text-success" /> Edit
                            </span>
                        </h4>
                    </div>


                    <div className="section border-top border-bottom">
                        <h3 className="mb--1 ">{contact.display_name ? contact.display_name : <span className='text-warning'>Name Not Found</span>}</h3>
                        <h5 className="mb--2 sm muted">{contact.email ? contact.email :  <span className='text-warning'>Email Not Found</span>}</h5>
                        <h5 className="mb-0  text-muted">{contact.phone ? contact.phone : <span className='text-warning'>Phone Not Found</span>}</h5>
                    </div>
               </div>
            ) : ''} */}
        </div>
    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewSidebarLeft);
