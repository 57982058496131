import { useCallback, useState } from 'react';

import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Nav, NavItem, NavLink, Row } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';

import DatePicker from 'components/markup/inputs/Day';
import Time from 'components/markup/inputs/Time';

const UserSettingsMatter = ({setTab}) => {

    const [type, setType] = useState('days')
    const [start, setStart] = useState(null)
    const [hoursStart, setHoursStart] = useState(32400)
    const [hoursEnd, setHoursEnd] = useState(64800)
    const [end, setEnd] = useState(null)
    const [note, setNote] = useState(null)


    const onSave = useCallback(async (e) => {
        e.preventDefault();

        if(!note) return toast.info('Please enter a reason for your request before continuing.')

        if(type === 'hours') {
            if(!start) return toast.info('You must select a date for your request before continuing')
            if(hoursEnd < hoursStart) return toast.info('Please enter a time where the ending hours are greater than the starting hours')
        } else {
            if(!start || !end) return toast.info('Please select both a start date and and end date for your request');
        }

        const unix_start = type === 'hours' ? parseInt(moment(start).format('X')) + hoursStart : parseInt(moment(start).format('X'))
        const unix_end = type === 'hours' ? parseInt(moment(start).format('X')) + hoursEnd : parseInt(moment(end).format('X'))

        if(unix_end < unix_start) return toast.info('Your request must have an end date after the date you have selected as a start date');

        toggleStandardLoader(true)
        const saved = await api.time_off_requests.create({    
            unix_start,
            unix_end,
            request_note: note,        
        })
        toggleStandardLoader(false)

        if(!saved.success) return toast.error('Something went wrong creating your request, please try again.')
        setTab(1)
    }, [end, hoursEnd, hoursStart, note, start, type, setTab])

    return (

        <Form>

            <Nav
                className="nav-fill flex-column flex-sm-row mb-4"
                id="tabs-text"
                pills
                role="tablist"
            >

                <NavItem>
                    <NavLink
                        aria-selected={type === 'days'}
                        className={`mb-sm-3 mb-md-0 ${type === 'days' ? 'active' : ''}`}
                        onClick={e => setType('days')}
                        role="tab"
                    >
                    Request Full Day / Days
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        aria-selected={type === 'hours'}
                        className={`mb-sm-3 mb-md-0 ${type === 'hours' ? 'active' : ''}`}
                        onClick={e => setType('hours')}
                        role="tab"
                    >
                        Request Partial Day
                    </NavLink>
                </NavItem>

            </Nav>

            <Card>
                <CardBody>

                    <h6 className="heading-small text-muted mb-4">Time Off Dates</h6>

                    {type === 'days' ? (
                        <Row>
                            <Col lg="6">
                                <DatePicker 
                                    value={start}
                                    onChange={(e) => setStart(e)}
                                    title="Start"
                                />
                            </Col>

                            <Col lg="6">
                                <DatePicker 
                                    value={end}
                                    onChange={(e) => setEnd(moment(e).endOf('day').toDate())}
                                    title="End"
                                />
                            </Col>
                        </Row>
                    ) : (
                        <div>
                            <DatePicker 
                                value={start}
                                onChange={(e) => {
                                    setStart(e)
                                }}
                                title="Select Day"
                            />
                          
                            <Time 
                                start={hoursStart}
                                end={hoursEnd}
                                onStartChange={(e) => setHoursStart(e)}
                                onEndChange={(e) => setHoursEnd(e)}
                                startTitle="From"
                                endTitle="Until"
                            />
                        </div>
                    )}

                    {type === 'days' && start && end ? (
                        <p className='text-sm mb-0'>
                            ***Your time off request will be processed for the period of
                            {' '}
                            <b className='text-underline'>
                            {moment(start).format('MM/DD/YYYY')}
                            {' at '}
                            {moment(start).format('h:mm A')}
                            {' through '}
                            {moment(end).format('MM/DD/YYYY')}
                            {' at '}
                            {moment(end).format('h:mm A')}
                            </b>
                        </p>
                    ) : type === 'hours' && start ? (
                        <p className='text-sm mb-0'>
                            ***Your time off request will be processed for the period of
                            {' '}
                            <b className='text-underline'>
                            {moment(start).add(hoursStart, 'seconds').format('MM/DD/YYYY')}
                            {' at '}
                            {moment(start).add(hoursStart, 'seconds').format('h:mm A')}
                            {' through '}
                            {moment(start).add(hoursEnd, 'seconds').format('MM/DD/YYYY')}
                            {' at '}
                            {moment(start).add(hoursEnd, 'seconds').format('h:mm A')}
                            </b>
                        </p>
                    ) : ''}

                    <hr />

                    <FormGroup>
                        <label className="form-control-label" htmlFor="address_line_1" >Reason For Request</label>
                        <Input
                            style={{minHeight: 200}}
                            value={note}
                            placeholder="   "
                            type="textarea"
                            onChange={e => setNote(e.target.value) }
                        />
                        <div className="valid-feedback">Looks good!</div>
                    </FormGroup>

                </CardBody>

                <CardFooter className="text-right">
                    <button className="btn btn-success btn-sm" onClick={onSave} >
                        Submit Request
                    </button>
                </CardFooter>

            </Card>

        </Form>

    );
}

export default UserSettingsMatter