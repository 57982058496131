import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Modal } from "reactstrap";

import SearchCollections from "components/system/Search/Collections";

import { toast } from 'react-toastify';

const TextsSidebarLeftSelectContact = ({showModal, toggleModal, viewing_user, divisions, onSelect, selected_division}) => {

    const [filterDivisions] = useState(divisions.filter(d => viewing_user.divisions.includes(d._id)))
    const [selectedDivision, setSelectedDivision] = useState('')
    const [contact, setContact] = useState({})

    const onMoveOn = useCallback(() => {
        if(!selectedDivision) return toast.info(`You must select a division to proceed.`)
        if(!contact._id) return toast.info(`You must select a contact to proceed.`)
        if(!contact.phone && !contact.phone_2 && !contact.phone_3) return toast.warning('The contact you have selected does not have a valid phone number to text with.')

        onSelect(contact, selectedDivision);
        toggleModal();

        setContact({})
    }, [selectedDivision, contact, onSelect, toggleModal])

    useEffect(() => {
        let _division = filterDivisions[0] ? filterDivisions[0]._id : ''
        if(selected_division._id) {
            const foundDivision = filterDivisions.find(d => d._id === selected_division._id)
            if(foundDivision) _division = foundDivision._id
        } 
        setSelectedDivision(_division)
    }, [filterDivisions, selected_division._id])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Search Contacts</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">


                <SearchCollections
                    collection={'contacts'}
                    title="Select The Contact You Wish To Text Below"
                    placeholder=""
                    value={contact._id}
                    showEnhanced="contacts"
                    onChange={(obj) => setContact(obj.obj)}
                /> 

            </div>

            <div className='modal-body border-top'>
                <Form onSubmit={e => e.preventDefault()}>
                    <p className='text-sm mb-0 font-weight-bold'>Select The Division You Would Like To Text In Reference Too:</p>
                    {filterDivisions.map(m => (
                        <div key={m._id} className="custom-control custom-radio mb-3">
                            <input
                                className="custom-control-input"
                                id={`archk-text-message-division-select-${m._id}`}
                                name="archk-text-message-division-select"
                                type="radio"
                                checked={selectedDivision === m._id}
                                onClick={e => setSelectedDivision(m._id)}
                            />
                            <label className="custom-control-label" htmlFor={`archk-text-message-division-select-${m._id}`}>
                                {m.name}
                            </label>
                        </div>
                    ))}
                </Form>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onMoveOn}>
                    Select
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        divisions: state.divisions,
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(TextsSidebarLeftSelectContact);