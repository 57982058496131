import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import moment from 'moment';

import Add from './Add';
import Edit from './Edit';

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';
import { getUrlParameter } from 'utils/urls';

const BankruptcyGarnishments = ({matter, hasOpenedUrlLink, setHasOpenedUrlLink, fetchInfo}) => {

    const [garnishments, setGarnishments] = useState(null);

    const fetchData = useCallback(async () => {
        const result = await api.modules.bk.garnishments.findByMatter(matter._id)
        if(!result.success) return toast.info(`Something went wrong fetching garnishments for this case, please reload your page.`)

        setGarnishments(result.data)
        setTimeout(() => {
            setHasOpenedUrlLink(true)
        }, 500)

    }, [matter._id, setHasOpenedUrlLink])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!garnishments) return <Circle />

    return (

        <div>

            <h2 className='text-warning mb-4'>Garnishments</h2>

            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={6} className='align-self-center'>
                                <CardTitle className='mb-0'>All Listed Garnishments</CardTitle>
                            </Col>
                            <Col md={6} className='align-self-center text-right'>
                                <ModalToggler component={Add} onSuccess={fetchData} fetchInfo={fetchInfo}>
                                    <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                                </ModalToggler>
                            </Col>
                        </Row>
                    </CardHeader>

                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Checks</th>
                                    <th>Documents</th>
                                    <th>Amount</th>
                                    <th className='text-right'>Created</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>

                            <tbody>
                                {garnishments.length ? garnishments.map((p, i) => (
                                    <tr>
                                        <td>
                                            <span className='cursor-pointer'>
                                                <ModalToggler component={Edit} onSuccess={fetchData} fetchInfo={fetchInfo} garnishment_id={p._id}>
                                                    {p.name}
                                                </ModalToggler>
                                            </span>
                                        </td>
                                        <td className='text-capitalize'>{p.status ? p.status.replace('_', ' ') : ''}</td>
                                        <td>{p.checks.length}</td>
                                        <td>{p.documents.length}</td>
                                        <td>{formatCurrency(p.amount)}</td>
                                        <td className='text-right'>{moment.unix(p.created_at).format('MM/DD/YYYY')}</td>
                                        <td className='text-right'>
                                            <ModalToggler 
                                                component={Edit} 
                                                onSuccess={fetchData} 
                                                garnishment_id={p._id}
                                                fetchInfo={fetchInfo}
                                                showModal={getUrlParameter('garnishment') === p._id && !hasOpenedUrlLink}

                                            >
                                                <button className='btn btn-sm btn-outline-info'><i className="fas fa-edit mr-2" /> Edit</button>
                                            </ModalToggler>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No garnishments have been added yet.</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <CardFooter>
                        <ModalToggler component={Add} onSuccess={fetchData} fetchInfo={fetchInfo}>
                            <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                        </ModalToggler>
                    </CardFooter>
                </Card>
            </div>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(BankruptcyGarnishments);