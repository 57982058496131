import store from 'store';
import * as actionTypes from '../../actions';

import { Device } from 'twilio-client';
import { toast } from 'react-toastify';

import api from 'api';

import { JOIN_AGENT_ROOM, LEAVE_AGENT_ROOM, } from 'sockets/events';
// import { TRANSFER_DID_NOT_ANSWER  } from 'sockets/events'



export const playSound = (tries = 0) => {
    Device.audio.ringtoneDevices.test('/Ringtone.m4a').catch(e => {
        if(tries > 10) return;

        setTimeout(() => {
            playSound(tries++)
        }, 100)
    })
    // setTimeout(() => {
    //     Device.audio.disconnect(false)
    //     Array.from(document.querySelectorAll('audio, video')).forEach(el => el.muted = true)

    // }, 500)
}

export const toggleMute = () => {
    if(Device.activeConnection().isMuted()) {
        Device.activeConnection().mute(false)
        return false
    } else {
        Device.activeConnection().mute(true)
        return true
    }
}

// user and contact are populated objects
export const addCall = async ({type, user, userNumber, contact, contactNumber, number, callSid}) => new Promise (async resolve => {

    if(!number.includes('+')) number = '+' + number;

    const params = {
        user    : user ? user._id : null,
        contact : contact ? contact._id : null,
        type,
        userNumber,
        contactNumber,
        number,
        callSid
    }

    const added = await api.call_center.addCall(params)

    if(!added.success) {
        toast.error(
            `Transfer could not be completed at this time, if you have entered a ` +
            `phone number please double check that it is a valid US based phone number.`
        )
        return resolve(false)
    }

    resolve(true)

    let display_name = number
    let _id = number

    if(user) {
        display_name = user.display_name;
        _id = user._id
    } else if(contact) {
        if(contact.display_name) {
            _id = contact._id
            display_name = contact.display_name
        } else {
            display_name = contactNumber
        }
    }

    dispatch({ transfer_call: { _id, display_name, type, can_merge: false } })

    const addListener = (tries = 0) => {
        if(tries > 40) return ;

        setTimeout(() => {
            const merge = document.getElementById('archk-call-merge')
            const cancelTransfer = document.getElementById('archk-cancel-transfer')
    
            if(!merge || !cancelTransfer) return addListener(tries + 1)

            merge.addEventListener('click', async () => {
                const merged = await api.call_center.mergeCall(params)
                if(!merged.success) return toast.error(`Could not merge call, please try again.`)

                if(merged.data && merged.data.status === 'Contact Hung Up')  {
                    toast.warning(`The contact you are trying to merge calls with has hung up.`)
                    const endButton = document.getElementById('archk-call-button-end')
                    if(endButton) endButton.click()
                }

                dispatch({
                    transfer_call: {}
                })
            })
    
            cancelTransfer.addEventListener('click', async () => {
                const cancelled = await api.call_center.cancelTransfer({
                    callSid
                })

                if(!cancelled.success) return toast.error(`Could not cancel call, please try again.`)

                dispatch({
                    transfer_call: {}
                })
            })

        },  250)
       
    }

    addListener();
})

const dispatch = (payload) => {
    store.dispatch({ type: actionTypes.SET_CALL_CENTER, payload });
}

const removeIncomingCall = () => {
    dispatch({incoming_call: {}})
}

const removeCurrentCall = () => {
    dispatch({current_call: {}, transfer_call: {}})
}

const getCallParams = (conn) => {
    return {
        // untested if contact and matter will be on all calls
        matter: conn.customParameters.get('matter'),
        contact: conn.customParameters.get('contact'),

        // should be on all calls
        name: conn.customParameters.get('name'),
        number: conn.customParameters.get('number'),
        address: conn.customParameters.get('address'),
        call_sid: conn.parameters.CallSid,
    }
}

const fetchAndSetToken = async () => new Promise (async resolve => {
    const token = await api.call_center.accessToken();

    try {  

        if(token.message && token.message[0] === "Invalid Twilio Configuration, Check Credentials") {
            console.error(
                `Twilio error, this error means your twilio account is not configured ` +
                `properly and settings must be updated in your admin dashboard.`
            )
            return toast.error(`Invalid Twilio Configuration, Check Credentials Or Contact Your System Administrator`)
        }

        Device.setup(token.data.token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
            closeProtection: true,
            sounds: {
                // kill the connected sound by placing a bad url here
                // outgoing: 'test',
                incoming: '/Ringtone.m4a',
            }
        });
        return resolve(true)

    } catch(e) {
        console.log(e)
        toast.error(
            `Browser calling could not be setup. Refresh your browser and try ` +
            `again or visit https://networktest.twilio.com for troubleshooting.`
        )
        dispatch({ browser_dialing: 'error' })
        return resolve(false)
    }
})

// this function will create a device with twilio allowing the user to dial via browser if they have that as their call setting
export const enableDialing = async () => new Promise (async resolve => {
    const state = store.getState();
    if(state.call_center.browser_dialing === 'ready') return resolve(true);

    dispatch({ browser_dialing: 'loading' })

    const set = await fetchAndSetToken()
    if(!set) return resolve(false)

     // wont fire on login but fires every other time a user refreshes the page
    Device.on('ready', (a) => {
        const socket = store.getState().socket;
        socket.emit(JOIN_AGENT_ROOM, {})

        socket.on('CALL_CENTER.TRANSFER_DID_NOT_ANSWER', (params) => {
            dispatch({
                transfer_call: {}
            })
        })

        socket.on('CALL_CENTER.TRANSFER_ANSWERED', (params) => {
            dispatch({
                transfer_call: {
                    ...store.getState().call_center.transfer_call,
                    can_merge: true
                }
            })
        })

        // https://www.twilio.com/docs/voice/sdks/javascript/v1/device#audio

        dispatch({ browser_dialing: 'ready' })
        
        return resolve(true)
    })

    // log any device error
    Device.on('error', async (err) => {
        console.log(err);
        
        const socket = store.getState().socket;
        socket.emit(LEAVE_AGENT_ROOM, {})

        const refreshed = await fetchAndSetToken()

        if(refreshed) {
            const socket = store.getState().socket;
            socket.emit(JOIN_AGENT_ROOM, {})
        }

    })

    Device.on('disconnect', (conn) => {
        // setIncomingCall(false);
        // setCallReview(fetchConnectionParams(conn))
        // setOffCall();
        removeIncomingCall()
        removeCurrentCall()
    })
    
    // fired if call not picked up
    Device.on('cancel', (conn) => {
        // setIncomingCall(false);
        // setOffCall();
        removeIncomingCall()

    })

    Device.on('ringing', async (conn) => {   

    })

    Device.on('connect', async (conn) => {   

        removeIncomingCall()
        dispatch({current_call: getCallParams(conn)})

    })
    
    Device.on('incoming', (conn) => {
        dispatch({incoming_call: getCallParams(conn)})

        const initListeners = (tries) => {
            if(!tries > 100) return;
            const accept = document.getElementById('archk-accept-call');
            const decline = document.getElementById('archk-decline-call');

            if(!accept || !decline) return setTimeout(() => {
                initListeners(tries + 1)
            }, 200)

            accept.addEventListener('click', () => {
                // setOnCall(fetchConnectionParams(conn))
                conn.accept()
                // addOnCallListeners(conn)
                // _call_queues.markConnected({contact: conn.customParameters.get('contact_id'), type: 'hotline'})
                // _call_center.callAccepted({contact: conn.customParameters.get('contact_id')})
                // setIncomingCall(false);
                removeIncomingCall()
            })

            decline.addEventListener('click', () => {
                conn.reject()
                dispatch({incoming_call: {}})
            })

        }
        initListeners(0)
    })


})

// number is the number to call, contact, division, and matter are just ids
export const dialNumber = async ({name, address, number, contact, division, matter, call_queue_entry}) => {
    // let call = await Device.connect({ name, address, number, contact, division, matter, call_queue_entry });
    // console.log(call)
    Device.connect({ name, address, number, contact, division, matter, call_queue_entry });
}
