// This is a good claim entry
// https://v2.courtdrive.com/cases/pacer/miebke/2:23-bk-47233/claims

import { useCallback, useEffect, useState } from 'react';

import Circle from 'components/markup/loading/Circle';
import reactHTMLParser from 'html-react-parser';

import moment from 'moment';
import api from 'api';

import { formatCurrency } from 'utils/currency';

const ModuleBKClaims = ({ bkCase }) => {
    const hasInfo = bkCase && bkCase.case_number && bkCase.court_code;

    const [total, setTotal] = useState(0);
    const [entries, setEntries] = useState(null);
    const [err, setErr] = useState(false);

    const fetchData = useCallback(async () => {
        if(!hasInfo) return;

        // const data = await api.modules.bk.claims.find('miebke', '2:24-bk-49093')
        const data = await api.modules.bk.claims.find(bkCase.court_code, bkCase.case_number)
        if(!data.success) setErr(`Internal Error, Please Refresh Your Page`);

        if(data.data) {
            let _total = 0;
            data.data.forEach(t => _total += t.amount);

            setTotal(_total)
            return setEntries(data.data)
        }
        return setEntries([])

    }, [bkCase.case_number, bkCase.court_code, hasInfo])

    useEffect(() => {
        fetchData()
    }, [fetchData])
  
    return (
        <div>

            <h2 className='text-warning'>Filed Claims</h2>

            {!hasInfo ? (
                <div className='alert alert-info'><i className="fas fa-info-circle mr-2" /> A case must have a valid case number and court code to pull in claims</div>
            ) : err ? (
                <div className='alert alert-warning'>Could not load entries for this case, check case number and court code.</div>
            ) : !entries ? (
                <Circle />
            ) : (
                !entries.length ? (
                    <p className='text-sm'>No claims were found for this case.</p>
                ) : (
                    <div className='table-responsive border'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Last Updated</th>
                                    <th className='text-center' style={{width: 40}}>#</th>
                                    <th>Creditor</th>
                                    <th className='text-right'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entries.map(entry => (
                                    <tr key={entry._id}>
                                        <td>{moment.unix(entry.modified_unix).format('MM/DD/YYYY h:mm A')}</td>
                                        <td className='text-center'>{entry.claim_number}</td>
                                        <td>{reactHTMLParser(entry.creditor)}</td>
                                        <td className='text-right'>{formatCurrency(entry.amount)}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right text-dark'>Total: {formatCurrency(total)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            )}

        </div>


    )
}

export default ModuleBKClaims;


