/*
Documentation

toggleAlert params

1 - boolean
this is either true or false. True will show the alert false will hide it

2 - type
one of: success, danger, warning, info

3 - text
this is either a react component or a string of text to show in the alert

4 - time
the time in miliseconds to keep the alert up
default is 5000
if is string "infinite" the alert will never go away

*/

import * as actionTypes from '../../actions';
import store from 'store';
import { v4 as uuidv4 } from 'uuid';

import { toast } from 'react-toastify';


let timeout;

export const toggleAlert = (boolean, type, text, time) => {

    clearTimeout(timeout)

    //set the payload to dispatch the notification
    const payload =  {
        boolean,
        text,
        type,
    }

    //dispatch the store
    store.dispatch({
        type: actionTypes.TOGGLE_SYSTEM_ALERT,
        payload
    });

    //if we are showing and not hiding the alert
    if(boolean === true) {

        //if second parameter is not true lets set a timeout to hide this alert
        //default is 5 seconds to hide
        if(time !== 'infinite') {

            //set timeout so it can be cleared later
            //useful if another toggleAlert is called while this is still up.
            timeout = setTimeout(() => {
                store.dispatch({
                    type: actionTypes.TOGGLE_SYSTEM_ALERT,
                    payload: {
                        ...payload,
                        boolean: false,
                    }
                });
            }, time ? time : 5000);

        }

    }

}


export const toggleLoader = (boolean) => {

    store.dispatch({
        type: actionTypes.TOGGLE_SYSTEM_LOADER,
        payload: boolean,
    });

}

export const toggleStandardLoader = (boolean) => {

    store.dispatch({
        type: actionTypes.TOGGLE_STANDARD_LOADER,
        payload: boolean,
    });

}

export const addArchkAlert = (payload) => {

    const id = uuidv4();

    const archk_alerts = store.getState().system.archk_alerts

    if(archk_alerts.length > 9) return;

    const newAlerts = archk_alerts.concat({ id, ...payload })

    store.dispatch({
        type: actionTypes.SET_ARCHK_ALERTS,
        payload: newAlerts,
    });

    setTimeout(() => {

        let afterAlerts = store.getState().system.archk_alerts
        afterAlerts = afterAlerts.filter(a => a.id !== id)

        store.dispatch({
            type: actionTypes.SET_ARCHK_ALERTS,
            payload: afterAlerts,
        });

    }, payload.timeout ? payload.timeout : 2000)

}
