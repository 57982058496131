import { useEffect, useState, useCallback } from 'react';

import SearchCollections from "components/system/Search/Collections";

import Circle from 'components/markup/loading/Circle'
import ApiError from 'components/markup/layout/ApiError'

import api from 'api'

import { toast } from 'react-toastify'

const FinishTaskAssignContact = ({task, onFinish}) => {

    const workflow_contact = task.task_template ? task.task_template.workflow_contact : null;

    const name = task.task_template ? task.task_template.name : task.name;
    const description = task.task_template ? task.task_template.description : task.description;


    const [err, setErr] = useState(false)
    const [contact, setContact] = useState(null)
    const [workflowContact, setWorkflowContact] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await api.workflow_contacts.findById(workflow_contact) 
        if(!data.success || (data.data && !data.data.contact_type)) return setErr('Could not associate contact, please refresh your page or contact support if the issue persists')
        setWorkflowContact(data.data)
    }, [workflow_contact])

    const onSave = useCallback(async () => {
        if(!contact) return;
        const data = await api.matters.partyAdd({ 
            matter: task.matter, 
            workflow_contact: workflowContact._id, 
            contact,
            task: task._id
        })
        if(!data.data) return toast.error(`Something's not right, please try again.`)
        onFinish(data.data)
    }, [workflowContact._id, task._id, contact, onFinish, task.matter])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])


    if(err) return <ApiError err={err} />
    if(!workflowContact) return <Circle />

    return (
        <div>
            <SearchCollections
                collection={'contacts'}
                title={name}
                description={description}
                includeFields={[]}
                placeholder="Search Contacts..."
                value={contact}
                onChange={(obj) => setContact(obj.value)}
                filter={{
                    contact_types: { $in: [workflowContact.contact_type] }
                }}
            /> 

        </div>
    )
}

export default FinishTaskAssignContact