import { Badge, Card, CardBody, Container } from "reactstrap";
import { formatCurrency } from 'utils/currency';




const ContactsAll = ({data}) => {

    const paidInFull = data.matter.billing_paid === data.matter.billing_total ? 'text-success' : ''

    return (

        <div className="bg-inf text-whit">
            <Container fluid>
                <Card className="mb-3 z-depth- bg-secondary rounded text-center">
                    <CardBody>
                    <p className="text-sm mb-0">

                        <span style={styles.span} className={paidInFull ? 'text-success' : ''}>
                            <b className="text-dark text-uppercase pr-3">Total:</b>{' '}
                            {formatCurrency(data.matter.billing_total)}
                        </span>

                        <span style={styles.span} className={paidInFull ? 'text-success' : ''}>
                            <b className="text-dark text-uppercase pr-3 ">Paid: </b>{' '}
                            {formatCurrency(data.matter.billing_paid)}
                        </span>

                        <span style={styles.span}>
                            <b className="text-dark text-uppercase pr-3">Balance: </b>{' '}
                            {formatCurrency(data.matter.billing_balance)}
                        </span>

                        <span style={styles.span}>
                            <b className="text-dark text-uppercase pr-3">Payments:</b>{' '}
                            {data.payments.length}
                        </span>

                        <span style={styles.span}>
                            <b className="text-dark text-uppercase pr-3">Invoices:</b>{' '}
                            {data.invoices.length}
                        </span>

                        <span style={styles.span}>
                            <b className="text-dark text-uppercase pr-3">Payment Plan:</b>{' '}
                            {data.payment_subscription ? (
                                <span className="text-success"><i className="fas fa-check" /></span>
                            ) : (
                                <span className="text-danger"><i className="fas fa-times" /></span>
                            )}
                        </span>

                    </p>
                    <p className="text-sm mb-0">
                        <span style={styles.span}>
                            <b className="text-dark text-uppercase pr-3">Matter ID{data.matter.identifier ? 's' : ''}: </b>{' '}
                            {data.matter.id}
                            {data.matter.identifier ? ' - ' + data.matter.identifier : ''}
                        </span>

                    </p>
                    </CardBody>
                </Card>
            </Container>
        </div>

    )

}

const styles = {
    span: {
        display: 'inline-block', 
        marginRight: 20
    },
    badge: {
        width: 40
    }
}

export default ContactsAll