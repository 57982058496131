import { useCallback, useEffect, useState, memo } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import api from 'api';

import ApiError from "components/markup/layout/ApiError";
import Circle from 'components/markup/loading/Circle';

const MattersCreateDivisions = ({onSetDivision}) => {
    const [ divisions, setDivisions ] = useState(false)
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(async () => {
        const query = await api.divisions.search({ filter: { deleted: false } })
        if(query.data) return setDivisions(query.data.documents)
        setErr(query.message)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    if(err) return <ApiError err={err} />
    if(!divisions) return <Circle className="py-6" />

    if(!divisions.length) return (
        <Card>
            <CardBody><p className="text-sm mb-0">No divisions currently exist to create a matter with</p></CardBody>
        </Card>
    )

    return (
        <div>
            <h4>Select A Division</h4>
            <Row>
                {divisions.map(division => (
                    <Col lg={3} key={division._id}>
                        <Card className="cursor-pointer card-hover" onClick={() => onSetDivision(division)}>
                            <CardHeader>
                                <CardTitle className="mb-0"> Division</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <p className="mb-0">{division.name}</p>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )

}

export default memo(MattersCreateDivisions);