import { useState } from 'react';

import moment from 'moment';

import Message from './Message';

const TextsMessageList = ({thread, setAllAttachments}) => {

    const [currentYear] = useState(moment().format('YYYY'))

    let lastDay = '';
    let lastTime = ''
    let lastIdentifier = ''

    return thread.emails.map((t, i) => {

        const year = moment.unix(t.date).format('YYYY')
        const day = moment.unix(t.date).format('MMMM Do')
        const time = moment.unix(t.date).format('h:mm A')

        const user = t.user
        const contact = t.contact
        const identifier = user ? user : contact;
        const inbound = t.outbound ? false : true;
        
        let renderDay = day !== lastDay;
        let renderTimeAndIdentifier = time !== lastTime || identifier !== lastIdentifier;

        lastDay = day;
        lastTime = time;
        lastIdentifier = identifier;

        return (
            <Message 
                totalEmails={thread.emails.length}
                setAllAttachments={setAllAttachments}
                currentYear={currentYear}
                renderDay={renderDay}
                day={day}
                year={year}
                inbound={inbound}
                renderTimeAndIdentifier={renderTimeAndIdentifier}
                time={time}
                email={t}
                isLast={i + 1 === thread.emails.length ? true : false}
                i={i}
            />
        )
                
    })
 
}

export default TextsMessageList