
import { useState, useEffect } from "react";
import { connect } from 'react-redux';

const MattersViewSidebarLeft = ({matter,  customFields, fetchCustomFields}) => {

    const [fields, setFields] = useState([])

    useEffect(() => {
        if(!customFields) {
            fetchCustomFields()
        } else {
            let _fields = [];
            matter.workflow_step.custom_fields.forEach(s => {
                const foundField = customFields.find(ff => ff._id === s);
                if(foundField) _fields.push(foundField)

            })
            setFields(_fields)
        }
    }, [customFields, fetchCustomFields, matter.workflow_step.custom_fields])

    return (

        <div className="">

            <div className="section mb-0 py-1 border-none bg-secondary">
                <h5 className="mb-0 "><span className="text-uppercase">Details</span> <span className="float-right"><small><i className="fas fa-edit mr-2 " /> Edit</small></span></h5>
            </div>

            <div className="sectio border-top">
                {fields.length ? (
                    <div>
                        {fields.map(c => {
                            const answer = matter.custom_fields[c._id]
                            return (
                                <div key={c._id} className="section py-2">
                                    <h4 className="pl- mb-0">{c.name}</h4>
                                    <p className="mb--2 text-sm">{answer ? answer : <i className="fas fa-times text-danger" />}</p>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="section">
                        <p className="text-sm"><i className="fas fa-info-circle text-info" /> No fields were found for the current step this matter is on.</p>
                    </div>
                )}
            </div>

        </div>
    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewSidebarLeft);
