import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";

import DatePicker from 'components/markup/inputs/Day';


import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';

const ModalGarnishmentEditCheck = ({showModal, toggleModal, onSuccess, garnishment, check_id}) => {

    const [date, setDate] = useState('')
    const [memo, setMemo] = useState('')

    const onSave = useCallback(async () => {

        let result;

        if(check_id) {
            result = await api.modules.bk.garnishments.checks.update(garnishment._id, { date, memo, check: check_id })
        } else {
            result = await api.modules.bk.garnishments.checks.add(garnishment._id, { date, memo })

        }

        if(!result.success) return toast.error(`Something went wrong updating this garnishment, please refresh your page`)
        
        toast.success(`Garnishment updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [check_id, date, garnishment._id, memo, onSuccess, toggleModal])
    
    const onDelete = useCallback(async () => {

        let result = await api.modules.bk.garnishments.checks.delete(garnishment._id, { check: check_id })

        if(!result.success) return toast.error(`Something went wrong updating this garnishment, please refresh your page`)
        
        toast.success(`Garnishment updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)

    }, [check_id, garnishment._id, onSuccess, toggleModal])

    useEffect(() => {
        if(showModal && check_id) {
            const c = garnishment.checks.find(cc => cc._id === check_id);
            setDate(c.date)
            setMemo(c.memo)
        } else {
            setMemo('')
            setDate('')
        }
    }, [check_id, garnishment.checks, showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2500}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Garnishment</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div>

                <div className="modal-body border-bottom">

                    <DatePicker 
                        title={`Check Date`}
                        value={date ? moment(date).toDate() : ''} 
                        onChange={day => setDate(day)} 
                    />

                    <FormGroup>
                        <label className='form-control-label'>Check Memo</label>
                        <Input 
                            type="text"
                            value={memo}
                            onChange={(e) => setMemo(e.target.value)}
                        />
                    </FormGroup>

                </div>
               
                <div className="modal-body bg-secondary">

                    <p className='text-sm mb-0'>Check memo should exactly reference what is on the bottom left of the check</p>

                </div>
                
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>

                {check_id ? (
                    <button className="btn btn-outline-danger" onClick={onDelete}>
                        <i className="fas fa-trash mr-2" /> Delete
                    </button>
                ) : ''}
               

                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Save
                </button>

            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalGarnishmentEditCheck);