import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, FormGroup, Row } from 'reactstrap';


import Circle from 'components/markup/loading/Circle';

import keys from 'keys';

import { toast } from 'react-toastify';

import Input from './Input'

import MessageAssistant from './messages/Assistant'
import MessageUser from './messages/User'

import moment from 'moment';

const MattersViewMainComponentsStepHistory = ({messages, setCurrentMessage, onAddMessage, onAddThread, selectedThread, setSelectedThread, currentMessage, selectedAssistant}) => {
    return (

        <div className='archk-main-content-thread'>
            <div className='archk-main-content-messages'>
                {/* <Container fluid > */}
                    <div className='pt-5'>

                        {!messages.length && selectedThread ? (
                            <Circle />
                        ) : messages.length ? messages.map((m, i) => (
                            m.role === 'user' ? (
                                <MessageUser m={m} />
                            ) : (
                                <MessageAssistant m={m} />
                            )

                        )) : (
                            <p className='text-white text-center'>This is the start of your thread history.</p>
                        )}

                        {currentMessage ? (
                            <div className='ml-7'>
                                <div className='archk-main-content-message'>
                                    <pre style={{whiteSpace: 'break-spaces'}}>{currentMessage}</pre>
                                </div>
                                
                                <p className='text-sm'>Assistant - {moment().format('MM/DD/YYYY h:mm A')}</p>
                            </div>
                        ) : ''}

                    </div>


            </div>

            <Input 
                setCurrentMessage={setCurrentMessage}
                onAddMessage={onAddMessage}
                onAddThread={onAddThread}
                selectedThread={selectedThread}
                setSelectedThread={setSelectedThread}
                selectedAssistant={selectedAssistant}
            />

        </div>

    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsStepHistory);