import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';


import Circle from 'components/markup/loading/Circle';

import keys from 'keys';

import { toast } from 'react-toastify';

const MattersViewMainComponentsStepHistory = ({DOMAINS, selected_matter, setCurrentMessage, onAddMessage, onAddThread, selectedThread, selectedAssistant, setSelectedThread}) => {

    const [running, setRunning] = useState(false)
    const [prompt, setPrompt] = useState('')

    const _handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') document.querySelector(".archk-main-content-thread-textarea-send").click();
    }, [])

    const askAI = useCallback(async () => {
        if(!prompt) return;

        if(!selectedAssistant) return toast.info(`An AI assistant must be selected before running your prompt.`)

        if(selectedThread && selectedThread.ai_assistant && selectedThread.ai_assistant !== selectedAssistant) {
            return toast.info(`You may not change the assistant on an already created thread.`)
        }
        
        try {
            let url = `${DOMAINS.SERVER}/v1/ai/ask`;
            url += `?prompt=${prompt}`
            url += `&matter=${selected_matter._id}`
            url += `&ai_assistant=${selectedAssistant}`

            if(selectedThread) url += `&thread_id=${selectedThread.thread_id}`

            const _prompt = prompt;
            setPrompt('')

            onAddMessage({
                created_at: Math.floor(new Date() / 1000),
                role: "user",
                content: [{
                    type: "text",
                    text: {
                        value: prompt,
                        annotations: []
                    }
                }],
                attachments: [],
                metadata: {}
            })

            setRunning(true)
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`,
                    'Content-Type': 'text/event-stream'
                },
                credentials: 'include',
            })
           

            
            const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
    
            let i = 0
            let message = ''

            const container = document.querySelector('.archk-main-content-thread')
    
            while(true) {
                const { value, done } = await reader.read();
                
                if(i === 0 && !selectedThread) {
                    i++

                    try {
                        const split = value.split('-')

                        onAddThread({
                            _id: split[1],
                            thread_id: split[0],
                            name: _prompt && _prompt.length > 30 ? _prompt.slice(0, 30) + '...' : _prompt ? _prompt : 'Unknown thread',
                            updated_at: Math.floor(new Date() / 1000)
                        })
                        setSelectedThread({
                            _id: split[1],
                            thread_id: split[0]
                        })
                    } catch(e) {
                        console.log(e)
                    }
                                        
                } else if(done) {
                    i = 0
                    onAddMessage({
                        created_at: Math.floor(new Date() / 1000),
                        role: "assistant",
                        content: [{
                            type: "text",
                            text: {
                                value: message,
                                annotations: []
                            }
                        }],
                        attachments: [],
                        metadata: {}
                    })
                    setCurrentMessage('')
                    setRunning(false)
                    message = ''
                    break;
                } else {
                    if(value) message = value;
                    setCurrentMessage(value)
                    container.scroll({ top: container.scrollHeight, behavior: 'smooth' });

                }
            }

        } catch(e) {
            console.log(e)
            setRunning(false)
            setPrompt('')
            setCurrentMessage('')
            toast.error(`Something went wrong loading your data. Please try again.`)
        }

    }, [prompt, selectedAssistant, DOMAINS.SERVER, selected_matter, selectedThread, onAddMessage, onAddThread, setSelectedThread, setCurrentMessage])

    return (

        <div className='archk-main-content-thread-textarea'>
            <div className='position-relative'>
                {running ? (
                    <span className='archk-main-content-thread-textarea-loading'><Circle /></span>
                ) : (
                    <i 
                        className="fas fa-paper-plane archk-main-content-thread-textarea-send cursor-pointer p-3" 
                        onClick={askAI} 
                    />
                )}

                <Input 
                    value={prompt}
                    placeholder='Type something here...'
                    onChange={e => setPrompt(e.target.value)}
                    onKeyDown={_handleKeyDown}

                />
            </div>
            
        </div>

    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
        selected_matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsStepHistory);