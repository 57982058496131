import keys from 'keys';
import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Card, Container, CardHeader, Row, Col, Badge } from "reactstrap";
import A from 'components/markup/links/A'
import moment from 'moment';

import Circle from 'components/markup/loading/Circle'

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

import { getIdentifier } from 'utils/contacts';
import api from 'api';

// import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';

import { onDownloadSignedDocument } from 'utils/documents'
import { toggleStandardLoader } from "store/functions/system/system";
import { toast } from 'react-toastify'


import ConfirmationModal from 'components/functional/modals/Confirmation'

const ContactsAll = ({match}) => {

    const [showVoidDocument, setShowVoidDocument] = useState(false)
    const [showAll, setShowAll] = useState({})
    const [data, setData] = useState(null)

    const history = useHistory();

    const onSetShowAll = useCallback((_id) => {
        const _showAll = JSON.parse(JSON.stringify(showAll))
        _showAll[_id] = true;
        setShowAll(_showAll)
    }, [showAll])

    const fetchData = useCallback(async () => {
        const _data = await api.signing_documents.view(match.params.signing_document_id)
        if(_data.data) return setData(_data.data)
    }, [match.params.signing_document_id])

    const onVoid = useCallback(async () => {
        toggleStandardLoader(true)
        const _data = await api.signing_documents.void(match.params.signing_document_id, { status: 'void' })
        toggleStandardLoader(false)
        if(_data.data) return setData({...data, status: 'void'})
        toast.error(`Something went wrong, please try again.`)
    }, [match.params.signing_document_id, data])

    // const getTimeline = useCallback((event) => {
    //     return (
    //         <VerticalTimelineElement
    //             visible={true}
    //             key={event._id}
    //             className={`vertical-timeline-element--work type-system`}
    //             date={moment.unix(event.created_at).format('MM/DD/YY h:mm A') + ' - IP: ' + event.ip}
    //             icon={
    //                 event.type === 'user' ? (
    //                     <i className="fas fa-user " />
    //                 ) : event.type === 'system' ? (
    //                     <i className="fas fa-database " />
    //                 ) : event.type === 'action' ? (
    //                     <i className="fas fa-exclamation " />
    //                 ) : event.type === 'info' ? (
    //                     <i className="fas fa-info " />
    //                 ) : (
    //                     <i className="fas fa-check " />
    //                 ) 
    //             }
    //         >
    //             <h3 className="vertical-timeline-element-title text-capitalize">{event.event}</h3>
    //         </VerticalTimelineElement>
    //     )
    // }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])
        
    if(!data) return <div className="py-6"><Circle /></div>

    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Signed Contracts`}</title>
                <meta name="description" content="Signed Contracts" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Contracts"
                breadcrumb_2={data.name}
                icon="fas fa-signature"
                actionComponent={(
                    <>
                    <button onClick={history.goBack} className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2 " /> Back</button>
                    {data.finalized_on ? (
                        <button onClick={() => onDownloadSignedDocument(data)} className="btn btn-success"><i className="fas fa-download mr-2 " /> Download</button>
                    ) : data.status !== 'void' ? (
                        <button onClick={() => setShowVoidDocument(true)} className="btn btn-danger">Void Document</button>
                    ) : null}
                    </>
                )}
            />
    
            <Card className="card-color card-primary">

                {data.status === 'void' ? (
                    <CardHeader>
                        <Row className="my-3 border-bottom">
                            <Col xs={12}>
                                <div className="alert alert-info mb-3">
                                    This document has been voided and can no longer be signed by any parties involved.
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                ) : null}

                {data.parties.map(party => (
                    <CardHeader key={party._id} className="py-0">

                        {!data.status === 'void' && party.failed_attempts === 5 ? (
                            <Row className="my-3 border-bottom">
                                <Col xs={12}>
                                    <div className="alert alert-danger mb-3">
                                        This party has failed 5 login attempts and will no longer be able to sign this document. A new document must be sent out for signature.
                                    </div>
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            <Col lg={6} className="border-right py-3">

                                <h3>{getIdentifier(party.contact)}</h3>

                                <p className="text-sm mb-0">
                                    <span style={styles.identifier}>Status:</span> {party.signed_on ? <Badge color="success">Signed</Badge> : <Badge color="info">Awaiting Signature</Badge>}
                                </p>
                                <p className="text-sm mb-0">
                                    <span style={styles.identifier}>Failed Logins:</span> {party.failed_attempts === 5 ? <span className="text-danger">5</span> : party.failed_attempts}
                                </p>

                                <p className="text-sm mb-0">
                                    <span style={styles.identifier}>Password Hint:</span> {party.hint ? party.hint : 'No Password Required'}
                                </p>

                                <p className="text-sm mb-0">
                                    <span style={styles.identifier}>Last Access IP:</span> {party.last_access_ip ? party.last_access_ip : '-'}
                                </p>

                                <p className="text-sm mb-0">
                                    <span style={styles.identifier}>Signed On:</span> {party.signed_on ? moment.unix(party.signed_on).format('MMM Do, YYYY h:mm A') : '-'}
                                </p>
                                <hr className="my-3" />
                                <p className="text-sm mb-0">
                                    Link To Sign: <br />
                                    <A className="text-underline" href={`${keys.SIGN_URL}/${data._id}/${party.access_code}`}>{`${keys.SIGN_URL}/${data._id}/${party.access_code}`}</A>
                                </p>

                            </Col>
                            <Col lg={6} className="py-3">
                                <h3 className="text-center">Events</h3>
                                {/* <VerticalTimeline layout="1-column-left" >
                                    {
                                        !party.events.length ?  <div>There are not signing events to show for this contact.</div> :
                                         showAll[party._id] ? party.events.map(event => getTimeline(event)) : 
                                         (
                                            <>
                                            {getTimeline(party.events[0])}
                                            <span onClick={() => onSetShowAll(party._id)} className="cursor-pointer">
                                                <VerticalTimelineElement
                                                    className={`vertical-timeline-element--work type-load-more`}
                                                    iconStyle={{cursor: 'pointer'}}
                                                    icon={<i className="fas fa-plus-square " />}
                                                    iconOnClick={() => onSetShowAll(party._id)}
                                                >
                                                    Show All
                                                </VerticalTimelineElement>
                                            </span>
                                            </>
                                         )
                                     }        
                                </VerticalTimeline> */}
                            </Col>
                        </Row>
                    </CardHeader>
                ))}
            </Card>

            <ConfirmationModal 
                showModal={showVoidDocument}
                toggleModal={() => setShowVoidDocument(false)}
                title="Void Document"
                body={(
                    <span>
                        Are you sure you wish to void this document? It will no longer be able to be signed by any party.
                    </span>
                )}
                onConfirmation={onVoid}
            />

        </Container>
    )

}

const styles = {
    identifier: {
        display: 'inline-block',
        width: 150
    },
    badge: {
        display: 'inline-block',
        width: 90
    }
}

export default ContactsAll