const _selectBase = {
    menuPortalTarget: document.body,
    theme: (theme) => ({
        ...theme,
        borderRadius: 0,
        spacing: {
            ...theme.spacing,
            controlHeight: 31,
            baseUnit: 3,
        },
        colors: {
            color: 'white',
            text: 'white',
            primary25: '#777',
            primary: '#555',
            neutral20: 'white',
            neutral50: '#aaa',
            neutral80: 'white'
        },
    }),
    styles: {
        dropdownIndicator: (styles) => ({
            ...styles,
            paddingTop: 2,
            paddingBottom: 2,
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop:2,
            paddingBottom:2,
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: 'solid 1px #ddd',
            cursor: 'pointer'
        }),
        menuPortal: base => ({ ...base, zIndex: 999999 }),
        menu: (provided, state) => ({
            ...provided,
            boxShadow: '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)',
            position: "absolute",
            background: '#333',
            color: 'white',
            right: 0
        }),
        control: (base, state) => ({
            ...base,
            fontSize: '.7225rem',
            borderColor: '#dee2e6',
        })
    }
}

export default _selectBase;