import { useCallback, useState } from 'react';
import { Input, Modal } from "reactstrap";



import api from 'api';

import { toast } from 'react-toastify';


const ModalPreviewImage = ({showModal, toggleModal, doc, data, setData, onNameUpdated}) => {

    const [name, setName] = useState(doc ? doc.name : '')

    const onBlur = useCallback(async () => {
        const updated = await api.documents.update(doc._id, { name })
        if(!updated.success) return toast.error(`Could not update document name, please try again.`)

        if(onNameUpdated) onNameUpdated(updated.data)
    }, [doc._id, name, onNameUpdated])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >
            <div className="modal-header ">
                <h5 className="modal-title">No Preview is available for this document however you may change the name below.</h5>

                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body text-center">

                <div className=''>
                    <Input 
                        onBlur={onBlur}
                        value={name}
                        onChange={(e) => setName(e.target.value )}
                        style={{width: 500, maxWidth: '80%'}}
                    />
                </div>
            </div>

            <div className="modal-footer">
                <button onClick={toggleModal} className="btn btn-warning" >
                    Close
                </button>
            </div>

        </Modal>
    )
}

export default ModalPreviewImage
