import { useState, useEffect, useCallback } from 'react'
import { FormGroup, Input } from 'reactstrap'
import { toast } from 'react-toastify';

import api from 'api';

const FinishTaskConfirmation = ({task, onFinish}) => {
    const [identifier, setIdentifier] = useState('')

    const name = task.task_template ? task.task_template.name : task.name
    const description = task.task_template ? task.task_template.description : task.description

    const onSave = useCallback(async () => {
        if(!identifier) return;
        
        const data = await api.matters.update(task.matter, { task: task._id, identifier } )
        if(!data.data) return toast.error(`Something's not right, please try again.`)

        onFinish(data.data)
    }, [identifier, onFinish, task._id, task.matter])

    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])

    return (
        <>
       
            <FormGroup>
                <label className='form-control-label'>{name}</label>
                {description ? (<p className='text-sm mb-0'>{description}</p>) : ''}

                <Input 
                    type="text"
                    value={identifier}
                    onChange={e => setIdentifier(e.target.value)}
                />

            </FormGroup>

        </>
    )
}

export default FinishTaskConfirmation