import Circle from 'components/markup/loading/Circle';

import image from './image.svg';

import { useEffect, useState } from 'react';

import moment from 'moment';

import Call from './Call';
import Email from './Email';
import Text from './Text';

const TextsMain = ({thread, active}) => {

    const [items, setItems] = useState([])
    const [currentYear] = useState(moment().format('YYYY'))

    let lastDay = '';

    useEffect(() => {
        if(thread && thread.ticket) {

            thread.ticket.calls.forEach(c => c.category = 'call')
            thread.ticket.emails.forEach(c => c.category = 'email')
            thread.ticket.texts.forEach(c => c.category = 'text')

            const _items = thread.ticket.calls.concat(thread.ticket.emails).concat(thread.ticket.texts);
            _items.sort((a, b) => {
                const aField = a.date ? a.date : a.created_at;
                const bField = b.date ? b.date : b.created_at;

                return aField < bField ? -1 : 1
            })

            setItems(_items)
        }
    }, [thread])

    if(!thread && !active) return (
        <div className="archk-messaging-main archk-messaging-main-empty">
            <div className="archk-messaging-main-wrapper">
                <div className="archk-messaging-main-wrapper-inner text-center">
                    <img id="test123" src={image} alt="icon" style={{width: 200, }} className="pt-9"  />
                </div>
            </div>
        </div>
    )
    
  

    return (

        <div className={`archk-messaging-main ${thread && thread.ticket.resolved_at ? 'archk-messaging-main-no-response' : ''}`}>

            <div className="archk-messaging-main-wrapper">
                {!thread ? <Circle className="my-5" /> : (
                    <div className="archk-messaging-main-wrapper-inner">

                        {items.map((item, i) => {

                            const timestamp = item.date ? item.date : item.created_at;

                            const year = moment.unix(timestamp).format('YYYY')
                            const day = moment.unix(timestamp).format('MMMM Do')
                            
                            let renderDay = day !== lastDay;
                    
                            lastDay = day;

                            return (
                                <div key={item._id}>

                                    {i === 0 ? (
                                        <div className='text-muted text-center border-bottom py-3 bg-white '>Start Of Ticket History</div>
                                    ) : ''}

                                    {renderDay ? (
                                        <div className='text-center py-3 text-uppercase'>
                                            <b className='text-dark '>
                                                {day}{year !== currentYear ? ', ' + year : ''}
                                            </b>
                                        </div>
                                    ) : ''}

                                    <div className='mt-2'>
                                        {item.category === 'call' ? (
                                            <Call call={item} />
                                        ) : item.category === 'text' ? (
                                            <Text text={item} />
                                        ) : (
                                            <Email email={item} />
                                        )}
                                    </div>
                                </div>
                            )
                        })}

                        <div className='pb-4' />
                         <div id="archk-messaging-end" /> 
                    </div>
                )}
            </div>

        </div>
    )

}

export default TextsMain