import { useCallback, useEffect, useState, memo } from "react";
import { Card, CardBody, CardHeader, CardTitle, CardFooter, FormGroup, Input, Row, Col, Badge } from "reactstrap";

import api from 'api';

import ApiError from "components/markup/layout/ApiError";
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

const MattersCreateFinish = ({division, workflow, name, setName, onCreate, roles, parties, workflowContacts, workflowRoles}) => {

    const [ overview, setOverview ] = useState(false)
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    // const fetchData = useCallback(async () => {
    //     const query = await api.contacts.overview(contact._id)
    //     if(query.data) return setOverview(query.data)
    //     setErr(query.message)
    // }, [contact._id])

    const findRole = (role) => {
        const found = roles.find(r => r.workflow_role === role);
        if(found) return <ObjectFinder collection="users" _id={found.user} />
        return <Badge color="warning"><i className="fas fa-times mr-2" /> Not Selected</Badge>
    }
    
    const findContact = (contact) => {
        const found = parties.find(r => r.workflow_contact === contact);
        if(found) return <ObjectFinder collection="contacts" _id={found.contact} />
        return <Badge color="warning"><i className="fas fa-times mr-2" /> Not Selected</Badge>
    }

    // useEffect(() => {
    //     fetchData()
    // }, [fetchData])

    if(err) return <ApiError err={err} />
    // if(!overview) return <Circle className="py-6" />

    return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0"> Create This Matter</CardTitle>
            </CardHeader>
            <CardFooter>
                <Row>
                    <Col lg={12}>
                        <h4>Matter Details</h4>
                        <p className="mb-0 text-sm"><span style={styles.span}>Workflow:</span>{workflow.name}</p>
                        <p className="mb-0 text-sm"><span style={styles.span}>Division:</span>{division.name}</p>
                        {/* <p className="mb-0 text-sm"><span style={styles.span}>Contact: </span>{contact.display_name}</p> */}
                    </Col>
                    {/* <Col lg={6}>
                        <h4>Current Client Matters</h4>
                        {overview.matters.length ? overview.matters.map((matter, i) => (
                            <p key={i} className="text-sm mb-0">{matter.name}</p>
                        )) : (
                            <p className="text-sm mb-0"><i className="fas fa-check mr-2 text-success " /> This client does not currently have any associated matters.</p>
                        )}
                    </Col> */}

                   
                </Row>
            </CardFooter>
            <CardFooter>
                <Row>
                    <Col lg={6}>
                        <h4>Assigned Roles</h4>
                        {workflowRoles.map((role, i) => (
                            <p key={i} className="text-sm mb-2"><b className="text-dark" style={styles.span}>{role.name}</b> - {findRole(role._id)}</p>
                        ))}
                    </Col>
                    <Col lg={6}>
                        <h4>Assigned Contacts</h4>
                        {workflowContacts.map((role, i) => (
                            <p key={i} className="text-sm mb-2"><b className="text-dark" style={styles.span}>{role.name}</b> - {findContact(role._id)}</p>
                        ))}
                    </Col>

                   
                </Row>
            </CardFooter>
            <CardFooter>
                <FormGroup>
                    <label className="form-control-label">Matter Name</label>
                    <Input 
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormGroup>
            </CardFooter>
            <CardFooter>
                <button onClick={onCreate} className="btn btn-success">Create Matter</button>
            </CardFooter>
        </Card>
    )

}

const styles = {
    span: {
        display: 'inline-block', width: 180
    }
}

export default memo(MattersCreateFinish);