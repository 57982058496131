import { Col, Row } from 'reactstrap';

import Circle from 'components/markup/loading/Circle';

import ModalToggler from 'components/functional/modals/Toggler'


import { Link } from 'react-router-dom';

import ContactsEdit from 'views/dashboard/pages/contacts/components/Edit'


const NavbarSearch = ({contacts, matters, loadingContacts, loadingMatters, view, pageHistories, setShowResults, onSearch}) => {

    return (
        <div className="results-section border-bottom">
            {view === 'search' ? (
                <Row  className='py-2'>
                    <Col lg={6} className="position-relative">
                        <Row>
                            <Col xs={7} className="align-self-center">
                                <h4><i className="fas fa-users mr-0" /> Contact Results ({contacts.length})</h4>
                            </Col>
                            <Col xs={5} className="text-right align-self-center">
                                {loadingContacts ? (
                                    <span ><Circle sm="true" /></span>
                                ) : (
                                    <ModalToggler component={ContactsEdit} onSaved={() => onSearch()} modalTitle="Update Client">
                                        <Link to="/contacts/create" className="btn btn-outline-success  btn-sm" >Add New <i className="fas fa-plus " /></Link>
                                    </ModalToggler>
                                )}
                            </Col>
                        </Row>
                     
                    </Col>
                    <Col lg={6} className="position-relative">
                        <Row>
                            <Col xs={7} className="align-self-center">
                                <h4><i className="fas fa-gavel mr-0" /> Matter Results ({matters.length})</h4>
                            </Col>
                            <Col xs={5} className="text-right align-self-center">
                                {loadingMatters ? (
                                    <span ><Circle sm="true" /></span>
                                ) : (
                                    <Link 
                                        onClick={() => setShowResults(false)} 
                                        to="/matters/create" 
                                        className="btn btn-outline-info  btn-sm" 
                                    >
                                        Add New <i className="fas fa-plus " />
                                    </Link>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Row >
                    <Col lg={6} className="position-relative">
                        <h4><i className="fas fa-link mr-0" /> Pages Viewed</h4>
                    </Col>
                    <Col lg={6} className="position-relative text-right">
                        <h4>Showing Last ({pageHistories.length}) Pages</h4>
                    </Col>
                </Row>
            )}
        </div>
    )
}

export default NavbarSearch;