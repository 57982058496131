import { Input, FormGroup } from "reactstrap";
import { useState, useCallback, useEffect, useRef, memo } from 'react'

import { toast } from 'react-toastify';

import api from 'api';

import ReactQuill from 'react-quill';

const FeedCreate = ({matter_id, contact_id}) => {

    const ref = useRef(null)
    const [note, setNote] = useState('')
    const [disabled, setDisabled] = useState('')

    const createNote = useCallback(async () => {
        if(!note) return;
        let val = note;

        const trimEndingBreaks = () => {
            let end = val.slice(val.length - 11, val.length)
            if(end === '<p><br></p>') {
                val = val.slice(0, val.length - 11)
                trimEndingBreaks()
            }
        }

        trimEndingBreaks()

        setDisabled(true)
        const params = {
            value     : val,
            type      : 'user'
        }
        if(matter_id) params.matter = matter_id;
        if(contact_id) params.contact = contact_id;

        

        const created = await api.notes.create(params)
        setDisabled(false)

        if(created.success) return setNote('');    
        toast.error("Note was not saved, please try again")
    }, [note, matter_id, contact_id])

    useEffect(() => {
        if(note === '') {
            const el = ref.current;
            el.focus()
        }
      }, [note]);

    return (
        <div className="position-relative archk-feed-create">
            <FormGroup className="hide-toolbar">

            <ReactQuill 
                defaultValue={note}
                value={note}
                ref={ref}
                onChange={(value) => setNote(value)} 
                theme="snow"
                modules={{
                    toolbar: [
                        ["bold", "italic"],
                    ]
                }}
            />
            </FormGroup>
            <button disabled={disabled} onClick={createNote} style={{position: 'absolute', bottom: 10, right: 10}} className="btn btn-sm btn-outline-success">
                <i className="fas fa-plus " /> Add Note
            </button>
        </div>
    )

}

export default memo(FeedCreate)