import { FormGroup, Input } from "reactstrap";
import DatePicker from 'react-datepicker'
import moment from 'moment';

const ContactsUpdate = ({onInputChange, contact}) => {
    return (
        <div>
            <FormGroup>
                <label className="form-control-label">First Name</label>
                <Input 
                    type="text"
                    value={contact.given_name}
                    onChange={e => onInputChange('given_name', e.target.value)}
                />
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Last Name</label>
                <Input 
                    type="text"
                    value={contact.family_name}
                    onChange={e => onInputChange('family_name', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <label className="form-control-label">Middle Name</label>
                <Input 
                    type="text"
                    value={contact.middle_name}
                    onChange={e => onInputChange('middle_name', e.target.value)}
                />
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Date Of Birth</label>
                <DatePicker 
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    selected={contact.dob ? moment(contact.dob).toDate() : ''} 
                    onChange={(date) => onInputChange('dob', moment(date).format('YYYY-MM-DD'))}
                />
            </FormGroup>
        </div>
    )
}

export default ContactsUpdate;