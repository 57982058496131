import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';

const TasksMoveStepSelect = ({hasNextSteps, steps, nextStep, selected, setSelected, onSetType}) => {

    return (
        <div className="ection">

            <Card className='card-no-shadow'>

                <CardHeader>
                    <CardTitle className='mb-0'>Select A Step To Move To Below:</CardTitle>
                </CardHeader>

                <CardBody className='pt-3'>
                    {hasNextSteps ? steps.map((step, i) => {
                        const id = `archk-move-step-${i}`
                        const isRecommended = nextStep && step._id === nextStep._id;
                        const isSelected = step._id === selected
                        return (
                            <div key={i}>
                                <div 
                                    onClick={() => setSelected(step._id)} 
                                    className={`custom-control custom-radio mb-3 border py-3 pl-6 rounded cursor-pointer ${isSelected ? 'bg-gradient-success checkbox-bg-success text-white' : 'bg-secondary'}`}
                                >
                                    <div className='pl-2'>

                                    <input
                                        className="custom-control-input"
                                        id={id}
                                        name="customRadio"
                                        type="radio"
                                        checked={isSelected}
                                        onChange={() => setSelected(step._id)}
                                    />
                                    <label className="custom-control-label" htmlFor={id}>
                                        <b className='pr-3'>{step.name}</b> {isRecommended && <Badge color={`${isSelected ? 'secondary text-dark' : 'info'}  mt--4`} style={{position: 'relative', top: -1}} >Recommended</Badge>}
                                    </label>

                                    </div>
                                </div>
                            </div>
                        )
                    }) : (
                        <div>
                            <p className='text-sm mb-0'><i className="fas fa-info-circle mr-2 text-info" /> There are no more steps to move to on this matter.</p>
                        </div>
                    )}
                </CardBody>

                <CardFooter className='text-right'>
                    <button onClick={() => onSetType(selected ? 'check' : 'select')} className='btn btn-outline-success'>Move Step</button>
                </CardFooter>
              

            </Card>

        </div>
    );

}

export default TasksMoveStepSelect