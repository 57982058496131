import { connect } from 'react-redux'
import { useCallback, useState } from 'react'
import { Modal, FormGroup, Input, Row, Col } from "reactstrap";


import { toast } from 'react-toastify';

import SearchCollections from "components/system/Search/Collections";

import api from 'api';

const maxCharacters = 30

const MattersViewMainComponentsBannersAddBanner = ({showModal, toggleModal, banners, tags, setAllBanners, setAllTags, matter}) => {

    
    const [banner, setBanner] = useState('');
    const [tag, setTag] = useState('');
    const [useCustom, setUseCustom] = useState(false);

    const [hide, setHide] = useState(false)

    const onSaveBanner = useCallback(async (_banner) => {
        const saved = await api.matters.update(matter._id, {banners: [...banners, _banner]})
        if(!saved.data) return toast.error(`Something's not right, please try again.`)

        setAllBanners([...banners, _banner])
        setBanner('')
    }, [matter, setAllBanners, banners])


    const onAdd = useCallback(async (tag_id) => {
        const data = await api.matters.tagAdd({ matter: matter._id, tag: tag_id })
        if(!data.data) return toast.error(`Something's not right, please try again.`)

        setBanner('')

        setAllTags([...tags, tag_id])

        setHide(true)
        setTimeout(() => {
            setHide(false)
        }, 250)
    }, [matter._id, setAllTags, tags])


    const bannerCheckbox = (
        <div className="custom-control custom-checkbox">
            <input
                className="custom-control-input"
                id="confirm-terms-of-service"
                type="checkbox"
                onChange={(e) => setUseCustom(!useCustom)}
                checked={useCustom}
            />
            <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                Use a custom banner
            </label>
        </div>
    )

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add Matter Banner</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                {useCustom ? (
                    <FormGroup>
                        <label className="form-control-label">Custom Banner Description</label>
                        <Input 
                            type="text"
                            value={banner}
                            onChange={(e) => setBanner(e.target.value && e.target.value.length > maxCharacters ? banner : e.target.value)}
                        />
                        <Row className="pt-2">
                            <Col xs={6}>
                                 {bannerCheckbox}
                            </Col>
                            
                            <Col xs={6}>
                                <p className={`text-sm mb-0 text-right ${banner.length === maxCharacters ? 'text-danger' : ''}`}>
                                    {banner.length} / {maxCharacters} Characters
                                </p>
                            </Col>
                        </Row>
                       
                    </FormGroup>
                ) : (
                    <>
                    {hide ? '' : (
                        <SearchCollections
                           collection={'tags'}
                           title="Search Banners"
                           value={tag}
                           onChange={(obj) => setTag(obj.value)}
                           filter={{
                               division: matter.division,
                               deleted: false
                           }}
                       /> 
                    )}
                   
                    <FormGroup>
                        {bannerCheckbox}
                    </FormGroup>
                    </>
                )}
                
            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={() => !useCustom ? onAdd(tag):  onSaveBanner(banner)}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsBannersAddBanner);
