import { useState } from "react";
import { Helmet } from 'react-helmet';

import { Card, Container, Alert, Badge } from "reactstrap";

import Table, { downloadCSV } from 'components/functional/tables/Standard';
import NameCircle from 'components/markup/misc/NameCircle';
import React from "react";
import formatObject from 'utils/formatObject';
import formatText from 'utils/formatText';
import api from 'api';
import moment from 'moment'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import ModalToggler from 'components/functional/modals/Toggler'

import AdminLock from 'views/dashboard/pages/_components/AdminLock'

import ModalDelete from './ModalDelete'
import ModalCreate from './ModalCreate';
import ModalUpdate from './ModalUpdate';

const ContactsAll = () => {

    const [ showModalDelete, setShowModalDelete ] = useState(false);
    const [ showModalUpdate, setShowModalUpdate ] = useState(false);
    const [ userToUpdate, setUserToUpdate ] = useState(false);
    const [ selectedRows, setSelectedRows ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(25);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)
    const [ refresh, setRefresh ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        const query = await api.users.search({ ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // similar to query however it does not pass in a limit or skip
    // and once the data is fetched it will trigger the browser to download
    // the newly created file
    const onDownloadCSV = async (params) => {
        const data = await fetchData(params, true);
        downloadCSV(data, { name: 'contacts' });
    }

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onUpdate = () => {
        setRefresh(true);
        setTimeout(() => setRefresh(false), 500)

        setShowModalUpdate(false);
        fetchData()
    }

    const columns = [
        {
            dataField: "given_name",
            text: "Name",
            formatter: (cell, row) => (
                <span className="text-capitalize">
                    <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                    {formatObject(row).name()}
                </span>
            ),
        },
        {
            dataField: "email",
            text: "Email",
            formatter: (cell, row) => (
                row.email && <div key={row._id}><i className="text-muted fas fa-envelope mr-2" /> {row.email}</div>
            )
        },
        {
            dataField: "phone",
            text: "Phone",
            formatter: (cell, row) => (
                <div>
                    {row.phone && <div key={row.created_at} > <i className="text-muted fas fa-mobile mr-2" /> {formatText(row.phone).phone()} </div>}
                </div>
            )
        },
        {
            dataField: "deleted",
            text: "Active",
            headerStyle: {textAlign: 'center'},
            formatter: (cell, row) => (
                <div className="text-center">
                    {row.deleted ? (
                        <Badge style={styles.badge} className="text-sm" color="danger">
                            <i className="fas fa-times mr-2 " /> No
                        </Badge>
                       
                    ) : (
                        <Badge style={styles.badge} className="text-sm" color="success">
                            <i className="fas fa-check mr-2 " /> Yes
                        </Badge>
                    )}
                </div>
            )
        },
        {
            dataField: "is_admin",
            text: "Is Admin",
            headerStyle: {textAlign: 'center'},
            formatter: (cell, row) => (
                <div className="text-center">
                    {row.is_admin ? (
                        <Badge style={styles.badge} className="text-sm" color="success">
                            <i className="fas fa-check mr-2 " /> Yes
                        </Badge>
                    ) : (
                        <Badge style={styles.badge} className="text-sm" color="danger">
                            <i className="fas fa-times mr-2 " /> No
                        </Badge>
                    )}
                </div>
            )
        },
        {
            dataField: "created_at",
            text: "Created",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">
                    {moment.unix(row.created_at).format('MM/DD/YYYY')}
                </div>
            )
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <i 
                            onClick={() => {
                                setShowModalUpdate(true)
                                setUserToUpdate(row)
                            }}
                            className="fas fa-edit text-success mr-2 cursor-pointer" 
                        />
                        <i 
                            onClick={() => {
                                setSelectedRows([row])
                                setShowModalDelete(true)
                            }} 
                            className="fas fa-trash text-danger cursor-pointer"
                        />
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (

        <AdminLock >
            <Container fluid>
        
                <Helmet>
                    <title>{`All Users`}</title>
                    <meta name="description" content="Users" />
                </Helmet>

                <DashHeaderOpen 
                    title={<span>Dashboard</span>}
                    breadcrumb_1="Users"
                    breadcrumb_2="All"
                    icon="fas fa-users"
                    actionComponent={(
                        <ModalToggler component={ModalCreate} query={query}>
                            <button className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</button>
                        </ModalToggler>
                    )}
                />
        
                {refresh ? null : (
                <Card className="card-color card-primary">
                    <Table
                        placeholder="Name, Email, Phone"
                        downloadCSV={onDownloadCSV}
                        query={query}
                        columns={columns}
                        data={tableData.data}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />
                </Card>
                )}
                
                <ModalDelete 
                    showModal={showModalDelete}
                    toggleModal={setShowModalDelete}
                    selectedRows={selectedRows}
                    query={query}
                />

                <ModalUpdate 
                    showModal={showModalUpdate}
                    toggleModal={setShowModalUpdate}
                    userToUpdate={userToUpdate}
                    onUpdate={onUpdate}
                />
        
            </Container>
        </AdminLock>
    )

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 80
    }
}

export default ContactsAll
