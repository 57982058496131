import { useEffect, useState, useCallback } from 'react';

import SearchCollections from "components/system/Search/Collections";
import Circle from 'components/markup/loading/Circle'
import ApiError from 'components/markup/layout/ApiError'

import api from 'api'

import { toast } from 'react-toastify'

const FinishTaskAssignContact = ({task, onFinish}) => {

    const workflow_role = task.task_template.workflow_role

    const name = task.task_template ? task.task_template.name : task.name;
    const description = task.task_template ? task.task_template.description : task.description;

    const [err, setErr] = useState(false)
    const [user, setUser] = useState(null)
    const [workflowRole, setWorkflowRole] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await api.workflow_roles.findById(workflow_role) 
        if(!data.success) return setErr('Could not associate user, please refresh your page or contact support if the issue persists')
        setWorkflowRole(data.data)
    }, [workflow_role])

    const onSave = useCallback(async () => {
        if(!user) return;
        const data = await api.matters.roleAdd({ 
            matter: task.matter, 
            workflow_role: workflowRole._id, 
            user,
            task: task._id
        })

        if(!data.data) return toast.error(`Something's not right, please try again.`)
        onFinish(data.data)
    }, [workflowRole._id, task._id, user, onFinish, task.matter])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])


    if(err) return <ApiError err={err} />
    if(!workflowRole) return <Circle />

    return (
        <div>
            <SearchCollections
                collection={'users'}
                title={name}
                description={description}
                includeFields={[]}
                placeholder="Search Users..."
                value={user}
                onChange={(obj) => setUser(obj.value)}
                filter={{
                    divisions: { $in: [workflowRole.division] }
                }}
            /> 

        </div>
    )
}

export default FinishTaskAssignContact