import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'reactstrap';

import InfoBanner from '../_components/InfoBanner';

import Dates from './Dates';
import Fields from './Fields';

import api from 'api'

import { toast } from 'react-toastify'

const ModuleBKOverview = ({ bkCase, onSaveBkCase, onSyncToPacer, matter }) => {

    const [data, setData] = useState(bkCase)
    const [saving, setSaving] = useState(false)

    const onInputChange = useCallback((field, val) => {
        const d = JSON.parse(JSON.stringify(data))
        d[field] = val;
        setData(d)
    }, [data])

    const onSave = useCallback(async () => {
        setSaving(true)
        const result = await api.modules.bk.cases.update(data._id, data)
        setSaving(false)

        if(!result.data) return toast.error(`Could not save case, please try again.`)
        toast.success(`Case Saved Successfully.`)

    }, [data])

    console.log(bkCase)

    return (
        <Form onSubmit={e => e.preventDefault()}>

            <InfoBanner bkCase={bkCase} />

            <Row>
                <Col md={5}>
                    <Dates data={data} onInputChange={onInputChange} />
                </Col>

                <Col md={7}>
                    <Fields data={data} onInputChange={onInputChange} />
                </Col>
            </Row>

            <div className='text-right'>
                <button disabled={saving} onClick={onSave} className='btn btn-success'>Update Information</button>
            </div>


        </Form>


    )
}

const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


