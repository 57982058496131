import { connect } from 'react-redux';

import { useState, useEffect } from 'react'

import { Container } from 'reactstrap';

import ObjectFinder from 'components/system/Objects/Finder';

const MattersViewMainComponentsStepHistory = ({matter}) => {

    const [steps, setSteps] = useState([])


    useEffect(() => {

        const dates = JSON.parse(JSON.stringify(matter.dates));
        dates.reverse();

        setSteps(dates.length > 5 ? dates.slice(dates.length - 5, dates.length) : dates)
    }, [matter.dates])

    return (

        <Container fluid>
            <div className="section bg-white border mb-3">
    
                <h3 className="text-center mb-4">Matter History</h3>
    
                <div className="archk-matter-history">
                    <div className="stepper-wrapper">

                        {steps.map((s, i) => (
                        <div key={i} className={`stepper-item ${i + 1 === steps.length ? 'active-item' : 'completed'}`}>

                            <div className="step-counter"><i className="fas fa-check font-weight-bold  text-success" /></div>
                            <div className="step-name">{s.workflow_step_name}</div>
                        </div>
                        ))}

                        {matter.workflow_step.recommended_next_step ? (
                            <div className="stepper-item">
                                <div className="step-counter"></div>
                                <div className="step-name"><ObjectFinder collection="workflow_steps" _id={matter.workflow_step.recommended_next_step}  /></div>
                            </div>
                        ) : ''}
    

                    </div>
                </div>
    
            </div>
        </Container>
    
    )
}


const mapStateToProps = state => {
	return {
        company: state.company,
        matter: state.matters.selected_matter,

	};
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsStepHistory);