/*
address
property type (single family, duplex, etc.)
property nature of interest
description
other info
ownership (d1, d2, d1 & 2)

current value
current_value_type (unknown, partial interest)

amount of secured claim (unknown / linked to liens)
amount of secured claim type (unkown, known, linked to liens)

year
make
model
mileage

name of institution
Type of account ('checking savings, certificates o deposit, ira)
percent Ownership

beneficiary



*/



import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalDivisionOverride = ({title, obj, field, onInputChange}) => {

    return (

        <div>

            <ReactSelect
                title={title ? title : 'Who Owns This Item'}
                formGroup={true}
                onChange={(obj) => onInputChange(field, obj.value)}
                options={[
                    { value: 'd1', label: 'Debtor 1' },
                    { value: 'd2', label: 'Debtor 2' },
                    { value: 'd1_and_d2', label: 'Debtor 1 & 2' },
                    { value: 'd_and_other', label: 'At least one debtor and another' },
                ]}
                value={obj[field]}
            />    

        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);