import { connect } from 'react-redux';
import { Card, CardBody, Col, Row,  CardHeader, CardTitle, CardFooter, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";

import moment from 'moment';

import reactHTMLParser from 'html-react-parser';
import { useCallback, useEffect, useState, useRef, memo } from 'react';

import Circle from 'components/markup/loading/Circle'

import api from 'api'
import HeaderTabs from 'components/markup/layout/HeaderTabs';
import ObjectFinder from 'components/system/Objects/Finder';
import AvatarImage from "components/functional/images/AvatarImage";

import Edit from './Edit'

import ModalToggler from 'components/functional/modals/Toggler'

const limit = 25;

const EmailBody = ({ entry }) => {

    const [body, setBody] = useState('')

    const onSetShowFull = useCallback(async () => {

        setBody(entry.email.body)

        const data = await api.emails.getAttachments(entry.email._id)
        if(data.data) {
            let _body = entry.email.body

            data.data.forEach((a, i) => {

                let type = a.content_type
                if(type && type.includes('image/png')) {
                    type = 'image/png'
                    data.data[i].content_type = 'image/png'
                }
                if(type && type.includes('image/jpeg')) {
                    type = 'image/jpeg'
                    data.data[i].content_type = 'image/jpeg'
                }
                if(type && type.includes('application/pdf')) {
                    type = 'application/pdf'
                    data.data[i].content_type = 'application/pdf'
                }
    
                if(a.data && a.is_inline) {
                    const replace = `cid:${a.content_id}`;
                    const re = new RegExp(replace,"g");
    
                    _body = _body.replace(re, `data:${a.type};base64, ${a.data}`)
                }
            })

            setBody(_body)
        }

    }, [entry])

    return (
        entry.email && entry.email.snippet ? 
            body ? <div>{reactHTMLParser(body)}</div> : 
            <span className='cursor-pointer' onClick={() => onSetShowFull(true)}>{entry.email.snippet}{entry.email.snippet.length >= 199 ? '...' : ''} </span> :
            <span className='text-warning'>**NEW MESSAGE: Refresh To View</span>
    )

}

const MattersViewMainFeed = ({ contact, matter, socket, matchHeight, viewing_user }) => {

    const [now] = useState(Math.floor(new Date() / 1000))
    const [doneFetching, setDoneFetching] = useState(false)
    const [feed, setFeed] = useState(null)
    const [loading, setLoading] = useState(null)
    const [filter, setFilter] = useState(false)
    const [tab, setTab] = useState('user')
    const listInnerRef = useRef();
   
    const fetchMore = useCallback(async () => {
        let string = `?limit=${limit}&skip=${feed.length}`
        if(tab) string += `&type=${tab}`
        if(matter) {
            string += `&matter=${matter}`
        } else {
            string += `&contact=${contact}`
        }
        setLoading(true)
        const notes = await api.notes.find(string)
        setLoading(false)
        if(notes.data) {
            if(notes.data.length < limit) return setDoneFetching(true)
            let newFeed = JSON.parse(JSON.stringify(feed))
            newFeed = newFeed.concat(notes.data)
            setFeed(newFeed)
        }
    }, [contact, matter, feed, tab])

    const onSetTab = useCallback((_tab) => {
        setTab(_tab)
        setFeed(null)
        setDoneFetching(false)
    }, [])

    const onUpdated = useCallback((data) => {
        const _feed = JSON.parse(JSON.stringify(feed));
        const index = _feed.findIndex(f => f._id === data._id);

        if(index !== -1) {
            _feed[index].value = data.value;
            setFeed(_feed)
        }
    }, [feed])

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollHeight < scrollTop + clientHeight + 1) {
                fetchMore()
            }
        }
    }

    const fetchInitial = useCallback(async () => {
        let string = `?limit=${limit}`
        if(tab) string += `&type=${tab}`
        if(matter) {
            string += `&matter=${matter}`
        } else {
            string += `&contact=${contact}`
        }

        const notes = await api.notes.find(string)
        if(notes.data) {
            if(notes.data.length < limit) setDoneFetching(true)
            setFeed(notes.data);
        }
    }, [contact, matter, tab])

    const onMessage = useCallback((params) => {
        if(
            (params.matter && params.matter === matter) || 
            (params.contacts && params.contacts.length && params.contacts.includes(contact))
        ) {
            if(!tab || (params.type === tab) || (params.user === viewing_user._id)) {
                if(feed) {
                    const newFeed = JSON.parse(JSON.stringify(feed));
                    newFeed.unshift(params);
                    setFeed(newFeed)
                }
            }
        }
    }, [contact, matter, feed, tab, viewing_user._id])

    useEffect(() => {
        if(matchHeight) {

            const el = document.getElementById(matchHeight)
            const header = document.getElementById('archk-timeline-feed-header')
            if(el) {
                const setHeight = (tries = 0) => {
                    if(tries > 10) return;
                    if(!listInnerRef.current) return setTimeout(() => {
                        setHeight(tries + 1)
                    }, 250)

                    let height = listInnerRef.current.style.height = el.offsetHeight;
                    if(header) height = height - header.offsetHeight - 10
                    listInnerRef.current.style.height = height + 'px'

                }
                setHeight()
            }
        }
    }, [matchHeight])

    useEffect(() => {
        fetchInitial()
    }, [fetchInitial])

    useEffect(() => {
        const feed = document.querySelector('#archk-timeline-feed .archk-timeline');
        const height = window.innerHeight - feed.getBoundingClientRect().top;
        if(height < 200) return;

        feed.style.height = (height - 25) + 'px'
    }, [])
   
    useEffect(() => {
        socket.on('NOTES.CREATED', onMessage)
        return () => {
            socket.off('NOTES.CREATED', onMessage)
        }

    }, [socket, onMessage])

    const body = !feed ? (
        <div className="archk-timeline">
            <Circle className="py-6" />
        </div>
    ) : (
        <div className="archk-timeline pr-3 py-3" onScroll={onScroll} style={{overflow: 'auto', }} ref={listInnerRef}>

            {feed.map(entry => {

                const name = entry.type === 'system' || entry.type === 'alert' ? 'SYSTEM' : 
                    entry.user || entry.type === 'alert' ? <ObjectFinder collection="users" _id={entry.user}/>  : 
                    entry.email && entry.email.from && entry.email.from[0] ? entry.email.from[0].name ? entry.email.from[0].name : entry.email.from[0].email : 
                    'Unknown'

                let wrapperType;
                let icon;
                let text;

                switch (entry.type) {
                    case 'text':
                        wrapperType = 'icon-wrapper-purple';
                        icon = 'fas fa-mobile pl-1';
                        text = 'Text Message: ';
                        break;
                    case 'email':
                        wrapperType = 'icon-wrapper-purple';
                        icon = 'fas fa-envelope';
                        text = 'Email: ';
                        break;
                    case 'user':
                        wrapperType = 'icon-wrapper-info';
                        icon = 'fas fa-edit';
                        text = '';
                        break;
                    case 'user_comment':
                        wrapperType = 'icon-wrapper-info';
                        icon = 'fas fa-edit';
                        text = '';
                        break;
                    case 'call':
                        wrapperType = 'icon-wrapper-warning';
                        icon = 'fas fa-headset';
                        text = 'Talked With Client: ';
                        break;
                    case 'callback':
                        wrapperType = 'icon-wrapper-warning';
                        icon = 'fas fa-headset';
                        text = 'Requested a callback: ';
                        break;
                    case 'system':
                        wrapperType = 'icon-wrapper-success';
                        icon = 'fas fa-database';
                        text = '';
                        break;
                    case 'alert':
                        wrapperType = 'icon-wrapper-danger';
                        icon = 'fas fa-exclamation-triangle';
                        text = '';
                        break;
                
                    default:
                        break;
                }

                return (
                    <Row key={entry._id} className="mb-4">
                        <div className="col-auto" style={{width: 50}}>
                            <div className={`icon-wrapper ${wrapperType}`}>
                                {entry.user && entry.type !== 'system' && entry.type !== 'alert' ? (
                                    <AvatarImage src="test"  />
                                ) : (
                                    <i className={`${icon} `} />

                                )}
                            </div>
                        </div>
                        <Col className='pl-0'>
                            
                            <Card className="mb-0 bg-secondary">
                                <CardBody className='px-3'>
                                    {entry.type === 'email' && entry.email ? <span className='font-weight-bold'>{entry.email.subject} <br /></span> : text}
                                    {
                                        entry.type === 'system' || entry.type === 'user' || entry.type === 'alert' ? reactHTMLParser(entry.value) : 
                                        entry.type === 'user_comment' ? entry.user_comment && entry.user_comment.body ? (
                                            <div>Commented On "{entry.user_comment.parent_name}": {reactHTMLParser(entry.user_comment.body)}</div>
                                            ) : '-' :
                                        entry.type === 'email' ? <EmailBody entry={entry} /> :
                                        entry.type === 'text' ? entry.text && entry.text.body? entry.text.body : <span className='text-warning'>**NEW MESSAGE: Refresh To View</span> :
                                        ''
                                    }
                                    </CardBody>
                            </Card>
                          
                            {entry.created_at ? (
                                <Row>
                                    <Col xs={6}>
                                        <p className="text-sm mb-0">
                                            {entry.type === 'email' || entry.type === 'text' ? 'Sent By' : 'Posted by'} 
                                            {' '}
                                            <b className="text-dark">{name} {name === 'SYSTEM' && entry.user ? <span> for <ObjectFinder collection="users" _id={entry.user}/></span> : ''}</b>
                                            {entry.user === viewing_user._id && entry.type === 'user' && entry.created_at > now - 86400 ? (
                                                <ModalToggler component={Edit} obj={entry} onUpdated={onUpdated}>
                                                     <i className="fas fa-edit pl-3 pr-2 cursor-pointer text-success" />
                                                </ModalToggler>
                                            ) : ''}
                                            {' '}
                                            {!entry.matter ? <span className='text-info'> - No Associated Matter</span> : ''}
                                        </p> 
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <p className="text-sm mb-0 text-muted"><i className="fas fa-clock mr-2 " /> 
                                            {moment.unix(entry.created_at).format('MMM Do, YYYY')}
                                            {' at ' }
                                            {moment.unix(entry.created_at).format('h:mm A')}
                                        </p>
                                    </Col>
                                </Row>
                            ) : null}
                              
                        </Col>
                    </Row>
                )
            })}

            {doneFetching || !feed.length ? (
                <p className='text-sm text-center'>No Further Notes or Messages Found</p>
            ) : loading ? (
                <Circle />
            ) : (
                <div className='text-center'>
                    <button className='btn btn-success' onClick={fetchMore}>Load More</button>
                </div>
            )}
        </div>
    )

    return (
        <Card id="archk-timeline-feed">
            <CardHeader id="archk-timeline-feed-header" className='pb-2'>
                <Row>
                    <Col xs={3} className="align-self-center ">
                        <CardTitle className="pt-0 mb-0">{matter ? 'Matter Feed' : 'Contact Feed'}</CardTitle>
                    </Col>
                    <Col xs={9} className="align-self-end text-right">
                        <HeaderTabs 
                            className="mb-0 mr--4 d-none d-lg-block"
                            tab={tab}
                            setTab={(tab) => onSetTab(tab)}
                            tabs={[
                                { value: 'user', name: <span><i className="fas fa-edit " /> Notes</span> },
                                { value: 'email', name: <span><i className="fas fa-envelope " /> Emails</span> },
                                { value: 'text', name: <span><i className="fas fa-mobile " /> Texts</span> },
                                { value: 'call', name: <span><i className="fas fa-mobile " /> Calls</span> },
                                { value: false, name: <span><i className="fas fa-comments " /> All</span> },
                            ]}
                        />
                        <UncontrolledDropdown className='d-block d-lg-none'>
                            <DropdownToggle color={filter ? 'success' : 'outline-info'}>
                                {filter ? filter : 'Showing Notes'}  <i className="fas fa-sort-amount-down ml-2" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={e => setFilter(false)}>All</DropdownItem>
                                <DropdownItem onClick={e => setFilter('user')}>Notes</DropdownItem>
                                <DropdownItem onClick={e => setFilter('email')}>Emails</DropdownItem>
                                <DropdownItem onClick={e => setFilter('text')}>Texts</DropdownItem>
                                <DropdownItem onClick={e => setFilter('call')}>Calls</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Col>
                </Row>
            </CardHeader>
            <CardFooter className='py-0 my-0'>
                {body}
            </CardFooter>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    socket: state.socket,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(memo(MattersViewMainFeed));
