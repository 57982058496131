import { Nav, NavItem, NavLink } from "reactstrap";
import { useState, useCallback, useEffect } from 'react';

import Navigation from './Navigation'

import ScheduleAB from './ScheduleAB'

const schedules = [
    'Property - A/B',
    'Exemptions - C',
    'Debts - D/E/F',
    'Leases - G',
    'Codebtors - H',
    'Income - I',
    'Expenses - J',
]

const ModalCustomFields = () => {

    const [schedule, setSchedule] = useState(schedules[0])

    const onSetSchedule = useCallback((s) => {
        setSchedule(s)
    }, [])

    return (

        <div>

            <h2 className='text-warning mb-4'>Real And Personal Property</h2>

            <Navigation 
                schedule={schedule}
                onSetSchedule={onSetSchedule}
                schedules={schedules}
            />

            {schedule === schedules[0] ? (
                <ScheduleAB />
            ) : ''}

        </div>
    )
}


export default ModalCustomFields