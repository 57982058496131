import { connect } from 'react-redux';
import ReactSelect from 'components/functional/inputs/ReactSelect';


const States = ({STATES, value, onChange, title, formGroup, description, menuPlacement}) => (
    <ReactSelect
        menuPlacement={menuPlacement}
        title={title}
        formGroup={formGroup === undefined ? true : formGroup}
        description={description}
        onChange={(obj) => onChange(obj.value)}
        options={STATES.map(s => { return { value: s.code, label: s.name } })}
        value={value}
    />    
)


const mapStateToProps = state => {
    return {
        STATES: state.config.STATES,
    };
};

export default connect(mapStateToProps, '')(States);

