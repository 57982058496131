import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';

import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import { setSelectedMatter } from 'store/functions/matters';
import { toggleStandardLoader } from 'store/functions/system/system';

const MatterViewMainDocuments = ({matter}) => {

    const [date, setDate] = useState('')
    const [dates, setDates] = useState(null)
    const [err, setErr] = useState(null)

    const onSave = useCallback(async () => {
        if(!date) return toast.info(`A date selection must be made before proceeding`);

        toggleStandardLoader(true)
        const result = await api.matters.customDateAdd({ custom_date: date, matter: matter._id })
        if(!result.success) {
            toggleStandardLoader(false)
            return toast.error(`Something went wrong saving your changes, please try again.`)
        }

        await setSelectedMatter(matter._id)

        toggleStandardLoader(false)
        toast.success(`Matter Identifiers Updated Successfully`)
        setDate('')
    }, [matter._id, date])

    const fetchData = useCallback(async () => {
        const data = await api.custom_dates.find(matter.division)
        if(data.data) return setDates(data.data)

        setErr(true)
    }, [matter.division])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <div className='alert alert-danger'>Could not load dates, please refresh your page</div>
    if(!dates) return <Circle />
  
    return (
        <div className="section pt-0" >
            <Card>
           
                <CardHeader>
                    <CardTitle className='mb-0'>Matter Dates</CardTitle>
                </CardHeader>

                <CardBody>
                    <ReactSelect
                        title={"To Add A Date, Select One Below and Click Save"}
                        formGroup={true}
                        onChange={(obj) => setDate(obj.value)}
                        options={[
                            { value: '', label: '' },
                            ...dates.map(d => {
                                return { value: d._id, label: d.name }
                            })
                        ]}
                        value={date}
                    />    
                </CardBody>

                <CardFooter className='text-right'>
                    <button onClick={onSave} className='btn btn-success'>Save</button>
                </CardFooter>

            </Card>
            
            <Card>
           
                <CardHeader>
                    <CardTitle className='mb-0'>Date History</CardTitle>
                </CardHeader>

                    {matter.custom_dates.length ? (
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>User</th>
                                        <th className='text-right'>Date</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {matter.custom_dates.map(d => (
                                        <tr>
                                            <td>{d.name}</td>
                                            <td><ObjectFinder collection="users" _id={d.user} /></td>
                                            <td className='text-right'>{moment.unix(d.timestamp).format("MM/DD/YYYY h:mm A")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <CardBody>
                            <div>No dates have been added for this matter</div>
                        </CardBody>
                    )}

            </Card>
        </div>

    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocuments);