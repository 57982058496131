import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, CardTitle } from 'reactstrap';

import api from 'api';

import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import SearchCollections from "components/system/Search/Collections";


const MatterViewMainDocumentsMine = ({matter, setIsMoving}) => {

    const [selected, setSelected] = useState('')
    const [redirect, setRedirect] = useState(null)

    const moveStep = useCallback(async () => {
        if(!selected) return toast.info(`You must select a step to jump to before proceeding`)
        toggleStandardLoader(true);
        setIsMoving(true)
        const result = await api.matters.moveStep(matter._id, { workflow_step: selected, jump: true });
        setIsMoving(false)
        toggleStandardLoader(false)

        if(!result.success) {
            if(result.message && result.message[0] && result.message[0] === 'not authorized') return toast.error(`Your account does not have access to jump steps.`)
            return toast.error(`Step could not be jumped, please try again or refresh your page.`)
        }

        setRedirect(`/soft_redirect?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`)
    }, [matter._id, selected, setIsMoving])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div>
            {/*  */}
             <Card className='card-no-shadow'>

                <CardHeader>
                    <CardTitle className='mb-0'>Select A Step To Jump To Below:</CardTitle>
                </CardHeader>

                <CardBody className='pt-3'>
                    <SearchCollections
                        collection={'workflow_steps'}
                        hideTitle={true}
                        // title={<span>Select The Step To Jump To Below</span>}
                        placeholder="Search Steps..."
                        value={selected}
                        onChange={(obj) => setSelected(obj.value)}
                        filter={{
                            division: matter.division,
                            workflow: matter.workflow,
                        }}
                    /> 
                </CardBody>

                <CardFooter className='text-right'>
                    <button onClick={moveStep} className='btn btn-outline-success'>Jump Step</button>
                </CardFooter>


            </Card>
        </div>
    )

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocumentsMine);