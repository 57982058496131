import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import SearchCollections from 'components/system/Search/Collections';

import api from 'api'
import { setSelectedMatter } from 'store/functions/matters'

import { toast } from 'react-toastify'

const ModalUpdateLocation = ({showModal, toggleModal, location, currentLocation, matter}) => {

    const [val, setVal] = useState(currentLocation ? currentLocation._id : null)

    const onAdd = useCallback(async () => {
        if(!val) return;
        const data = await api.matters.locationAdd({ matter: matter._id, workflow_location: location._id, location: val })
        if(!data.data) return toast.error(`Something's not right, please try again.`)

        setSelectedMatter(matter._id)
        toggleModal()
    }, [location._id, val, matter._id, toggleModal])

    const onRemove = useCallback(async () => {
        if(!val) return;
        const data = await api.matters.locationRemove({ matter: matter._id, workflow_location: location._id, location: currentLocation._id })
        if(!data.data) return toast.error(`Something's not right, please try again.`)

        setSelectedMatter(matter._id)
        toggleModal()
    }, [location._id, val, matter._id, toggleModal, currentLocation])
    
    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="sm"
        >

           <div className="modal-header">
               <h5 className="modal-title">Assign Location: {location.name}</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body bg-secondary border-bottom">
                <p className='text-sm mb-0'>
                    {currentLocation ? `This location is currently assigned to ${currentLocation.display_name}. To change this select a new location below.` : `This location is currently not assigned to anyone. Select a location below to change that.`}
                </p>
           </div
           >
           <div className="modal-body">
                <SearchCollections
                    collection="locations" 
                    value={val}
                    onChange={(obj) => setVal(obj.value)}
                    filter={{
                        division: matter.division,
                        location_types: location.location_type,
                    }}
                /> 
           </div>

           <div className="modal-footer">
                {currentLocation ? (
                    <button className="btn btn-sm btn-outline-warning" onClick={onRemove}>
                        Remove Assignment
                    </button>
                ) : ''}
                <button className="btn btn-sm btn-success" onClick={onAdd}>
                    Assign Location
                </button>
           </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalUpdateLocation);