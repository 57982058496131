import { useState, useCallback, useEffect } from 'react';
import { FormGroup, Input } from 'reactstrap';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import hasPrivilege from 'utils/hasPrivilege'

import api from 'api'

import CopyToClipboard from 'react-copy-to-clipboard'

const Number = ({onSave, contact}) => {

    const canUpdate = hasPrivilege('SOCIAL_SECURITY_NUMBERS.WRITE');
    const canRead = hasPrivilege('SOCIAL_SECURITY_NUMBERS.READ');

    const [disabled, setDisabled] = useState(true)
    const [showRedacted, setShowRedacted] = useState(true)
    const [showNumber, setShowNumber] = useState(false)
    const [val, setVal] = useState('')
    const [initialVal, setInitialVal] = useState('')

    const onFocus = useCallback(() => {
        setShowRedacted(false)
    }, [])
    const onBlur = useCallback(() => {
        setShowRedacted(true)
    }, [])

    const handleChange = useCallback((e) => {
        let value = e.target.value;
        value = value.replace(/[^0-9.]/g, '');
        if(value && value.toString().length > 9) return
        setVal(value);
    }, [])

    const fetchData = useCallback(async () => {
        const data = await api.social_security_numbers.findByContact(contact)
        setVal(data.data)

        try {
            setInitialVal(
                data.data && data.data.length === 9 ?  
                data.data.slice(0,3) + '-' + data.data.slice(3,5) + '-' + data.data.slice(5,9) :
                data.data
            )
        } catch(e) {
            console.log(e)
            setInitialVal(data.data)
        }

    }, [contact])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(canUpdate) return (
        <>
        <FormGroup>        
            <label className={`form-control-label`}>
                Social Security Number

                {canRead ? showNumber ? (
                    <CopyToClipboard 
                        text={initialVal}
                        onCopy={() => toast.success('Copied!')}
                    >
                        <span className='pl-3'>
                            {initialVal}
                            <i className="fas fa-copy text-success ml-2" />
                        </span>
                    </CopyToClipboard>
                ) : initialVal ? (
                    <i onClick={() => setShowNumber(true)} className="fas fa-eye text-success ml-2 cursor-pointer" />
                ) : '' : ''}

            </label>

            <Input
                value={showRedacted && val ? '*********' : val === true ? '' : val}
                type={'text'}
                onChange={handleChange}
                disabled={disabled}
                onBlur={() => {
                    onBlur()
                }}
                onFocus={() =>{
                    onFocus()
                }}
            />

            {disabled && val && val.length === 9 ? (
                <p className='text-sm mb-0'>
                    This field has already been filled out but is redacted for security reasons.{' '}
                    <b className='text-underline cursor-pointer' onClick={() => setDisabled(false)}>Click HERE to edit it.</b>
                </p>
            ) : disabled ? (
                <p className='text-sm mb-0'>
                    To enter a valid 9 digit social security number <b className='text-underline cursor-pointer' onClick={() => setDisabled(false)}>CLICK HERE</b>.
                </p>
            ) : ''}

        </FormGroup>  

        <hr />

        <div className='text-right'>
            <button 
                className='btn btn-success' onClick={() => {
                    onSave(val ? val : 'delete')
                    setInitialVal(val)
                }} 
            >
                <i className="fas fa-save mr-2" /> Save
            </button>
        </div>

        </>
    )

    if(canRead) return (
        <FormGroup>        
            <label className={`form-control-label`}>Social Security Number</label>

            <Input                     
                value={initialVal ? '*********' : ''}
                type={'text'}
                onChange={() => {}}
                disabled={true}
            />

            {initialVal && initialVal.length ? (
                showNumber ? (
                    <p className='text-sm mb-0'>
                        <span className='cursor-pointer'>
                            <b>Social Security Number:</b>{' '}

                            <CopyToClipboard 
                                text={initialVal}
                                onCopy={() => toast.success('Copied!')}
                                >
                                <span>{initialVal}
                                <i className="fas fa-copy text-success ml-2" /></span>
                            </CopyToClipboard>
                        </span>
                    </p>
                ) : (
                    <p className='text-sm mb-0'>
                        A valid social security number was found for this contact.{' '}
                        <b className='text-underline cursor-pointer' onClick={() => setShowNumber(true)}>Click Here to View The Number</b>
                    </p>
                )
            ) : (
                <p className='text-sm mb-0'>
                    A valid social security number was not found for this contacts file.
                </p>
            )}

        </FormGroup>  
    )

    return (
        <FormGroup>        
            <label className={`form-control-label`}>Social Security Number</label>

            <Input                     
                value={'*********'}
                type={'text'}
                onChange={() => {}}
                disabled={true}
            />

            <p className='text-sm mb-0'>Your account does not have access to read or update social security numbers.</p>

        </FormGroup>  
    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Number);