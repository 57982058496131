import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, UncontrolledTooltip, Input } from 'reactstrap';
import keys from 'keys';

import Circle from 'components/markup/loading/Circle';

import { pdfjs } from 'react-pdf';
import Axios from 'axios';

import api from 'api'

import { toast } from 'react-toastify';

import Transcription from './Transcription'

class PreviewPDF extends Component {

    state = {
        fullLoad: false,
        pdfDoc: null,
        loaded: false,
        totalPages: 0,
        fullscreen: false,
        images: [],
        docName: ''
    };

    _mounted = false;

    toggleFullscreen = () => this.setState({fullscreen: !this.state.fullscreen});

    onBlur = async () => {
        const updated = await api.documents.update(this.props.doc._id, { name: this.state.name })
        if(!updated.success) return toast.error(`Could not update document name, please try again.`)

        if(this.props.onNameUpdated) this.props.onNameUpdated(updated.data)
    }

    renderPDF = async (doc, cb) => {
        let data = this.props.data;

        if(!data) {
            const result = await Axios({
                method: 'get',
                url: keys.API_URL + `/v1/documents/${doc._id}/download`,
                responseType:'arraybuffer',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
            })

            data = result.data
            this.props.setData(data)
        }


        pdfjs.getDocument({data}).promise.then(async pdfDoc => {
            await this.renderPage(1, pdfDoc);
            this.setState({pdfDoc, totalPages: pdfDoc.numPages})
            cb(pdfDoc)
        }).catch(e => {
            this.setState({error: true})
        })
    }

    loadFullDocument = async () => {
        const { pdfDoc } = this.state;
        const totalPages = pdfDoc.numPages

        let images = [];
        let counter = 0;
        let pageNumber = 0;
        let awaitCounter = 0;

        while(counter < totalPages) {
            if(counter === 0) {
                images.push(this.state.images[0])
            } else {
                images.push('');
            }
            counter++;
        }

        this.setState({fullLoad: true, images})

         // for each page from the pdf render it as a canvas
         while(pageNumber < parseInt(totalPages)) {
            pageNumber++

            if(pageNumber === 1) continue;
            await this.renderPage(pageNumber);
            awaitCounter++;

            if(awaitCounter === totalPages) {
                const anchor = document.getElementById('canvas-anchor');
                if(anchor) anchor.innerHTML = '';
                pdfDoc.destroy()
            }
        }
    }

    renderPage = (pageNumber, pdfDoc) => new Promise (async resolve => {
        if(!pdfDoc) pdfDoc = this.state.pdfDoc;
        if(!pdfDoc) return;

        pdfDoc.getPage(pageNumber).then(async (page) => {

            const canvasID = `pdf-canvas-page-${pageNumber}`
            const container = document.getElementById('canvas-anchor')
            // component was closed, do nothing
            if(!container) return;
    
            const {  multiplier, finalWidth, finalHeight } = this.getDimensions(page)

            // createPageNumber({container, pageNumber, totalPages })
            this.createPageCanvas({container, pageNumber, canvasID, finalWidth, finalHeight })

            var canvas = document.getElementById(canvasID);
            var ctx = canvas.getContext('2d');
            
            // var viewport = page.getViewport({scale: multiplier});
            var viewport = page.getViewport({scale: multiplier * 2});

            canvas.height = finalHeight * 2;
            canvas.width = finalWidth * 2;
            canvas.style.transform = 'scale(.5)';
            canvas.style.position = 'absolute';
            canvas.style.top = -finalHeight / 2 + 'px';
            canvas.style.left = -finalWidth / 2 + 'px';

            // Render PDF page into canvas context
            page.render({ canvasContext: ctx, viewport: viewport }).promise.then(() => {


                const dataURL = canvas.toDataURL("image/svg",1);

                const images = [...this.state.images]
                images[pageNumber - 1] = dataURL;
                this.setState({images})

                resolve()
            }).catch(e => {
                this.setState({error: true})
            });


        }).catch(e => {
            this.setState({error: true})
        });

    })


    getDimensions = (page) => {
        const checkViewport = page.getViewport({scale: 1});
        const currentWidth = checkViewport.width
        const currentHeight = checkViewport.height

        const viewer = document.querySelector('.archk-pdf-viewer');
        const desiredWidth = viewer.offsetWidth

        const multiplier = desiredWidth / currentWidth
        const finalWidth = currentWidth * multiplier
        const finalHeight = currentHeight * multiplier

        return { multiplier, finalWidth, finalHeight }
    }

    createPageCanvas = (params) => {
        // 1 create a wrapper to store the canvas
        const wrapperID = params.canvasID + '-wrapper';

        var newWrapper = document.createElement('div')
        newWrapper.id = wrapperID;
        newWrapper.style.position = 'relative'

        newWrapper.setAttribute('data-pdf-page-wrapper', 'true')

        params.container.appendChild(newWrapper)

        const wrapperElement = document.getElementById(wrapperID)

        var newCanvas = document.createElement('canvas');
        
        // component was closed, do nothing
        if(!newCanvas) return;

        newCanvas.id = params.canvasID;
        newCanvas.style.width = document.querySelector('.archk-pdf-viewer').offsetWidth;
        newCanvas.style.height = 'auto';
    
        newCanvas.setAttribute('data-page', params.pageNumber)
        newCanvas.setAttribute('data-width', params.finalWidth)
        newCanvas.setAttribute('data-height', params.finalHeight)
        newCanvas.setAttribute('data-pdf-page', "true")

        wrapperElement.appendChild(newCanvas)
    }


    startRender = (props, tries = 0) => {
        // no doc, no preview
        const doc = props.doc;
        if(!doc) return;

        this.setState({ name: doc.name })

        // check to see if the canvas is within view
        const loaded = document.getElementById('canvas-anchor')

        // if modal has not dropped down yet do nothing
        // to prevent throwing an error
        if(!loaded) {
            // if its been 10 seconds stop trying to load the pdf
            if(tries > 10) return;
            // try again in 1 second to load the pdf
            return setTimeout(() => { this.renderPDF(this.props.doc, tries) }, 1000)
        }

        this.renderPDF(doc, () => {
            this.setState({loaded: true})
        })
    }

    componentDidUpdate = (prevProps) => {
        if(!prevProps.showModal && this.props.showModal) {
            this.startRender(this.props);
        } else if(prevProps.showModal && !this.props.showModal) {
            if(this.state.pdfDoc) this.state.pdfDoc.destroy()

            this.setState({
                fullLoad: false,
                pdfDoc: null,
                loaded: false,
                totalPages: 0,
                fullscreen: false,
                images: [],
            })   
        }
    }
    
    componentWillUnmount = () => {
        if(this.state.pdfDoc) this.state.pdfDoc.destroy()
    }

    componentDidMount = () => {
        if(this.props.showModal) this.startRender(this.props);
    }
    
    render() {

        const { pdfDoc, images, totalPages, fullLoad, fullscreen, error } = this.state;
        const { showModal, toggleModal, doc } = this.props;

        if(!showModal) return <></>;

        let className = 'modal-dialog-centered';
        if(fullscreen) className += ' fullscreen';

        return (
            <Modal
                className={className}
                isOpen={showModal}
                toggle={toggleModal}
                fade={false}
                size="lg"
                id="archk-pdf-modal"
            >
                <div className="modal-header">

                    <div className='pt-2 mb--2'>
                        <Input 
                            onBlur={this.onBlur}
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                    </div>

                    <button
                        id="archk-pdf-modal-fullscreen-toggle"
                        aria-label="Close"
                        className="close toggle-fullscreen"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleFullscreen}
                    >
                        <span aria-hidden={true}>
                            {fullscreen ? (
                                <i className="fas fa-compress " />
                            ) : (
                                <i className="fas fa-expand " />
                            )}
                        </span>
                    </button>

                    <UncontrolledTooltip delay={0} placement="bottom" target="archk-pdf-modal-fullscreen-toggle">
                        {fullscreen ? 'Minimize' : 'Fullscreen'}
                    </UncontrolledTooltip>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true} style={{fontSize: 32, position: 'relative', top: 5}}>×</span>
                    </button>
                </div>
        
                <div className="modal-body text-center bg-dark px-4 py-4">
                    <div className='px-2'>

                        {this.props.doc.ai_summary ? (
                            <div className='mb-4'>
                                <div className='text-left border p-3 rounded'>
                                    <p className='text-white mb-0 font-weight-bold'>SUMMARY</p>
                                    <p className='text-white text-sm mb-0'>{this.props.doc.ai_summary}</p>
                                </div>
                                <Transcription doc={doc} />
                            </div>
                        ) : ''}

                        {images && images.length ? images.map((img, i) => (
                            <div key={i + Math.floor(new Date() / 1)} >

                                <p className="text-right text-sm mb-1 text-muted">Page {i +1}/{totalPages} </p>

                                <div className="rounded z-depth-3 mb-4">
                                    {img ? (
                                        <img src={img} className="w-100 rounded" alt={`Page: ${i +1}/${totalPages}`}/>
                                    ) : (
                                        <div className="rounded z-depth-3 text-center py-9 bg-white">
                                            <i className="fas fa-file text-success " /><Circle />
                                        </div>
                                    )}
                                </div>
                                
                            </div>
                        )) :  error ? (
                            <div>
                                <p className="text-center lead text-warning font-weight-bold"><i className="fas fa-exclamation-triangle mr-2 " /> This file is unable to be read.</p>
                                <p className="text-center text-muted">This is most likely caused by uploading a file that is damaged or corrupted. You may try downloading it and checking if it will open normally on your computer.</p>
                            </div>
                        ) : <Circle />}


                        <div className="archk-pdf-viewer"  />
                        <div id="canvas-anchor" style={{display: 'none'}} />
            
                    </div>

                </div>
        
                {pdfDoc && !fullLoad ? (
                    <div className="modal-footer">
                        <button  id="archk-pdf-preview-back" onClick={this.loadFullDocument} className="btn btn-success" >
                            Load Full Document
                        </button>
                    </div>
                ) : null}
              
        
            </Modal>
        )
        
    }

}

PreviewPDF.propTypes = {
    doc: PropTypes.object.isRequired,
    maxNameLength: PropTypes.number,
}

export default PreviewPDF;