import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getUrlParameter, setUrlParameter } from 'utils/urls';

import api from 'api';

import ApiError from 'components/markup/layout/ApiError';
import HeaderTabs from 'components/markup/layout/HeaderTabs';

import List from './List';
import Step from './Step';
import Submissions from './Submissions';

const tabs = ['List', 'Step', 'Submissions']

const MatterViewMainDocuments = ({matter}) => {




    const [tab, setTab] = useState('Step')
    const [err, setErr] = useState(false)

    const [formAnswers, setFormAnswers] = useState(null)
    const [forms, setForms] = useState(null)

    const docs = matter.unfinished_forms.length

    const onSetTab = useCallback((_tab) => {
        setTab(_tab);
        setUrlParameter('docsTab', _tab)
    }, [])

    const fetchData = useCallback(async (obj) => {
        // setForms(null)
        // setFormAnswers(null)
        const forms = await api.forms.find(matter.division)
        if(forms.data) setForms(forms.data)

        const formAnswers = await api.form_answers.findByMatter(matter._id)
        if(formAnswers.data) setFormAnswers(formAnswers.data)
    }, [matter.division, matter._id])


    useEffect(() => {
        if(!formAnswers) fetchData()
    }, [formAnswers, fetchData])

    useEffect(() => {
        const t = getUrlParameter('docsTab')
        if(tabs.includes(t)) setTab(t)
    }, [])

    if(err) return <ApiError err={err} />

    return (
        <div className="section pt-0">

            <div className="section bg-white z-depth- border-top text-white pt-3 borer-top mt-0 pb-0 " style={{borderBottom: 'none'}}>
                <HeaderTabs 
                    className="mb-0"
                    tab={tab}
                    setTab={(tab) => onSetTab(tab)}
                    tabs={[
                        { value: 'Step', name: <span className="text-whit"><i className="fas fa-search " /> Step Forms {docs ? `(${docs})` : ''}</span> },
                        { value: 'List', name: <span className="text-whit"><i className="fas fa-list pl-1 " /> All Forms</span> },
                        { value: 'Submissions', name: <span className="text-whit"><i className="fas fa-file pl-1 " /> Submissions</span> },
                    ]}
                />
            </div>

            {tab === 'List' ? (
                <List 
                    formAnswers={formAnswers}
                    forms={forms}
                    fetchData={fetchData}
                />
            ) : tab === 'Step' ? (
                <Step 
                    formAnswers={formAnswers}
                    forms={forms}
                    fetchData={fetchData}
                />
            ) : tab === 'Submissions' ? (
                <Submissions />
            ) : ''}

        </div>

    );

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocuments);