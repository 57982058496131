
import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Alert, Card, Container, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";

import api from 'api';
import Table from 'components/functional/tables/Standard';
import moment from 'moment';

import { onDownloadDocument } from 'utils/documents';

import ImageRenderer from 'components/functional/images/ImageRenderer';

import TableIcons from 'components/markup/icons/TableIcons';

import ObjectFinder from 'components/system/Objects/Finder';
import prettyBytes from 'pretty-bytes';
import { openDocumentCenter } from 'store/functions/document_center';
import { toast } from 'react-toastify'

import ConfirmationModal from 'components/functional/modals/Confirmation'
import Circle from 'components/markup/loading/Circle'

import ModalToggler from 'components/functional/modals/Toggler'

import formatText from 'utils/formatText'

import ActionsMerge from '../actions/Merge'
import ActionsUpdateCategory from '../actions/UpdateCategory'
import ActionsSetPinned from '../actions/SetPinned'

const DocumentName = ({ row }) => {

    const [d, setD] = useState(row);

    return (
        <span>
            <ImageRenderer id={`archk-doc-list-id-${row._id}`} doc={d} onNameUpdated={(d) => setD(d)}>
                <div>
                    <div>{d.name}</div>
                    <div>{moment.unix(d.created_at).format('MMM Do, YYYY h:mm A')}</div>
                </div>
            </ImageRenderer>
        </span>
    )
}

const DocumentsAll = ({matter, pinned}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ checked, setChecked ] = useState([])
    
    const [ showMergeModal, setShowMergeModal ] = useState(false)
    const [ showAddToCategory, setShowAddToCategory ] = useState(false)
    const [ showPinned, setShowPinned ] = useState(false)

    const onDelete = useCallback(async (_id) => {
        setLoading(true);
        const deleted = await api.documents.delete(_id);
        if(!deleted.success) return toast.error(`Something went wrong deleting this document, please try again.`)
        setLoading(false);
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }

        const filter = { matter: matter._id, deleted: false } 
        if(pinned) filter.pinned = pinned

        const query = await api.documents.search({ ...params, isCSV, filter})
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onSetChecked = useCallback((row) => {
        let _checked = JSON.parse(JSON.stringify(checked))
        if(checked.some(i => i._id === row._id)) {
            _checked = _checked.filter(i => i._id !== row._id)
        } else {
            _checked.push(row)
        }
        setChecked(_checked)
    }, [checked])

    const reload = useCallback(() => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false)
        }, [250])
    }, [])

    useEffect(() => {
        reload()
    }, [pinned, reload])

    const columns =[
        {
            dataField: "name",
            headerStyle: {width: 30},
            text: "",
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="position-relative" style={{width: 10}}>
                    <div className="custom-control custom-checkbox pl-0" style={{position: 'absolute', right: -20, top: -5}}>
                        <input
                            className="custom-control-input"
                            id={`archk-document-check-${row._id}`}
                            type="checkbox"
                            checked={checked.some(i => i._id === row._id)}
                            onChange={(e) => onSetChecked(row)}
                            style={{width: 0}}
                        />
                        <label className="custom-control-label" htmlFor={`archk-document-check-${row._id}`} />
                    </div>
                </div>

                // <button onClick={() => onSetChecked(row._id)}>Check</button>
            )
        },
        {
            dataField: "name",
            headerStyle: {width: 250},
            text: "name",
            formatter: (cell, row) => row.name === 'no data' ? pinned ? 'No Document Pinned' : 'No Documents Uploaded' : (
                <DocumentName row={row} />
            )
        },
       
        {
            dataField: "mimetype",
            text: "Type",
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div className="text-uppercase">
                        <div><span>type</span> {formatText(row.mimetype).mimetype()}</div>
                        <div><span>size:</span> {prettyBytes(row.size)}</div>
                    </div>
                )
            )
        },
        {
            dataField: "uploaded_by",
            text: "Uploaded By",
            formatter: (cell, row) => (
                <div >
                    {row.name === 'no data' ? '' : !row.uploaded_by  ? 'System' : (
                        <div>
                            {
                                row.upload_type === 'user' ? <ObjectFinder collection="users" _id={row.uploaded_by}  />  :
                                row.upload_type === 'contact' ? <ObjectFinder collection="contacts" _id={row.uploaded_by}  />  :
                                ''
                            }
                        </div>
                    )}
                </div>
            )
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right'},
            text: "actions",
            headerFormatter: () => {
                return (
                    <div>
                        <button style={{width: 84}} className="btn btn-sm btn-outline-success" onClick={() => openDocumentCenter({matter: matter._id})}>
                            <i className="fas fa-upload mr-2 " /> Add
                        </button>
                    </div>
                )
            },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div className="text-right">
                    <TableIcons
                        icons={[
                            { 
                                icon: 'fas fa-search',
                                color: 'warning', 
                                onClick: () => {
                                    const el = document.getElementById(`archk-doc-list-id-${row._id}`)
                                    if(el) el.click();
                                }
                            },
                            {  
                                icon: 'fas fa-download', 
                                color: 'info',
                                onClick: () => onDownloadDocument(row._id) 
                            },
                            { 
                                icon: 'fas fa-trash',
                                color: 'danger',
                                wrapper: ModalToggler,
                                component: ConfirmationModal,
                                title: 'Delete Document',
                                onConfirmation: () => onDelete(row._id),
                                body: (
                                    <span>
                                        Are you sure you wish to delete the document:{' '}
                                        <b className="text-danger text-underline">{row.name}</b><br /> 
                                        This cannot be undone.
                                    </span>
                                )
                            },
                        ]}
                    />
                </div>

            )
        },
    ]



    if(error) return (
        <Container fluid >
            <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
        </Container>
    )

    return (

        <>

            <Card className="card-color card-primary card-no-shadow">    
                {loading ? <Circle /> : (
                    <Table
                        placeholder="Search Documents..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                        actionComponent={(
                            <UncontrolledDropdown>
                                <DropdownToggle caret color={`${!checked.length ? 'outline-info' : 'info'} btn-sm`} disabled={!checked.length} >
                                    Actions
                                </DropdownToggle>


                                <DropdownMenu style={{margin: 0}}>
                                    <DropdownItem onClick={e => setShowMergeModal(true)}>
                                        Merge Documents
                                    </DropdownItem>
                                    <DropdownItem onClick={e => setShowPinned(true)}>
                                        Pin Documents
                                    </DropdownItem>
                                    <DropdownItem onClick={e => setShowAddToCategory(true)}>
                                        Add To Document Category
                                    </DropdownItem>
                                   
                                </DropdownMenu>

                            </UncontrolledDropdown>
                          
                        )}
                    />
                )}
            </Card>

            <ActionsMerge 
                showModal={showMergeModal}
                toggleModal={() => setShowMergeModal(false)}
                documents={checked}
            />

            <ActionsUpdateCategory 
                showModal={showAddToCategory}
                toggleModal={() => setShowAddToCategory(false)}
                documents={checked}
                onSuccess={() => setChecked([])}
                type="push"
            />
            
            <ActionsSetPinned 
                showModal={showPinned}
                toggleModal={() => setShowPinned(false)}
                documents={checked}
                onSuccess={() => {
                    setChecked([])
                    reload()
                }}
            />

        </>
    )

}

const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(DocumentsAll);
