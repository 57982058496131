import { FormGroup, Input, Row, Col } from "reactstrap";
import States from 'components/markup/inputs/States'

const ContactsUpdate = ({onInputChange, contact}) => {
    return (
        <div>
        
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 1</label>
                        <Input 
                            type="text"
                            value={contact.address_line_1}
                            onChange={e => onInputChange('address_line_1', e.target.value)}
                        />
                    </FormGroup>    
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">Address Line 2</label>
                        <Input 
                            type="text"
                            value={contact.address_line_2}
                            onChange={e => onInputChange('address_line_2', e.target.value)}
                        />
                    </FormGroup>    
                </Col>
            </Row>

            <Row>
                <Col lg={4}>
                    <FormGroup>
                        <label className="form-control-label">City</label>
                        <Input 
                            type="text"
                            value={contact.city}
                            onChange={e => onInputChange('city', e.target.value)}
                        />
                    </FormGroup>    
                </Col>
                <Col lg={4}>
                    <States 
                        title="State"
                        value={contact.state}
                        onChange={(val) => onInputChange('state', val)}
                    /> 
                </Col>
                <Col lg={4}>
                    <FormGroup>
                        <label className="form-control-label">Zip</label>
                        <Input 
                            type="text"
                            value={contact.postal_code}
                            onChange={e => onInputChange('postal_code', e.target.value)}
                        />
                    </FormGroup>    
                </Col>
            </Row>
        </div>
    )

}

export default ContactsUpdate;