import React, { useEffect, useCallback, useState } from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch, useLocation } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar/index.js";
import Notifications from "./components/Notifications/index.js";
import routes from "./routes";

import { setCompany } from 'store/functions/company/company'
import { setConfig } from 'store/functions/config/config'
import { setSelectedDivision } from 'store/functions/state'

import ArchkTutorial from 'components/functional/tutorial'
import ArchkAlerts from 'components/functional/alerts'

import setDivisions from 'store/functions/divisions'


import OnCall from 'components/system/OnCall';
import Upload from 'components/system/Upload';

import Sidebar from './components/Sidebar'

const Dashboard = (props) => {
    const { company } = props;

    const location = useLocation();

    const [loaded, setLoaded] = useState(false);
    const [sidenavOpen, setSidenavOpen] = useState(false)


    //get routes for this layout
    const getRoutes = routes => routes().map((prop, key) => {

        return (
            <Route
                exact path={`${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })


    const toggleSidenav = useCallback(() => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("noScroll");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");   
            document.body.classList.add("noScroll");
        }

        setSidenavOpen(!sidenavOpen)
    }, [sidenavOpen])
   
    const loadData = useCallback(async () => {
        if(!company._id) {
            await setConfig()

            await Promise.all([
                setCompany(),
                setDivisions(),
            ])
            setSelectedDivision();
        }

        setLoaded(true)
    }, [company._id])

    useEffect(() => {
        loadData()
    }, [loadData])

    useEffect(() => {
        document.body.classList.remove("g-sidenav-show");
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.remove("noScroll");
        setSidenavOpen(false)
    }, [location.pathname])


    if(!loaded) return <div />;

    return (
        <div className="archk-sidebar-left">

            {/* <DetectChange onCloseNav={() => this.setState({ sidenavOpen: false })} /> */}

            <ArchkAlerts />

            <Sidebar sidenavOpen={sidenavOpen} toggleSidenav={toggleSidenav} />

            {/* <div className={`bg-toggler ${device.currentScreenWidth < 1000 && sidenavOpen ? 'show' : null}`} onClick={toggleSidenav}/> */}

            <div className="wave" />

            <div className="main-content content-wrapper" >

                <Navbar {...props} toggleSidenav={toggleSidenav} />

                <Notifications />
                <Upload />

                <div className="dashboard-content">
                    <Switch>
                        {getRoutes(routes)}
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>
                
            </div>
            
            <OnCall />
            
        </div>

    );

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	    company: state.company,
	    tutorial: state.tutorial.tutorial,
	    email_editors: state.state.email_editors,
	    load: state.load,
	};
};

export default connect(mapStateToProps, '')(Dashboard);

