/*
Documentation

Renders the keypad a user can control on a cal

*/

import PropTypes from 'prop-types'
import React, { useState, useEffect,  useCallback } from 'react';
import { Device } from 'twilio-client';

const KeypadOnCall = ({showKeypad, toggleKeypad}) => {

    const [number, setNumber] = useState('')

    // adds number by button click
    const addNumber = useCallback((_number) => {
        const activeConnection = Device.activeConnection()

        if(activeConnection) {
            
            activeConnection.sendDigits(_number);
            
            let newNumber = number ? number + _number : _number
            setNumber(newNumber)

        }
    }, [number])

    useEffect(() => {
        if(!showKeypad) setNumber('')
    }, [showKeypad])

    if(!showKeypad) return <></>

    return (

        <div className="keypad">

            <div className="keypad-background" onClick={toggleKeypad} />

            <div className="keypad-content rounded">


                    <div className="header">{number ? number : <span className='text-muted'>Click To Dial</span>}</div>


                <span className="block first" onClick={() => addNumber('1')}>
                    1 <span className="letters placeholder">-</span>
                </span>

                <span className="block second" onClick={() => addNumber('2')}>
                    2 <span className="letters">ABC</span>
                </span>

                <span className="block third" onClick={() => addNumber('3')}>
                    3 <span className="letters">DEF</span>
                </span>

                <span className="block first" onClick={() => addNumber('4')}>
                    4 <span className="letters">GHI</span>
                </span>

                <span className="block second" onClick={() => addNumber('5')}>
                    5 <span className="letters">JKL</span>
                </span>

                <span className="block third" onClick={() => addNumber('6')}>
                    6 <span className="letters">MNO</span>
                </span>

                <span className="block first" onClick={() => addNumber('7')}>
                    7 <span className="letters">PQRS</span>
                </span>

                <span className="block second" onClick={() => addNumber('8')}>
                    8 <span className="letters">TUV</span>
                </span>

                <span className="block third" onClick={() => addNumber('9')}>
                    9 <span className="letters">WXYZ</span>
                </span>

                <span className="block first last" onClick={() => addNumber('*')}>
                    * <span className="letters text-white">.</span>
                </span> 

                <span className="block second last" onClick={() => addNumber('0')}>
                    0 <span className="letters last">+</span>
                </span>

                <span className="block third last" onClick={() => addNumber('#')}>
                    #  <span className="letters text-white">.</span>
                </span>

            </div>
        </div>

    )

}

KeypadOnCall.propTypes = {
    showKeypad:      PropTypes.bool.isRequired,
    toggleKeypad:   PropTypes.func.isRequired
}

export default KeypadOnCall
