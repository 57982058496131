


import { useCallback, useState } from 'react';

import { Input } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';

import SelectTemplate from './SelectTemplate'

import ModalToggler from 'components/functional/modals/Toggler'


const TextsSend = ({thread, to}) => {

    const [disabled, setDisabled] = useState(false)
    const [bodies, setBodies] = useState({})
    const body = bodies[thread ? thread.thread._id : '']


    const onSetBodies = useCallback((value) => {
        const _bodies = JSON.parse(JSON.stringify(bodies));
        _bodies[thread.thread._id] = value;
        setBodies(_bodies)
    }, [bodies, thread])

    const onSelectTemplate = useCallback(async (template, matter) => {

        const value = await api.inject_values.string({
            string: template.body,
            contact: thread.contact._id,
            matter: matter,
            hideErrors: true
        })

        if(!value.data) return toast.info(`The template you have selected cannot be used at this time.`)
        
        onSetBodies(value.data)

    }, [thread, onSetBodies])

    const onSent = useCallback(async () => {
        if(!body) return;
        setDisabled(true)
        const sent = await api.texts.send({
            contact     : thread.contact._id,
            division    : thread.thread.division,
            to          : to,
            body        : body,
        })

        setDisabled(false)

        if(!sent.success) return toast.info(`Your text message could not be sent, please try again.`)
        onSetBodies('')

    }, [thread, body, to, onSetBodies])
    
      //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') onSent()
	}, [onSent])

    return (

        
        <div className="archk-messaging-send">
            <div className="archk-messaging-send-wrapper">
                <Input 
                    type="text"
                    placeholder="Type Message..."
                    value={body || ''}
                    onChange={e => onSetBodies(e.target.value)}
                    onKeyDown={_handleKeyDown}
                />      
                
                <ModalToggler component={SelectTemplate} matters={thread ? thread.matters : []} onSelect={onSelectTemplate}>
                    <i className="archk-messaging-template fas fa-edit" />
                </ModalToggler>

                {/* <i className="archk-messaging-send-attach fas fa-refresh" /> */}
                <i className="archk-messaging-send-attach fas fa-paperclip" />

                <button disabled={disabled} className="btn btn-info" onClick={onSent}><i className="fas fa-paper-plane" /></button>
            </div>
        </div>
    )

}

export default TextsSend