import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, FormGroup, Input } from 'reactstrap';





const ModuleBKOverview = ({ data, onInputChange }) => {
    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Review Notes</CardTitle>
                </CardHeader>

                <CardFooter>
                    <FormGroup>
                        <label className='form-control-label'>Paralegal Review Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 120}}
                            value={data.ch_13_paralegal_review_notes}
                            onChange={e => onInputChange('ch_13_paralegal_review_notes', e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

                <CardFooter>
                    <FormGroup>
                        <label className='form-control-label'>Attorney Review Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 120}}
                            value={data.ch_13_attorney_review_notes}
                            onChange={e => onInputChange('ch_13_attorney_review_notes', e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


