import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal, Row, Col } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";
import DatePicker from 'components/markup/inputs/Day';
import SearchCreditors from '../../Creditors/Search';

import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';

const ModalGarnishmentAdd = ({showModal, toggleModal, matter, onSuccess, fetchInfo}) => {

    const [name, setName] = useState('')
    const [creditor, setCreditor] = useState('')
    const [severity, setSeverity] = useState(1)
    const [objectionNumber, setObjectionNumber] = useState(1)
    const [dateFiled, setDateFiled] = useState('')
    const [opposingParty, setOpposingParty] = useState('')

    const onSave = useCallback(async () => {
        if(!name) return toast.info(`You must set a name before creating a objection.`)
        if(!dateFiled) return toast.info(`You must set a date filed before creating an objection.`)

        const result = await api.modules.bk.objections.create({ 
            name, 
            severity,
            objection_number: objectionNumber,
            date_filed: dateFiled,
            opposing_party: opposingParty ? opposingParty : undefined,
            creditor: creditor ? creditor : undefined,
            matter: matter._id 
        })
        if(!result.success) return toast.error(`Something went wrong creating this objection, please try again.`);

        toast.success(`Objection Created Successfully`)
        if(onSuccess) onSuccess(result.data);

        fetchInfo()
        setName('')
        toggleModal()

    }, [creditor, dateFiled, fetchInfo, matter._id, name, objectionNumber, onSuccess, opposingParty, severity, toggleModal])

    useEffect(() => {
        if(showModal) {
            setName('')
            setSeverity(1)
            setObjectionNumber(1)
            setDateFiled('')
            setOpposingParty('')
            setCreditor('')
        }
    }, [showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Objection</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div>

                <div className="modal-body border-bottom">

                    <FormGroup>
                        <label className='form-control-label'>Objection Name</label>
                        <Input 
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>

                    
                    <SearchCreditors 
                        title="Associated Creditor"
                        value={creditor}
                        onChange={(obj) => setCreditor(obj.value)}
                    />

                    <DatePicker 
                        title={`Objection Filed Date`}
                        value={dateFiled ? moment(dateFiled).toDate() : ''} 
                        onChange={day => setDateFiled(day)} 
                    />

                    <Row>
                        <Col md={6}>
                            <ReactSelect
                                title="Severity"
                                formGroup={true}
                                onChange={(obj) => setSeverity(obj.value)}
                                options={[
                                    { value: 1, label: 'Normal' },
                                    { value: 2, label: 'Important' },
                                    { value: 3, label: 'Severe' },
                                ]}
                                value={severity}
                            />    
                        </Col>
                        <Col md={6}>
                            <ReactSelect
                                title="Objection Number"
                                formGroup={true}
                                onChange={(obj) => setObjectionNumber(obj.value)}
                                options={[
                                    { value: 1, label: 1 },
                                    { value: 2, label: 2 },
                                    { value: 3, label: 3 },
                                    { value: 4, label: 4 },
                                    { value: 5, label: 5 },
                                    { value: 6, label: 6 },
                                    { value: 7, label: 7 },
                                    { value: 8, label: 8 },
                                    { value: 9, label: 9 },
                                    { value: 10, label: 10 },
                                    { value: 11, label: 11},
                                    { value: 12, label: 12},
                                    { value: 13, label: 13},
                                    { value: 14, label: 14},
                                    { value: 15, label: 15},
                                    { value: 16, label: 16},
                                    { value: 17, label: 17},
                                    { value: 18, label: 18},
                                    { value: 19, label: 19},
                                    { value: 20, label: 20},
                                ]}
                                value={objectionNumber}
                            />    
                        </Col>
                    </Row>

                  

                    <SearchCollections
                        collection={'contacts'}
                        title="Opposing Party"
                        placeholder="Search Contacts..."
                        value={opposingParty}
                        onChange={(obj) => setOpposingParty(obj.value)}
                        filter={{}}
                    /> 

                </div>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>

                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Add Objection
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalGarnishmentAdd);