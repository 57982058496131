import { DropdownToggle, UncontrolledDropdown } from "reactstrap";

const HeaderTabs = ({tab, setTab, tabs, className}) => (
    <div className={`archk-navigation ${className ? className : 'mb-4 '}`}>
        {tabs && tabs.length ? (
            <ul className="pl-0 mb-0">
                {tabs.map((t, i) => (
                    <li 
                        key={i}
                        onClick={t.dropdown ? null : () => setTab(t.value)} 
                        className={`d-inline-block ${t.dropdown ? 'is-dropdown' : ''} ${tab === t.value ? 'active' : ''} ${t.className}`}
                    >

                        {!t.dropdown ? t.name : (
                            <UncontrolledDropdown>
                                <DropdownToggle caret color="outline-info btn-sm">
                                {t.name}
                                </DropdownToggle>
                                {t.menu}
                            </UncontrolledDropdown>
                        )}
                    </li>
                ))}
            </ul>

        ) : null}
    </div>
)

export default HeaderTabs;