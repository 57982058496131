
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { useState } from 'react'

import moment from 'moment';

import ObjectFinder from 'components/system/Objects/Finder';

import HistoryMarkup from './HistoryMarkup';

const EventsEditHistory = ({ finished, history, foundEvent }) => {

    const [showHistory, setShowHistory] = useState(false)

    return (
        <div className='my-3'>
    
            <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>Event Info</CardTitle>
                </CardHeader>
                <div className='table-responsive'>
                    <table className='table'>
    
                        <tbody>
                            <tr>
                                <td style={{width: 150}}>Name</td>
                                <td>{finished.name}</td>
                            </tr>
                            
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Outcome</td>
                                <td>{finished.outcome ? <ObjectFinder collection="event_outcomes" _id={finished.outcome} /> : <span className='text-warning'>Not Set</span>}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Matter</td>
                                <td>{finished.matter ? <ObjectFinder collection="matters" _id={finished.matter} /> : ''}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Users</td>
                                <td>{finished.users.length ? finished.users.map((c, i) => (
                                    <span>{i !== 0 ? ', ' : ''} <ObjectFinder collection="users" _id={c} /></span>
                                )) : '-'}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Contacts</td>
                                <td>{finished.contacts.length ? finished.contacts.map((c, i) => (
                                    <span>{i !== 0 ? ', ' : ''} <ObjectFinder collection="contacts" _id={c} /></span>
                                )) : '-'}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Start & End</td>
                                <td>{moment.unix(finished.unix_start).format('MMM Do, YYYY h:mm')} - {moment.unix(finished.unix_end).format('h:mm A')}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Duration</td>
                                <td>{(finished.unix_end - finished.unix_start) / 60} Minutes</td>
                            </tr>
                          
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Created By</td>
                                <td>{finished.created_by ? <ObjectFinder collection="users" _id={finished.created_by} /> : 'System'}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Created At</td>
                                <td>{moment.unix(finished.created_at).format('MMM Do, YYYY h:mm A')}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Finished By</td>
                                <td>{finished.finished_by ? <ObjectFinder collection="users" _id={finished.finished_by} /> : 'System'}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Finished At</td>
                                <td>{moment.unix(finished.finished_at).format('MMM Do, YYYY h:mm A')}</td>
                            </tr>
                          
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Location</td>
                                <td>{finished.location ? <ObjectFinder collection="locations" _id={finished.location} /> : ''}</td>
                            </tr>
                           
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Invitation Sent From</td>
                                <td>{finished.send_invites_from ? <ObjectFinder collection="users" _id={finished.send_invites_from} /> : ''}</td>
                            </tr>
    
                            <tr style={styles.firstColumn}>
                                <td className="font-weight-bold text-dark">Description</td>
                                <td>{finished.description}</td>
                            </tr>
    
                        </tbody>
                    </table>
                </div>
            </Card>

            {showHistory ? (
                <Card>
                    <CardHeader>
                        <CardTitle className='mb-0'>Event History</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <HistoryMarkup foundEvent={foundEvent} history={history} />
                    </CardBody>
                </Card>
            ) : (
                <p onClick={() => setShowHistory(true)} className='text-center text-underline mb-0 cursor-pointer'>
                    <i className="fas fa-info-circle mr-2 text-info" /> Click here to view the Event History
                </p>
            )}
           
    
    
    
            {/* <button disabled={saving} onClick={() => setShowHistory(!showHistory)} className="btn btn-outline-info"><i className="fas fa-arrow-left mr-2" /> Update Event</button>  */}
        </div>
    
    )
}

const styles = {
    firstColumn: { width: 150 }
}

export default EventsEditHistory