import { connect } from 'react-redux';
import { useState, useCallback, useEffect } from 'react'
import { Modal, FormGroup, Input } from "reactstrap";

import { toast } from 'react-toastify'

import api from 'api';

const MatterAlertsModal = ({showModal, toggleModal, matter}) => {

    const [body, setBody] = useState('')

    const onSave = useCallback(async () => {
        if(!body) return;
        const created = await api.matters.alertAdd({matter: matter._id, body})
        if(!created.success) return toast.error(`Something's not right, please try again`)

        toggleModal()
    }, [matter._id, body, toggleModal])

    useEffect(() => {
        if(!showModal) setBody('')
    }, [showModal])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

           <div className="modal-header">
               <h5 className="modal-title">Add A Case Alert</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body">

                <FormGroup>
                    <label className='form-control-label'>Alert Body</label>
                    <Input 
                        type="textarea"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                    />
                </FormGroup>

           </div>

           <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> Cancel
                </button>
                <button className="btn btn-success" onClick={onSave}>
                    Create Alert
                </button>
           </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(MatterAlertsModal);