import ContactsEdit from 'views/dashboard/pages/contacts/components/Edit'

const ContactsViewUpdate = ({contact, onSaved}) => (
    <div>
        <ContactsEdit 
            contact_id={contact._id} 
            onSaved={onSaved}
        />
    </div>
)

export default ContactsViewUpdate