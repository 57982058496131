/*
address
property type (single family, duplex, etc.)
property nature of interest
description
other info
ownership (d1, d2, d1 & 2)

current value
current_value_type (unknown, partial interest)

amount of secured claim (unknown / linked to liens)
amount of secured claim type (unkown, known, linked to liens)

year
make
model
mileage

name of institution
Type of account ('checking savings, certificates o deposit, ira)
percent Ownership

beneficiary



*/



import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalDivisionOverride = ({obj, field, onInputChange}) => {

    const investment = `${field}_is_investment`;
    const timeshare = `${field}_is_timeshare`;

    return (

        <div>

            <ReactSelect
                title="What Is The Property Type"
                formGroup={true}
                onChange={(obj) => onInputChange(field, obj.value)}
                options={[
                    { value: 'single_family', label: 'Single Family Home' },
                    { value: 'duplex_or_multi', label: 'Duplex Or Multi Unit Building' },
                    { value: 'condominium_or_cooperative', label: 'Condominium Or Cooperative' },
                    { value: 'manufactured_or_mobile', label: 'Manufactured Or Mobile Home' },
                    { value: 'land', label: 'Land' },
                    { value: 'other', label: 'Other' },
                ]}
                value={obj[field]}
            />    

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-${field}-is-investment`}
                        type="checkbox"
                        checked={obj[investment] || false}
                        onChange={() => onInputChange(investment, !obj[investment])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-${field}-is-investment`}>
                        Is this an investment property?
                    </label>
                </div>
            </FormGroup>

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-${field}-is-timeshare`}
                        type="checkbox"
                        checked={obj[timeshare] || false}
                        onChange={() => onInputChange(timeshare, !obj[timeshare])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-${field}-is-timeshare`}>
                        Is this property a timeshare?
                    </label>
                </div>
            </FormGroup>


        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);