import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';

import { Alert, Card, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import api from 'api';
import Table from 'components/functional/tables/Standard';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';
import moment from 'moment';

import ObjectFinder from 'components/system/Objects/Finder';
import { toast } from 'react-toastify';

import ReactHTMLParser from 'html-react-parser';

const NotificationsAll = () => {

    const [ loading, setLoading ] = useState(false);
    const [ category, setCategory ] = useState(null);
    const [ read, setRead ] = useState('unread');
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: null, total_documents: 0 })
    const [ error, setError ] = useState(false)

    const toggleSeen = async (row) => {
        const now = Math.floor(new Date() / 1000);
        const read_at = row.read_at ? 0 : now;
        const last_read_at = row.read_at;

        const newState = JSON.parse(JSON.stringify(tableData.data));
        const notification = newState.find(d => d._id === row._id);
        notification.read_at = read_at

        // update table row
        setTableData({...tableData, data: newState})

        const updated = await api.notifications.update(row._id, { read_at })

        // if we have an error revert back the row display here
        if(!updated.success) {
            notification.read_at = last_read_at;
            toast.error(`Something went wrong updating this notification.`)
            setTableData({...tableData, data: newState})
        }
    }

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage, filter: {} }
        if(!params.filter) params.filter = {}
        if(category) params.filter.category = category
        if(read === 'read') params.filter.read_at = { $gt: 0 }
        if(read === 'unread') params.filter.read_at = 0

        const query = await api.notifications.search({ ...params, isCSV })

        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

   

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onSetFilter = useCallback((field, value) => {
        if(field === 'read') {
            setRead(value)
        } else {
            setCategory(value)
        }

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 300)
    }, [])

    const columns = [
        {
            dataField: "_id",
            text: "Date",
            headerStyle: { width: 100 },
            formatExtraData: {
                toggleSeen
            },
            formatter: (cell, row) => row.name === 'no data' ? 'No Notifications Found' : (
                <div>
                    <div>{moment.unix(row.created_at).format('MM/DD/YYYY')}</div>
                    <div>{moment.unix(row.created_at).format('h:mm A')}</div>
                </div>
            )

        },
        {
            dataField: "category",
            text: "Category & Division",
            headerStyle: { width: 200 },
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <div><span className="text-capitalize">{row.category.replace('_', ' ')}</span></div>
                    <div><ObjectFinder collection="divisions" _id={row.division} /></div>
                </div>
            )
        },
        {
            dataField: "body",
            text: "Notification",
            formatter: (cell, row) => {
                return <div>{row.body ? ReactHTMLParser(row.body) : ''}</div>
            }
        },
        {
            dataField: "created_at",
            text: "Actions",
            isDummyField: true,
            headerStyle: {textAlign: 'right'},
            formatExtraData: {
                toggleSeen
            },
            formatter: (cell, row, rowIndex, formatter) => (
                <div className="text-right">
                    {row.read_at ? (
                          <button 
                            className="btn btn-outline-warning btn-sm" 
                            onClick={() => formatter.toggleSeen(row)}
                            style={{minWidth: 120}}
                        >
                            Mark Unread
                        </button>
                    ) : (
                      
                     <button 
                        className="btn btn-success btn-sm" 
                        onClick={() => formatter.toggleSeen(row)}
                        style={{minWidth: 120}}
                    >
                        Mark Read 
                    </button>
                    )}

                </div>
            )
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (
        <>
    
            <Helmet>
                <title>{`All Notifications`}</title>
                <meta name="description" content="Notifications" />
            </Helmet>

            <HeaderNavigation 
                title={"Notifications"}
                description="Last 30 Days"
                leftColSize={5}
                actionComponent={(
                    <div >
                        <UncontrolledDropdown>
                            <DropdownToggle color={category ? 'info' : 'outline-info'} style={{width: 150}}>
                                 {category ? <span>{category}<i className="fas fa-sort-amount-down ml-2" /></span> : 'All Categories'}
                            </DropdownToggle>

                            <DropdownMenu>
                                <DropdownItem onClick={e => onSetFilter('category', null)}>All</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'step_moved')}>Step Moved</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'document_uploaded')}>Document Uploaded</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'document_expired')}>Document Expired</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'document_category_updated')}>Document Category Updated</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'incoming_call')}>Incoming Call</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'incoming_text')}>Incoming Text</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'outgoing_call')}>Outgoing Call</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'outgoing_text')}>Outgoing Text</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'document_signed')}>Document Signed</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'document_sent')}>Document Sent</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'task_completed')}>Task Completed</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'form_completed')}>Form Completed</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('category', 'system')}>System</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                       
                        <UncontrolledDropdown>
                            <DropdownToggle color={read ? 'warning' : 'outline-warning'} style={{width: 150}}>
                                {read !== null ? <span>{read}<i className="fas fa-sort-amount-down ml-2" /></span> : 'All Notifications'}
                                {/* Unread  <i className="fas fa-sort-amount-down ml-2" /> */}
                            </DropdownToggle>

                            <DropdownMenu>
                                <DropdownItem onClick={e => onSetFilter('read', 'read')}>Read</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('read', 'unread')}>Unread</DropdownItem>
                                <DropdownItem onClick={e => onSetFilter('read', null)}>All</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
    
                    </div>
                )}
            />
    
            <Container fluid>
                <Card className="card-color card-primary">    
                    {loading ? <Circle /> : (
                        <Table
                            placeholder="Notifications"
                            hideSearch={true}
                            query={query}
                            columns={columns}
                            data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                            totalDocuments={tableData.total_documents}
                            sizePerPage={sizePerPage}
                            onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                            
                            rowClasses={(row, rowIndex) => {
                                return row.read_at ? 'bg-secondary text-muted' : ' bg-white text-dark';
                            }}
                        />
                    )}
                </Card>

            </Container>
    
        </>
    )

}

export default NotificationsAll