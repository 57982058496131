import { useCallback, useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';


import New from './New';
import Search from './Search';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import InternalSidebar from 'components/markup/layout/InternalSidebar';

const AccountSettings = () => {

    const [tab, setTab] = useState(
        window.location.href.includes('pending') ? 0 :
        window.location.href.includes('all') ? 1 :
        window.location.href.includes('request') ? 2 :
        0
    )

    const onSetTab = useCallback((_tab) => {
        if(_tab === 0) {
            window.history.pushState(null, null, '/time_off/pending');
        } else if(_tab === 1) {
            window.history.pushState(null, null, '/time_off/all');
        } else {
            window.history.pushState(null, null, '/time_off/request');
        }
        setTab(_tab)
    }, [])

    return (

        <div>
             <Helmet>
                <title>Settings</title>
                <meta name="description" content="Settings" />
            </Helmet>

            <HeaderNavigation 
                title="My Account"
                // description="Settings"
                actionComponent={(
                    <div>
                        {/* <Link to="/clients" className="btn btn-info"><i className="fas fa-search mr-2 " /> Search Clients</Link>
                        <Link to="/matters/create" className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</Link> */}
                    </div>
                )}
            />

            {/* <Time time={Math.floor(new Date() / 1000)} format="MM/DD/YYYY hh:mm A" /> */}

            <InternalSidebar 
                tab={tab}
                onClick={(route, tab) => onSetTab(tab)}
                routes={[
                    {name: 'Pending Requests'},
                    {name: 'All Requests'},
                    {name: 'New Request'},
                ]}
            >
                {tab === 0 ? (
                    <Search status="pending" />
                ) : tab === 1 ? (
                    <Search/>
                ) : tab === 2 ? (
                    <New setTab={setTab} />
                ) : null}
            </InternalSidebar>
            
        </div>
    )

}

const mapStateToProps = state => {
    return {
        config: state.config,
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);