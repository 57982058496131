import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';

import Dots from 'components/markup/loading/Dots';

const ModalActionsMerge = ({showModal, toggleModal, matter, documents, onSuccess}) => {

    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('')
    const [running, setRunning] = useState(false)
    const [err, setErr] = useState(false)
    const [success, setSuccess] = useState(false)

    const onSave = useCallback(async () => {
        if(!name) return toast.info(`You must enter a name for the documents you with to merge.`)

        setRunning(true)
        toast.info(`Your document merge has started.`)
        const merged = await api._ai.documents.merge({
            matter: matter._id,
            name,
            prompt,
            documents: documents.map(d => d._id)
        })
        setRunning(false);

        if(merged.success) {
            toast.success(`Your document merge was successful!`)
            if(onSuccess) onSuccess(merged.data)
            return setSuccess(true);
        }

        toast.error('Document Merge Failed')

        if(merged.message && merged.message[0]) {
            return setErr(merged.message[0])
        }
        return setErr('An Unknown Error Occurred')



    }, [documents, matter._id, name, onSuccess, prompt])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Merge Documents</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom bg-secondary">
                <p className='text-sm'>You are about to merge the following documents.</p>
                <ol>
                    {documents.map(d => (
                        <li key={d._id}>{d.name}</li>
                    ))}
                </ol>

            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className='form-control-label'>Final Document Name*</label>
                    <Input 
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </FormGroup>

            </div>

            {success ? (
                <div className='modal-body border-top'>
                    <i className="fas fa-check text-success mr-2" /> Your Document Merge Was Successful!
                </div>
            ) : err ? (
                <div className='modal-body border-top'>
                    <i className="fas fa-times text-danger mr-2" /> Document Merge Failed!
                </div>
            ) : running ? (
                <div className='modal-body border-top'>
                    <i className="fas fa-info-circle text-info mr-2" /> Document Merge Started, You May Close This Tab Or Wait Here For The Results<Dots />
                </div>
            ) : ''}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave} disabled={running}>
                    Merge Documents
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalActionsMerge);