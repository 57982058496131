import { connect } from 'react-redux';

import { useCallback, useEffect, useState } from 'react';

import api from 'api';

import ReactSelect from './Select';

const AI = ({ selected_matter, setSelectedAssistant, selectedAssistant }) => {

    const [ assistants, setAssistants ] = useState(null);

    const fetchData = useCallback(async () => {
        const data = await api.ai_assistants.find(selected_matter.division)
        if(data.data) setAssistants(data.data);
    }, [selected_matter.division])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <div className='archk-ai-main-navigation'>
            <div className='section px-4'>
                {assistants ? (
                    <ReactSelect
                        menuPlacement="top"
                        hideTitle={true}
                        formGroup={false}
                        placeholder="Select Assistant..."
                        onChange={(obj) => setSelectedAssistant(obj.value)}
                        options={assistants.map(a => {
                            return {
                                label: a.name, value: a._id
                            }
                        })}
                        value={selectedAssistant}
                    />    
                ) : <span></span>}
                
            </div>
        </div>
    )
}


const mapStateToProps = state => {
	return {
        selected_matter: state.matters.selected_matter,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(AI);