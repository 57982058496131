import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Modal, Row } from "reactstrap";

import DatePicker from 'components/markup/inputs/Day';
import States from 'components/markup/inputs/States';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';

const ModalCreditorsEdit = ({showModal, toggleModal, onSuccess, matter}) => {

    const [creditor, setCreditors] = useState({})

    const onInputChange = useCallback((field, val) => {
        const _creditor = JSON.parse(JSON.stringify(creditor));
        _creditor[field] = val;
        setCreditors(_creditor)
    }, [creditor])

    const onSave = useCallback(async () => {
        if(!creditor.name) return toast.info(`A name must be set before updating a creditor`);

        const result = await api.modules.bk.creditors.create({
            ...creditor,
            matter: matter._id,
            balance: creditor.balance ? parseFloat(creditor.balance) : 0,
            monthly_payment: creditor.monthly_payment ? parseFloat(creditor.monthly_payment) : 0,
        })

        if(!result.success) return toast.error(`Something went wrong updating this creditor, please refresh your page`)
        
        toast.success(`Creditor updated successfully`);
        toggleModal()
        if(onSuccess) onSuccess(result.data)
        setCreditors({})

    }, [creditor, matter._id, onSuccess, toggleModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Creditor</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div>

                <div className="modal-body border-bottom">

                    <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Debt Info</h2>

                    <FormGroup>
                        <label className='form-control-label'>Creditor Name</label>
                        <Input 
                            type="text"
                            value={creditor.name}
                            onChange={(e) => onInputChange('name', e.target.value)}
                        />
                    </FormGroup>

                    <Row>
                        <Col xs={4}>
                            <DatePicker 
                                title={`Last Payment Date`}
                                value={creditor.last_payment_date ? moment(creditor.last_payment_date).toDate() : ''} 
                                onChange={day => onInputChange('last_payment_date', moment(day).format('MM/DD/YYYY'))} 
                            />
                        </Col>

                        <Col xs={4}>
                            <FormGroup>
                                <label className='form-control-label'>Balance</label>
                                <Input 
                                    type="number"
                                    value={creditor.balance}
                                    onChange={(e) => onInputChange('balance', e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup>
                                <label className='form-control-label'>Monthly Payment</label>
                                <Input 
                                    type="number"
                                    value={creditor.monthly_payment}
                                    onChange={(e) => onInputChange('monthly_payment', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                </div>
                
                <div className="modal-body border-bottom bg-secondary">
                    <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Contact Info</h2>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <label className='form-control-label'>Phone Number</label>
                                <Input 
                                    type="text"
                                    value={creditor.phone}
                                    onChange={(e) => onInputChange('phone', e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={6}>
                            <FormGroup>
                                <label className='form-control-label'>Email</label>
                                <Input 
                                    type="text"
                                    value={creditor.email}
                                    onChange={(e) => onInputChange('email', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="modal-body border-bottom">

                    <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Address</h2>

                    <div>
                        
                        <FormGroup>
                            <label className="form-control-label">Street Address</label>
                            <Input 
                                type="text"
                                value={creditor.street}
                                onChange={e => onInputChange('street', e.target.value)}
                            />
                        </FormGroup>    
                        
                        <Row>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={creditor.city}
                                        onChange={e => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                            <Col lg={4}>
                                <States 
                                    title="State"
                                    value={creditor.state}
                                    onChange={(val) => onInputChange('state', val)}
                                /> 
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip</label>
                                    <Input 
                                        type="text"
                                        value={creditor.postal_code}
                                        onChange={e => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>    
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Save
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalCreditorsEdit);