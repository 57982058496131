import { connect } from 'react-redux';
import { Badge, Modal } from "reactstrap";

import moment from 'moment';

import Circle from 'components/markup/loading/Circle';

const TimeOffRequestView = ({showModal, toggleModal, timeOffRequest}) => {

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Update Time Off Request</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {!timeOffRequest ? <Circle /> : (
                    <div>

                        <p className='text-s mb-0 mb--4 text-cente'>
                            You have requested time off from:
                            <b>{moment.unix(timeOffRequest.unix_start).format('MM/DD/YYYY [at] h:mm A')} until {moment.unix(timeOffRequest.unix_end).format('MM/DD/YYYY [at] h:mm A')}</b>
                        </p>

                        <hr />

                        <p className='text-s mb-0 text-cente'>
                            <b><b style={{display: 'inline-block', width: 130}} className="text-dark">Your Reason:</b> </b>{timeOffRequest.request_note}
                        </p>

                        {timeOffRequest.response_note ? (
                            <div>
                                <hr />
                                <p className='text-s mb-0 text-cente'>
                                <b style={{display: 'inline-block', width: 130}} className="text-dark">Response:</b>{timeOffRequest.response_note}
                                </p>
                              
                                <hr />
                                <p className='text-s mb-0 text-cente'>
                                <b style={{display: 'inline-block', width: 130}} className="text-dark">Response Date:</b>{moment.unix(timeOffRequest.status_marked_at).format('MM/DD/YYYY [at] h:mm A')}
                                </p>

                            </div>
                        ) : ''}

                        <hr />

                        <Badge 
                            color={timeOffRequest.status === 'approved' ? 'success' : timeOffRequest.status === 'denied' ? 'danger' : 'info'}
                            className="text-capitalize text-sm mt-1 mb-3"
                        >
                            {timeOffRequest.status}
                        </Badge>


                    </div>
                )}
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(TimeOffRequestView);