
import { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/functional/modals/Confirmation'

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import api from 'api';
import formatText from 'utils/formatText'

import ModalConfirmCode from './ModalConfirmCode'

const AccountMFAPhone = ({viewing_user}) => {

    const hasMFA = viewing_user.mfa.phone;

    const [showModalConfirm, setShowModalConfirm] = useState(null);
    const [showModal, setShowModal] = useState(null);

    const onStartMFAPhone = useCallback(async () => {

        toggleStandardLoader(true)
        const sent = await api.auth.mfa.send({
            _id: viewing_user._id, 
            company: viewing_user.company, 
            type: 'phone'
        })
        toggleStandardLoader(false);

        if(sent.success) return setShowModal(true);

        toast.error(`Something's not right, please try again.`)

    }, [viewing_user._id, viewing_user.company])

    return (
        <>
        <ModalConfirmCode 
        showModal={showModal}
        toggleModal={() => setShowModal(false)}
        type="phone"
        />

        <ConfirmationModal 
            prevButtonText="Cancel"
            nextButtonText="Send Code"
            showModal={showModalConfirm}
            toggleModal={() => setShowModalConfirm(false)}
            title={hasMFA ? "Disable SMS MFA" : "Enable SMS MFA"}
            onConfirmation={() => onStartMFAPhone(true)}
            body={(
                <span>
                    {hasMFA ? (
                        'By removing sms as an MFA method you will not be prompted to input a security code texted to your account on login. It is recommended to keep at least 1 MFA method active at all times.'
                    ) : (
                        'By adding sms as an MFA method you will be prompted to enter a security code texted to your number on file before every login.'
                    )}
                    
                </span>
            )}
        />

        <h3>SMS</h3>

        {!viewing_user.phone ? (
            <div>
                <p className="text-sm mb-0"><i className="fas fa-times mr-2 text-warning " /> SMS MFA is not currently enabled.</p>
                <p className="text-sub  mb-0 font-weight-bold">To set up SMS MFA please add a phone number to your profile.</p>
            </div>
        ) : hasMFA ? (
            <div>
                <p className="text-sm mb-0"><i className="fas fa-check mr-2 text-success " /> SMS MFA is currently set up.</p>
                <p className="text-sm mb-0 font-weight-bold">{formatText(viewing_user.phone).phone()}</p>

                <button className="btn btn-outline-danger mt-4" onClick={() => setShowModalConfirm(true)}>
                    <i className="fas fa-envelope mr-2 " /> Remove
                </button>

                <p className="text-sub  mb-0 font-weight-bold">(not recommended)</p>
            </div>
        ) : (
            <div>
                <p className="text-sm mb-0">SMS MFA is currently not set up.</p>
                <p className="text-sm mb-0 font-weight-bold">{formatText(viewing_user.phone).phone()}</p>
                
                <button className="btn btn-success mt-4" onClick={() => setShowModalConfirm(true)}>
                    <i className="fas fa-envelope mr-2 " /> Enable
                </button>

                <p className="text-sub text-gradient-info mb-0 font-weight-bold">(recommended)</p>
            </div>
        )}
       
        </>
    )

}

const mapStateToProps = state => {
    return {
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountMFAPhone);