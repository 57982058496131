import { useState } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import MFA from '..//MFA';
import AccountInfo from './AccountInfo';
import Data from './Data';
import Matters from './Matters';
import Password from './Password';
import ErrorLogs from './ErrorLogs';
import Divisions from './Divisions';
import CalendarEmail from './CalendarEmail';
import Audio from './Audio';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import InternalSidebar from 'components/markup/layout/InternalSidebar';

const AccountSettings = () => {

    const [tab, setTab] = useState(0)

    return (

        <div>
             <Helmet>
                <title>Settings</title>
                <meta name="description" content="Settings" />
            </Helmet>

            <HeaderNavigation 
                title="My Account"
                // description="Settings"
                actionComponent={(
                    <div>
                        {/* <Link to="/clients" className="btn btn-info"><i className="fas fa-search mr-2 " /> Search Clients</Link>
                        <Link to="/matters/create" className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</Link> */}
                    </div>
                )}
            />

            {/* <Time time={Math.floor(new Date() / 1000)} format="MM/DD/YYYY hh:mm A" /> */}

            <InternalSidebar 
                tab={tab}
                onClick={(route, tab) => setTab(tab)}
                routes={[
                    {name: 'Account Info'},
                    {name: 'Login & Password'},
                    {name: 'Multi-Factor Auth'},
                    // {name: 'Matters'},
                    {name: 'Data'},
                    {name: 'Error Logs'},
                    // {name: 'Divisions'},
                    {name: 'Calendar / Email'},
                    {name: 'Audio'},
                ]}
            >
                {tab === 0 ? (
                     <AccountInfo />
                ) : tab === 1 ? (
                    <Password />
                ) : tab === 2 ? (
                    <MFA />
                ) 
                // : tab === 3 ? (
                //     <Matters />
                // ) 
                : tab === 3 ? (
                    <Data />
                ) : tab === 4 ? (
                    <ErrorLogs />
                ) 
                // : tab === 5 ? (
                //     <Divisions />
                // ) 
                : tab === 5 ? (
                    <CalendarEmail />
                ) : tab === 6 ? (
                    <Audio />
                ) : null}
            </InternalSidebar>
            
        </div>
    )

}

const mapStateToProps = state => {
    return {
        config: state.config,
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);