import moment from 'moment';
import ObjectFinder from 'components/system/Objects/Finder';

const TextsMain = ({call}) => {

    const outbound = call.direction === 'outbound' ? true : false;

    return (


        <div>
            <div className={`mx-3 my-1 d-inline-block ${outbound ? 'ml-7' : 'mr-7'}`}>
                <p className='text-sm mb-0'> 
                    <i className={`fa-solid mr-1 ${outbound ? 'fa-arrow-up text-success' : 'fa-arrow-down text-info'} `} /> 
                    <i className={`fa-solid fa-phone-volume mr-4 ${outbound ? 'text-success' : 'text-info'}`} /> 

                    {outbound ? <ObjectFinder collection="users" _id={call.call_legs[0].user} /> : <ObjectFinder collection="contacts" _id={call.contact} />}
                    { ' - '}
                    {moment.unix(call.created_at).format('MMM Do, h:mm A')}
                </p>

                <div className='px-4 py-4 bg-white rounded border'>
                    <div className=''>
                    
                        <p className='text-sm mb-0'>
                            {call.intent ? <span><b className='text-dark'>Call Intent:</b> {call.intent}</span> : outbound ? 'Outbound Call' : 'Inbound Call'}
                        </p>

                    </div>
                </div>

                <p className={`text-sm mb-0 text-right ${call.call_legs.length <= 1 ? 'text-warning' : ''}`}>
                    Parties: {' '}
                    {call.call_legs.map((l, i) => (
                        <span>
                            {i !== 0 ? ', ' : ''}
                            {l.user ? (
                                <ObjectFinder collection="users" _id={l.user} />
                            ) : (
                                <ObjectFinder collection="contacts" _id={l.contact} />
                            )}
                        </span>
                    ))}
                </p>

            </div>
        </div>
    )

}

export default TextsMain