import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";

import { FormGroup, Input } from 'reactstrap';

const CustomFieldInput = ({customField, value, onChange, hideTitle}) => {

    let input = (
        <FormGroup>
            {hideTitle !== true ? <label className='form-control-label'>Enter Your Answer Below:</label> : ''}
            <Input 
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </FormGroup>
    )

    if(customField.type === 'select') {
        input = (
            <ReactSelect
                title={hideTitle === true ? '' : "Select From The Answers Below:"}
                formGroup={true}
                placeholder=""
                onChange={(obj) => onChange(obj.value)}
                options={customField.answers.map(a => { return { value: a, label: a } })}
                value={value}
            />    
        )
    } else if(customField.type === 'number') {
        input = (
            <FormGroup>
                {hideTitle !== true ? <label className='form-control-label'>Enter Your Answer Below:</label> : ''}
                <Input 
                    type="number"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
            </FormGroup>
        )
    } else if(customField.type === 'date') {
        input = (
            <FormGroup>
                {hideTitle !== true ? <label className='form-control-label'>Enter Your Answer Below:</label> : ''}
                <Input 
                    type="date"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
            </FormGroup>
        )
    } else if(customField.type === 'contact_type') {
        input = (
            <SearchCollections
                collection={'contacts'}
                hideTitle={hideTitle}
                title={<span>Select A Contact Below <i className="fas fa-arrow-down ml-2" /></span>}
                placeholder="Search Contacts..."
                value={value}
                onChange={(obj) => onChange(obj.value)}
                filter={{
                    contact_types: { $in: [customField.contact_type] },
                }}
            /> 
        )
    } else if(customField.type === 'location_type') {
        input = (
            <SearchCollections
                collection={'locations'}
                hideTitle={hideTitle}
                title={<span>Select A Location Below <i className="fas fa-arrow-down ml-2" /></span>}
                placeholder="Search Locations..."
                value={value}
                onChange={(obj) => onChange(obj.value)}
                filter={{
                    location_types: { $in: [customField.location_type] },
                }}
            /> 
        )
    } else if(customField.type === 'user') {
        input = (
            <SearchCollections
                collection={'users'}
                hideTitle={hideTitle}
                title={<span>Associate A Party To This Matter Below <i className="fas fa-arrow-down ml-2" /></span>}
                placeholder="Search Users..."
                value={value}
                onChange={(obj) => onChange(obj.value)}
                filter={{
                    divisions: { $in: [customField.division] }
                }}
            /> 
        )
    }

    return input
}

export default CustomFieldInput