/*
Documentation

uploads a file to s3

@props

zone_id: a unique id on the page. can be any standard html id
url: the url to post to, we add in the keys here so just add the suffix after the hostname
onUpload: a function that takes the result as a param on successful file upload

note there is no on error function as our backend should catch all errors

*/

import Dropzone from "dropzone";
import keys from 'keys';
import React from "react";
import PropTypes from 'prop-types'

import Circle from 'components/markup/loading/Circle'
import { Row, Col } from 'reactstrap';
import heic2any from 'heic2any'
import ImgPreview from './ImgPreview'
import FileNameInput from './FileNameInput'

import { toggleStandardLoader } from 'store/functions/system/system';
toggleStandardLoader(false);

const unsupportedTypes = [
    'image/jp2'
]

const MAX_UPLOADS = 50

class DragAndDrop  extends React.Component {

    state = {
        has_files: [],
        files_to_upload: null,
        files_uploaded: 0,
        loading: false,
        loadingHeic: 0
    }

     setDropzoneListeners = () => {
        const { Dropzone } = this.state

        Dropzone.on("maxfilesexceeded", (file, xhr, formData) => {
            this.showMaxFileError(file)
        })

        Dropzone.on("sending", (file, xhr, formData) => {
            // Will any extra data given as props along with the file as POST data.
            const extraData = this.props.extraData

            if(extraData) {
                Object.keys(extraData).forEach(key => { formData.append(key, extraData[key]); })
            }

            const has_files = this.state.has_files;
            const found_file = has_files.find(f => f.upload.uuid === file.upload.uuid)

            let name = 'unknown document' + this.state.mountedTimestamp

            // add a timestamp to the end of the documents trying to be uploaded
            if(found_file && found_file.friendly_name) {

                let friendly_name
                const nameString = found_file.friendly_name.split('.')

                nameString.forEach((s, i) => {

                    if((i + 1) !== nameString.length) {
                        friendly_name = friendly_name ? friendly_name: s + '.'
                    } else {
                        friendly_name =  friendly_name + nameString[nameString.length - 1]
                    }

                })

                name = friendly_name

            }

            // toggleStandardLoader(true);
            formData.append('friendly_name', name);
        });

        //when a file is successfully uploaded
        Dropzone.on('success', (file, responseText) => {
            let files_uploaded = this.state.files_uploaded
            files_uploaded++
            if(files_uploaded === this.state.files_to_upload) {
                // toggleStandardLoader(false);
                if(this.props.onSuccess) this.props.onSuccess()
                this.setState({loading: false, files_to_upload: 0, files_uploaded: 0})
            }

            // this will be empty if the data sent was chunked
            if(!responseText) {

                try {
                    return this.onSuccess(file, JSON.parse(file.xhr.responseText))
                } catch(e) {
                    return this.onError({success: false, message: e}, file)
                }

            // this run for a file sent that was not chunked
            } else {

                //for each upload send of the response text
                if(responseText.success) {
                    return this.onSuccess(file, responseText)
                } else {
                    return this.onError(responseText, file)
                }

            }
        })

        Dropzone.on("error", async (file, message) => {
            if(this.props.onError) this.props.onError(file, message)
        });

        //when file is added add it to preview
        Dropzone.on("addedfile", async (file) => {
            if(unsupportedTypes.includes(file.type) ) return this.rejectFile(file);

            if(file.type.match(/image.*/)) {
                if(file.type !== 'image/svg+xml') return
            }

            this.onFileAdded(file)
        })

        Dropzone.on('thumbnail', (file) => {
            // don't let unsupported types in
            if(unsupportedTypes.includes(file.type) ) return this.rejectFile(file);

            // don't render svg's as thumbnails
            if(file.type === 'image/svg+xml') return

            if (file.type === 'image/heic') {

                this.setState({loadingHeic: this.state.loadingHeic + 1})

                return heic2any({ blob: file, toType: "image/jpeg" }).then(resultBlob => {

                    resultBlob.lastModifiedDate = file.lastModifiedDate;
                    resultBlob.name = file.name + ".jpg";
                    resultBlob.name = resultBlob.name.replace('.heic', '');

                    // add converted file to upload
                    Dropzone.handleFiles([resultBlob]);
                    // remove origin heic file for upload
                    Dropzone.removeFile(file);

                    this.setState({loadingHeic: this.state.loadingHeic - 1})

                }).catch((e) => {

                    // remove origin heic file for upload
                    Dropzone.removeFile(file);
                    alert('An error occurred uploading your file');

                });

            }

            this.onFileAdded(file)
        })

    }

    onSuccess = (file, responseText) => {
        this.setState({ has_files: [] })
        this.state.Dropzone.removeAllFiles();
        if(this.props.onSuccess) this.props.onSuccess(file, responseText)
    }

    onError = (err) => {
        this.state.Dropzone.removeAllFiles();
        this.setState({ has_files: [], })
        if(this.props.onError) this.props.onError(err)
    }

    //when this is fired any files in the dropzone will be uploaded
    onUpload = () => {
        const Dropzone = this.state.Dropzone

        if(!Dropzone.files || (Dropzone.files && !Dropzone.files.length)) return this.state.Dropzone.removeAllFiles();
        if(!this.validateFiles()) return;

        const { shouldShowLoader } = this.props

        this.setState({
            files_to_upload: Dropzone.files.length, 
            loading: shouldShowLoader ? true : false, 
            fileTypeError: false
        })

        if(Dropzone.files.find(f => f.status === 'error')) {
            this.setState({loading: false, fileTypeError: true, has_files: []}, () => {
                setTimeout(() => {
                    this.setState({fileTypeError: false})
                }, 2000)
            })

            return this.state.Dropzone.removeAllFiles();
        }

        //send off the files
        Dropzone.files.forEach(async (f, index) => {
            Dropzone.enqueueFile(Dropzone.files[index]);
        })

        if(this.props.onUploadStarted) this.props.onUploadStarted()
    }

    onRemoveFileForUpload = (file, index) => {
        const {Dropzone, has_files} = this.state;
        //remove from the dropzone
        Dropzone.removeFile(file)

        //remove from preview
        if(has_files && has_files.length) {

            let currentFiles = [...has_files]
            currentFiles = currentFiles.filter((f, i) => i !== index)

            this.setState({has_files: currentFiles})

            if(this.props.onFileRemoved) this.props.onFileRemoved(currentFiles)
        }
    }

    onFriendlyNameChange = (e, file, index) => {
        const value = e.target.value;

        let has_files = [...this.state.has_files]
        has_files[index].friendly_name = value;

        this.setState({has_files})
    }

    validateFiles = () => {
        const { defaultValue } = this.props;
        let has_files = [...this.state.has_files]
        let errors = 0;
        let fileNames = []

        has_files.forEach((file, index) => {
            let originalName = file.name;
            let friendly_name = file.friendly_name;

            // assume valid
            has_files[index].friendly_nameState = null

            // if not friendly name set invalid
            if(!friendly_name) {

                if(defaultValue) {
                    has_files[index].friendly_name = defaultValue
                    friendly_name = defaultValue
                } else {
                    has_files[index].friendly_nameState = 'File Name Required';
                    return errors++;
                }

            }


            let finalName = friendly_name.trim()
            finalName = finalName.replace(/ /g, '_')


            if(!friendly_name.includes('.')) {
                const splitFile = originalName.split('.')
                const extension = splitFile[splitFile.length - 1]

                finalName = finalName + '.' + extension
            }

            if(fileNames.includes(finalName)) {
                has_files[index].friendly_nameState = 'File Name May Not Be The Same As Another File Being Uploaded';
                return errors++;
            } else {
                fileNames.push(finalName)
            }
        })

        // only change the file name if we don't have errors and are about to send
        if(!errors) {
            has_files.forEach((file, index) => {
                has_files[index].friendly_name = fileNames[index]
            })
        }

        this.setState({has_files});
        return !errors
    }

    onFileAdded = (file) => {

        const Dropzone = this.state.Dropzone

        const { defaultValue, uploadOne, onFileAdded } = this.props
        if(!defaultValue && file.name) {
            file.friendly_name = file.name.split('.')[0]
        }

        let has_files = Dropzone.files

        if(uploadOne) {
            Dropzone.files = [file]

            has_files = [file]
            this.setState({has_files})
        } else {

            if(has_files.length + 1 > MAX_UPLOADS) return this.showMaxFileError()

            this.setState({has_files}, () => {
                setTimeout(() => {
                    const firstInput = document.getElementById(`drop-zone-name-${this.props.zone_id}-0`)
                    if(firstInput) firstInput.focus()
                }, 300)
            })

        }

        if(onFileAdded) onFileAdded(has_files)
    }

    rejectFile = (file) => {
        this.state.Dropzone.removeFile(file)
        this.setState({rejectedType: file.type}, () => {
            setTimeout(() => this.setState({rejectedType: false}), 3000)
        })
    }

    showMaxFileError = () => {
        this.setState({maxFileError: true}, () => {
            setTimeout(() => { this.setState({maxFileError: false}) }, 3000)
        })
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if(nextProps.shouldFireUploads === true) {
            this.onUpload();
            if(this.props.onParentUpload) this.props.onParentUpload(this.state.Dropzone.files)
        }
    }

    componentDidMount = async () => {
        //set dropzone as the state so it can be accessed late when we want to upload the file
        this.setState({
            mountedTimestamp:       '_' + Math.floor(new Date() / 1),
            Dropzone: new Dropzone(document.getElementById(this.props.zone_id), {
            headers                 : { method: 'post', authorization: `Bearer ${keys.SYSTEM_API_KEY}` },
            url                     : keys.PROCESSING_URL + this.props.url,
            maxFiles                : MAX_UPLOADS + 1,
            autoQueue               : false,
            parallelUploads         : 100,
            timeout                 : 1000 * 180,
            withCredentials         : true,
            thumbnailWidth          : null,
            thumbnailHeight         : null,
            resizeMethod            : 'contain',
            // chunking                : false,
            // chunkSize               : 5242880, // 5 mb exactly
            chunking                : false,

            parallelChunkUploads    : true,
            resizeQuality           : 1.0,
            resizeWidth             : this.props.resizePixels ? this.props.resizePixels : 2000,
            resizeHeight            : this.props.resizePixels ? this.props.resizePixels : 2000,
            previewsContainer       : document.getElementsByClassName( "dz-preview-single" + this.props.zone_id )[0],
            previewTemplate         : document.getElementsByClassName("dz-preview-single" + this.props.zone_id)[0].innerHTML,
            acceptedFiles           : this.props.acceptedFiles ? this.props.acceptedFiles : null,
            chunksUploaded          : (file, done) => { done() }
        })}, this.setDropzoneListeners)

        document.getElementsByClassName("dz-preview-single" + this.props.zone_id)[0].innerHTML = "";
    }

    render() {

        const { zone_id, defaultValue, shouldShowButton, btnText } = this.props
        const { fileTypeError, loading, has_files, loadingHeic, rejectedType, maxFileError } = this.state

        return (

            <div className="arch-media-library">

                <Row>
                    <Col md={has_files && has_files.length ? 4 : 12}>
                        <div className="dropzone dropzone-single mb-3" id={zone_id}>
                            <div className="fallback">
                                <div className="custom-file">
                                    <input className="custom-file-input" id="projectCoverUploads" type="file"/>
                                    <label className="custom-file-label" htmlFor="projectCoverUploads"> Choose file </label>
                                </div>
                            </div>
                            <div className={"dz-preview dz-preview-single" + zone_id}>
                                <div className="dz-preview-cover">
                                <img alt="..." className="dz-preview-img" data-dz-thumbnail=""/>
                                </div>
                            </div>
                        </div>

                        {maxFileError ? <div className="alert alert-danger mt-4">You may not upload more than {MAX_UPLOADS} files at a time.</div> : null}
                        {fileTypeError ? <div className="alert alert-danger mt-4">One or more files was not of the correct type.</div> : null}
                        {rejectedType ? (
                            <p className="text-sm font-weight-bold mt-4 mb-2">
                                <i className="fas fa-exclamation mr-2 text-danger" />
                                The file type "{rejectedType}" is not supported.
                                {has_files.length ? <hr /> : null}
                            </p>
                        ): null}

                        {has_files && has_files.length ? (
                              shouldShowButton === false || !has_files.length ? null : (
                                <button className="btn btn-block btn-success" onClick={() => this.onUpload()} >
                                    {loading ? 'Uploading Files' : 
                                        btnText ? btnText : 
                                        has_files.length > 1 ? `Upload (${has_files.length}) files` : 
                                        `Upload (1) File`
                                    }
                                </button>
                            )
                        ) : ''}

                    </Col>
                    {((has_files && has_files.length) || loadingHeic) && !this.props.hidePreview ? (

                    <Col md={8}>
                    <>

                        <table className="table border-left border-bottom border-right">
                            <thead>
                                <tr>
                                    <th>Preview</th>
                                    <th>File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {has_files.map((i, index) => (
                                    <tr key={i.upload.uuid}>
                                        <td style={{width: 100}}>
                                            <div className="p-3">
                                            <ImgPreview file={i} />
                                            </div>
                                        </td>

                                        <td>
                                            <FileNameInput 
                                                file={i}
                                                index={index} 
                                                zone_id={zone_id}
                                                friendly_nameState={i.friendly_nameState}
                                                defaultValue={defaultValue}
                                                onRemoveFileForUpload={this.onRemoveFileForUpload}
                                                onFriendlyNameChange={this.onFriendlyNameChange}
                                            />
                                        </td>
                                        {/* <p className="mb--2 text-sm"><b className="text-dark" style={styles.details}>File:</b> {i.name.length > 30 ? i.name.slice(0, 30) + '...' : i.name}</p> */}
{/* 
                                        <Row>
                                            <Col xs={6}>
                                                <p className="mb-0 text-sm"><b className="text-dark" style={styles.details}>Type:</b> {i.type}</p>
                                            </Col>
                                            <Col xs={6}>
                                                <p className="mb-0 text-sm"><b  className="text-dark" style={styles.details}>Size:</b> {(i.size / 1000000).toFixed(2)}MB</p>
                                            </Col>
                                        </Row> */}

                                    </tr>
                                ))}
                            </tbody>
                        </table>

                       
                        {loading && ( <div className="mt-3"> <Circle width={20} /> </div> )}

                    </>
                    </Col>
                    ):null}
                    
                </Row>
                

            </div>

        );
    }
}

DragAndDrop.propTypes = {
    //if true we are showing a selectable version of the DragAndDrop
    zone_id             : PropTypes.string.isRequired,
    url                 : PropTypes.string.isRequired,
    acceptedFiles       : PropTypes.string,
    uploadOne           : PropTypes.bool,

    onUpload            : PropTypes.func,
    onError             : PropTypes.func,
    onFileAdded         : PropTypes.func,
    onFileRemoved       : PropTypes.func,
    onParentUpload      : PropTypes.func,
    extraData           : PropTypes.object,
    shouldShowButton    : PropTypes.bool,
    hidePreview         : PropTypes.bool,
    shouldShowLoader    : PropTypes.bool,
    btnText             : PropTypes.string,

    resizePixels        : PropTypes.number,
};

export default DragAndDrop

