import React, { useState, useEffect, useCallback } from 'react';
import { Nav, NavItem, NavLink, Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";

import Edit from './Edit'

import ModalToggler from 'components/functional/modals/Toggler'


const ModalCustomFields = () => {

    const [properties, setProperties] = useState([])

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row>
                        <Col md={6} className='align-self-center'>
                            <CardTitle className='mb-0'>All Listed Property</CardTitle>
                        </Col>
                        <Col md={6} className='align-self-center text-right'>
                            <ModalToggler component={Edit}>
                                <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                            </ModalToggler>
                        </Col>
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Owner</th>
                                <th>Value</th>
                                <th>Exempt</th>
                            </tr>
                        </thead>

                        <tbody>
                            {properties.length ? properties.map((p, i) => (
                                <tr>
                                    <td></td>
                                    <td>{p.description}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No items have been added yet.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <ModalToggler component={Edit}>
                        <button className='btn btn-sm btn-success'><i className="fas fa-plus mr-2" /> Add</button>
                    </ModalToggler>
                </CardFooter>
            </Card>
        </div>
    )
}


export default ModalCustomFields