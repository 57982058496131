import SendText from 'components/system/Text'

const FinishTaskSendText = ({task, onFinish}) => {
    return <div className='py-3'>
        <SendText 
            matter={task.matter}
            task={task._id}
            textTemplate={task.task_template ? task.task_template.text_template : ''}
            onSent={onFinish}
        />
    </div>
}

export default FinishTaskSendText