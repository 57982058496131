import { useCallback, useEffect, useState, useRef, Component } from 'react';
import { toast } from 'react-toastify';

import reactHTMLParser from 'html-react-parser';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import moment from 'moment';
import useOnScreen from 'utils/hooks/useOnScreen'
  

class EmailBody extends Component {

    state = {
        body: this.props.body
    }

    componentDidCatch = (e) => {
       this.setState({body: "<span class='text-danger'>COULD NOT DISPLAY EMAIL</span>"})
    }

    render() {
        return <div dangerouslySetInnerHTML={{__html: this.state.body}} />
    }
}
   
const TextsMain = ({email}) => {

    const ref = useRef(null)
    const isVisible = useOnScreen(ref)

    const outbound = email.outbound ? true : false;
    const addresses = email.to.concat(email.cc)

    const [attachments, setAttachments] = useState(null)
    const [body, setBody] = useState(email.body.replace(/<head>[\s\S]*?<\/head>/, '<head></head>'));
    const [hasMore] = useState(body.match(/<blockquote[\s\S]*?<\/blockquote>/) ? true : false)
    const [loading, setLoading] = useState(false)
    const [showFull, setShowFull] = useState(false)


    const loadAttachments = useCallback(async () => {
        setLoading(true)
        const data = await api.emails.getAttachments(email._id)
        setLoading(false)
        if(!data.data) return toast.info(`Attachments could not be loaded`);
        let _body = JSON.parse(JSON.stringify(body))

        let err = false;

        data.data.forEach((a, i) => {

            let type = a.content_type
            if(type && type.includes('image/png')) {
                type = 'image/png'
                data.data[i].content_type = 'image/png'
            }
            if(type && type.includes('image/jpeg')) {
                type = 'image/jpeg'
                data.data[i].content_type = 'image/jpeg'
            }
            if(type && type.includes('application/pdf')) {
                type = 'application/pdf'
                data.data[i].content_type = 'application/pdf'
            }

            if(!a.data) err = true
            if(a.data && a.is_inline) {
                const replace = `cid:${a.content_id}`;
                const re = new RegExp(replace,"g");

                _body = _body.replace(re, `data:${a.type};base64, ${a.data}`)
            }
        })

        if(err) {
            console.log('One more attachments could not be downloaded on this email, to view them you will need to open the original email inside of your email provider client.')
        } else {
            setAttachments(data.data)
        }
        setBody(_body)

    }, [email._id, body])

    useEffect(() => {
        if(isVisible && email.attachments.length && !attachments && !loading) {
            loadAttachments()
        }
    }, [attachments, isVisible, loadAttachments, loading, email.attachments.length])

    return (
        <div>
            <div ref={ref} className={`archk-email-body ${outbound ? 'pl-7' : 'pr-7'}`}>

            <div className={`mx-4 my-1   d-inline-block `} style={{maxWidth: '100%'}}>

                <p className='text-sm mb-0 '> 

                    <i className={`fa-solid mr-2  ${outbound ? 'fa-arrow-up text-success' : 'fa-arrow-down text-info'}`} /> 
                    <i className={`fa-solid fa-envelope-open-text mr-4 ${outbound ? 'text-success' : 'text-info'}`} /> 

                    {email.from[0].name} To {email.to[0].email}
                    {' - '}
                    {moment.unix(email.date).format('MMM Do, h:mm A')}

                   
                </p>


                <div className='px-4 py-4 bg-white rounded border'>
                    <div className=''>
                        <p className='text-sm mb-0 text-uppercase'><b className='text-dark '>Subject:</b> {email.subject}</p>

                        <hr className='my-2' />

                        <p className='text-sm mb-0'>
                            <EmailBody 
                                body={showFull ? body : body.replace(/<blockquote[\s\S]*?<\/blockquote>/, '<blockquote></blockquote>')}
                            />
                            {/* {reactHTMLParser(showFull ? body : body.replace(/<blockquote[\s\S]*?<\/blockquote>/, '<blockquote></blockquote>'))} */}
                        </p>

                    </div>

                    {hasMore && !showFull ? (
                        <p  className='text-sm mb-0 text-center border-top pt-3'>
                            <span className='cursor-pointer' onClick={() => setShowFull(true)}><i className="fas fa-info-circle text-info" /> Load Full Email</span>
                        </p>
                    ) : ''}

                </div>

                {addresses.length > 1 ? (
                    <p className='text-sm mb-0 text-info font-weight-bold text-right'>
                        Also Copied: {addresses.map((t, i) => (
                            i === 0 ? '' : (
                                <span>{i === 1 ? '' : ', '} {t.email}</span>
                            )
                        ))}
                    </p>
                ) : ''}

            </div>
            </div>

        </div>
    )
}

export default TextsMain