import { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';

import ReactSelect from 'components/functional/inputs/ReactSelect';

// type is "push" or "pull"
const ModalActionsMerge = ({showModal, toggleModal, documents, onSuccess}) => {


    const [pinned, setPinned] = useState(true)
    const [running, setRunning] = useState(false)
    const [err, setErr] = useState(false)

    const onSave = useCallback(async () => {

        setRunning(true)
        const merged = await api.documents.pin({
            pinned,
            documents: documents.map(d => d._id)
        })
        setRunning(false);

        if(merged.success) {
            if(onSuccess) onSuccess(merged.data)
            toast.success(`Document category updated`)
            return toggleModal()

        }

        toast.error('Document category failed to update.')

        if(merged.message && merged.message[0]) {
            return setErr(merged.message[0])
        }
        return setErr('An Unknown Error Occurred')

    }, [pinned, documents, onSuccess, toggleModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Pin Document(s)</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom bg-secondary">
                <p className='text-sm'>Selected Documents.</p>
                <ol>
                    {documents.map(d => (
                        <li key={d._id}>{d.name}</li>
                    ))}
                </ol>
            </div>

            <div className="modal-body">

                <ReactSelect
                    title="Select The Category To Add The Above Document To"
                    formGroup={true}
                    onChange={(obj) => setPinned(obj.value)}
                    options={[
                        { value: true, label: 'Pinned' },
                        { value: false, label: 'Unpinned' },
                    ]}
                    value={pinned}
                />    

            </div>

            { err ? (
                <div className='modal-body border-top'>
                    <i className="fas fa-times text-danger mr-2" /> {err}
                </div>
            ) : ''}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave} disabled={running}>
                    Save Documents
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalActionsMerge);