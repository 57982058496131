

import Circle from 'components/markup/loading/Circle';

const StandardLoader = () => (
    <div className="py-6">
        <Circle />
    </div>
)

export default StandardLoader;