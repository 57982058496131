import { connect } from 'react-redux'
import React, { useState, useCallback } from 'react';
import { CardHeader, CardFooter, CardTitle, FormGroup, Input } from 'reactstrap';

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import api from 'api';

import { formatCredits } from 'utils/currency'

import A from 'components/markup/links/A'

const SettingsInfoDocuments = ({company, setCompany, CREDITS}) => {

    const amounts = CREDITS.CREDIT_AMOUNTS;

    const [apiErr, setApiErr] = useState(0);
    const [acceptRealStatements, setAcceptRealStatements] = useState(company.accept_real_statements)
    const [acceptRealStatementsLength, setAcceptRealStatementsLength] = useState(company.accept_real_statements_length ? company.accept_real_statements_length : '365')
    const [acceptFinancial, setAcceptFinancial] = useState(company.accept_financial)
    const [acceptEmployment, setAcceptEmployment] = useState(company.accept_employment)
    const [acceptAutoPolicies, setAcceptAutoPolicies] = useState(company.accept_auto_policies)
    const [acceptQuickLinks, setAcceptQuickLinks] = useState(company.accept_quick_links)
    const [allowDocsReturn, setAllowDocsReturn] = useState(company.allow_docs_return)
    const [useInstitutionName, setUseInstitutionName] = useState(company.use_institution_name)

    const onUpdate = async () => {

        if(!acceptFinancial && !acceptEmployment && !acceptAutoPolicies) {
            return setApiErr([`You must set your portal settings to accept at least one of the following: Financial Accounts, Employment Records, Auto Insurance Policies.`])
        }

        toggleStandardLoader(true)
        const updated = await api.companies.update(company._id, {
            accept_real_statements_length: acceptRealStatementsLength,
            accept_real_statements: acceptRealStatements,
            accept_financial: acceptFinancial,
            accept_employment: acceptEmployment,
            accept_quick_links: acceptQuickLinks,
            accept_auto_policies: acceptAutoPolicies,
            allow_docs_return: allowDocsReturn,
            use_institution_name: useInstitutionName,
        })
        if(updated.success) {
            toast.success('Company settings updated successfully.')
            setCompany()
        } else {
            setApiErr(updated.message)
        }

        toggleStandardLoader(false)
    }

    const onStatementAcceptanceChange = useCallback((e) => {
        const value = e.target.value;

        let accept_real_statements = false
        if(value === "1") accept_real_statements = true;

        setAcceptRealStatements(accept_real_statements)
    }, [])

    return (
        <div className="border-left">

            <CardHeader>
                <CardTitle className="mb-0">Document Settings</CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary">
                <p className="text-sm">These are your default settings for ordering documents. </p>
                <p className="text-sm mb-0">*<A className="text-underline cursor-pointer" href="/company/pricing">Additional charges</A> may apply for bank statements and documents in excess of product limits, time constraints allowed, and number of documents in an order. </p>
            </CardHeader>


            <CardHeader>

                <FormGroup>
                    <label className="form-control-label d-block">
                        Connections allowed in the IntuiDesk Portal

                        <span className="float-right pr-4">
                            {acceptFinancial ? (
                                <i className="fas fa-dollar-sign text-info ml-4 mr--3" />
                            ) : null}
                            {acceptEmployment ? (
                                <i className="fas fa-clipboard-list text-success ml-4 mr--3" />
                            ) : null}
                            {acceptAutoPolicies ? (
                                <i className="fas fa-car text-warning ml-4 mr--3" />
                            ) : null}
                        </span>
                    </label>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-accept-financial"
                            type="checkbox"
                            checked={acceptFinancial}
                            onChange={(e) => setAcceptFinancial(!acceptFinancial)}
                        />
                        <label className="custom-control-label" htmlFor="archk-accept-financial">
                            Allow Connection of Financial Accounts
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-accept-employment"
                            type="checkbox"
                            checked={acceptEmployment}
                            onChange={(e) => setAcceptEmployment(!acceptEmployment)}
                        />
                        <label className="custom-control-label" htmlFor="archk-accept-employment">
                            Allow Connection of Employment Records
                        </label>
                    </div>
                </FormGroup>

                <FormGroup>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="archk-accept-auto"
                            type="checkbox"
                            checked={acceptAutoPolicies}
                            onChange={(e) => setAcceptAutoPolicies(!acceptAutoPolicies)}
                        />
                        <label className="custom-control-label" htmlFor="archk-accept-auto">
                            Allow Connection of Auto Insurance Policies
                        </label>
                    </div>
                </FormGroup>

            </CardHeader >
            

            
            <CardHeader className="">

                <FormGroup>
                    <label className="form-control-label d-block">
                        Document Orders

                        <span className="float-right pr-4">
                            <i className="fas fa-history text-info ml-4 mr--3" />
                            {acceptRealStatements === '1' || acceptRealStatements === true ? (
                                <i className="fas fa-file-pdf text-success ml-4 mr--3" />
                            ) : null}
                        </span>
                    </label>
                    <p className="text-sm mb-0">In the event a user connects a financial account would you like IntuiDesk to return bank statements as well as transaction histories where applicable?
                    </p>
                    <Input
                        type="select"
                        onChange={onStatementAcceptanceChange}
                        value={acceptRealStatements === '1' || acceptRealStatements === true ? "1" : '2'}
                    >
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                    </Input>
                </FormGroup>

            </CardHeader>

            {acceptRealStatements ? (
                <>
                <CardHeader className="bg-secondary">
                    <p className="text-sm mb-0">In the event that IntuiDesk is able to pull bank statements your account will be charged {formatCredits(amounts.mx_statement)} credit per statement. Please specify the length of statements you would like to receive below. </p>
                </CardHeader>    

                <CardHeader>
                    <FormGroup>
                        <label className="form-control-label">How Far Should Statements Go Back?</label>
                        <Input
                            type="select"
                            onChange={(e) => setAcceptRealStatementsLength(e.target.value)}
                            value={acceptRealStatementsLength}
                        >
                            <option value="none">Do Not Order Bank Statements</option>
                            <option value="30">Order 30 Days Of Bank Statements</option>
                            <option value="90">Order 90 Days Of Bank Statements</option>
                            <option value="365">Order 365 Days Of Bank Statements</option>
                        </Input>
                    </FormGroup>
                </CardHeader>    
                </>
            ) : null}

            <CardHeader>
                <FormGroup>
                    <label className="form-control-label">Use Quick Links?</label>
                    <p className="text-sm mb-0">By Enabling quick links your clients will <b className="text-underline text-dark">not</b> need to enter their email/phone number or their access code when they come to the client portal. We will pre-fill this information in the link we send out to them.</p>
                    <Input
                        type="select"
                        onChange={(e) => setAcceptQuickLinks(e.target.value === 'true' ? true : false)}
                        value={acceptQuickLinks === 'true' || acceptQuickLinks === true ? "true" : 'false'}
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </Input>
                </FormGroup>
            </CardHeader>    
           
            <CardHeader>
                <FormGroup>
                    <label className="form-control-label">Allow Client Access After Completion?</label>
                    <p className="text-sm mb-0">If "yes" is selected below, contacts will be able to come back an update their account connections at any time after they complete the client portal. If set to "no", contacts will not be allowed to re-enter the portal after they have completed it.</p>
                    <Input
                        type="select"
                        onChange={(e) => setAllowDocsReturn(e.target.value === 'true' ? true : false)}
                        value={allowDocsReturn === 'true' || allowDocsReturn === true ? "true" : 'false'}
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </Input>
                </FormGroup>
            </CardHeader>    
            
            <CardHeader>
                <FormGroup>
                    <label className="form-control-label">Add Institution Name To Document Titles?</label>
                    <p className="text-sm mb-0">If "yes" is selected below, documents will append the institution name to their title. Example "Chase - Savings". If set to "no" document titles will show as "Savings"</p>
                    <Input
                        type="select"
                        onChange={(e) => setUseInstitutionName(e.target.value === 'true' ? true : false)}
                        value={useInstitutionName === 'true' || useInstitutionName === true ? "true" : 'false'}
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </Input>
                </FormGroup>
            </CardHeader>    
            
            {apiErr ? (
                <CardFooter>
                    {apiErr.map((e, i) => <p key={i} className="text-sm text-danger font-weight-bold mb-0">{e}</p>)}
                </CardFooter>
            ) : null}

            <CardFooter className="text-right">
                <button onClick={onUpdate} className="btn btn-success">
                    <i className="fas fa-save mr-2 " /> Save
                </button>
            </CardFooter>
        
        </div>

    )
}


const mapStateToProps = state => {
    return {
        CREDITS: state.load.CREDITS,
    };
};

export default connect(mapStateToProps, '')(SettingsInfoDocuments);