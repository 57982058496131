// "_id": 1,
// "company": 1,
// "division": 1,
// "matter": 1,
// "name": 1,
// "assigned_to": 1,
// "due_date": 1,
// "priority": 1,
// "finished_on": 1,
// "created_at": 1,

import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'

import { getUrlParameter } from "utils/urls";

import api from 'api';

import Circle from 'components/markup/loading/Circle'

const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, viewing_user}) => {
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ sort, setSort ] = useState('due_date +1')
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const onSetSort = useCallback((_sort) => {
        setSort(_sort)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        const type = getUrlParameter('type')

        const startOfDay = parseInt(moment().startOf('day').format('X'))

        // params.filter.division = selected_division._id
        params.filter.finished_on = 0
        if(selected_division._id) params.filter.division = selected_division._id
        if(type === 'today') params.filter.due_date = { $gte: startOfDay, $lt: startOfDay + 86399 }
        if(type === '7') params.filter.due_date = { $gte: startOfDay, $lt: startOfDay + (86400 * 7)}
        if(type === 'overdue') params.filter.due_date = { $lte: Math.floor(new Date() / 1000) }

        if(sort === 'priority +1') {
            params.sort = { priority: 1 }
        } else if(sort === 'priority -1') {
            params.sort = { priority: -1 }
        } else if(sort === 'due_date -1') {
            params.sort = { due_date: -1 }
        } else if(sort === 'due_date +1') {
            params.sort = { due_date: 1 }
        }

        const query = await api.tasks.search({ ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: 'Task',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div><Link to={`/matters/${row.matter}?tab=Tasks&docsTab=MyTasks`} className="text-dark font-weight-bold">{row.name}</Link></div>
                    </div>
                )
            },
        },
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    <div><Link to={`/matters/${row.matter}`} className="text-dark font-weight-bold"><ObjectFinder collection="matters" _id={row.matter} /></Link></div>
                </div>
            )
        },
        {
            dataField: "assigned_to",
            text: 'assigned_to',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.assigned_to ? '' :
                row.assigned_to.map((c, i) => (
                    <span>{i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={c} /></span>
                ))
            )
        },
        {
            dataField: "due_date",
            text: 'due',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>{!row.due_date ? '-' : row.due_date < Math.floor(new Date() / 1000) ? <span className="text-danger">{moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</span> : moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</div>
                    </div>
                )
            )
        },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/matters/${row.matter}?tab=Tasks&docsTab=MyTasks`,
                                },
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/matters/${row.matter}?tab=Tasks&docsTab=MyTasks`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = getUrlParameter('type') === 'today' ? 'Tasks Due Today' : 
        getUrlParameter('type') === 'overdue' ? 'Overdue Tasks' : 
        getUrlParameter('type') === '7' ? 'Upcoming 7 Days' : 
        'All Tasks'
    const description = getUrlParameter('type') === 'today' ? 'All tasks with a due date of midnight tonight' : 
        getUrlParameter('type') === 'overdue' ? 'All tasks with a due date before the current time' : 
        getUrlParameter('type') === '7' ? 'All tasks assigned to you over the next 7 days' : 
        'Showing all tasks assigned to you.'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            actionComponent={(
                <div>
                     <UncontrolledDropdown>
                        <DropdownToggle color="outline-warning">
                            {
                                sort === 'priority -1' ? 'Sort By Highest Priority' : 
                                sort === 'priority +1' ? 'Sort By Lowest Priority' :
                                sort === 'due_date -1' ? 'Sort By Recent Due Date' :
                                sort === 'due_date +1' ? 'Sort By Most Overdue' : 
                                ''
                            }
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => onSetSort('priority 1')}>Sort By Highest Priority</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('priority -1')}>Sort By Lowest Priority</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('due_date -1')}>Sort By Recent Due Date</DropdownItem>
                            <DropdownItem onClick={e => onSetSort('due_date +1')}>Sort By Most Overdue</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                </div>
            )}
        />
        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search Task Name..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
