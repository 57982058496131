import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import DatePicker from 'components/markup/inputs/Day';

import moment from 'moment';

import { timeSelection } from './_helpers'

const ModuleBKOverview = ({ data, onInputChange }) => {
    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Hearing Review</CardTitle>
                </CardHeader>

                <CardFooter>
                    <Row>
                        <Col xs={4}>
                            <ReactSelect
                                title="341 Held / Concluded?"
                                formGroup={true}
                                onChange={(obj) => onInputChange('ch_13_341_held_or_concluded', obj.value)}
                                options={[
                                    { value: false, label: 'No' },
                                    { value: true, label: 'Yes' },
                                ]}
                                value={data.ch_13_341_held_or_concluded}
                            /> 
                        </Col>
                        <Col xs={8}>
                            <Row>
                                <Col xs={6}>
                                    <DatePicker 
                                        title={`Confirmation Hearing Date / Time`}
                                        value={data.ch_13_confirmation_date ? moment(data.ch_13_confirmation_date).toDate() : ''} 
                                        onChange={day => onInputChange('ch_13_confirmation_date', moment(day).format('MM/DD/YYYY'))} 
                                    />

                                    <p className='text-sm mb-0 font-weight-bold'>Confirmation Hearing Status</p>

                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            name="archk_ch_13_confirmation_hearing_status_select"
                                            id={`ch_13_confirmation_hearing_status_original`}
                                            type="radio"
                                            checked={data.ch_13_confirmation_hearing_status === 'original'}
                                            onClick={e => onInputChange('ch_13_confirmation_hearing_status', 'original')}
                                        />
                                        <label className="custom-control-label" htmlFor={`ch_13_confirmation_hearing_status_original`}>
                                            Original Confirmation Hearing
                                        </label>
                                    </div>

                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            name="archk_ch_13_confirmation_hearing_status_select"
                                            id={`ch_13_confirmation_hearing_status_adjourned_via_minutes`}
                                            type="radio"
                                            checked={data.ch_13_confirmation_hearing_status === 'adjourned_via_minutes'}
                                            onClick={e => onInputChange('ch_13_confirmation_hearing_status', 'adjourned_via_minutes')}
                                        />
                                        <label className="custom-control-label" htmlFor={`ch_13_confirmation_hearing_status_adjourned_via_minutes`}>
                                            Adjourned via Minute Entry
                                        </label>
                                    </div>
                                   
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            className="custom-control-input"
                                            name="archk_ch_13_confirmation_hearing_status_select"
                                            id={`ch_13_confirmation_hearing_status_adjourned_via_order`}
                                            type="radio"
                                            checked={data.ch_13_confirmation_hearing_status === 'adjourned_via_order'}
                                            onClick={e => onInputChange('ch_13_confirmation_hearing_status', 'adjourned_via_order')}
                                        />
                                        <label className="custom-control-label" htmlFor={`ch_13_confirmation_hearing_status_adjourned_via_order`}>
                                            Adjourned via Order
                                        </label>
                                    </div>

                                </Col>
                                <Col xs={6}>
                                    <ReactSelect
                                        title={<span className='text-white'>l</span>}
                                        formGroup={true}
                                        onChange={(obj) => onInputChange('ch_13_confirmation_time', obj.value)}
                                        options={timeSelection}
                                        value={data.ch_13_confirmation_time}
                                    /> 

                                    {data.ch_13_confirmation_hearing_status === 'adjourned_via_order' ? (
                                        <div>
                                            <p className='text-sm mb-0 font-weight-bold'>Are All Conditions Met?</p>

                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    name="archk_ch_13_confirmation_order_conditions_met_select"
                                                    id={`archk_ch_13_confirmation_order_conditions_met_yes`}
                                                    type="radio"
                                                    checked={data.ch_13_confirmation_order_conditions_met === true}
                                                    onClick={e => onInputChange('ch_13_confirmation_order_conditions_met', true)}
                                                />
                                                <label className="custom-control-label" htmlFor={`archk_ch_13_confirmation_order_conditions_met_yes`}>
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    name="archk_ch_13_confirmation_order_conditions_met_select"
                                                    id={`archk_ch_13_confirmation_order_conditions_met_no`}
                                                    type="radio"
                                                    checked={data.ch_13_confirmation_order_conditions_met === false}
                                                    onClick={e => onInputChange('ch_13_confirmation_order_conditions_met', false)}
                                                />
                                                <label className="custom-control-label" htmlFor={`archk_ch_13_confirmation_order_conditions_met_no`}>
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    ) : ''}


                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </CardFooter>

                <CardFooter>
                    <FormGroup>
                        <label className='form-control-label'>341 Not Held / Unmet Condition Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 60}}
                            value={data.ch_13_confirmation_order_conditions_notes}
                            onChange={e => onInputChange('ch_13_confirmation_order_conditions_notes', e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

                <CardFooter>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <label className='form-control-label'>Filing Fee Balance Due</label>
                                <Input 
                                    type="number"
                                    value={data.ch_13_filing_fee_balance}
                                    onChange={e => onInputChange('ch_13_filing_fee_balance', e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className='form-control-label'>Payment History</label>
                                <Input 
                                    type="number"
                                    value={data.ch_13_payment_history}
                                    onChange={e => onInputChange('ch_13_payment_history', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <DatePicker 
                                title={`Filing Fee Payment Deadline`}
                                value={data.ch_13_filing_fee_deadline ? moment(data.ch_13_filing_fee_deadline).toDate() : ''} 
                                onChange={day => onInputChange('ch_13_filing_fee_deadline', moment(day).format('MM/DD/YYYY'))} 
                            />
                            <FormGroup>
                                <label className='form-control-label'>Payment History</label>
                                <Input 
                                    type="number"
                                    value={data.ch_13_payment_delinquent_amount}
                                    onChange={e => onInputChange('ch_13_payment_delinquent_amount', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <ReactSelect
                        title="Payment Order Working?"
                        formGroup={true}
                        onChange={(obj) => onInputChange('ch_13_payment_order_working', obj.value)}
                        options={[
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ]}
                        value={data.ch_13_payment_order_working}
                    /> 

                </CardFooter>

                <CardFooter>
                    <Row>
                        <Col xs={6}>
                        
                            
                            <p className='text-sm mb-0 font-weight-bold'>Other Pending Matters (See Separate Status Sheet)</p>

                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_ch_13_pending_matters_order_to_show_cause`}

                                    checked={data.ch_13_pending_matters.includes('order_to_show_cause')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.ch_13_pending_matters));
                                        if(arr.includes('order_to_show_cause')) {
                                            arr = arr.filter(a => a !== 'order_to_show_cause')
                                        } else {
                                            arr.push('order_to_show_cause')
                                        }
                                        onInputChange('ch_13_pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_pending_matters_order_to_show_cause`}>
                                    Order To Show Cause
                                </label>
                            </div>

                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_ch_13_pending_matters_motion_to_dismiss`}

                                    checked={data.ch_13_pending_matters.includes('motion_to_dismiss')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.ch_13_pending_matters));
                                        if(arr.includes('motion_to_dismiss')) {
                                            arr = arr.filter(a => a !== 'motion_to_dismiss')
                                        } else {
                                            arr.push('motion_to_dismiss')
                                        }
                                        onInputChange('ch_13_pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_pending_matters_motion_to_dismiss`}>
                                    Motion To Dismiss
                                </label>
                            </div>
                            
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_ch_13_pending_matters_objection_to_poc`}

                                    checked={data.ch_13_pending_matters.includes('objection_to_poc')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.ch_13_pending_matters));
                                        if(arr.includes('objection_to_poc')) {
                                            arr = arr.filter(a => a !== 'objection_to_poc')
                                        } else {
                                            arr.push('objection_to_poc')
                                        }
                                        onInputChange('ch_13_pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_pending_matters_objection_to_poc`}>
                                    Objection To POC
                                </label>
                            </div>
                        
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_ch_13_pending_matters_objection_to_borrow`}

                                    checked={data.ch_13_pending_matters.includes('objection_to_borrow')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.ch_13_pending_matters));
                                        if(arr.includes('objection_to_borrow')) {
                                            arr = arr.filter(a => a !== 'objection_to_borrow')
                                        } else {
                                            arr.push('objection_to_borrow')
                                        }
                                        onInputChange('ch_13_pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_pending_matters_objection_to_borrow`}>
                                    Objection To Borrow
                                </label>
                            </div>
                        
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_ch_13_pending_matters_motion_for_relief`}

                                    checked={data.ch_13_pending_matters.includes('motion_for_relief')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.ch_13_pending_matters));
                                        if(arr.includes('motion_for_relief')) {
                                            arr = arr.filter(a => a !== 'motion_for_relief')
                                        } else {
                                            arr.push('motion_for_relief')
                                        }
                                        onInputChange('ch_13_pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_pending_matters_motion_for_relief`}>
                                    Motion For Relief
                                </label>
                            </div>
                        
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_ch_13_pending_matters_other`}

                                    checked={data.ch_13_pending_matters.includes('other')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.ch_13_pending_matters));
                                        if(arr.includes('other')) {
                                            arr = arr.filter(a => a !== 'other')
                                        } else {
                                            arr.push('other')
                                        }
                                        onInputChange('ch_13_pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_pending_matters_other`}>
                                    Motion For Relief
                                </label>
                            </div>
                        </Col>

                        <Col xs={6}>
                            <FormGroup>
                                <label className='form-control-label'>Pending Matter Notes</label>
                                <Input 
                                    type="textarea"
                                    style={{minHeight: 120}}
                                    value={data.ch_13_pending_matter_notes}
                                    onChange={e => onInputChange('ch_13_pending_matter_notes', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


