import { useState } from 'react'
import { connect } from 'react-redux';
import { Modal, FormGroup, Input, Row, Col } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';

import formatText from 'utils/formatText'

import moment from 'moment';

import { toast } from 'react-toastify';

import api from 'api';
import { toggleStandardLoader } from 'store/functions/system/system';

const ModalDivisionOverride = ({showModal, toggleModal, thread, onMarkResolved}) => {

    const [resolvedOutcome, setResolvedOutcome] = useState('')
    const [resolvedType, setResolvedType] = useState('')
    const [markEmailsRead, setMarkEmailsRead] = useState(false)
    const [markTextsRead, setMarkTextsRead] = useState(false)
    const [resolvingMatter, setResolvingMatter] = useState(false)

    const onResolve = useCallback(async () => {
        if(!resolvedOutcome) return toast.info(`To resolve a ticket you must write a ticket resolution`)
        if(!resolvedType) return toast.info(`To resolve a ticket you must specify the way you resolved the ticket`)

        const params = {
            resolved_outcome: resolvedOutcome,
            resolved_type: resolvedType,
            mark_emails_read: markEmailsRead,
            mark_texts_read: markTextsRead,
        }

        if(resolvingMatter) params.resolving_matter = resolvingMatter

        toggleStandardLoader(true)
        const resolved = await api.support_tickets.resolve(thread.ticket._id, params)
        if(!resolved.success) {
            toggleStandardLoader(false)
            return toast.error(`Something went wrong resolving this ticket, please try again.`)
        }

        onMarkResolved(resolved.data)
        toggleModal()
        
    }, [markEmailsRead, markTextsRead, resolvedOutcome, resolvedType, resolvingMatter, thread.ticket._id, onMarkResolved, toggleModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Close Ticket</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom">

                <Row>
                    <Col md={4}>
                        <i className="fas fa-user mr-2 text-info" />
                        {thread.contact.display_name}
                    </Col>
                    <Col md={4}>
                        <i className="fas fa-mobile text-info mr-2" />
                        {thread.contact.phone ? formatText(thread.contact.phone).phone() : 'No Phone Found'}
                    </Col>
                    <Col md={4}>
                        <i className="fas fa-mobile text-info mr-2" />
                        {thread.contact.email ? thread.contact.email : 'No Email Found'}
                    </Col>
                </Row>

            </div>

           <div className='modal-body'>
            <div className='table-responsive border'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Info</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Division</td>
                                <td>{thread.ticket.division ? <ObjectFinder collection="divisions" _id={thread.ticket.division} /> :  'None'}</td>
                            </tr>
                            <tr>
                                <td>Opened</td>
                                <td>{moment.unix(thread.ticket.created_at).format('MMM Do, YYYY h:mm A')}</td>
                            </tr>
                            <tr>
                                <td>Last Updated</td>
                                <td>{moment.unix(thread.ticket.updated_at).format('MMM Do, YYYY h:mm A')}</td>
                            </tr>
                            <tr>
                                <td>Last Item</td>
                                <td>{thread.ticket.snippet}</td>
                            </tr>
                            <tr>
                                <td>Calls Exchanged</td>
                                <td>{thread.ticket.calls.length}</td>
                            </tr>
                            <tr>
                                <td>Emails Exchanged</td>
                                <td>{thread.ticket.emails.length}</td>
                            </tr>
                            <tr>
                                <td>Texts Exchanged</td>
                                <td>{thread.ticket.texts.length}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
           </div>

            <div className="modal-body border-bottom bg-secondary">

                <FormGroup>
                    <label className='form-control-label'><span className={resolvedOutcome ? '' : 'text-warning'}>*Ticket Resolution:</span></label>
                    <Input 
                        type="textarea"
                        value={resolvedOutcome}
                        onChange={e => setResolvedOutcome(e.target.value)}
                    />
                </FormGroup>

            </div>

            <div className="modal-body">
                <ReactSelect
                    title={<span className={resolvedType ? '' : 'text-warning'}>*Ticket Was Resolved By:</span>}
                    formGroup={true}
                    placeholder=""
                    onChange={(obj) => setResolvedType(obj.value)}
                    options={[
                        { value: 'call', label: 'Phone Call' },
                        { value: 'email', label: 'Email Response' },
                        { value: 'text', label: 'Text Message Response' },
                        { value: 'other', label: 'Other' },
                        { value: 'no action', label: 'No Action Needed' },
                    ]}
                    value={resolvedType}
                />    
            </div>
            
            <div className="modal-body border-top bg-secondary">
                <h4>Ticket Resolution Actions:</h4>
                
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-resolve-ticket-emails"
                                    type="checkbox"
                                    checked={markEmailsRead}
                                    onChange={(e) => setMarkEmailsRead(!markEmailsRead)}
                                />
                                <label className="custom-control-label" htmlFor="archk-resolve-ticket-emails">
                                    Mark All Contact Email Threads As Read
                                </label>
                            </div>
                        </FormGroup>
                    </Col>
                    
                    <Col md={12}>
                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="archk-resolve-ticket-texts"
                                    type="checkbox"
                                    checked={markTextsRead}
                                    onChange={(e) => setMarkTextsRead(!markTextsRead)}
                                />
                                <label className="custom-control-label" htmlFor="archk-resolve-ticket-texts">
                                    Mark All Contact Text Threads As Read
                                </label>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> Cancel
                </button>
                <button className="btn btn-success" onClick={onResolve}>
                    <i className="fas fa-check mr-2" /> Close Ticket
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);