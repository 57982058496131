import { useEffect, useState } from 'react'
import { Modal } from 'reactstrap';
import { Row, Col, Input, FormGroup } from 'reactstrap';

import { connect } from 'react-redux';
import api from 'api';
import { toggleStandardLoader } from 'store/functions/system/system';

const ModalCreateUser = ({showModal, toggleModal, query, viewing_user}) => {

    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [isAdmin, setIsAdmin] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [errors, setErrors] = useState(false)

    const onCreate = async () => {

        setErrors(false)

        if(!givenName || !familyName || !email || !phone) {
            return setErrors([`A user must have a last name, email, and phone field.`])
        }
    
        toggleStandardLoader(true)

        const fields = {
            given_name: givenName,
            family_name: familyName,
            email: email,
            phone: phone,
            is_admin: isAdmin === 'yes' ? true : false,
            password: password,
            passwordConfirm: passwordConfirm,
            company: viewing_user.company
        }

        let saved = await api.users.create(fields, true);
        toggleStandardLoader(false)

        // show error messages if unsuccessful
        if(!saved.success) {
            return setErrors(saved.message);
        } else {
            query();
            toggleModal()
        }
        
    }

    useEffect(() => {
        if(showModal) {

            setGivenName('');
            setFamilyName('');
            setEmail('');
            setPhone('');
            setPassword('')
            setPasswordConfirm('')
            setErrors(false)

            const tryFocus = (tries = 0) => {

                const input = document.getElementById('create-user-first-name')

                if(input) {
                    input.focus();
                } else {
                    setTimeout(() => {
                        tryFocus(tries + 1)
                    }, 200)
                }

            }

            tryFocus();
       }

    }, [showModal])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={() => toggleModal(false)}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Create A User</h5>
                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleModal(false)} >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom">
                <p className="text-sm mb-0">
                    <i className="fas fa-info-circle text-success mr-2 " />{' '}
                    To create a user they must have a first name, last name, email and phone.
                </p>
            </div>

            <div className="modal-body border-bottom">

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">First Name*</label>
                            <Input 
                                id="create-user-first-name"
                                type="text"
                                value={givenName}
                                onChange={(e) => setGivenName(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Last Name*</label>
                            <Input 
                                type="text"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Email*</label>
                            <Input 
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Phone*</label>
                            <Input 
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <hr />

                <FormGroup>
                    <label className="form-control-label">Has Admin Privilege*</label>
                    <Input 
                        type="select"
                        value={isAdmin}
                        onChange={(e) => setIsAdmin(e.target.value)}
                    >
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Input>
                </FormGroup>

                <hr />

                <Row>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Password*</label>
                            <Input 
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                    </Col>

                    <Col lg={6}>
                        <FormGroup>
                            <label className="form-control-label">Confirm Password*</label>
                            <Input 
                                type="password"
                                value={passwordConfirm}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>

            </div>

            {errors ? (
                <div className="modal-body border-bottom">
                    {errors.map((err, i) => (
                        <p key={i} className="text-sm font-weight-bold text-danger mb-0"><i className="fas fa-exclamation mr-2 " /> {err}</p>
                    )) }
                </div>
            ) : null}

            <div className="modal-footer text-right">
                <button onClick={() => toggleModal(false)} className="btn btn-outline-warning">
                    <i className="fas fa-arrow-left mr-2 " /> Cancel
                </button>
                <button onClick={onCreate} className="btn btn-success">
                    <i className="fas fa-check mr-2 " /> Create User
                </button>
            </div>

        </Modal>
    )
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(ModalCreateUser);
