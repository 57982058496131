import keys from 'keys';

import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter } from "reactstrap";

import Axios from 'axios';
import fileDownloader from 'js-file-download';

import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import SearchCollections from "components/system/Search/Collections";

const MattersViewMainDocumentsSigning = ({matter}) => {

    const [doc, setDoc] = useState('')

    const onGenerate = useCallback(async () => {
        try {

            toggleStandardLoader(true)

            const result = await Axios({
                method: 'get',
                url: keys.API_URL + `/v1/word_documents/${doc}/${matter._id}/generate`,
                responseType:'arraybuffer',
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
            })

            if(result.headers && result.headers['content-type'].includes('application/json')) {

                try {
                    var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                    var body = JSON.parse(decodedString);
                    if(body.message && body.message.length) {
                        return toast.error(body.message[0] ? body.message[0] : `Something's Not Right, Please Try Again Later`)
                    }
                } catch(e) {
                    return toast.error(`Something went wrong downloading this document`)
                }
            }

            let filename = result.headers.filename;

            if(filename && filename.includes('attachment; filename=')) {
                filename = filename.replace('attachment; filename=', '')
                filename = filename.replace(/"/g, '')
            }   
        
            if(filename && filename.includes(' filename=')) {
                filename = filename.replace(' filename=', '')
                filename = filename.replace(/"/g, '')
            }   
            if(filename && filename.includes(' filename*=')) {
                filename = filename.replace(' filename*=', '')
                filename = filename.replace(/"/g, '')
            }   


            fileDownloader(result.data, filename) 
            setDoc('')

            toggleStandardLoader(false)

        } catch(e) {

            return toast.error(`Something went wrong downloading this document`)
        }

    }, [doc, matter._id])

    return (
        <>
   

        <Card className='card-no-shadow'>

            <CardBody>

                <SearchCollections
                    collection={'word_documents'}
                    title="Select Template"
                    placeholder="Search Templates..."
                    value={doc}
                    onChange={(obj) => setDoc(obj.value)}
                    filter={{ division: matter.division }}
                /> 
            </CardBody>
         
            <CardFooter className='text-right'>
                <button 
                    disabled={!doc}
                    className='btn btn-success'
                    onClick={onGenerate}
                >
                    Generate Template
                </button>

            </CardFooter>
            
        </Card>

        </>
    );

}

const styles = {
    badge: {
        display: 'inline-block',
        width: 90
    }
}


const mapStateToProps = state => {
	return {
	    device: state.device,  
        matter: state.matters.selected_matter,
        viewing_user: state.auth.viewing_user,
        PRIVILEGES: state.config.PRIVILEGES,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainDocumentsSigning);