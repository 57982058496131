/*
Documentation

This component takes an image url and returns it if as a circular image
if it does not it will return a default image

*/

import PropTypes from 'prop-types'
import React from "react";
import DefaultAvatar from './DefaultAvatar.jpg'

class AvatarImage extends React.Component {

    state = {
        error: false
    }

    onError = () =>  this.setState({ error: true });

    render() {

        const alt = this.props.alt ? this.props.alt : '...'

        const { error } = this.state
        const { src, style, className } = this.props;

        if(!src || error) {

            return <img style={style ? style : {}} className={'avatar-image z-depth-2 ' + className}  src={DefaultAvatar} alt={alt} />

        } else {

            return <img style={style ? this.props.style : {}} className={'avatar-image z-depth-2 ' + className} src={this.props.src} onError={this.onError} alt={alt} />

        }

    }

}

AvatarImage.propTypes = {
    src         : PropTypes.string,
    alt         : PropTypes.string,
    style       : PropTypes.object,
    className   : PropTypes.string,
}

export default AvatarImage;
