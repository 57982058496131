import React from 'react';
import { connect } from 'react-redux';
import { Card } from "reactstrap";

import StandardLoader from 'components/markup/layout/StandardLoader';
import A from 'components/markup/links/A';

import ModalToggler from 'components/functional/modals/Toggler';
import SendForm from 'components/system/SendForm';

const FormsStep = ({formAnswers, forms, matter, fetchData, DOMAINS}) => {

    const stepForms = matter.workflow_step.forms;

    if(!forms || !formAnswers) return <StandardLoader />

    return (
        <Card className='card-no-shadow'>

            <div className="table-responsive ">
                <table className="table table-not-bordered">
                    <thead>
                        <tr>
                            <th>Form Name</th>
                            <th>Contact</th>
                            <th>Pages</th>
                            <th>Completion %</th>
                            <th className="text-right">Status</th>
                        </tr>
                    </thead>

                    <tbody>

                        {stepForms.length ? stepForms.map(f => {
                            const foundForm = forms.find(ff => ff._id === f.form);
                            let markup = [(
                                <tr key={foundForm._id}>
                                    <td>{foundForm.name}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )]

                            matter.contacts.forEach(c => {
                                const foundAnswers = formAnswers.find(ff => ff.form === foundForm._id && ff.contact === c._id);

                                markup.push((
                                    <tr key={foundForm._id + c._id}>
                                        <td></td>
                                        <td>
                                            {foundAnswers ? (
                                                <A className='cursor-pointer' href={`${DOMAINS.FORMS}/${foundAnswers._id}`}>
                                                    <i className="fas fa-link text-success mr-2 " />
                                                    {c.display_name}
                                                </A>
                                            ) : (
                                                c.display_name
                                            )}
                                        </td>
                                        <td>{foundForm.pages}</td>
                                        <td>{foundAnswers ? foundAnswers.completion_percent + '%' : '-'}</td>
                                        <td className='text-right'>
                                            {!foundAnswers ? (
                                                <ModalToggler component={SendForm} form_id={foundForm._id} matter_id={matter._id} onRequested={fetchData} contact={c._id}>
                                                    <button style={{width: 130}} className="btn btn-sm btn-danger">Not Sent</button>
                                                </ModalToggler>
                                            ) : foundAnswers.completion_percent >= 99.8 ? (
                                                <ModalToggler component={SendForm} form_id={foundForm._id} matter_id={matter._id} onRequested={fetchData} contact={c._id}>
                                                    <button style={{width: 130}} className="btn btn-sm btn-success">Completed</button>
                                                </ModalToggler>
                                            ) : (
                                                <ModalToggler component={SendForm} form_id={foundForm._id} matter_id={matter._id} onRequested={fetchData} contact={c._id}>
                                                    <button style={{width: 130}} className="btn btn-sm btn-info">Not Complete</button>
                                                </ModalToggler>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            })


                            return markup
                        }) : (
                            <tr>
                                <td>No forms were found for this step.</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>

        </Card>

    );

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(FormsStep);