import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, FormGroup, Input, Modal } from "reactstrap";

import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from 'components/system/Search/Collections';

import DatePicker from 'components/markup/inputs/Day';

import api from 'api';

import { toast } from 'react-toastify';

import moment from 'moment';

const baseState = {
    assigned_to: []
}

const ModalDivisionOverride = ({showModal, toggleModal, onSaved, matter}) => {

    const [task, setTask] = useState(baseState);
    const [dueDate, setDueDate] = useState();

    const onInputChange = useCallback((field, value) => {
        const t = JSON.parse(JSON.stringify(task));
        t[field] = value;
        setTask(t)
    }, [task])
    
    const onAssignedToChange = useCallback((_id) => {
        const t = JSON.parse(JSON.stringify(task));

        if(t.assigned_to.includes(_id)) {
            t.assigned_to = t.assigned_to.filter(r => r !== _id)
        } else {
            t.assigned_to.push(_id)
        }
        setTask(t)

    }, [task])

    const onSave = useCallback(async () => {
        if(!matter.workflow_step) return toast.info(`This matter must be on a valid step before creating a task`);

        const saved = await api.tasks.create({
            matter: matter._id,
            workflow_step: matter.workflow_step,
            task_template: task.task_template,
            required: false,
            assigned_to: task.assigned_to,
            due_date: parseInt(moment(dueDate).format('X')),
            name: task.name,
        })

        if(!saved.success) return toast.error(`Could not save task, please try again.`);

        toast.success('Task Saved Successfully')
        toggleModal()
        if(onSaved) onSaved(saved.data)

    }, [dueDate, matter._id, matter.workflow_step, task, toggleModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Task</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            {console.log(task.due_date)}

            <div className="modal-body">
                <DatePicker 
                    value={dueDate}
                    onChange={(e) => setDueDate(e)}
                    title="Due Date*"
                />

                <FormGroup>
                    <label className='form-control-label'>Task Title*</label>
                    <Input 
                        type="text"
                        value={task.name || ''}
                        onChange={e => onInputChange('name', e.target.value)}
                    />
                </FormGroup>
            </div>

            <div className="modal-body">

                <SearchCollections
                    title="Select Task Template*"
                    collection="task_templates" 
                    value={null}
                    onChange={(obj) => onInputChange('task_template', obj.value)}
                    filter={{
                        division: matter.division
                    }}
                /> 

                <SearchCollections
                    collection={'users'}
                    title="Assigned To*"
                    placeholder="Search Users..."
                    hideSelected={true}
                    onChange={(obj) => onAssignedToChange(obj.value)}
                    filter={{
                        divisions: { $in: [matter.division] }
                    }}
                /> 

                {task.assigned_to.length ? task.assigned_to.map((i) => (
                    <Badge key={i} color="success" className="ml-0 mb-2 text-left mr-3">
                        <ObjectFinder collection="users" _id={i} />
                        <span className="pr-2 pl-3 cursor-pointer float-right" onClick={() => onAssignedToChange(i)}>
                            <i className="fas fa-times " />
                        </span>
                    </Badge>
                )) : null}

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave}>
                    Add Task
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);