import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

import NameCircle from 'components/markup/misc/NameCircle';
import formatText from 'utils/formatText'

import OnCallKeypad from './OnCallKeypad'


// import { getInitials } from 'utils/names'

import ModalAddCall from './ModalAddCall'

import api from 'api';

import { toast } from 'react-toastify';

import { toggleMute } from 'store/functions/call_center'

const CallsOnCall = ({current_call, socket}) => {

    const [hold, setHold] = useState(false)
    const [mute, setMute] = useState(false)
    const [showKeypad, setShowKeypad] = useState(false)

    // // const initials = getInitials(current_call.name)

    const [showModal, setShowModal] = useState(false);

    const getNameMarkup = () => (
        <div 
            className='bg-gradient-success text-center  pt-4 pb-2' 
            style={{overflow: 'hidden'}}
        >
            <div className='pt-3'>
                <NameCircle 
                    className="z-depth-2" 
                    width={70} 
                    contact={{
                        given_name: 'John', 
                        family_name: 'Maher'
                    }}  
                />{' '}
                <p className='text-sm mb-0 mt-3 text-white font-weight-bold'>{current_call.name}</p>
                <p className='text-sm mb-3 mt--2 text-white font-weight-bold'>{formatText(current_call.number).phone()}</p>
            </div>
        </div>
    )

    const onEndCall = useCallback(async () => {
        const action = await api.call_center.hangup({callSid: current_call.call_sid})
        if(!action.success) toast.error(`Could not hang up call, please try again or refresh the page.`)

    }, [current_call.call_sid])

    const onToggleMute = useCallback(() => {
        const muted = toggleMute();
        setMute(muted)
    }, [])
   
    const onToggleHold = useCallback(async () => {
        const currentHold = hold;
        setHold(!hold)
        const action = await api.call_center.hold({callSid: current_call.call_sid})
        if(!action.success) {
            toast.error(`Could change hold status of this call, please try again or refresh the page.`);
            return setHold(currentHold)
        }
        if(action.data.status === 'err') {
            toast.error(`Something went wrong placing 1 or more call participants on hold.`);
            return setHold(currentHold)
        }

    }, [current_call.call_sid, hold])

    const onHoldChanged = useCallback((params) => {
        if(params.hold !== undefined) setHold(params.hold)
    }, [])
  
    useEffect(() => {
        socket.on('CALL_CENTER.HOLD_TOGGLED', onHoldChanged)

        return () => {
            socket.off('CALL_CENTER.HOLD_TOGGLED', onHoldChanged)
        }
    }, [socket, onHoldChanged])

   return (
        <div className='position-relative'>

            <OnCallKeypad showKeypad={showKeypad} toggleKeypad={() => setShowKeypad(false)} />

            {current_call.matter ? (
                <Link to={`/matters/${current_call.matter}`} className="cursor-pointer">{getNameMarkup()}</Link>
            ) : current_call.contact ? (
                <Link to={`/contacts/${current_call.contact}`} className="cursor-pointer">{getNameMarkup()}</Link>
            ) : getNameMarkup()}

            <div className='text-center archk-call archk-call-controls border-bottm bg-gradient-success border-top' style={{overflow: 'hidden'}}>

                <i 
                    className="fa-solid fa-keyboard text-white px-3 cursor-pointer" 
                    style={{position: 'absolute', top: 5, right: 0}} 
                    onClick={() => setShowKeypad(true)}
                />

                <div className="archk-on-call-wapper archk-call-buttons">
                    <Row>

                        <Col 
                            className='text-center archk-call-button' 
                            onClick={() => setShowModal(true)} 
                            id="archk-call-button-transfer"
                        >
                            <i 
                                style={styles.icon} 
                                className="display-5 fa-solid fa-shuffle text-white" 
                            />
                        </Col>

                        <Col  
                            className={`text-center archk-call-button ${mute ? 'archk-call-button-active' : ''}`} 
                            onClick={onToggleMute} 
                            id="archk-call-button-mute"
                        >
                            <i 
                                style={styles.icon}  
                                className="display-5 fa-solid text-white fa-volume-xmark "  
                            />
                        </Col>

                        <Col 
                            className={`text-center archk-call-button ${hold ? 'archk-call-button-active' : ''}`} 
                            onClick={onToggleHold} 
                            id="archk-call-button-hold"
                        >
                            <i 
                                style={styles.icon}  
                                className="display-5 fa-solid text-white fa-pause "  
                            />
                        </Col>

                        <Col 
                            className='text-center archk-call-button' 
                            onClick={onEndCall} 
                            id="archk-call-button-end"
                        >
                            <i 
                                style={styles.iconEnd}  
                                className="display-5 fa-solid fa-phone-slash text-danger bg-white borde border-secondary" 
                            />
                        </Col>

                        <UncontrolledTooltip delay={1000} placement="bottom" target="archk-call-button-transfer">Transfer</UncontrolledTooltip>
                        <UncontrolledTooltip delay={1000} placement="bottom" target="archk-call-button-mute">Mute</UncontrolledTooltip>
                        <UncontrolledTooltip delay={1000} placement="bottom" target="archk-call-button-hold">Hold</UncontrolledTooltip>
                        <UncontrolledTooltip delay={1000} placement="bottom" target="archk-call-button-end">End Call</UncontrolledTooltip>
                    </Row>
                </div>
            </div>

            <ModalAddCall 
                showModal={showModal}
                toggleModal={() => setShowModal(!showModal)}
            />
        </div>
   )

}

const styles = {
    icon: {
        fontSize: 16
    },
    iconEnd: {
        fontSize: 18,
        borderRadius: '100%'
    }
}


const mapStateToProps = state => {

	return {
	    current_call: state.call_center.current_call,
	    socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(CallsOnCall);
