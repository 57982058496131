import ReactSelect from 'components/functional/inputs/ReactSelect';
import { Col, Row } from 'reactstrap';
import { useEffect, useState } from 'react';

import moment from 'moment'

const minutes5 = 300

const times = [
    { label: '12:00 AM', value: minutes5 * 0 },
    { label: '12:05 AM', value: minutes5 * 1 },
    { label: '12:10 AM', value: minutes5 * 2 },
    { label: '12:15 AM', value: minutes5 * 3 },
    { label: '12:20 AM', value: minutes5 * 4 },
    { label: '12:25 AM', value: minutes5 * 5 },
    { label: '12:30 AM', value: minutes5 * 6 },
    { label: '12:35 AM', value: minutes5 * 7 },
    { label: '12:40 AM', value: minutes5 * 8 },
    { label: '12:45 AM', value: minutes5 * 9 },
    { label: '12:50 AM', value: minutes5 * 10 },
    { label: '12:55 AM', value: minutes5 * 11 },

    { label: '1:00 AM', value: minutes5 * 12 },
    { label: '1:05 AM', value: minutes5 * 13 },
    { label: '1:10 AM', value: minutes5 * 14 },
    { label: '1:15 AM', value: minutes5 * 15 },
    { label: '1:20 AM', value: minutes5 * 16 },
    { label: '1:25 AM', value: minutes5 * 17 },
    { label: '1:30 AM', value: minutes5 * 18 },
    { label: '1:35 AM', value: minutes5 * 19 },
    { label: '1:40 AM', value: minutes5 * 20 },
    { label: '1:45 AM', value: minutes5 * 21 },
    { label: '1:50 AM', value: minutes5 * 22 },
    { label: '1:55 AM', value: minutes5 * 23 },

    { label: '2:00 AM', value: minutes5 * 24 },
    { label: '2:05 AM', value: minutes5 * 25 },
    { label: '2:10 AM', value: minutes5 * 26 },
    { label: '2:15 AM', value: minutes5 * 27 },
    { label: '2:20 AM', value: minutes5 * 28 },
    { label: '2:25 AM', value: minutes5 * 29 },
    { label: '2:30 AM', value: minutes5 * 30 },
    { label: '2:35 AM', value: minutes5 * 31 },
    { label: '2:40 AM', value: minutes5 * 32 },
    { label: '2:45 AM', value: minutes5 * 33 },
    { label: '2:50 AM', value: minutes5 * 34 },
    { label: '2:55 AM', value: minutes5 * 35 },

    { label: '3:00 AM', value: minutes5 * 36 },
    { label: '3:05 AM', value: minutes5 * 37 },
    { label: '3:10 AM', value: minutes5 * 38 },
    { label: '3:15 AM', value: minutes5 * 39 },
    { label: '3:20 AM', value: minutes5 * 40 },
    { label: '3:25 AM', value: minutes5 * 41 },
    { label: '3:30 AM', value: minutes5 * 42 },
    { label: '3:35 AM', value: minutes5 * 43 },
    { label: '3:40 AM', value: minutes5 * 44 },
    { label: '3:45 AM', value: minutes5 * 45 },
    { label: '3:50 AM', value: minutes5 * 46 },
    { label: '3:55 AM', value: minutes5 * 47 },
 
    { label: '4:00 AM', value: minutes5 * 48 },
    { label: '4:05 AM', value: minutes5 * 49 },
    { label: '4:10 AM', value: minutes5 * 50 },
    { label: '4:15 AM', value: minutes5 * 51 },
    { label: '4:20 AM', value: minutes5 * 52 },
    { label: '4:25 AM', value: minutes5 * 53 },
    { label: '4:30 AM', value: minutes5 * 54 },
    { label: '4:35 AM', value: minutes5 * 55 },
    { label: '4:40 AM', value: minutes5 * 56 },
    { label: '4:45 AM', value: minutes5 * 57 },
    { label: '4:50 AM', value: minutes5 * 58 },
    { label: '4:55 AM', value: minutes5 * 59 },
  
    { label: '5:00 AM', value: minutes5 * 60 },
    { label: '5:05 AM', value: minutes5 * 61 },
    { label: '5:10 AM', value: minutes5 * 62 },
    { label: '5:15 AM', value: minutes5 * 63 },
    { label: '5:20 AM', value: minutes5 * 64 },
    { label: '5:25 AM', value: minutes5 * 65 },
    { label: '5:30 AM', value: minutes5 * 66 },
    { label: '5:35 AM', value: minutes5 * 67 },
    { label: '5:40 AM', value: minutes5 * 68 },
    { label: '5:45 AM', value: minutes5 * 69 },
    { label: '5:50 AM', value: minutes5 * 70 },
    { label: '5:55 AM', value: minutes5 * 71 },
   
    { label: '6:00 AM', value: minutes5 * 71 },
    { label: '6:05 AM', value: minutes5 * 73 },
    { label: '6:10 AM', value: minutes5 * 74 },
    { label: '6:15 AM', value: minutes5 * 75 },
    { label: '6:20 AM', value: minutes5 * 76 },
    { label: '6:25 AM', value: minutes5 * 77 },
    { label: '6:30 AM', value: minutes5 * 78 },
    { label: '6:35 AM', value: minutes5 * 79 },
    { label: '6:40 AM', value: minutes5 * 80 },
    { label: '6:45 AM', value: minutes5 * 81 },
    { label: '6:50 AM', value: minutes5 * 82 },
    { label: '6:55 AM', value: minutes5 * 83 },

    { label: '7:00 AM', value: minutes5 * 84 },
    { label: '7:05 AM', value: minutes5 * 85 },
    { label: '7:10 AM', value: minutes5 * 86 },
    { label: '7:15 AM', value: minutes5 * 87 },
    { label: '7:20 AM', value: minutes5 * 88 },
    { label: '7:25 AM', value: minutes5 * 89 },
    { label: '7:30 AM', value: minutes5 * 90 },
    { label: '7:35 AM', value: minutes5 * 91 },
    { label: '7:40 AM', value: minutes5 * 92 },
    { label: '7:45 AM', value: minutes5 * 93 },
    { label: '7:50 AM', value: minutes5 * 94 },
    { label: '7:55 AM', value: minutes5 * 95 },
   
    { label: '8:00 AM', value: minutes5 * 96 },
    { label: '8:05 AM', value: minutes5 * 97 },
    { label: '8:10 AM', value: minutes5 * 98 },
    { label: '8:15 AM', value: minutes5 * 99 },
    { label: '8:20 AM', value: minutes5 * 100 },
    { label: '8:25 AM', value: minutes5 * 101 },
    { label: '8:30 AM', value: minutes5 * 102 },
    { label: '8:35 AM', value: minutes5 * 103 },
    { label: '8:40 AM', value: minutes5 * 104 },
    { label: '8:45 AM', value: minutes5 * 105 },
    { label: '8:50 AM', value: minutes5 * 106 },
    { label: '8:55 AM', value: minutes5 * 107 },
  
    { label: '9:00 AM', value: minutes5 * 108 },
    { label: '9:05 AM', value: minutes5 * 109 },
    { label: '9:10 AM', value: minutes5 * 110 },
    { label: '9:15 AM', value: minutes5 * 111 },
    { label: '9:20 AM', value: minutes5 * 112 },
    { label: '9:25 AM', value: minutes5 * 113 },
    { label: '9:30 AM', value: minutes5 * 114 },
    { label: '9:35 AM', value: minutes5 * 115 },
    { label: '9:40 AM', value: minutes5 * 116 },
    { label: '9:45 AM', value: minutes5 * 117 },
    { label: '9:50 AM', value: minutes5 * 118 },
    { label: '9:55 AM', value: minutes5 * 119 },

    { label: '10:00 AM', value: minutes5 * 120 },
    { label: '10:05 AM', value: minutes5 * 121 },
    { label: '10:10 AM', value: minutes5 * 122 },
    { label: '10:15 AM', value: minutes5 * 123 },
    { label: '10:20 AM', value: minutes5 * 124 },
    { label: '10:25 AM', value: minutes5 * 125 },
    { label: '10:30 AM', value: minutes5 * 126 },
    { label: '10:35 AM', value: minutes5 * 127 },
    { label: '10:40 AM', value: minutes5 * 128 },
    { label: '10:45 AM', value: minutes5 * 129 },
    { label: '10:50 AM', value: minutes5 * 130 },
    { label: '10:55 AM', value: minutes5 * 131 },

    { label: '11:00 AM', value: minutes5 * 132 },
    { label: '11:05 AM', value: minutes5 * 133 },
    { label: '11:10 AM', value: minutes5 * 134 },
    { label: '11:15 AM', value: minutes5 * 135 },
    { label: '11:20 AM', value: minutes5 * 136 },
    { label: '11:25 AM', value: minutes5 * 137 },
    { label: '11:30 AM', value: minutes5 * 138 },
    { label: '11:35 AM', value: minutes5 * 139 },
    { label: '11:40 AM', value: minutes5 * 140 },
    { label: '11:45 AM', value: minutes5 * 141 },
    { label: '11:50 AM', value: minutes5 * 142 },
    { label: '11:55 AM', value: minutes5 * 143 },
   
    { label: '12:00 PM', value: minutes5 * 144 },
    { label: '12:05 PM', value: minutes5 * 145 },
    { label: '12:10 PM', value: minutes5 * 146 },
    { label: '12:15 PM', value: minutes5 * 147 },
    { label: '12:20 PM', value: minutes5 * 148 },
    { label: '12:25 PM', value: minutes5 * 149 },
    { label: '12:30 PM', value: minutes5 * 150 },
    { label: '12:35 PM', value: minutes5 * 151 },
    { label: '12:40 PM', value: minutes5 * 152 },
    { label: '12:45 PM', value: minutes5 * 153 },
    { label: '12:50 PM', value: minutes5 * 154 },
    { label: '12:55 PM', value: minutes5 * 155 },

    { label: '1:00 PM', value: minutes5 * 156 },
    { label: '1:05 PM', value: minutes5 * 157 },
    { label: '1:10 PM', value: minutes5 * 158 },
    { label: '1:15 PM', value: minutes5 * 159 },
    { label: '1:20 PM', value: minutes5 * 160 },
    { label: '1:25 PM', value: minutes5 * 161 },
    { label: '1:30 PM', value: minutes5 * 162 },
    { label: '1:35 PM', value: minutes5 * 163 },
    { label: '1:40 PM', value: minutes5 * 164 },
    { label: '1:45 PM', value: minutes5 * 165 },
    { label: '1:50 PM', value: minutes5 * 166 },
    { label: '1:55 PM', value: minutes5 * 167 },

    { label: '2:00 PM', value: minutes5 * 168 },
    { label: '2:05 PM', value: minutes5 * 169 },
    { label: '2:10 PM', value: minutes5 * 170 },
    { label: '2:15 PM', value: minutes5 * 171 },
    { label: '2:20 PM', value: minutes5 * 172 },
    { label: '2:25 PM', value: minutes5 * 173 },
    { label: '2:30 PM', value: minutes5 * 174 },
    { label: '2:35 PM', value: minutes5 * 175 },
    { label: '2:40 PM', value: minutes5 * 176 },
    { label: '2:45 PM', value: minutes5 * 177 },
    { label: '2:50 PM', value: minutes5 * 178 },
    { label: '2:55 PM', value: minutes5 * 179 },

    { label: '3:00 PM', value: minutes5 * 180 },
    { label: '3:05 PM', value: minutes5 * 181 },
    { label: '3:10 PM', value: minutes5 * 182 },
    { label: '3:15 PM', value: minutes5 * 183 },
    { label: '3:20 PM', value: minutes5 * 184 },
    { label: '3:25 PM', value: minutes5 * 185 },
    { label: '3:30 PM', value: minutes5 * 186 },
    { label: '3:35 PM', value: minutes5 * 187 },
    { label: '3:40 PM', value: minutes5 * 188 },
    { label: '3:45 PM', value: minutes5 * 189 },
    { label: '3:50 PM', value: minutes5 * 190 },
    { label: '3:55 PM', value: minutes5 * 191 },
  
    { label: '4:00 PM', value: minutes5 * 192 },
    { label: '4:05 PM', value: minutes5 * 193 },
    { label: '4:10 PM', value: minutes5 * 194 },
    { label: '4:15 PM', value: minutes5 * 195 },
    { label: '4:20 PM', value: minutes5 * 196 },
    { label: '4:25 PM', value: minutes5 * 197 },
    { label: '4:30 PM', value: minutes5 * 198 },
    { label: '4:35 PM', value: minutes5 * 199 },
    { label: '4:40 PM', value: minutes5 * 200 },
    { label: '4:45 PM', value: minutes5 * 201 },
    { label: '4:50 PM', value: minutes5 * 202 },
    { label: '4:55 PM', value: minutes5 * 203 },

    { label: '5:00 PM', value: minutes5 * 204 },
    { label: '5:05 PM', value: minutes5 * 205 },
    { label: '5:10 PM', value: minutes5 * 206 },
    { label: '5:15 PM', value: minutes5 * 207 },
    { label: '5:20 PM', value: minutes5 * 208 },
    { label: '5:25 PM', value: minutes5 * 209 },
    { label: '5:30 PM', value: minutes5 * 210 },
    { label: '5:35 PM', value: minutes5 * 211 },
    { label: '5:40 PM', value: minutes5 * 212 },
    { label: '5:45 PM', value: minutes5 * 213 },
    { label: '5:50 PM', value: minutes5 * 214 },
    { label: '5:55 PM', value: minutes5 * 215 },
   
    { label: '6:00 PM', value: minutes5 * 216 },
    { label: '6:05 PM', value: minutes5 * 217 },
    { label: '6:10 PM', value: minutes5 * 218 },
    { label: '6:15 PM', value: minutes5 * 219 },
    { label: '6:20 PM', value: minutes5 * 220 },
    { label: '6:25 PM', value: minutes5 * 221 },
    { label: '6:30 PM', value: minutes5 * 222 },
    { label: '6:35 PM', value: minutes5 * 223 },
    { label: '6:40 PM', value: minutes5 * 224 },
    { label: '6:45 PM', value: minutes5 * 225 },
    { label: '6:50 PM', value: minutes5 * 226 },
    { label: '6:55 PM', value: minutes5 * 227 },

    { label: '7:00 PM', value: minutes5 * 228 },
    { label: '7:05 PM', value: minutes5 * 229 },
    { label: '7:10 PM', value: minutes5 * 230 },
    { label: '7:15 PM', value: minutes5 * 231 },
    { label: '7:20 PM', value: minutes5 * 232 },
    { label: '7:25 PM', value: minutes5 * 233 },
    { label: '7:30 PM', value: minutes5 * 234 },
    { label: '7:35 PM', value: minutes5 * 235 },
    { label: '7:40 PM', value: minutes5 * 236 },
    { label: '7:45 PM', value: minutes5 * 237 },
    { label: '7:50 PM', value: minutes5 * 238 },
    { label: '7:55 PM', value: minutes5 * 239 },
   
    { label: '8:00 PM', value: minutes5 * 240 },
    { label: '8:05 PM', value: minutes5 * 241 },
    { label: '8:10 PM', value: minutes5 * 242 },
    { label: '8:15 PM', value: minutes5 * 243 },
    { label: '8:20 PM', value: minutes5 * 244 },
    { label: '8:25 PM', value: minutes5 * 245 },
    { label: '8:30 PM', value: minutes5 * 246 },
    { label: '8:35 PM', value: minutes5 * 247 },
    { label: '8:40 PM', value: minutes5 * 248 },
    { label: '8:45 PM', value: minutes5 * 249 },
    { label: '8:50 PM', value: minutes5 * 250 },
    { label: '8:55 PM', value: minutes5 * 251 },
  
    { label: '9:00 PM', value: minutes5 * 252 },
    { label: '9:05 PM', value: minutes5 * 253 },
    { label: '9:10 PM', value: minutes5 * 254 },
    { label: '9:15 PM', value: minutes5 * 255 },
    { label: '9:20 PM', value: minutes5 * 256 },
    { label: '9:25 PM', value: minutes5 * 257 },
    { label: '9:30 PM', value: minutes5 * 258 },
    { label: '9:35 PM', value: minutes5 * 259 },
    { label: '9:40 PM', value: minutes5 * 260 },
    { label: '9:45 PM', value: minutes5 * 261 },
    { label: '9:50 PM', value: minutes5 * 262 },
    { label: '9:55 PM', value: minutes5 * 263 },

    { label: '10:00 PM', value: minutes5 * 264 },
    { label: '10:05 PM', value: minutes5 * 265 },
    { label: '10:10 PM', value: minutes5 * 266 },
    { label: '10:15 PM', value: minutes5 * 267 },
    { label: '10:20 PM', value: minutes5 * 268 },
    { label: '10:25 PM', value: minutes5 * 279 },
    { label: '10:30 PM', value: minutes5 * 270 },
    { label: '10:35 PM', value: minutes5 * 271 },
    { label: '10:40 PM', value: minutes5 * 272 },
    { label: '10:45 PM', value: minutes5 * 273 },
    { label: '10:50 PM', value: minutes5 * 274 },
    { label: '10:55 PM', value: minutes5 * 275 },

    { label: '11:00 PM', value: minutes5 * 276 },
    { label: '11:05 PM', value: minutes5 * 277 },
    { label: '11:10 PM', value: minutes5 * 278 },
    { label: '11:15 PM', value: minutes5 * 279 },
    { label: '11:20 PM', value: minutes5 * 280 },
    { label: '11:25 PM', value: minutes5 * 281 },
    { label: '11:30 PM', value: minutes5 * 282 },
    { label: '11:35 PM', value: minutes5 * 283 },
    { label: '11:40 PM', value: minutes5 * 284 },
    { label: '11:45 PM', value: minutes5 * 285 },
    { label: '11:50 PM', value: minutes5 * 286 },
    { label: '11:55 PM', value: minutes5 * 287 },
]

const Time = ({start, end, onStartChange, onEndChange, startTitle, endTitle}) => {

    const [allTimes, setAllTimes] = useState(null)

    useEffect(() => {

        const _start = moment.unix(start).utc().format('h:mm A')
        const _end = moment.unix(end).utc().format('h:mm A')
        const _times = JSON.parse(JSON.stringify(times))

        if(!times.some(t => t.label === _start)) {
            _times.push({label: <span>{_start} (Current Clock In)</span>, value: start})
        }
      
        if(!times.some(t => t.label === _end)) {
            _times.push({label: <span>{_end} (Current Clock Out)</span>, value: end})
        }

        _times.sort((a, b) => a.value < b.value ? -1 : 1)
        setAllTimes(_times)


    }, [start, end])

    if(!allTimes) return <></>

    return (
        <Row>
            <Col xs={6}>
                <ReactSelect
                    title={startTitle ? startTitle : "Clocked In"}
                    formGroup={true}
                    onChange={(obj) => onStartChange(obj.value)}
                    options={allTimes}
                    value={start}
                />    
            </Col>
            <Col xs={6}>
                <ReactSelect
                    title={endTitle ? endTitle : "Clocked Out"}
                    formGroup={true}
                    onChange={(obj) => onEndChange(obj.value)}
                    options={allTimes}
                    value={end}
                />    
            </Col>
        </Row>
    )
}

export default Time