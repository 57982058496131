// import r from './r'

import store from 'store';
import keys from 'keys';

import errorLog from './errorLog'

// SEVER, PROCESSING, TALK

import Axios from 'axios';

// r stands for request
const request = (domain, method, url, data, stream) => {
    return new Promise (async resolve => {

        const state = store.getState()
        const domains = state.load.DOMAINS;
        const baseDomain = domains ? domains[domain] : keys.API_URL;
        const user = state.auth.viewing_user;

        const start = Math.floor(new Date() / 1)

        try {
            const payload = {
                method,
                url: baseDomain + url,
                headers: {
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`,
                },
                withCredentials: true,
                data,
            }

            if(stream) payload.responseType = 'stream'

            const result = await Axios(payload)

            const end = Math.floor(new Date() / 1)
            if(user && user.settings.log_errors) errorLog.store(result, start, end)

            resolve(result.data)

        } catch(e) {
            const end = Math.floor(new Date() / 1)
            if(user && user.settings.log_errors) errorLog.store(e, start, end)

            try {
                const err = JSON.parse(JSON.stringify(e.response.data))
                if(err && err.error === 'THREAT DETECTED') {
                    if(!window.location.href.includes('errors/threat')) return window.location.href = '/errors/threat'
                }

                return resolve({success: false, message: err})
            } catch(e) {
                console.log(e)
                return resolve({success: false, message: e})
            }
        
        }

    })
}

export default request;