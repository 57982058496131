// <div className="section">
//     <Row>
//         <Col md={hasPrivilege('CUSTOM_FIELDS.READ') ? 6 : 12}>
//             {view === 'People' ? (
//                 <button className="btn btn-sm px-0 btn-block btn-success"><i className="fas fa-users" /> People</button>
//             ) : (
//                 <button onClick={() => setView('People')} className="btn btn-sm px-0 btn-block btn-outline-warning"><i className="fas fa-users" /> People</button>
//             )}
//         </Col>

//         {hasPrivilege('CUSTOM_FIELDS.READ') ? (
//             <Col md={6} className="text-right">
//                 {view === 'People' ? (
//                     <button onClick={() => setView('Details')}  className="btn btn-sm px-0 btn-block btn-outline-warning"><i className="fas fa-edit" /> Details</button>
//                 ) : (
//                     <button className="btn btn-sm px-0 btn-block btn-success"><i className="fas fa-edit" /> Details</button>
//                 )}
//             </Col>
//         ) : ''}
        
//     </Row>
// </div>

import { Col, Row } from "reactstrap";
import hasPrivilege from 'utils/hasPrivilege'

const MattersViewSidebarLeft = ({view, setView, setShowAI}) => (
    hasPrivilege('VIEW.AI') ? (
        <div className="section">
            <button onClick={() => setShowAI(true)}  className="btn btn-sm px-0 btn-block btn-success">
                <i className="fa-solid fa-lightbulb" /> ASK MERLIN
            </button>
        </div>
    ) : ''
)

export default MattersViewSidebarLeft