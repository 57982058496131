// "_id": 1,
// "company": 1,
// "division": 1,
// "matter": 1,
// "name": 1,
// "assigned_to": 1,
// "due_date": 1,
// "priority": 1,
// "finished_on": 1,
// "created_at": 1,

import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Badge, CardBody } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'
import ReactSelect from 'components/functional/inputs/ReactSelect';

import { getUrlParameter } from "utils/urls";

import api from 'api';

import Circle from 'components/markup/loading/Circle'

const noData = { data: [], total_documents: 0 };

const MattersAll = ({selected_division, viewing_user}) => {
    const [ now ] = useState(Math.floor(new Date() / 1000));

    const [ type, setType ] = useState('all');
    const [ checklist, setChecklist ] = useState('all');
    const [ priority, setPriority ] = useState('all');

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState(noData)
    const [ hasDueDate, setDueDate ] = useState(true)
    const [ onlyMe, setOnlyMe ] = useState(false)
    const [ sort, setSort ] = useState('due_date +1')
    const [ hideTable, setHideTable ] = useState(false)
    const [ err, setErr ] = useState(false)

    const onSetType = useCallback((val) => {
        setType(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    const onSetChecklist = useCallback((val) => {
        setChecklist(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
    const onSetPriority = useCallback((val) => {
        setPriority(val)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
   
    const onSetOnlyMe = useCallback((_onlyMe) => {
        setOnlyMe(_onlyMe)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])
  
    const onSetHasDueDate = useCallback((_hasDueDate) => {
        setDueDate(_hasDueDate)
        setHideTable(true)
        setTableData(noData)

        setTimeout(() => {
            setHideTable(false)
        }, 500)
    }, [])

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(selected_division._id) params.filter.division = selected_division._id



        params.filter.module = 'bk'

        if(type === 'tasks') params.filter.is_deadline = false;
        if(type === 'deadlines') {
            params.filter.is_deadline = true;
            params.filter.category = { $ne: 'bk_hearing' };
        }
        if(type === 'hearings') {
            params.filter.is_deadline = true;
            params.filter.category = 'bk_hearing';
        }

        if(checklist === 'yes') params.filter.checklist_percent = 100
        if(checklist === 'no') params.filter.checklist_percent =  { $ne: 100 };

        if(priority !== 'all') params.filter.priority = priority

        // { value: 'all', label: <span className="text-white">-</span> },
        // { value: 'tasks', label: 'Only Tasks' },
        // { value: 'deadlines', label: 'Only Deadlines' },
        // { value: 'hearings', label: 'Only Hearings' },

        const query = await api.outstanding_items.search({ ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "name",
            text: 'Task',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                const overdue = row.due_date && row.due_date < now ? true : false;

                return (
                    <div> 
                        <Link 
                            to={`/matters/${row.matter}?tab=Items&docsTab=Outstanding`} 
                            className={`font-weight-bold ${overdue ? 'text-danger text-uppercase' : 'text-dark'}`}
                        >
                            {row.is_deadline ? <i className="fas fa-clock text-info mr-2" /> : ''}
                            {row.category === 'bk_hearing' ? <i className="fas fa-calendar text-warning mr-2" /> : ''}
                            {row.name}
                        </Link>


                        {row.due_date ? (
                            <div className={`${overdue ? 'text-danger font-weight-bold text-uppercase' : 'text-muted'}`}>
                                {moment.unix(row.due_date).format('MMM Do, h:mm A')}
                            </div>
                        ) : ''}
                    </div>
                )
            },
        },
        {
            dataField: "matter",
            text: 'Matter',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : 
                <div>
                    <div><Link to={`/matters/${row.matter}`} className="text-dark font-weight-bold"><ObjectFinder collection="matters" _id={row.matter} /></Link></div>
                </div>
            )
        },
        {
            dataField: "checklist",
            text: 'Checklist',
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => {
                if(row.name === 'no data' || !row.checklist) return '';

                const finished = row.checklist.filter(r => r.finished).length
                if(finished === row.checklist.length) return (
                    <div className="text-center"><i className="fas fa-check-circle text-success" /></div>
                )

                return (
                    <div className="text-center">
                        {finished} / {row.checklist.length}
                    </div>
                )
            }
        },
        {
            dataField: "assigned_to",
            text: 'Assigned To',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.assigned_to ? '' :
                row.assigned_to.map((c, i) => (
                    <span>{i !== 0 ? ', ' : ''}<ObjectFinder collection="users" _id={c} /></span>
                ))
            )
        },
        {
            dataField: "priority",
            text: 'Priority',
            formatter: (cell, row) => {
                if(row.name === 'no data') return ''
                if(row.priority === 1) return <Badge color="danger">Highest</Badge>
                if(row.priority === 2) return <Badge color="warning">High</Badge>
                if(row.priority === 3) return <Badge color="info">Default</Badge>
                if(row.priority === 4) return <Badge color="purple">Low</Badge>
                if(row.priority === 5) return <Badge color="success">Lowest</Badge>
            }
        },
        
        
        // {
        //     dataField: "due_date",
        //     text: 'due',
        //     formatter: (cell, row) => (
        //         row.name === 'no data' ? '' : (
        //             <div>
        //                 <div>{!row.due_date ? '-' : row.due_date < Math.floor(new Date() / 1000) ? <span className="text-danger">{moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</span> : moment.unix(row.due_date).format('MM/DD/YYYY h:mm A')}</div>
        //             </div>
        //         )
        //     )
        // },
        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/matters/${row.matter}?tab=Items&docsTab=Outstanding`,
                                },
                                { 
                                    icon: 'fas fa-edit',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/matters/${row.matter}?tab=Items&docsTab=Outstanding`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError className="py-4 mx-5" err={err} />

    const title = 'Bankruptcy Tasks'
    const description = getUrlParameter('type') === 'overdue' ? 'All Items with a due date before the current time' : 'Showing all Items assigned to you.'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            leftColSize={5}

        />

        <Card>
            <CardBody>

                <Row>
                    <Col md={4}>
                        <ReactSelect
                            title={"Task Type"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetType(obj.value)}
                            options={[
                                { value: 'all', label: <span className="text-white">-</span> },
                                { value: 'tasks', label: 'Only Tasks' },
                                { value: 'deadlines', label: 'Only Deadlines' },
                                { value: 'hearings', label: 'Only Hearings' },
                            ]}
                            value={type}
                        />    
                    </Col>
                    <Col md={4}>
                        <ReactSelect
                            title={"Checklist Finished"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetChecklist(obj.value)}
                            options={[
                                { value: 'all', label: <span className="text-white">-</span> },
                                { value: 'yes', label: 'Yes' },
                                { value: 'no', label: 'No' },
                            ]}
                            value={checklist}
                        />    
                    </Col>
                    <Col md={4}>
                        <ReactSelect
                            title={"Priority"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetPriority(obj.value)}
                            options={[
                                { value: 'all', label: <span className="text-white">-</span> },
                                { value: 1, label: 'Priority 1 (Highest)', },
                                { value: 2, label: 'Priority 2 (High)'  },
                                { value: 3, label: 'Priority 3 (Default)'  },
                                { value: 4, label: 'Priority 4 (Low)'  },
                                { value: 5, label: 'Priority 5 (Lowest)'  },
                            ]}
                            value={priority}
                        />    
                    </Col>
                </Row>

            </CardBody>
        </Card>

        {hideTable ? <Circle /> : (
            <Container fluid>
        
                <Card className="card-color card-primary table-fixed table-no-stripes">
                    <Table
                        tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                        placeholder="Search By Item Name..."
                        query={query}
                        columns={columns}
                        data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                        totalDocuments={tableData.total_documents}
                        sizePerPage={sizePerPage}
                        onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                    />    
                </Card>

            </Container>
            )}
        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
