import { toast } from 'react-toastify';

import CopyToClipboard from 'react-copy-to-clipboard';

const ContactsUpdatePortal = ({contact}) => {

    const link = `https://fairmax.intuidesk.com/register?email=${contact.email}`

    if(!contact.email) return (
        <p className="text-sm">
            <i className="fas fa-info-circle text-warning mr-2" /> 
            A contact must have a valid email on file before their portal can be accessed.
        </p>
    )

    return (
        <div>

            <h2>Portal Link:</h2>

            <span className="cursor-pointer">
                <CopyToClipboard 
                    text={link}
                    onCopy={() => {
                        toast.info('Log ID Copied To Clipboard')
                    }}
                >
                    <span>{link} <i className="fas fa-copy ml-2 text-info" /></span>
                </CopyToClipboard>
            </span>
           
        </div>
    )

}

export default ContactsUpdatePortal