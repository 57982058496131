import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from "reactstrap";

const OutstandingItemsEditChecklistItem = ({item, onItemChange, viewing_user}) => {

    const [editing, setEditing] = useState(false)

    const onSetEditing = useCallback((val) => {
        setEditing(val);
        setTimeout(() => {
            const el = document.getElementById(`archk-${item.id}-input`)
            if(el) el.focus();
        }, [250])
    }, [item.id])

    const onInputChange = useCallback((field, val) => {
        const obj = JSON.parse(JSON.stringify(item));
        obj[field] = val

        if(field === 'finished') obj['finished_by'] = viewing_user._id

        onItemChange(obj)
    }, [item, onItemChange, viewing_user._id])
 
    return (

        <div>
            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-${item.id}`}
                        type="checkbox"
                        checked={item.finished}
                        onChange={e => onInputChange('finished', !item.finished)}

                    />
                    <label className="custom-control-label" htmlFor={`archk-${item.id}`} />
                    {editing ? (
                        <Input 
                            id={`archk-${item.id}-input`}
                            className='d-inline-block'
                            value={item.name}
                            onChange={e => onInputChange('name', e.target.value)}
                            onBlur={() => {
                                setEditing(false)
                                if(!item.name) onInputChange('name', "New Item")
                            }}
                        />
                    ) : (
                        <span className='cursor-pointer' onClick={() => onSetEditing(true)}>{item.name}</span>
                    )}
                    
                </div>
            </FormGroup>
        </div>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(OutstandingItemsEditChecklistItem);