import { useEffect, useState } from 'react';

import A from 'components/markup/links/A'
import ReactSelect from 'components/functional/inputs/ReactSelect';
import Circle from 'components/markup/loading/Circle';

import formatText from 'utils/formatText'

import moment from 'moment'

import Attachments from '../Main/Attachments'

const TextsSidebarRight = ({active, thread, allAttachments}) => {

    if(!active && !thread) return (
        <div className="archk-messaging-sidebar-right z-depth-1">
            
            <div className="archk-messaging-spacer position-relative border-bottom ">
                <h3 className=" my-3 pl-3">
                    <i className="fas fa-info-circle text-warning mr-2" /> No Contact Selected
                </h3>
            </div>
        </div>
    )
  
    if(!thread) return (
        <div className="archk-messaging-sidebar-right z-depth-1">
            
            <div className="archk-messaging-spacer position-relative border-bottom ">
                <h3 className=" my-3 pl-3">
                    <Circle />
                </h3>
            </div>
        </div>
    )

    return (

        <div className="archk-messaging-sidebar-right z-depth-1">
        
            <div className="archk-messaging-spacer position-relative border-bottom">
                <h3 className=" my-3 pl-3">
                    <i className="fas fa-gavel mr-2 text-success" /> Contact Matters 
                </h3>
            </div>

            {thread.matters.length ? (
                <div>
                   

                    {thread.matters.map(m => (
                        <A href={`/matters/${m.id}`} key={m._id}>
                            <div className={`archk-messaging-spacer archk-text-message-list-item  `}>
            
                                <p className="text-s font-weight bold text-dark mb--1"><small>{m.name}</small></p>
                                <p className="text-sm mb-0  archk-text-message-list-item-description mb-0"><small><b className="text-dark">Last Note:</b> {m.last_note_preview ? m.last_note_preview : ''}</small></p>
                                <p className="text-cente text-sm text-dark mb-0"><small>Created On: {moment.unix(m.created_at).format('MMM Do, YYYY')}</small></p>
            
                            </div>
                        </A>
                    ))}

                </div>
            ) : (
                <div className={`archk-messaging-spacer text-cente  border-bottom`}>
                    <h5 className=" my-3 pl-3">
                        <i className="fas fa-info-circle text-info mr-2" /> No Matters Found
                    </h5>
                </div>
            )}

            <div className="archk-messaging-spacer position-relative border-bottom">
                <h3 className=" my-3 pl-3">
                    <i className="fas fa-file mr-2 text-success" /> Attachments 
                </h3>
            </div>

         
            
            <div className="archk-messaging-spacer position-relative border-bottom">
                {allAttachments.length ? (
                    <Attachments attachments={allAttachments.filter(a => a.filename)} sidebar={true} />
                ) : (
                    <h5 className=" my-3 pl-3">
                        <i className="fas fa-info-circle text-info mr-2" /> No Attachments Found
                    </h5>
                )}
            </div>
            
        </div>
    )

}

export default TextsSidebarRight