import formatText from './formatText'

export const getIdentifier = (contact) => {

    if(!contact) return ''

    return contact.display_name ? <span className="text-capitalize">{contact.display_name}</span> :
    contact.email ? contact.email :
    contact.phone ? formatText(contact.phone).phone() : 
    ''

}

