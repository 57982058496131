import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, Form, CardHeader, CardTitle } from "reactstrap";

import Circle from 'components/markup/loading/Circle';

import api from 'api';
import ApiError from 'components/markup/layout/ApiError'

const UserSettingsMatter = ({viewing_user, divisions}) => {

    const [err, setErr] = useState(null)
    const [userDepartments, setUserDepartments] = useState(null)

    const userDivisions = divisions.filter(d => viewing_user.divisions.includes(d._id))

    const fetchData = useCallback(async () => {
        const data = await api.division_departments.search({ limit: 500, filter: { users: { $in: [viewing_user._id] } } })
        if(!data.data) return setErr(data.message)
        
        setUserDepartments(data.data.documents)
    }, [viewing_user._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} />
    if(!userDepartments) return <Circle className="pt-4" />

    return (
        <Form>
            <h3>My Divisions & Departments</h3>
             {userDivisions.length ? userDivisions.map(division => {

                const departments = userDepartments.filter(dd => dd.division === division._id)

                return (
                    <Card key={division._id}>

                        <CardHeader>
                            <CardTitle className="mb-0 text-dark font-weight-bold">
                                <span></span> {division.display_name}
                            </CardTitle>
                        </CardHeader>

                        <CardBody>
                            <ul className='mb-0 pl-0'>
                                {departments.length ? departments.map(department => (
                                    <li className='list-style-none' >
                                        {' - '}
                                        {department.name}
                                        {department.supervisors && department.supervisors.includes(viewing_user._id) ? (
                                            <span> (Supervisor)</span>
                                        ) : ''}
                                    </li>
                                )) : (
                                    <li className='list-style-none' >
                                        
                                        <i className="fas fa-times mr-2 text-danger" /> No Departments Found 
                                    </li>
                                )}
                            </ul>
                        </CardBody>
                    </Card>
                )
            }) : (
                <CardFooter>No Divisions Found</CardFooter>
            )}

        </Form>

    );
}


const mapStateToProps = state => {
    return {
        divisions: state.divisions,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(UserSettingsMatter);