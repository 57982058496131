const onUrlChange = (onChange) => {
    let currentUrl = window.location.href

    setInterval(() => {
        if(window.location.href !== currentUrl) {
            currentUrl = window.location.href
            onChange(currentUrl)
        }
    }, 500)
}


export default onUrlChange;