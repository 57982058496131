import { connect } from 'react-redux';

const ModuleBKTracker = ({ matter }) => {

    return (
        <div className="archk-matter-history bg-secondary mb-4 border py-3">
            <div className="stepper-wrapper">


                {/* <div key={i} className={`stepper-item ${i + 1 === steps.length ? 'active-item' : 'completed'}`}> */}
                <div className={`stepper-item completed`}>

                    <div className="step-counter"><i className="fas fa-check font-weight-bold  text-success" /></div>
                    <div className="step-name">Not Filed</div>
                </div>

                <div className={`stepper-item active-item`}>

                    <div className="step-counter"><i className="fas fa-check font-weight-bold  text-success" /></div>
                    <div className="step-name">New Case Filed</div>
                </div>

                <div className={`stepper-item`}>

                    <div className="step-counter"><i className="fas fa-check font-weight-bold  text-success" /></div>
                    <div className="step-name">341</div>
                </div>
            
                <div className={`stepper-item`}>

                    <div className="step-counter"><i className="fas fa-check font-weight-bold  text-success" /></div>
                    <div className="step-name">Confirmation</div>
                </div>

                <div className={`stepper-item`}>

                    <div className="step-counter"><i className="fas fa-check font-weight-bold  text-success" /></div>
                    <div className="step-name">Finished</div>
                </div>


                {matter.workflow_step.recommended_next_step ? (
                    <div className="stepper-item">
                        <div className="step-counter"></div>
                        <div className="step-name">Step Name</div>
                    </div>
                ) : ''}


            </div>
        </div>

    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKTracker);


