import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";


import api from 'api';

import { toast } from 'react-toastify';

const ModalGarnishmentAdd = ({showModal, toggleModal, matter, onSuccess, fetchInfo}) => {

    const [name, setName] = useState('')

    const onSave = useCallback(async () => {
        if(!name) return toast.info(`You must set a name before creating a garnishment.`)

        const result = await api.modules.bk.garnishments.create({ name, matter: matter._id })
        if(!result.success) return toast.error(`Something went wrong creating this garnishment, please try again.`);

        toast.success(`Garnishment Created Successfully`)
        if(onSuccess) onSuccess(result.data);

        fetchInfo()
        setName('')
        toggleModal()

    }, [matter._id, name, onSuccess, toggleModal, fetchInfo])

    useEffect(() => {
        if(showModal) {
            setName('')
        }
    }, [showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Garnishment</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div>

                <div className="modal-body border-bottom">

                    <FormGroup>
                        <label className='form-control-label'>Garnishment Name</label>
                        <Input 
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>

                </div>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>

                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Add Garnishment
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalGarnishmentAdd);