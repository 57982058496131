import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import HeaderTabs from 'components/markup/layout/HeaderTabs';

import MyTasks from './MyTasks';
import StepTasks from './StepTasks';
import FinishedTasks from './FinishedTasks';
import MoveStep from './MoveStep';
import JumpStep from './JumpStep';
import AllTasks from './AllTasks';
import History from './History';
// import List from './List';
// import Missing from './Missing';
// import Signing from './Signing';

import { getUrlParameter, setUrlParameter } from 'utils/urls';

import hasPrivilege from 'utils/hasPrivilege'
// hasPrivilege('FUNCTION.JUMP_STEPS')

const tabs = ['MyTasks', 'StepTasks', 'FinishedTasks', 'MoveStep', 'JumpStep', 'History']

const MatterViewMainTasks = ({setIsMoving}) => {

    const [tab, setTab] = useState('StepTasks')

    const onSetTab = useCallback((_tab) => {
        setTab(_tab);
        setUrlParameter('docsTab', _tab)
    }, [])

    useEffect(() => {
        const t = getUrlParameter('docsTab')
        if(tabs.includes(t)) setTab(t)
    }, [])

    return (
        <div className="section pt-0" style={{minHeight: 250}}>

            <div className="section bg-white z-depth- border-top text-white pt-3 borer-top mt-0 pb-0 " style={{borderBottom: 'none'}}>
                <HeaderTabs 
                    className="mb-0"
                    tab={tab}
                    setTab={(tab) => onSetTab(tab)}
                    tabs={[
                        { value: 'StepTasks', name: <span className="text-whit"><i className="fas fa-shoe-prints " /> Step Tasks</span> },
                        { value: 'MyTasks', name: <span className="text-whit"><i className="fas fa-user pl-1 " /> My Tasks</span> },
                        { value: 'AllTasks', name: <span className="text-whit"><i className="fas fa-list pl-1 " /> All Tasks</span> },
                        { value: 'FinishedTasks', name: <span className="text-whit"><i className="fas fa-check " /> Finished Tasks</span> },
                        { value: 'MoveStep', name: <span className="text-whit"><i className="fas fa-shoe-prints " /> Move Step</span> },
                        
                        ...(hasPrivilege('FUNCTION.JUMP_STEPS') ? [
                            { value: 'JumpStep', name: <span className="text-whit"><i className="fas fa-shoe-prints " /> Jump Steps</span> }
                        ] : []),
                        
                        { value: 'History', name: <span className="text-whit"><i className="fa-solid fa-clock-rotate-left " /> History</span> },
                        // { value: 'Signing', name: <span className="text-whit"><i className="fas fa-signature " /> Signing</span> },
                        // { value: 'Doc Hunter', name: <span className="text-whit"><i className="fas fa-search-location " /> Doc Hunter</span> },
                    ]}
                />
            </div>

            {tab === 'MyTasks' ? (
                <MyTasks />
            ) : tab === 'AllTasks' ? (
                <AllTasks />
            ) : tab === 'StepTasks' ? (
                <StepTasks />
            ) : tab === 'FinishedTasks' ? (
                <FinishedTasks />
            ) : tab === 'MoveStep' ? (
                <MoveStep setIsMoving={setIsMoving} />
            ) : tab === 'JumpStep' ? (
                <JumpStep setIsMoving={setIsMoving} />
            )  : tab === 'History' ? (
                <History />
            )  : ''}

        </div>

    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainTasks);