import { Row, Col } from 'reactstrap';

const TextButtonRow = ({title, button}) => (
    <Row className="mb-3">
        <Col xs={6} className="align-self-center">
            <h3 className="mb-0">{title}</h3>
        </Col>
        <Col xs={6} className="align-self-center text-right">
            {button ? button : ''}
        </Col>
    </Row>
)

export default TextButtonRow;