import api from 'api'
import { connect } from 'react-redux';
import React, { useCallback, useState, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill-new';

import { Mention, MentionBlot } from 'quill-mention';
import { toast } from 'react-toastify'

Quill.register({ 'modules/mention': Mention }, true);
Quill.register({ "blots/mention": MentionBlot}, true);

const UserComment = ({ selected_matter, collection_name, collection_id, parent_name, onSaved, comment, reply }) => {
    const quillRef = useRef(null);

    const [value, setValue] = useState(comment ? comment.body : reply ? reply : '');
    const [saving, setSaving] = useState(false);

    const extractMentionsFromDelta = (delta) => {
        const mentions = [];
    
        delta.ops.forEach((op) => {
            if(op.insert && op.insert.mention) {
                mentions.push(op.insert.mention);
            }
        });
    
        return mentions;
    };
    
    const handleGetMentions = useCallback(() => {
        const editor = quillRef.current.getEditor();
        const delta = editor.getContents();
    
        const mentions = extractMentionsFromDelta(delta);
        const ids = mentions.map(m => m['id']);

        return ids
    }, [])
  
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['mention']
        ],
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            source: useCallback(async function(searchTerm, renderList, mentionChar) {

                if(!searchTerm) {
                    let roles = selected_matter.roles.filter(r => r && r.user)
                    return renderList(roles.map(u => { return { id: u.user._id, value: u.user.display_name, type: 'test1' } }), searchTerm)
                }

                const data = await api.users.search({
                    searchString: searchTerm,
                    filter: {
                        division: selected_matter.division
                    }
                })

                const users = data.data && data.data.documents ? data.data.documents : []
                return renderList(users.map(u => { return { id: u._id, value: u.display_name, type: 'test2'  } }), searchTerm)

            }, [selected_matter.division, selected_matter.roles])
        }
    };

    const onSave = useCallback(async () => {
        setSaving(true)
        let result;

        if(!comment) {
            console.log('create')
            result = await api.user_comments.create({
                matter: selected_matter._id,
                body: value,
                tagged_users: handleGetMentions(),
                collection_name, 
                collection_id, 
                parent_name
            })
        } else {
            console.log('upate')
            result = await api.user_comments.update(comment._id, {
                body: value,
                tagged_users: handleGetMentions(),
                collection_name, 
                collection_id, 
                parent_name
            })
        }
      
        setSaving(false)

        if(result.data) {
            setValue('')
            onSaved(result.data)
        } else {
            toast.error(`Could not save comment, please try again later.`)
        }

    }, [collection_name, collection_id, handleGetMentions, parent_name, selected_matter._id, value, comment, onSaved])

    return (
        <div>
            <ReactQuill
                placeholder='Add Comment...'
                ref={quillRef}
                value={value}
                onChange={setValue}
                modules={modules}
            />
            <div className='pt-3'>
                {comment ? (
                    <button disabled={saving} className='btn btn-sm btn-outline-info' onClick={onSave}>
                        <i className="fas fa-save mr-2" /> {'Save'}
                    </button>
                ) : (
                    <button disabled={saving} className='btn btn-sm btn-outline-success' onClick={onSave}>
                        {}
                        <i className="fas fa-plus mr-2" /> {reply ? 'Add Reply' : 'Add Comment'}
                    </button>
                )}
            </div>
        </div>
      
    );
  };
  

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        selected_matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(UserComment);