
import Signing    from '../pages/signing/Edit/index';
import CustomViews    from '../pages/custom_views';
import NoteFormatter    from '../pages/NoteFormatter';

const routes = () =>  [


    {
        path: "/custom_views/:custom_view_id",
        component: CustomViews,
    },
    {
        path: "/signing/:matter_id/:template_id",
        component: Signing,
    },
    {
        path: "/utility/note_formatter",
        component: NoteFormatter,
    },

   
]

export default routes;
