import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import NameCircle from 'components/markup/misc/NameCircle';
import ObjectFinder from 'components/system/Objects/Finder';

const NavbarSearchResultsSearch = ({contacts, matters, setShowResults}) => (
    <div className="results-wrapper bg-secondary">
        <div className="results-section">
            <Row className="">

                <Col lg={6} className="p">
                    <ul>
                        {!contacts.length ? (
                            <li className=" d-block hide-hover bg-whit"><i className="fas fa-info-circle text-info mr-2" /> No Contacts Found</li>
                        ) : contacts.map(contact => (
                            <Link key={contact._id} to={`/clients/${contact._id}`}>
                                <li className=" d-block mb-2" onClick={() => setShowResults(false)}>

                                    {contact.display_name ? 
                                        <p className={`identifier text-dark ${contact.email || contact.phone ? 'mb--3' : 'mb-0'}`}> {contact.display_name}</p>
                                    : ''}
                                    {contact.email || contact.phone ? <p className={`text-sm text-muted mb-0`}> {contact.email ? contact.email : contact.phone}</p> : ''}
                                </li>
                            </Link>
                        ))}
                    </ul>
                </Col>

                <Col lg={6} className="">
                    <ul>
                        {!matters.length ? (
                            <li className=" d-block hide-hover bg-whit"><i className="fas fa-info-circle text-info mr-2" /> No Matters Found</li>
                            ) : matters.map(matter => (
                            <Link key={matter._id} to={`/matters/${matter.id}`}>
                                <li className=" d-block mb-2" onClick={() => setShowResults(false)}>
                                    <div className="">
                                        <p className="identifier mb--3 text-dark">{matter.name}</p>
                                        <p className="text-sm text-muted mb-0"><ObjectFinder collection="divisions" _id={matter.division} /> - {matter.current_step_name ? matter.current_step_name : 'No Step Found'}</p>

                                    </div>
                                </li>
                            </Link>
                        ))}
                    </ul>
                </Col>
                
            </Row>
        </div>
    </div>
)

export default NavbarSearchResultsSearch;