import { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import ListItem from './ListItem';

import Circle from 'components/markup/loading/Circle'

import api from 'api'

import ModalToggler from 'components/functional/modals/Toggler'
import SelectContact from './SelectContact'

import Resolve from '../Resolve'
import { toggleStandardLoader } from 'store/functions/system/system';


const limit = 30

const TextsSidebarLeft = ({active, setActive, thread, socket, selected_division}) => {

    const listInnerRef = useRef();

    const [type, setType] = useState('unresolved')
    const [fetchingMore, setFetchingMore] = useState(false)
    const [done, setDone] = useState(false)
    const [threads, setThreads] = useState(null)

    const onSelect = useCallback((contact, division) => {
        let initials = '';
        let identifier = '';

        try {

            if(contact.display_name) identifier = contact.display_name

            if(contact.given_name) {
                initials = contact.given_name.slice(0, 1)
            }

            if(contact.family_name) {
                if(initials) {
                    initials += contact.family_name.slice(0, 1)
                } else {
                    initials = contact.family_name.slice(0, 1)
                }
            }
        } catch(e) {
            console.log(e)
        }

        if(!initials) initials = 'NF'
        if(!identifier) identifier = contact.phone()

        setActive({
            contact: contact._id,
            division: division,
            initials,
            identifier
        })
    }, [setActive])

    const getFilter = useCallback(() => {
        let filter = {}
        if(type === 'unresolved') filter.resolved_at = 0;
        // if(type === 'assigned') {
        //     filter = { assigned: true }
        // } else if(type === 'all') {
        //     filter = { assigned: false }
        // } else  if(type === 'unreturned') {
        //     filter = { unreturned: true }
        // }

        filter.division = selected_division._id
        return filter
    }, [type, selected_division._id])

    const fetchMore = useCallback(async () => {
        if(fetchingMore || !threads || done) return;
        setFetchingMore(true)
        const data = await api.support_tickets.search({ limit, skip: threads.length, filter: getFilter() })
        setFetchingMore(false)
        if(data.success) {
            if(data.data.documents.length) setThreads(threads.concat(data.data.documents))
            if(data.data.documents.length < limit) setDone(true)
        }
    }, [fetchingMore, threads, done, getFilter])

    const fetchInitialThreads = useCallback(async () => {
        setThreads(null)
        setDone(false)


        const data = await api.support_tickets.search({ limit, filter: getFilter() })
        if(data.success) {
            setThreads(data.data.documents)
            if(data.data.documents.length < limit) setDone(true)
        }
    }, [getFilter])

    const onMarkResolved = useCallback(async () => {
        setThreads(null)
        setDone(false)
        setActive(null)

        const data = await api.support_tickets.search({ limit, filter: getFilter() })
        if(data.success) {
            setThreads(data.data.documents)
            if(data.data.documents.length < limit) setDone(true)
            if(data.data.documents.length) {
                setActive(data.data.documents[0], true)
            } else {
                toggleStandardLoader(false)
            }
        } else {
            toggleStandardLoader(false)
        }
    }, [getFilter, setActive])

    const onScroll = useCallback(() => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollHeight < scrollTop + clientHeight + 1) {
                fetchMore()
            }
        }
    }, [fetchMore])

    const onThreadUpdated = useCallback((params) => {
        const _threads = JSON.parse(JSON.stringify(threads))
        const thread = params;

        const foundIndex = _threads.findIndex(t => t._id === thread._id);
        const threadInSidebar = foundIndex !== -1
        // const read = thread.read_by.includes(viewing_user._id)
        const read = thread.resolved_at

        if(type === 'assigned') {
            if(threadInSidebar) {

                // if the thread is in the sidebar and it is read just update the entry without moving it
                if(read) {
                    _threads[foundIndex] = thread;

                    return  setThreads(_threads);
                // if the thread is in the sidebar and is unread push it to the top of the sidebar
                } else {
                    _threads.splice(foundIndex, 1)
                    _threads.unshift(thread)
                    return setThreads(_threads);
                }
            // if the thread is not in the sidebar so we are only looking at unread messages only push if the thread is unread
            } else if(!read) {
                _threads.unshift(thread)
                return setThreads(_threads);
            }
        } else {
            if(threadInSidebar) {
                if(read) {
                    _threads[foundIndex] = thread;
                    return  setThreads(_threads);
                } else {
                    _threads.splice(foundIndex, 1)
                    _threads.unshift(thread)
                    return setThreads(_threads);
                }
            } else {
                _threads.unshift(thread)
                return setThreads(_threads);
            }
        }
    }, [threads, type])

    useEffect(() => {
        fetchInitialThreads()
    }, [fetchInitialThreads])

    useEffect(() => {
        socket.on('SUPPORT_TICKETS.UPDATED', onThreadUpdated)
        socket.on('SUPPORT_TICKETS.CREATED', onThreadUpdated)

        return () => {
            socket.off('SUPPORT_TICKETS.UPDATED', onThreadUpdated)
            socket.off('SUPPORT_TICKETS.CREATED', onThreadUpdated)
        }

    }, [socket, onThreadUpdated])

    return (

        <>

        <div className="archk-messaging-sidebar-left z-depth-1" ref={listInnerRef} onScroll={onScroll}>

            <div className="archk-messaging-spacer position-relative border-bottom">
                <h3 className=" my-3 pl-3">
                    
                    <i className="fa-solid fa-envelope-open-text mr-2 text-info" /> My Tickets{' '}
                    
                    <i className="archk-messaging-sidebar-left-search fas fa-plus text-muted mr-6" />

                    <ModalToggler component={SelectContact} onSelect={onSelect}>
                        <i className="archk-messaging-sidebar-left-search fas fa-search text-muted" />
                    </ModalToggler>
                </h3>
            </div>
            <div className="archk-messaging-spacer position-relative border-bottom py-3 bg-secondary">
                <Row>
                    <Col md={6} className="align-self-center">
                        <button className={`btn btn-block btn-sm ${type === 'unresolved' ? 'btn-success' : 'btn-outline-info'}`} onClick={() => setType('unresolved')} >Unresolved</button>

                    </Col>
                    <Col md={6} className="text-right align-self-center">
                        <button className={`btn btn-block btn-sm ${type === 'resolved' ? 'btn-success' : 'btn-outline-info'}`} onClick={() => setType('resolved')}>All Tickets</button>
                    </Col>

                </Row>
            </div>
            
            {!threads ? <Circle className="pt-5" /> : !threads.length ? (
                <div className='border-bottom'>
                    <p className='text-sm mb-0 text-center my-3'><i className="fas fa-info-circle text-info mr-2" /> No Support Tickets Found</p>
                </div>
            ) : threads.map(thread => (
                <ListItem 
                    key={thread._id + thread.updated_at}
                    thread={thread}
                    setActive={setActive}
                    active={active}
                    read={thread.resolved_at}
                />
            ))}

            {fetchingMore ? (
                <Circle className="py-4 border-top" />
            ) : done ? (
                <div className="archk-messaging-spacer position-relative border-top">
                    <p className='text-sm mb-0 text-center'><b className='text-dark'>End Of Support Tickets</b> </p>
                    <p className='text-sm mb-0 mt--2 text-center'>Total Tickets: {threads.length} </p>
                </div>
            ) : (
                <div className="archk-messaging-spacer position-relative border-top">
                    <button onClick={fetchMore} className="btn btn-success btn-block btn-sm">Load More</button>
                </div>
            )}

        </div>

        {thread ? (
            <Resolve 
                thread={thread}
                onMarkResolved={onMarkResolved}
            /> 
        ) : ''}

        </>
    )

}


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(TextsSidebarLeft);
