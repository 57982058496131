import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Card, CardBody } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ConfirmationModal from 'components/functional/modals/Confirmation';
import reactHTMLParser from 'html-react-parser';

const ModuleBKOverview = ({ bkCase, onSaveBkCase, onSyncToPacer, matter }) => {

    const [caseNumber, setCaseNumber] = useState('');
    const [courtCode, setCourtCode] = useState('');
    
    const [confirmSync, setConfirmSync] = useState(false)

    const onSync = useCallback(async () => {
        const payload = {
            case_number: caseNumber,
            court_code: courtCode
        }

        onSyncToPacer(payload)
    }, [caseNumber, courtCode, onSyncToPacer])
   
    const onSave = useCallback(async () => {
        const payload = {
            case_number: caseNumber,
            court_code: courtCode
        }

        onSaveBkCase(payload)
    }, [caseNumber, courtCode, onSaveBkCase])

    useEffect(() => {
        if(bkCase) {
            setCaseNumber(bkCase.case_number)
            setCourtCode(bkCase.court_code)
        } else if(matter.identifier) {
            setCaseNumber(matter.identifier)
        }
        if(!bkCase && matter.identifier) setCaseNumber(matter.identifier)
    }, [bkCase, matter.identifier])
  
    return (
        <div>

            <h2 className='text-warning'>Case Overview</h2>

            {bkCase && bkCase.html_header ? (
                <Card>
                    <CardBody>
                        {reactHTMLParser(bkCase.html_header)}
                    </CardBody>
                </Card>
            ) : ''}

            <FormGroup>
                <label className='form-control-label'>Case Number</label>
                <p className='text-sm mb-0'>This number should exactly match the case number inside of Pacer. Example: "2:24-bk-12345"</p>
                <Input 
                    value={caseNumber}
                    onChange={e => setCaseNumber(e.target.value)}
                />
            </FormGroup>

            <ReactSelect
                title="Court District"
                formGroup={true}
                onChange={(obj) => setCourtCode(obj.value)}
                options={[
                    { value: 'miebke', label: 'Eastern District Of Michigan' },
                    { value: 'miwbke', label: 'Western District Of Michigan' },
                    { value: 'ohnbke', label: 'Northern District Of Ohio' },
                    { value: 'ohsbke', label: 'Sourthern District Of Ohio' },
                ]}
                value={courtCode}
            />    

            {!bkCase ? (
                <div className='alert alert-info mb--3'><i className="fas fa-info-circle mr-2" /> This case has not been filed with the court yet. Once filed enter the case number and court code above.</div>
            ) : ''}

            <hr />

            <div className='text-right'>
                {bkCase && bkCase.case_number && bkCase.court_code ? (
                    <button onClick={() => setConfirmSync(true)} className='btn btn-outline-info'>Sync To Pacer</button>
                ) : ''}
                <button onClick={onSave} className='btn btn-success'>
                Save Case
                </button>
            </div>

            <ConfirmationModal 
                showModal={confirmSync}
                toggleModal={() => setConfirmSync(false)}
                title="Sync To Pacer"
                body={<span>Syncing to pacer will incur costs, this should only be done if no data is currently found for this case regarding docket entries and claims.</span>}
                onConfirmation={onSync}
                zIndex={2000}
            />
           

        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


