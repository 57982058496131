// unlike unfinished task table this table is a list of task templates based on the required for move task templates

import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';

import moment from 'moment';

import Circle from 'components/markup/loading/Circle';
import ModalToggler from 'components/functional/modals/Toggler';
import FinishTask from 'components/system/FinishTask';
import ApiError from 'components/markup/layout/ApiError';

import formatText from 'utils/formatText';

const MatterViewMainDocumentsAll = ({matter, tasks, err, findTasks, isStepTaskView}) => {

   

    if(err) return <ApiError rounded={false} err={err} />
    if(!tasks) return <Circle />

    // either loop through templates on step task view or the actual tasks
    const tasksToLoop = isStepTaskView ? matter.workflow_step.tasks : tasks

    return (
        <Card className='card-no-shadow'>

        <div className='table-responsive'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Task</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {!tasksToLoop.length ? ( 
                        <tr>
                           <td><i className="fas fa-check mr-2 text-success" />No Tasks Found</td>
                           <td></td>
                       </tr>
                    ) : tasksToLoop.map((task_template, i) => {

                        // mimic a real task from the task template
                        // backend will pickup the id as the template and handle the rest
                        const t = {
                            ...task_template,
                            matter: matter._id,
                            task_template: task_template,
                            task: task_template._id,
                        }

                        const name = (
                            <span className='cursor-pointer'>

                                {formatText(t.task_template ? t.task_template.name : t.name).stripHTML(30)}
                                <div className='text-muted'>{formatText(t.task_template ? t.task_template.description : t.description).stripHTML(30)}</div>
                            </span>
                        )

                        return (
                            <tr key={i}>

                                <td >
                                    <ModalToggler onTaskFinished={findTasks} component={FinishTask} task={t}>
                                       {name}
                                    </ModalToggler>
                                </td>

                         
                                <td className='text-right'>
                                    <ModalToggler onTaskFinished={findTasks} component={FinishTask} task={t}>
                                        <button className='btn btn-sm btn-info'>Finish</button>
                                    </ModalToggler>
                                </td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </Card>
    );

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocumentsAll);