import { useCallback, useState, useEffect } from 'react';

import React from "react";
import { Col, Modal, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';

import api from 'api';

import Circle from 'components/markup/loading/Circle'

import { toast } from 'react-toastify'


const LoginCompanyMFA = ({type, showModal, toggleModal}) => {

    const [err, setErr] = useState(false);
    const [code, setCode] = useState({});
    const [loading, setLoading] = useState('');

    const focusInput = useCallback((num) => {
        let timesTried = 0;

        const focus = () => {
            if(timesTried > 20) return;
            const input = document.getElementById(`code-input-${num}`);
            if(input) return input.focus();

            setTimeout(() => {
                timesTried++;
                focus();
            }, 100)
        }
        focus()
    }, [])    

    const onVerify = useCallback(async (_code) => {

        // if code is passed in use that code, else use state code if button is pressed
        const CODE = _code ? _code : code;

        if(!CODE[1] || !CODE[2] || !CODE[3] || !CODE[4] || !CODE[5] || !CODE[6]) return;
        setErr(false);
        setLoading(true)

        let fullCode = '';
        Object.keys(CODE).forEach(key => fullCode += CODE[key]);

        const verified = await api.auth.mfa.set({
            code: fullCode,
            type
        })

        if(!verified.success) {
            setLoading(false)
            focusInput(6);
            return setErr(verified.message);
        }

        toast.success(`Your MFA settings have been updated.`)
        await setViewingUser();
        toggleModal()

    }, [type, code, toggleModal, focusInput])

    const onSetCode = useCallback((num, value) => {
        const _code = JSON.parse(JSON.stringify(code));
        _code[num] = value ? value.slice(-1) : value;
        setCode(_code)

        const nextInput = document.getElementById(`code-input-${num + 1}`);

        if(nextInput) return nextInput.focus();

        onVerify(_code);
    }, [code, onVerify])

    const onKeyDown = useCallback((num, e) => {
        if (e.key === 'Delete' || e.key === 'Backspace' || e.code === 8) {

            e.preventDefault();

            const _code = JSON.parse(JSON.stringify(code));
            _code[num] = '';
            setCode(_code)

            const lastInput = document.getElementById(`code-input-${num - 1}`);
            if(lastInput) lastInput.focus();
        }
    }, [code])

    useEffect(() => {
        if(showModal) {
            focusInput(1)
        } else {
            setCode({})
            setLoading(false)
        }
    }, [showModal, setCode, focusInput])

    return (

        <Modal
            className="modal-dialog-centered layout-auth"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >
            
            {loading ? (
                <div className="pb-4 pt-5"><Circle /></div>
            ) : (
                <>

                    <div className="modal-header">
                        <h5 className="modal-title">Verify Code</h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={toggleModal}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>


                    <div className="modal-body bg-secondary">
                        <p className="text-sm mb-0">Please Enter The Authorization Code {type === 'email' ? 'Sent To Your Email' : 'Sent To Your Phone'}</p>
                    </div>

                    <div className="modal-body mfa-auth-code">
                        <FormGroup className="mb-0">

                            <Row>
                                <Col xs={2}>
                                    <Input 
                                        id="code-input-1"
                                        onKeyDown={(e) => onKeyDown(1, e)}
                                        type="text"
                                        value={code[1] || ''}
                                        onChange={(e) => onSetCode(1, e.target.value)}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Input 
                                        id="code-input-2"
                                        onKeyDown={(e) => onKeyDown(2, e)}
                                        type="text"
                                        value={code[2] || ''}
                                        onChange={(e) => onSetCode(2, e.target.value)}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Input 
                                        id="code-input-3"
                                        onKeyDown={(e) => onKeyDown(3, e)}
                                        type="text"
                                        value={code[3] || ''}
                                        onChange={(e) => onSetCode(3, e.target.value)}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Input 
                                        id="code-input-4"
                                        onKeyDown={(e) => onKeyDown(4, e)}
                                        type="text"
                                        value={code[4] || ''}
                                        onChange={(e) => onSetCode(4, e.target.value)}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Input 
                                        id="code-input-5"
                                        onKeyDown={(e) => onKeyDown(5, e)}
                                        type="text"
                                        value={code[5] || ''}
                                        onChange={(e) => onSetCode(5, e.target.value)}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <Input 
                                        id="code-input-6"
                                        onKeyDown={(e) => onKeyDown(6, e)}
                                        type="text"
                                        value={code[6] || ''}
                                        onChange={(e) => onSetCode(6, e.target.value)}
                                    />
                                </Col>
                            </Row>
                            
                        </FormGroup>
                    </div>

                    <div className="modal-body bg-secondary">
                        <p className="text-sm mb-0">Didn't receive a code? Please check your spam folder.</p>
                    </div>

                    {err ? (
                        <div className="modal-body">
                            <p className="text-sm text-danger mb-0">{err}</p>
                        </div>
                    ) : null}

                    <div className="modal-footer">
                        <button onClick={() => onVerify(code)} className="btn btn-outline-success">Confirm</button>
                    </div>

                </>
            )}
            
        </Modal>
            
    )
    
}

export default LoginCompanyMFA;