import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Badge, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { formatCurrency } from 'utils/currency';

import moment from 'moment';

import ModalToggler from 'components/functional/modals/Toggler';
import ObjectFinder from 'components/system/Objects/Finder';

import Refund from './Refund';
import View from './View';

import { onDownloadDocumentResponse } from 'utils/documents';

const Payments = ({payment_methods, matter, payments, setTab, fetchData}) => {

    const onDownload = useCallback(async () => {
        toast.info('Download Started')
        onDownloadDocumentResponse(`/v1/payments/${matter._id}/download`)
    }, [matter._id])

    return (
        <Card>
            <CardHeader className='py-3'>
                <Row>
                    <Col md={6} className="align-self-center">
                        <CardTitle className="mb-0">Matter Payments</CardTitle>
                    </Col>
                    <Col md={6} className="align-self-center text-right">
                        {payments.length ? (
                            <button onClick={onDownload} className='btn btn-outline-info btn-sm'>
                                <i className="fas fa-download mr-2" /> Download
                            </button>
                        ) : ''}
                        <button onClick={() => setTab('Charge Payment')} className='btn btn-success btn-sm'>
                            <i className="fas fa-plus mr-2" /> Add New
                        </button>
                    </Col>
                </Row>
            </CardHeader>

            {payments.length ? (
                    <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th className='text-right'>Amount</th>
                                <th className='text-right text-center'>Status</th>
                                <th className='text-right'>Made By</th>
                                <th className='text-right'>Card/Bank</th>
                                <th className='text-center'>Trust</th>
                                <th className='text-right'>Type</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {payments.map(p => {

                                const method = payment_methods.find(method => method._id === p.payment_method) 

                                let madeBy = ''
                                if(method) {
                                    if(method.type === 'card') {
                                        madeBy = <span><i className="fa-solid fa-money-check text-success mr-2" /> xx{method.last_4}</span>
                                    } else {
                                        madeBy = <span><i className="text-warning fa-solid fa-building-columns" /> xx{method.last_4}</span>
                                    }
                                }

                                return (
                                    <tr key={p._id} >
                                        <td>
                                            <span className='cursor-pointer'>
                                                <ModalToggler component={View} payment={p}>
                                                    <i className="fas fa-list mr-2 text-success" />
                                                    {moment.unix(p.created_at).format('MM/DD/YYYY')}
                                                </ModalToggler>
                                               
                                            </span>
                                        </td>

                                        <td className='text-right'>
                                            {formatCurrency(p.amount)}
                                        </td>

                                        <td className='text-right text-capitalize'>
                                            {p.status === 'succeeded' ? (
                                                <Badge style={styles.badge} color="success">{p.status}</Badge>
                                            ) : p.status === 'failed' ? (
                                                <Badge style={styles.badge} color="danger">{p.refund_for ? 'REFUND' : p.status}</Badge>
                                            ) : p.status === 'refunded' || p.status === 'voided'  ? (
                                                <Badge style={styles.badge} color="warning">Refunded</Badge>
                                            ) : (
                                                <Badge style={styles.badge} color="info">{p.status}</Badge>
                                            )}
                                        </td>

                                        <td className='text-right'>
    
                                            {p.user ? (
                                                <ObjectFinder collection="users" _id={p.user} />
                                            ) : p.contact ? (
                                                <ObjectFinder collection="contacts" _id={p.contact} />
                                            ) : 'System'}
                                        </td>

                                        <td className='text-right'>
                                            {madeBy}
                                        </td>

                                        <td className='text-center'>
                                            {p.trust ? (
                                                <i className="fas lead mb-0 mt-0 fa-check text-success font-weight-bold" /> 
                                            ) : (
                                                <i className="fas lead mb-0 mt-0 fa-times text-danger font-weight-bold" /> 
                                            )}
                                        </td>

                                        <td className='text-right'>
                                            {p.type === 'recurring' ? 'Recurring' : p.type === 'cash' ? 'Cash' : 'One Time'}
                                        </td>

                                        <td className='text-right'>
                                            {p.status === 'succeeded' ? (
                                                <ModalToggler component={Refund} payment={p} onRefund={fetchData}>
                                                    <button className={`btn btn-${p.refunded_amount === p.amount ? 'outline-warning' : 'warning'} btn-sm`}>
                                                        <i className="fa-solid fa-rotate-left mr-2" />
                                                        REFUND
                                                    </button>
                                                </ModalToggler>
                                              
                                            ) : ''}
                                        </td>
    
                                    </tr>
                                )
                            })}
                         
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    <CardBody className='py-8'>
                        <p className="text-ss mb-0 text-center text-capitalize">
                            <i className="fas fa-info-circle mr-3 text-info" /> 
                            No Payments have been charged to this this matter.
                        </p>
                    </CardBody>
                    
                </div>
            )}


        </Card>
    )

}

const styles = {
    badge: {
        width: 110
    }
}

export default Payments