import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, CardFooter, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row, UncontrolledDropdown} from "reactstrap";

import CustomFieldInput from 'components/system/CustomFieldInput'


import Circle from 'components/markup/loading/Circle';

import api from 'api'

import { toast } from 'react-toastify'

const MattersViewMainOverview = ({matter, workflowLocations, fetchWorkflowLocations, customFields, fetchCustomFields}) => {

    const [updatedFields, setUpdatedFields] = useState(matter.custom_fields)
    const [type, setType] = useState('step')

    let fields = customFields ? type === 'step' ? matter.workflow_step.custom_fields.map(s => customFields.find(ff => ff._id === s)) : customFields : []

    fields = fields.filter(f => f)

    const onChange = useCallback((customField, val) => {
        const state = JSON.parse(JSON.stringify(updatedFields))
        state[customField._id] = val;

        if(customField.linked_fields && customField.linked_fields.length) {
            customField.linked_fields.forEach(field => {
                const foundAnswer = field.answer_map.find(a => a.answer === val)
                if(foundAnswer) {
                    state[field.custom_field] = foundAnswer.linked_answer;

                }
            })
        }

        setUpdatedFields(state)

    }, [updatedFields])

    const onSave = useCallback(async () => {
        const result = await api.matters.update(matter._id, { custom_fields: updatedFields })
        if(!result.success) toast.error(`Something went wrong saving your changes, please try again.`)
    }, [matter._id, updatedFields])

    useEffect(() => {
        if(!customFields) {
            fetchCustomFields()
        }
    }, [customFields, fetchCustomFields])



    return (
        <div className="section pt-0">

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">
                        Matter Custom Fields
                        <span className="float-right"> 
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                    {type === 'step' ? 'Showing Step Fields' : 'Showing All Matter Fields'} <i className="fas fa-caret-down text-dark" />
                                </DropdownToggle>

                                <DropdownMenu end>
                                    <div className="dropdown-content">
                                        <DropdownItem onClick={() => setType('step')} >Show Step Fields</DropdownItem>
                                        <DropdownItem onClick={() => setType('all')} >Show All Fields</DropdownItem>
                                    </div>
                                </DropdownMenu>

                            </UncontrolledDropdown>
                        </span>
                    </CardTitle>
                </CardHeader>

                <CardBody>

                    {!fields ? (
                        <Circle />
                    ) : !fields.length ? (
                        <p className="mb-0">No Custom Fields Found For The Current Step</p>
                    ) : fields.map((c, i) => {
                        if(!c) return <React.Fragment key={i} />
                        const answer = updatedFields[c._id]
                        return (
                            <div key={c._id}>
                                <p className="text-sm mb-0">{c.name}</p>
                                <CustomFieldInput 
                                    hideTitle={true}
                                    value={answer || ''}
                                    onChange={(val) => onChange(c, val)}
                                    customField={c}
                                />
                            </div>
                        )
                    })}
                </CardBody>

                <CardFooter className="text-right py-3">
                    <button onClick={onSave} className="btn btn-success"><i className="fas fa-save mr-2" /> Save Fields</button>
                </CardFooter>

            </Card>

            
        </div>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainOverview);
