import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import api from 'api';

import { toast } from 'react-toastify';
import { toggleStandardLoader } from 'store/functions/system/system';

import CheckStep from './CheckStep';
import SelectStep from './SelectStep';

const MatterViewMainDocumentsMine = ({matter, setIsMoving}) => {

    const [steps] = useState(matter.workflow_step.next_steps.sort((a, b) => {
       if( a._id === matter.workflow_step.recommended_next_step ) return -1
       return 0
    }));

    const [type, setType] = useState('select')
    const [selected, setSelected] = useState('')
    const [redirect, setRedirect] = useState(null)

    const nextStep = steps.find(s => s._id === matter.workflow_step.recommended_next_step)
    const hasNextSteps = steps.length

    const selectedStep = steps.find(s => s._id === selected);

    const onSetType = useCallback((_type) => {
        setType(_type)
    }, [])

    const moveStep = useCallback(async () => {
        toggleStandardLoader(true);
        setIsMoving(true)
        const result = await api.matters.moveStep(matter._id, { workflow_step: selected });
        setIsMoving(false)
        toggleStandardLoader(false)

        if(!result.success) return toast.error(`Step could not be moved, please try again or refresh your page.`)

        setRedirect(`/soft_redirect?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`)
    }, [matter._id, selected, setIsMoving])

    if(redirect) return <Redirect to={redirect} />

    if(type === 'select') {
        return (
            <SelectStep 
                hasNextSteps={hasNextSteps} 
                steps={steps}
                nextStep={nextStep}
                selected={selected}
                setSelected={setSelected}
                onSetType={onSetType}
            />
        )
    } else {
        return (
            <CheckStep 
            moveStep={moveStep}
            selected={selected}
            onSetType={onSetType}
            matter={matter}
            selectedStep={selectedStep}
        />
        )
    }

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocumentsMine);