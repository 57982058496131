/*
Documentation

This page handles updating a users profile

*/

import React from 'react';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Helmet } from 'react-helmet';
import { Container } from "reactstrap";
import { connect } from 'react-redux';

import Circle from 'components/markup/loading/Circle';

import Info from './Info/index';
import Branding from './Branding';
import Navigation from './Navigation';
import Messaging from './Messaging';
import ApiKeys from './ApiKeys';

import api from 'api';
import { setCompany } from 'store/functions/company/company'

import AdminLock from 'views/dashboard/pages/_components/AdminLock'

import { getUrlParameter } from 'utils/urls'

class AccountSettings extends React.Component {

    state = {
        company: null,
        loaded: false,
        tab: 0,
    }

    getCompany = async  () => {
        return new Promise (async resolve => {

            setCompany();
            
            const foundCompany = await api.companies.findById(this.props.viewing_user.company)
            
            if(foundCompany.success) {
                this.setState({company: foundCompany.data})
                resolve()
            }

        })
    }

    setCompany = async  () => {
        return new Promise (async resolve => {

            await Promise.all([
                this.getCompany(),
            ])

            this.setState({loaded: true})
            resolve()

        })
    }

    componentDidMount = async () => {

        this.setCompany()

        const tab = parseInt(getUrlParameter('tab'))
        if(tab === 1 || tab === 2 || tab === 3 || tab === 4) {
            if(tab === 4 && !this.props.viewing_user.is_staff) return;

            this.setState({tab})
        }

    }

    render() {

        const { loaded, company, tab } = this.state

        if(!loaded ) return <div className="py-6"><Circle /></div>

        return (

            <AdminLock>

                <Container fluid>

                    <Helmet>
                        <title>Company Settings</title>
                        <meta name="description" content="Company Settings" />
                    </Helmet>

                    <DashHeaderOpen 
                        title={<span>Dashboard</span>}
                        breadcrumb_1="Company"
                        breadcrumb_2="Settings"
                        icon="fas fa-building"
                    />

                    <Navigation 
                        tab={tab}
                        setTab={(tab) => this.setState({tab})}
                    />

                    {tab === 0 ? (
                        <Info
                            company={company}
                            setCompany={this.setCompany}
                        />
                    ) : tab === 1 ? (
                        <Messaging 
                            company={company}
                            setCompany={this.setCompany}
                        />
                    ) : tab === 2 ? (
                        <Branding 
                            company={company}
                            setCompany={this.setCompany}
                        />
                    ) : tab === 3 ? (
                        <ApiKeys 
                            company={company}
                            setCompany={this.setCompany}
                        />
                    ) : null}
                
                </Container>

            </AdminLock>

        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);
