import keys from 'keys'


import { Modal } from "reactstrap";
import { formatCurrency } from 'utils/currency';

import ObjectFinder from 'components/system/Objects/Finder';
import A from 'components/markup/links/A';

import moment from 'moment';

const ModalCustomFields = ({showModal, toggleModal, payment}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="md"
    >

        <div className="modal-header">
            <h5 className="modal-title">Payment Info</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
                >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body border-bottom bg-secondary pt-0">
            <table className='table'>

                <tbody>
                    <tr>
                        <td>Date</td>
                        <td>{moment.unix(payment.date).format('MM/DD/YYYY h:mm A')}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td className="text-capitalize">{payment.status}</td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td>{formatCurrency(payment.amount)}</td>
                    </tr>
                    <tr>
                        <td>Refunded Amount</td>
                        <td>{formatCurrency(payment.refunded_amount)}</td>
                    </tr>
                    <tr>
                        <td>Potential Refund</td>
                        <td>{formatCurrency(payment.remaining_balance)}</td>
                    </tr>
                    
                    <tr>
                        <td>Trust</td>
                        <td>{payment.trust ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" /> }</td>
                    </tr>
                    <tr>
                        <td>Made By</td>
                        <td>
                            {payment.user ? (
                                <ObjectFinder collection="users" _id={payment.user} />
                            ) : payment.contact ? (
                                <ObjectFinder collection="contacts" _id={payment.contact} />
                            ) : 'System'}
                        </td>
                    </tr>
                    <tr>
                        <td>Delinquent</td>
                        <td>{payment.delinquent ? <span className='text-danger'>Yes</span> : <span className='text-success'>No</span>}</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{payment.description || '-'}</td>
                    </tr>
                   
                    <tr>
                        <td>Last 4</td>
                        <td>{payment.payment_method_last_4}</td>
                    </tr>
                    <tr>
                        <td>Method Type</td>
                        <td className="text-capitalize">{payment.payment_method_type}</td>
                    </tr>
                    <tr>
                        <td>Decline Code</td>
                        <td className="text-capitalize">{payment.payment_status_code ? payment.payment_status_code.toLowerCase() : '-'}</td>
                    </tr>
                    <tr>
                        <td>Decline Message</td>
                        <td className="text-capitalize">{payment.payment_status_message ? payment.payment_status_message.toLowerCase() : '-'}</td>
                    </tr>
                    <tr>
                        <td>Decline Reason</td>
                        <td className="text-capitalize">{payment.payment_status_reason ? payment.payment_status_reason.toLowerCase() : '-'}</td>
                    </tr>
                    <tr>
                        <td>Payment Type</td>
                        <td className="text-capitalize">{payment.type.replace('_', ' ')}</td>
                    </tr>
                    <tr>
                        <td>Processor</td>
                        <td className="text-capitalize">{payment.processor}</td>
                    </tr>
                    <tr>
                        <td>Processor ID</td>
                        <td>
                            {payment.type === 'cash' ? '-' : (
                                <A href={`${keys.BLUE_FIN_API_URL}/transdetails.php?transactionid=${payment.payment_id}`}>{payment.payment_id}</A>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>IntuiDesk ID</td>
                        <td>{payment._id}</td>
                    </tr>
                    <tr>
                        <td>Created At</td>
                        <td>{moment.unix(payment.created_at).format('MM/DD/YYYY h:mm A')}</td>
                    </tr>
                </tbody>
            </table>     
        </div>

        <div className="modal-footer">
            <button className="btn btn-warning" onClick={toggleModal}>
                <i className="fas fa-arrow-left mr-2" /> Close
            </button>
        </div>

    </Modal>
)

export default ModalCustomFields