
/*
Documentation

This page handles updating a users profile

*/

import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, Form, CardHeader, CardTitle, Row, Col, Badge } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';
import ApiError from 'components/markup/layout/ApiError'

import Circle from 'components/markup/loading/Circle';

import { toast } from 'react-toastify';

import errorLogs from 'api/errorLog'
import moment from 'moment-timezone';

import ModalShow from './ModalShow'
import ModalToggler from 'components/functional/modals/Toggler'
import CopyToClipboard from 'react-copy-to-clipboard'


const UserSettingsMatter = ({viewing_user}) => {

    const [err, setErr] = useState(null)
    const [user, setUser] = useState(null)
    const [logID, setLogID] = useState(null)
    const logs = user && user.settings.log_errors ? errorLogs.getLogs() : null

    const sendLogs = useCallback(async (e) => {
        e.preventDefault();
        toggleStandardLoader(true)
        const sent = await errorLogs.send(logs)
        toggleStandardLoader(false)
        if(!sent) return toast.error(`Error report not sent, please try again`);
        setLogID(sent)
    }, [logs])

    const onSave = useCallback(async (e) => {
        e.preventDefault();

        if(!user.email) return toast.info('An email address must be set.')
        if(!user.phone) return toast.info('An phone number must be set.')

        toggleStandardLoader(true)
        const updated = await api.users.update(user._id, user)
        toggleStandardLoader(false)

        if(!updated.success) toast.error('Something went wrong updating your profile, please try again')
    }, [user])

    const onSettingsChange = useCallback((field, value) => {
        const newUser = JSON.parse(JSON.stringify(user));
        newUser.settings[field] = value;
        setUser(newUser)
    }, [user])

    const fetchData = useCallback(async () => {
        const viewing_user = await api.users.me()
        if(!viewing_user.data) setErr(viewing_user.message)
        setUser(viewing_user.data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} />
    if(!user) return <Circle className="pt-4" />

    if(logID) return (
        <Card>
            <CardBody className='text-center'>
                <p className='text-sm'>
                    <i className="fas fa-check-circle mr-2 text-success" />{' '}
                    Your error report was sent, the ID associated with it is:
                </p>
                
                <p className='mb-0'>
                    <b>
                        <CopyToClipboard 
                            text={logID}
                            onCopy={() => {
                                toast.info('Log ID Copied To Clipboard')
                            }}
                        >
                            <div><i className="fas fa-copy cursor-pointer text-info mr-2" /> {logID}</div>
                        </CopyToClipboard>
                    </b>
                </p>
            </CardBody>
            <CardFooter>
                <p className='text-center text-sm mb-0'>Please send the report ID above to your dedicated support representative.</p>
            </CardFooter>
        </Card>
    )

    return (
        <Form>

            <CopyToClipboard 
                    text={viewing_user._id}
                    onCopy={() => {
                        toast.info('User ID Copied To Clipboard')
                    }}
                >
                <div className='alert alert-success text-center font-weight-bold cursor-pointer'>
                    My System User Id: {viewing_user._id} <i className="fas fa-copy cursor-pointer ml-2" /> 
                </div>
            </CopyToClipboard>

            <Card>
                <CardBody>
                    <Row>
                        <Col md={6} className="align-self-center">
                        <h6 className="heading-small text-muted mb-4">Error Logs</h6>

                        </Col>
                        <Col md={6} className="text-right align-self-center">
                            <button className="btn btn-sm btn-success" onClick={onSave} >
                                Save Settings
                            </button>
                        </Col>
                    </Row>
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            id="confirm-terms-of-service"
                            type="checkbox"
                            onChange={(e) => onSettingsChange('log_errors', !user.settings.log_errors)}
                            checked={user.settings.log_errors}
                        />
                        <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                            Store Error Logs To Be Sent
                        </label>
                    </div>
                    <p className='text-sm mb-0'><small>Enabling error logs will store all network requests made inside of IntuiDesk and will allow you to send them off to our developers if something should go wrong. Note this may have a performance impact and should be left off unless trying to send information on a specific bug.</small></p>
                </CardBody>

                <CardFooter className="text-right">
                   
                </CardFooter>

            </Card>

            {logs ? (
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                <CardTitle>Current Logs</CardTitle>
                            </Col>
                            <Col md={6} className="text-right">
                                <button onClick={sendLogs} className='btn btn-sm btn-warning'>Send Logs</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <div className='table-responsive'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>URL</td>
                                    <td>Seconds</td>
                                    <td>Method</td>
                                    <td className='text-right'>Response Status</td>
                                </tr>
                            </thead>
                            <tbody>
                            {logs.length ? logs.map(l => {
                                const startTime = l.start / 1000;
                                const duration = l.duration / 1000;
                                return (
                                    <tr>

                                        <td>
                                            <ModalToggler component={ModalShow} log={l}>
                                                <div>{l.request.url}</div>
                                                <div>{moment.unix(startTime).format('h:mm:ss A')} {moment.tz.guess()}</div>
                                            </ModalToggler>
                                        </td>

                                        <td>
                                            <Badge 
                                                color={duration < 1 ? 'success' : duration < 2 ? 'warning' : 'danger'}
                                            >
                                                {duration}
                                            </Badge>
                                        </td>

                                        <td>{l.request.method}</td>

                                        <td className='text-right'>
                                            <Badge 
                                                color={l.response.status !== 200 ? 'danger' : 'success'}
                                            >
                                                {l.response.status}
                                            </Badge>
                                        </td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <td>No Logs Founds</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>

                </Card>
            ) : ''}

        </Form>

    );
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(UserSettingsMatter);