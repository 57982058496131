/*
address
property type (single family, duplex, etc.)
property nature of interest
description
other info
ownership (d1, d2, d1 & 2)

current value
current_value_type (unknown, partial interest)

amount of secured claim (unknown / linked to liens)
amount of secured claim type (unkown, known, linked to liens)

year
make
model
mileage

name of institution
Type of account ('checking savings, certificates o deposit, ira)
percent Ownership

beneficiary




Current fields

address: {
    address_line_1,
    address_line_2,
    city,
    state,
    postal_code,
    county,
}

type
type_is_investment
type_is_timeshare

current_value
current_value_type 'known, unknown, partial_interest
current_value_partial_interest_percent

nature_of_interest

// amount_secured
// amount_secured_unknown
// amount_secured_linked_to_liens

*/



import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import categories from './categories.json'

import ReactSelect from 'components/functional/inputs/ReactSelect';

import InputAddress from './Inputs/Address'
import InputPropertyType from './Inputs/PropertyType'
import InputValue from './Inputs/Value'
import InputNatureOfInterest from './Inputs/NatureOfInterest'
import InputDescription from './Inputs/Description'
import InputOwnership from './Inputs/Ownership'
import InputAmountSecured from './Inputs/AmountSecured'
import AmountSecured from './Inputs/AmountSecured';

const baseState = {
    category: 1
}

const ModalDivisionOverride = ({showModal, toggleModal}) => {

    const [property, setProperty] = useState(baseState)

    const onInputChange = useCallback((field, val) => {
        const _property = JSON.parse(JSON.stringify(property));
        _property[field] = val;
        setProperty(_property)
    }, [property])

    const onSave = useCallback(() => {

    }, [])

    const cat = property.category

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Property / Asset</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom bg-secondary">

                <ReactSelect
                    title="Category"
                    formGroup={true}
                    onChange={(obj) => onInputChange('category', obj.value)}
                    formatOptionLabel={({ type, label }) => (
                        <span>
                            <b className='text-dark' style={{display: 'inline-block', width: 135}}>{type}</b>{' '}
                            {label}
                        </span>
                    )}
                    options={categories.map(c => {
                        return { 
                            type: c.type, 
                            label: c.name, 
                            value: c.id 
                        }
                    })}
                    value={property.category}
                />    

            </div>

            <div className="modal-body">

                <Row>
                    <Col md={6}>
                        {cat === 1 ? (
                            <div>
                                <InputAddress 
                                    obj={property}
                                    field="address"
                                    onInputChange={onInputChange}
                                />
                                <hr />
                            </div>
                        ) : ''}
                        
                        {cat === 1 ? (
                            <div>
                                <InputNatureOfInterest 
                                    obj={property}
                                    field="nature_of_interest"
                                    onInputChange={onInputChange}
                                />
                            </div>
                        ) : ''}
                      
                        {cat === 1 ? (
                            <InputDescription 
                                title="Other Information"
                                obj={property}
                                field="nature_of_interest"
                                onInputChange={onInputChange}
                            />
                        ) : ''}

                        

                        {cat === 1 ? (
                            <div>
                                <hr />
                                <InputOwnership 
                                    title="Who Owns This Property?"
                                    obj={property}
                                    field="ownership"
                                    onInputChange={onInputChange}
                                />
                            </div>
                        ) : ''}

                    </Col>

                    <Col md={6}>
                        {cat === 1 ? (
                            <div>
                                <InputPropertyType 
                                    obj={property}
                                    field="type"
                                    onInputChange={onInputChange}
                                />
                                <hr />
                            </div>
                        ) : ''}
                        <InputValue 
                            obj={property}
                            field="current_value"
                            onInputChange={onInputChange}
                        />
                        <hr />
                        <AmountSecured 
                            obj={property}
                            field="amount_secured"
                            onInputChange={onInputChange}
                        />
                    </Col>
                </Row>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" /> Save
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);