import { useCallback, useState, useEffect } from 'react';


import api from 'api';

import Modalify from 'components/functional/modals/Modalify'

import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';

import InternalSidebarAdmin from 'components/markup/layout/InternalSidebarAdmin'

import Details from './Details'
import Communication from './Communication'
import Address from './Address'
import Types from './Types'
import Social from './Social'
import Portal from './Portal'

const baseState = {
    contact_types: []
}

const ContactsUpdate = ({contact_id, onSaved, showModal, toggleModal, startingTab, startingPhone}) => {

    const [tab, setTab] = useState(startingTab ? startingTab : 'Name/Details')
    const [contactTypes, setContactTypes] = useState(null)
    const [contact, setContact] = useState(null)
    const [err, setErr] = useState(null)
    const [disabled, setDisabled] = useState(null)

    const onSetTab = useCallback((_tab) => {
        setTab(_tab)
        setErr(false)
    }, [])

    const setContactName = useCallback((_contact) => {
        _contact.given_name = _contact.given_name_unformatted
        _contact.family_name = _contact.family_name_unformatted
            
        return _contact;
    }, [])

    const onContactTypeChange = useCallback((_id) => {
        const newContact = JSON.parse(JSON.stringify(contact))
        if(newContact.contact_types.includes(_id)) {
            newContact.contact_types = newContact.contact_types.filter(i => i !== _id)
        } else {
            newContact.contact_types.push(_id)
        }
        setContact(newContact)
    }, [contact])

    const fetchContactTypes = useCallback(async () => {
        const types = await api.contact_types.find()
        if(!types.data) return setErr(true);

        let all = {};

        types.data.forEach(e => {
            if(all[e.division]) {
                all[e.division].push(e)
            } else {
                all[e.division] = [e]
            }
        })
        setContactTypes(all)
    }, [])
    const fetchData = useCallback(async () => {
        if(!contact_id) return setContact(startingPhone ? {...baseState, phone: startingPhone} : baseState);
        const contact = await api.contacts.findById(contact_id)
        if(!contact.data) return setErr(true);

        const formattedContact = setContactName(contact.data)
        setContact(formattedContact)
    }, [contact_id, setContactName, startingPhone])

    const onInputChange = useCallback(( field, value) => {
        const newContact = JSON.parse(JSON.stringify(contact))
        newContact[field] = value;
        setContact(newContact);
    }, [contact])

    const onSave = useCallback(async () => {

        setErr(false)
        //copy state
        let newState = JSON.parse(JSON.stringify(contact));
        //assume we have no errors

        if(!newState.family_name && !newState.email && !newState.phone) {
            return setErr([`A contact must have either a last name, email, or phone field.`])
        }

        setContact(newState)

        let saved;

        const id = toast.loading("Saving Contact...")
        setDisabled(true)
        if(newState._id) {
            saved = await api.contacts.update(newState._id, newState, true);
        } else {
            saved = await api.contacts.create(newState, true);
        }
        setDisabled(false)
        if(saved.success) {
            toast.update(id, { render: "Contact Profile Saved", type: "success", isLoading: false, autoClose: 1500 });
            fetchData();
            if(toggleModal) toggleModal();
            if(onSaved) onSaved(saved.data)
        } else {
            toast.update(id, { render: "Please Try Again", type: "info", isLoading: false, autoClose: 1500 });
            return setErr(saved.message);
        }
    
    }, [contact, fetchData, toggleModal, onSaved])

    const onSaveSocialSecurityNumber = useCallback(async (social_security_number) => {
        const data = await api.social_security_numbers.save(contact_id, { social_security_number })
        if(!data.success) return toast.info(`Could not save social security number, please try again`)

        toast.success(`SSN Updated Successfully`)
    }, [contact_id])

    useEffect(() => {
        fetchData()
        fetchContactTypes()
    }, [fetchData, fetchContactTypes])

    useEffect(() => {
        if(!showModal) setErr(null)
    }, [showModal])

    return (
        <Modalify 
            showModal={showModal}
            toggleModal={toggleModal}
            title={contact && contact._id ? "Update Contact" : "Create Contact"}
            err={err}
            header={(
                contact ? (
                    <Row>
                        <Col md={4}>
                            <b><i className="fas text-info fa-user mr-2" /></b> 
                            {contact.given_name || contact.family_name ? (
                                <span>{contact.given_name ? contact.given_name : ''} {contact.family_name ? contact.family_name : ''}</span>
                            ) : (
                                <span>-</span>
                            )}
                        </Col>

                        <Col md={4} className="text-center">
                            <b><i className="fas text-info fa-envelope mr-2" /></b> 
                            {contact.email ? contact.email : '-'}
                        </Col>

                        <Col md={4} className="text-right">
                            <b><i className="fas text-info fa-mobile mr-2" /></b> 
                            {contact.phone ? contact.phone : '-'}
                        </Col>
                    </Row>
                ) : ''
            )}
            footer={tab === 'Social'  ? null : (
                <button disabled={disabled} className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            
            )}
            sections={!contact ? [] :  [
                <InternalSidebarAdmin 
                    tab={tab}
                    setTab={onSetTab}
                    className="ml--4"
                    tabs={[
                        { value: 'Name/Details', name: 'Name/Details' },
                        { value: 'Communication', name: 'Communication' },
                        { value: 'Address', name: 'Address' },
                        { value: 'Types', name: 'Types' },
                        { value: 'Social', name: 'Social' },
                        { value: 'Portal', name: 'Portal' },
                    ]}
                >  
                    <div className='py-3 pl-3'>
                        {tab === 'Name/Details' ? (
                            <Details onInputChange={onInputChange} contact={contact} />
                        ) : tab === 'Communication' ? (
                            <Communication onInputChange={onInputChange} contact={contact} />
                        ) : tab === 'Address' ? (
                            <Address onInputChange={onInputChange} contact={contact} />
                        ) : tab === 'Types' ? (
                            <Types onContactTypeChange={onContactTypeChange} contactTypes={contactTypes} contact={contact} />
                        ) : tab === 'Social' ? (
                            <Social onSave={onSaveSocialSecurityNumber} contact={contact._id} />
                        ) : tab === 'Portal' ? (
                            <Portal contact={contact} />
                        ) : ''}
                    </div>
                </InternalSidebarAdmin>
                  
            ]}
        />
    )

}

export default ContactsUpdate;