import React from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter } from 'reactstrap';

import moment from 'moment';

import Circle from 'components/markup/loading/Circle';
import ModalToggler from 'components/functional/modals/Toggler';
import FinishTask from 'components/system/FinishTask';
import ApiError from 'components/markup/layout/ApiError';

import formatText from 'utils/formatText';

import CreateTask from './CreateTask'


const MatterViewMainDocumentsAll = ({matter, viewing_user, err, tasks, findTasks, isStepTaskView}) => {

    const now = Math.floor(new Date() / 1000);

    if(err) return <ApiError rounded={false} err={err} />
    if(!tasks) return <Circle />

    // either loop through templates on step task view or the actual tasks
    const tasksToLoop = isStepTaskView ? matter.workflow_step.tasks.filter((task, i) => {
        let t = task;
        if(isStepTaskView) t = tasks.find(t => task.task_template && t.task_template ? t.task_template._id === task.task_template._id : '')

        if(!t) return false
        if(t.finished_on) return false
        return true
    }) : tasks

    return (
        <Card className='card-no-shadow'>

        <div className='table-responsive'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Task</th>
                        <th>Assigned To</th>
                        <th>Due</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {!tasksToLoop.length ? ( 
                        <tr>
                           <td><i className="fas fa-check mr-2 text-success" />No Tasks Found</td>
                           <td></td>
                           <td></td>
                           <td></td>
                       </tr>
                    ) : tasksToLoop.map((task, i) => {
                        // if we are on the stepTaskView we are looping through step templates
                        // here we need to make t an actual found task
                        let t = task;
                        if(isStepTaskView) t = tasks.find(t => task.task_template && t.task_template ? t.task_template._id === task.task_template._id : '')

                        if(!t) return null

                        // if we have a finished task don't show it here
                        if(t.finished_on) return null

                        const overdue = t ? t.due_date && t.due_date <= now : false
                        let assignedToMe = false;
                        let assignedToOthers = 0;
                        let assignedText = '';

                        if(t.assigned_to) {
                            if(t.assigned_to.includes(viewing_user._id)) assignedToMe = true;
                            assignedToOthers = t.assigned_to.filter(i => i !== viewing_user._id).length;
                        }
                   
                        if(assignedToMe && assignedToOthers) {
                            if(assignedToOthers === 1) {
                                assignedText = <span>Me & 1 <i className="fas fa-user ml-1" /></span>
                            } else {
                                assignedText = <span>Me & {assignedToOthers} <i className="fas fa-users ml-1" /></span>
                            }
                        } else if(assignedToMe) {
                            assignedText = 'Me'
                        } else if(assignedToOthers) {
                            if(assignedToOthers === 1) {
                                assignedText = <span>1 <i className="fas fa-user ml-1" /></span>
                            } else {
                                assignedText =  <span>{assignedToOthers} <i className="fas fa-users ml-1" /></span>
                            }
                        }

                        const name = (
                            <span className='cursor-pointer'>
                                {overdue ? <i className="fas fa-exclamation-triangle text-danger mr-2" /> : ''}
                                {formatText(t.creating_user ? t.name : t.task_template ? t.task_template.name : t.name).stripHTML(30)}
                                <div className='text-muted'>{formatText(t.task_template ? t.task_template.description : t.description).stripHTML(30)}</div>
                            </span>
                        )

                        return (
                            <tr key={i}>

                                <td className={overdue ? 'text-dark fontw-weight-bold' : ''}>
                                    <ModalToggler onTaskFinished={findTasks} component={FinishTask} task={t}>
                                        {isStepTaskView ? task.required : t.required ? <i className="fas fa-exclamation mr-2 text-warning" /> : ''}
                                       {name}
                                    </ModalToggler>
                                </td>

                                <td className={overdue ? 'font-weight-bold text-danger' : ''}>
                                    {assignedText}
                                </td>

                                <td className={overdue ? 'font-weight-bold text-danger' : ''}>
                                    {t.due_date ? moment.unix(t.due_date).format('MMM Do h:mm A') : ''}
                                </td>

                                <td className='text-right'>
                                    <ModalToggler onTaskFinished={findTasks} component={FinishTask} task={t}>
                                        <button className='btn btn-sm btn-info'>Finish</button>
                                    </ModalToggler>
                                </td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>

        <CardFooter className='text-right'>
            <ModalToggler component={CreateTask} onSaved={findTasks}>
                <button className='btn btn-outline-success btn-sm'>Create Task</button>
            </ModalToggler>
        </CardFooter>
    </Card>
    );

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocumentsAll);