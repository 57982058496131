
import { useState } from 'react';

import { Col, Row } from "reactstrap";

import ObjectFinder from 'components/system/Objects/Finder';
import AvatarImage from "components/functional/images/AvatarImage";
import NameCircle from 'components/markup/misc/NameCircle';

import Attachments from './Attachments';

import moment from 'moment';

const TextsMessageList = ({thread}) => {

    const [currentYear] = useState(moment().format('YYYY'))

    let lastDay = '';
    let lastTime = ''
    let lastIdentifier = ''

    return thread.texts.map((t, i) => {

        const year = moment.unix(t.date).format('YYYY')
        const day = moment.unix(t.date).format('MMMM Do')
        const time = moment.unix(t.date).format('h:mm A')

        const user = t.user
        const contact = t.contact
        const identifier = user ? user : contact;
        const inbound = t.type === 'inbound' ? true : false;
        
        let renderDay = day !== lastDay;
        let renderTimeAndIdentifier = time !== lastTime || identifier !== lastIdentifier;

        lastDay = day;
        lastTime = time;
        lastIdentifier = identifier;

        return (
            <div key={t._id}>

                {i === 0 ? (
                    <div className='text-muted text-center border-bottom py-3 bg-white '>Start Of Message History</div>
                ) : ''}

                {renderDay ? (
                    <div className='text-center py-3 text-uppercase'>
                        <b className='text-dark '>
                            {day}{year !== currentYear ? ', ' + year : ''}
                        </b>
                    </div>
                ) : ''}
                <Row className='ml-0 mr-0'>
                    {inbound ? (
                        <div className="col-auto  text-center pt-7 mt--3" style={{width: 45}}>
                            {renderTimeAndIdentifier ? (
                                <div className='pt-1 pl-3'>
                                    <NameCircle width={38} initials={thread.thread.initials} style={{marginRight: 5}} />
                                </div>
                            ) : ''}
                        </div>
                    ) : ''}
                   
                    <Col className='px-0'>
                        <div 
                            className={
                                `archk-text-message pb-1 archk-text-message-${inbound ? 'incoming' : 'outgoing'} ` +
                                `${!renderTimeAndIdentifier ? 'pt-0' : ''}`
                            }
                        >

                        {renderTimeAndIdentifier ? (
                            <p className="text-sm mb-0 mt-4">
                                {user ? (
                                    <ObjectFinder collection="users" _id={user} />
                                ) : (
                                    <ObjectFinder collection="contacts" _id={contact} />
                                )} - {time}
                            </p> 
                        ) : ''}

                            {t.body !== 'Media Message' ? (
                                <div className="archk-text-message-wrapper">
                                <p className='text-sm mb-0'>{t.body}</p>
                            </div>

                            )  : ''}
                            
                            <Attachments attachments={t.attachments} />

                        </div>
                    </Col>

                    {!inbound ? (
                        <div className="col-auto  text-left pr-0 pt-7 mt--3 pl-0 ml--3" style={{width: 65}}>
                            {renderTimeAndIdentifier ? (
                                <div className='pt-1 pr-4'>
                                    {/* <NameCircle width={38} initials={thread.thread.initials} style={{}} /> */}
                                    <AvatarImage src="test" style={{width: 38}} />
                                </div>
                            ) : ''}
                        </div>
                    ) : ''}



                </Row>

                {i + 1 === thread.texts.length ? <div id="archk-text-message-end" /> : ''}
            </div>
        )
    })
 
}

export default TextsMessageList