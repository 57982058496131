import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import api from 'api';

const NavbarDesktopHeaderBottomNotifications = ({ selected_division }) => {

    const [count, setCount] = useState(null)

    const color = count === 0 ? 'success' : count <= 49 ? 'info' : 'danger';


    const fetchNotifications = useCallback(async () => {
        const result = await api.user_comments.getCount(selected_division._id)
        if(result.success) setCount(result.data.count)
    }, [selected_division._id])

    useEffect(() => {
        fetchNotifications()

        window.addEventListener('focus', fetchNotifications)

        return () => {
            window.removeEventListener('focus', fetchNotifications)
        }

    }, [fetchNotifications])

    return (

        <Link to="/mentions">
            <i className={`ml-0 text-${color} fas fa-comments`} /> {' '}
            {count === null ? '' : (
                <span 
                    style={styles.notification} 
                    className={` notification notification-${color}`}
                >
                    {count >= 99 ? '99+' : count}
                </span>
            )}
        </Link>

    )

}

const styles = {
    notification: {
        display: 'inline-block',
        position: 'relative',
        width: 35,
        textAlign: 'center'
    }
}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(NavbarDesktopHeaderBottomNotifications);

