/*
Documentation

This page handles updating a users profile

*/


import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

import { Helmet } from 'react-helmet';
import { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Card, CardBody } from "reactstrap";

import ConfirmationModal from 'components/functional/modals/Confirmation'

import Email from './Email';
import Phone from './Phone';
       


const AccountSettings = ({viewing_user}) => {

    const [showModalConfirm, setShowModalConfirm] = useState(null);

    const onStartMFAEmail = useCallback(() => {

    }, [])

    return (
        <div fluid>



            <ConfirmationModal 
                prevButtonClasses="btn btn-success"
                nextButtonClasses="btn btn-outline-warning"
                prevButtonText="Cancel"
                nextButtonText="Confirm"
                showModal={showModalConfirm ? true : false}
                toggleModal={() => setShowModalConfirm(false)}
                title="Proceed With Unverified Accounts"
                body={(
                    <span>
                        Before proceeding please verify all of your accounts. If you do not, no documents will be able to be retrieved from any account marked as needing verification.<br /><br /> 
                        <b>
                            <span className="text-underline">To verify your account(s)</span>, click the{' '}
                            <span className="text-warning">ORANGE BUTTON</span>{' '}
                            next to their name.
                        </b>
                    </span>
                )}
                onConfirmation={() => onStartMFAEmail(true)}
            />

           <Card>
           
               <CardBody>
                   <Row>
                       <Col lg={6} className="text-center">
                            <Email />
                       </Col>
                       <Col lg={6} className="text-center">
                           <Phone />
                       </Col>
                   </Row>
               </CardBody>
           

           </Card>

        </div>
    )

}

const mapStateToProps = state => {
    return {
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountSettings);