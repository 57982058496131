import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';

import moment from 'moment';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import HeaderTabs from 'components/markup/layout/HeaderTabs';
import A from 'components/markup/links/A';
import Circle from 'components/markup/loading/Circle';

import api from 'api';
import ApiError from 'components/markup/layout/ApiError'

const limit = 50;

const MattersViewMainLogs = ({ contact, matter, socket, matchHeight, viewing_user }) => {

    const [err, setErr] = useState(false)
    const [doneFetching, setDoneFetching] = useState(false)
    const [logs, setLogs] = useState(null)
    const [loading, setLoading] = useState(null)
    const [tab, setTab] = useState('')
    const listInnerRef = useRef();

   
    const fetchMore = useCallback(async () => {
        let string = `?limit=${limit}&skip=${logs.length}`
        if(tab) string += `&type=${tab}`
        setLoading(true)
        const _logs = await api.matter_progress_logs.find(string)

        setLoading(false)
        if(_logs.data) {
            if(_logs.data.length < limit) return setDoneFetching(true)
            let newLogs = JSON.parse(JSON.stringify(logs))
            newLogs = newLogs.concat(_logs.data)
            setLogs(newLogs)
        }
    }, [logs, tab])

    const onSetTab = useCallback((_tab) => {
        setTab(_tab)
        setLogs(null)
        setDoneFetching(false)
    }, [])

    const fetchInitial = useCallback(async () => {
        let string = `?limit=${limit}`
        if(tab) string += `&type=${tab}`

        const _logs = await api.matter_progress_logs.find(string)

        if(!_logs.data) return setErr(_logs.message)

        if(_logs.data.length < limit) setDoneFetching(true)
        setLogs(_logs.data);

    }, [tab])

    const fetchBody = useCallback(() => {

        const onScroll = () => {
            if (listInnerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
                if (scrollHeight < scrollTop + clientHeight + 1) {
                    fetchMore()
                }
            }
        }

        let lastDay = ''
        let days = {}

        logs.forEach((entry, i) => {

            const isNegative = entry.percent < 0
            const day = moment.unix(entry.created_at).format('MM/DD/YYYY')
            const nextDay = logs[i + 1] ? moment.unix(logs[i + 1].created_at).format('MM/DD/YYYY') : ''
            const isNewDay = day !== lastDay
            const noNextDay = day !== nextDay

            if(isNewDay) {
                lastDay = day

                days[day] = {
                    matters: [],
                    percent: 0,
                    markup: []
                }
            }

            if(entry.matter) {
                if(!days[day].matters.includes(entry.matter._id)) {
                    days[day].matters.push(entry.matter._id)
                }
            }

            days[day].percent += entry.percent

            let wrapperType;
            let icon;
            let text;

            switch (entry.type) {
                case 'custom_fields':
                    wrapperType = isNegative ? 'text-danger' :  'text-info';
                    icon = 'fas fa-edit pl-1';
                    text =  isNegative ? 'Field(s) Removed': 'Field(s) Entered: ';
                    break;
                case 'matter_documents':
                    wrapperType = isNegative ? 'text-danger' : 'text-success';
                    icon = 'fas fa-file';
                    text = isNegative ? 'Doc Category Marked Incomplete' : 'Doc Category Completed: ';
                    break;
                case 'tasks':
                    wrapperType = 'text-white';
                    icon = 'fas fa-list';
                    text = 'Task Finished';
                    break;

                default:
                    // console.log(entry.type)
                    break;
            }

            days[day].markup.push((
                <div key={entry._id} className={`mb-0 bg-secodary px-3 py-2 ${noNextDay ? '' : 'border-bottom '}`}>

                        
                        <span style={{display: 'inline-block', width: 40}}><i className={`border p-3 bg-dark rounded fas fa-${icon} ${wrapperType} mr-3`} /></span>
                        <span style={{display: 'inline-block', width: '30%'}}>{text}</span>
                        <span style={{display: 'inline-block', width: '30%'}}>{entry.matter ? <A href={`/matters/${entry.matter._id}`}>{entry.matter.name}</A> : '-'}</span>
                        <span style={{display: 'inline-block', width: '20%'}}>{moment.unix(entry.created_at).format('h:mm A')}</span>
                        <span style={{display: 'inline-block', width: 'calc(20% - 40px)'}} className="text-right">{parseFloat(entry.percent.toFixed(2))}%</span>

                </div>
            ))

        })

        return <div className="archk-timeline pr-3 py-3" onScroll={onScroll} style={{overflow: 'auto', }} ref={listInnerRef}>

            {Object.keys(days).map(day => {

                const obj = days[day]

                return (
                    <Card key={day} className="z-depth-">
                        <CardHeader>
                            <CardTitle className='mb-0'>
                                <span style={{display: 'inline-block', width: 'calc(30% + 40px)'}} className="">{day}</span>
                                <span style={{display: 'inline-block', width: 'calc(30%)'}} className="">{obj.matters.length} Matters Moved</span>
                                <span style={{display: 'inline-block', width: 'calc(20%)'}} className="">{parseFloat(obj.percent.toFixed(2))}% Total</span>
                                <span style={{display: 'inline-block', width: 'calc(20% - 40px)'}} className="text-right">
                                    {(parseFloat((obj.percent / obj.matters.length).toFixed(2))).toLocaleString()}% AVG 
                                </span>
                            </CardTitle>
                        </CardHeader>
    
                        {/* <CardBody> */}
                            {obj.markup.map((d, i) => (
                                <span key={i}>{d}</span>
                            ))}
                        {/* </CardBody> */}
                    </Card>
                )
            })}

            {doneFetching || !logs.length ? (
                <p className='text-sm text-center'>No Further Progress Logs Found</p>
            ) : loading ? (
                <Circle />
            ) : (
                <div className='text-center'>
                    <button className='btn btn-success' onClick={fetchMore}>Load More</button>
                </div>
            )}
        </div>
    }, [doneFetching, logs, fetchMore, loading])

    useEffect(() => {
        if(matchHeight) {

            const el = document.getElementById(matchHeight)
            const header = document.getElementById('archk-timeline-logs-header')
            if(el) {
                const setHeight = (tries = 0) => {
                    if(tries > 10) return;
                    if(!listInnerRef.current) return setTimeout(() => {
                        setHeight(tries + 1)
                    }, 250)

                    let height = listInnerRef.current.style.height = el.offsetHeight;
                    if(header) height = height - header.offsetHeight - 10
                    listInnerRef.current.style.height = height + 'px'

                }
                setHeight()
            }
        }
    }, [matchHeight])

    useEffect(() => {
        fetchInitial()
    }, [fetchInitial])

    useEffect(() => {
        const el = document.querySelector('#archk-timeline-logs .archk-timeline');
        const height = window.innerHeight - el.getBoundingClientRect().top;
        if(height < 200) return;

        el.style.height = (height - 25) + 'px'
    }, [])
   

    const body = !logs ? (
        <div className="archk-timeline">
            <Circle className="py-6" />
        </div>
    ) : fetchBody()

    if(err) return <ApiError err={err} className="py-4 mx-4" /> 

    return (
        <>

        <Helmet>
            <title>Matter Progress</title>
            <meta name="description" content="Matter Progress" />
        </Helmet>

        <HeaderNavigation 
            title="Matter Progress"
            description="Here you can view the actionable events you have made to move matters closer to completion."
        />

        <Container fluid>
        
            <Card id="archk-timeline-logs" className=''>

                <CardHeader id="archk-timeline-logs-header" className='pb-0'>
                    <Row>

                        <Col xs={3} className="align-self-cente ">
                            <CardTitle className="pt-1">Matter Progress Events</CardTitle>
                        </Col>

                        <Col xs={9} className="align-self-end text-right">
                            <HeaderTabs 
                                className="mb-0 mr--4 d-none d-lg-block"
                                tab={tab}
                                setTab={(tab) => onSetTab(tab)}
                                tabs={[
                                    { value: 'custom_fields', name: <span><i className="fas fa-edit " /> Fields</span> },
                                    { value: 'matter_documents', name: <span><i className="fas fa-file " /> Docs</span> },
                                    { value: 'tasks', name: <span><i className="fas fa-list " /> Tasks</span> },
                                    { value: false, name: <span><i className="fas fa-comments " /> All</span> },
                                ]}

                            />
                        </Col>

                    </Row>
                </CardHeader>

                <CardFooter className='py-0 my-0 bg-secondary'>
                    {body}
                </CardFooter>

            </Card>

        </Container>
        </>
       
    )

}

const mapStateToProps = state => {
	return {
	    socket: state.socket,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(memo(MattersViewMainLogs));


