import { connect } from 'react-redux';
import { Modal, Row, Col, Badge } from "reactstrap";

import moment from 'moment-timezone';

const ModalCustomFields = ({showModal, toggleModal, log}) => {

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="xl"
        >

            <div className="modal-header">
               <h5 className="modal-title">Network Log</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
            </div>

            <div className="modal-body">
                <Row>
                    <Col md={6} className="align-self-center">
                        <p className='text-sm mb-0'><b>Timestamp:</b> {moment.unix(log.start).format('hh:mm:ss A') } {moment.tz.guess}</p>
                    </Col>
                    <Col md={6} className="text-right align-self-center">

                        <Badge 
                            color={log.duration / 1000 < 1 ? 'success' : log.duration / 1000 < 2 ? 'warning' : 'danger'}
                        >
                            {log.duration / 1000}
                        </Badge>

                    </Col>
                </Row>
            </div>
            <div className="modal-body">
                <Row>
                    <Col lg={6} className="border">
                        <div className="border bg-secondary p-3 rounded">
                            <h3>Request</h3>
                            <span style={{whiteSpace: 'pre-line'}}>
                                <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(log.request, null, 4)}</pre>
                            </span>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="border bg-secondary p-3 rounded">
                            <h3>Response</h3>
                            <span style={{whiteSpace: 'pre-line'}}>
                                <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(log.response, null, 4)}</pre>
                            </span>
                        </div>
                      
                    </Col>
                </Row>
           </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalCustomFields);