import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from "reactstrap";

import api from 'api';

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

const LoginButton = ({ email, synced, division }) => {

    const [url, setUrl] = useState(null)

    const fetchData = useCallback( async () => {
        if(!division) return; 
        const result = await api.nylas.login(division)
        if(result.data) return setUrl(result.data.url);
    }, [division])
   
    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!url) return <></>

    return (
        synced ? (
            <a style={{width: 150}} href={url + '___' + encodeURI(email)} className={`btn btn-outline-info btn-sm`}>
                Login To Update <i className="fas fa-link ml-2" /> 
            </a>
        ) : (
            <a style={{width: 150}} href={url + '___' + encodeURI(email)} className={`btn btn-success btn-sm`}>
                Login To Sync <i className="fas fa-link ml-2" /> 
            </a>
        )
    )   
}

const UserSettingsMatter = ({viewing_user}) => {

    const [err, setErr] = useState(false)
    const [grants, setGrants] = useState(null)

    const isSynced = useCallback((email) => {

        const foundGrant = grants.find(g => g.email === email);
        if(foundGrant && foundGrant.status === 'synced') return true;
        return false;
    }, [grants])

    const load = useCallback(async () => {
        const grants = await api.nylas_email_grants.find();

        if(grants.data) return setGrants(grants.data)
        setErr(true)

    }, [])


    useEffect(() => {
        load()
    }, [load])

    return (
        // <Form>
        <Card>
            <CardHeader>
                <h6 className="heading-small text-muted mb-0">Calendar and Email</h6>
            </CardHeader>

            {err ? (
                <CardBody>
                    <i className="fas fa-exclamation-triangle mr-2 text-warning" /> Something went wrong loading this page, please try again.
                </CardBody>
            ) : !grants ? (
                <CardBody>
                    <Circle />
                </CardBody>
            ) : (
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Divisions</th>
                                <th>Email</th>
                                <th>Status</th>
                                <td></td>
                            </tr>
                        </thead>

                        <tbody>
                            {viewing_user.division_overrides.length ? viewing_user.division_overrides.map(d => {
                                const synced = isSynced(d.email);
                                return (
                                    <tr key={d._id}>
                                        <td><ObjectFinder collection="divisions" _id={d.division} /></td>
    
                                        <td className='font-weight-bold text-dark'>{d.email}</td>
    
                                        <td>
                                            {synced ? (
                                                <span><i className="fas fa-check text-success mr-2" /> Synced</span>
                                            ) : (
                                                <span><i className="fas fa-times text-danger mr-2" /> Not Synced</span>
                                            )}
                                        </td>
    
                                        <td className='text-right'>
                                            <LoginButton 
                                                synced={synced}
                                                division={d.division}
                                                email={d.email}
                                            />
    
                                        </td>
                                    </tr>
                                )
                            }) : (
                                <tr>
                                    <td>You Do Not Have Any Authorized Emails To Sync</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}

        </Card>

    );
}


const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(UserSettingsMatter);