
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import HistoryMarkup from './HistoryMarkup';

const EventsEditHistory = ({ foundEvent, history, saving, showHistory, setShowHistory }) => (
    <div className='my-3'>
        
        <Card>
            <CardHeader>
                <CardTitle className='mb-0'>Event History</CardTitle>
            </CardHeader>
            <CardBody>
                <HistoryMarkup foundEvent={foundEvent} history={history} />
            </CardBody>
        </Card>


        <button disabled={saving} onClick={() => setShowHistory(!showHistory)} className="btn btn-outline-info"><i className="fas fa-arrow-left mr-2" /> Update Event</button> 
    </div>

)

export default EventsEditHistory