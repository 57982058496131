import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';




const ModalDivisionOverride = ({showModal, toggleModal, findTasks, item, onItemChange, modalIndex}) => {

    const [saving, setSaving] = useState(false);
    const [outcome, setOutcome] = useState('');

    const onSave = useCallback(async () => {
        if(!outcome) return toast.info(`You must add an outcome before resolving the outstanding item.`)

        setSaving(true)
        const saved = await api.outstanding_items.finish(item._id, { outcome })
        setSaving(false)
        if(!saved.success) return toast.error(`Could not finish completing this item at this time, please try again.`)
        findTasks()
        if(onItemChange) onItemChange('finished', saved)

    }, [item._id, findTasks, outcome, onItemChange])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
            zIndex={modalIndex}
        >

            <div className="modal-header">
                <h5 className="modal-title">Finish An Outstanding Item</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary border-bottom">
                <p className='text-sm mb-0'>Marking The Following Outstanding Item Complete: <b className='text-underline text-dark'>{item.name}</b></p>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className='form-control-label'>Item Outcome</label>
                    <p className='text-sm mb-0'>Ex: "Called Client, They Emailed Documents Needed."</p>
                    <Input 
                        type="text"
                        value={outcome}
                        onChange={e => setOutcome(e.target.value)}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                
                <button className="btn btn-success" onClick={onSave} disabled={saving}>
                    Mark Complete
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        selected_matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);