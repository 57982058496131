import { useState, useCallback } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container, Alert } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'
import ConfirmationModal from 'components/functional/modals/Confirmation'

import { toggleStandardLoader } from 'store/functions/system/system'
import { toast } from 'react-toastify'


import api from 'api';

const SigningTemplatesAll = () => {

    const [ templateToDelete, setTemplateToDelete ] = useState(null);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)
    const [ hideTable, setHideTable ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }

        const query = await api.signing_templates.search({ ...params })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const onDelete = useCallback(async (_templateToDelete) => {
        toggleStandardLoader(true);
        const deleted = await api.signing_templates.delete(_templateToDelete._id);
        toggleStandardLoader(false);
        if(deleted.success) {
            setHideTable(true);
            setTimeout(() => {
                setHideTable(false)
            }, 250)
        } else {
            toast.error(`Something's not right, please try again`)
        }
    }, [])

    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div>No Signing Templates Have Been Created. <Link to="/editor/signing/new?template=true" className="text-underline">Click Here To Add One</Link></div>
                )
                return (
                    <Link className="text-capitalize" to={`/editor/signing/${row._id}?template=true`}>
                        {row.name}
                    </Link>
                )
                
            },
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: 'Actions',
            headerFormatter: () => {
                return <div>Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <Link to={`/editor/signing/${row._id}`}><i className="fas fa-paper-plane text-info mr-3"></i></Link>
                        <Link to={`/editor/signing/${row._id}?template=true`}><i className="fas fa-edit text-success mr-3"></i></Link>
                        <i onClick={() => setTemplateToDelete(row)} className="fas fa-trash text-danger cursor-pointer"></i>
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }



    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Signing Templates`}</title>
                <meta name="description" content="Signing Templates" />
            </Helmet>

            <DashHeaderOpen 
                title={<span>Dashboard</span>}
                breadcrumb_1="Signing"
                breadcrumb_2="Templates"
                icon="fas fa-file-alt"
                actionComponent={(
                    <Link to="/editor/signing/new?template=true">
                        <button className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</button>
                    </Link>
                )}
            />
    
            <Card className="card-color card-primary table-fixed">
                {hideTable ? null : <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Name"
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />}
            </Card>

            <ConfirmationModal 
                showModal={templateToDelete ? true : false}
                toggleModal={() => setTemplateToDelete(false)}
                title="Delete Template"
                body={(
                    <span>
                        Are you sure you wish to delete the template{' '}
                        "<b className="text-underline">{templateToDelete ? templateToDelete.name : null}</b>"?{' '}
                    </span>
                )}
                onConfirmation={() => onDelete(templateToDelete)}
            />
    
        </Container>
    )

}

export default SigningTemplatesAll
