/*

'custom_field',
'send_text',
'send_email',
'create_event',
'assign_tag',
'send_form',
'send_document_for_signature',
'upload',
'confirmation',
'assign_contact',
'assign_location'
*/


import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import AssignContact from './AssignContact'
import AssignRole from './AssignRole'
import AssignLocation from './AssignLocation'
import AssignTag from './AssignTag'
import Confirmation from './Confirmation'
import CreateEvent from './CreateEvent'
import CustomField from './CustomField'
import SendEmail from './SendEmail'
import SendText from './SendText'
import SendForSignature from './SendForSignature'
import Upload from './Upload'
import SetIdentifier from './SetIdentifier'
import SendForm from './SendForm'
import SelectTag from './SelectTag'
import CreateOutstandingItem from './CreateOutstandingItem'

import { setSelectedMatter } from 'store/functions/matters'

const FinishTask = ({showModal, toggleModal, task, onTaskFinished}) => {

    const onFinish = useCallback((data) => {
        toggleModal()
        if(onTaskFinished) onTaskFinished(data)
        if(task.type === 'assign_contact' || task.type === 'assign_role') setSelectedMatter(task.matter)

    }, [toggleModal, onTaskFinished, task.type, task.matter])

    if(!showModal) return <></>;

    return (

        <Modal
           className="modal-dialog-centered display-none"
           isOpen={showModal}
           toggle={toggleModal}
           size={task && task.type === 'send_form' ? 'sm' : 'lg'}
        >

            <div className="modal-header">
                <h5 className="modal-title">Finish Task</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {task.type === 'assign_contact' ? (

                    <AssignContact task={task} onFinish={onFinish} />

                ) : task.type === 'assign_role' ? (

                    <AssignRole task={task} onFinish={onFinish} />

                ) : task.type === 'assign_location' ? (

                    <AssignLocation task={task} onFinish={onFinish} />

                ) : task.type === 'assign_tag' ? (

                    <AssignTag task={task} onFinish={onFinish} />

                ) : task.type === 'select_tag' ? (

                    <SelectTag task={task} onFinish={onFinish} />

                ) : task.type === 'confirmation' ? (

                    <Confirmation task={task} onFinish={onFinish} />

                ) : task.type === 'set_identifier' ? (

                    <SetIdentifier task={task} onFinish={onFinish} />

                ) : task.type === 'create_event' ? (

                    <CreateEvent task={task} onFinish={onFinish} />

                ) : task.type === 'custom_field' ? (

                    <CustomField task={task} onFinish={onFinish} />

                ) : task.type === 'send_email' ? (

                    <SendEmail task={task} onFinish={onFinish} />

                ) : task.type === 'send_text' ? (

                    <SendText task={task} onFinish={onFinish} />

                ) : task.type === 'send_document_for_signature' ? (

                    <SendForSignature task={task} onFinish={onFinish} toggleModal={toggleModal} />

                ) : task.type === 'upload' ? (

                    <Upload task={task} onFinish={onFinish} toggleModal={toggleModal} />

                ) : task.type === 'send_form' ? (

                    <SendForm task={task} onFinish={onFinish} toggleModal={toggleModal} />

                ) : task.type === 'create_outstanding_item' ? (

                    <CreateOutstandingItem task={task} onFinish={onFinish} toggleModal={toggleModal} />

                ) : ''}
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> Close
                </button>

                {task.type !== 'send_email' && task.type !== 'send_text'  ? (
                    <button id="archk-tasks-finish-button" className="btn btn-success">
                        <i className="fas fa-check mr-2" /> Finish Task
                    </button>
                ) : ''}
               
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(FinishTask);