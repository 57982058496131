/*
address
property type (single family, duplex, etc.)
property nature of interest
description
other info
ownership (d1, d2, d1 & 2)

current value
current_value_type (unknown, partial interest)

amount of secured claim (unknown / linked to liens)
amount of secured claim type (unkown, known, linked to liens)

year
make
model
mileage

name of institution
Type of account ('checking savings, certificates o deposit, ira)
percent Ownership

beneficiary



*/



import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import { formatCurrency } from 'utils/currency';

const ModalDivisionOverride = ({obj, field, onInputChange}) => {

    const type = `${field}_type`;
    const interest = `${field}_partial_interest_percent`;

    return (

        <div>

            <ReactSelect
                title="What Is The Type Of This Assets Value"
                formGroup={true}
                onChange={(obj) => onInputChange(type, obj.value)}
                options={[
                    { value: 'known', label: 'Known' },
                    { value: 'unknown', label: 'Unknown' },
                    { value: 'partial', label: 'Partial Interest' },
                ]}
                value={obj[type] ? obj[type] : 'known'}
            />    

            <FormGroup>
                <label className="form-control-label">Current Value: {obj[field] ? <span className='text-info font-weight-bold'>{formatCurrency(obj[field])}</span> : '$0.00'}</label>
                <Input 
                    disabled={obj[type] === 'unknown' ? true : false}
                    type="number"
                    value={obj[field]}
                    onChange={e => onInputChange(field, parseFloat(e.target.value))}
                />
            </FormGroup>   

            {obj[type] === 'partial' ? (
                <FormGroup>
                    <label className="form-control-label">% Owned</label>
                    <Input 
                        type="number"
                        value={obj[interest]}
                        onChange={e => {
                            const val = parseFloat(e.target.value);

                            onInputChange(interest, val < 0 ? 0 : val > 100 ? 100 : val)
                        }}
                    />
                </FormGroup>   
            ) : ''} 


        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);