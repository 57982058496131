import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';

import ConfirmationModal from 'components/functional/modals/Confirmation';

import Circle from 'components/markup/loading/Circle';
import moment from 'moment';

const MattersViewMainTasks = ({matter, selected_division, fetchMatterEvents}) => {

    const [assignments, setAssignments] = useState(null)
    const [assignmentToStop, setAssignmentToStop] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await api.automation_assignments.findByMatter(matter._id);
        if(!data.data) return toast.error(`Could not fetch automations assigned to this matter`);

        setAssignments(data.data)
    }, [matter._id])

    const onRemoveAutomation = useCallback(async () => {
        const result = await api.automation_assignments.delete(assignmentToStop._id)
        setAssignmentToStop(false)
        if(result.success) return fetchData();

        toast.error(`Automation could not be stopped, please try again.`)
    }, [assignmentToStop, fetchData])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!assignments) return <Circle />

    return (
        <div className="section pt-0">
            <Card className='card-no-shadow'>

                <CardHeader className="py-3">
                    <Row>
                        <Col md={6} className='align-self-center'>
                            <CardTitle className="mb-0">Assigned Automations</CardTitle>
                        </Col>

                    </Row>
                </CardHeader>

                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Automation</th>
                                <th></th>
                                <th>Results</th>
                                <th>Next Run Date</th>
                            </tr>
                        </thead>

                        {assignments.length ? assignments.map(a => {

                            a.history.reverse();

                            return (
                                <>
                                    <tr key={a._id}>
                                        <td>
                                            {a.automation ? a.automation.name : 'Automation Not Found'}
                                        </td>

                                        <td style={{width: 160}}>
                                            {a.finished ? (
                                                <Badge color="danger">Finished</Badge> 
                                            ) : ( 
                                                <Badge color="success" className='cursor-pointer' onClick={(() => setAssignmentToStop(a))}>Active</Badge>
                                            )}
                                        </td>
                                        <td>
                                            {!a.finished ? <span>Next Run: {moment.unix(a.next_run_date).format('MM/DD/YYYY h:mm A')}</span> : '-'}
                                        </td>
                                    </tr>

                                    {a.history.map((aa, i) => (

                                        <tr>
                                            <td></td>
                                            <td>{moment.unix(aa.date).format('MM/DD/YYYY h:mm A')}</td>
                                            <td>{aa.result}</td>
                                            <td></td>
                                        </tr>

                                    ))}
                                    <tr>
                                        <td></td>
                                        <td>{moment.unix(a.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                        <td className='text-dark font-weight-bold'>Assigned By: {a.created_by}</td>
                                        <td></td>
                                    </tr>
                                </>
                            )
                        }) : (
                            <tr>
                                <td>No automations have been assigned to this matter.</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </table>
                </div>

                <CardFooter className='text-right'>
                    {/* <button onClick={onSave} className='btn btn-success btn-sm'>Confirm All</button> */}
                </CardFooter>

                <ConfirmationModal 
                    showModal={assignmentToStop ? true : false}
                    toggleModal={() => setAssignmentToStop(false)}
                    title={"Stop Automation"}
                    onConfirmation={onRemoveAutomation}
                    body={(
                        <span>
                            Are you sure you wish to stop the automation {assignmentToStop && assignmentToStop.automation ? assignmentToStop.automation.name : ''}?
                        </span>
                    )}
                />
                
            </Card>

        </div>
    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainTasks);