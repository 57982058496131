import { memo } from 'react';
import moment from 'moment'

const MattersViewMainComponentsStepHistory = ({m}) => {

    return (
        <div className='ml-7'>
            <div className='archk-main-content-message'>
                {m.content.map((message, i) => (
                    <pre style={{whiteSpace: 'break-spaces'}}>{message.text.value}</pre>
                ))}
            </div>
            
            <p className='text-sm'>Assistant - {moment.unix(m.created_at).format('MM/DD/YYYY h:mm A')}</p>
        </div>
    )

}

export default memo(MattersViewMainComponentsStepHistory)