import { Col, Row } from "reactstrap";

import NameCircle from 'components/markup/misc/NameCircle';
import Dots from 'components/markup/loading/Dots';
import A from 'components/markup/links/A';

import formatText from 'utils/formatText'

import ModalToggler from 'components/functional/modals/Toggler'
import CallContact from 'components/system/CallContact'
import ObjectFinder from 'components/system/Objects/Finder';
                    
const TextsHeader = ({thread, active}) => {

    if(!thread && !active) return (
        <div className="archk-messaging-main-header z-depth-1">
            <h1 className='display-4 pt-1 text-center'><i className="fas fa-exclamation-triangle mr-2 text-warning" /> Support Tickets</h1>
        </div>
    )

    if(!thread) return (
        <div className="archk-messaging-main-header z-depth-1">
            <h1 className='display-4 pt-1'>Loading<Dots /></h1>
        </div>
    )

    return (

        <div className="archk-messaging-main-header z-depth-1">
            <Row>
                <div className="col-auto align-self-center" style={{width: 55}}>
                    <NameCircle width={45} initials={thread.ticket.initials} style={{marginRight: 5}} />{' '}
                </div>
                <Col className="align-self-center">
                    <Row>
                        <Col md={8} className="align-self-center">
                        <h2 className="mb-0">
                            {thread.contact.display_name ? thread.contact.display_name : formatText(thread.contact.phone).phone()}{' '}
                            {thread.contact.email || thread.contact.phone ? (
                                <small><small> 
                                    {' - '}{thread.contact.email ? thread.contact.email : ''} {thread.contact.phone ? formatText(thread.contact.phone).phone() : ''}
                                </small></small>
                            ) : ''}
                            {/* <span className="text-danger"><i className="fas fa-exclamation-triangle mx-2" /> OPTED OUT</span> */}
                        </h2>

                        {thread.matters && thread.matters.length && thread.ticket ? (
                            <p className="text-sm mb-0 text-muted">
                                <b className="text-dark">
                                    {thread.ticket.division ? <ObjectFinder collection="divisions" _id={thread.ticket.division} /> : 'Unknown Division'}
                                </b> 
                                {' - '}
                                {thread.matters.filter(m => m.division === thread.ticket.division).length} Open Cases
                            </p> 
                        ) : (
                            <p className="text-sm mb-0 text-warning">
                                <b className="text-dark">
                                    {thread.ticket.division ? <ObjectFinder collection="divisions" _id={thread.ticket.division} /> : 'Unknown Division'}
                                </b>
                                {' - '}
                                0 Open Cases
                            </p>

                        )}

                        </Col>
                        <Col md={4} className="align-self-center text-right">
                            {thread.ticket && thread.ticket.matters.length > 1 ? (
                                <ModalToggler 
                                    component={CallContact} 
                                    contact={thread.contact._id}
                                >
                                    <i className="archk-messaging-main-icon fa-solid fa-hand-pointer" />
                                </ModalToggler>
                            ) : ''}
                        

                            <ModalToggler 
                                component={CallContact} 
                                matter={thread.ticket && thread.ticket.matters.length === 1 ? thread.ticket.matters[0]._id : null} 
                                contact={thread.contact._id}
                            >
                                <i className="archk-messaging-main-icon fas fa-phone" />
                            </ModalToggler>

                            <A href={`/contacts/${thread.contact.id}`}>
                                <i className="archk-messaging-main-icon fas fa-user" />
                            </A>

                            <A href={`/billing/${thread.contact.id}`}>
                                <i className="archk-messaging-main-icon fa-solid fa-wallet" />
                            </A>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )

}

export default TextsHeader