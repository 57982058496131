import { useCallback, useState, useEffect } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api';

import { toast } from 'react-toastify';

const FinishTaskCustomField = ({task, onFinish}) => {

    const name = task.task_template ? task.task_template.name : task.name
    const description = task.task_template ? task.task_template.description : task.description

    const [answer, setAnswer] = useState('')

    const onSave = useCallback(async () => {
        if(!answer) return;
        const foundOption = task.task_template.tag_answers.find(d => d.option === answer);
        if(!foundOption) return toast.error(`Internal Error Occurred When Finishing This Task, Please Contact Support`)

        const data = await api.matters.tagAdd({ 
            matter: task.matter, 
            tag: foundOption.tag,
            task: task._id
        })
        if(!data.data) return toast.error(`Something's not right, please try again.`)
        onFinish(data.data)
    }, [task, answer, onFinish])

    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])

    if(!task.task_template) return (
        <p className='text-sm text-danger mb-0 font-weight-bold'>This task has been altered by a system admin and cannot be completed at this time.</p>
    )

    return (
        <div>
             <ReactSelect
                title={name}
                description={description}
                formGroup={true}
                placeholder=""
                onChange={(obj) => setAnswer(obj.value)}
                options={task.task_template.tag_answers.map(r => {
                    return { label: r.option, value: r.option }
                })}
                value={answer}
            />    
        </div>
    )
}

export default FinishTaskCustomField