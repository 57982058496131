import { useEffect, useState, useCallback } from 'react';

import SearchCollections from "components/system/Search/Collections";
import Circle from 'components/markup/loading/Circle'
import ApiError from 'components/markup/layout/ApiError'

import api from 'api'

import { toast } from 'react-toastify'

const FinishTaskAssignLocation = ({task, onFinish}) => {

    const name = task.task_template ? task.task_template.name : task.name;
    const description = task.task_template ? task.task_template.description : task.description;

    const workflow_location = task.task_template ? task.task_template.workflow_location : null;

    const [err, setErr] = useState(false)
    const [location, setLocation] = useState(null)
    const [workflowLocation, setWorkflowLocation] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await api.workflow_locations.findById(workflow_location) 
        if(!data.success || (data.data && !data.data.location_type)) return setErr('Could not associate location, please refresh your page or contact support if the issue persists')
        setWorkflowLocation(data.data)
    }, [workflow_location])

    console.log(task)

    const onSave = useCallback(async () => {
        if(!location) return;
        const data = await api.matters.locationAdd({ 
            matter: task.matter, 
            workflow_location: workflowLocation._id, 
            location,
            task: task._id
        })
        if(!data.data) return toast.error(`Something's not right, please try again.`)
        onFinish(data.data)
    }, [workflowLocation._id, task._id, location, onFinish, task.matter])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])

    if(err) return <ApiError err={err} />
    if(!workflowLocation) return <Circle />

    return (
        <div>
            <SearchCollections
                collection={'locations'}
                title={name}
                description={description}
                includeFields={[]}
                placeholder="Search Locations..."
                value={location}
                onChange={(obj) => setLocation(obj.value)}
                filter={{
                    location_types: { $in: [workflowLocation.location_type] },
                    division: task.division
                }}
            /> 

        </div>
    )
}

export default FinishTaskAssignLocation