import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';

import PreviewImage from './PreviewImage';
import PreviewPDF from './PreviewPDF';
import PreviewNameChange from './PreviewNameChange';

import { toast } from 'react-toastify'


const imageEndings = ['jpeg', 'jpg', 'png', 'tif', 'tiff', 'svg', 'gif', 'heic']

const Documents = ({doc, children, onNameUpdated, id}) => {

    const [shouldRender, setShouldRender] = useState(false)
    const [showPreviewImageModal, setShowPreviewImageModal] = useState(false)
    const [showPreviewPDFModal, setShowPreviewPDFModal] = useState(false)
    const [showPreviewNameChange, setShowPreviewNameChange] = useState(false)
    const [data, setData] = useState(false)

    const onPreview = useCallback(() => {

        setShouldRender(true)

        const split = doc.mimetype ? doc.mimetype.split('/')[1] : ''

        if(split && imageEndings.includes(split)) {
            setShowPreviewImageModal(true)
        } else if(doc.mimetype && doc.mimetype.includes('pdf')) {
            setShowPreviewPDFModal(true)
        } else {
            setShowPreviewNameChange(true)
        }
        
    }, [doc])
 
   return (
    <span className="archk-image-renderer">
        
        {id ? (
            <span id={id} onClick={onPreview}>{children}</span>
        ) : (
            <span onClick={onPreview}>{children}</span>
        )}

        {shouldRender ? (
            <>
                {showPreviewImageModal && (
                    <PreviewImage 
                        showModal={showPreviewImageModal}
                        toggleModal={() => setShowPreviewImageModal(!showPreviewImageModal)}
                        doc={doc}
                        data={data}
                        setData={setData}
                        onNameUpdated={onNameUpdated}
                    />
                )}
                {console.log(showPreviewNameChange)}
                {showPreviewNameChange && (
                    <PreviewNameChange 
                        showModal={showPreviewNameChange}
                        toggleModal={() => setShowPreviewNameChange(!showPreviewNameChange)}
                        doc={doc}
                        data={data}
                        setData={setData}
                        onNameUpdated={onNameUpdated}
                    />
                )}
                
                {showPreviewPDFModal && (
                    <PreviewPDF 
                        showModal={showPreviewPDFModal}
                        toggleModal={() => setShowPreviewPDFModal(!showPreviewPDFModal)}
                        doc={doc}
                        data={data}
                        setData={setData}
                        onNameUpdated={onNameUpdated}
                    />
                )}
            </>
        ) : null}
    

    </span>

   )

}

Documents.propTypes = {
    doc: PropTypes.object.isRequired,
    maxNameLength: PropTypes.number,
}

export default Documents
