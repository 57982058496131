import { Nav, NavItem, NavLink } from "reactstrap";
import React from 'react';

const ModalCustomFields = ({ schedules, schedule, onSetSchedule }) => (
    <Nav
        className="nav-fill flex-column flex-sm-row mb-4"
        pills
        role="tablist"
    >
        {schedules.map((t, i) => (
            <NavItem key={i}>
                <NavLink
                    aria-selected={schedule === t}
                    className={`mb-sm-3 mb-md-0 ${schedule === t ? 'active' : ''}`}
                    onClick={e => onSetSchedule(t)}
                    role="tab"
                >
                    {t}
                </NavLink>
            </NavItem>
        ))}

    </Nav>
)


export default ModalCustomFields