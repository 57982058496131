import { useState } from 'react';
import { Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import api from 'api';

import ConfirmationModal from 'components/functional/modals/Confirmation'
import CopyToClipboard from 'react-copy-to-clipboard'

       
const CompanyBranding = ({company, setCompany}) => {

    const [confirmRoll, setConfirmRoll] = useState(false);
    const [keys, setKeys] = useState(false);

    const [copiedKey, setCopiedKey] = useState(false)
    const [copiedSecret, setCopiedSecret] = useState(false)
    const [copiedSigningSecret, setCopiedSigningSecret] = useState(false)

    const onSetCopiedKey = () => {
        setCopiedKey(true)
        setTimeout(() => { setCopiedKey(false) }, 1000)
    }
    const onSetCopiedSecret = () => {
        setCopiedSecret(true)
        setTimeout(() => { setCopiedSecret(false) }, 1000)
    }
    const onSetCopiedSigningSecret = () => {
        setCopiedSigningSecret(true)
        setTimeout(() => { setCopiedSigningSecret(false) }, 1000)
    }

    if(!company.branding) company.branding = {};

    const generateAPIKeys = async () => {

        toggleStandardLoader(true)
        const generated = await api.companies.rollKeys();
        toggleStandardLoader(false)

        if(generated.success && generated.data) {
            setCompany();
            setKeys(generated.data);
        } else {
            toast.error('Something went wrong, please try again later');
        }

    }

    return (
        <Card>

            <ConfirmationModal 
                showModal={confirmRoll ? true : false}
                toggleModal={() => setConfirmRoll(false)}
                title="Roll Keys"
                body={<span>Are you sure you wish to roll your API keys? Any existing integrations will not work until their API key pair has been updated to the new set that will be generated</span>}
                onConfirmation={generateAPIKeys}
            />

            <CardHeader>
                <CardTitle className="mb-0">API Keys</CardTitle>
            </CardHeader>
            
            <CardHeader>
                <p className="text-sm">API Keys allow other applications to interact with IntuiDesk in the form of creating contacts and sending requests for documents.</p>
                <p className="text-sm mb-0">{company.api_key ? (
                    <span><i className="fas fa-check mr-3 text-success " /> Your application currently has a set of API keys in place. If you need to change them you can roll your keys with the button below.</span>
                ) : (
                    <span>Your account does not currently have an API key pair associated. You may create a set with the button below.</span>
                )}</p>
            </CardHeader>

            {company.api_key && company.api_secret_last_six ? (
                <CardHeader>
                    {keys ? (
                        <>
                            <p className="text-sm mb-0 text-muted"><b>API KEY</b></p>
                            <p className="text-sm text-dark">
                                
                                <CopyToClipboard 
                                    text={keys.apiKey}
                                    onCopy={onSetCopiedKey}
                                    >
                                    <i className="fas fa-copy cursor-pointer text-success mr-3" />
                                </CopyToClipboard>
                                {keys.apiKey}
                                {copiedKey ? (<span className="text-warning d-block">COPIED!</span>) : null}
                            </p>

                            <p className="text-sm mb-0"><b>API SECRET</b></p>
                            <p className="text-sm mb-0 text-dark">
                            <CopyToClipboard 
                                text={keys.apiSecret}
                                onCopy={onSetCopiedSecret}
                                >
                                <i className="fas fa-copy cursor-pointer text-success mr-2" />
                            </CopyToClipboard>

                                {keys.apiSecret}
                                {copiedSecret ? (<span className="text-warning d-block">COPIED!</span>) : null}
                            </p>
                            {!copiedSecret ? (
                            <p className="text-sm">
                                <b>
                                    <span className="text-warning d-block">
                                        <i className="fas fa-arrow-up mr-2 ml-4" /> 
                                        THIS KEY WILL NEVER BE SHOWN AGAIN, STORE IT SOMEWHERE SAFE
                                    </span>
                                </b>
                            </p>
                            ) : null}

                            <p className="text-sm mb-0"><b>Webhook Signing Secret</b></p>
                            <p className="text-sm mb-0 text-dark">
                            <CopyToClipboard 
                                text={keys.apiSigningSecret}
                                onCopy={onSetCopiedSigningSecret}
                                >
                                <i className="fas fa-copy cursor-pointer text-success mr-2" />
                            </CopyToClipboard>

                                {keys.apiSigningSecret}
                                {copiedSigningSecret ? (<span className="text-warning d-block">COPIED!</span>) : null}
                            </p>
                            {!copiedSigningSecret ? (
                            <p className="text-sm">
                                <b>
                                    <span className="text-warning d-block">
                                        <i className="fas fa-arrow-up mr-2 ml-4" /> 
                                        THIS KEY WILL NEVER BE SHOWN AGAIN, STORE IT SOMEWHERE SAFE
                                    </span>
                                </b>
                            </p>
                            ) : null}


                        </>
                    ) : (
                        <>
                            <p className="text-sm mb-0 text-muted"><b>API KEY</b></p>
                            <p className="text-sm">
                                <CopyToClipboard 
                                    text={company.api_key}
                                    onCopy={onSetCopiedKey}
                                    >
                                    <i className="fas fa-copy cursor-pointer text-success mr-3" />
                                </CopyToClipboard>
                                {company.api_key}
                                {copiedKey ? (<span className="text-warning d-block">COPIED!</span>) : null}
                            </p>
                            <p className="text-sm mb-0"><b>API SECRET</b></p>
                            <p className="text-sm">.....{company.api_secret_last_six}</p>
                            <p className="text-sm mb-0"><b>Webhook Signing Secret</b></p>
                            <p className="text-sm">.....{company.api_signing_secret_last_six}</p>
                        </>
                    )}

                </CardHeader>
            ) : null}

            <CardFooter className="text-right">
                {company.api_key ? (
                    <button onClick={() => setConfirmRoll(true)} className="btn btn-outline-danger">
                        <i className="fas fa-exclamation-triangle mr-2 " /> Roll Keys
                    </button>
                ) : (
                    <button onClick={generateAPIKeys} className="btn btn-success">
                        <i className="fas fa-plus mr-2 " /> Generate Keys
                    </button>
                )}
            </CardFooter>

        </Card>
    )

}

export default CompanyBranding;
