import { connect } from 'react-redux';

import { Row, Col, Nav, NavItem, NavLink, Input } from 'reactstrap';

import { useEffect, useState } from 'react'

import Thread from './Thread';

import ThreadNotSelected from './ThreadNotSelected';

const AI = ({ currentMessage, setCurrentMessage, onAddMessage, onAddThread, selectedThread, setSelectedThread, messages, selectedAssistant }) => {

    // if(!creatingThread && !selectedThread) return <ThreadNotSelected />

    return (
        // <ThreadNotSelected />
        <Thread 
            messages={messages}
            currentMessage={currentMessage}
            setCurrentMessage={setCurrentMessage}
            onAddMessage={onAddMessage}
            onAddThread={onAddThread}
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
            selectedAssistant={selectedAssistant}
        />
    )
}


const mapStateToProps = state => {
	return {
        selected_matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(AI);