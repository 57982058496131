import CreateEvent from 'components/system/Event'

const FinishTaskCreateEvent = ({task, onFinish}) => {

    return <div className='my-3'>
        <CreateEvent 
            useSaveButtonId="archk-tasks-finish-button" 
            matter={task.matter}
            task={task._id}
            eventTemplate={task.task_template ? task.task_template.event_template : ''}
            onSave={onFinish}
        />
    </div>
}

export default FinishTaskCreateEvent