
import { useCallback, useState } from 'react';
import { useEffect } from "react";
import { connect } from 'react-redux';

import Header from './Header';
import Main from './Main';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';
import Send from './Send';

import api from 'api'

import Circle from 'components/markup/loading/Circle'

import { v4 as uuidv4 } from 'uuid';

const Texts = ({socket, match}) => {

    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(null)
    const [thread, setThread] = useState(null)
    const [to, setTo] = useState({})

    const scrollToBottom = useCallback((tries = 0, behavior) => {
        if(tries > 20) return;

        const bottomDiv = document.getElementById("archk-text-message-end");
        const container = document.querySelector(".archk-messaging-main");

        if(bottomDiv && container) return container.scroll({ top: container.scrollHeight, behavior });

        setTimeout(() => {
            scrollToBottom(tries + 1)
        }, 250)


    }, [])

    const onSetActive = useCallback(async (_active) => {
        if(_active && _active._id && active && active._id && _active._id === active._id) return;
        if(active && active._id) api.text_threads.read(active._id, { read: true })

        setThread(null);

        if(_active._id) {
            const data = await api.text_threads.findById(_active._id);
            if(data.data) {
                setThread(data.data)
                setActive(_active)
    
                scrollToBottom()
            }
        } else {

            const data = await api.text_threads.findByDivision(_active.contact, _active.division);
            if(data.data) {
                if(!data.data.thread) {
                    data.data.thread = _active
                    data.data._id = uuidv4()
                }
                setThread(data.data)
                setActive(_active)

                scrollToBottom()
            }
        }

      

    }, [active, scrollToBottom])

    const onThreadUpdated = useCallback((params) => {
        if(thread && thread.thread.contact === params.thread.contact && thread.thread.division === params.thread.division) {
            const _thread = JSON.parse(JSON.stringify(thread));
            _thread.texts.push(params.message)
            setThread(_thread)

            setTimeout(() => {
                scrollToBottom(0, 'smooth'); 
            }, 300);
        }
    }, [thread, scrollToBottom])

    useEffect(() => {
        if(!match.params.thread_id) return;

        const fetchThread = async () => {
            setLoading(true)
            const data = await api.text_threads.findById(match.params.thread_id);
            setLoading(false)

            if(data.data) {
                setThread(data.data)
                setActive(data.data.thread)

                scrollToBottom()
            }
        }

        fetchThread()
    }, [match.params.thread_id, scrollToBottom])


    useEffect(() => {
        socket.on('TEXT_THREADS.UPDATED', onThreadUpdated)

        return () => {
            socket.off('TEXT_THREADS.UPDATED', onThreadUpdated)
        }

    }, [socket, onThreadUpdated])
    
    useEffect(() => {
        document.body.classList.add('noScroll')

        return () => {
            document.body.classList.remove('noScroll')
        }
    }, [])

    if(loading) return <Circle className="py-6" />

    return (

        <div className="archk-messaging">
            <div className="archk-messaging-wrapper">

                <Header 
                    thread={thread}
                    active={active}
                />

                <SidebarLeft
                    active={active}
                    setActive={onSetActive}
                />
    
                <Main 
                    thread={thread}
                    active={active}
                    to={to.value}
                />

                <Send 
                    to={to.value}
                    thread={thread}
                />

                <SidebarRight 
                    thread={thread}
                    active={active}
                    to={to}
                    setTo={setTo}
                />
        
            </div>
        </div>
    )

}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Texts);
