import keys from 'keys';

import { useEffect, useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getUrlParameter } from "utils/urls";
import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'


import useEnhancedReducer from "store/reducers/_utils/useEnhancedReducer";

import Circle from 'components/markup/loading/Circle'

import Axios from 'axios';
import moment from "moment";

import Main from './Main'
import Sidebar from './Sidebar'
import Header from './Header'

import ModalSaveTemplate from './Modals/SaveTemplate'
import ModalSendDocument from './Modals/SendDocument'
import ModalNotifications from './Modals/Notifications'

import api from 'api'


const MockParties = [
    { display_name: 'Party 1' },
    { display_name: 'Party 2' },
    { display_name: 'Party 3' },
    { display_name: 'Party 4' },
]

const SignatureMaster = ({match, device, selected_division}) => {

    const reducer = useCallback((state, action) => {
        return {...state, ...action};
    }, [])

    const [reducerState, dispatch, getState] = useEnhancedReducer(reducer, {
        markers: [],
        showingParty: 1
    });
    
    const [redirect, setRedirect] = useState(false)
    const [err, setErr] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [showModalSendDocument, setShowModalSendDocument] = useState(false)
    const [showModalSaveTemplate, setShowModalSaveTemplate] = useState(false)
    const [showModalNotifications, setModalShowNotifications] = useState(false)
    const [file, setFile] = useState(null)
    const [defaultName] = useState('Agreement ' + moment().format('MMM Do, YYYY'))
    const [pdfData, setPdfData] = useState(null)
    const [state, setState] = useState({
        requiredInternalAnswers: [],
        markers: [],
        docName: defaultName,
        // if we are a template mock 4 contacts so we have full range in setting markers
        contacts: getUrlParameter('template') === 'true' ? MockParties : [],
        isTemplate: getUrlParameter('template') === 'true' ? true : false,
        send_emails_to: []
    })

    const [configuration, setConfiguration] = useState({
        MAX_PARTIES: 0,
        MAX_MARKUP_LENGTH: 0,
        MAX_NAME_LENGTH: 0,
        MAX_ANSWER_LENGTH: 0,
    })

    const { docName, contacts, isTemplate, requiredInternalAnswers } = state;
    const { markers, showingParty } = reducerState;

    const toggleShowModalNotifications = useCallback(() => {
        setModalShowNotifications(!showModalNotifications)
    }, [showModalNotifications])

    const toggleShowModalSaveTemplate = useCallback(() => {
        setShowModalSaveTemplate(!showModalSaveTemplate)
    }, [showModalSaveTemplate])

    const toggleShowModalSendDocument = useCallback(() => {
        setShowModalSendDocument(!showModalSendDocument)
    }, [showModalSendDocument])

    const onSetState = useCallback((field, e) => {
        const _state = JSON.parse(JSON.stringify(state));
        const value = e && e.target && e.target.value ? e.target.value : e;
        _state[field] = value;
        setState(_state);
    }, [state])
    
    const onSetStateMultiple = useCallback((newState) => {
        const _state = JSON.parse(JSON.stringify(state));
        setState({..._state, ...newState});
    }, [state])

    const onSetShowingParty = useCallback((showingParty) => {
        dispatch({showingParty})
    }, [dispatch])

    const onSetMarkers = useCallback((markers) => {
        markers = markers.sort((a, b) => a.pageNumber < b.pageNumber ? -1 : 1);
        dispatch({markers})
    }, [dispatch])

    const onRemoveContact = useCallback((contact) => {
        let _contacts = JSON.parse(JSON.stringify(state.contacts));
        const index = _contacts.findIndex(c => c._id === contact._id);
        _contacts.splice(index, 1);
        onSetState('contacts', _contacts)

        const _reducerState = JSON.parse(JSON.stringify(getState()));
        let _markers = _reducerState.markers;

        _markers = _markers.filter(m => m.party !== (index + 1))
        _markers.forEach(m => {
            if(m.party > index + 1) m.party = m.party -1;
        })
        dispatch({markers: _markers})

    }, [state.contacts, onSetState, getState, dispatch])

    const onAddContact = useCallback((contact) => {
        const _contacts = JSON.parse(JSON.stringify(state.contacts));
        if(_contacts.find(c => c._id === contact._id)) return;
        _contacts.push(contact);
        onSetState('contacts', _contacts);
        onSetShowingParty(_contacts.length)
    }, [state.contacts, onSetState, onSetShowingParty])

    const onClearMarkers = useCallback(() => {
        dispatch({markers: []})
    }, [dispatch])

    const onFileAdded = useCallback(async (files) => {
        // if the file is a pdf continue as normal
        const _file = files[0]

        if(_file.type === 'application/pdf') {
            let _state = {}
            if(docName === defaultName) {
                let docName = _file.name;
                const last4 = _file.name.slice(_file.name.length - 4, _file.name.length)
                if(last4 === '.pdf') docName = _file.name.slice(0, _file.name.length - 4)
                _state.docName = docName;
            }
            setPdfData(_file)
            onSetStateMultiple(_state)
            setFile(_file);
        } else {
            toast.info(`Please upload a valid PDF document.`)
        }
    }, [docName, defaultName, onSetStateMultiple])

    const addMarker = useCallback((coords, pageNumber) => {
        const state = JSON.parse(JSON.stringify(getState()));
        const _markers = state.markers;
        const party = state.showingParty;
        _markers.push({...coords, pageNumber, party})
        onSetMarkers(_markers)
    }, [getState, onSetMarkers])

    const removeMarker = useCallback((i) => {
        const _markers = JSON.parse(JSON.stringify(getState().markers));
        _markers.splice(i, 1)
        onSetMarkers(_markers)
    }, [getState, onSetMarkers])

    const  setMarkerType = (i, type) => {
        const _markers = JSON.parse(JSON.stringify(getState().markers));
        _markers[i].type = type
        onSetMarkers(_markers)
    }

    const  updateMarkerName = useCallback((text, i) => {
        const _markers = JSON.parse(JSON.stringify(getState().markers));
        _markers[i].name = text
        onSetMarkers(_markers)
    }, [getState, onSetMarkers])

    const toggleErr = useCallback(() => {
        toast.error('Something went wrong, please try again.')
    }, [])

    const onSaveTemplate = useCallback(async () => {
        const _reducer = JSON.parse(JSON.stringify(getState()));
        const _markers = _reducer.markers;

        if(pdfData._id) {
            toggleStandardLoader(true)
            const updated = await api.signing_templates.update(pdfData._id, {
                name: state.docName,
                markers: _markers,
                send_emails_to: state.send_emails_to,
            })
            toggleStandardLoader(false)

            if(updated.data && updated.data._id) {
                setShowModalSaveTemplate(false);
                return toast.success('Template Updated Successfully.')
            }

            return toggleErr();

        } else {
            var bodyFormData = new FormData();
            bodyFormData.append('file', file); 
            bodyFormData.append('name', state.docName); 
            bodyFormData.append('division', selected_division._id); 
            bodyFormData.append('markers', JSON.stringify(_markers)); 
            bodyFormData.append('send_emails_to', JSON.stringify(state.send_emails_to)); 
    
            try {
                toggleStandardLoader(true)
                const data = await Axios({
                    method: "post",
                    url: `${keys.API_URL}/v1/signing/templates`,
                    data: bodyFormData,
                    withCredentials: true,
                    headers: { 
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                    },
                })
                toggleStandardLoader(false)

                if(data.data && data.data.data && data.data.data._id) {
                    return setRedirect('/system/signing_templates')
                }

                return toggleErr();
            } catch(e) {
                console.log(e)   
                toggleStandardLoader(false)
                return toggleErr();
            }
        }
      

    }, [state, getState, file, toggleErr, selected_division._id, pdfData])

    const onSendDocument = useCallback(async (usePassword, passwords, hints, requiredAnswers) => {

        const _reducer = JSON.parse(JSON.stringify(getState()));
        const _markers = _reducer.markers;

        const parties = []

        markers.forEach(marker => {
            if(requiredAnswers[marker._id]) {
                marker.name = requiredAnswers[marker._id];
            }
        })

        contacts.forEach((contact, i) => {
            const hasPassword   = usePassword[`password${i + 1}`];
            const password      = passwords[`password${i + 1}`];
            const hint          = hints[`hint${i + 1}`];

            parties.push({
                contact               : contact._id,
                party_index           : i + 1,
                password              : hasPassword ? password : '',
                hint                  : hasPassword ? hint : '',
            })
        })

        let task = getUrlParameter('task')
        let redirect = getUrlParameter('redirect')
        if(!task) task = undefined

        const link = redirect ? redirect :  `/matters/${match.params.matter_id}?tab=Documents&docsTab=Signing`

        if(pdfData._id) {
            toggleStandardLoader(true)
            const updated = await api.signing_documents.create({
                matter              : match.params.matter_id,
                signing_template    : pdfData._id,
                name                : state.docName,
                send_emails_to      : state.send_emails_to,
                division            : selected_division._id,
                parties             : parties,
                markers             : markers,
                task                : task
            })
            toggleStandardLoader(false)

            if(updated.data && updated.data._id) {
                toggleShowModalSendDocument(false);
                toast.success('Document Sent Successfully.')
                return setRedirect(link)
            }

            return toggleErr();

        } else {
            var bodyFormData = new FormData();
            bodyFormData.append('file', file); 
            bodyFormData.append('name', state.docName); 
            bodyFormData.append('division', selected_division._id); 
            bodyFormData.append('matter', match.params.matter_id); 
            bodyFormData.append('markers', JSON.stringify(_markers)); 
            bodyFormData.append('send_emails_to', JSON.stringify(state.send_emails_to)); 
            bodyFormData.append('parties', JSON.stringify(parties)); 
            if(task) bodyFormData.append('task', task); 
    
            try {
                toggleStandardLoader(true)
                const data = await Axios({
                    method: "post",
                    url: `${keys.API_URL}/v1/signing/documents`,
                    data: bodyFormData,
                    withCredentials: true,
                    headers: { 
                        "Content-Type": "multipart/form-data",
                        authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                    },
                })
                toggleStandardLoader(false)

                if(data.data && data.data.data && data.data.data._id) {
                    toast.success('Document Sent Successfully.')
                    return setRedirect(link)
                }
                

                return toggleErr();
            } catch(e) {
                console.log(e)   
                toggleStandardLoader(false)
                return toggleErr();
            }
        }
      
    }, [state, getState, file, toggleErr, contacts, toggleShowModalSendDocument, markers, selected_division._id, pdfData, match.params.matter_id])

    useEffect(() => {
        document.body.style.minHeight = '0px'
        return () => {
            document.body.style.minHeight = '100vh'
        }
    }, [])

    useEffect(() => {
        const template_id = match.params.template_id;
        const matter_id = match.params.matter_id;
      
        const fetchConfig = () => new Promise (async resolve => {
            const config = await api.signing_templates.getConfig()
            if(config.data) {
                setConfiguration(config.data)
            } else {
                setErr(true)
            }    
            return resolve()
        })

        let newState = {}
        
        const fetchDoc = () => new Promise (async resolve => {
           
            if(template_id !== 'new') {
                const doc = await api.signing_templates.findById(template_id);
                if(doc.data) {
                    newState = {
                        markers: doc.data.markers,
                        docName: doc.data.name,
                        send_emails_to: doc.data.send_emails_to,
                        requiredInternalAnswers: JSON.parse(JSON.stringify(doc.data.markers.filter(m => m.party === 0)))
                    }

                    setPdfData(doc.data)
                    dispatch({markers: doc.data.markers})
                } else {
                    return setErr(true)
                }
            } else {
                setLoaded(true)
            }

            const foundMatter = await api.matters.findById(matter_id, 'contacts');
            if(foundMatter.data) {
                newState = {...newState, contacts: foundMatter.data.contacts}
                onSetShowingParty(1)
            } else {
                setErr(true)
            }

            onSetStateMultiple(newState)

            return resolve()

        })



        const promiseArr = [ fetchConfig(), fetchDoc() ]

        const loadData = async () => {
            await Promise.all(promiseArr)
            setLoaded(true);
        }

        loadData();

        // eslint-disable-next-line
    }, [])

    if(!loaded) return <div className="py-6"><Circle /></div>
    if(redirect) return <Redirect to={redirect} />


    if(!device.info.isLG) {
        return (
            <p className="text-center my-4 ml-auto mr-auto " style={{maxWidth: '90%', width: 800}}>
                <i className="fas fa-info-circle text-info mr-2 " />This editor must be accessed on a laptop or desktop device over 1000px wide. If you are on a laptop or desktop and this message appears try maximizing your browser or zooming out.
            </p>
        )
    }

    return (

        <div className="archk-template-docs" style={{minWidth: 900}} >

            <Header 
                onSetState={onSetState}
                docName={docName}
                showingParty={showingParty}
                markers={markers}
                isTemplate={isTemplate}
                toggleShowModalSaveTemplate={toggleShowModalSaveTemplate}
                toggleShowModalSendDocument={toggleShowModalSendDocument}
                configuration={configuration}
            />
            
            <Sidebar 
                markers={markers}
                pdfData={pdfData}
                updateMarkerName={updateMarkerName}
                showingParty={showingParty}
                onSetState={onSetState}
                docName={docName}
                contacts={contacts}
                isTemplate={isTemplate}
                onSetShowingParty={onSetShowingParty}
                onAddContact={onAddContact}
                onRemoveContact={onRemoveContact}
                onClearMarkers={onClearMarkers}
                configuration={configuration}
                send_emails_to={state.send_emails_to}
                toggleShowModalSaveTemplate={toggleShowModalSaveTemplate}
                toggleShowModalSendDocument={toggleShowModalSendDocument}
                toggleShowModalNotifications={toggleShowModalNotifications}
            />

            <Main 
                markers={markers}
                addMarker={addMarker}
                removeMarker={removeMarker}
                setMarkerType={setMarkerType}
                updateMarkerName={updateMarkerName}
                onFileAdded={onFileAdded}
                pdfData={pdfData}
                showingParty={showingParty}
                isTemplate={isTemplate}
                contacts={contacts}
                onAddContact={onAddContact}
                configuration={configuration}
                err={err}
                setErr={setErr}
            />
        
            <ModalSaveTemplate 
                showModal={showModalSaveTemplate}
                toggleModal={toggleShowModalSaveTemplate}
                isTemplate={isTemplate}
                markers={markers}
                contacts={contacts}
                onSaveTemplate={onSaveTemplate}
                docName={docName}
            />
            
            <ModalSendDocument 
                showModal={showModalSendDocument}
                toggleModal={toggleShowModalSendDocument}
                isTemplate={isTemplate}
                markers={getState().markers}
                contacts={contacts}
                onSaveTemplate={onSaveTemplate}
                onSendDocument={onSendDocument}
                requiredInternalAnswers={requiredInternalAnswers}
                updateMarkerName={updateMarkerName}
            />
            <ModalNotifications 
                showModal={showModalNotifications}
                toggleModal={toggleShowModalNotifications}
                send_emails_to={state.send_emails_to}
                onSetState={onSetState}
                configuration={configuration}
            />




        </div>

    );
}


const mapStateToProps = state => {
	return {
	    device: state.device,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(SignatureMaster);
