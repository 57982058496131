import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu } from "reactstrap";

import CreateAlert from '../../Alerts/Create'

import api from 'api'

import { toast } from 'react-toastify';

import hasPrivilege from 'utils/hasPrivilege'

import HeaderTabs from 'components/markup/layout/HeaderTabs';
import ModalSendEmail from 'components/system/Email/Modal';
import { toggleStandardLoader } from 'store/functions/system/system';

const MattersViewMainComponentsMainNavigation = ({tab, setTab, setShowAI, setShowModuleBK, matter, viewing_user, selected_division}) => {

    const [aiBody, setAIBody] = useState('test')
    const [showEmail, setShowEmail] = useState(false)
    const [tabs, setTabs] = useState([])
    const [isBookmarked, setIsBookmarked] = useState(matter.bookmarked_by.includes(viewing_user._id))

    const [showAlertModal, setAlertModal] = useState(false)

    const docs = matter.missing_documents.length
    const tasks = matter.unfinished_tasks.length
    const forms = matter.unfinished_forms.length
    const events = matter.upcoming_events.length
    const items = matter.outstanding_items.length

    const generateAiOutboundTicket = useCallback(async () => {
        toggleStandardLoader(true)
        const data = await api._ai.outboundSupportTicket(matter.contacts[0]._id, matter._id)
        toggleStandardLoader(false)

        if(data.data) {
            setAIBody(data.data.email)
            setShowEmail(true)
        }
    }, [matter])

    const onSetBookmark = useCallback(async () => {
        let action;
        if(isBookmarked) {
            action = await api.matters.bookmarkRemove({matter: matter._id})
        } else {
            action = await api.matters.bookmarkAdd({matter: matter._id})
        }
        if(!action.success) return toast.error('Please try again.')
        setIsBookmarked(!isBookmarked)
    }, [isBookmarked, matter._id])

    useEffect(() => {
        let _tabs = [{ 
            value: 'Overview', 
            name: <span className="text-whit"><i className="fas fa-chart-pie" /> Matter</span> 
        }]
        if(hasPrivilege('NOTES.READ')) {
            _tabs.push({ 
                value: 'Feed', 
                name: <span className="text-whit"><i className="fas fa-comments" /> Feed</span> 
            })
        }
        if(hasPrivilege('DOCUMENTS.READ')) {
            _tabs.push({ 
                value: 'Documents', 
                name: <span className={docs ? 'text-warning' : ''}><i className="fas fa-file" /> Docs {docs ? `(${docs})` : ''}</span> 
            })
        }
        if(hasPrivilege('TASKS.READ')) {
            _tabs.push({ 
                value: 'Tasks', 
                name: <span className={tasks ? 'text-warning' : ''}><i className="fas fa-hammer" /> Tasks {tasks ? `(${tasks})` : ''}
                </span> 
            })
        }
        if(hasPrivilege('OUTSTANDING_ITEMS.READ')) {
            _tabs.push({ 
                value: 'Items', 
                name: <span className={items ? 'text-warning' : ''}><i className="fas fa-list" /> Items {items ? `(${items})` : ''}
                </span> 
            })
        }
        if(hasPrivilege('EVENTS.READ')) {

            _tabs.push({ 
                value: 'Events', 
                name: <span className={events ? 'text-warning' : ''}><i className="fas fa-calendar" /> Events {events ? `(${events})` : ''}</span> 
            })
        }
        if(hasPrivilege('FORMS.READ')) {
            _tabs.push({ 
                value: 'Forms', 
                name: <span className={forms ? 'text-warning' : ''}><i className="fa-regular fa-rectangle-list" /> Forms {forms ? `(${forms})` : ''}</span> 
            })
        }
        _tabs.push({ 
            name: <span className="text-whit">More</span>, 
            dropdown: true,
            menu: (
                <DropdownMenu style={{margin: 0}}>

                    {hasPrivilege('MATTERS.WRITE') ? (
                        <DropdownItem onClick={e => setAlertModal(true)}>
                            Add Alert
                        </DropdownItem>
                    ) : ''}
                    
                    {/* {hasPrivilege('SUPPORT_TICKETS.WRITE') ? (
                        <DropdownItem onClick={e => setAlertModal(true)}>
                            Add Support Ticket
                        </DropdownItem>
                    ) : ''} */}

                    {/* {hasPrivilege('MATTERS.WRITE') ? (
                        <DropdownItem onClick={e => generateAiOutboundTicket()}>
                            Send Step Email
                        </DropdownItem>
                    ) : ''} */}
                    {hasPrivilege('MATTERS.WRITE') ? (
                        <DropdownItem  onClick={e => setTab('Identifier')}>
                            Set Matter Identifiers
                        </DropdownItem>
                    ) : ''}
                    {hasPrivilege('MATTERS.WRITE') ? (
                        <DropdownItem  onClick={e => setTab('CustomDates')}>
                            Set A Matter Date
                        </DropdownItem>
                    ) : ''}

                    {hasPrivilege('BILLING.READ') ? (
                        <DropdownItem href={`/billing/-/${matter.id}`} target="_blank">
                            <span className='text-capitalize'>View Billing</span>
                        </DropdownItem>
                    ) : ''}

                    {hasPrivilege('AUTOMATIONS.READ') ? (
                        <DropdownItem  onClick={e => setTab('Automations')}>
                            View Automations
                        </DropdownItem>
                    ) : ''}

                  

                    {hasPrivilege('MATTERS.READ') ? (
                        <DropdownItem  onClick={e => setTab('CustomFields')}>
                            View Matter Fields
                        </DropdownItem>
                    ) : ''}
                    
                    {hasPrivilege('MATTERS.READ') ? (
                        selected_division.affiliate_value_type === 'invoice' ? null : (
                            <DropdownItem  onClick={e => setTab('MatterValue')}>
                                Matter Value
                            </DropdownItem>
                        )
                    ) : ''}
                  
                    {hasPrivilege('LEAD_SOURCES.READ') ? (
                        <DropdownItem  onClick={e => setTab('LeadSources')}>
                            Lead Sources
                        </DropdownItem>
                    ) : ''}

                    {hasPrivilege('VIEW.AI') ? (
                        <DropdownItem  onClick={e => setShowAI(true)}>
                            Artificial Intelligence
                        </DropdownItem>
                    ): ''}
                   
                    {/* {hasPrivilege('VIEW.AI') ? ( */}
                        <DropdownItem  onClick={e => setShowModuleBK(true)}>
                            BK Filing
                        </DropdownItem>
                    {/* ): ''} */}
                  
                    {hasPrivilege('MATTERS.READ') ? (
                        <DropdownItem  onClick={onSetBookmark}>
                            {isBookmarked ? (
                                <span><i className="fas fa-times  text-danger" /> Remove Bookmark</span>
                            ) : (
                                <span><i className="fas fa-plus  text-success" /> Bookmark Matter</span>
                            )}
                        </DropdownItem>
                    ): ''}

                    

                </DropdownMenu>
            )
        })

        setTabs(_tabs)
    }, [docs, events, forms, isBookmarked, matter.id, onSetBookmark, setTab, tasks, items, generateAiOutboundTicket, selected_division.affiliate_value_type, setShowAI, setShowModuleBK])

 
    return (

        <div id="archk-matter-page-navigation" className=' borde'>
            <div className='bg-white py-1 border-bottom'>
                <div className="section  text-white pt-3 borer-top mt-0 pb-0  ">

                    <HeaderTabs 
                        className="mb-0"
                        tab={tab}
                        setTab={(tab) => setTab(tab)}
                        tabs={tabs}
                    />

                    <CreateAlert 
                        showModal={showAlertModal}
                        toggleModal={() => setAlertModal(false)}
                    />

                    <ModalSendEmail 
                        defaultBody={aiBody}
                        showModal={showEmail}
                        toggleModal={() => setShowEmail(false)}
                        matter={matter._id}
                        division={matter.division}
                        onSent={() => setShowEmail(false)}
                    />

    

                </div>
            </div>
        </div>

    )

}
// missing_documents, unfinished_tasks, upcoming_events
const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
        viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsMainNavigation);
