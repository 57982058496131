// test cards
// https://developers.bluefin.com/payconex/docs/chase-paymentech

import { useEffect, useState, useCallback } from 'react'

import { Modal, Nav, NavLink, NavItem, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import keys from 'keys'
import api from 'api'

import Circle from 'components/markup/loading/Circle';

const ModalUpdateCard = ({showModal, toggleModal, matter, fetchData}) => {

    const [type, setType]       = useState('cc');

    const [zipCode, setZipCode] = useState('');
    const [nameOnCard, setNameOnCard] = useState('');
    const [iframeLoading, setIframeLoading] = useState(false);
    const [paymentIframe, setPaymentIframe] = useState(false);
    const [errs, setErrs] = useState([]);

    const onSetZipCode = useCallback((e) => {
        const val = e.target.value
        if(val && val.length > 5) return;

        setZipCode(val)
    }, [])
    
    const onSetNameOnCard = useCallback((e) => {
        const val = e.target.value
        if(val && val.length > 200) return;

        setNameOnCard(val)
    }, [])

    const onSave = useCallback(() => {

        if(!nameOnCard) {
            return setErrs([`Please enter the card holders name.`])
        }

        if(type === 'cc') {
            if(!zipCode || ( zipCode && zipCode.length !== 5 )) {
                return setErrs([`Please enter a valid 5 digit zip code.`])
            }
        }
       
        
        toggleStandardLoader(true);

        paymentIframe.encrypt().success( async function (res) {

            const expires = res.masked.expy ? res.masked.expy.slice(0, 2) + '/' + res.masked.expy.slice(2, 4) : ''

            const created = await api.payment_methods.create({
                payment_processor: 'payment tree',
                owner_name    : nameOnCard,
                zip           : zipCode,
                type          : res.masked.expy ? 'card' : 'ach',
                token         : res.eToken,
                last_4        : res.masked.number.slice(-4),
                expires,
                matter: matter._id,
            })

            toggleStandardLoader(false);

            if(!created.success) return setErrs(created.message)

            toggleModal()
            fetchData()

        }).failure( function (err) {

            toggleStandardLoader(false);
            setErrs([err.message])

        }).invalidInput( function (data) {
            toggleStandardLoader(false);

            let _errs = [];
            for ( var i = 0; i < data.invalidInputs.length; i++ ){
                const err = data.invalidInputs[i];
                _errs.push(err.field);
            }

            setErrs(_errs)
        });

    }, [paymentIframe, zipCode, nameOnCard, fetchData, toggleModal, type, matter])

    const loadFrame = useCallback((tries = 0) => {
        const div = document.getElementById('iframe_container');

        if(!div) {
            if(tries > 50) return toast.error('Please refresh your page to add a payment method')
            return setTimeout(() => loadFrame(tries + 1), 250)
        }

        const iframe = new window.PaymentiFrame({
            create: true,
            iframeId: "payment_iframe",
            settings: {
                account           : keys.BLUE_FIN_ACCOUNT_ID,
                parentId          : "iframe_container",
                lang              : "en",
                cvv               : "required",
                width             : "100%",
                height            : "110px",
                showFrame         : false,
                devServer         : keys.BLUE_FIN_API_URL,
                onload            : () => setIframeLoading(false),
                // numberLabel       : 1,
                // expyLabel         : 1,
                // cvvLabel          : 9,
                layout            : 'layout_2',
                inputWidth        : '20em',
                inputStyle        : 1,
                labelFontSize     : 'font_size_14',
                labelFontFamily   : 'font_family_14',
                labelFontColor    : '#abcd12',
                inputFontSize     : 'font_size_14',
                inputFontFamily   : 'font_family_14',
                inputFontColor    : '#cc6633',
                number_label      : 'Card Number ss',
                expy              : 'double_input',
                payment_method    : type,
                css: {

                    "class_label"  : "font-weight: 300; display: block; margin-bottom: .24rem; color: #525f7f; font-size: 13px; font-weight: 500; font-family: Helvetica",
                    
                    "class_label:focus"  : "color: red",

                    "id_account_input": "display: block; width: 100%; box-sizing: border-box; padding: 0.25rem 0.25rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 14px; background: #fff; padding-left: 16px; padding-right: 16px; transition: all .3s; margin-bottom: 10px",
                 
                    "class_input_box": "display: block; width: 100%; box-sizing: border-box; padding: 0.25rem 0.25rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 14px; background: #fff; padding-left: 16px; padding-right: 16px; transition: all .3s; border-radius: 0px;",
                    
                    "class_input_box:focus" : "color: #212529; background: #f6f9fc; border: solid 1px #212529;  border-radius: 0; outline: none; border-radius: 0px;",

                    "id_expy_input_month"  : "display: block; width: 48%; float: left; box-sizing: border-box; padding: 0.25rem 0.25rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 14px; background: #fff; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                    
                    "id_expy_input_month:focus" : "color: #212529; background: #f6f9fc; border: solid 1px #212529;  border-radius: 0; outline: none; border-radius: 0px;",

                    "id_expy_input_month::placeholder"  : "color: #adb5bd",

                    "id_expy_input_year"   : "display: block; width: 48%; float: right; box-sizing: border-box; padding: 0.25rem 0.25rem; line-height: 1.5; color: #8898aa; border: 1px solid #dee2e6; box-shadow: 0 3px 2px rgb(233 236 239 / 5%); font-size: 14px; background: #fff; padding-left: 16px; padding-right: 16px; transition: all .3s;",
                    
                    "id_expy_input_year:focus" : "color: #212529; background: #f6f9fc; border: solid 1px #212529;  border-radius: 0; outline: none; border-radius: 0px;",
                    
                    "id_expy_input_year::placeholder"  : "color: #adb5bd",

                    "id_number_input::placeholder"  : "color: #adb5bd",
                    
                    "id_cvv_input::placeholder"  : "color: #adb5bd",

                    "id_number_row": "margin-bottom: .6rem",

                    "id_expy_row"  : "width: 65%;float: left;",

                    "id_cvv_row"  : "width: 32%; float: right",

                },

                text     : {
                    bank_account : {
                        label       : "Account Number*",
                        placeholder : "111 222 3333"
                    },
                    routing_number : {
                        label       : "Routing Number*",
                        placeholder : "999 888 777"
                    },
                    number : {
                        label       : "Card Number*",
                        placeholder : "1111 2222 3333 4444"
                    },
                    cvv : {
                        label       : "CVV*",
                        placeholder : "123"
                    },
                    expy_double : {
                        label         : "Expires*",
                        placeholder_1 : "MM",
                        placeholder_2 : "YY"
                    }
                },

            }
        });

        setPaymentIframe(iframe);
        setIframeLoading(false)

    }, [type])

    useEffect(() => {

        if(!showModal) {
            setErrs([])
            setIframeLoading(true)
            setType('cc')
            return setPaymentIframe(null)
        }

        loadFrame()

        return () => {
            const iframe = document.getElementById('iframe_container')
            if(iframe) iframe.innerHTML = ''
        }
        
    }, [showModal, toggleModal, loadFrame])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Payment Method</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body py-3">

                <Nav
                    className="nav-fill flex-column flex-sm-row"
                    id="tabs-text"
                    pills
                    role="tablist"
                >

                    <NavItem>
                        <NavLink
                            aria-selected={type === 'cc'}
                            className={`mb-sm-3 mb-md-0 ${type === 'cc' ? 'active' : ''}`}
                            onClick={e => setType('cc')}
                            role="tab"
                        >
                            Credit / Debit
                        </NavLink>
                    </NavItem>
                    
                    <NavItem>
                        <NavLink
                            aria-selected={type === 'ach'}
                            className={`mb-sm-3 mb-md-0 ${type === 'ach' ? 'active' : ''}`}
                            onClick={e => setType('ach')}
                            role="tab"
                        >
                            Bank Account
                        </NavLink>
                    </NavItem>

                </Nav>
            </div>


            <div className="modal-body border-top">
                <FormGroup>
                    <label className='form-control-label'>
                        {type === 'cc' ? 'Name On Card' : "Account Holder's Name"}*
                    </label>
                    <Input
                        type="text"
                        value={nameOnCard}
                        onChange={onSetNameOnCard}
                    />
                </FormGroup>
            </div>

          
            <div className="modal-body border-top">
                <div className="pt-3 mb-1">
                    {iframeLoading ? <div className="py-6"><Circle /></div> : null}
                    <div id="iframe_container" />
                </div>
            </div>

            {type === 'cc' ? (
                <div className="modal-body border-top">
                    <FormGroup>
                        <label className='form-control-label'>Zip Code*</label>
                        <Input
                            type="number"
                            value={zipCode}
                            onChange={onSetZipCode}
                        />
                    </FormGroup>
                </div>
            ) : ''}

            <div className="modal-body bg-secondary border-top">
                <p className="text-sm  mb-0">You may make this method the default payment method after adding it. If there are no payment methods existing on this matter it will automatically default to the default method.</p>
            </div>

            {errs.length ? (
                <div className="modal-body border-top">
                    {errs.map((err, i) => (
                    <p key={i} className="text-sm mb-0 text-danger">
                        {
                            err === 'number' ? `* Please check your card number.` : 
                            err === 'expy'   ? `* Please check your card's expiration date.` :
                            err === 'cvv'    ? `* Please check your card's CVV.` : 
                            err === 'routing number'    ? `* Please check your routing number.` : 
                            err === 'bank account'    ? `* Please check your bank account number.` : 
                            err
                        }
                    </p>

                    ))}
                </div>
            ) : null}
            
            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left " /> Back
                </button>

                <button onClick={onSave} className={`btn btn-${type === 'cc' ? 'success' : 'info'}`}>
                    {type === 'cc' ? (
                        <span><i className="fa-solid fa-money-check mr-2" /> Add Card</span>
                    ) : (
                        <span><i className="fa-solid fa-building-columns mr-2" /> Add Bank</span>
                    )}
                </button>
            </div>

        </Modal>

    )

}

export default ModalUpdateCard