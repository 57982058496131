// findWithEvents

import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal, Badge } from "reactstrap";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import A from 'components/markup/links/A'

import Circle from 'components/markup/loading/Circle'

import moment from 'moment';

import ConfirmationModal from 'components/functional/modals/Confirmation'
import ModalToggler from 'components/functional/modals/Toggler';

import { toggleStandardLoader } from 'store/functions/system/system'
import { toast } from 'react-toastify';

const ModalCustomFields = ({showModal, toggleModal, DOMAINS, _id, fetchDocuments}) => {


    const [data, setData] = useState({})

    const { signing_document, signing_events } = data

    const onVoid = useCallback(async () => {
        toggleStandardLoader(true)
        const voided = await api.signing_documents.update(_id, { status: 'void' })
        toggleStandardLoader(false)

        if(voided.success) {
            fetchDocuments()
            return toggleModal()
        }

        toast.error('Please try again.')

    }, [_id, toggleModal, fetchDocuments])

    const fetchData = useCallback(async () => {
        const data = await api.signing_documents.findWithEvents(_id)
        setData(data.data)
    }, [_id])

    useEffect(() => {
        if(showModal) {
            fetchData()
        } else {
            setData({})
        }
    }, [showModal, fetchData])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit Templates</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {!signing_document ? <Circle /> : (
                    <div>
                        <h3 className='mb-0'>{signing_document.name} {signing_document.status === 'signed' ? <Badge color="success">Signed</Badge>: signing_document.status === 'void' ? <Badge color="warning">Void</Badge> : <Badge color="warning">Pending</Badge>}</h3>

                        <p className="text-sm mt-3 mb-3">Finalized_on: {signing_document.finalized_on ? moment.unix(signing_document.finalized_on).format('MM/DD/YYYY: h:mm A') :  '-'}</p>

                        {signing_document.parties.map((p, index) => {

                            const partyEvents = signing_events.filter(e => e.contact === p.contact);
                            const link = `${DOMAINS.SIGN}/${p.id}`

                            return (
                                <div key={p._id} className='bg-secondary px-4 py-3 border rouned mb-3'>
                                    <h3 className='mb-0'>Party #{index + 1}:</h3>
                                    <p className='text-sm'> 
                                            <A href={`/clients/${p.contact}`}><ObjectFinder collection="contacts" _id={p.contact} /></A>
                                    </p>

                                    <h3 className='mb-0'>Signing Link:</h3>

                                    <p className='text-sm'><A href={link}><i className="fas fa-link text-info mr-2" /> {link} </A></p>

                                    <h3 className='mb-0'>Events</h3>
                                    {!partyEvents.length ? (
                                        <p className='text-sm'>No Events Founds</p>
                                    ) : (
                                        <ul className='pl-4'>
                                            {partyEvents.map((e) => (
                                                <li key={e._id} className="ml-2 mb-0">
                                                    <p className='text-sm text-muted mb--2'>{moment.unix(e.created_at).format('MM/DD/YYYY h:mm A')} - IP: {e.ip}</p>
                                                    <p className='text-sm font-weight-bold text-dark mb-0'>{e.event}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    )}

                                    <h4 className='mb-0'>More Info</h4>
                                    <p className='text-sm mb-0'>Failed Password Attempts: {p.failed_attempts}</p>
                                    <p className='text-sm mb-0'>Password Hint: {p.hint ? p.hint : <span className='text-warning'>NA</span>}</p>
                                    <p className='text-sm mb-0'>Finalized On: 
                                        {p.finalized_on ? moment.unix(p.finalized_on).format('MM/DD/YYYY: h:mm A') :  <span className='text-warning'>NA</span>}
                                    </p>
                                    <p className='text-sm mb-0'>
                                        Confirmation Emails:{' '}
                                        {p.send_emails_to && p.send_emails_to.length ? p.send_emails_to.map((e, i) => (
                                            <span key={i}>{i !== 0 ? ', ' : ''} {e}</span>
                                        )): 'Only Parties On The Document'}
                                    </p>
                                </div>
                            )

                        })}
                    </div>
                )}
            </div>

            <div className="modal-footer">
                {signing_document && (signing_document.status === 'in progress' || signing_document.status === 'sent') ? (
                    <ModalToggler 
                        component={ConfirmationModal} 
                        onConfirmation={onVoid} 
                        title="Void Document" 
                        body={"Are you sure you wish to void this document? It will no longer be available for signature."}
                    >
                        <button className="btn btn-outline-danger">
                            Void Document
                        </button>
                    </ModalToggler>
                ) : ''}
                <button className="btn btn-outline-info" onClick={toggleModal}>
                    Close
                </button>
            </div>
        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        DOMAINS: state.load.DOMAINS,
    };
};

export default connect(mapStateToProps, '')(ModalCustomFields);