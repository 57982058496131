import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Container } from 'reactstrap';
import moment from "moment";

import api from 'api'

import EventsEdit from 'components/system/Event'
// import ObjectFinder from 'components/system/Objects/Finder';
import SearchCollections from "components/system/Search/Collections";

const localizer = momentLocalizer(moment);

const FullCalendar = ({COLORS, viewing_user}) => {

    const [user, setUser] = useState(viewing_user._id)
    const [selectedEvent, setSelectedEvent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [start, setStart] = useState(parseInt(moment().startOf('day').format('X')))
    const [end, setEnd] = useState(parseInt(moment().endOf('day').format('X')))

    const [events, setEvents] = useState([])
    const [view, setView] = useState()
    const [date, setDate] = useState(new Date())

    const fetchEvents = useCallback(async () => {
        // view is not used in the api call however int needs to be here to accurately change between 
        // month, day, etc views
        const _events = await api.calendar_events.calendar(start, end, user, view)

        if(_events.data) {
            setEvents(_events.data.map(e => {
                return {
                    _id: e._id,
                    event_id: e.metadata && e.metadata.event_id ? e.metadata.event_id : null,
                    title: e.title,
                    color: e.color,
                    users: e.users,
                    metadata: e.metadata,
                    finished_at: e.finished_at,
                    start: moment.unix(e.start_time).toDate(),
                    end: moment.unix(e.end_time).toDate(),
                }
            }))
            setLoading(false)
        }
    }, [start, end, user, view])

    useEffect(() => {
        const _start = parseInt(moment().startOf('day').format('X'))
        const _end = parseInt(moment().endOf('day').format('X'))
        fetchEvents(_start, _end)
    }, [fetchEvents])

    return (
        <Container className={loading ? 'archk-calendar-loading' : ''} fluid>
            <SearchCollections
                collection={'users'}
                title={<span>Viewing Calendar For:</span>}
                placeholder="Search Calendars..."
                value={user}
                onChange={(obj) => setUser(obj.value)}
                filter={{
                    divisions: { $in: viewing_user.divisions },
                }}
            /> 
            <Calendar
                views={['month', 'day', 'agenda']} 
                view={view}
                onView={(_view) => {

                    setView(_view)
                }}
                onNavigate={(_date) => setDate(_date)}
                localizer={localizer}
                date={date}
                defaultView="day"
                events={events}
                style={{ height: "calc(100vh - 158px)" }}
                scrollToTime={moment().subtract(2, 'hours').toDate()}

                step={15}
                selectable
                onRangeChange={(time, type) => {

                    setEvents([])
                    setLoading(true)

                    let startUnix;
                    let endUnix;

                    const { start, end } = time

                    if(start && end) {
                        startUnix = parseInt(moment(start).startOf('day').format('X'))
                        endUnix = parseInt(moment(end).format('X'))
                    } else if(time.length > 1) {

                        startUnix = parseInt(moment(time[0]).format('X'))
                        endUnix = parseInt(moment(time[time.length - 1]).endOf('day').format('X'))
                    } else if(time.length === 1) {
                        startUnix = parseInt(moment(time[0]).format('X'))
                        endUnix = startUnix + 86399
                    } else {
                        console.log('time not found')
                    }

                    // fetchEventsThrottled(startUnix, endUnix)
                    setStart(startUnix)
                    setEnd(endUnix)
                }}

                eventPropGetter={(event, a, b) => {
                    if(view !== 'agenda') {
                        if(event.title === 'Busy') return { style: { background: '#333', color: 'white', border: 'solid 1px #888', opacity: .7 } }
                       
                        if(event.metadata) {

                            if(event.metadata.finished_at) {
                                return { style: { background: '#eee', color: COLORS.black, opacity: 1 } }
                            }

                            if(event.metadata.color) {
                                return { style: { background: event.metadata.color, color: 'white', borderColor: event.metadata.color }, className: 'rbc-agenda-colored' }
                            }
                        }

                    }
                  
                }}
                titleAccessor={(event) => {
                    if(event.metadata && event.metadata.finished_at) return<span><i className="fas fa-check text-success mr-1" /> {event.title}</span>
                    // if(view === 'agenda' && event.users.length) return (
                    //     <span>
                    //         {event.title} <i className="fas fa-users mx-3" />
                    //         {event.users.map((u, i) => (
                    //             <span>{i !== 0 ? ', ' : ''} <ObjectFinder collection="users" _id={u} /></span>
                    //         ))} 
                    //     </span>
                    // )
                    return event.title
                }}

                onSelectEvent={(event) => {
                    setSelectedEvent(event.event_id ? event.event_id : 'test')
                }}
                onSelectSlot={(slotInfo) => {
                    if(view === 'month' || view === 'week') {
                        setView('day')
                        setDate(slotInfo.start)
                    }
                }}
                components={{
                    timeGutterHeader : function(){
                        return 'All Day Events'
                    }
                }}
            />

            {selectedEvent ? (
                <EventsEdit 
                    showModal={true}
                    toggleModal={() => setSelectedEvent(null)}
                    onSave={(event) => {
                        setSelectedEvent(null)

                        const foundIndex = events.findIndex(e => e.event_id === event._id)
                        if(foundIndex !== -1) {
                            const _events = JSON.parse(JSON.stringify(events));

                            _events[foundIndex] = {
                                _id: event._id,
                                event_id: event._id,
                                title: event.name,
                                start: event.start,
                                end: event.end,
                            }

                            const newState = _events.map(e => {
                                return {
                                    _id: event._id,
                                    event_id: event._id,
                                    title: event.name,
                                    start: moment(e.start).toDate(),
                                    end: moment(e.end).toDate(),
                                }
                            })

                            setEvents(newState)
                        }

                    }}
                    _id={selectedEvent ? selectedEvent : null}
    
                />
            ) : ''}
          
        </Container>
      )

}


const mapStateToProps = state => {
	return {
	    COLORS: state.config.COLORS,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(FullCalendar);