import { useCallback,useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row, UncontrolledDropdown } from "reactstrap";

import formatObject from 'utils/formatObject'

import Search from './Search';

import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { enableDialing } from 'store/functions/call_center';

// import AvatarImage from 'components/functional/images/AvatarImage';

import api from 'api';
import moment from 'moment';

import hasPrivilege from 'utils/hasPrivilege'

const NavbarDesktopHeaderTop = ({selected_division, divisions, socket, onSetDivision, viewing_user, DOMAINS, toggleSidenav}) => {

    const canReadMatters = hasPrivilege('MATTERS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.MATTERS')
    const canWriteMatters = hasPrivilege('MATTERS.WRITE') && hasPrivilege('VIEW.APP_DASHBOARD.MATTERS')
    const canWriteContacts = hasPrivilege('CONTACTS.WRITE') && hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS')

    // save value here because the viewing user is cached for 5 seconds on the server
    const [available, setAvailable] = useState(viewing_user.status_available)
    const [agentCanReceiveCalls, setAgentCanReceiveCalls] = useState(viewing_user.agent_can_receive_calls)
    const [agentIsInQueue, setAgentIsInQueue] = useState(viewing_user.agent_is_in_queue)
    const [canChangeState, setCanChangeStatus] = useState(true)

    const setReceivingQueueCalls = useCallback(async () => {
        setCanChangeStatus(false)
        enableDialing()
        const updated = await api.users.update(viewing_user._id, { agent_is_in_queue: !agentIsInQueue })
        setCanChangeStatus(true)
        if(updated.data) {
            setAgentIsInQueue(!agentIsInQueue)
        } else {
            toast.error(`Something went wrong changing your status, please try again`)
        }
    }, [agentIsInQueue, viewing_user._id])
   
    // this is fired directly to the user and not to a company
    const onCanReceiveCallsUpdated = useCallback((_user) => {
        if(_user._id === viewing_user._id) {
            setAgentCanReceiveCalls(_user.agent_can_receive_calls)
        }
    }, [viewing_user._id])
    
    const onUpdate = useCallback((data) => {
        if(data._id === viewing_user._id) {
            setAvailable(data.status_available)
            setAgentIsInQueue(data.agent_is_in_queue)
        }
    }, [viewing_user._id])

    useEffect(() => {
        socket.on('USERS.UPDATED', onUpdate)
        socket.on('AGENT_CAN_RECEIVE_CALLS', onCanReceiveCallsUpdated)

        return () => {
            socket.off('USERS.UPDATED', onUpdate)
            socket.off('AGENT_CAN_RECEIVE_CALLS', onCanReceiveCallsUpdated)
        }
    }, [onUpdate, onCanReceiveCallsUpdated, socket])

    return (

        <Container  fluid>

            <Row className="align-items-center py-2">

                <Col md={5} xs="3" className="align-self-center">
                    <div className='d-none d-lg-block'>
                        {
                            (hasPrivilege('VIEW.APP_DASHBOARD.MATTERS') && hasPrivilege('MATTERS.READ')) ||
                            (hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') && hasPrivilege('CONTACTS.READ'))
                            ? <Search /> : ''
                        }
                    </div>
                  
                    <ul className='d-block d-lg-none'>
                        <li>
                            <i onClick={toggleSidenav} style={{fontSize: 20}} class="fa-solid fa-bars display- cursor-pointer"></i>
                        </li>
                    </ul>
                     
                </Col>

                <Col md={7} xs="9"   className="text-right header-right align-self-center">

                    <Nav className="align-items-center ml-auto ml-md-0 pl-5 "  navbar>

                        <ul>
                            <li>

                                <UncontrolledDropdown >
                                    <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                        {selected_division.name ? selected_division.name : "All Divisions"} <i className="fas fa-caret-down ml-2 px-0 " />
                                    </DropdownToggle>

                                    <DropdownMenu end>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h6 className="text-overflow m-0">Selected Division</h6>
                                            </DropdownItem>

                                            <DropdownItem onClick={() => onSetDivision({_id: 'all'})} >
                                                All Divisions
                                            </DropdownItem>

                                            {divisions.map(division => (
                                                <DropdownItem key={division._id} onClick={() => onSetDivision(division)} >
                                                    {division.name}
                                                </DropdownItem>
                                            ))}
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </li>

                            <li className='position-relative d-none d-md-inline-block'>
                                <i 
                                    id="archk-tooltip-queue-ready"
                                    data-tooltip-content={agentIsInQueue ? `I Am Receiving Queue Calls` : 'I am NOT Receiving Queue Calls'}
                                    data-tooltip-delay-show={1000}
                                    onClick={canChangeState ? setReceivingQueueCalls : null} 
                                    data-tooltip-variant={agentIsInQueue ? 'success' : 'error'}
                                    className={`fas fa-mobile ${agentIsInQueue ? 'text-success' : 'text-danger'} px-2 cursor-pointer -3 navbar-block`} 
                                />
                                <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-queue-ready" />

                                {available && !agentCanReceiveCalls ? (
                                    <span 
                                        id="archk-tooltip-cannot-receive-calls"
                                        data-tooltip-content={`No tabs open with audio enabled`}
                                        data-tooltip-delay-show={1000}
                                        data-tooltip-variant={'warning'}
                                        style={{position: 'absolute', top: -5, right: -7, zIndex: 3, borderRadius: '100%'}}
                                        className="border-danger bg-secondary cursor-pointer"
                                        onClick={enableDialing}
                                    >
                                        <i 
                                            className="fa-solid fa-volume-xmark text-danger" 
                                            style={{paddingLeft: 4, paddingRight: 4, fontSize: 12}}
                                        />
                                        <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-cannot-receive-calls" />
                                    </span>
                                ) : ''}
                            </li>
                            
                            {/* <li className='position-relative'>
                                <i 
                                    id="archk-tooltip-available"
                                    data-tooltip-content={available ? `I Am Clocked In` : 'I am NOT Clocked In'}
                                    data-tooltip-delay-show={1000}
                                    onClick={canChangeState ? setStatusAvailable : null} 
                                    data-tooltip-variant={available ? 'success' : 'error'}
                                    className={`fa-solid fa-circle ${available ? 'text-success' : 'text-danger'} px-2 cursor-pointer -3 navbar-block`} 
                                />
                                <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-available" />

                            </li> */}

                            {canReadMatters ? (
                                <li className='d-none d-md-inline-block'>
                                    <Link to="/matters/search/bookmarked">
                                        <i 
                                            id="archk-tooltip-nav-bookmark"
                                            data-tooltip-content={ `Bookmarks`}
                                            data-tooltip-delay-show={1500}
                                            data-tooltip-variant={'success'}
                                            className="fas fa-bookmark text-muted navbar-block cursor-pointer" 
                                        />
                                        <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-nav-bookmark" />
                                    </Link>
                                </li>
                            ) : ''}

                            {canWriteMatters || canWriteContacts  ? (
                                <li className='d-none d-md-inline-block'>
                                    <UncontrolledDropdown >
                                        
                                        <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                            <i 
                                                id="archk-tooltip-nav-settings"
                                                data-tooltip-content={ `Add New`}
                                                data-tooltip-delay-show={1500}
                                                data-tooltip-variant={'success'}
                                                className="fas fa-plus text-muted navbar-block cursor-pointer" 
                                            />
                                            <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-nav-settings" />
                                        </DropdownToggle>

                                        <DropdownMenu end>
                                            <div className="dropdown-content">

                                                <DropdownItem  className="noti-title" header tag="div">
                                                    <h5 className="text-overflow m-0">Add Something</h5>
                                                </DropdownItem>

                                                {canWriteMatters ? (
                                                    <DropdownItem tag={Link} to="/matters/create" >
                                                        Matter
                                                    </DropdownItem>
                                                ) : ''}

                                                {canWriteContacts ? (
                                                    <DropdownItem tag={Link} to="/contacts/create" >
                                                        Client
                                                    </DropdownItem>
                                                ) : ''}
                                                
                                                {/* {hasPrivilege('TASKS.WRITE') ? (
                                                    <DropdownItem tag={Link} to="/settings" >
                                                        Task
                                                    </DropdownItem>
                                                ) : ''}

                                                {hasPrivilege('EVENTS.WRITE') ? (
                                                    <DropdownItem tag={Link} to="/settings" >
                                                        Event
                                                    </DropdownItem>
                                                ) : ''} */}
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                            ) : ''}                             

                            <li className='ml-4'>
                                <UncontrolledDropdown>
                                    <DropdownToggle className="nav-link pr-0 ml-3 cursor-pointer font-weight-bold text-capitalize" color="" tag="a">

                                    {formatObject(viewing_user).name() } <i className="fas fa-caret-down ml-2  px-0 " />
                                        
                                    </DropdownToggle>

                                    <DropdownMenu end>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h5 className="text-overflow m-0">My Account</h5>
                                            </DropdownItem>

                                            <DropdownItem tag={Link} to="/settings" >
                                                Settings
                                            </DropdownItem>
                                            
                                            {/* <DropdownItem tag={Link} to="/time_off" >
                                                Time Off
                                            </DropdownItem> */}
                                            
                                            {/* <DropdownItem tag={Link} to="/settings" >
                                                Recent Activity
                                            </DropdownItem> */}
                                            
                                            <DropdownItem href={`${DOMAINS.AUTH}/logout`} >
                                                <span>Logout</span>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </li>
                        </ul>

                    </Nav>

                </Col>

            </Row>

        </Container>

    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
        divisions: state.divisions,
        socket: state.socket,

	};
};

export default connect(mapStateToProps, '')(NavbarDesktopHeaderTop);
