
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, FormGroup, Input, CardFooter } from "reactstrap";

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalCustomFields = ({invoice, onSetInvoice}) => {

    let canSend = true
    if(invoice._id && invoice.status === 'sent') canSend = false

    return (

        <div>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Invoice Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <label className='form-control-label'>Invoice Name*</label>
                        <Input 
                            type="text"
                            value={invoice.name}
                            onChange={(e) => onSetInvoice('name', e.target.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <label className='form-control-label'>Description</label>
                        <p className='text-sm mb-0'>Line breaks will not show on client invoice</p>
                        <Input 
                            type="textarea"
                            value={invoice.description}
                            onChange={(e) => onSetInvoice('description', e.target.value)}
                        />
                    </FormGroup>

                    {canSend  ? (
                        <ReactSelect
                            title={"Status*"}
                            formGroup={true}
                            placeholder=""
                            onChange={(obj) => onSetInvoice('sent', obj.value)}
                            options={[
                                { label: 'Draft', value: false },
                                { label: 'Sent', value: true },
                            ]}
                            value={invoice.sent}
                        />  
                    ) : '' }
                </CardBody>

                {!canSend ? (
                    <CardFooter>
                        <p className='text-sm mb-0'>**This invoice has already been sent out and can no longer go back to draft status.</p>
                    </CardFooter>
                ) : ''}
            </Card>
        </div>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalCustomFields);