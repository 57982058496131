import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Card, CardHeader, CardBody, CardTitle, Form, CardFooter, Row, Col } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ConfirmationModal from 'components/functional/modals/Confirmation';

import DatePicker from 'components/markup/inputs/Day';

import moment from 'moment'

import PlanDetails from './PlanDetails'
import HearingReview from './HearingReview'
import ReviewNotes from './ReviewNotes'

const ModuleBKOverview = ({ bkCase, onSaveBkCase, onSyncToPacer, matter }) => {

    const [data, setData] = useState(bkCase)

    const onInputChange = useCallback((field, val) => {
        const d = JSON.parse(JSON.stringify(data))
        d[field] = val;
        setData(d)
    }, [data])

    return (
        <Form onSubmit={e => e.preventDefault()}>


            <h2 className='text-warning'>Chapter 13 Details</h2>

            <PlanDetails 
                data={data}
                onInputChange={onInputChange}
            />
            <HearingReview 
                data={data}
                onInputChange={onInputChange}
            />
            <ReviewNotes 
                data={data}
                onInputChange={onInputChange}
            />


        </Form>


    )
}

const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


