import { useEffect } from 'react';
import { getLogoutURL } from 'utils/helpers'

const Logout = () => {

    useEffect(() => {
        window.location.href = getLogoutURL(true);
    }, [])

    return <div></div>
}

export default Logout