import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';

import moment from 'moment';
import api from 'api';

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';
import ApiError from 'components/markup/layout/ApiError';

import formatText from 'utils/formatText';

const MatterViewMainTasksFinished = ({matter, viewing_user}) => {

    const [err, setErr] = useState(null)
    const [tasks, setTasks] = useState(null)

    const findTasks = useCallback(async () => {
        const tasks = await api.tasks.search({
            skip: 0,
            limit: 100,
            searchString: '',
            sort: { finished_on: -1 },
            filter: {
                matter: matter._id,
                finished_on: { $gt: 0 },
            }
        })

        if(!tasks.success) return setErr(tasks.message)
        if(tasks.data) setTasks(tasks.data.documents)
    }, [matter._id])

    useEffect(() => {
        findTasks()
    }, [findTasks])

    if(err) return <ApiError err={err} />
    if(!tasks) return <Circle />

    return (
        <div className="ection">

            <Card className='card-no-shadow'>

                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Task</th>
                                <th>Finished By</th>
                                <th>Due</th>
                                <th>Finished On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!tasks.length ? ( 
                                <tr>
                                   <td><i className="fas fa-check mr-2 text-success" />No Tasks Found</td>
                                   <td></td>
                                   <td></td>
                                   <td></td>
                               </tr>
                             ) : tasks.map(t => {

                                return (
                                    <tr key={t._id}>
                                        <td>
                                            {formatText(t.task_template.name).stripHTML(30)}
                                            {t.task_template ? (
                                                <div className='text-muted'>{formatText(t.task_template.description).stripHTML(30)}</div>
                                            ) : ''}                                    
                                        </td>
                                        <td>
                                            {t.finished_by ? <ObjectFinder collection="users" _id={t.finished_by} /> : 'System' }
                                        </td>
                                        <td>
                                            {t.due_date ? moment.unix(t.due_date).format('MMM Do h:mm A') : ''}
                                        </td>
                                        <td className='text-right'>
                                            {t.finished_on ? (
                                                <span className={t.due_date && t.due_date < t.finished_on ? 'text-danger' : ''}>
                                                    {moment.unix(t.finished_on).format('MMM Do h:mm A')}
                                                </span>
                                            ) : ''}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>

        </div>
    );

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainTasksFinished);