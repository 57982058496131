import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Progress, Row } from 'reactstrap';

import hasPrivilege from 'utils/hasPrivilege';

const BarWrapper = ({ onSetTab, tab, privilege, children }) => {
    let active = false
    const canView = privilege ? hasPrivilege(privilege) : ''

    if(canView) active = true;
    if(!canView && privilege) return <></>

    if(active) return (
        <Col  className="pl-2 pr-2">
            <Card onClick={() => onSetTab(tab)} className='my-3 cursor-pointer' style={{borderRadius: 6}}>
                {children}
            </Card>
        </Col>
    )

    return (
        <Col  className="pl-2 pr-2">
            <Card className='my-3' style={{borderRadius: 6}}>
                {children}
            </Card>
        </Col>
    )

}

const ProgressBar = ({ percent, title, onSetTab, tab, privilege }) => {

    let className = "";
    if(percent >= 0 && percent < 19.99) className = 'danger'
    if(percent >= 20 && percent < 49.99) className = 'warning'
    if(percent >= 50 && percent < 97.99) className = 'info'
    if(percent >= 98) className = 'success'

    return (
        <BarWrapper onSetTab={onSetTab} tab={tab} privilege={privilege} >
            <CardBody>
                <div className="progress-wrapper pt-3">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 text-${className}`}>
                                 {title}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{ percent}%</span>
                        </div>
                    </div>
                    <Progress max="100" value={percent < 10 ? 10 : percent} color={className} />
                </div>
            </CardBody>
        </BarWrapper>
    )
}

const MattersViewMainComponentsStepHistory = ({matter, onSetTab, socket, isMoving}) => {

    const [docs, setDocs] = useState(matter.current_step_doc_percentage);
    const [tasks, setTasks] = useState(matter.current_step_task_percentage);
    const [fields, setFields] = useState(matter.current_step_field_percentage);
    const [forms, setForms] = useState(matter.current_step_form_percentage);
    const [signatures, setSignatures] = useState(matter.current_step_sign_percentage);
    const [total, setTotal] = useState(matter.current_step_percentage);

    const onUpdate = useCallback((payload) => {
        if(payload._id === matter._id) {
            setDocs(payload.current_step_doc_percentage)
            setTasks(payload.current_step_task_percentage)
            setFields(payload.current_step_field_percentage)
            setForms(payload.current_step_form_percentage)
            setSignatures(payload.current_step_sign_percentage)
            setTotal(payload.current_step_percentage)
        }
    }, [matter._id])

    useEffect(() => {
        if(!isMoving) {
            socket.on('MATTERS.UPDATED', onUpdate)
    
            return () => {
                socket.off('MATTERS.UPDATED', onUpdate)
            }
        }
       
    }, [onUpdate, socket, isMoving])

    return (
        <Container fluid className='border-bottom'>
            
            <Row>
                <ProgressBar title="Docs" tab="Documents" percent={docs} onSetTab={onSetTab} privilege="DOCUMENTS.READ" />
                <ProgressBar title="Tasks" tab="Tasks" percent={tasks} onSetTab={onSetTab} privilege="TASKS.READ" />
                <ProgressBar title="Fields" tab="CustomFields" percent={fields} onSetTab={onSetTab}  privilege="CUSTOM_FIELDS.READ"/>
                <ProgressBar title="Forms" tab="Forms" percent={forms} onSetTab={onSetTab} privilege="FORMS.READ" />
                <ProgressBar title="Signatures" tab="Signature" percent={signatures} onSetTab={onSetTab} privilege="SIGNING.DOCUMENTS.READ" />
                <ProgressBar title="Overall" tab="Overview" percent={total} onSetTab={onSetTab} />
            </Row>

        </Container>
    )

}

const mapStateToProps = state => {
	return {
        socket: state.socket,
	    company: state.company.company,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsStepHistory);