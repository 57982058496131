import { useCallback, useState } from 'react';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';

const baseState = {
    description   : '',
    amount        : '',
}

const ModalCustomFields = ({invoice, onAddArrayItem, onRemoveArrayItem}) => {

    const [writeOff, setWriteOff] = useState(baseState);

    const onAddWriteOff = useCallback(() => {
        if(writeOff.amount  === '' || writeOff.amount < 0) return toast.info('Write Off amount must be greater or equal to 0.')
        if(!writeOff.description) return toast.info('Write Off description cannot be left blank.')

        onAddArrayItem('write_offs', { 
            ...writeOff,
            amount: parseFloat(writeOff.amount),
        })
        setWriteOff(baseState)
    }, [writeOff, onAddArrayItem])

    const onSetWriteOff = useCallback((field, val) => {
        const _writeOff = JSON.parse(JSON.stringify(writeOff))
        _writeOff[field] = val
        setWriteOff(_writeOff)
    }, [writeOff])

    const disabled = writeOff.amount === '' || !writeOff.description;

    return (

        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Add Write Off</CardTitle>
                </CardHeader>
                
                <CardBody>


                    <FormGroup>
                        <label className='form-control-label'>Item Amount*</label>
                        <Input 
                            type="number"
                            value={writeOff.amount}
                            onChange={(e) => onSetWriteOff('amount', e.target.value)}
                        />
                    </FormGroup>

                  
                    <FormGroup>
                        <label className='form-control-label'>Item Description*</label>
                        <Input 
                            type="text"
                            value={writeOff.description}
                            onChange={(e) => onSetWriteOff('description', e.target.value)}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <button 
                        className={`btn btn-${disabled ? 'outline-info' : 'success'} btn-sm`}
                        onClick={onAddWriteOff}
                        disabled={disabled}
                    >
                        <i className="fas fa-plus mr-2" /> Add Write Off
                    </button>
                    {disabled ? <span>* Above Fields Required</span> : ''}
                </CardFooter>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Current WriteOffs</CardTitle>
                </CardHeader>
                
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        {invoice.write_offs.length ? invoice.write_offs.map((obj, i) => (
                            <tr key={i}>
                                <td>{obj.description}</td>
                                <td>{formatCurrency(obj.amount)}</td>
                                <td>
                                    <i 
                                        className="fas fa-trash text-danger cursor-pointer"  
                                        onClick={() => onRemoveArrayItem('write_offs', i)}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>*No Write Off Have Been Added</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Card>

          
        </div>

    )
}

export default ModalCustomFields