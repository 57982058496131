import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import A from 'components/markup/links/A';
import ModalToggler from 'components/functional/modals/Toggler';
import ObjectFinder from 'components/system/Objects/Finder';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';
import Dots from 'components/markup/loading/Dots';
import FinishTask from 'components/system/FinishTask';

import moment from 'moment';
import api from 'api';
import formatText from 'utils/formatText';

import { toast } from 'react-toastify';
import { formatCurrency } from "utils/currency";

import document from './images/document.svg';
import event from './images/event.svg';
import notFound from './images/notFound.svg';
import task from './images/task.svg';
import text from './images/text.svg';
import voicemail from './images/voicemail.svg';

import DocumentCategories from '../matters/View/Main/Documents/Categories'

const ContactsAll = ({selected_division}) => {

    const [foundItem, setFoundItem] = useState(null)
    const [ignore, setIgnore] = useState([])

    const onSetIgnore = useCallback((_id) => {
        const _ignore = JSON.parse(JSON.stringify(ignore))
        _ignore.push(_id)
        setIgnore(_ignore)
    }, [ignore])


 
    const fetchNextItem = useCallback(async (e, newIgnore) => {
        setFoundItem(null)
        const _ignore = ignore;
        if(newIgnore) _ignore.push(newIgnore)

        const data = await api.work_center.findNext({ignore: _ignore, division: selected_division._id ? selected_division._id : undefined})
        if(!data.success) return toast.error('Something went wrong, please refresh your page')

        if(_ignore.length !== ignore.length) setIgnore(_ignore)
        setFoundItem(data.data ? data.data : 'not found')
    }, [ignore, selected_division._id])

    const getContactIdentifier = useCallback((contact) => {
        if(contact.display_name) return contact.display_name
        if(contact.phone) return formatText(contact.phone).phone();
        return contact.email
    }, [])

    const onMarkResolved = useCallback(async () => {
        const updated = await api.call_voicemails.resolve(foundItem.doc._id)
        if(!updated.success) return toast.error(`Something went wrong marking this voicemail as resolved, please try again`)

        fetchNextItem()
    }, [fetchNextItem, foundItem])

    useEffect(() => {
        fetchNextItem()

        window.addEventListener('focus', fetchNextItem)

        return () => {
            window.removeEventListener('focus', fetchNextItem)
        }

    }, [fetchNextItem])
 

    return (

        <>

        <HeaderNavigation 
            title="Work Center"
            description={foundItem ? `Found Item: ${foundItem.type ? foundItem.type : ''}` : <span>Loading<Dots /></span> }
            actionComponent={(
               <div>
                    <button onClick={fetchNextItem} className="btn btn-info">
                        <i className="fas fa-refresh mr-2 " /> Refresh
                    </button>
               </div>
            )}
        />

        <Container fluid>
    
            <Helmet>
                <title>Work Center</title>
                <meta name="description" content="Work Center" />
            </Helmet>

            {!foundItem ? <Circle className="pt-6" /> : !foundItem.doc ? (
                <Card className="rounded" style={{overflow: 'hidden'}}>

                    <Row >
                        <div 
                            className="col-auto border-right" 
                            style={{
                                width: 200, 
                                backgroundSize: 'contain',
                                backgroundPosition: 'center', 
                                overflow: 'hidden', 
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url("${notFound}")`
                            }} 
                        />
                        
                        <Col className="pl-0">
                            
                            <CardBody className="py-7 text-center">
                                <h2 className="mb-0">All Set! No Outstanding Items Were Found For The Current Division</h2>
                            </CardBody>

                        </Col>
                    </Row>

                </Card>
            ) : (

                <>

                <Card className="rounded" style={{overflow: 'hidden'}}>

                    <Row >
                        <div 
                            className="col-auto border-right" 
                            style={{
                                width: 200, 
                                backgroundSize: 'contain',
                                backgroundPosition: 'center', 
                                overflow: 'hidden', 
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url("${
                                    foundItem.type === 'Document' ? document : 
                                    foundItem.type === 'Voicemail' ? voicemail : 
                                    foundItem.type === 'Event' ? event : 
                                    foundItem.type === 'Text' ? text : 
                                    foundItem.type === 'Task' ? task : 
                                    ''}")`
                            }} 
                        />
                        
                        <Col className="pl-0">
                            <CardHeader>
                                <CardTitle className="mb-0">
                                    {foundItem.matter ? (
                                        <A  href={`/matters/${foundItem.matter.id}`}><b>Matter: </b> {foundItem.matter.name}</A>
                                    ) : foundItem.contact ?  (
                                        <span><b>Contact: </b> {getContactIdentifier(foundItem.contact)}</span>
                                    ) : (
                                        <span><b>Item: </b> {foundItem.doc.name}</span>
                                    )}
                                </CardTitle>
                            </CardHeader>

                            <CardBody>
                                {foundItem.type === 'Task' ? (
                                    <div className="py-3">
                                        <Row>
                                            <Col className="align-self-center">
                                                <p className="text-sm text-uppercase mb-0">TASK: { foundItem.doc.name }</p>
                                            </Col>
                                          
                                            <div className="col-auto text-right align-self-center" style={styles.rightCol}>
                                            <ModalToggler onTaskFinished={fetchNextItem} component={FinishTask} task={foundItem.doc} isStepTaskView={true}>
                                                <button style={styles.rightButton} className="btn btn-success btn-sm">Finish Task</button>
                                            </ModalToggler>
                                            </div>

                                        </Row>
                                    </div>
                                ) : foundItem.type === 'Document' ? (
                                    <div>
                                        <p className="text-sm text-uppercase mb-0">Document: <ObjectFinder collection="matter_documents" _id={foundItem.doc.matter_document} /></p>
                                        <p className="text-sm  mb-0 text-capitalize">Current Status: {foundItem.doc.status}</p>
                                    </div>
                                ) : foundItem.type === 'Voicemail' ? (
                                    <div className="py-3">
                                        <Row>
                                            <Col className="align-self-center">
                                            <p className="text-sm text-uppercase mb-0">New Voicemail</p>
                                            <p className="text-sm  mb-0 text-capitalize">Transcription: {foundItem.doc.transcription_text ? foundItem.doc.transcription_text : '-'}</p>

                                            </Col>

                                            <div className="col-auto text-right align-self-center" style={styles.rightCol}>
                                                <button onClick={onMarkResolved} style={styles.rightButton} className="btn btn-success btn-sm">Mark Resolved</button>
                                            </div>

                                        </Row>
                                    </div>
                                ) : foundItem.type === 'Text' ? (

                                     <div className="py-3">
                                        <Row>
                                            <Col className="align-self-center">
                                                <p className="text-sm text-uppercase mb-0">New Text Message</p>
                                                <p className="text-sm  mb-0 text-capitalize">Body: {foundItem.doc.last_message_preview ? foundItem.doc.last_message_preview : '-'}</p>
                                            </Col>
                                        
                                            <div className="col-auto text-right align-self-center" style={styles.rightCol}>
                                                <A style={styles.rightButton} href={`/texts/${foundItem.doc._id}`} className="btn btn-success btn-sm">Open Thread</A>
                                            </div>

                                        </Row>
                                    </div>
                                )  : foundItem.type === 'Event' ? (
                                    <div>
                                         <Row>
                                            <Col className="align-self-center">
                                                <p className="text-sm text-uppercase mb-0">Unfinished Event</p>
                                                <p className="text-sm  mb-0 text-capitalize">{foundItem.doc.name ? foundItem.doc.name : '-'}</p>                                            
                                            </Col>
                                            
                                            <div className="col-auto text-right align-self-center" style={styles.rightCol}>
                                            <ModalToggler onTaskFinished={fetchNextItem} component={FinishTask} task={foundItem.doc} isStepTaskView={true}>
                                                <button style={styles.rightButton} className="btn btn-success btn-sm">Finish Event</button>
                                            </ModalToggler>
                                            </div>

                                        </Row>
                                      

                                    </div>
                                ) : (
                                    <p className="text-sm mb-0">No Items Found</p>
                                )}
                            </CardBody>

                            <CardFooter>
                                
                                    <Row>
                                        <Col md={6} className="align-self-center">

                                        <p className="text-sm mb-0 font-weight-bold text-dark">Date: {moment.unix(foundItem.date).format('MMM Do, YYYY h:mm A')}</p>


                                        </Col>
                                        <Col md={6} className="align-self-center text-right">
                                            <button style={styles.rightButton} onClick={() => onSetIgnore(foundItem.doc._id)} className="btn btn-warning btn-sm">Skip Item <i className="fas fa-arrow-right" /></button>
                                        </Col>
                                    </Row>
                                
                            </CardFooter>
                        </Col>
                    </Row>

                </Card>

                {foundItem.type === 'Voicemail' ? (
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Voice Recording</CardTitle>
                        </CardHeader>

                            <CardBody>
                                <div className="py-3">
                                <audio controls style={{width: '100%'}} className="my-0">
                                    <source src={foundItem.doc.recording_url} type="audio/mp3" />
                                    Your browser does not support the audio tag.
                                </audio>
                                </div>
                        </CardBody>
                    </Card>
                ) : ''}
            
                {foundItem.type === 'Document' ? (
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Matter Documents</CardTitle>
                        </CardHeader>
                        
                        <DocumentCategories matter={foundItem.matter} onSave={() => fetchNextItem(null, foundItem.doc._id)} />

                    </Card>
                ) : ''}

                {/* matters/39?docsTab=Missing&tab=Documents */}

                {foundItem.matter ? (
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Matter Info</CardTitle>
                        </CardHeader>

                        <div className="table-responsive border">
                            <table className="table">

                                <tbody>
                                    <tr>
                                        <td style={styles.firstTd}>Matter Name</td>
                                        <td><A href={`/matters/${foundItem.matter.id}`}>{foundItem.matter.name}</A></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Current Step</td>
                                        <td><ObjectFinder collection="workflow_steps" _id={foundItem.matter.workflow_step} /></td>
                                    </tr>
                                 
                                    <tr>
                                        <td style={styles.firstTd}>Step Category</td>
                                        <td>{foundItem.matter.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={foundItem.matter.workflow_step_category} /> : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Step Disposition</td>
                                        <td>{foundItem.matter.workflow_disposition ? <ObjectFinder collection="workflow_dispositions" _id={foundItem.matter.workflow_disposition} /> : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Assigned Department</td>
                                        <td>{foundItem.matter.division_department ? <ObjectFinder collection="division_departments" _id={foundItem.matter.division_department} /> : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Contacts</td>
                                        <td>{foundItem.matter.contacts.map((c, i) => i === 0 ? <ObjectFinder collection="contacts" _id={c} /> : <span>, <ObjectFinder collection="contacts" _id={c} /></span>)} </td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Step Last Moved</td>
                                        <td>{moment.unix(foundItem.matter.current_step_start).format('MMM Do, YYYY h:mm A')}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Created At</td>
                                        <td>{moment.unix(foundItem.matter.created_at).format('MMM Do, YYYY h:mm A')}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Billing Total</td>
                                        <td>{formatCurrency(foundItem.matter.billing_total)}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Billing Paid</td>
                                        <td>{formatCurrency(foundItem.matter.billing_paid)}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Last Note</td>
                                        <td>{foundItem.matter.last_note}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Link</td>
                                        <td><A href={`/matters/${foundItem.matter.id}`} className="btn btn-success btn-sm">Open Matter</A></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </Card>
                ) : foundItem.contact ? (
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Contact Info</CardTitle>
                        </CardHeader>

                        <div className="table-responsive border">
                            <table className="table">


                                <tbody>
                                    <tr>
                                        <td style={styles.firstTd}>Name</td>
                                        <td><A href={`/contacts/${foundItem.contact.id}`}>{foundItem.contact.display_name}</A></td>
                                    </tr>
                                
                                    <tr>
                                        <td style={styles.firstTd}>Main Number</td>
                                        <td>{foundItem.contact.phone ? formatText(foundItem.contact.phone).phone() : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Phone 2</td>
                                        <td>{foundItem.contact.phone_2 ? formatText(foundItem.contact.phone_2).phone() : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Phone 3</td>
                                        <td>{foundItem.contact.phone_3 ? formatText(foundItem.contact.phone_3).phone() : '-'}</td>
                                    </tr>
                                   
                                    <tr>
                                        <td style={styles.firstTd}>Main Email</td>
                                        <td>{foundItem.contact.email ? foundItem.contact.email : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Email 2</td>
                                        <td>{foundItem.contact.email_2 ? foundItem.contact.email_2 : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Email 3</td>
                                        <td>{foundItem.contact.email_3 ? foundItem.contact.email_3 : '-'}</td>
                                    </tr>
                                  
                                    <tr>
                                        <td style={styles.firstTd}>Inbound Calls</td>
                                        <td>{foundItem.contact.calls_inbound}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.firstTd}>Outbound Calls</td>
                                        <td>{foundItem.contact.calls_outbound}</td>
                                    </tr>
                                
                                    <tr>
                                        <td style={styles.firstTd}>Link</td>
                                        <td><A href={`/contacts/${foundItem.contact.id}`} className="btn btn-success btn-sm">Open File</A></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </Card>
                ) : ''}

                </>
            )}


            {/* <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(foundItem, null, 4)}</pre> */}

        </Container>

        </>
    )

}

const styles = {
    firstTd: {
        width: 175
    },
    rightCol: {
        width: 175,
    },
    rightButton: {
        width: 120
    }
}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
