import { useEffect } from "react"

const FinishTaskSendForSignature = ({task, toggleModal}) => {

    useEffect(() => {

        toggleModal()
        window.open(`/editor/signing/${task.matter}/${task.task_template.signing_template}?task=${task._id}&redirect=${window.location.href.replace(window.location.origin, "")}`, '_blank');

    }, [task, toggleModal])

    return <div></div>
}

export default FinishTaskSendForSignature