import Modal from './Modal'
import ModalToggler from 'components/functional/modals/Toggler'


const TextsSend = ({thread, onMarkResolved}) => {
    // thread.ticket.snippet = 'Hi John How Are you? I had a question about my upcoming appointment. I '
   
    return (

        
        <div className="archk-messaging-send bg-white border-top z-depth-1">
            <div className="archk-messaging-send-wrapper px-3 pt-">
                {/* {thread.ticket} */}
                <ModalToggler component={Modal} thread={thread} onMarkResolved={onMarkResolved}>
                    <button className='btn btn-success btn-block' style={{position: 'relative', top: 0}}>
                        <i className="fas fa-check mr-2" /> Resolve Ticket
                    </button>
                </ModalToggler>
            </div>
        </div>
    )

}

export default TextsSend