import { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap'

import api from 'api';

import Dots from 'components/markup/loading/Dots'

const Transcription = ({ doc, background }) => {

    const [ transcription, setTranscription ] = useState(null)
    const [ showFull, setShowFull ] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await api.documents.findById(doc._id);
        if(data.data && data.data.ai_transcription) return setTranscription(data.data.ai_transcription)
            setTranscription('err')
    }, [doc._id])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <div className='text-left mt-5 border p-3 rounded'>
            <p className={`${background === 'white' ? '' : 'text-white'} mb-0 font-weight-bold text-uppercase`}>
                <Row>
                    <Col md={6} className='align-self-center'>
                        Transcription{!transcription ? <Dots /> : ''}
                    </Col>
                    <Col md={6} className='align-self-center text-right'>
                        {!showFull && transcription && transcription !== 'err' ? (
                            <div className='mt-0'><button onClick={() => setShowFull(true)} className='btn btn-sm btn-outline-info'><i className="fas fa-eye mr-2" /> Show Full Transcription</button></div>
                        ) : ''}
                    </Col>
                </Row>
            </p>
            <p className={`text-sm mb-0`}>
                {
                    !transcription ? <span></span> : 
                    transcription === 'err' ? 'No transcription was found for this document' : 
                    showFull ? <pre className={`${background === 'white' ? '' : 'text-white'}`}>{transcription}</pre> : null
                }
            </p>
        </div>
    )
}

export default Transcription