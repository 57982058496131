import React from "react";
import { Col, Row } from "reactstrap";

const InternalSidebar = (props) => {

    const { routes, onClick, tab } = props

    return (
        <Row className="archk-internal-sidebar">

            <div className='col-auto' style={{width: 200}}>
                <ul>
                    {routes.map((route, i) => (
                        <li key={i} className={i === tab ? 'active rounded' : 'rounded'} onClick={() => onClick(route, i)} >{route.name}</li>
                    ))}
                </ul>
            </div>

            <Col>
                {props.children}
            </Col>

        </Row>
    )

}


export default InternalSidebar