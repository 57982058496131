import { useEffect, useState, useMemo } from 'react';

const useOnScreen = (ref) => {

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    ), [])
  
  
    useEffect(() => {
      observer.observe(ref.current)
      return () => observer.disconnect()
    }, [ref, observer])
  
    return isIntersecting
}

export default useOnScreen;