import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Circle from 'components/markup/loading/Circle'
import ObjectFinder from 'components/system/Objects/Finder';

import { setSelectedMatter } from 'store/functions/matters'
import { useCallback } from 'react';


const MattersViewSidebarLeft = ({related, matter}) => {

    const all = related ? related.filter(r => r._id !== matter._id) : null

    const onLinkClick = useCallback(() => {
        window.scrollTo(0,0)

        setSelectedMatter()
    }, [])

    return (
        <div>


            <div className="section mb-0 py-1 border-bottom bg-secondary">
                <h4 className='mb-0 text-uppercse'>Related Cases</h4>

            </div>

            {!all ? <Circle /> : all.length ? all.map(matter => (
                <Link key={matter._id} to={`/matters/${matter.id}`} >
                    <div onClick={onLinkClick} className="section border-top border-bottom">
                        <h5 className="mb-0 text-muted">ID: {matter.id} - <small><ObjectFinder collection="workflows" _id={matter.workflow} /></small></h5>
                        <h3 className="mb--2">{matter.name}</h3>
                        <h5 className="mb-0 text-muted">{matter.current_step_name}</h5>
                    </div>
                </Link>
                // <div key={matter._id}>
                //     <h2 className=" text-uppercase mb-0">#{matter.id}</h2>
                //     <p className="text-sm mb-0">{matter.name}</p>
                //     <p className="text-sm mb-0"><small>Type: <ObjectFinder collection="divisions" _id={matter.division} /> </small></p>
                // </div>
            )) : (
                <div className="section border-bottom">
                    <p className='mb-0 text-sm'><i className="fas fa-info-circle text-info mr-2" /> No related cases found</p>
                </div>
            )}
          
            
        </div>
    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewSidebarLeft);
