import { useState } from 'react'

import SendForm from 'components/system/SendForm';

const FinishTaskSendForm = ({ task, onFinish, toggleModal }) => {

    const [showModal, setShowModal] = useState(true)

    return (
        <SendForm 
            showModal={showModal}
            toggleModal={() => {
                setShowModal(false)
                toggleModal()
            }}
            matter={task.matter}
            task_id={task._id}
            matter_id={task.matter}
            form_id={task.task_template ? task.task_template.form : ''}
            onRequested={onFinish}
        />
    )
    // <ModalToggler component={SendForm} form_id={foundForm._id} matter_id={matter._id} onRequested={fetchData} contact={c._id}>
    //     <button style={{width: 130}} className="btn btn-sm btn-danger">Not Sent</button>
    // </ModalToggler>
}

export default FinishTaskSendForm