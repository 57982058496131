import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Badge, Card, Container } from "reactstrap";

import api from 'api';
import { Link } from 'react-router-dom';

import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';

import { Helmet } from 'react-helmet';

import moment from 'moment';

import { toast } from 'react-toastify';

import formatText from 'utils/formatText';

import CallContactBadge from 'components/system/CallContact/Badge';


const Claim = ({row, viewing_user}) => {

    const [claimed, setClaimed] = useState(row.claimed_by === viewing_user._id ? 'me' : row.claimed_by ? 'other' : false);

    const onClaimed = useCallback(async () => {
        setClaimed('me');
        const saved = await api.leads.claim(row._id)
        if(!saved.success) {
            if(saved.message && saved.message[0] === 'This lead has already been claimed') {
                setClaimed('other')
                toast.info('The lead you have tried to claimed was claimed first by another party.')
            } else {
                setClaimed(false)
                toast.error('Could not claim lead, please try again.')
            }
        }
    }, [row._id])

    const icons = [
        { 
            icon: 'fas fa-user',
            color: 'warning', 
            wrapper: Link,
            to: `/leads/${row._id}`,
        },
        {
            icon: 'fas fa-external-link-alt',
            color: 'success', 
            wrapper: Link,
            to: `/contacts/${row.contact}`,
            tooltip: 'Open File'
        },
    ]

    if(!claimed) {
        icons.unshift({
            onClick: onClaimed,
            icon: 'fa-solid fa-clipboard-check',
            color: 'info',
            tooltip: 'Claim Lead'
        })
    } else if(claimed === 'me') {
        icons.unshift({
            icon: 'fa-solid fa-check',
            color: 'success', 
            tooltip: 'Claimed By Me'
        })
    } else if(claimed === 'other') {
        icons.unshift({
            icon: 'fa-solid fa-times',
            color: 'danger', 
            tooltip: 'Claimed By Other'
        })
    }

    return (
        <TableIcons
            icons={icons}
    />
    )
}

const LeadsLive = ({socket, selected_division, viewing_user}) => {

    const [ documents, setDocuments ] = useState(null)
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(() => new Promise (async resolve => {
        const params = { searchString: '', page: 1,  skip: 0,  sizePerPage: 100,  limit: 100 }

        let filter = { division: selected_division._id }
        filter.claimed_by = null


        const query = await api.leads.search({ ...params, filter, isCSV: false })
        if(!query.data) return setError(query.message)

        return setDocuments(query.data.documents)
    }), [selected_division._id])

    const onLeadCreated = useCallback((lead) => {
        if(lead.division === selected_division._id) {
            const _documents = JSON.parse(JSON.stringify(documents));
            _documents.unshift(lead);
            setDocuments(_documents);
        }
    }, [documents, selected_division._id])

    const onLeadClaimed = useCallback((lead) => {
        const foundIndex = documents.findIndex(d => d._id === lead._id);
        if(foundIndex !== -1 && lead.claimed_at > 0 && lead.claimed_by !== viewing_user._id) {
            const _documents = JSON.parse(JSON.stringify(documents));
            _documents.splice(foundIndex, 1)
            setDocuments(_documents);
        }
    }, [documents, viewing_user._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        socket.on('LEADS.CREATED', onLeadCreated)
        socket.on('LEADS.CLAIMED', onLeadClaimed)

        return () => {
            socket.off('LEADS.CREATED', onLeadCreated)
            socket.off('LEADS.CLAIMED', onLeadClaimed)
        }

    }, [onLeadClaimed, onLeadCreated, socket])

    if(error) return <ApiError err={error} />
    if(!documents) return <div><Circle /></div>

 

    return (
        <>

        <Helmet>
            <title>{`Leads`}</title>
            <meta name="description" content="Leads" />
        </Helmet>

        <HeaderNavigation 
            title={'Leads'}
            description="Live Dashboard"
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed">

                <table className="table">
                    <thead>
                        <tr>
                            <th>Lead</th>
                            <th>Created At</th>
                            <th>Duplicate</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Source</th>
                            <th className="text-right">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {documents && documents.length ? documents.map(row => (
                            <tr key={row._id}>
                                <td>
                                    <Link to={`/leads/${row._id}`}>
                                        <div>
                                            <div>{row.display_name ? row.display_name : <span className="text-warning">Unknown Name</span>}</div>
                                            {row.postal_code ? (
                                                <div>ZIP: {row.postal_code}</div>
                                            ) : ''}
                                        </div>
                                    </Link>
                                </td>

                                <td>
                                    <div>
                                        <div>{moment.unix(row.created_at).format('h:mm A')}</div>
                                        <div>{moment.unix(row.created_at).format('MMM Do, YYYY')}</div>
                                    </div>
                                </td>

                                <td>
                                    {row.duplicate ? (
                                        <Badge style={styles.badge} color="danger">YES</Badge>
                                    ) : (
                                        <Badge style={styles.badge} color="success">NO</Badge>
                                    )}
                                </td>

                                <td>
                                    {row.email}
                                </td>

                                <td>
                                    {row.phone ? (
                                        <CallContactBadge contact={row.contact} phone={row.phone}>
                                            <span className="cursor-pointer">{formatText(row.phone).phone()}</span>
                                        </CallContactBadge>
                                    ) : '-'}
                                </td>

                                <td>
                                    <ObjectFinder collection="lead_sources" _id={row.lead_source} />
                                </td>

                                <td>
                                    <div className="text-right">
                                        <Claim row={row} viewing_user={viewing_user} />
                                    </div>
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Unclaimed Leads Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                /> */}
            </Card>

        </Container>

        </>
    )

}

const styles = {
    badge: {
        width: 100  
    }
}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(LeadsLive);