/*
address
property type (single family, duplex, etc.)
property nature of interest
description
other info
ownership (d1, d2, d1 & 2)

current value
current_value_type (unknown, partial interest)

amount of secured claim (unknown / linked to liens)
amount of secured claim type (unkown, known, linked to liens)

year
make
model
mileage

name of institution
Type of account ('checking savings, certificates o deposit, ira)
percent Ownership

beneficiary



*/



import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalDivisionOverride = ({obj, field, onInputChange}) => {

    return (

        <div>

            <ReactSelect
                title="Nature Of Interest"
                formGroup={true}
                onChange={(obj) => onInputChange(field, obj.value)}
                options={[
                    { value: 'equitable_interest', label: 'Equitable Interest' },
                    { value: 'fee_simple', label: 'Fee Simple' },
                    { value: 'future_interest', label: 'Future Interest' },
                    { value: 'joint_tenant', label: 'Joint Tenant' },
                    { value: 'life_estate', label: 'Life Estate' },
                ]}
                value={obj[field]}
            />    

        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);