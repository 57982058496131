/*
address
property type (single family, duplex, etc.)
property nature of interest
description
other info
ownership (d1, d2, d1 & 2)

current value
current_value_type (unknown, partial interest)

amount of secured claim (unknown / linked to liens)
amount of secured claim type (unkown, known, linked to liens)

year
make
model
mileage

name of institution
Type of account ('checking savings, certificates o deposit, ira)
percent Ownership

beneficiary



*/



import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import { formatCurrency } from 'utils/currency';

const ModalDivisionOverride = ({obj, field, onInputChange}) => {

    // amount_secured
// amount_secured_unknown
// amount_secured_linked_to_liens

    const unknown = `${field}_amount_secured_unknown`;
    const liens = `${field}_linked_to_liens`;

    return (

        <div>

            {/* <ReactSelect
                title="What Is The Type Of This Assets Value"
                formGroup={true}
                onChange={(obj) => onInputChange(type, obj.value)}
                options={[
                    { value: 'known', label: 'Known' },
                    { value: 'unknown', label: 'Unknown' },
                    { value: 'partial', label: 'Partial Interest' },
                ]}
                value={obj[type] ? obj[type] : 'known'}
            />     */}

            <FormGroup>
                <label className="form-control-label">Amount Of Secured Claim: {obj[field] ? <span className='text-info font-weight-bold'>{formatCurrency(obj[field])}</span> : '$0.00'}</label>
                <Input 
                    disabled={obj[unknown]|| obj[liens] ? true : false}
                    type="number"
                    value={obj[field]}
                    onChange={e => onInputChange(field, parseFloat(e.target.value))}
                />
            </FormGroup>   

            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-${field}-unknown`}
                        type="checkbox"
                        checked={obj[unknown] || false}
                        onChange={() => onInputChange(unknown, !obj[unknown])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-${field}-unknown`}>
                        Amount Is Unknown
                    </label>
                </div>
            </FormGroup>
            <FormGroup>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`archk-bk-checkbox-${field}-liens`}
                        type="checkbox"
                        checked={obj[liens] || false}
                        onChange={() => onInputChange(liens, !obj[liens])}
                    />
                    <label className="custom-control-label" htmlFor={`archk-bk-checkbox-${field}-liens`}>
                        Linked To Liens
                    </label>
                </div>
            </FormGroup>


        </div>
    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);