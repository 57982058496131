import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Container } from 'reactstrap';
import A from 'components/markup/links/A'

import EditLink from './Edit'

const Links = ({workflowLinks, fetchWorkflowLinks, matter}) => {

    const [showModal, setShowModal] = useState(false)
    const [matterLinks, setMatterLinks] = useState(matter.links)

    useEffect(() => {
        if(!workflowLinks) fetchWorkflowLinks()
    }, [workflowLinks, fetchWorkflowLinks])

    if(!workflowLinks || (workflowLinks && !workflowLinks.length)) return <></>;

    return (
        <div className="bg-secondary border-bottom py-3">
            <Container fluid>
                {workflowLinks.map(l => {
                    const foundLink = matterLinks.find(ll => ll.workflow_link === l._id)
                    const linkVal = foundLink ? foundLink.link : ''
                    const href = l.link.replace(`[[value]]`, linkVal)
                    const requiresValue = l.link.includes('[[value]]')

                    return (foundLink && foundLink.link) || !requiresValue ? (

                        <A href={href} key={l._id + linkVal}>
                            <span  className="mr-2 px-3 py-2 mb-0 text-sm border rounded bg-white cursor-pointer">
                                <i 
                                    className="fas fa-link mr-2" 
                                    onClick={(e) => {

                                            e.preventDefault()
                                            setShowModal({
                                                link: l,
                                                value: linkVal
                                            })

                                    }}
                                /> {l.name}
                            </span>
                        </A>
                    ) : (

                            <span 
                                key={l._id + linkVal}
                                className="mr-2 px-3 py-2 mb-0 text-sm border rounded cursor-pointer" 
                                style={{background: '#ddd'}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowModal({
                                        link: l,
                                        value: linkVal
                                    })
                                }} 
                            >
                                <i className="fas fa-link mr-2" /> {l.name}
                            </span>

                    )
                })}
            </Container>

            <EditLink 
                showModal={showModal ? true : false}
                toggleModal={() => setShowModal(false)}
                link={showModal}
                setMatterLinks={setMatterLinks}
            />
        </div>
    )

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(Links);