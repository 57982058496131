const onTimeOnPageAdded = (onAdd) => {

    let isFocused;
    
    if(document.hasFocus()) {
        isFocused = setInterval(() => {
            onAdd(.5)
        }, 500)
    }
  

    const setFocus = () => {
        isFocused = setInterval(() => {
            onAdd(.5)
        }, 500)
    }
    const setBlur = () => {
        // console.log(isFocused)
        clearInterval(isFocused)
    }

    window.addEventListener("blur", setBlur);
    window.addEventListener("focus", setFocus);
}


export default onTimeOnPageAdded;