import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";

import NameCircle from 'components/markup/misc/NameCircle';
import ObjectFinder from 'components/system/Objects/Finder';

import moment from 'moment'
import { useCallback } from 'react';


const TextsSidebarLeftListItem = ({active, read, setActive, thread, selected_division, viewing_user}) => {

    let classNames = 'archk-messaging-spacer archk-text-message-list-item';
    if(read) classNames += ' archk-text-message-list-item-read'
    if(active && active._id === thread._id) classNames += ' archk-text-message-list-item-active'

    const startOfDay = parseInt(moment().startOf('day').format('X'))

    const renderParticipants = useCallback((participants) => {
        let markup = [];
        let extras = 0

        participants = participants.filter(p => {
            if(p.email === viewing_user.email) {
                return false;
            } else if(viewing_user.division_overrides.some(o => o.email === p.email)) {
                return false;
            }
            return true;
        })

        participants.forEach((p, i) => {

            let identifier = p.name ? p.name : p.email
            if(identifier.length > 20) identifier = identifier.slice(0, 20) + '...'

            if(!markup.length) {
                markup.push(<div>{identifier}</div>)
            } else if(i === 1) {
                markup.push(<div>{identifier}</div>)
            } else {
                extras++
            }
        })

        if(extras) {
            markup.push(<div>+{extras} More</div>)
        }
        return markup;
    }, [])

    return (

        <div className={classNames} onClick={() => setActive(thread)}>
            <Row>
                {/* <div className="col-auto align-self-center" style={{width: 42}}>
                    <NameCircle width={30} initials={thread.initials} style={{marginRight: 5}} />{' '}
                </div> */}

                <Col className="align-self-center">
                    <Row>

                        <Col className="col-auto align-self-center pr-" style={{width: 185}}>
                            <p className="text-s font-weight-bold text-dark mb-0 text-uppercase" style={{lineHeight: '14px'}}>
                                <small>
                                    {thread.subject && thread.subject.length > 20 ? thread.subject.slice(0, 20) + '...' : thread.subject}
                                </small>
                            </p>

                            <p className="text-sm  mb-2 " style={{lineHeight: '14px'}}>
                                <small>
                                    {renderParticipants(thread.participants)}
                                </small>
                            </p>
                            <p className={`text-s mb-0 archk-text-message-list-item-description ${selected_division._id ? 'mb-2' : 'mb-0'}`}>
                                <small>
                                    {thread.snippet && thread.snippet.length > 26 ? thread.snippet.slice(0, 26) + '...' : thread.snippet}
                                </small>
                            </p>

                            {!selected_division._id && thread.division? (
                                <p className="text-sm mb-0 archk-text-message-list-item-description mb-2 text-dark font-weight-bold">
                                    <small>
                                        <ObjectFinder collection="divisions" _id={thread.division} />
                                    </small>
                                </p>
                            ) : ''}
                        </Col>

                        <Col  className="pl-0">
                            <p className="text-sm text-muted mb-0 mt--2">
                                <small>
                                    {moment.unix(thread.recent_message).format(thread.recent_message > startOfDay ? 'h:mm A' : 'MMM Do')}
                                    {/* 12:45 PM */}
                                </small>
                            </p>
                            {!read ? (
                                <div className="text-right">
                                    <span className="archk-message-number"><small><i className="fas fa-comment" /></small></span>
                                </div>
                            ) : ''}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )

}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(TextsSidebarLeftListItem);