import A from 'components/markup/links/A'

const imageFiles = [
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/tiff',
]

const audioFiles = [
    'audio/basic',
    'audio/L24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/ac3',
    'audio/webm',
    'audio/amr-nb',
    'audio/amr',
]

const videoFiles = [
    'video/mpeg',
    'video/mp4',
    'video/quicktime',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
    'video/3gpp-tt',
    'video/H261',
    'video/H263',
    'video/H263-1998',
    'video/H263-2000',
    'video/H264',
]

const textFiles = [
    'text/vcard',
    'text/x-vcard',
    'text/csv',
    'text/rtf',
    'text/richtext',
    'text/calendar',
    'text/directory',
]

const applicationFiles = [
    'application/pdf',
    'application/vcard',
    'application/vnd.apple.pkpass',
]

const imageClasses = 'archk-text-message-attachment mr-2 archk-text-message-image rounded z-depth-3 mt-2'
const fileClasses = 'archk-text-message-attachment archk-text-message-file mr-2 rounded z-depth-3 mt-2 bg-gradient-warning'
const errorClasses = 'archk-text-message-attachment archk-text-message-file mr-2 rounded z-depth-3 mt-2 bg-gradient-danger'

const TextsMainAttachments = ({attachments}) => {

   if(!attachments.length) return <></>
   
   return (
        <div className='archk-text-message-attachment-wrapper'>
            {attachments.map((a, i) => {

                if(imageFiles.includes(a.type)) return (
                    <A href={a.url} key={i}>
                        <span className={imageClasses} style={{backgroundImage: `url(${a.url})`}} />
                    </A>
                )
                    
                if(applicationFiles.includes(a.type)) return (
                    <A href={a.url} key={i}>
                        <span className={fileClasses}> 
                            <i className="fa-solid fa-file-pdf display-1 text-white" /> 
                        </span>
                    </A>
                )
                    
                 if(videoFiles.includes(a.type)) return (
                    <A href={a.url} key={i}>
                        <span className={fileClasses}>
                            <i className="fa-solid fa-file-video display-1 text-white" />
                        </span>
                    </A>
                ) 


                if(audioFiles.includes(a.type)) return (
                    <A href={a.url} key={i}>
                        <span className={fileClasses}>
                            <i className="fa-solid fa-volume-high display-1 text-white" />
                        </span>
                    </A>
                )

                if(textFiles.includes(a.type)) return (
                    <A href={a.url} key={i}>
                        <span className={fileClasses}>
                            <i className="fa-solid fa-file-csv display-1 text-white" />
                        </span>
                    </A>
                )

                return (
                    <A href={a.url} key={i}>
                        <span className={errorClasses}>
                            <i className="fa-solid fa-ban display-1 text-white" />
                        </span>
                    </A>
                )
            })}
        </div>
   )
 
}

export default TextsMainAttachments