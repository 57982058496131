import { connect } from 'react-redux';
import FeedRenderer from 'components/system/Feed/Renderer'

const MattersViewMainFeedPage = ({matter}) => (
    <div className="section pt-0" style={{minHeight: 240}}>
        <FeedRenderer matter={matter._id} />
    </div>
)

const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainFeedPage);
