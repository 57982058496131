import { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import Circle from 'components/markup/loading/Circle';
import { Container, Row } from "reactstrap";

import api from 'api';

import { setSelectedMatter } from 'store/functions/matters';

import Main from './Main';
import SidebarLeft from './SidebarLeft';

import * as actionTypes from 'store/actions';
import store from 'store';

import { getUrlParameter, setUrlParameter } from "utils/urls";

const tabs = ['Overview', 'Feed', 'Documents', 'Forms', 'Tasks', 'Events', 'Billing', 'More', 'CustomFields', 'Checklist', 'Identifier', 'MatterValue', 'LeadSources', 'Automations', 'Items', 'CustomDates']

const ContactsAll = ({match, viewing_user, matter, socket}) => {

    const [isMoving, setIsMoving] = useState(false)
    const [workflowContacts, setWorkflowContacts] = useState(null)
    const [workflowRoles, setWorkflowRoles] = useState(null)
    const [workflowLocations, setWorkflowLocations] = useState(null)
    const [workflowLinks, setWorkflowLinks] = useState(null)

    const [showAI, setShowAI] = useState(false)
    // const [showModuleBK, setShowModuleBK] = useState(window.location.href.includes('localhost') ? true : false)
    const [showModuleBK, setShowModuleBK] = useState(getUrlParameter('showModule') === 'bk' ? true : false)

    const [events, setEvents] = useState(null)
    const [customFields, setCustomFields] = useState(null)

    const [tab, setTab] = useState('Overview')
    const [loaded, setLoaded] = useState(false)

    const onSetTab = useCallback((_tab) => {

        if(_tab === 'Signature') {
            _tab = "Documents"
            setUrlParameter('docsTab', 'Signing')
        } else if(_tab === "Documents") {
            setUrlParameter('docsTab', 'Missing')
        }

        setTab(_tab);
        setUrlParameter('tab', _tab)

        const el = document.getElementById('archk-matter-page-navigation')
        el.scrollIntoView({behavior: 'smooth'})
    }, [])

    const fetchWorkflowLinks = useCallback(async () => {
        const data = await api.workflow_links.find(matter.workflow)
        if(data.data) setWorkflowLinks(data.data)
    }, [matter.workflow])

    const fetchMatterEvents = useCallback(async () => {
        const data = await api.events.findByMatter(matter._id)
        if(data.data) setEvents(data.data)
    }, [matter._id])
   
    const fetchWorkflowContacts = useCallback(async () => {
        const data = await api.workflow_contacts.find(matter.workflow)
        if(data.data) setWorkflowContacts(data.data)
    }, [matter.workflow])
    
    const fetchCustomFields = useCallback(async () => {
        const data = await api.custom_fields.find(matter.division)
        if(data.data) setCustomFields(data.data)
        // if(data.data) setWorkflowRoles(data.data)
    }, [matter.division])
  
    const fetchWorkflowRoles = useCallback(async () => {
        const data = await api.workflow_roles.find(matter.workflow)
        if(data.data) setWorkflowRoles(data.data)
    }, [matter.workflow])
    
    const fetchWorkflowLocations = useCallback(async () => {
        const data = await api.workflow_locations.find(matter.workflow)
        if(data.data) setWorkflowLocations(data.data)
    }, [matter.workflow])

    const onNumbersUpdated = useCallback((data) => {
        if(data._id === match.params._id) {
            const _case = JSON.parse(JSON.stringify(store.getState().matters.selected_matter))
            _case.missing_documents = data.missing_documents
            _case.unfinished_tasks = data.unfinished_tasks
            _case.upcoming_events = data.upcoming_events
            
            store.dispatch({type: actionTypes.SET_SELECTED_MATTER, payload: _case})
        }
    }, [match.params._id])

    useEffect(() => {
        socket.on('MATTERS.NUMBERS_UPDATED', onNumbersUpdated)
        return () => {
            socket.off('MATTERS.NUMBERS_UPDATED', onNumbersUpdated)
        }

    }, [socket, onNumbersUpdated])


    useEffect(() => {
        api.page_histories.create(window.location.pathname);
        setSelectedMatter(match.params._id)

        return () => {
            setSelectedMatter(null)
        }
    }, [match.params._id, viewing_user._id])

    useEffect(() => {
        api.notes.create({
            matter    : matter._id,
            value     : 'Accessed this matter.',
            type      : 'system'
        })
        setEvents(null)
    }, [matter._id])

    useEffect(() => {
        const t = getUrlParameter('tab')
        if(tabs.includes(t)) {
            setTab(t)
        } else {
            const defaultTab = viewing_user.settings.matter_default_tab
            setTab(defaultTab)
        }
        setLoaded(true)
    }, [viewing_user.settings.matter_default_tab])

    // make sure to use the friendly url of a matter and not the mongo object id
    useEffect(() => {
        if(matter.id) {
            let url = window.location.href
            url = url.replace(matter._id, matter.id)
            window.history.pushState(null, null, `${url}`);

            api.matters.update(matter._id, { 
                last_viewed_by: viewing_user._id,
                last_viewed_at: Math.floor(new Date() / 1000)
            });
        }
    }, [matter.id, matter._id, viewing_user._id])

    if(matter._id === 'not found') return (
        <div className="alert alert-warning text-center">
            <i className="fas fa-info-circle mr-2" /> The matter you are looking for could not be found or is not part of a division you have access to.{' '}
            <Link to="/matters" className="text-underline text-white">Search Matters Here</Link>
        </div>
    )
    if(!matter._id) return <Circle className="py-6" />

    return (

        <div className="archk-matter-page">

            <Helmet>
                <title>{matter.name}</title>
                <meta name="description" content={matter.name} />
            </Helmet>


            <Container fluid>
                <Row>

                    <SidebarLeft 
                        fetchWorkflowContacts={fetchWorkflowContacts}
                        workflowContacts={workflowContacts}
                        fetchWorkflowRoles={fetchWorkflowRoles}
                        workflowRoles={workflowRoles}
                        fetchCustomFields={fetchCustomFields}
                        customFields={customFields}
                        setShowAI={setShowAI}
                    />
                    
                    <Main 
                        workflowLocations={workflowLocations}
                        fetchWorkflowLocations={fetchWorkflowLocations}
                        fetchCustomFields={fetchCustomFields}
                        fetchWorkflowLinks={fetchWorkflowLinks}
                        customFields={customFields}
                        workflowLinks={workflowLinks}
                        events={events}
                        fetchMatterEvents={fetchMatterEvents}
                        tab={tab}
                        onSetTab={onSetTab}
                        loaded={loaded}
                        isMoving={isMoving}
                        setIsMoving={setIsMoving}
                        showAI={showAI}
                        setShowAI={setShowAI}
                        showModuleBK={showModuleBK}
                        setShowModuleBK={setShowModuleBK}
                    />

                </Row>
            </Container>

        </div>
    )

}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	    device: state.device,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
