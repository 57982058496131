import { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, FormGroup, Input } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api';

import { toast } from 'react-toastify'
import { toggleStandardLoader } from 'store/functions/system/system';

const ModalDivisionOverride = ({showModal, toggleModal, matter, onSave}) => {

    const [status, setStatus] = useState('missing');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const onCreate = useCallback(async () => {

        if(!status) return toast.info(`You must select a document status before proceeding.`)
        if(!name) return toast.info(`You must select a document name before proceeding.`)

        toggleStandardLoader(true)
        const saved = await api.matter_document_uploads.create({
            matter: matter._id,
            status,
            name,
            description: description ? description : undefined
        })
        toggleStandardLoader(false)

        if(!saved.success) return toast.info(`Could not create document category, please try again.`)
        if(onSave) onSave(saved.data);

        toggleModal();
    }, [description, matter._id, name, onSave, status, toggleModal])
  
    useEffect(() => {
        if(showModal) {
            setStatus('missing')
            setName('')
            setDescription('')

            setTimeout(() => {
                const input = document.getElementById('archk-document-category-add')
                if(input) input.focus();
            }, 250)

        }
    }, [showModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Document Category</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom bg-secondary">

                <p className="text-sm mb-0"></p>

            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className='form-control-label'>*Category Name</label>
                    <Input 
                        id="archk-document-category-add"
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <label className='form-control-label'>Category Description</label>
                    <Input 
                        type="textarea"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </FormGroup>

                <ReactSelect
                    title="Category Status"
                    formGroup={true}
                    placeholder="Status"
                    onChange={(obj) => setStatus(obj.value)}
                    options={[
                        { label: <span><i className="fas fa-times-circle mr-2 text-purple" /> NA</span>, value: 'na' },
                        { label:  <span><i className="fas fa-question-circle mr-2 text-info" /> Pending</span>, value: 'pending' },
                        { label: <span><i className="fas fa-thumbs-up mr-2 text-success" /> Approved</span>, value: 'approved' },
                        { label: <span><i className="fa-solid fa-person-circle-question mr-2 text-warning" /> Missing</span>, value: 'missing' },
                        { label: <span><i className="fas fa-clock mr-2 text-danger" /> Expired</span>, value: 'expired' },
                    ]}
                    value={status}
                />

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> Close
                </button>
                <button className="btn btn-success" onClick={onCreate}>
                    <i className="fas fa-save mr-2" /> Add Category
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);