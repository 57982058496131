import * as actionTypes from 'store/actions';
import store from 'store';

import api from 'api';

export const setSelectedMatter = async(matter_id, cb) => {
    if(!matter_id) {
        if(cb) cb()
        return store.dispatch({type: actionTypes.SET_SELECTED_MATTER, payload: {}});
    }

    const data = await api.matters.overview(matter_id);
    if(!data.data) {
        if(cb) cb()
        return store.dispatch({type: actionTypes.SET_SELECTED_MATTER, payload: { _id: 'not found' }});
    }

    if(cb) cb(data.data)
    store.dispatch({type: actionTypes.SET_SELECTED_MATTER, payload: data.data});
}
