import { useCallback } from 'react';

import Circle from 'components/markup/loading/Circle';

import ModalToggler from 'components/functional/modals/Toggler';
import SelectTemplate from './SelectTemplate';

import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const SendEmailToolbar = ({attachments, setAttachments, onSend,onAttachmentsChange, emailTemplate, sending, foundMatter, foundThread, onTemplateChange}) => {


    const onFocusAttachmentUpload = useCallback(() => {
        const input = document.getElementById('archk-send-send-email-attachment-input')
        input.click();
    }, [])

    const onAddAttachments = useCallback((e) => {
        const newAttachments = e.target.files

        let files = [];

        for (let i = 0; i < newAttachments.length; i++) {
            const file = newAttachments[i]
            const id = uuidv4();
            files.push({
                id,
                name: file.name,
                type: file.type,
                size: file.size,
                status: 'loading'
            })

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                setAttachments({ type: 'update', payload: { id, status: 'success', data: reader.result } })
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
                toast.info(`Attachment could not be uploaded, please try again.`)
                setAttachments({ type: 'update', payload: { id, status: 'err', data: reader.result } })
            };
        }

        setAttachments({ type: 'add', payload: files })
    }, [setAttachments])

    const onRemoveAttachment = useCallback((id) => {
        setAttachments({ type: 'delete', payload: id })
    }, [setAttachments])

    const renderAttachments = useCallback(() => {
        // return mapping of names
        return attachments.map((a, i) => (
            <span className={`attachment border pr-6 ${a.status === 'err' ? 'bg-danger text-white' : ''}`} key={`attachment__${i}`}>
                {a.status === 'loading' ? (
                    <Circle />
                ) : ''}
                {a.name.length > 30 ? a.name.slice(0, 30) + '...' + a.name.slice(a.name.length - 3, a.name.length) : a.name}
                <span onClick={() => onRemoveAttachment(a.id)} className="remove"><i className={`fas fa-trash pl-3 ${a.status === 'err' ? 'text-white' : ''}`} /></span>
            </span>
        ))
    }, [attachments, onRemoveAttachment])


    return (

        <div className='position-relative'>

            <div className="show-attachments border-left border-right">
                <h4 className='mb-2'>Attachments:</h4>
                {attachments.length ? <div className='show-attachments-container'>{renderAttachments()}</div> : <p className='text-sm mb-0'><i className="fas fa-info-circle text-info mr-2" /> No Email Attachments Found</p>}
            </div>

            <div id="toolbar">

                <button disabled={sending} onClick={onSend} className="btn btn-success send"><i className="fas fa-paper-plane mr-2" />Send</button>
                {emailTemplate ? '' : (
                    <ModalToggler component={SelectTemplate} foundMatter={foundMatter} foundThread={foundThread} onTemplateChange={onTemplateChange}>
                         <button className="btn btn-outline-success template"><i className="fas fa-plus mr-2" />Template</button>
                    </ModalToggler>
                )}
                

                <button onClick={onFocusAttachmentUpload}>
                    <i className="fas fa-paperclip" style={{position: 'relative', top: -3}} />
                </button>

                <button className="ql-bold"></button>
                <button className="ql-underline"></button>
                <button className="ql-italic"></button>
                <button className="ql-link"></button>
                <select className="ql-background"></select>
                <select className="ql-color"></select>

            </div>

            <input id="archk-send-send-email-attachment-input" hidden={true} multiple={true} type="file" onChange={onAddAttachments} />
    {/* 
            <ModalSelectTemplateEmail
                showModal={showModal}
                toggleModal={this.toggleModal}
                onSelect={onSelectTemplate}
                division={division}

            /> */}

            {sending ? (
                <div style={{position: 'absolute', bottom: -7, right: 0, width: 50, height: 50}}>
                    <Circle />
                </div>
            ) : ''}

        </div>
    )

}

export default SendEmailToolbar
