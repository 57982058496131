import { connect } from 'react-redux';
import { Col, Row } from "reactstrap";

import NameCircle from 'components/markup/misc/NameCircle';
import ObjectFinder from 'components/system/Objects/Finder';

import moment from 'moment'

const TextsSidebarLeftListItem = ({active, read, setActive, thread, selected_division}) => {

    let classNames = 'archk-messaging-spacer archk-text-message-list-item';
    if(read) classNames += ' archk-text-message-list-item-read'
    if(active && active._id === thread._id) classNames += ' archk-text-message-list-item-active'

    const startOfDay = parseInt(moment().startOf('day').format('X'))

    return (

        <div className={classNames} onClick={() => setActive(thread)}>
            <Row>
                <div className="col-auto align-self-center" style={{width: 42}}>
                    <NameCircle width={30} initials={thread.initials} style={{marginRight: 5}} />{' '}
                </div>

                <Col className="align-self-center">
                    <Row>
                        <Col className="col-auto align-self-center pr-0" style={{width: 140}}>
                            <p className="text-s font-weight bold text-dark mb--2 text-uppercase">
                                <small>
                                    {thread.identifier && thread.identifier.length > 14 ? thread.identifier.slice(0, 14) + '...' : thread.identifier}
                                </small>
                            </p>
                            <p className={`text-sm mb-0 archk-text-message-list-item-description ${selected_division._id ? 'mb-2' : 'mb-0'}`}>
                                <small>
                                    {thread.last_message_preview && thread.last_message_preview.length > 20 ? thread.last_message_preview.slice(0, 20) + '...' : thread.last_message_preview}
                                </small>
                            </p>
                            {!selected_division._id ? (
                            <p className="text-sm mb-0 archk-text-message-list-item-description mb-2 text-dark font-weight-bold">
                                <small>
                                    <ObjectFinder collection="divisions" _id={thread.division} />
                                </small>
                            </p>
                            ) : ''}
                        </Col>
                        <Col  className="pl-0">
                            <p className="text-sm text-muted mb-0 mt--2">
                                <small>
                                    {moment.unix(thread.last_message_date).format(thread.last_message_date > startOfDay ? 'h:mm A' : 'MMM Do')}
                                    {/* 12:45 PM */}
                                </small>
                            </p>
                            {!read ? (
                                <div className="text-right">
                                    <span className="archk-message-number"><small><i className="fas fa-comment" /></small></span>
                                </div>
                            ) : ''}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )

}


const mapStateToProps = state => {
	return {
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(TextsSidebarLeftListItem);