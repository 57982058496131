import { useEffect, useState, useCallback } from 'react';

import { FormGroup, Input } from 'reactstrap'
import SearchCollections from "components/system/Search/Collections";
import Circle from 'components/markup/loading/Circle'
import ApiError from 'components/markup/layout/ApiError'
import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api'

import { toast } from 'react-toastify'

const FinishTaskCustomField = ({task, onFinish}) => {

    const custom_field = task.task_template ? task.task_template.custom_field : ''

    const name = task.task_template ? task.task_template.name : task.name
    const description = task.task_template ? task.task_template.description : task.description

    const [err, setErr] = useState(false)
    const [answer, setAnswer] = useState('')
    const [customField, setCustomField] = useState(false)

    const fetchData = useCallback(async () => {
        const data = await api.custom_fields.findById(custom_field) 
        if(!data.success || !data.data) return setErr('Could not associate custom field, please refresh your page or contact support if the issue persists')
        setCustomField(data.data)
    }, [custom_field])

    const onSave = useCallback(async () => {
        if(!answer) return;

        const fields = { [customField._id]: answer };
        const linkedFields = customField.linked_fields;

        if(linkedFields && linkedFields.length) {
            linkedFields.forEach(f => {
                const found = f && f.answer_map && f.answer_map.find(aa => aa.answer === answer)
                if(found) {
                    fields[f.custom_field] = found.linked_answer
                }
            })
        }

        const data = await api.matters.update(task.matter, { task: task._id, custom_fields: fields })

        if(!data.data) return toast.error(`Something's not right, please try again.`)
        onFinish(data.data)
    }, [customField, task._id, answer, onFinish, task.matter])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])

    if(err) return <ApiError err={err} />
    if(!customField) return <Circle />

    let input = (
        <FormGroup>
            <label className='form-control-label'>{name}</label>
            {description ? <p className='text-sm mb-0'>{description}</p> : ''}
            <Input 
                type="text"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
            />
        </FormGroup>
    )

    console.log(customField)

    if(customField.type === 'select') {
        input = (
            <ReactSelect
                title={name}
                description={description}
                formGroup={true}
                placeholder=""
                onChange={(obj) => setAnswer(obj.value)}
                options={customField.answers.map(a => { return { value: a, label: a } })}
                value={answer}
            />    
        )
    } else if(customField.type === 'number') {
        input = (
            <FormGroup>
                <label className='form-control-label'>{name}</label>
                {description ? <p className='text-sm mb-0'>{description}</p> : ''}
                <Input 
                    type="number"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                />
            </FormGroup>
        )
    } else if(customField.type === 'date') {
        input = (
            <FormGroup>
                <label className='form-control-label'>{name}</label>
                {description ? <p className='text-sm mb-0'>{description}</p> : ''}
                <Input 
                    type="date"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                />
            </FormGroup>
        )
    } else if(customField.type === 'contact') {
        input = (
            <SearchCollections
                collection={'contacts'}
                title={name}
                description={description}
                placeholder="Search Contacts..."
                value={answer}
                onChange={(obj) => setAnswer(obj.value)}
                filter={{}}
            /> 
        )
    } else if(customField.type === 'location') {
        input = (
            <SearchCollections
                collection={'locations'}
                title={name}
                description={description}
                placeholder="Search Locations..."
                value={answer}
                onChange={(obj) => setAnswer(obj.value)}
                filter={{
                    division: task.division
                }}
            /> 
        )
    } else if(customField.type === 'user') {
        input = (
            <SearchCollections
                collection={'users'}
                title={name}
                description={description}
                placeholder="Search Users..."
                value={answer}
                onChange={(obj) => setAnswer(obj.value)}
                filter={{
                    divisions: { $in: [task.division] }
                }}
            /> 
        )
    }

    return input;
}

export default FinishTaskCustomField