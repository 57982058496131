import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Container } from "reactstrap";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'api';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import LoadingTable from 'components/markup/loading/Table.js';
import ObjectFinder from 'components/system/Objects/Finder';

import moment from 'moment'

import formatText from 'utils/formatText'

const ContactsView = ({match, viewing_user}) => {

    const [err, setErr] = useState(false)
    const [claimed, setClaimed] = useState(false);
    const [lead, setLead] = useState(null)

    const claimLead = useCallback(async () => {
        setClaimed('me');
        const saved = await api.leads.claim(lead._id)
        if(!saved.success) {
            if(saved.message && saved.message[0] === 'This lead has already been claimed') {
                setClaimed('other')
                toast.info('The lead you have tried to claimed was claimed first by another party.')
            } else {
                setClaimed(false)
                toast.error('Could not claim lead, please try again.')
            }
        }
    }, [lead])

    const fetchData = useCallback(async () => {
        const _lead = await api.leads.findById(match.params.lead_id)
        if(!_lead.data) return setErr(true);
        setLead(_lead.data)
        setClaimed(_lead.data.claimed_by === viewing_user._id ? 'me' : _lead.data.claimed_by ? 'other' : false)
    }, [match.params.lead_id, viewing_user._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <>

        <HeaderNavigation 
            title={'View Lead'}
            description={"Submission Info"}
            actionComponent={!lead ? <></> : (
                <div>
                    {!claimed ? <button onClick={claimLead} className="btn btn-info">Claim Lead</button> : ''}
                    <Link to={`/contacts/${lead.contact}`} className="btn btn-success">View File</Link>
                </div>
            )}
        />

        <Container fluid>

            <Helmet>
                <title>View Lead</title>
                <meta name="description" content="View Lead" />
            </Helmet>

           {err ? (
               <div className="alert alert-warning my-4 mb-0">
                   <i className="fas fa-exclamation-triangle mr-2 " />
                   The contact you are looking for cannot be found.
               </div>
           ) : !lead ? (
               <LoadingTable rows={10} />
           )  : (

                <div>
                    {claimed === 'me' ? <div className="alert alert-success mb-3">This lead was claimed by me</div> : ''}
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Lead Info</CardTitle>
                        </CardHeader>
                        
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: 150}}>Lead Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Fist Name</td>
                                        <td>{lead.given_name ? lead.given_name : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Last Name</td>
                                        <td>{lead.family_name ? lead.family_name : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Display Name</td>
                                        <td>{lead.display_name ? lead.display_name : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{lead.email ? lead.email : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone</td>
                                        <td>{lead.phone ? formatText(lead.phone).phone() : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Lead Source</td>
                                        <td>{lead.lead_source ? <ObjectFinder _id={lead.lead_source} collection="lead_sources" /> : '-'} </td>
                                    </tr>
                                    <tr>
                                        <td>Division</td>
                                        <td>{lead.division ? <ObjectFinder _id={lead.division} collection="divisions" /> : ''} </td>
                                    </tr>
                                    <tr>
                                        <td>Created At</td>
                                        <td>{moment.unix(lead.created_at).format('MM/DD/YYYY h:mm A')}</td>
                                    </tr>
                                    <tr>
                                        <td>Claimed At</td>
                                        <td>{lead.claimed_at ? moment.unix(lead.claimed_at).format('MM/DD/YYYY h:mm A') : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Claimed_by</td>
                                        <td className="text-uppercase">{!claimed ? '-' : claimed}</td>
                                    </tr>
                                    <tr>
                                        <td>ID</td>
                                        <td>{lead.id}</td>
                                    </tr>
                                    <tr>
                                        <td>Type</td>
                                        <td>{lead.type}</td>
                                    </tr>
                                    <tr>
                                        <td>url</td>
                                        <td>{lead.url ? lead.url : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Address Line 1</td>
                                        <td>{lead.address_line_1 ? lead.address_line_1 : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Address Line 2</td>
                                        <td>{lead.address_line_2 ? lead.address_line_2 : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>City</td>
                                        <td>{lead.city ? lead.city : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td>{lead.state ? lead.state : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Zip</td>
                                        <td>{lead.postal_code ? lead.postal_code : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Area Code</td>
                                        <td>{lead.area_code ? lead.area_code : '-'}</td>
                                    </tr>
                                                                       
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>

           )}
                
        </Container>

        </>

    );

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(ContactsView);

// {

//     "division": "6376b82424e2233278fa3571",
//     "type": "form submission",
//     "given_name": "sam",
//     "family_name": "hiller",
//     "email": "samhiller@gmail.com",
//     "id": "89",
//     "display_name": "Sam Hiller",
//     "date": "2024-05-05",
//     "contact": "66380a9d5084d15b3b3e937d",
//     "created_at": 1714948765,
//     "updated_at": 1715116630,
//     "company": "631e5c687418496a7ab0cb24",
//     "__v": 1
// }