import { useState, useCallback, useEffect, useMemo } from 'react';
import { Input } from 'reactstrap';
import api from 'api';

import debounce from 'lodash.debounce';

import ResultsSearch from './ResultsSearch';
import ResultsHistory from './ResultsHistory';
import Header from './Header';
import Footer from './Footer';

const NavbarSearch = () => {
    const [view, setView] = useState('search')
    const [val, setVal] = useState('')
    const [showResults, setShowResults] = useState(false);
    const [contacts, setContacts] = useState([])
    const [matters, setMatters] = useState([])
    const [pageHistories, setPageHistories] = useState([])
    const [hasLoaded, setHasLoaded] = useState(false)
    const [loadingContacts, setLoadingContacts] = useState(false) 
    const [loadingMatters, setLoadingMatters] = useState(false) 

    const fetchHistories = useCallback(async () => {
        const history = await api.page_histories.find();
        if(history.data) setPageHistories(history.data) 
    }, [])

    const onSetView = useCallback(async (_view) => {
        setView(_view)
        if(_view === 'history') fetchHistories()
    }, [fetchHistories])

    const onSearch = useCallback((_val) => {
        const fetchContacts = () => new Promise (async resolve => {
            const params = { searchString: _val, page: 1,  skip: 0,  sizePerPage: 15,  limit: 15 }
            setLoadingMatters(true)
            const query = await api.contacts.search({ ...params, filter: { deleted: false } })
            setLoadingMatters(false)
            if(query.data) setContacts(query.data.documents)

        })
        const fetchMaters = () => new Promise (async resolve => {
            const params = { searchString: _val, page: 1,  skip: 0,  sizePerPage: 15,  limit: 15 }
            setLoadingContacts(true)
            const query = await api.matters.search({ ...params, filter: { deleted: false } })
            setLoadingContacts(false)
            if(query.data) setMatters(query.data.documents)
        })
        
        fetchContacts()
        fetchMaters()

    }, [])

    const changeHandler = useCallback((_val) => {
        onSearch(_val);
    }, [onSearch])

    const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

    const onSetShowResults = useCallback((bool) => {
        setShowResults(bool)
        if(bool) {
            document.body.classList.add('noScroll');
        } else {
            document.body.classList.remove('noScroll');
        }
    }, [])

    const onSetVal = useCallback((e) => {
        const _val = e && e.target ? e.target.value : e;
        setVal(_val ? _val.toLowerCase() : _val)

        if(_val) onSetShowResults(true);

        debouncedChangeHandler(_val);
    }, [onSetShowResults, debouncedChangeHandler])

    useEffect(() => {
        if(!hasLoaded && showResults) {
            onSearch()
            setHasLoaded(true)
        }
    }, [onSearch, hasLoaded, showResults])
   
    return (
        <div className="archk-master-search">
            <div className="position-relative search-wrapper">
                <Input 
                    value={val}
                    onChange={onSetVal}
                    placeholder="Search Matters & Contacts..."
                    onFocus={() => {
                        onSetShowResults(true)
                        if(!showResults && view === 'history') fetchHistories();
                    }}
                />
                <i className="fas fa-search search-icon " />
            </div>

            {!showResults ? '' : (
                <>
                    <div className="results-backdrop" onClick={() => onSetShowResults(false)} />

                    <div className="results z-depth-3 pb-3">

                        <Header 
                            matters={matters}
                            contacts={contacts}
                            loadingContacts={loadingContacts}
                            loadingMatters={loadingMatters}
                            pageHistories={pageHistories}
                            view={view}
                            setShowResults={setShowResults}
                            onSearch={onSearch}
                        />
                     
                        {view === 'search' ? (
                             <ResultsSearch 
                                matters={matters}
                                contacts={contacts}
                                setShowResults={setShowResults}
                            />
                        ) : (
                            <ResultsHistory 
                                matters={matters}
                                contacts={contacts}
                                pageHistories={pageHistories}
                                setShowResults={setShowResults}
                            />
                        )}
                       
                        <Footer 
                            matters={matters}
                            contacts={contacts}
                            onSetView={onSetView}
                            view={view}
                        />

                    </div>

                </>
            )}
            
        </div>
    )
}

export default NavbarSearch;