import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Modal } from "reactstrap";

import { toast } from 'react-toastify';

import SearchCollections from "components/system/Search/Collections";

const ModalDivisionOverride = ({showModal, toggleModal, matter, selected_division, onAddSource}) => {

    const [newSource, setNewSource] = useState({ lead_source: null, lead_source_percent: 0 })

    const onSetNewSource = useCallback((field, value) => {
        const source = JSON.parse(JSON.stringify(newSource))
        source[field] = value;
        setNewSource(source)
    }, [newSource])

    const onAdd = useCallback(() => {
        if(!newSource.lead_source) return toast.info(`A source must be selected to add a lead source.`)
        if(newSource.lead_source_percent < 0) return toast.info(`Source percent cannot be less than 0`);
        if(newSource.lead_source_percent > 100) return toast.info(`Source percent cannot be greater than 100`);

        onAddSource(newSource)
        setNewSource({ lead_source: null, lead_source_percent: 0 })
        toggleModal()

    }, [newSource, onAddSource, toggleModal])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add Lead Source</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <SearchCollections
                        collection={'lead_sources'}
                        title="Select Source"
                        placeholder="Search..."
                        value={newSource.lead_source}
                        onChange={(obj) => onSetNewSource('lead_source', obj.value)}
                        filter={{
                            division: selected_division._id
                        }}
                    /> 
                    <FormGroup>
                        <label className='form-control-label'>Lead Source Percent</label>
                        <Input 
                            type="number"
                            value={newSource.lead_source_percent}
                            onChange={(e) => onSetNewSource('lead_source_percent', e.target.value)}
                        />
                    </FormGroup>

            </div>


            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onAdd}>
                    Add Source
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);