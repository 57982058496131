import { connect } from 'react-redux';

import { useCallback, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';

import MainHeader from './Main/Header';
import Sidebar from './Sidebar';

import MainContent from './Main/Content';
import MainNavigation from './Main/Navigation';

import api from 'api';

function reducer(state, action) {
    const current = JSON.parse(JSON.stringify(state))
    if(action.type === 'add') {
        current.push(action.payload)
        return current
    } else if(action.type === 'update') {
        let index = current.findIndex(i => i.id === action.payload.id)
        current[index].status = action.payload.status
        current[index].data = action.payload.data
        return current;
    } else if(action.type === 'delete') {
        let index = current.findIndex(i => i.id === action.payload)
        current.splice(index, 1)
        return current;
    } else if(action.type === 'all') {
        return action.payload;
    }

    return []
}


const AI = ({ selected_matter, showAI, setShowAI }) => {

    const [messages, setMessages] = useReducer(reducer, []);
    
    const [selectedAssistant, setSelectedAssistant] = useState(false)
    const [tab, setTab] = useState(0)
    const [selectedThread, setSelectedThread] = useState(null)

    const [threads, setThreads] = useState(null);

    const [currentMessage, setCurrentMessage] = useState('')


    const onAddThread = useCallback((_thread) => {
        const _threads = JSON.parse(JSON.stringify(threads));
        _threads.unshift(_thread)
        setThreads(_threads)
    }, [threads])
    
    const onAddMessage = useCallback((_message) => {
        // const _messages = JSON.parse(JSON.stringify(messages));
        // _messages.unshift(_message)
        setMessages({ type: 'add', payload: _message })
    }, [])

    const fetchMessages = useCallback(async () => {
        setMessages({ type: 'all', payload: [] })

        const messages = await api.ai_threads.messages(selected_matter._id, selectedThread._id)
        if(!messages.success) return toast.info(`Could not fetch messages for this thread at this time, please try again.`)
        setMessages({ type: 'all', payload: messages.data })

    }, [selectedThread, selected_matter._id])

    useEffect(() => {
        document.body.classList.add('noScroll')

        return () => {
            document.body.classList.remove('noScroll')
        }
    })

    useEffect(() => {
        if(selectedThread) {
            fetchMessages()
        } else {
            setMessages({ type: 'all', payload: [] })
        }
    }, [fetchMessages, selectedThread])
    
    useEffect(() => {
        if(messages) {
            const container = document.querySelector('.archk-main-content-thread')
            container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
        }
    }, [fetchMessages, messages, selectedThread])

    if(!showAI) return <></>

    return (
        <div className="archk-ai">

            <div className="archk-ai-blackout" />

            <div className="archk-ai-main z-depth-5">

                <MainHeader 
                    setShowAI={setShowAI} 
                    setSelectedThread={setSelectedThread} 
                />

                <Sidebar 
                    selectedThread={selectedThread} 
                    setSelectedThread={setSelectedThread} 
                    setSelectedAssistant={setSelectedAssistant} 
                    threads={threads}
                    setThreads={setThreads}
                />

                <div className='archk-ai-main-content'>

                    <MainNavigation 
                        tab={tab} 
                        setTab={setTab} 
                        setSelectedAssistant={setSelectedAssistant}
                        selectedAssistant={selectedAssistant}
                    />

                    <MainContent 
                        messages={messages}
                        currentMessage={currentMessage}
                        setCurrentMessage={setCurrentMessage}
                        onAddThread={onAddThread}
                        selectedThread={selectedThread}
                        setSelectedThread={setSelectedThread}
                        onAddMessage={onAddMessage}
                        selectedAssistant={selectedAssistant}
                    />

                </div>

            </div>

        </div>
    )
}


const mapStateToProps = state => {
	return {
        selected_matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(AI)