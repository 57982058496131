import { useCallback, useEffect, useState } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';

import { Modal } from "reactstrap";
import ReactSelect from 'components/functional/inputs/ReactSelect';

const ModalSelectPhone = ({showModal, toggleModal, contact, onSelect}) => {

    const [phone, setPhone] = useState(contact ? contact.phone : '');

    const onConfirm = useCallback(() => {
        const obj = { ...contact, phone }
        onSelect({obj})
        toggleModal()
    }, [contact, phone, onSelect, toggleModal])

    useEffect(() => {
        if(contact) setPhone(contact.phone)
    }, [contact])

    if(!contact) return <></>

    let options = []
    if(contact.phone) options.push({label: contact.phone, value: contact.phone});
    if(contact.phone_2) options.push({label: contact.phone_2, value: contact.phone_2});
    if(contact.phone_3) options.push({label: contact.phone_3, value: contact.phone_3});

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="sm"
        >

           <div className="modal-header">
               <h5 className="modal-title">Select Phone</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body">
                <ReactSelect
                    value={phone}
                    options={options}
                    onChange={(e) => setPhone(e.value)}
                />
           </div>

           <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onConfirm}>
                    Select Phone
                </button>
           </div>

        </Modal>

    )
}

const SendPhoneTo = ({editingRecipients, setEditingRecipients, to, onToChange, foundMatter}) => {

    const [contactToSelect, setContactToSelect] = useState(null)

    const onRemoveTo = useCallback(() => {
        onToChange(null)
    }, [onToChange])

    const onAddTo = useCallback((obj, confirmed) => {
        const contact = obj.obj

        if(!confirmed) {
            let phones = 0;
            if(contact.phone) phones++
            if(contact.phone_2) phones++
            if(contact.phone_3) phones++

            if(phones > 1) return setContactToSelect(contact)
        }

        onToChange(contact)
    }, [onToChange])

    if(editingRecipients) return (
        <div className="to-container editing bg-secondary">

            <ModalSelectPhone 
                contact={contactToSelect}
                showModal={contactToSelect ? true : false}
                toggleModal={() => setContactToSelect(null)}
                onSelect={(contact) => onAddTo(contact, true)}
            />

            <div className='section'>
                <span style={{display: 'inline-block', width: 80}}>Recipients: {' '}</span>
                {to ? (
                    <span className='span' key={to._id}>{to.phone}
                        <b><i onClick={() => onRemoveTo(to._id)} className="fas fa-times" /></b>
                    </span>
                ) : (
                    <span className='span bg-info text-white' >No Recipients Selected
                        <b> </b>
                    </span>
                )}
            </div>

            <div className='section'>
                <span style={{display: 'inline-block', width: 110}}>Select Contact: {' '}</span>

                {foundMatter.parties.map(p => (
                    !p.contact ? <></> : 
                    to && to._id === p.contact._id ? <span key={p.contact._id}></span> : 
                    p.contact.phone ? (
                        <Badge 
                            key={p.contact._id}
                            onClick={() => onAddTo({obj: p.contact})} 
                            color="success" 
                            className='cursor-pointer mb-2 mr-2'
                        >
                            {p.workflow_contact.name} - {p.contact.display_name} <i className="fas fa-plus ml-3" />
                        </Badge>
                    ) : (
                        <span key={p.contact._id}>
                            <Badge 
                                id={`archk-send-phone-no-phone-${p.contact._id}`}
                                color="warning" 
                                className='mr-2 mb-2'
                            >
                                {p.workflow_contact.name} - {p.contact.display_name}
                            </Badge>

                            <UncontrolledTooltip delay={0} placement="bottom" target={`archk-send-phone-no-phone-${p.contact._id}`}>
                                An phone # was not found on this record.
                            </UncontrolledTooltip>

                        </span>
                    )
                
                ))}

            </div>

           
        </div>
    )

    return (
        <div className="to-container " onClick={() => setEditingRecipients(true)}>
            Recipient: {' '}

            {to ? (
                <span className='span' key={to._id}>{to.display_name ? to.display_name : to.phone}</span>
            ): (
                <Badge color="warning" className='cursor-pointer ml-3'>Click To Add</Badge>
            )}

        </div>
    )

}


export default SendPhoneTo
