import SendEmail from 'components/system/Email'

const FinishTaskSendEmail = ({task, onFinish}) => {
    return <div className='py-3'>
        <SendEmail 
            matter={task.matter}
            task={task._id}
            division={task.division}
            emailTemplate={task.task_template ? task.task_template.email_template : ''}
            onSent={onFinish}
        />
    </div>
}

export default FinishTaskSendEmail