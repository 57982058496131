import { Modal } from "reactstrap";

import SearchCollections from 'components/system/Search/Collections';

const ModalSendTextTemplate = ({showModal, toggleModal, matter, onTemplateChange}) => {

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
           zIndex={1510}
        >

            <div className="modal-header">
                <h5 className="modal-title">Select Template</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <SearchCollections
                    collection="text_templates" 
                    value={null}
                    onChange={(obj) => {
                        onTemplateChange(obj.obj, matter)
                        toggleModal()
                    }}
                    filter={{
                        division: matter.division
                    }}
                /> 
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}

export default ModalSendTextTemplate