import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle } from 'reactstrap';


import DatePicker from 'components/markup/inputs/Day';

import moment from 'moment';

const ModuleBKOverview = ({ data, onInputChange }) => {


    const getDateField = useCallback((title, field, editable, showTimeSelect) => {
        if(!editable) return (
            <tr>
                <td>{title}</td>
                <td className='text-right'>{data[field] ? moment(data[field]).format("MM/DD/YYYY") : ''}</td>
            </tr>
        )

        return (
            <tr style={{borderTop: 'none'}}>
                <td style={{borderTop: 'none'}}>{title}</td>
                <td style={{borderTop: 'none'}}>
                    <DatePicker 
                        value={data[field] ? moment(data[field]).toDate() : ''} 
                        onChange={day => onInputChange(field, day)} 
                        hideFormGroup={true}
                        showTimeSelect={showTimeSelect ? showTimeSelect : false}
                        dateFormat={showTimeSelect ? "Pp" : undefined}
                    />
                </td>
            </tr>
        )

    }, [data, onInputChange])

    return (
        <div>
            <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>Major Dates & Times</CardTitle>
                </CardHeader>
                <div className='table-responsive'>
                    <table className='table' style={{borderTop: 'none'}}>
                        <tbody>
                            {getDateField('Date Filed', 'date_filed', true)}
                            {getDateField('341 Date', 'hearing_341_date', true)}
                            {data.chapter === 13 ? getDateField('Confirmation Date', 'hearing_confirmation_date', true) : ''}
                            {getDateField('Show Cause Date', 'date_show_cause', true)}
                            {getDateField('Dismissed On', 'date_dismissed', true)}
                            {getDateField('Discharged On', 'date_discharged', true)}
                            {getDateField('Converted On', 'date_chapter_conversion', true)}
                        </tbody>
                    </table>
                </div>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className='mb-0'>Payment Dates</CardTitle>
                </CardHeader>
                <div className='table-responsive'>
                    <table className='table' style={{borderTop: 'none'}}>
                        <tbody>
                            {getDateField('First Payment Due', 'date_first_payment_due', true)}
                            {getDateField('Final Payment Due', 'date_final_payment_due', true)}
                            {getDateField('FF Paid In Full On', 'date_ff_paid_in_full', true)}
                        </tbody>
                    </table>
                </div>
            </Card>

            {data.chapter === 13 ? (
                <Card>
                    <CardHeader>
                        <CardTitle className='mb-0'>Chapter 13 Dates</CardTitle>
                    </CardHeader>
                    <div className='table-responsive'>
                        <table className='table' style={{borderTop: 'none'}}>
                            <tbody>
                                {getDateField('Plan Filed', 'date_plan_filed', true)}
                                {getDateField('Confirmed On', 'date_confirmed', true)}
                                {getDateField('Claims Deadline', 'date_claims_deadline', true)}
                                {getDateField('Claims Deadline (gov)', 'date_claims_deadline_gov', true)}
                                {getDateField('Probation Date', 'date_probation', true)}
                                {getDateField('Last Letter Date', 'date_last_letter', true)}
                                {getDateField('Closing Order Date', 'date_closing_order', true)}
                                {getDateField('Cleared Date', 'date_cleared', true)}
                                {getDateField('Payment Order Served', 'date_payment_order_served', true)}
                            </tbody>
                        </table>
                    </div>
                </Card>
            ) : ''}
        </div>

    )
}

const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


