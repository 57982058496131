import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { Alert, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import moment from 'moment';
import api from 'api';
import formatText from 'utils/formatText';

import Circle from 'components/markup/loading/Circle';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import ObjectFinder from 'components/system/Objects/Finder';
import { formatCurrency } from 'utils/currency';


const ContactsAll = ({match}) => {

    const [ redirect, setRedirect ] = useState(null);
    const [ data, setData ] = useState(null);
    const [ error, setError ] = useState(false)

    const fetchData = useCallback(async () => {
        const _data = await api.contacts.overview(match.params._id)
        if(!_data.success) return setError(_data.message)

        if(_data.data.matters.length === 1) {
            return setRedirect(`/billing/${match.params._id}/${_data.data.matters[0].id}`)
        }

        _data.data.matters.sort((a, b) => a.billing_total > b.billing_total ? -1 : 1)
        setData(_data.data)
    }, [match.params._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(error) {
        return (
            <Container fluid >
                <Alert color="warning"><i className="fas fa-info-circle mr-2" /> The contact you are searching for could not be found..</Alert>
            </Container>
        )
    }
   
    if(redirect) return <Redirect to={redirect} />
    if(!data) return <Circle className="py-5" />

    const title = 'Select A Matter'
    const description = formatText().contactIdentifier(data.contact)

    return (

        <>

        <HeaderNavigation 
            title={title}
            description={description}
            actionComponent={(
               <div>
                    <Link to="/billing" className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> Search Contacts
                    </Link>
               </div>
            )}
        />

        <Container fluid>
    
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={title} />
            </Helmet>


                {!data.matters.length ? (
                    <p className="text-center py-4">
                        <span className="border bg-secondary px-5 py-4 rounded "><i className="fas fa-info-circle mr-2 text-info" /> No matters have been created for this contact.</span>
                    </p>
                ) : (
                    <Row>
                        {data.matters.map(matter => (
                            <Col md={3}>
                                <Link to={`/billing/${match.params._id}/${matter.id}`}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle className="mb-0">
                                                <ObjectFinder collection="workflows" _id={matter.workflow} />
                                            </CardTitle>
                                        </CardHeader>

                                        <CardBody>
                                            <p className="mb-0">{matter.name}</p>
                                            <p className="text-sm mb-0">Step: {matter.current_step_name}</p>
                                            <p className="text-sm mb-0">Created: {moment.unix(matter.created_at).format('MMMM Do, YYYY')}</p>
                                        </CardBody>

                                        <CardFooter>
                                            <p className="text-sm mb-0">
                                                <span 
                                                    className={
                                                        matter.billing_paid && matter.billing_paid === matter.billing_total ? 'text-success' : 
                                                        matter.billing_paid ? 'text-info' : 
                                                        ''
                                                    }
                                                >
                                                    {formatCurrency(matter.billing_paid)} 
                                                </span>

                                                {' / '}

                                                <span className={matter.billing_total ? 'text-success' : ''}>
                                                    {formatCurrency(matter.billing_total)}
                                                </span>
                                            </p>
                                        </CardFooter>

                                    </Card>
                                </Link>
                            </Col>
                        ))}

                    </Row>
                )}


        </Container>

        </>
    )

}

export default ContactsAll