import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';

const MattersViewMainTasks = ({matter, selected_division, fetchMatterEvents}) => {

    const [matter_designated_value , setValue] = useState(matter.matter_designated_value)
    const [affiliate_multiplier_volume , setVolume] = useState(matter.affiliate_multiplier_volume)
    const [affiliate_multiplier_price , setPrice] = useState(matter.affiliate_multiplier_price)

    const onSave = useCallback(async () => {

        const saved = await api.matters.value(matter._id, {
            matter_designated_value       : parseFloat(matter_designated_value),
            affiliate_multiplier_volume   : parseFloat(affiliate_multiplier_volume),
            affiliate_multiplier_price    : parseFloat(affiliate_multiplier_price),
        })

        if(!saved.success) return toast.error(`Something went wrong updating this matters value, please try again`);
        toast.success(`Matter Updated Successfully`)

    }, [affiliate_multiplier_price, affiliate_multiplier_volume, matter_designated_value, matter._id])

    return (
        <div className="section pt-0">
            <Card className='card-no-shadow'>

                <CardHeader className="py-3">
                    <CardTitle className="mb-0">Matter Value</CardTitle>
                </CardHeader>

                <CardBody>
                    {selected_division.affiliate_value_type === 'invoice' ? (
                        <p className='text-sm mb-0'>This matter is set to display value from the total of all invoices. Matter value must be updated there.</p>
                    ) : selected_division.affiliate_value_type === 'designated_value' ? (
                        <FormGroup>
                            <label className='form-control-label'>Designate Matter Value</label>
                            <Input 
                                value={matter_designated_value}
                                type="number"
                                onChange={e => setValue(e.target.value)}
                            />
                        </FormGroup>
                    ) : (
                        <div>
                            <FormGroup>
                                <label className='form-control-label'>Set Matter Volume</label>
                                <Input 
                                    value={affiliate_multiplier_volume}
                                    type="number"
                                    onChange={e => setVolume(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className='form-control-label'>Set Price Per Volume</label>
                                <Input 
                                    value={affiliate_multiplier_price}
                                    type="number"
                                    onChange={e => setPrice(e.target.value)}
                                />
                            </FormGroup>
                        </div>
                    )}
                </CardBody>

             
                <CardFooter>
                    <button onClick={onSave} className='btn btn-success'>Update Value</button>
                </CardFooter>
                
            </Card>

        </div>
    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainTasks);