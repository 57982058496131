import { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Alert, Card, Container } from "reactstrap";

import Table from 'components/functional/tables/Standard';
import NameCircle from 'components/markup/misc/NameCircle';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';


import api from 'api';
import formatText from 'utils/formatText';


const ContactsAll = ({match, device}) => {

    const title = "Billing Search"
    const type = match.params.type;

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false)


    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(type === 'new') params.filter.created_at =  { $gte: Math.floor(new Date() / 1000) - ( 86400 * 3 ) }

        const query = await api.contacts.search({ ...params, isCSV })
        
        if(query.data) {
            return resolve(query.data)
        } else {
            setError(true)
        }
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div>Name</div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <Link className="text-capitalize"  to={`/billing/${row.id ? row.id : row._id}`}>
                        <NameCircle width={27} contact={row} style={{marginRight: 5}} />{' '}
                        {row.display_name}
                    </Link>
                )
                
            },
        },
        {
            dataField: "email",
            text: 'Email',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                row.email ? <span><i className="text-muted fas fa-envelope mr-2" /> {row.email} </span>: '-'
            )
        },
        {
            dataField: "phone",
            text: 'Phone',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' :
                row.phone ? <span><i className="text-muted fas fa-mobile mr-2" /> {formatText(row.phone).phone()} </span>: '-'
            )
        },

        
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 140},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <Link className="btn btn-success btn-sm" to={`/billing/${row.id ? row.id : row._id}`}>View Billing</Link>
                    </div>
                )
            }
        },
    ]

    if(error) {
        return (
            <Container fluid >
                <Alert color="danger">Something went wrong, please refresh your page or try again later.</Alert>
            </Container>
        )
    }

    return (

        <>

        <HeaderNavigation 
            title={title}
        />

        <Container fluid>
    
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={title} />
            </Helmet>

            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Contacts..."

                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>


        </Container>

        </>
    )

}

export default ContactsAll