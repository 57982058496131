import { useCallback, useState } from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import { Container } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import Create from './Main'

const MattersCreate = () => {

    const [redirect, setRedirect] = useState(false);

    const onCreated = useCallback((matter) => {
        setRedirect(`/matters/${matter.id}`)
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (

        <>
       
        <Helmet>
            <title>{`Create A Matter`}</title>
            <meta name="description" content="Matters" />
        </Helmet>

        <HeaderNavigation 
            title="Matters"
            description={`Create New`}
            actionComponent={(
                <Link to="/matters" className="btn btn-outline-info"><i className="fas fa-arrow-left mr-2 " /> All Matters</Link>
            )}
        />

        <Container fluid>
            <Create onCreated={onCreated} />
        </Container>

        </>
    )

}

export default MattersCreate;