import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';

import api from 'api';

import UnfinishedTaskTable from '../UnfinishedTaskTable'

const MatterViewMainDocumentsAll = ({matter}) => {

    const [err, setErr] = useState(null)
    const [tasks, setTasks] = useState(null)

    const findTasks = useCallback(async () => {

        let templates = [];
        matter.workflow_step.tasks.forEach(t => {
            if(t.task_template) templates.push(t.task_template._id)
        })

        const tasks = await api.tasks.search({
            skip: 0,
            limit: 100,
            searchString: '',
            filter: {
                matter: matter._id,
                workflow_step: matter.workflow_step._id,
                task_template: { $in: templates }
            }
        })

        if(!tasks.success) return setErr(tasks.message)
        if(tasks.data) setTasks(tasks.data.documents)
    }, [matter._id, matter.workflow_step.tasks, matter.workflow_step._id])

    useEffect(() => {
        findTasks()
    }, [findTasks])

    return tasks && tasks.length  ? (
        <UnfinishedTaskTable 
            err={err}
            tasks={tasks}
            findTasks={findTasks}
            isStepTaskView={true}
        />
    ) : (
        <Card className='card-no-shadow'>

            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Task</th>
                            <th>Assigned To</th>
                            <th>Due</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody></tbody>
                    <tr>
                        <td><i className="fas fa-check mr-2 text-success" />No Tasks Found</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </Card>
    )
  
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocumentsAll);