import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

import PipelineColumn from "./PipelineColumn";

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Pipeline = ({viewing_user, selected_division}) => {

    const [pipeline, setPipeline] = useState(null);
    const [pipelines, setPipelines] = useState(null);
    const [hideActions] = useState(false);
    const [hideLastNote, setHideLastNote] = useState(false);
    
    const [sort] = useState('Incoming Communication');

    const sortOptions = 
        sort === 'Incoming Communication' ? { incoming_communication_at: -1, _id: -1 } : 
        sort === 'Time On Step' ? { current_step_start: -1, _id: -1 } : 
        { created_at: -1, _id: -1 }

    const onSetPipeline = useCallback((pipeline) => {
        if(pipeline) cookies.set('archk-pipeline', pipeline._id, { path: '/' });
        setPipeline(pipeline)
    }, [])


    const fetchPipelines = useCallback(async () => {
        const data = await api.pipelines.findByUser(viewing_user._id)
        if(data.data) {
            let foundByCookie = false;
            const saved = cookies.get('archk-pipeline')
            if(saved) {
                foundByCookie = data.data.find(d => d._id === saved)
            }
            if(selected_division._id) data.data = data.data.filter(d => d.division === selected_division._id)
            setPipeline(foundByCookie ? foundByCookie : data.data[0])
            setPipelines(data.data)
        }
    }, [viewing_user._id, selected_division._id])

    useEffect(() => {
        fetchPipelines()

        document.body.classList.add('bg-secondary')
        return () => {
            document.body.classList.remove('bg-secondary')
        }
    }, [fetchPipelines])

    if(!pipelines) return <Circle className="py-6" />

    return (

        <>

        <HeaderNavigation 
            title="Pipeline"
            description={`Division: ${selected_division && selected_division.name ? selected_division.name : 'All'}`}
            background="bg-white"
            leftColSize={5}
            actionComponent={pipeline ? (
                <div >
                    
                    <UncontrolledDropdown>
                        <DropdownToggle color={'purple'}>
                            {pipeline.name} <i className="fas fa-hand-pointer" />
                        </DropdownToggle>
                        <DropdownMenu>
                            {pipelines.map((p, i) => (
                                <DropdownItem key={p._id + i} onClick={e => onSetPipeline(p)}>{p.name}</DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* <UncontrolledDropdown>
                        <DropdownToggle color={'outline-info'}>
                            {sort ? sort : 'Sort'}  <i className="fas fa-sort-amount-down ml-2" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => setSort('Recent Communication')}>Incoming Communication</DropdownItem>
                            <DropdownItem onClick={e => setSort('Time On Step')}>Time On Step</DropdownItem>
                            <DropdownItem onClick={e => setSort('Creation Date')}>Creation Date</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                   
                    <UncontrolledDropdown>
                        <DropdownToggle color="outline-warning">
                            Settings  <i className="fas fa-cogs ml-2" />
                        </DropdownToggle>
                        <DropdownMenu>
                            {/* <DropdownItem onClick={e => setHideActions(!hideActions)}>{hideActions ? 'Show Quick Actions' : 'Hide Quick Actions'}</DropdownItem> */}
                            <DropdownItem onClick={e => setHideLastNote(!hideLastNote)}>{hideLastNote ? 'Show Last Note' : 'Hide Last Note'}</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    {/* <button className="btn btn-outline-success">Display <i className="fas fa-laptop ml-2 " /></button> */}
                </div>
            ) : <></>}
        />

        <Container className="ful  bg-white mt--4 pt-4" fluid>
    
            <Helmet>
                <title>{`Pipeline`}</title>
                <meta name="description" content="Pipeline" />
            </Helmet>

            <div className="archk-pipeline">

                <Row className="archk-pipeline-row">

                    {!pipeline ? (
                        <div>
                            <p className="text-sm mb-0 text-center">No Pipelines Found For Current Division</p>
                        </div>
                    ) : pipeline.workflow_steps.map((workflow_step, i) => (
                        <PipelineColumn
                            key={workflow_step + i}
                            sortOptions={sortOptions}
                            hideActions={hideActions}
                            hideLastNote={hideLastNote}
                            viewing_user={viewing_user}
                            workflow_step={workflow_step}
                            header={(
                                <div className="bg-danger  px-4 py-2">
                                    <h3 className="text-white pt-1">Docs Needed (16) <i className="fas fa-sort-amount-down float-right pt-2 " /></h3>
                                </div>
                            )}
                        />
                    ))}

                </Row>
            </div>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
        company: state.company,
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Pipeline);
