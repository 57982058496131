import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader} from 'reactstrap';

import Circle from 'components/markup/loading/Circle'

import api from 'api';
import moment from 'moment'

import { toast } from 'react-toastify';

import ModalToggler from 'components/functional/modals/Toggler'
import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'

import Edit from '../Edit'
import Finish from '../Finish'

import ConfirmationModal from 'components/functional/modals/Confirmation'
import { getUrlParameter } from 'utils/urls';


const MyTasks = ({matter, finished, items, modalIndex, onItemChange, hideAddNew, cardHeader}) => {

    const [err, setErr] = useState(null)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [outstandingItems, setOutstandingItems] = useState(items ? items : null)

    const findTasks = useCallback(async () => {
        if(items) return setOutstandingItems(items)
        setOutstandingItems(null);
        const response = await api.outstanding_items.findByMatter(matter._id, finished)
        if(!response.success) return setErr(response.message)
        if(response.data) setOutstandingItems(response.data)

        setTimeout(() => {
            setHasLoaded(true)
        }, 2000)
    }, [items, matter._id, finished])

    const onDelete = useCallback(async (item) => {
        const deleted = await api.outstanding_items.finish(item._id, {
            outcome: `Manually Deleted`
        })
        if(!deleted.success) return toast.error(`Could not delete item at this time, please try again.`)
        findTasks()
        if(onItemChange) onItemChange('deleted', deleted)
    }, [findTasks, onItemChange])

    useEffect(() => {
        findTasks()
    }, [findTasks, items])

    if(err) return (
        <Card>
            <CardBody>
                <p className='text-sm mb-0'><i className="fas fa-exclamation-triangle text-warning mr-2" /> An error occurred, please refresh your page.</p>
            </CardBody>
        </Card>
    )

    return (
        <Card className='card-no-shadow'>

            {cardHeader ? <CardHeader>{cardHeader}</CardHeader> : ''}

            {!outstandingItems ? (
                <CardBody><Circle /></CardBody>
            ) : (

                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Item</th>
                                {!finished ? <th>Assigned To</th> : ''}
                                <th>Contact</th>
                                <th>{finished ? 'Resolved At' : 'Due'}</th>
                                <th>{finished ? 'Outcome' : 'Next Follow Up'}</th>
                                {finished ? <td className='text-right' style={{width: 120}}>Finished By</td> : <td></td>}
                            </tr>
                        </thead>

                        <tbody>
                            {!outstandingItems.length ? ( 
                                <tr>
                                <td><i className="fas fa-check mr-2 text-success" />No Outstanding Items Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            ) : outstandingItems.map((item, i) => {

                                const overdue = false;

                                return (
                                    <tr key={i}>
            
                                        <td className={overdue ? 'text-dark fontw-weight-bold' : ''}>
                                            {item.name}
                                            {item.description ? <div className='text-muted'>{item.description}</div> : ''}
                                        </td>

                                        {!finished ? (
                                            <td>
                                                {item.assigned_to.map((a, ii) => (
                                                    <span key={ii}>{ii !== 0 ? ', ' : ''} <ObjectFinder collection="users" _id={a} /></span>
                                                ))}
                                            </td>
                                        ) : ''}
                                        
            
                                        <td className={overdue ? 'font-weight-bold text-danger' : ''}>
                                            {item.contact ? <ObjectFinder collection="contacts" _id={item.contact} /> : ''}
                                        </td>
            
                                        <td className={overdue ? 'font-weight-bold text-danger' : ''}>
                                            {finished ? (
                                                <div style={{width: 80}}>
                                                    <div>{moment.unix(item.finished_at).format('MMM Do')}</div>
                                                    <div>{moment.unix(item.finished_at).format('h:mm A')}</div>
                                                </div>
                                            ) : item.due_date ? (
                                                <div style={{width: 80}}>
                                                    <div>{moment.unix(item.due_date).format('MMM Do')}</div>
                                                    <div>{moment.unix(item.due_date).format('h:mm A')}</div>
                                                </div>
                                            ) : ''}
                                        </td>
                                        <td className={overdue ? 'font-weight-bold text-danger' : ''}>
                                            {finished ? (
                                                <div style={{width: 200}}>{item.outcome}</div>
                                            ) : item.next_follow_up_at ? (
                                                <div style={{width: 80}}>
                                                    <div>{moment.unix(item.next_follow_up_at).format('MMM Do')}</div>
                                                    <div>{moment.unix(item.next_follow_up_at).format('h:mm A')}</div>
                                                </div>
                                            ) : ''}
                                        </td>
            
                                        {finished ? (
                                            <td className='text-right' >
                                                {item.finished_by ? <ObjectFinder collection="users" _id={item.finished_by} /> : 'System'}
                                            </td>
                                        ) : (
                                            <td className='text-right'>
                                                <div className='text-right ml-auto' style={{width: 120}}>
                                                    <TableIcons
                                                        icons={[
                                                            {
                                                                icon: 'fas fa-times',
                                                                color: 'danger', 
                                                                component: ConfirmationModal,
                                                                title: `Delete Outstanding Item`,
                                                                body: (
                                                                    <span>
                                                                        Are you sure you wish to delete the item: <b className='text-underline text-dark'>{item.name}</b>? This cannot be undone.
                                                                    </span>
                                                                ),
                                                                onConfirmation: () => onDelete(item),
                                                                wrapper: ModalToggler, 
                                                                zIndex: modalIndex ? modalIndex : undefined
                                                            },
                                                            {
                                                                icon: 'fas fa-edit',
                                                                color: 'info', 
                                                                component: Edit,
                                                                item,
                                                                findTasks,
                                                                wrapper: ModalToggler, 
                                                                showModal: getUrlParameter('selectedItem') === item._id && !hasLoaded,
                                                                modalIndex: modalIndex ? modalIndex : undefined,
                                                                onItemChange
                                                            },
                                                            {
                                                                icon: 'fas fa-check',
                                                                color: 'success', 
                                                                component: Finish,
                                                                item,
                                                                findTasks,
                                                                wrapper: ModalToggler, 
                                                                modalIndex: modalIndex ? modalIndex : undefined,
                                                                onItemChange
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                            </td>
                                        )}
            
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {outstandingItems && !hideAddNew ? (
                <CardFooter className="text-right">
                    <ModalToggler findTasks={findTasks} component={Edit} >
                        <button className='btn btn-outline-success btn-sm'>
                            Add New Item <i className="fas fa-plus ml-2" />
                        </button>
                    </ModalToggler>
                    
                </CardFooter>
            ) : ''}
        </Card>
    )
  
}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MyTasks);