import { Helmet } from 'react-helmet';
import { Card, Container, CardBody } from "reactstrap";

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen'

const SigningAccess = () => (
    <Container fluid>

        <Helmet>
            <title>{`Access`}</title>
            <meta name="description" content="Signing Access" />
        </Helmet>

        <DashHeaderOpen 
            col1Size={5}
            col2Size={7}
            title={<span>Dashboard</span>}
            breadcrumb_1="Signing"
            icon="fas fa-edit"
        />

        <Card className="card-color card-primary">    
            <CardBody>
                <p>Zap Sign is the fastest way of securely obtaining electronically signed documents from your clients.</p>
                <p>Upload a pdf, mark where you want it signed, and send it off to your clients via text and email.</p>
                <p className="mb-0">For access to the Zap Sign please send an email to john@zapreports.com</p>
            </CardBody>
        </Card>

    </Container>
)

export default SigningAccess;