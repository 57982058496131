
import { useState, useCallback } from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/functional/modals/Confirmation'

import { toggleStandardLoader } from 'store/functions/system/system';
import { toast } from 'react-toastify'

import api from 'api';

import ModalConfirmCode from './ModalConfirmCode'

const AccountMFAEmail = ({viewing_user}) => {

    const hasMFA = viewing_user.mfa.email;

    const [showModalConfirm, setShowModalConfirm] = useState(null);
    const [showModal, setShowModal] = useState(null);

    const onStartMFAEmail = useCallback(async () => {

        toggleStandardLoader(true)
        const sent = await api.auth.mfa.send({
            _id: viewing_user._id, 
            company: viewing_user.company, 
            type: 'email'
        })
        toggleStandardLoader(false);

        if(sent.success) return setShowModal(true);

        toast.error(`Something's not right, please try again.`)

    }, [viewing_user._id, viewing_user.company])

    return (
        <>
        <ModalConfirmCode 
        showModal={showModal}
        toggleModal={() => setShowModal(false)}
        type="email"
        />

        <ConfirmationModal 
            prevButtonText="Cancel"
            nextButtonText="Send Code"
            showModal={showModalConfirm}
            toggleModal={() => setShowModalConfirm(false)}
            title={hasMFA ? "Disable Email MFA" : "Enable Email MFA"}
            onConfirmation={() => onStartMFAEmail(true)}
            body={(
                <span>
                    {hasMFA ? (
                        'By removing email as an MFA method you will not be prompted to input a security code emailed to your account on login. It is recommended to keep at least 1 MFA method active at all times.'
                    ) : (
                        'By adding email as an MFA method you will be prompted to enter a security code emailed to your address on file before every login.'
                    )}
                    
                </span>
            )}
        />

        <h3>Email</h3>

        {hasMFA ? (
            <div>
                <p className="text-sm mb-0"><i className="fas fa-check mr-2 text-success " /> Email MFA is currently set up.</p>
                <p className="text-sm mb-0 font-weight-bold">{viewing_user.email}</p>

                <button className="btn btn-outline-danger mt-4" onClick={() => setShowModalConfirm(true)}>
                    <i className="fas fa-envelope mr-2 " /> Remove
                </button>

                <p className="text-sub  mb-0 font-weight-bold">(not recommended)</p>
            </div>
        ) : (
            <div>
                <p className="text-sm mb-0">Email MFA is currently not set up.</p>
                <p className="text-sm mb-0 font-weight-bold">{viewing_user.email}</p>
                
                <button className="btn btn-success mt-4" onClick={() => setShowModalConfirm(true)}>
                    <i className="fas fa-envelope mr-2 " /> Enable
                </button>

                <p className="text-sub text-gradient-info mb-0 font-weight-bold">(recommended)</p>
            </div>
        )}
       
        </>
    )

}

const mapStateToProps = state => {
    return {
        company: state.company.company,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountMFAEmail);