import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Modal, Row, Col, Card, CardHeader, FormGroup, Input, CardTitle } from "reactstrap";
import { useCallback } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import SearchCollections from "components/system/Search/Collections";
import Circle from 'components/markup/loading/Circle';
import DatePicker from 'components/markup/inputs/Day';
import ObjectFinder from 'components/system/Objects/Finder';
import ModalToggler from 'components/functional/modals/Toggler'

import Check from './Check'

import { openDocumentCenter } from 'store/functions/document_center';
import { onDownloadDocument } from 'utils/documents';

import api from 'api'
import moment from 'moment'

import { toast } from 'react-toastify'

import UserComments from 'components/system/UserComments';

const ModalGarnishmentEdit = ({showModal, toggleModal, onSuccess, garnishment_id, fetchInfo}) => {

    const [err, setErr] = useState(false)
    const [garnishment, setGarnishment] = useState(null)

    const onInputChange = useCallback((field, val) => {
        const _garnishment = JSON.parse(JSON.stringify(garnishment));
        _garnishment[field] = val;
        setGarnishment(_garnishment)
    }, [garnishment])

    const onUpload = useCallback(async (obj) => {
        const result = await api.modules.bk.garnishments.documents.add(garnishment._id, { document: obj._id })
        if(result.data) {
            const newObj = JSON.parse(JSON.stringify(garnishment))
            newObj.documents = result.data.documents;
            setGarnishment(newObj)
        }
    }, [garnishment])
   
    const onRemoveUpload = useCallback(async (id) => {
        const result = await api.modules.bk.garnishments.documents.delete(garnishment._id, { document: id })
        if(result.data) {
            const newObj = JSON.parse(JSON.stringify(garnishment))
            newObj.documents = result.data.documents;
            setGarnishment(newObj)
        }
    }, [garnishment])

    const onSave = useCallback(async () => {
        if(!garnishment.name) return toast.info(`A name must be set before updating a garnishment`);

        const result = await api.modules.bk.garnishments.update(garnishment._id, {
            ...garnishment,
            amount: garnishment.amount ? parseFloat(garnishment.amount) : 0
        })

        if(!result.success) return toast.error(`Something went wrong updating this garnishment, please refresh your page`)
        
        toast.success(`Garnishment updated successfully`);
        toggleModal()
        fetchInfo()
        if(onSuccess) onSuccess(result.data)

    }, [garnishment, onSuccess, toggleModal, fetchInfo])

    const fetchData = useCallback(async () => {
        if(showModal) {
            const result = await api.modules.bk.garnishments.findById(garnishment_id);
            if(!result.success) return setErr(true);
            setGarnishment(result.data)
        } else {
            setErr(false)
        }
    }, [garnishment_id, showModal])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
            zIndex={2000}
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit A Garnishment</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {err ? (
                <div className="modal-body">
                    <div className='alert alert-danger mb-0'>Something went wrong loading this garnishment, please refresh your page.</div>
                </div>
            ) : !garnishment ? (
                <div className="modal-body">
                    <Circle />
                </div>
            )  : (
                <div>

                    <div className="modal-body border-bottom">

                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Status / Amount</h2>

                        <FormGroup>
                            <label className='form-control-label'>Garnishment Name</label>
                            <Input 
                                type="text"
                                value={garnishment.name}
                                onChange={(e) => onInputChange('name', e.target.value)}
                            />
                        </FormGroup>

                        <Row>
                            <Col md={6}>
                                <ReactSelect
                                    title="Garnishment Status"
                                    formGroup={true}
                                    onChange={(obj) => onInputChange('status', obj.value)}
                                    options={[
                                        { value: 'demand_needed', label: 'Demand Needed' },
                                        { value: 'demand_sent', label: 'Demand Sent' },
                                        { value: 'recovery_partial', label: 'Partial Recovery' },
                                        { value: 'recovery_full', label: 'Full Recovery' },
                                        { value: 'preference_under', label: 'Under Preference' },
                                        { value: 'preference_beyond', label: 'Beyond Preference Period' },
                                    ]}
                                    value={garnishment.status}
                                />    
                            </Col>

                            <Col md={6}>
                                <FormGroup>
                                    <label className='form-control-label'>Garnishment Amount</label>
                                    <Input 
                                        type="number"
                                        value={garnishment.amount}
                                        onChange={(e) => onInputChange('amount', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                    </div>
                  
                    <div className="modal-body border-bottom bg-secondary">

                        <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Parties / Dates</h2>

                        <Row>
                            <Col md={6}>
                                <SearchCollections
                                    collection={'contacts'}
                                    title="Garnishing Party"
                                    placeholder="Search Contacts..."
                                    value={garnishment.garnishing_party}
                                    onChange={(obj) => onInputChange('garnishing_party', obj.value)}
                                    filter={{}}
                                /> 

                                <SearchCollections
                                    collection={'contacts'}
                                    title="Garnishing Party's Attorney"
                                    placeholder="Search Contacts..."
                                    value={garnishment.garnishing_attorney}
                                    onChange={(obj) => onInputChange('garnishing_attorney', obj.value)}
                                    filter={{}}
                                /> 
                            </Col>
                          
                            <Col md={6}>
                               
                                <DatePicker 
                                    title={`Garnishment Letter Date`}
                                    value={garnishment.garnishment_letter_date ? moment(garnishment.garnishment_letter_date).toDate() : ''} 
                                    onChange={day => onInputChange('garnishment_letter_date', day)} 
                                />
                               
                                <DatePicker 
                                    title={`Garnishment Follow Up Letter Date`}
                                    value={garnishment.garnishment_follow_up_letter_date ? moment(garnishment.garnishment_follow_up_letter_date).toDate() : ''} 
                                    onChange={day => onInputChange('garnishment_follow_up_letter_date', day)} 
                                />
                            </Col>

                        </Row>

                    </div>
                  
                    <div className="modal-body">

                    <h2 className='text-uppercase text-cente mb-0 pb-3 pt-3'>Checks / Documents / Notes</h2>


                        <Row>
                            <Col md={6}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col md={6} className='align-self-center'>
                                                <CardTitle className='mb-0'>Checks</CardTitle>
                                            </Col>

                                            <Col md={6} className='align-self-center text-right'>
                                                <ModalToggler component={Check} garnishment={garnishment} onSuccess={(data) => setGarnishment(data)}>
                                                    <button className='btn btn-outline-success btn-sm'><i className="fas fa-plus mr-2" /> Add</button>
                                                </ModalToggler>
                                            </Col>
                                        </Row>
                                    </CardHeader>

                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Check Date</th>
                                                    <th>Check Memo</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {garnishment.checks.length ? garnishment.checks.map(c => (
                                                    <tr key={c._id}>
                                                        <td>{c.date ? moment(c.date).format('MM/DD/YYYY') : '-'}</td>
                                                        <td>{c.memo}</td>
                                                        <td className='text-right'>
                                                            <ModalToggler component={Check} garnishment={garnishment} check_id={c._id} onSuccess={(data) => setGarnishment(data)}>
                                                                <button className='btn btn-sm btn-success'>Edit</button>
                                                            </ModalToggler>
                                                        </td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td>No Checks Added</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                            </Col>

                            <Col md={6}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col md={6} className='align-self-center'>
                                                <CardTitle className='mb-0'>Documents</CardTitle>
                                            </Col>

                                            <Col md={6} className='align-self-center text-right'>
                                                <button 
                                                    onClick={() => openDocumentCenter({matter: garnishment.matter, onUpload, zIndex: 3000})}
                                                    className='btn btn-outline-success btn-sm'
                                                >
                                                    <i className="fas fa-plus mr-2" /> Add
                                                </button>
                                            </Col>
                                        </Row>
                                    </CardHeader>

                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Document</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {garnishment.documents.length ? garnishment.documents.map(c => (
                                                    <tr key={c}>
                                                        <td>
                                                            <span className='cursor-pointer' onClick={() => onDownloadDocument(c)}>
                                                                <ObjectFinder collection="documents" _id={c} />
                                                            </span>
                                                        </td>
                                                        <td className='text-right'>
                                                            <button onClick={() => onRemoveUpload(c)} className='btn btn-sm btn-danger'>Remove</button>
                                                        </td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td>No Documents Added</td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <UserComments 
                            collection_name="bk_garnishments" 
                            collection_id={garnishment._id}
                            parent_name={garnishment.name}
                        />
                    </div>

                </div>
            )}

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                {!err && garnishment ? (
                    <button className="btn btn-success" onClick={onSave}>
                        <i className="fas fa-save mr-2" /> Save
                    </button>
                ) : ''}
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalGarnishmentEdit);