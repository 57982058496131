import { connect } from 'react-redux';

import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import Circle from 'components/markup/loading/Circle';

import api from 'api';

import moment from 'moment';

const AI = ({ selected_matter, selectedThread, setSelectedThread, setSelectedAssistant, threads, setThreads }) => {

    const fetchData = useCallback(async () => {
        const data = await api.ai_threads.findByMatter(selected_matter._id)
        if(!data.success) return toast.info('Could not load threads at this time.')
        setThreads(data.data)
    }, [selected_matter._id, setThreads])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <div className="archk-ai-main-left-sidebar">

            {!threads ? (
                 <div className='section'><Circle /></div>
            ) : (
                <div>
                    <div className='section'>
                        <h3 className='mb-0'>ALL Threads</h3>
                    </div>

                    {threads.map(t => {
                        const active = selectedThread && selectedThread._id === t._id;
                        return (
                            <div key={t._id} className={`section mb-0 cursor-pointer ${active ? 'active' : ''}`} onClick={() => {
                                setSelectedThread(t)
                                if(t.ai_assistant) setSelectedAssistant(t.ai_assistant)
                            }} >
                                <p className={`text-sm mb-0 ${active ? 'text-white' : ''}`}>{t.name}</p>
                                <p className={`text-sm mb-0 ${active ? 'text-white' : 'text-muted'}`}><small>{moment.unix(t.updated_at).format('MM/DD/YYYY h:mm A')}</small></p>
                            </div>
                        )
                    })}
                </div>

            )}

        </div>
    )
}


const mapStateToProps = state => {
	return {
        selected_matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(AI);