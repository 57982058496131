import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import DatePicker from 'components/markup/inputs/Day';

import moment from 'moment';

const ModuleBKOverview = ({ data, onInputChange }) => {
    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Plan Details</CardTitle>
                </CardHeader>

                <CardFooter>
                    <Row>
                        <Col xs={6}>
                            <DatePicker 
                                title={`Petition / Conversion Date`}
                                value={data.ch_13_petition_date ? moment(data.ch_13_petition_date).toDate() : ''} 
                                onChange={day => onInputChange('ch_13_petition_date', moment(day).format('MM/DD/YYYY'))} 
                            />
                        </Col>
                        <Col xs={6}>
                            <DatePicker 
                                title={`Date Plan Last Filed`}
                                value={data.ch_13_plan_last_filed_date ? moment(data.ch_13_plan_last_filed_date).toDate() : ''} 
                                onChange={day => onInputChange('ch_13_plan_last_filed_date', moment(day).format('MM/DD/YYYY'))} 
                            />
                        </Col>
                    </Row>

                    </CardFooter>

                    <CardFooter>

                    <Row>
                        <Col xs={4}>
                            <p className='text-sm mb-0 font-weight-bold'>Plan Length</p>

                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_plan_length_select"
                                    id={`archk_ch_13_plan_length_36`}
                                    type="radio"
                                    checked={data.ch_13_plan_length === 36}
                                    onClick={e => onInputChange('ch_13_plan_length', 36)}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_plan_length_36`}>
                                    36 Months
                                </label>
                            </div>
                           
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_plan_length_select"
                                    id={`archk_ch_13_plan_length_60`}
                                    type="radio"
                                    checked={data.ch_13_plan_length === 60}
                                    onClick={e => onInputChange('ch_13_plan_length', 60)}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_plan_length_60`}>
                                    60 Months
                                </label>
                            </div>
                        </Col>

                        <Col xs={4}>
                            <p className='text-sm mb-0 font-weight-bold'>Tax Refunds</p>

                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_tax_refunds_select"
                                    id={`archk_ch_13_tax_refunds_100`}
                                    type="radio"
                                    checked={data.ch_13_tax_refunds === '100'}
                                    onClick={e => onInputChange('ch_13_tax_refunds', '100')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_tax_refunds_100`}>
                                    100%
                                </label>
                            </div>
                           
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_tax_refunds_select"
                                    id={`archk_ch_13_tax_refunds_0`}
                                    type="radio"
                                    checked={data.ch_13_tax_refunds === '0'}
                                    onClick={e => onInputChange('ch_13_tax_refunds', '0')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_tax_refunds_0`}>
                                    0%
                                </label>
                            </div>
                         
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_tax_refunds_select"
                                    id={`archk_ch_13_tax_refunds_prorated`}
                                    type="radio"
                                    checked={data.ch_13_tax_refunds === 'prorated'}
                                    onClick={e => onInputChange('ch_13_tax_refunds', 'prorated')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_tax_refunds_prorated`}>
                                    Prorated
                                </label>
                            </div>
                           
                        </Col>


                        <Col xs={4}>
                            <p className='text-sm mb-0 font-weight-bold'>General Unsecured</p>

                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_general_unsecured_select"
                                    id={`archk_ch_13_general_unsecured_0_unsecured_base`}
                                    type="radio"
                                    checked={data.ch_13_general_unsecured === '0_unsecured_base'}
                                    onClick={e => onInputChange('ch_13_general_unsecured', '0_unsecured_base')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_general_unsecured_0_unsecured_base`}>
                                    $0 Unsecured Base
                                </label>
                            </div>

                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_general_unsecured_select"
                                    id={`archk_ch_13_general_unsecured_100_dividend`}
                                    type="radio"
                                    checked={data.ch_13_general_unsecured === '100_dividend'}
                                    onClick={e => onInputChange('ch_13_general_unsecured', '100_dividend')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_general_unsecured_100_dividend`}>
                                    100% Dividend
                                </label>
                            </div>
                           
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    name="archk_ch_13_general_unsecured_select"
                                    id={`archk_ch_13_general_unsecured_lqa_dividend`}
                                    type="radio"
                                    checked={data.ch_13_general_unsecured === 'lqa_dividend'}
                                    onClick={e => onInputChange('ch_13_general_unsecured', 'lqa_dividend')}
                                />
                                <label className="custom-control-label" htmlFor={`archk_ch_13_general_unsecured_lqa_dividend`}>
                                    LQA Dividend
                                </label>
                            </div>
                        </Col>

                    </Row>
             
                </CardFooter>

                <CardFooter>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <label className='form-control-label'>Monthly Plan Payment</label>
                                <Input 
                                    type="number"
                                    value={data.ch_13_plan_payment_amount}
                                    onChange={e => onInputChange('ch_13_plan_payment_amount', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <Row>
                                <Col xs={7}>
                                    <FormGroup>
                                        <label className='form-control-label'>Payment Via Wage Order</label>
                                        <Input 
                                            type="number"
                                            value={data.ch_13_wage_order_amount}
                                            onChange={e => onInputChange('ch_13_wage_order_amount', e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={5}>
                                    <ReactSelect
                                        title={<span className='text-white'>t</span>}
                                        formGroup={true}
                                        onChange={(obj) => onInputChange('plan_payment_frequency', obj.value)}
                                        options={[
                                            { value: 'weekly', label: 'Weekly' },
                                            { value: 'biweekly', label: 'Bi-Weekly' },
                                            { value: 'semimonthly', label: 'Semi-Monthly' },
                                            { value: 'monthly', label: 'Monthly' },
                                        ]}
                                        value={data.plan_payment_frequency}
                                    /> 
                                </Col>
                            </Row>
                            <DatePicker 
                                title={`Date Order Filed`}
                                value={data.ch_13_wage_order_filed_date ? moment(data.ch_13_wage_order_filed_date).toDate() : ''} 
                                onChange={day => onInputChange('ch_13_wage_order_filed_date', moment(day).format('MM/DD/YYYY'))} 
                            />
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <label className='form-control-label'>Direct Pay To Trustee</label>
                                <Input 
                                    type="number"
                                    value={data.ch_13_direct_pay_amount}
                                    onChange={e => onInputChange('ch_13_direct_pay_amount', e.target.value)}
                                />
                            </FormGroup>
                            <DatePicker 
                                title={`Date Order Filed`}
                                value={data.ch_13_direct_pay_filed_date ? moment(data.ch_13_direct_pay_filed_date).toDate() : ''} 
                                onChange={day => onInputChange('ch_13_direct_pay_filed_date', moment(day).format('MM/DD/YYYY'))} 
                            />
                        </Col>
                    </Row>
                </CardFooter>
                <CardFooter>
                    <FormGroup>
                        <label className='form-control-label'>Direct Pay Creditor Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 60}}
                            value={data.ch_13_direct_pay_notes}
                            onChange={e => onInputChange('ch_13_direct_pay_notes', e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


