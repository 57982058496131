import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { formatCurrency } from 'utils/currency';

import moment from 'moment';

const Payments = ({matter, payment_subscription, schedule, payment_methods}) => {

    let method = '';
    if(payment_subscription) method = payment_methods.find(method => method._id === payment_subscription.payment_method)


    return (
        <Card>
            <CardHeader className='py-3'>
                <Row>
                    <Col md={6} className="align-self-center">
                        <CardTitle className="mb-0">Payment Plan Schedule</CardTitle>
                    </Col>
                    <Col md={6} className="align-self-center text-right">

                    </Col>
                </Row>
            </CardHeader>

            {schedule.length ? (
                    <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Payment Method</th>
                                <th className='text-right'>Amount</th>
                                <th className='text-right'>Balance</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className='text-dark font-weight-bold'>Current Balance</td>
                                <td></td>
                                <td></td>
                                <td className='text-dark font-weight-bold text-right'>{formatCurrency(matter.billing_balance)}</td>
                            </tr>
                            {schedule.map(p => {


                                return (
                                    <tr key={p._id} >
                                        <td>{moment(p.date).format('MMM Do, YYYY')}</td>
                                        <td>
                                        {method.type === 'card' ? (
                                            <span><i className="fa-solid fa-money-check text-success mr-2" /> xx{method.last_4}</span>
                                        ) : (
                                            <span><i className="text-warning fa-solid fa-building-columns" /> xx{method.last_4}</span>
                                        )}

                                        </td>
                                        <td className='text-right'>{formatCurrency(p.amount)}</td>
                                        <td className='text-right'>{formatCurrency(p.balance)}</td>
                                    </tr>
                                )
                            })}
                         
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    <CardBody className='py-8'>
                        <p className="text-ss mb-0 text-center text-capitalize">
                            <i className="fas fa-info-circle mr-3 text-info" /> 
                            There is no payment schedule for this matter
                        </p>
                    </CardBody>
                    
                </div>
            )}
        </Card>
    )

}

export default Payments