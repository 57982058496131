/*
Documentation

this component create the breadcrumb header for the dashboard/admin pages

*/

import PropTypes from "prop-types";
import React from "react";
import { Breadcrumb, BreadcrumbItem, Col, Row, Card, CardBody, CardTitle } from "reactstrap";

const DashHeader = ({title, icon, breadcrumb_1, breadcrumb_2, breadcrumb_3, actionComponent, col1Size, col2Size}) => (
    <Card className="dash-header-open">

        <CardBody>
            <Row className="align-items-center">

                <Col lg={col1Size ? col1Size : 6} md={col1Size ? col1Size : 7} xs="12" className="align-self-center">
                    <CardTitle className="d-inline-block mb-0 ">{title}</CardTitle>{" "}
                    <Breadcrumb
                        className="ml-4 d-inline-block"
                        listClassName="breadcrumb-links"
                    >

                        {icon &&
                            <BreadcrumbItem>
                                <i className={icon + ' text-warning'} />
                            </BreadcrumbItem>
                        }

                        {breadcrumb_1 &&
                            <BreadcrumbItem aria-current="page" className="active">
                            {breadcrumb_1}
                            </BreadcrumbItem>
                        }

                        {breadcrumb_2 &&
                            <BreadcrumbItem aria-current="page" className="active">
                            {breadcrumb_2}
                            </BreadcrumbItem>
                        }

                        {breadcrumb_3 &&
                            <BreadcrumbItem aria-current="page" className="active">
                            {breadcrumb_3}
                            </BreadcrumbItem>
                        }
                    </Breadcrumb>
                </Col>

                {actionComponent &&
                    <Col className="text-right align-self-center" lg={col2Size ? col2Size : 6} md={col2Size ? col2Size : 5} xs="12">
                        {actionComponent}
                    </Col>
                }

            </Row>
        </CardBody>
    </Card>
);


DashHeader.propTypes = {
  title: PropTypes.element.isRequired,
  breadcrumb_1: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  breadcrumb_2: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  breadcrumb_3: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actionComponent: PropTypes.element,
  icon: PropTypes.string,
  icon_link: PropTypes.string,
};

export default DashHeader;
