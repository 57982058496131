
import React from "react";
import { Container } from 'reactstrap'
import { connect } from 'react-redux';

const AdminLock = ({viewing_user, children}) => {

    if(!viewing_user.is_admin) return (
        <Container fluid>

            <div className="alert alert-danger my-4">
                <i className="fas fa-exclamation-triangle mr-2 " />
                You do not have access to view this page.
            </div>

        </Container>
    )


    return children;

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(AdminLock);

