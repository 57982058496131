import { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";
import SearchCollections from "components/system/Search/Collections";

import A from 'components/markup/links/A'

const ModalCustomFields = ({showModal, toggleModal, matter}) => {

    const [selected, setSelectd] = useState('')

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

           <div className="modal-header">
               <h5 className="modal-title">Search Signing Templates</h5>
               <button
                   aria-label="Close"
                   className="close"
                   data-dismiss="modal"
                   type="button"
                   onClick={toggleModal}
                >
                   <span aria-hidden={true}>×</span>
               </button>
           </div>

           <div className="modal-body">
                <SearchCollections
                    collection={'signing_templates'}
                    title="Select The Document You Wish To Send Below"
                    value={selected}
                    onChange={(obj) => setSelectd(obj.value)}
                    filter={{
                        division: matter.division
                    }}
                /> 
           </div>

           <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                
                {selected ? (
                    <span onClick={toggleModal} >
                        <A href={`/editor/signing/${matter._id}/${selected}`} className="btn btn-success">
                            Send <i className="fas fa-paper-plane ml-2" />
                        </A>
                    </span>
                ) : ''}
           </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalCustomFields);