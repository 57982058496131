import { useState } from 'react';
import { connect } from 'react-redux';

import { openDocumentCenter } from 'store/functions/document_center';

import FeedCreate from 'components/system/Feed/Create';

import ModalSendEmail from 'components/system/Email/Modal';
import EventsEdit from 'components/system/Event';
import ModalSendText from 'components/system/Text/Modal';

import hasPrivilege from 'utils/hasPrivilege';

const MattersViewMainComponentsQuickActions = ({matter, onSetTab}) => {

    const [showEmailModal, setShowEmailModal] = useState(false)
    const [showTextModal, setShowTextModal] = useState(false)
    const [showEventModal, setShowEventModal] = useState(false)

    return (

        <div className="section pb-0" style={{borderBottom: 'none'}}>
            <h4 className="mb-3">
                <button className="btn btn-info btn-sm" onClick={() => onSetTab('Checklist')}>
                    Step Checklist
                </button>

                {hasPrivilege('DOCUMENTS.WRITE') ? (
                    <span className="cursor-pointer hover-info" style={styles.nav} onClick={() => openDocumentCenter({matter: matter._id})}>
                        <i className="fas fa-upload ml-3" /> Attach File
                    </span>
                ) : ''}
                
                {hasPrivilege('EMAILS.WRITE') ? (
                    <span 
                        className="cursor-pointer hover-info" 
                        style={styles.nav} 
                        onClick={() => setShowEmailModal(true)}
                    >
                        <i className="fas fa-paper-plane ml-5 "  /> Send Email
                    </span>
                ) : ''}
                
                {hasPrivilege('TEXTS.WRITE') ? (
                    <span 
                        onClick={() => setShowTextModal(true)}
                        className="cursor-pointer hover-info"
                        style={styles.nav}
                    >
                        <i className="fas fa-mobile ml-5 " /> Send Text
                    </span>
                ) : ''}
                
                {hasPrivilege('EVENTS.WRITE') ? (
                    <span 
                        onClick={() => setShowEventModal(true)}
                        className="cursor-pointer hover-info" 
                        style={styles.nav}
                    >
                        <i className="fas fa-calendar ml-5 " /> Create Event
                    </span>
                ) : ''}
                
            </h4>

            {hasPrivilege('NOTES.WRITE') ? (
                <FeedCreate matter_id={matter._id} />
            ) : ''}

            <ModalSendEmail 
                showModal={showEmailModal}
                toggleModal={() => setShowEmailModal(false)}
                matter={matter._id}
                division={matter.division}
                onSent={() => setShowEmailModal(false)}
            />

    
            <ModalSendText 
                showModal={showTextModal}
                toggleModal={() => setShowTextModal(false)}
                matter={matter._id}
                onSent={() => setShowTextModal(false)}
            />

            <EventsEdit 
                showModal={showEventModal}
                toggleModal={() => setShowEventModal(false)}
                matter={matter._id}
                onSave={(e) => setShowEventModal(false)}
            />

    
        </div>

    )

}

const styles = {
    nav: {
        position: 'relative', 
        top: 5
    }
}

const mapStateToProps = state => {
	return {
        company: state.company,
        matter: state.matters.selected_matter,

	};
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsQuickActions);
