


import { useCallback, useState } from 'react';

import { Input } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';

import SelectTemplate from './SelectTemplate'

import ModalToggler from 'components/functional/modals/Toggler'
import ModalSendEmail from 'components/system/Email/Modal';
{/* <ModalSendEmail 
                showModal={showEmailModal}
                toggleModal={() => setShowEmailModal(false)}
                matter={matter._id}
                onSent={() => setShowEmailModal(false)}
            /> */}

const TextsSend = ({thread, to}) => {

    const [disabled, setDisabled] = useState(false)
    const [bodies, setBodies] = useState({})
    const body = bodies[thread ? thread.thread._id : '']


    const onSetBodies = useCallback((value) => {
        const _bodies = JSON.parse(JSON.stringify(bodies));
        _bodies[thread.thread._id] = value;
        setBodies(_bodies)
    }, [bodies, thread])

    const onSent = useCallback(async () => {
        if(!body) return;
        setDisabled(true)

        const data = await api.emails.compose({
            division        : thread.thread.division,
            // matter          : matter,
            // contact         : contact,
            email_thread    : thread.thread._id,
            // email_template  : emailTemplate,
            // task            : task,
        })
        if(!data.success) {
            setDisabled(false)
            return toast.info(`Quick reply could not be sent, please try again.`)
        }

        const payload = {
            from: data.data.from[0].email,
            to: data.data.to,
            attachments: [],
            subject: data.data.subject,
            body,
            reply_to_message_id: data.data.reply_to_message_id
            // task,
            // matter: foundMatter ? foundMatter._id : undefined,
            // email_template: foundEmailTemplate ? foundEmailTemplate._id : undefined
        }

        // setFrom(data.data.from)
        // setTo(data.data.to)
        // setSubject(data.data.subject)

        // if(data.data.reply_to_message_id) setReplyToId(data.data.reply_to_message_id)
        // if(data.data.email_template) setFoundEmailTemplate(data.data.email_template)
        // if(data.data.email_template && data.data.email_template.attachments) setAttachments(data.data.email_template.attachments)
        // if(data.data.matter) setFoundMatter(data.data.matter)
        // if(data.data.email_thread) setFoundThread(data.data.email_thread)
        // if(data.data.body) setBody(data.data.body)

        const sent = await api.emails.send(payload)

        setDisabled(false)

        if(!sent.success) return toast.info(`Your email message could not be sent, please try again.`)
        onSetBodies('')

    }, [thread, body, onSetBodies])
    
      //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') onSent()
	}, [onSent])

    return (

        
        <div className="archk-messaging-send">
            <div className="archk-messaging-send-wrapper">
                <Input 
                    type="text"
                    placeholder="Type Message..."
                    value={body || ''}
                    onChange={e => onSetBodies(e.target.value)}
                    onKeyDown={_handleKeyDown}
                />      

                {thread ? (
                    <ModalToggler component={ModalSendEmail} thread={thread.thread._id} division={thread.thread.division} onSent={() => onSetBodies('')}>
                        <i className="archk-messaging-send-attach fas fa-edit" />
                    </ModalToggler>
                ) : ''}
               
                {/* <i className="archk-messaging-send-attach fas fa-paperclip" /> */}

                <button disabled={disabled} className="btn btn-info" onClick={onSent}><i className="fas fa-paper-plane" /></button>
            </div>
        </div>
    )

}

export default TextsSend