import { useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'
import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'
import formatText from 'utils/formatText'

import api from 'api';

const MattersAll = ({viewing_user}) => {
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        // params.filter.user = viewing_user._id

        const query = await api.calls.search({ ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "matter",
            text: 'Matter & Contact',
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )

                return (
                    <div>
                       {row.matter ? (
                        <div>
                            <Link to={`/matters/${row.matter}`} className="text-dark font-weight-bold">
                                <ObjectFinder collection="matters" _id={row.matter} />
                            </Link>
                        </div>
                       ) : ''}
                        <div>
                            <Link to={`/contacts/${row.contact}`} className="text-dark font-weight-bold">
                                <ObjectFinder collection="contacts" _id={row.contact} />
                            </Link>
                        </div>
                    </div>
                )
            },
        },

        {
            dataField: "direction",
            text: 'Direction & Duration',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div className="text-capitalize">
                        <div>

                            {row.direction} 
                            {row.direction === 'outbound' ? (
                                <span className={row.status === 'completed' ? 'text-success' : 'text-warning'}>
                                    <i className="fas fa-phone ml-2" /><i className="fas fa-arrow-up ml-" /> 
                                </span>
                            ) : (
                                <span className={row.status === 'completed' ? 'text-success' : 'text-warning'}>
                                    <i className="fas fa-phone ml-2 " /><i className="fas fa-arrow-down  ml-" /> 
                                </span>
                            )}
                        </div>
                        <div>{formatText(row.duration).secondsToTime()}</div>
                    </div>
                )
            )
        },
        {
            dataField: "status",
            text: 'Call Status',
            formatter: (cell, row) => row.name === 'no data' ? '' : row.status ? row.status.replace ('_', ' ') : row.status
        },

        {
            dataField: "start",
            text: 'Start',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>{moment.unix(row.start).format('MM/DD/YYYY')}</div>
                        <div>{moment.unix(row.start).format('h:mm A')}</div>
                    </div>
                )
            )
        },

        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 100},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                {
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/calls/${row._id}`,
                                }
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} />

    return (

        <>

        <Helmet>
            <title>Call Logs</title>
            <meta name="description" content="Call Logs" />
        </Helmet>

        <HeaderNavigation 
            title="Call Logs"
            description="My Call Logs"
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    hideSearch={true}
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Status..."
                    query={query}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
