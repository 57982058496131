import { Nav, NavItem, NavLink } from "reactstrap";

const ModalCustomFields = ({tab, setTab}) => (

    <Nav
        className="nav-fill flex-column flex-sm-row my-3"
        id="tabs-text"
        pills
        role="tablist"
    >

        <NavItem>
            <NavLink
                aria-selected={tab === 'details'}
                className={`mb-sm-3 mb-md-0 ${tab === 'details' ? 'active' : ''}`}
                onClick={e => setTab('details')}
                role="tab"
            >
                Details
            </NavLink>
        </NavItem>

        <NavItem>
            <NavLink
                aria-selected={tab === 'fees'}
                className={`mb-sm-3 mb-md-0 ${tab === 'fees' ? 'active' : ''}`}
                onClick={e => setTab('fees')}
                role="tab"
            >
                Fees
            </NavLink>
        </NavItem>
        
        <NavItem>
            <NavLink
                aria-selected={tab === 'expenses'}
                className={`mb-sm-3 mb-md-0 ${tab === 'expenses' ? 'active' : ''}`}
                onClick={e => setTab('expenses')}
                role="tab"
            >
                Expenses
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink
                aria-selected={tab === 'credits'}
                className={`mb-sm-3 mb-md-0 ${tab === 'credits' ? 'active' : ''}`}
                onClick={e => setTab('credits')}
                role="tab"
            >
                Credits
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink
                aria-selected={tab === 'discounts'}
                className={`mb-sm-3 mb-md-0 ${tab === 'discounts' ? 'active' : ''}`}
                onClick={e => setTab('discounts')}
                role="tab"
            >
                Discounts
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink
                aria-selected={tab === 'write_offs'}
                className={`mb-sm-3 mb-md-0 ${tab === 'write_offs' ? 'active' : ''}`}
                onClick={e => setTab('write_offs')}
                role="tab"
            >
                Write Offs
            </NavLink>
        </NavItem>

    </Nav>

)


export default ModalCustomFields