import { connect } from 'react-redux'
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import InternalSidebar from 'components/markup/layout/InternalSidebarAdmin'
import Circle from 'components/markup/loading/Circle'
import UserComments from 'components/system/UserComments';

import api from 'api'

import DocketEntries from './DocketEntries'
import Claims from './Claims'
import CaseInfo from './CaseInfo'
import Schedules from './Schedules'
import CreditReports from './CreditReports'
import Garnishments from './Garnishments'
import Objections from './Objections'
import Creditors from './Creditors'
import ChecklistConfirmation from './ChecklistConfirmation'
import CaseStage from './CaseStage'
import Deadlines from './Deadlines'
import Hearings from './Hearings'
import Overview from './Overview'

import Tracker from './_components/Tracker'

import { toast } from 'react-toastify'
import { toggleStandardLoader } from 'store/functions/system/system';
import { getUrlParameter } from 'utils/urls';

const ModuleBK = ({ setShowModuleBK, matter }) => {

    const [tab, setTab] = useState(getUrlParameter('moduleTab') || 'Creditors');
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState(null);
    const [bkCase, setBkCase] = useState(null);
    const [hasOpenedUrlLink, setHasOpenedUrlLink] = useState(false);
    const [items, setItems] = useState(false);
   
    const fetchStageTasks = useCallback((stage) => {
        const all = items ? items.filter(d => d.metadata && d.metadata.stage === stage) : []
        return all        
    }, [items])

    const fetchStageName = useCallback((stage, title, _items) => {
        const unfinished = _items ? _items.filter(i => !i.finished_at) : []
        const color = bkCase && bkCase.stage === stage ? 'text-success' : ''
        // return unfinished && unfinished.length ? <span>*{title} <b className='text-warning float-right'>({unfinished.length})</b></span>  : '*' + title
        return <span><b className={color}>{title}</b> {unfinished && unfinished.length ? <b className='text-info float-right'>({unfinished.length})</b> : ''}</span>
    }, [bkCase])

    const garnishmentTitle =  <span>Garnishments <b className='text-info float-right'>{info && info.garnishments ? <b>({info.garnishments})</b> : ''}</b></span>
    const objectionTitle =  <span>Objections <b className='text-info float-right'>{info && info.objections ? <b>({info.objections})</b> : ''}</b></span>

    const newCaseTasks              = fetchStageTasks('new_case_filed')
    const three41ActionTasks        = fetchStageTasks('341_action')
    const three41HeldTasks          = fetchStageTasks('341_held')
    const confirmationHeldTasks     = fetchStageTasks('confirmation_held')
    const confirmationActionTasks   = fetchStageTasks('confirmation_action')
    const dismissedTasks            = fetchStageTasks('case_dismissed')
    const concludedTasks            = fetchStageTasks('case_concluded')

    const deadlineTasks = items ? items.filter(i => i.is_deadline && !i.finished_at) : []
    const hearingTasks = items ? items.filter(i => i.category === 'bk_hearing') : []


    const newCaseTitle              = fetchStageName('new_case_filed', 'Case Filed', newCaseTasks)
    const three41ActionTitle        = fetchStageName('341_action', '341 - Action', three41ActionTasks)
    const three41HeldTitle          = fetchStageName('341_held', '341 - Held', three41HeldTasks)
    const confirmationActionTitle   = fetchStageName('confirmation_action', 'Conf - Action', confirmationActionTasks)
    const confirmationHeldTitle     = fetchStageName('confirmation_held', 'Conf - Held', confirmationHeldTasks)
    const dismissedTitle            = fetchStageName('case_dismissed', 'Dismissed', dismissedTasks)
    const concludedTitle            = fetchStageName('case_concluded', 'Concluded', concludedTasks)
    const deadlineTitle            = fetchStageName('', 'Deadlines', deadlineTasks)
    const hearingTitle            = fetchStageName('', 'Hearings', hearingTasks)

    const onClose = useCallback(() => {
        setShowModuleBK(false)
    }, [setShowModuleBK])

    const onSetTab = useCallback((_tab) => {
        setTab(_tab)
    }, [])

    const fetchItems = useCallback(async () => {
        if(!bkCase) return;
        setItems(null)
        const result = await api.outstanding_items.findByMatter(matter._id, 'all');
        // const allItems = result.data ? result.data.filter(d => d.metadata && d.metadata.stage === bkCase.stage) : []

        setItems(result.data)
    }, [bkCase, matter._id])

    const onSyncToPacer = useCallback(async (payload) => {
        toggleStandardLoader(true)

        const docketSync = await api.modules.bk.court_drive.sync({
            division: matter.division,
            court_code: payload.court_code,
            case_number: payload.case_number,
            resource: 'dockets'
        });

        if(!docketSync.success) {
            toggleStandardLoader(false)
            return toast.error(`Something went wrong syncing docket entries for this case, confirm case number and court code are correct.`);
        }

        const claimSync = await api.modules.bk.court_drive.sync({
            division: matter.division,
            court_code: payload.court_code,
            case_number: payload.case_number,
            resource: 'claims'
        });

        if(!claimSync.success) {
            toast.info(`Something went wrong syncing claims for this case, this is an internal error.`);
        } else {
            toast.success(`Case Successfully Synced To Patriot!`)
        }

        toggleStandardLoader(false)

    }, [matter.division])

    const onMoveStage = useCallback(async (stage) => {
        toggleStandardLoader(true)
        setErr(false)
        const data = await api.modules.bk.cases.moveStage(matter._id, { stage });
        toggleStandardLoader(false)

        if(!data.success) {
            if(data.message && data.message.length === 1) {
                return setErr(data.message[0])
            } else {
                return toast.error(`Something went wrong saving this case, please try again.`);
            }
        }
        if(data.data && data.data.case) {
            setBkCase(data.data.case)
            toast.success(`Stage Moved Successfully!`)
            // fetchItems();
        }

    }, [matter._id])
   
    const onSaveBkCase = useCallback(async (payload) => {
        toggleStandardLoader(true)
        setErr(false)
        const data = await api.modules.bk.cases.save(matter._id, payload);
        toggleStandardLoader(false)

        if(!data.success) {
            if(data.message && data.message.length === 1) {
                return setErr(data.message[0])
            } else {
                return toast.error(`Something went wrong saving this case, please try again.`);
            }
        }
        if(data.data) {
            setBkCase(data.data)
            toast.success(`Case Saved Successfully!`)
        }

    }, [matter._id])

    const fetchInfo = useCallback(async () => {
        const data = await api.modules.bk.cases.info(matter._id);
        setInfo(data.data)

    }, [matter._id])

    const fetchData = useCallback(async () => {
        const data = await api.modules.bk.cases.findByMatter(matter._id);
        if(!data.success) return toast.error(`Something went wrong loading data for this case, please refresh your page.`);

        fetchInfo()
        setLoading(false)
        setBkCase(data.data)
        
    }, [matter._id, fetchInfo])

    useEffect(() => {
        fetchData()
    }, [fetchData])
  
    useEffect(() => {
        if(bkCase) fetchItems()
    }, [bkCase, fetchItems])

    useEffect(() => {
        document.body.classList.add('noScroll')

        return () => {
            document.body.classList.remove('noScroll')
        }
    }, [])
  
    return (
        <div className='archk-workflow'>

            <div className='archk-workflow-sidebar'>


                <div className="archk-workflow-sidebar-edit">

                    <div className="archk-workflow-sidebar-edit-blackout" onClick={onClose} />

                    <div className="archk-workflow-sidebar-edit-modal">
                        
                        <div className="archk-workflow-sidebar-spacer border-bottom">
                            <Row>
                                <Col xs={7} className="align-self-center">
                                    <h2 className='mb-0 text-info text-capitalize'>
                                        {bkCase && bkCase.case_title ? bkCase.case_title : matter.name} <span className='text-dark'>- {bkCase && bkCase.stage ? bkCase.stage.replace(/_/g, ' ') : 'Case Not Filed'}</span>
                                    </h2>
                                </Col>
                                <Col xs={5} className="align-self-center text-right" >
                                    <button className="btn btn-outline-info" onClick={onClose}>
                                        <i className="fas fa-arrow-left mr-2 " /> Close Editor
                                    </button>
                                    {/* <button disabled={disabled}  className="btn btn-success" onClick={onSave}>
                                        <i className="fas fa-save mr-2 " /> Save
                                    </button> */}
                                </Col>
                            </Row>
                        </div>

                        <div className="archk-workflow-sidebar-spacer">

                        <InternalSidebar 
                            isAdmin={true}
                            tab={tab}
                            setTab={onSetTab}
                            title={"Pre Filing"}
                            // sidebarStyles={{
                            //     position: 'sticky',
                            //     top: 0,
                            //     maxHeight: 'calc(100vh - 53px)',
                            //     // overflow: 'auto'
                            // }}
                            tabs={[
                              
                                { value: 'Creditors', name: 'Creditors', disabled: false },
                                { value: 'Credit Reports', name: 'Credit Reports', disabled: false },
                                { value: 'Garnishments', name: garnishmentTitle, disabled: false },

                                // { value: 'Schedules', name: 'Schedules', disabled: loading ? true : !bkCase ?  true : false },
                                // { value: 'Plan Calculator', name: 'Plan Calculator', disabled: loading ? true : !bkCase ?  true : false },

                            ]}
                            secondTitle={"Pacer Notices"}
                            secondTabs={[
                                { value: 'CaseInfo', name: 'Case Info', disabled: loading ? true : false },
                                { value: 'Docket Entries', name: 'Docket Entries', disabled: loading ? true : !bkCase ?  true : false },
                                { value: 'Objections', name: objectionTitle, disabled: loading ? true : !bkCase ?  true : false },
                                { value: 'Claims', name: 'Claims', disabled: loading ? true : !bkCase ?  true : false },
                            ]}
                            thirdTitle={"Post Filing"}
                            thirdTabs={[
                                { value: 'Overview', name: 'Overview', disabled: loading ? true : !bkCase ?  true : false },
                                { value: 'CaseStage', name: newCaseTitle, disabled: loading ? true : !bkCase ?  true : false },

                                { value: '341Action', name: three41ActionTitle, disabled: loading ? true : !bkCase ?  true : false },
                                { value: '341Held', name: three41HeldTitle, disabled: loading ? true : !bkCase ?  true : false },
                                
                                ...(bkCase && bkCase.chapter === 13 ? [
                                    { value: 'ConfirmationAction', name: confirmationActionTitle, disabled: loading ? true : !bkCase ?  true : false },
                                    { value: 'ConfirmationHeld', name: confirmationHeldTitle, disabled: loading ? true : !bkCase ?  true : false },
                                ] : []),


                                { value: 'Dismissed', name: dismissedTitle, disabled: loading ? true : !bkCase ?  true : false },
                                { value: 'Concluded', name: concludedTitle, disabled: loading ? true : !bkCase ?  true : false },
                                
                                { value: 'Filed Notes', name: 'Filed Notes', disabled: loading ? true : !bkCase ?  true : false },

                            ]}
                            fourthTitle={"Important Dates"}
                            fourthTabs={[                                
                                { value: 'Hearings', name: hearingTitle, disabled: loading ? true : !bkCase ?  true : false },
                                { value: 'Deadlines', name: deadlineTitle, disabled: loading ? true : !bkCase ?  true : false },
                            ]}
                        >
                            {err ? <div className='alert alert-danger'>{err}</div> : ''}

                            {loading ? (
                                <Circle />
                            ) : tab === 'CaseInfo' ? (
                                <CaseInfo 
                                    bkCase={bkCase}
                                    onSaveBkCase={onSaveBkCase}
                                    onSyncToPacer={onSyncToPacer}
                                />
                            ) : tab === 'Docket Entries' ? (
                                <DocketEntries 
                                    bkCase={bkCase}
                                />
                            ) : tab === 'Claims' ? (
                                <Claims 
                                    bkCase={bkCase}
                                />
                            ) : tab === 'Schedules' ? (
                                <Schedules 
                                    bkCase={bkCase}
                                />
                            )  : tab === 'Credit Reports' ? (
                                <CreditReports 
                                    bkCase={bkCase}
                                />
                            ) : tab === 'Garnishments' ? (
                                <Garnishments 
                                    bkCase={bkCase}
                                    hasOpenedUrlLink={hasOpenedUrlLink}
                                    setHasOpenedUrlLink={setHasOpenedUrlLink}
                                    fetchInfo={fetchInfo}
                                />
                            ) : tab === 'Objections' ? (
                                <Objections 
                                    bkCase={bkCase}
                                    hasOpenedUrlLink={hasOpenedUrlLink}
                                    setHasOpenedUrlLink={setHasOpenedUrlLink}
                                    fetchInfo={fetchInfo}
                                />
                            ) : tab === 'Creditors' ? (
                                <Creditors 
                                    bkCase={bkCase}
                                />
                            ) : tab === 'Confirmation' ? (
                                <ChecklistConfirmation 
                                    bkCase={bkCase}
                                />
                            ) : tab === 'CaseStage' ? (
                                <CaseStage 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={newCaseTasks}
                                    stage="new_case_filed"
                                />
                            ) : tab === '341Action' ? (
                                <CaseStage 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={three41ActionTasks}
                                    stage="341_action"
                                />
                            ) : tab === '341Held' ? (
                                <CaseStage 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={three41HeldTasks}
                                    stage="341_held"
                                />
                            ) : tab === 'ConfirmationAction' ? (
                                <CaseStage 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={confirmationActionTasks}
                                    stage="confirmation_action"
                                />
                            ) : tab === 'ConfirmationHeld' ? (
                                <CaseStage 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={confirmationHeldTasks}
                                    stage="confirmation_held"
                                />
                            ) : tab === 'Dismissed' ? (
                                <CaseStage 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={dismissedTasks}
                                    stage="case_dismissed"
                                />
                            ) : tab === 'Concluded' ? (
                                <CaseStage 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={concludedTasks}
                                    stage="case_concluded"
                                />
                            ) : tab === 'Deadlines' ? (
                                <Deadlines 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={deadlineTasks}
                                />
                            ) : tab === 'Hearings' ? (
                                <Hearings 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                    fetchItems={fetchItems}
                                    items={hearingTasks}
                                />
                            ) : tab === 'Overview' ? (
                                <Overview 
                                    bkCase={bkCase}
                                    onMoveStage={onMoveStage}
                                />
                            ) : tab === 'Filed Notes' && bkCase ? (
                                <div className='border p-4 rounded'>
                                     <UserComments 
                                        title={<h2 className='text-warning mb-4'>Filed Comments / Notes</h2>}
                                        collection_name="bk_cases" 
                                        collection_id={bkCase._id}
                                        parent_name={bkCase.case_title}
                                    />
                                </div>
                            ) : null}



                        </InternalSidebar>

                            

                        </div>

                    </div>

                </div>
            </div>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBK);


