import { useCallback, useState } from 'react'
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap'
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api'

import moment from 'moment';

import { toast } from 'react-toastify';

const MatterAlerts = ({matter, showAlerts, setShowAlerts, alertsOnMount, viewing_user}) => {

    const [markRead, setMarkRead] = useState(matter.alerts_viewed_by.includes(viewing_user._id))
    const [resolved, setResolved] = useState([])

    const onResolve = useCallback(async (alert) => {
        const updated = await api.matters.alertResolve({
            matter: matter._id,
            alert,
        })
        if(!updated.success) return toast.error(`Something's not right, please try again`)

        const _resolved = JSON.parse(JSON.stringify(resolved))
        _resolved.push(alert)
        setResolved(_resolved)
    }, [matter._id, resolved])

    const onClose = useCallback(() => {
        api.matters.alertsRead({matter: matter._id, read: markRead});
        setShowAlerts(false)
    }, [matter._id, setShowAlerts, markRead])

    if(!showAlerts) return <></>;

    return (

        <div className='archk-case-alerts'>
            <div className='archk-case-alerts-background' />

            <div className="archk-case-alerts-main z-depth-5">

                <div className='section'>
                    <Row>
                        <Col md={4} className="align-self-center">
                            <h2 className='mb-0'>Matter Alerts</h2>
                        </Col>
                        <Col md={8} className="align-self-center text-right">
                            {/* <button onClick={onClose} className='btn btn-outline-info btn-sm'><i className="fas fa-arrow-left mr-2" /> Close Alerts</button> */}
                        </Col>
                    </Row>
                </div>

                {alertsOnMount ? (
                    <div className='section'>
                        <p className='mb-0 text-sm'><i className="fas fa-info-circle mr-2 text-info" /> There are new alert(s) for this matter since you last viewed it.</p>
                    </div>
                ) : ''}
               

                {matter.alerts.map((alert, i) => (
                    <div className='section' key={alert._id}>
                        <div className='archk-case-alerts-alert'>

                            <p className='mb-0'>
                                {alert.created_by ? <ObjectFinder collection="users" _id={alert.created_by} />  : 'SYSTEM'}
                                <span style={styles.date} className='float-right text-sm'>
                                    {moment.unix(alert.created_at).format('MM/DD/YYYY')}
                                </span>
                            </p>

                            <div className='border rounded bg-secondary px-4 py-3 mb-3'>
                                <p className='text-sm mb-0'>
                                    <i className="fas fa-exclamation-triangle text-warning mr-2" />{' '}
                                    {alert.body}
                                </p>
                            </div>

                            {resolved.includes(alert._id) ? (
                                <p className={`text-sm text-right ${i + 1 === matter.alerts.length ? 'mb-0' : ''}`}>
                                    <i className="fas fa-check text-success mr-2" />
                                    Resolved On{' '}
                                    {moment().format('MM/DD/YYYY')}
                                    {' by '} 
                                    <ObjectFinder collection="users" _id={viewing_user._id} />
                                </p>
                            ) : alert.resolved_at ? (
                                <p className={`text-sm text-right ${i + 1 === matter.alerts.length ? 'mb-0' : ''}`}>
                                    <i className="fas fa-check text-success mr-2" />
                                    Resolved On{' '}
                                    {moment.unix(alert.resolved_at).format('MM/DD/YYYY')}
                                    {' by '} 
                                    {alert.resolved_by ? <ObjectFinder collection="users" _id={alert.resolved_by} />  : 'SYSTEM'}
                                </p>
                            ) : (
                                <div className='text-right'>
                                    <button onClick={() => onResolve(alert._id)} className='btn btn-sm btn-outline-danger'>Mark Resolved</button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}

                <div className='section text-right border-bottom bg-secondary'>
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            className="custom-control-input"
                            id="archk-highlight-on-answer"
                            type="checkbox"
                            checked={markRead}
                            onChange={(e) => setMarkRead(!markRead)}
                        />
                        <label className="custom-control-label" htmlFor="archk-highlight-on-answer">
                            Don't show this set of alerts again for this matter
                        </label>
                    </div>
                    <button onClick={onClose} className='btn btn-outline-info btn-sm'><i className="fas fa-arrow-left" mr-2 /> Close Alerts</button>
                </div>

            </div>
        </div>

    )
}

const styles = {
    date: {
        position: 'relative',
        top: 5
    }
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(MatterAlerts);