import React from "react";
import Select from 'react-select';
import { FormGroup } from 'reactstrap'

import _selectBase from './_selectBase'

const ReactSelect = ({formGroup, title, description, invalid, placeholder, onChange, options, value, menuPlacement, formatOptionLabel}) => {

    // if value passed is not an object but matches the value of one of the objects 
    // find the object and pass it in as the value
    if(value || value === 0 || value === false) {
        const found = options.find(o => o.value === value.value);
        if(!found) {
            value = options.find(o => o.value === value);
        }
    }

    const onMenuOpen = () => {
        setTimeout(()=>{
          const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
          if(selectedEl){
            selectedEl.scrollIntoView({ block: 'center'});
          }
        },25);
      };

    const select = (
        <Select
            {..._selectBase}
            formatOptionLabel={formatOptionLabel}
            className={invalid ? "invalid react-select cursor-pointer" : "react-select cursor-pointer"}
            placeholder={placeholder}
            onChange={(obj) => obj ? onChange(obj) : {}}
            options={options}
            value={value}
            menuPlacement={menuPlacement ? menuPlacement : undefined}
            classNamePrefix={"MyDropdown"}
            // menuIsOpen={true}
            onMenuOpen={onMenuOpen}
        />
    )

    if(formGroup) return (
        <FormGroup>
            {title ? <label className="form-control-label">{title}</label> : null}
            {description ? <p className="text-sm mb-0">{description}</p> : null}
            {select}
        </FormGroup>
    )

    return select
}

export default ReactSelect
