import { connect } from 'react-redux';
import { Modal, FormGroup, Input } from "reactstrap";
import { useState, useCallback, useEffect }  from 'react'
import { toast } from 'react-toastify'

import api from 'api';

const ModalCustomFields = ({showModal, toggleModal, matter, link, setMatterLinks}) => {

    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    const onSave = useCallback(async () => {
        setDisabled(true)
        const saved = await api.matters.linkAdd({
            matter          : matter._id,
            workflow_link   : link.link._id,
            link            : value ? value : '__remove',
        })
        setDisabled(false)
        if(!saved.success) return toast.error('Link not saved, please try again.')
        if(saved.data && saved.data.links) setMatterLinks(saved.data.links)
        toggleModal()

    }, [matter._id, toggleModal, value, link, setMatterLinks])

    useEffect(() => {
        if(link && link.link) {
            setValue(link.value)
            setDisabled(false)
        }
    }, [link])

    if(!link) return <></>

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit Link</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary border-bottom">

                <p className="text-sm mb--2"><b className='text-dark'>Name:</b></p>
                <p className="text-sm mb-1">{link.link.name}</p>

                {link.link.description ? (
                    <div>
                        <p className="text-sm mb--2"><b className='text-dark'>Description:</b></p> 
                        <p className="text-sm mb-1">{link.link.description}</p> 
                    </div>
                ) : ''}
                
                <p className="text-sm mb--2"><b className='text-dark'>Full Link:</b></p>
                <p className="text-sm mb-0" style={{wordWrap: 'break-word'}}>{link.link.link}</p>

            </div>

            <div className="modal-body">
                <FormGroup>
                    <label className='form-control-label'>Enter Link Value</label>
                    <Input 
                        type="text"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </FormGroup>
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" />
                    Close
                </button>
                <button className="btn btn-success" onClick={onSave} disabled={disabled}>
                    Save
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(ModalCustomFields);