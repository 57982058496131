import { useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Card, CardFooter, Col, Row } from "reactstrap";

import ComponentsLastNote from "../components/LastNote";
import A from 'components/markup/links/A'

import Locations from './Locations';

import hasPrivilege from 'utils/hasPrivilege'
import { isValidURL } from 'utils/urls'

const Title = ({title}) => (
    <h3 className="text-upperase text-dark mb-0 ">{title}</h3>
)

const MattersViewMainOverview = ({matter, workflowLocations, fetchWorkflowLocations, customFields, fetchCustomFields, setTab }) => {

    const [fields, setFields] = useState([])
    useEffect(() => {
        if(!customFields) {
            fetchCustomFields()
        } else {
            let _fields = [];
            matter.workflow_step.custom_fields.forEach(s => {
                const foundField = customFields.find(ff => ff._id === s);
                if(foundField) _fields.push(foundField)

            })
            setFields(_fields)
        }
    }, [customFields, fetchCustomFields, matter.workflow_step.custom_fields])

    return (
        <div style={{minWidth: 250}}>

            <div className="section">

                {hasPrivilege('NOTES.READ') ? (
                    <Card>

                        <CardFooter>
                            <Title title="Last Note" />
                        </CardFooter>
    
                        <CardFooter className="bg-white">
                            <ComponentsLastNote />
                        </CardFooter>
                    </Card>
                ) : ''}
               

                {fields.length ? (
                    <Card>
                        <CardFooter className="">
                            <Row>
                                <Col md={6} className="align-self-center">
                                    <Title title="Step Information" />
                                </Col>
                                <Col md={6} className="align-self-center text-right">
                                    <button onClick={() => setTab('CustomFields')} className="btn btn-success btn-sm"><i className="fas fa-edit mr-2" /> Edit</button>
                                </Col>
                            </Row>
                        </CardFooter>
  
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Answer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fields.map(c => {
                                    const answer = matter.custom_fields[c._id]
                                    return (
                                        <tr key={c._id}>
                                            <td>{c.name}</td>
                                            <td>
                                                {!answer ? (
                                                    <i className="fas fa-times text-danger" />
                                                ) : (
                                                    isValidURL(answer) ? (
                                                        <A href={answer}>{answer}</A>
                                                    ) : (
                                                        answer
                                                    )
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                               

                            </tbody>
                        </table>
                    </Card>
                ) : ''}
                
                {hasPrivilege('WORKFLOWS.READ') ? (
                    <Card>

                        <CardFooter className="">
                            <Title title="Matter Locations" />
                        </CardFooter>
    
                        <Locations 
                            workflowLocations={workflowLocations}
                            fetchWorkflowLocations={fetchWorkflowLocations}
                        />
    
                    </Card>
                ) : ''}
                           
            </div>
        </div>

    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainOverview);
