import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';

import moment from 'moment';
import { Col, Row } from "reactstrap";

import api from 'api';

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';
import reactHTMLParser from 'html-react-parser';

const MattersViewMainOverview = ({matter, socket}) => {

    const [lastNote, setLastNote] = useState(null)

    const name = lastNote ? lastNote.type === 'system' ? 'SYSTEM' : lastNote.user ? <ObjectFinder collection="users" _id={lastNote.user}/>  : 'Unknown' : ''


    const fetchLastNote = useCallback(async () => {
        const notes = await api.notes.find(`?limit=1&skip=0&type=user&matter=${matter._id}`)
        if(notes.data) setLastNote(notes.data[0] ? notes.data[0] : 'not found')
    }, [matter._id])

    const onMessage = useCallback((params) => {
        if(params.matter && params.matter === matter._id) {
            if(params.type === 'user') {
                setLastNote(params)
            }
        }
    }, [matter._id])

    useEffect(() => {
        socket.on('NOTES.CREATED', onMessage)
        return () => {
            socket.off('NOTES.CREATED', onMessage)
        }

    }, [socket, onMessage])

    useEffect(() => {
        fetchLastNote()
    }, [fetchLastNote])

    return (

            <div className="mb-0 p-sm">
                {lastNote === 'not found' ? (
                    <p className="text-sm my-4 text-center"><i className="fas fa-info-circle mr-2 text-info" /> No notes were found for this case</p>
                ) : !lastNote ? (
                    <Circle />
                ) : (
                    <div className="py-2">
                        {reactHTMLParser(lastNote.value ? lastNote.value : lastNote.user_comment && lastNote.user_comment.body ? lastNote.user_comment.body : 'No Notes Found' )}
                    </div>
                )}

                {lastNote && lastNote !== 'not found' ? (
                    <Row className="border-top pt-2">
                        <Col xs={6}>
                            <p className="text-sm mb-0"><b className="text-dark">{name}</b></p> 
                        </Col>
                        <Col xs={6} className="text-right">
                            <p className="text-sm mb-0 text-muted"><i className="fas fa-clock mr-2 " /> 
                                {moment.unix(lastNote.created_at).format('MMM Do, YYYY')}
                                {' at ' }
                                {moment.unix(lastNote.created_at).format('h:mm A')}
                            </p>
                        </Col>
                    </Row>
                ) : ''}

            </div>


    )

}

const mapStateToProps = state => {
	return {
	    socket: state.socket,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainOverview);
