import moment from 'moment';

import ObjectFinder from 'components/system/Objects/Finder';

const TextsMain = ({text}) => {

    const outbound = text.type === 'outbound' ? true : false;

    return (
        <div>
            <div className={`mx-3 my-1 p-3 d-inline-block ${outbound ? 'ml-7' : 'mr-7'} `}>
                <p className='text-sm mb-0'> 
                    {/* <i className="fas fa-comment text-info mr-2" />  */}

                    <i className={`fa-solid mr-1  ${outbound ? 'fa-arrow-up text-success' : 'fa-arrow-down text-info'}`} /> 
                    <i className={`fa-solid fa-comments mr-4 ${outbound ? 'text-success' : 'text-info'}`} /> 

                    {moment.unix(text.created_at).format('MMM Do, h:mm A')}
                    {' - '}
                    {text.user ? <ObjectFinder collection="users" _id={text.user} /> : <ObjectFinder collection="contacts" _id={text.contact} />}
                </p>

                <div className='px-4 py-4 bg-white rounded border'>
                    <div className=''>
                    
                        <p className='text-sm mb-0'>
                            {text.body}
                        </p>

                    </div>

                </div>

            </div>
        </div>
    )

}

export default TextsMain