import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";

import api from 'api';

import { toast } from 'react-toastify';


import ModalToggler from 'components/functional/modals/Toggler';
import ObjectFinder from 'components/system/Objects/Finder';

import Add from './Add';
import Edit from './Edit';

const MattersViewMainTasks = ({matter, selected_division, fetchMatterEvents}) => {

    const [leadSources, setLeadSources] = useState(matter.lead_sources)
   
    const onAddSource = useCallback((src) => {
        const sources = JSON.parse(JSON.stringify(leadSources))
        const index = sources.findIndex(i => i.lead_source === src.lead_source);

        if(index === -1) {
            sources.push(src)
            setLeadSources(sources)
        } else {
            toast.info(`The lead source you have selected already exists on the matter.`)
        }
    }, [leadSources])
   
    const onRemove = useCallback((_id) => {
        let sources = JSON.parse(JSON.stringify(leadSources))
        sources = sources.filter(s => s._id !== _id);
        setLeadSources(sources)

    }, [leadSources])

    const onEditSource = useCallback((src) => {
        const sources = JSON.parse(JSON.stringify(leadSources))
        const index = sources.findIndex(i => i.lead_source === src.lead_source);
        if(index !== -1) {
            sources[index] = src;
            setLeadSources(sources)
        }
    }, [leadSources])
    


    const onSave = useCallback(async () => {

        const saved = await api.matters.leadSources(matter._id, {
            lead_sources: leadSources
        })

        if(!saved.success) {
            try {
                toast.error(saved.message[0]);
            } catch(e) {
                return toast.error(`Something went wrong updating this matters lead sources, please try again`);
            }
        }
        toast.success(`Matter Updated Successfully`)

    }, [matter._id, leadSources])

    return (
        <div className="section pt-0">
            <Card className='card-no-shadow'>

                <CardHeader className="py-3">
                    <Row>
                        <Col md={6} className='align-self-center'>
                            <CardTitle className="mb-0">Lead Sources</CardTitle>
                        </Col>
                        <Col md={6} className='align-self-center text-right'>
                            <ModalToggler component={Add} onAddSource={onAddSource} >
                                <button className='btn btn-sm btn-info'>Add Source</button>
                            </ModalToggler>
                        </Col>
                    </Row>
                </CardHeader>

                {leadSources.length ? (
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Lead Source</th>
                                    <th className='text-right'>Percentage</th>
                                    <th className='text-right'></th>
                                </tr>
                            </thead>

                            <tbody>
                                {leadSources.map((l, i) => (
                                    <tr key={i} className='bg-secondary'>
                                        <td><ObjectFinder collection="lead_sources" _id={l.lead_source} /></td>
                                        <td className='text-right'>{l.lead_source_percent}%</td>
                                        <td className='text-right'>
                                            <ModalToggler component={Edit} onEditSource={onEditSource} onRemove={onRemove} lead_source={l}>
                                                <button className='btn btn-sm btn-warning'>Edit Source</button>
                                            </ModalToggler>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <CardFooter>
                        <p className='text-sm mb-0'>No Lead Sources Have Been Added For This Matter</p>
                    </CardFooter>
                )}
             
                <CardFooter className='text-right'>
                    <button onClick={onSave} className='btn btn-success btn-sm'>Confirm All</button>
                </CardFooter>
                
            </Card>

        </div>
    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainTasks);