import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import api from 'api';

import UnfinishedTaskTable from '../UnfinishedTaskTable'

const MyTasks = ({matter, viewing_user}) => {

    const [err, setErr] = useState(null)
    const [tasks, setTasks] = useState(null)

    const findTasks = useCallback(async () => {
        const tasks = await api.tasks.search({
            skip: 0,
            limit: 100,
            searchString: '',
            filter: {
                matter: matter._id,
                finished_on: 0,
                assigned_to: { $in: [viewing_user._id] }
            }
        })

        if(!tasks.success) return setErr(tasks.message)
        if(tasks.data) setTasks(tasks.data.documents)
    }, [viewing_user._id, matter._id])

    useEffect(() => {
        findTasks()
    }, [findTasks])

    return (
        <UnfinishedTaskTable 
            err={err}
            tasks={tasks}
            findTasks={findTasks}
            isStepTaskView={false}
        />
    )
  
}


const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MyTasks);