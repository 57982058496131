import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Row, UncontrolledDropdown } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';


import formatText from 'utils/formatText';

import AvatarImage from "components/functional/images/AvatarImage";

const PipelineCard = ({hideActions, user}) => {

    return (
        <Col xl={3} lg={3} className="mb-3">
            <Card className="mb-0">
                <CardHeader className=" text-cente">
                    <Row>

                        <div className="col-auto align-self-center" style={{width: 45}}>
                            <AvatarImage src="test" style={{width: 45}} />
                        </div>

                        <Col className="align-self-center">
                            <div className="pl-4">
                                <CardTitle className="mb-0 text-dark">
                                    {user.display_name}
                                </CardTitle>
                                <p className="text-sm mb--2 text-muted"><span>{user.email}</span></p>
                                <p className="text-sm mb-0 text-muted"><span>{formatText(user.phone).phone()}</span></p>
                            </div>
                        </Col>
                      
                    </Row>
                    
                </CardHeader>

                <CardBody className="py-2 bg-secondary">
                    <Row>
                        <Col xs={4} className="pr-1">
                            <i className="fas fa-check-circle mr-1 text-success " /> Active
                        </Col>
                        <Col xs={4} className="text-center px-1">
                            <i className="fas fa-check-circle mr-1 text-success " /> At Work {' '}
                        </Col>
                        <Col xs={4} className="text-right pl-1">
                            <i className="fas fa-check-circle mr-1 text-success " /> On Call {' '}
                        </Col>
                    </Row>
                </CardBody>

                <CardBody className="py-2">
                    <p className="text-sm mb-0 text-muted">Bankruptcy, Roadside, Personal Injury</p>
                </CardBody>

            </Card>
        </Col>
    )
}

const TableRow = ({user}) => (
    <tr>
        <td>
            <AvatarImage src="test" style={{width: 35}} />
            <spa className="pl-2">{user.display_name}</spa>
        </td>
        <td>
            <div>{user.email}</div>
            <div>{formatText(user.phone).phone()}</div>
        </td>
        <td>
            <div>Bankruptcy</div>
            <div>Roadside</div>
            <div>Personal Injury</div>
        </td>
        <td className="text-center"><i className="fas fa-check-circle mr-1 text-success " /> </td>
        <td className="text-center"><i className="fas fa-check-circle mr-1 text-success " /> </td>
        <td className="text-center"><i className="fas fa-check-circle mr-1 text-success " /> </td>
    </tr>
)

const ContactsAll = ({selected_division, viewing_user}) => {
    
    const [view, setView] = useState('card');
    const [filter, setFilter] = useState(false);
    const [sort, setSort] = useState(false);

    useEffect(() => {
        document.body.classList.add('bg-secondary');
        return () => document.body.classList.remove('bg-secondary');
    }, [])

    return (

        <>

        <HeaderNavigation 
            title="Team"
            description={`Directory: ${selected_division ? selected_division.name : ''}`}
            background="bg-white"
            leftColSize={4}
            actionComponent={(
                <div >

                    <UncontrolledDropdown>
                        <DropdownToggle color={filter ? 'success' : 'outline-info'}>
                            {filter ? filter : 'Filter'}  <i className="fas fa-filter ml-2" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={e => setFilter(false)}>NO FILTER</DropdownItem>
                            <DropdownItem onClick={e => setFilter('Overdue')}>Overdue</DropdownItem>
                            <DropdownItem onClick={e => setFilter('Upcoming')}>Upcoming</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                   
                    {view === 'card' ? (
                        <button className="btn btn-outline-success" onClick={() => setView('list')}>List View <i className="fas fa-list ml-2 " /></button>
                    ) : (
                        <button className="btn btn-outline-success" onClick={() => setView('card')}>Card View <i className="fas fa-address-card ml-2 " /></button>
                    )}
                </div>
            )}
        />

        <Container fluid>
    
            <Helmet>
                <title>{`Pipeline`}</title>
                <meta name="description" content="Pipeline" />
            </Helmet>

            <FormGroup className="form-group-search">
                {/* <label className="form-control-label">Search Team Members</label> */}
                <i className="fas fa-search " />
            <Input 
                placeholder="Search Team Members"
            />
            </FormGroup>

            {view === 'card' ? (
                <Row>
                    <PipelineCard user={viewing_user} />
                    <PipelineCard user={viewing_user} />
                    <PipelineCard user={viewing_user} />
                    <PipelineCard user={viewing_user} />
                    <PipelineCard user={viewing_user} />
                    <PipelineCard user={viewing_user} />
                    <PipelineCard user={viewing_user} />
                </Row>
            ) : (
                <Card>
                    <div className="table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Divisions</th>
                                    <th className="text-center">Active</th>
                                    <th className="text-center">At Work</th>
                                    <th className="text-center">On Call</th>
                                </tr>
                            </thead>

                            <tbody>
                                <TableRow user={viewing_user} />
                                <TableRow user={viewing_user} />
                                <TableRow user={viewing_user} />
                                <TableRow user={viewing_user} />
                                <TableRow user={viewing_user} />
                                <TableRow user={viewing_user} />
                                <TableRow user={viewing_user} />
                            </tbody>
                        </table>
                    </div>
                </Card>
            )}

       
        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(ContactsAll);
