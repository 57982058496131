/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    alerts: {
        show:     false,
        text:     null,
        type:     null,
    },
    login_redirect: '',
    loader: false,
    standardLoader: false,
   
    archk_alerts: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.TOGGLE_SYSTEM_ALERT:

            return {
                ...state,
                alerts: {
                    show: action.payload.boolean,
                    text: action.payload.text,
                    type: action.payload.type,
                }
            }

        case actionTypes.TOGGLE_SYSTEM_LOADER:

            return {
                ...state,
                loader: action.payload,
            }

        case actionTypes.SET_LOGIN_REDIRECT:

            return {
                ...state,
                login_redirect: action.payload,
            }

        case actionTypes.TOGGLE_STANDARD_LOADER:

            return {
                ...state,
                standardLoader: action.payload,
            }
        
        case actionTypes.SET_ARCHK_ALERTS:

            return {
                ...state,
                archk_alerts: action.payload,
            }

        default:

            return state;

    }

}

export default reducer;
