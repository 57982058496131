import { Card, CardBody, CardHeader, CardTitle, Row, Col, Badge } from 'reactstrap';

import ModalToggler from 'components/functional/modals/Toggler'
import ObjectFinder from 'components/system/Objects/Finder';

import Add from './Add'

const Payments = ({matter, payment_methods, fetchData}) => {

    const now = Math.floor(new Date() / 1000);

    return (
        <Card>
            <CardHeader className='py-3'>
                <Row>
                    <Col md={6} className="align-self-center">
                        <CardTitle className="mb-0">Payment Methods</CardTitle>
                    </Col>
                    <Col md={6} className="align-self-center text-right">
                        <ModalToggler matter={matter} fetchData={fetchData} component={Add}>
                            <button className='btn btn-outline-success btn-sm'>Set Default</button>
                            <button className='btn btn-info btn-sm'>Add Card / Bank</button>
                        </ModalToggler>
                    </Col>
                </Row>
            </CardHeader>

            {payment_methods.length ? (
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Ends In</th>
                                <th>Expires</th>
                                <th>Name On Card</th>
                                <th>Zip</th>
                                <th>Default</th>
                                <th>Next Payment</th>
                                <th><i className="fas fa-check text-success font-weight-bold" /></th>
                                <th><i className="fas fa-times text-danger font-weight-bold" /></th>
                                <th className='text-right'>Added By</th>
                                <th className='text-right'></th>
                            </tr>
                        </thead>

                        <tbody>
                            {payment_methods.map(method => (
                                <tr key={method._id}>
                                    <td>{method.last_4}</td>
                                    <td>
                                        <span className={method.expires_unix < now ? 'text-danger' : ''}>
                                            {method.expires}
                                        </span> 
                                    </td>
                                    <td>{method.owner_name}</td>
                                    <td>{method.zip}</td>
                                    <td>
                                        {method.primary_method ? (
                                            <Badge style={styles.badge} color="success">Yes</Badge>
                                        ) : (
                                            <Badge style={styles.badge} color="info">No</Badge>
                                        )}
                                    </td>
                                    <td>
                                        {method.next_payment_success_rate === 0 && !method.payments_succeeded && !method.payments_failed ? (
                                            '-'
                                        ) : (
                                            <span>{method.next_payment_success_rate.toFixed(2)}%</span>
                                        )}
                                        
                                    </td>
                                    <td>{method.payments_succeeded}</td>
                                    <td>{method.payments_failed}</td>
                                    <td className='text-right'>
                                        {method.user ? (
                                            <ObjectFinder collection="users" _id={method.user} />
                                        ) : (
                                            <ObjectFinder collection="contacts" _id={method.contact} />
                                        )}
                                    </td>
                                    <td className='text-right'>
                                        <i className="cursor-pointer fas fa-edit text-success" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    <CardBody className='py-8'>
                        <p className="text-ss mb-0 text-center">
                            <i className="fas fa-info-circle mr-3 text-info" /> 
                            No Payment Methods Have Been Added To This Matter.
                        </p>
                    </CardBody>

                    
                </div>
            )}
        </Card>
    )

}

const styles = {
    badge: {
        width: 45
    }
}

export default Payments