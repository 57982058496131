import { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import Day from 'components/markup/inputs/Day';

import moment from 'moment-timezone';

const Minutes15 = 15 * 60;

const businessHours = [

    { value: Minutes15 * 36,  label: '9:00 AM' },
    { value: Minutes15 * 37,  label: '9:15 AM' },
    { value: Minutes15 * 38,  label: '9:30 AM' },
    { value: Minutes15 * 39,  label: '9:45 AM' },

    { value: Minutes15 * 40,  label: '10:00 AM' },
    { value: Minutes15 * 41,  label: '10:15 AM' },
    { value: Minutes15 * 42,  label: '10:30 AM' },
    { value: Minutes15 * 43,  label: '10:45 AM' },

    { value: Minutes15 * 44,  label: '11:00 AM' },
    { value: Minutes15 * 45,  label: '11:15 AM' },
    { value: Minutes15 * 46,  label: '11:30 AM' },
    { value: Minutes15 * 47,  label: '11:45 AM' },


    { value: Minutes15 * 48,  label: '12:00 PM' },
    { value: Minutes15 * 49,  label: '12:15 PM' },
    { value: Minutes15 * 50,  label: '12:30 PM' },
    { value: Minutes15 * 51,  label: '12:45 PM' },

    { value: Minutes15 * 52,  label: '1:00 PM' },
    { value: Minutes15 * 53,  label: '1:15 PM' },
    { value: Minutes15 * 54,  label: '1:30 PM' },
    { value: Minutes15 * 55,  label: '1:45 PM' },

    { value: Minutes15 * 56,  label: '2:00 PM' },
    { value: Minutes15 * 57,  label: '2:15 PM' },
    { value: Minutes15 * 58,  label: '2:30 PM' },
    { value: Minutes15 * 59,  label: '2:45 PM' },

    { value: Minutes15 * 60,  label: '3:00 PM' },
    { value: Minutes15 * 61,  label: '3:15 PM' },
    { value: Minutes15 * 62,  label: '3:30 PM' },
    { value: Minutes15 * 63,  label: '3:45 PM' },

    { value: Minutes15 * 64,  label: '4:00 PM' },
    { value: Minutes15 * 65,  label: '4:15 PM' },
    { value: Minutes15 * 66,  label: '4:30 PM' },
    { value: Minutes15 * 67,  label: '4:45 PM' },

    { value: Minutes15 * 68,  label: '5:00 PM' },
    { value: Minutes15 * 69,  label: '5:15 PM' },
    { value: Minutes15 * 70,  label: '5:30 PM' },
    { value: Minutes15 * 71,  label: '5:45 PM' },

    { value: Minutes15 * 72,  label: '6:00 PM' },
]


const fullHours = [
    { value: Minutes15 * 0,  label: '12:00 AM' },
    { value: Minutes15 * 1,  label: '12:15 AM' },
    { value: Minutes15 * 2,  label: '12:30 AM' },
    { value: Minutes15 * 3,  label: '12:45 AM' },
  
    { value: Minutes15 * 4,  label: '1:00 AM' },
    { value: Minutes15 * 5,  label: '1:15 AM' },
    { value: Minutes15 * 6,  label: '1:30 AM' },
    { value: Minutes15 * 7,  label: '1:45 AM' },
  
    { value: Minutes15 * 8,  label: '2:00 AM' },
    { value: Minutes15 * 9,  label: '2:15 AM' },
    { value: Minutes15 * 10, label: '2:30 AM' },
    { value: Minutes15 * 11, label: '2:45 AM' },
   
    { value: Minutes15 * 12,  label: '3:00 AM' },
    { value: Minutes15 * 13,  label: '3:15 AM' },
    { value: Minutes15 * 14,  label: '3:30 AM' },
    { value: Minutes15 * 15,  label: '3:45 AM' },

    { value: Minutes15 * 16,  label: '4:00 AM' },
    { value: Minutes15 * 17,  label: '4:15 AM' },
    { value: Minutes15 * 18,  label: '4:30 AM' },
    { value: Minutes15 * 19,  label: '4:45 AM' },
   
    { value: Minutes15 * 20,  label: '5:00 AM' },
    { value: Minutes15 * 21,  label: '5:15 AM' },
    { value: Minutes15 * 22,  label: '5:30 AM' },
    { value: Minutes15 * 23,  label: '5:45 AM' },
  
    { value: Minutes15 * 24,  label: '6:00 AM' },
    { value: Minutes15 * 25,  label: '6:15 AM' },
    { value: Minutes15 * 26,  label: '6:30 AM' },
    { value: Minutes15 * 27,  label: '6:45 AM' },

    { value: Minutes15 * 28,  label: '7:00 AM' },
    { value: Minutes15 * 29,  label: '7:15 AM' },
    { value: Minutes15 * 30,  label: '7:30 AM' },
    { value: Minutes15 * 31,  label: '7:45 AM' },
    
    { value: Minutes15 * 32,  label: '8:00 AM' },
    { value: Minutes15 * 33,  label: '8:15 AM' },
    { value: Minutes15 * 34,  label: '8:30 AM' },
    { value: Minutes15 * 35,  label: '8:45 AM' },

    ...businessHours,

    { value: Minutes15 * 73,  label: '6:15 PM' },
    { value: Minutes15 * 74,  label: '6:30 PM' },
    { value: Minutes15 * 75,  label: '6:45 PM' },

    { value: Minutes15 * 76,  label: '7:00 PM' },
    { value: Minutes15 * 77,  label: '7:15 PM' },
    { value: Minutes15 * 78,  label: '7:30 PM' },
    { value: Minutes15 * 79,  label: '7:45 PM' },

    { value: Minutes15 * 80,  label: '8:00 PM' },
    { value: Minutes15 * 81,  label: '8:15 PM' },
    { value: Minutes15 * 82,  label: '8:30 PM' },
    { value: Minutes15 * 83,  label: '8:45 PM' },

    { value: Minutes15 * 84,  label: '9:00 PM' },
    { value: Minutes15 * 85,  label: '9:15 PM' },
    { value: Minutes15 * 86,  label: '9:30 PM' },
    { value: Minutes15 * 87,  label: '9:45 PM' },

    { value: Minutes15 * 88,  label: '10:00 PM' },
    { value: Minutes15 * 89,  label: '10:15 PM' },
    { value: Minutes15 * 90,  label: '10:30 PM' },
    { value: Minutes15 * 91,  label: '10:45 PM' },

    { value: Minutes15 * 92,  label: '11:00 PM' },
    { value: Minutes15 * 93,  label: '11:15 PM' },
    { value: Minutes15 * 94,  label: '11:30 PM' },
    { value: Minutes15 * 95,  label: '11:45 PM' },
    { value: Minutes15 * 96,  label: '12:00 PM' },
]

const DateTime = ({startTime, endTime, onChange, title}) => {
    const day = parseInt(moment.unix(startTime).startOf('day').format('X'))
    const start = startTime - day;
    const end = endTime - day;

    // const [loaded, setLoaded] = useState(true)
    const [showFullHours, setShowFullHours] = useState(false)


    const onInputChange = useCallback((type, val) => {
        let _start;
        let _end;

        if(type === 'day') {
            _start = val + start
            _end = val + end
        } else if(type === 'start') {
            _start = day + val
            _end = day + end
        } else if(type === 'end') {
            _start = day + start
            _end =day + val
        }

        onChange(_start, _end, type)

    }, [day, end, onChange, start])

    return (
        <div className='bg-secondar position-relative'>
            <Day 
                title={title ? title : 'Select Date & Time:'} 
                value={moment.unix(day).toDate()} 
                onChange={val => onInputChange('day', parseInt(moment(val).format("X")))} 
            />

            <div className="custom-control custom-checkbox" style={{position: 'absolute', top: 0, right: 5}}>
                <input
                    className="custom-control-input"
                    id="archk-date-time-toggle"
                    type="checkbox"
                    checked={showFullHours}
                    onChange={(e) => setShowFullHours(!showFullHours)}
                />
                <label className="custom-control-label" htmlFor="archk-date-time-toggle">
                    Show Full Hours
                </label>
            </div>

            <Row>
                <Col lg={6}>
                    <ReactSelect
                        menuPlacement="top"
                        title={"Start"}
                        formGroup={true}
                        placeholder=""
                        onChange={(obj) => onInputChange('start', obj.value)}
                        options={showFullHours ? fullHours : businessHours}
                        value={start}
                    />    
                </Col>

                <Col lg={6}>
                    <ReactSelect
                        menuPlacement="top"
                        title={"End"}
                        formGroup={true}
                        placeholder=""
                        onChange={(obj) => onInputChange('end', obj.value)}
                        options={showFullHours ? fullHours : businessHours}
                        value={end}
                    />    
                </Col>
            </Row>

            {day && start && end ? (
                <div className='text-center'>
                    <i className="fas fa-clock text-info mr-2" />
                    {' '}
                    {moment.unix(day).add(start, 'seconds').format('MMM Do, YYYY h:mm A')}
                    {' - '}
                    {moment.unix(day).add(end, 'seconds').format('h:mm A')}
                    {' '}
                    <div> 
                        My Timezone: 
                        {' '}
                        <span className='text-success font-weight-bold'>{moment.tz.guess().replace('_', ' ')}</span>  
                    </div>
                </div>
            ) : ''}
        </div>
    )
}

export default DateTime