import FeedRenderer from 'components/system/Feed/Renderer'
import FeedCreate from 'components/system/Feed/Create';

import hasPrivilege from 'utils/hasPrivilege';

const MattersViewMainFeedPage = ({contact}) => (
    <div>
        {hasPrivilege('NOTES.WRITE') ? (
            <>
                <h2>Add A Note</h2>
                <FeedCreate contact_id={contact._id} />
            </>
        ) : ''}
        <div className="archk-matter-page section pt-0" style={{minHeight: 240}}>
            <FeedRenderer contact={contact._id} />
        </div>
    </div>
    
)

export default MattersViewMainFeedPage