import React from 'react';
import { FormGroup, Input, Row, Col } from "reactstrap";
import States from 'components/markup/inputs/States'

const ContactsUpdate = ({title, onInputChange, field, obj}) => {

    return (
    
        <FormGroup>
            <label className="form-control-label">{title ? title : 'Description'}</label>
            <Input 
                style={{minHeight: 50}}
                type="textarea"
                value={obj[field]}
                onChange={e => onInputChange(field, e.target.value)}
            />
        </FormGroup>    
    )

}

export default ContactsUpdate;