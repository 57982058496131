
import { useCallback, useState } from 'react';
import { useEffect } from "react";
import { connect } from 'react-redux';

import Header from './Header';
import Main from './Main';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';

import api from 'api'

import Circle from 'components/markup/loading/Circle'
import ObjectFinder from 'components/system/Objects/Finder';

import moment from 'moment';
import { toggleStandardLoader } from 'store/functions/system/system';

const Texts = ({socket, match}) => {

    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(null)
    const [thread, setThread] = useState(null)

    const scrollToBottom = useCallback((tries = 0, behavior) => {
        if(tries > 20) return;

        const bottomDiv = document.getElementById("archk-messaging-end");
        const container = document.querySelector(".archk-messaging-main");

        if(bottomDiv && container) return container.scroll({ top: container.scrollHeight, behavior });

        setTimeout(() => {
            scrollToBottom(tries + 1)
        }, 250)


    }, [])

    const onSetActive = useCallback(async (_active, killLoader) => {
        if(!_active) {
            if(killLoader) toggleStandardLoader(false)
            setActive(null)
            return setThread(null)
        }
        if(_active && _active._id && active && active._id && _active._id === active._id) {
            if(killLoader) toggleStandardLoader(false)
            return;
        }
        // if(active && active._id) api.text_threads.read(active._id, { read: true })

        setThread(null);

        const data = await api.support_tickets.findById(_active._id);
            if(data.data) {
                setThread(data.data)
                setActive(_active)
    
                scrollToBottom()
                if(killLoader) toggleStandardLoader(false)
            } else {
                if(killLoader) toggleStandardLoader(false)
            }

    }, [active, scrollToBottom])

    const onThreadUpdated = useCallback(async (params) => {
        if(thread && thread.ticket.contact === params.contact && thread.ticket.division === params.division) {
            // const _thread = JSON.parse(JSON.stringify(thread));

            const data = await api.support_tickets.findById(params._id);
            if(data.data) {
                setThread(data.data)
                setTimeout(() => {
                    scrollToBottom(0, 'smooth'); 
                }, 300);
            }

         
        }
    }, [thread, scrollToBottom])

    useEffect(() => {
        if(!match.params.thread_id) return;

        const fetchThread = async () => {
            setLoading(true)
            const data = await api.support_tickets.findById(match.params.thread_id);
            setLoading(false)

            if(data.data) {
                setThread(data.data)
                setActive(data.data.thread)

                scrollToBottom()
            }
        }

        fetchThread()
    }, [match.params.thread_id, scrollToBottom])


    useEffect(() => {
        socket.on('SUPPORT_TICKETS.UPDATED', onThreadUpdated)

        return () => {
            socket.off('SUPPORT_TICKETS.UPDATED', onThreadUpdated)
        }

    }, [socket, onThreadUpdated])
    
    useEffect(() => {
        document.body.classList.add('noScroll')

        return () => {
            document.body.classList.remove('noScroll')
        }
    }, [])

    if(loading) return <Circle className="py-6" />

    return (

        <div className="archk-messaging">
            <div className="archk-messaging-wrapper">

                <Header 
                    thread={thread}
                    active={active}
                />

                <SidebarLeft
                    thread={thread}
                    active={active}
                    setActive={onSetActive}
                />

                {thread && thread.ticket.resolved_at ? (
                    <div className='archk-messaging-resolved  text-center alert alert-info'>
                        <i className="fas fa-info-circle mr-2" />
                        This ticket was resolved by 
                        {' '}
                        <b className='text-underline font-weight-bold'>
                            <ObjectFinder collection="users" _id={thread.ticket.resolved_by} />
                        </b>
                        {' at '}
                        <b className='text-underline font-weight-bold'>
                            {moment.unix(thread.ticket.resolved_at).format('MM/DD/YYYY h:mm A')}
                        </b>
                    </div>
                ) : ''}
    
                <Main 
                    thread={thread}
                    active={active}
                />
                            
                <SidebarRight 
                    thread={thread}
                    active={active}
                /> 
        
            </div>
        </div>
    )

}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Texts);
