import ModalToggler from 'components/functional/modals/Toggler';
import CallContact from 'components/system/CallContact';

import formatText from 'utils/formatText';

const CallsNotOnCall = ({matter, contact, phone, color}) => {

    if(!phone) return <></>;

   return (
        <ModalToggler component={CallContact} matter={matter} contact={contact} phone={phone}>
            <span className='cursor-pointer border-sucess rounded font-weight-bold'>
                <i className={`fas fa-phone mr-2 ${color ? 'text-' + color : ''}`} />
                {formatText(phone).phone()}
            </span>
        </ModalToggler>
   )
}

export default CallsNotOnCall