import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Progress, Row } from "reactstrap";
import { toast } from 'react-toastify';

import Circle from 'components/markup/loading/Circle';

import moment from 'moment';
import api from 'api';

import hasPrivilege from 'utils/hasPrivilege'

const Overview = ({ total, title, completed }) => {

    const percent = completed || total ? ((completed / total) * 100 ).toFixed(2) : 100

    let className = "";
    if(percent >= 0 && percent < 19.99) className = 'danger'
    if(percent >= 20 && percent < 49.99) className = 'warning'
    if(percent >= 50 && percent < 97.99) className = 'info'
    if(percent >= 98) className = 'success'

    return (
        <Card className='my-3' style={{borderRadius: 6}}>
            <CardBody>
                <div className="progress-wrapper pt-3">
                    <div className={`progress-info mb-0 mt--2`}>
                        <div className="progress-label">
                            <span className={`bg-white font-weight-bold text-sm ml--4 text-${className}`}>
                                 {title}
                            </span>
                        </div>
                        <div className="progress-percentage">
                            <span>{ percent}%</span>
                        </div>
                    </div>
                    <Progress max="100" value={percent < 5 ? 5 : percent} color={className} />
                    <div className="mt--2">{completed}/{total}</div>
                </div>
            </CardBody>
        </Card>
    )
}

const ItemCard = ({title, count, link, privilege}) => {

    let color =
        count === 0 ? 'success' :
        count < 10 ? 'info' :
        count < 50 ? 'warning' :
        'danger'

    const Wrapper = ({children}) => {
        if(privilege && hasPrivilege(privilege)) {
            return (
                <Link to={link}>
                    {children}
                </Link>
            )
        }

        return <>{children}</>
    }
   
    return (
        <Wrapper>
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0"><i className={`fas fa-clock mr-2 text-${color} `} /> {title}</CardTitle>
                </CardHeader>
                
                <div className="table table-responsive">
                    {count === 0 ? (
                        <i className="fas fa-check-circle text-success pt-6 bg-secondary" />
                    ) : (
                        <h2 className={`fa-number text-${color}`}>{count >= 99 ? '99+' : count}</h2>
                    )}
                </div>

                <CardFooter>
                    <p className="text-sm text-right mb-0">
                        {count === 0 ? `You Have No ${title}` : <span>You have <b className="text-underline">{count}</b> {title}</span>}
                    </p>
                </CardFooter>
            </Card>
        </Wrapper>
    )
}

const ChangeCard = ({title, percent}) => {

    let color = percent >= 0 ? 'success' : 'warning'

    return (
        <Card className="text-center">
            <CardHeader>
                <CardTitle className="mb-0">{title}</CardTitle>
            </CardHeader>
            


            <CardFooter className="text-center">
                <h2 className={`display-2 text-${color}`}>
                    {percent < 0 ? '' : '+'}{percent.toLocaleString()}%
                </h2>
            </CardFooter>
        </Card>
    )
}

const AchievementCard = ({title, matters, percent}) => {

    let avg = parseFloat((percent / matters).toFixed(2))
    if(Number.isNaN(avg)) avg = 0;

    let color = avg > 0 ? 'success' : avg === 0 ? 'muted' : 'warning'

    const Wrapper = ({children}) => {
        if(hasPrivilege('MATTER_PROGRESS_LOGS.READ')) {
            return (
                <Link to="/matter_progress">
                    {children}
                </Link>
            )
        }

        return <>{children}</>
    }

    return (
        <Wrapper>
            <Card className="text-center">
                <CardHeader>
                    <CardTitle className="mb-0">{title}</CardTitle>
                </CardHeader>
                


                <CardBody className="text-center">
                    <h4 className={`display-3 text-${color}`}>
                        {avg < 0 ? '' : '+'}{avg.toLocaleString()}% <small><small className="text-muted"><small>AVG</small></small></small>
                    </h4>
                </CardBody>

                <CardFooter className="text-center">
                    {matters} Matters <i className="fas fa-arrow-right mx-2 text-info font-weight-bold" /> {percent.toLocaleString()}% Total
                </CardFooter>
            </Card>
        </Wrapper>
    )
}

const DashboardOverview = ({selected_division}) => {

    const canViewTasks = hasPrivilege('TASKS.READ') &&  hasPrivilege('VIEW.APP_DASHBOARD.TASKS');
    const canViewEvents = hasPrivilege('EVENTS.READ') &&  hasPrivilege('VIEW.APP_DASHBOARD.EVENTS');
    const canViewDocuments = hasPrivilege('DOCUMENTS.READ') &&  hasPrivilege('VIEW.APP_DASHBOARD.DOCUMENTS');
    const canViewMatters = hasPrivilege('MATTERS.READ') &&  hasPrivilege('VIEW.APP_DASHBOARD.MATTERS');
    const canViewTexts = hasPrivilege('TEXTS.READ') &&  hasPrivilege('VIEW.APP_DASHBOARD.TEXTS');
    const canViewEmails = hasPrivilege('EMAILS.READ') &&  hasPrivilege('VIEW.APP_DASHBOARD.EMAILS');

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const result = await api.dashboard.overview(selected_division._id ? selected_division._id : 'all')
        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)

        setData(result.data)
    }, [selected_division._id])

    useEffect(() => {
        fetchData();

        document.body.classList.add('bg-secondary');
        return () => document.body.classList.remove('bg-secondary');
    }, [fetchData])

    if(!data) return <Circle className="py-6" />

    return (

        <>



        <Container className="ful mt--4 pt-4" fluid>
    
            <Helmet>
                <title>{`Pipeline`}</title>
                <meta name="description" content="Pipeline" />
            </Helmet>

            <h2 className="display-4 mb-0 mt-4 text-uppercase ">My Week At A Glance</h2>
            <p className="text-sm mb-0">
                {moment.unix(data.startOfWeek).format('MMM Do h:mm A')}{' - '}
                {moment.unix(data.endOfWeek).format('MMM Do h:mm A')}
            </p>

            {canViewTasks || canViewEvents ? (
                <Row className="archk-overview">
                    {canViewEvents ? (
                        <Col xl={6} lg={6}>
                            <Overview 
                                title="This Weeks Events"
                                total={data.data.events_this_week}
                                completed={data.data.events_completed_this_week}
                            />
                        </Col>
                    ) : ''}

                    {canViewTasks ? (
                        <Col xl={6} lg={6}>
                            <Overview 
                                title="This Weeks Tasks"
                                total={data.data.tasks_this_week}
                                completed={data.data.tasks_completed_this_week}
                            />
                        </Col>
                    ) : ''}
                    

                </Row>
            ) : <hr />}
            

            {canViewTasks || canViewEvents || canViewDocuments || canViewMatters ? (
                <div>
                     <h2 className="display-4 mb-0 text-uppercase mt-4">Outstanding Items</h2>

                    <Row className="archk-overview">
                        {canViewTasks ? (
                            <Col xl={3} lg={6}>
                                <ItemCard 
                                    count={data.data.tasks_overdue}
                                    title="Overdue Tasks"
                                    link={`/tasks?type=overdue`}
                                    privilege="TASKS.READ"
                                />
                            </Col>
                        ) : ''}
                        {canViewEvents ? (
                            <Col xl={3} lg={6}>
                                <ItemCard 
                                    count={data.data.events_overdue}
                                    title="Missed Appointments"
                                    link="/events?type=missed"
                                    privilege="EVENTS.READ"
                                />
                            </Col>
                        ) : ''}
                        
                        {canViewDocuments ? (
                            <Col xl={3} lg={6}>
                                <ItemCard 
                                    count={data.data.documents_pending}
                                    title="Outstanding Documents"
                                    link="/documents?type=pending"
                                    privilege="DOCUMENTS.READ"
                                />
                            </Col>
                        ) : ''}
                        
                        {canViewMatters ? (
                            <Col xl={3} lg={6}>
                                <ItemCard 
                                    count={data.data.matters_overdue}
                                    title="Steps Lingering"
                                    link="/matters/search/lingering"
                                    privilege="MATTERS.READ"
                                />
                            </Col>
                        ) : ''}
                        

                    </Row>

                </div>
            ) : ''}
           
            {canViewTexts || canViewEmails ? (
                <div>
                    <h2 className="display-4 mb-0 text-uppercase mt-4">My Communication</h2>

                    <Row className="archk-overview">
                        {canViewTexts ? (
                            <Col xl={6} lg={6}>
                                <ItemCard 
                                    count={data.data.text_threads}
                                    title="Unread Texts"
                                    link="/texts"
                                    privilege="TEXTS.READ"
                                />
                            </Col>
                        ) : ''}

                        {canViewEmails ? (
                            <Col xl={6} lg={6}>
                                <ItemCard 
                                    count={data.data.emails}
                                    title="Unread Emails"
                                    link="/emails"
                                    privilege="EMAILS.READ"
                                />
                            </Col>
                        ) : ''}
                    </Row>
                </div>
            ) : ''}

            <h2 className="display-4 mb-0 text-uppercase mt-4">This Weeks Achievements</h2>

            <Row className="archk-overview">
                <Col xl={3} lg={6}>
                    <AchievementCard 
                        title="Matter Percents Moved"
                        percent={data.data.matter_progress.total_percent}
                        matters={data.data.matter_progress.total_matters}
                    />
                </Col>
                <Col xl={3} lg={6}>
                    <AchievementCard 
                        title="Field Percents Moved"
                        percent={data.data.matter_progress_fields.total_percent}
                        matters={data.data.matter_progress_fields.total_matters}
                    />
                </Col>
                <Col xl={3} lg={6}>
                    <AchievementCard 
                        title="Docs Percents Moved"
                        percent={data.data.matter_progress_docs.total_percent}
                        matters={data.data.matter_progress_docs.total_matters}
                    />
                </Col>
                <Col xl={3} lg={6}>
                    <AchievementCard 
                        title="Tasks Percents Moved"
                        percent={data.data.matter_progress_tasks.total_percent}
                        matters={data.data.matter_progress_tasks.total_matters}
                    />
                </Col>

            </Row>

            {/* <h2 className="display-4 mb-0 text-uppercase mt-4">30 Day Change</h2>

            <Row className="archk-overview">
                <Col xl={3} lg={6}>
                    <ChangeCard 
                        percent={0}
                        title="Completed Events"
                    />
                </Col>
                <Col xl={3} lg={6}>
                    <ChangeCard 
                        percent={-22.78}
                        title="Completed Tasks"
                    />
                </Col>
                <Col xl={3} lg={6}>
                    <ChangeCard 
                        percent={13.00}
                        title="Completed Documents"
                    />
                </Col>
                <Col xl={3} lg={6}>
                    <ChangeCard 
                        percent={19.38}
                        title="Steps Moved"
                    />
                </Col>
            </Row>
     */}
        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);
