import { useEffect } from "react";
import { connect } from 'react-redux';

import { CardFooter, Col, Row } from "reactstrap";

import ModalToggler from 'components/functional/modals/Toggler';
import LocationsUpdate from "./Update";

const Location = ({title, location}) => (
    <div className="border bg-secondary z-depth- py-3 px-4 mb-3" style={{minHeight: 100}}>
        <p className="mb--2 text-sm font-weight-bold text-dark"><i className="fas fa-edit mr-2 text-success" /> {title}:</p>
        {location ? (
                <div>
                    <p className="text-s mb--2"> {location.name}</p>
                    <p className="text-sm mb--2">{location.address_line_1}{location.address_line_2 ? <span> {location.address_line_2}</span> : ''}</p>
                    <p className="text-sm mb-0">{location.city}, {location.state} {location.postal_code}</p>
                </div>
        ) : (
            <p className="mb-0 text-sm text-muted">No Location Found</p>
        )}
    </div>
)


const MattersViewMainOverview = ({matter, workflowLocations, fetchWorkflowLocations}) => {

    useEffect(() => {
        if(!workflowLocations) fetchWorkflowLocations()
    }, [workflowLocations, fetchWorkflowLocations])

    return (
        workflowLocations && workflowLocations.length ? (
            <CardFooter className="py-4 bg-white">


                <Row>
                    {workflowLocations.map(workflowLocation => {
                        const location = matter.locations.find(l => l.workflow_location && l.workflow_location._id === workflowLocation._id)
                        return (
                            <Col xl={4} md={6} key={workflowLocation._id} className="" >
                                <span className="cursor-pointer">
                                    <ModalToggler 
                                        key={workflowLocation._id} 
                                        component={LocationsUpdate} p
                                        location={workflowLocation} 
                                        currentLocation={location && location.location ? location.location : null}
                                    >
                                        <Location 
                                            title={workflowLocation.name}
                                            location={location ? location.location : ''}
                                        />
                                    </ModalToggler>
                                </span>

                               
                            </Col>
                        )
                    })}
                    
                </Row>

                

            </CardFooter>
        ) : <></>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MattersViewMainOverview);
