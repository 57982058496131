/*
Documentation

This page handles updating a users profile

*/

import { useCallback, useEffect, useState } from 'react';

import { Card, CardBody, CardFooter, Form, FormGroup, Row, Col, Input } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import { toast } from 'react-toastify';

import States from 'components/markup/inputs/States'
import Timezones from 'components/markup/inputs/Timezones'

import ApiError from 'components/markup/layout/ApiError'

const UserSettingsMatter = () => {

    const [user, setUser] = useState(null)
    const [err, setErr] = useState(null)


    const onSave = useCallback(async (e) => {
        e.preventDefault();

        if(!user.email) return toast.info('An email address must be set.')
        if(!user.phone) return toast.info('An phone number must be set.')

        toggleStandardLoader(true)
        const updated = await api.users.update(user._id, user)
        toggleStandardLoader(false)

        if(!updated.success) toast.error('Something went wrong updating your profile, please try again')
    }, [user])

    const onSettingsChange = useCallback((field, value) => {
        const newUser = JSON.parse(JSON.stringify(user));
        newUser.settings[field] = value;
        setUser(newUser)
    }, [user])


    const onInputChange = useCallback((field, value) => {
        const newUser = JSON.parse(JSON.stringify(user));
        newUser[field] = value;
        setUser(newUser)
    }, [user])

    const fetchData = useCallback(async () => {
        const viewing_user = await api.users.me()
        if(!viewing_user.data) setErr(viewing_user.message)

        if(viewing_user.data.given_name_unformatted) viewing_user.data.given_name = viewing_user.data.given_name_unformatted;
        if(viewing_user.data.family_name_unformatted) viewing_user.data.family_name = viewing_user.data.family_name_unformatted;

        setUser(viewing_user.data)
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return <ApiError err={err} />
    if(!user) return <Circle className="pt-4" />

    return (
        <Form>
        <Card>
            <CardBody>

                <h6 className="heading-small text-muted mb-4">User information</h6>

                <Row>

                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="given_name" >First name</label>
                            <Input
                                id="given_name"
                                value={user.given_name || ''}
                                type="text"
                                valid={ user.given_nameState === "valid" }
                                invalid={ user.given_nameState === "invalid" }
                                onChange={e => onInputChange("given_name", e.target.value) }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="family_name" >Last name</label>
                            <Input
                                id="family_name"
                                value={user.family_name || ''}
                                type="text"
                                valid={ user.family_nameState === "valid" }
                                invalid={ user.family_nameState === "invalid" }
                                onChange={e => onInputChange("family_name", e.target.value) }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                </Row>

                <Row>

                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="email">Email address</label>
                            <Input
                                id="email"
                                value={user.email || ''}
                                type="email"
                                valid={ user.emailState === "valid" }
                                invalid={ user.emailState === "invalid" }
                                onChange={e => onInputChange("email", e.target.value) }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                    <Col lg="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="phone">Phone</label>
                            <Input
                                id="phone"
                                value={user.phone || ''}
                                type="tel"
                                valid={ user.phoneState === "valid" }
                                invalid={ user.phoneState === "invalid" }
                                onChange={e => onInputChange("phone", e.target.value) }

                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                </Row>

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Address</h6>

                <Row>

                    <Col md="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="address_line_1" >Address Line 1</label>
                            <Input
                                id="address_line_1"
                                value={user.address_line_1 || ''}
                                type="text"
                                valid={ user.address_line_1State === "valid" }
                                invalid={ user.address_line_1State === "invalid" }
                                onChange={e => onInputChange("address_line_1", e.target.value) }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="address_line_2" >Address Line 2</label>
                            <Input
                                id="address_line_2"
                                value={user.address_line_2 || ''}
                                type="text"
                                valid={ user.address_line_2State === "valid" }
                                invalid={ user.address_line_2State === "invalid" }
                                onChange={e => onInputChange("address_line_2", e.target.value) }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                </Row>

                <Row>

                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="city" >City</label>
                            <Input
                                id="city"
                                value={user.city || ''}
                                type="text"
                                valid={ user.cityState === "valid" }
                                invalid={ user.cityState === "invalid" }
                                onChange={e => onInputChange("city", e.target.value) }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                    <Col lg="4">
                        
                        <States 
                            title="State"
                            value={user.state}
                            onChange={(val) => onInputChange('state', val)}
                        /> 
                    </Col>

                    <Col lg="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="postal_code" >Postal code</label>
                            <Input
                                id="postal_code"
                                value={user.postal_code || ''}
                                type="text"
                                valid={ user.postal_codeState === "valid" }
                                invalid={ user.postal_codeState === "invalid" }
                                onChange={e => onInputChange("postal_code", e.target.value) }
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>

                </Row>
                {/* <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Timezone</h6>


                <Timezones 
                    title="Timezone"
                    value={user.settings.timezone}
                    onChange={(val) => onSettingsChange('timezone', val)}
                />  */}

            </CardBody>

            <CardFooter className="text-right">
                <button className="btn btn-success btn-sm" onClick={onSave} >
                    Save Profile
                </button>
            </CardFooter>

        </Card>

    </Form>

    );
}

export default UserSettingsMatter