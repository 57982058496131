import { useCallback, useState, useEffect } from 'react';

import ReactSelect from 'components/functional/inputs/ReactSelect';

import api from 'api';

import { toast } from 'react-toastify';

const FinishTaskCustomField = ({task, onFinish}) => {

    const name = task.task_template ? task.task_template.name : task.name
    const description = task.task_template ? task.task_template.description : task.description

    const [answer, setAnswer] = useState('')

    const onSave = useCallback(async () => {
        if(!answer) return;
        const func = answer === 'yes' ? 'tagAdd' : 'tagRemove'

        const data = await api.matters[func]({ 
            matter: task.matter, 
            tag: task.task_template.tag,
            task: task._id
        })
        if(!data.data) return toast.error(`Something's not right, please try again.`)
        onFinish(data.data)
    }, [task._id, answer, onFinish, task.matter, task.task_template.tag])

    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])

    return (
        <div>
             <ReactSelect
                title={name}
                description={description}
                formGroup={true}
                placeholder=""
                onChange={(obj) => setAnswer(obj.value)}
                options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                ]}
                value={answer}
            />    
        </div>
    )
}

export default FinishTaskCustomField