import { connect } from 'react-redux';
import { useCallback, useEffect } from "react"

import { openDocumentCenter } from 'store/functions/document_center'

const FinishTaskSendForSignature = ({task, toggleModal, socket, viewing_user}) => {


    useEffect(() => {
        openDocumentCenter({matter: task.matter, task: task._id})
        toggleModal();
    }, [task, toggleModal])

    return <div><p className='text-sm'>Upload a document to finish this task</p></div>
}


const mapStateToProps = state => {
	return {
        socket: state.socket,
	    document_center: state.document_center,
	};
};

export default connect(mapStateToProps, '')(FinishTaskSendForSignature);