import { useEffect } from 'react';

import { toast } from 'react-toastify'

import { Container } from 'reactstrap';

const NoteFormatter = () => {

    function copyFormatted (html) {
        // Create container for the HTML
        // [1]
        var container = document.createElement('div')
        container.innerHTML = html
      
        // Hide element
        // [2]
        container.style.position = 'fixed'
        container.style.pointerEvents = 'none'
        container.style.opacity = 0
      
        // Detect all style sheets of the page
        var activeSheets = Array.prototype.slice.call(document.styleSheets)
          .filter(function (sheet) {
            return !sheet.disabled
          })
      
        // Mount the container to the DOM to make `contentWindow` available
        // [3]
        document.body.appendChild(container)
      
        // Copy to clipboard
        // [4]
        window.getSelection().removeAllRanges()
      
        var range = document.createRange()
        range.selectNode(container)
        window.getSelection().addRange(range)
      
        // [5.1]
        document.execCommand('copy')
      
        // [5.2]
        for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true
      
        // [5.3]
        document.execCommand('copy')
      
        // [5.4]
        for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = false
      
        // Remove the container
        // [6]
        document.body.removeChild(container)

        toast.info(`Note Copied To Clipboard`)
    }

    useEffect(() => {
        const el = document.getElementById('NoteFormatter')

        el.focus()

        el.addEventListener('paste', function(e) {
            var html = e.clipboardData.getData('text/html');
            html = html.replace(/<img[^>]*>/g,"")

            copyFormatted(html)
            
        })
    }, [])

    return (
        <Container>

            <div className='pb-5 pt-3'>
                <p className='text-sm mb-0 font-weight-bold text-dark'>Past Formatted Note(s) Below</p>

                <div
                    id="NoteFormatter"
                    contentEditable='true'
                    onInput={e => console.log(e.currentTarget.textContent)}
                    className='border p-4'
                    style={{background: '#999', color: 'white'}}
                >

                </div>
            </div>

        </Container>

       
    )
}

export default NoteFormatter