import { Modal } from "reactstrap";

import EventsEdit from './index'

const EventsEditModal = ({showModal, toggleModal, matter, task, eventTemplate, onSave, _id, useSaveButtonId}) => {

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">View Event</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <EventsEdit 
                    _id={_id}
                    matter={matter}
                    task={task}
                    useSaveButtonId={useSaveButtonId}
                    eventTemplate={eventTemplate}
                    onSave={data => {
                        toggleModal()
                        onSave(data)
                    }}
                />
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>

    )
}


export default EventsEditModal