/*
Documentation

load all styles, assets, here
App is wrapped in a store provider

*/

import React from "react";

import "react-perfect-scrollbar/dist/css/styles.css";
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-phone-input-2/lib/high-res.css'
import "assets/scss/index.scss";

import App from './App'

import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import store from 'store';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

