import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Card } from "reactstrap";

import StandardLoader from 'components/markup/layout/StandardLoader';
import A from 'components/markup/links/A';

import ModalToggler from 'components/functional/modals/Toggler';
import SendForm from 'components/system/SendForm';

import api from 'api'

import { toast } from 'react-toastify';

import moment from 'moment';

import TableIcons from 'components/markup/icons/TableIcons';

import { onDownloadDocument } from 'utils/documents';
import ImageRenderer from 'components/functional/images/ImageRenderer';

const FormsStep = ({matter}) => {

    const [ submissions, setSubmissions ] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await api.form_submissions.findByMatter(matter._id);
        if(!data.success) return toast.error(`Something went wrong loading this matters form submissions, please refresh the page`)

            setSubmissions(data.data)
    }, [matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!submissions) return <StandardLoader />

    return (
        <Card className='card-no-shadow'>

            <div className="table-responsive ">
                <table className="table table-not-bordered">
                    <thead>
                        <tr>
                            <th>Form Name</th>
                            <th>Contact</th>
                            <th className="text-right">Submitted At</th>
                            <th className="text-right">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {submissions.map((s => (
                            <tr key={s._id}>
                                <td>
                                    {s.form ? s.form.name : 'Form Not Found'}
                                </td>

                                <td>
                                    {
                                        !s.contact ? '-' : 
                                        s.contact.display_name ? s.contact.display_name : 
                                        s.contact.email ? s.contact.email : s.contact.phone ? s.contact.phone : 
                                        '-'
                                    }
                                </td>
                                <td className='text-right'>
                                    {moment.unix(s.created_at).format('MMM Do, YYYY h:mm A')}
                                </td>

                                <td className='text-right'>
                                    <TableIcons
                                        icons={[
                                            { 
                                                icon: 'fas fa-search',
                                                color: 'warning', 
                                                wrapper: ImageRenderer, 
                                                doc: s.document 
                                            },
                                            {  
                                                icon: 'fas fa-download', 
                                                color: 'info',
                                                onClick: () => onDownloadDocument(s.document) 
                                            },
                                        
                                        ]}
                                    />
                                </td>

                            </tr>
                        )))}
                  
                    </tbody>
                </table>
            </div>

        </Card>

    );

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(FormsStep);