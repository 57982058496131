import { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCustomRefreshLink } from 'utils/urls'

import { toast } from 'react-toastify'

import api from 'api';

import hasPrivilege from 'utils/hasPrivilege'

import Calls from './Calls'

const Sidebar = ({PLATFORM, matter, socket, viewing_user, selected_division, toggleSidenav, sidenavOpen}) => {

    const location = useLocation(useLocation)
    
    const items = [
        ...(hasPrivilege('PIPELINES.READ') && hasPrivilege('VIEW.APP_DASHBOARD.PIPELINE') ? [{
            active: () => location.pathname.includes('pipeline'),
            name: 'Pipeline',
            link: '/pipeline'
        }] : []),
        ...(hasPrivilege('MATTERS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.MATTERS') ? [{
            active: () => location.pathname.includes('matters'),
            name: 'Matters',
            field: 'matters',
            // link: getCustomRefreshLink('/matters/search/mine'),
            dropdown: [
                { name: 'Mine', link: getCustomRefreshLink('/matters/search/mine') },
                { name: 'All', link: getCustomRefreshLink('/matters') },
                { name: 'New', link: getCustomRefreshLink('/matters/search/new')},
                { name: 'Bookmarked', link: getCustomRefreshLink('/matters/search/bookmarked')},
                { name: 'Overdue', link: getCustomRefreshLink('/matters/search/overdue')},
            ]
        }] : []),
       
        ...(hasPrivilege('CONTACTS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.CONTACTS') ? [{
            active: () => location.pathname.includes('contacts') || location.pathname.includes('clients'),
            name: 'Contacts',
            field: 'contacts',
            // link: getCustomRefreshLink('/clients'),
            dropdown: [
                { name: 'All', link: getCustomRefreshLink('/contacts') },
                { name: 'New', link: getCustomRefreshLink('/contacts/search/new')},
            ]
        }] : []),
        ...(hasPrivilege('LEADS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.LEADS') ? [{
            active: () => location.pathname.includes('leads'),
            name: 'Leads',
            field: 'leads',
            itemField: 'leads',
            // link: getCustomRefreshLink('/leads'),
            dropdown: [
                { name: 'Unclaimed', link: getCustomRefreshLink('/leads/live'), itemField: 'leads' },
                { name: 'Mine', link: getCustomRefreshLink('/leads/search/mine')},
                { name: 'All', link: getCustomRefreshLink('/leads') },
            ]
        }] : []),
        ...(hasPrivilege('TASKS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.TASKS') ? [{
            active: () => location.pathname.includes('tasks'),
            name: 'Tasks',
            field: 'tasks',
            itemField: 'tasks_overdue',
            // link:  getCustomRefreshLink('/tasks'),
            dropdown: [
                { name: 'All', link:  getCustomRefreshLink('/tasks') },
                { name: 'Today', link:  getCustomRefreshLink('/tasks?type=today'), itemField: 'tasks_today' },
                { name: 'Overdue', link:  getCustomRefreshLink('/tasks?type=overdue'), itemField: 'tasks_overdue' },
                { name: 'Next 7 Days', link:  getCustomRefreshLink('/tasks?type=7'), itemField: 'tasks_7_days' },
            ]
        }] : []),
        ...(hasPrivilege('OUTSTANDING_ITEMS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.OUTSTANDING_ITEMS') ? [{
            active: () => location.pathname.includes('outstanding_items'),
            name: 'Outstanding Items',
            field: 'outstanding_items',
            itemField: 'outstanding_items_overdue',
            // link:  getCustomRefreshLink('/tasks'),
            dropdown: [
                { name: 'All', link:  getCustomRefreshLink('/outstanding_items') },
                { name: 'Overdue', link:  getCustomRefreshLink('/outstanding_items?type=overdue'), itemField: 'outstanding_items_overdue' },
            ]
        }] : []),
        ...(hasPrivilege('EVENTS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.EVENTS') ? [{
            active: () => location.pathname.includes('events'),
            name: 'Events',
            field: 'events',
            // link: getCustomRefreshLink('/events'),
            itemField: 'events_overdue',
            dropdown: [
                { name: 'All', link: getCustomRefreshLink('/events') },
                { name: 'Today', link: getCustomRefreshLink('/events?type=today'), itemField: 'events_today' },
                { name: 'Missed', link: getCustomRefreshLink('/events?type=missed'), itemField: 'events_overdue' },
                { name: 'Next 7 Days', link: getCustomRefreshLink('/events?type=7'), itemField: 'events_7_days' },
            ]
        }] : []),
        ...(hasPrivilege('DOCUMENTS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.DOCUMENTS') ? [{
            active: () => location.pathname.includes('documents'),
            name: 'Documents',
            field: 'documents',
            itemField: 'documents_total',
            // link: getCustomRefreshLink('/documents?type=pending'),
            dropdown: [
                { name: 'All', link: getCustomRefreshLink('/documents')},
                { name: 'Pending', link: getCustomRefreshLink('/documents?type=pending'), itemField: 'documents_pending' },
                { name: 'Expired', link: getCustomRefreshLink('/documents?type=expired'), itemField: 'documents_expired' },
            ]
        }] : []),
        ...(hasPrivilege('TEXTS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.TEXTS') ? [{
            active: () => location.pathname.includes('texts'),
            name: 'Texts',
            field: 'texts',
            link: getCustomRefreshLink('/texts'),
            itemField: 'texts',
            // itemField: 'texts_unread',
            // dropdown: [
            //     { name: 'New', link: '/texts', itemField: 'texts_new'},
            //     { name: 'Unread', link: '/texts', itemField: 'texts_unread' },
            //     // { name: '24 Hours +', link: '/', itemField: 'texts_over_one_day_old' },
            // ]
        }] : []),
        ...(hasPrivilege('EMAILS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.EMAILS') ? [{
            active: () => location.pathname.includes('emails'),
            name: 'Emails',
            field: 'emails',
            link: getCustomRefreshLink('/emails'),
            itemField: 'emails',
        }] : []),
        ...(hasPrivilege('SUPPORT_TICKETS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.SUPPORT_TICKETS') ? [{
            active: () => location.pathname.includes('support_tickets'),
            name: 'Support Tickets',
            field: 'support_tickets',
            link: getCustomRefreshLink('/support_tickets'),
            itemField: 'support_tickets',

            // itemField: 'texts_unread',
            // dropdown: [
            //     { name: 'New', link: '/texts', itemField: 'texts_new'},
            //     { name: 'Unread', link: '/texts', itemField: 'texts_unread' },
            //     // { name: '24 Hours +', link: '/', itemField: 'texts_over_one_day_old' },
            // ]
        }] : []),
        ...(hasPrivilege('CALLS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.VOICEMAIL') ? [{
            active: () => location.pathname.includes('voicemail'),
            name: 'Voicemail',
            field: 'voicemails',
            link: getCustomRefreshLink('/voicemail'),
            itemField: 'voicemails'
        }] : []),
        
        

        ...(hasPrivilege('BILLING.READ') && hasPrivilege('VIEW.APP_DASHBOARD.BILLING') ? [{
            active: () => location.pathname.includes('billing'),
            name: 'Billing',
            field: 'billing',
            link: getCustomRefreshLink('/billing'),
            // dropdown: [
            //     { name: 'Contact Lookup', link: '/billing', itemField: 'emails'},
            //     { name: 'Todays Payments', link: '/billing/payments', itemField: 'emails'},
            //     { name: 'Todays Recurrings', link: '/billing/recurring', itemField: 'emails'},
            //     { name: 'Collections', link: '/billing/collections', itemField: 'emails'},
            // ]
        }] : []),
        ...(hasPrivilege('CALLS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.PHONE_CALLS') ? [{
            active: () => location.pathname.includes('calls'),
            name: 'Phone Calls',
            field: 'calls',
            link: getCustomRefreshLink('/calls'),
        }] : []),
     
        ...(hasPrivilege('CUSTOM_VIEWS.READ') && hasPrivilege('VIEW.APP_DASHBOARD.CUSTOM_VIEWS') ? [ {
            active: () => location.pathname.includes('custom_views'),
            name: 'Custom Views',
            icon: 'fa-solid fa-street-view mr-2 text-success',
            link: '/custom_views'
        }] : []),
        // {
        //     active: () => location.pathname.includes('knowledge_base'),
        //     name: 'Knowledge Base',
        //     icon: 'fas fa-book text-purple',
        //     link: '/'
        // },
        // {
        //     active: () => location.pathname.includes('company_info'),
        //     name: 'Company Info',
        //     icon: 'fas fa-info-circle text-info',
        //     link: '/'
        // },
       
       
    ]
    
    const [counts, setCounts] = useState({
        tasks: {
            today: 0,
            overdue: 0,
            next7Days: 0,
        },
        events: {
            today: 0,
            overdue: 0,
            next7Days: 0,
        },
        documents: {
            pending: 0,
            expired: 0,
        },
        texts: 0,
        emails: 0,
        support_tickets: 0,
        voicemails: 0,
        leads: 0,
    })
   
    const [sidebar, setSidebar] = useState({
        matters: 'closed',
        contacts: 'closed',
        calls: 'closed',
        pipeline: 'closed',
        tasks: 'closed',
        events: 'closed',
        documents: 'closed',
        texts: 'closed',
        emails: 'closed',
    })

    const onSetSidebar = useCallback((field) => {
        const _sidebar = JSON.parse(JSON.stringify(sidebar));
        _sidebar[field] = _sidebar[field] === 'open' ? 'closed' : 'open'

        Object.keys(_sidebar).forEach(f => {
            if(f !== field) _sidebar[f] = 'closed'
        })
        setSidebar(_sidebar)
    }, [sidebar])

    const fetchSidebarData = useCallback(async () => {
        const data = await api.dashboard.sidebar(selected_division._id ? selected_division._id : 'all');
        if(data.data) setCounts(data.data)
    }, [selected_division._id])

    const onMessage = useCallback((obj) => {
        if(obj.assigned_to && obj.assigned_to.length && obj.assigned_to.includes(viewing_user._id)) {
            fetchSidebarData()
        } else if(obj.users && obj.users.length && obj.users.includes(viewing_user._id)) {
            fetchSidebarData()
        }
    }, [viewing_user._id, fetchSidebarData])

    const onNumberCannotBeCalled = useCallback((params) => {
        console.log(params)
        toast.error(`The number you have called is either not in service or has a block on the number you have called from.`)
    }, [])

    useEffect(() => {
        socket.on('TASKS.FINISHED', onMessage)
        socket.on('TASKS.CREATED', onMessage)
        socket.on('TASKS.UPDATED', onMessage)
        socket.on('TASKS.DELETED', onMessage)
        
        socket.on('EVENTS.FINISHED', onMessage)
        socket.on('EVENTS.CREATED', onMessage)
        socket.on('EVENTS.UPDATED', onMessage)
        socket.on('EVENTS.DELETED', onMessage)
        
        socket.on('MATTER_DOCUMENT_UPLOADS.UPDATED', onMessage)
        socket.on('TEXT_THREADS.UPDATED', fetchSidebarData)
        
        socket.on('CALL_CENTER.NUMBER_CANNOT_BE_CALLED', onNumberCannotBeCalled)

        return () => {

            socket.off('TASKS.FINISHED', onMessage)
            socket.off('TASKS.CREATED', onMessage)
            socket.off('TASKS.UPDATED', onMessage)
            socket.off('TASKS.DELETED', onMessage)
            
            socket.off('EVENTS.FINISHED', onMessage)
            socket.off('EVENTS.CREATED', onMessage)
            socket.off('EVENTS.UPDATED', onMessage)
            socket.off('EVENTS.DELETED', onMessage)
            
            socket.off('MATTER_DOCUMENT_UPLOADS.UPDATED', onMessage)
            socket.off('TEXT_THREADS.UPDATED', fetchSidebarData)
            
            socket.off('CALL_CENTER.NUMBER_CANNOT_BE_CALLED', onNumberCannotBeCalled)

        }

    }, [socket, onMessage, fetchSidebarData, onNumberCannotBeCalled])


    useEffect(() => {
        fetchSidebarData();
    }, [fetchSidebarData])

    return (
        <>
        {sidenavOpen ? <div onClick={toggleSidenav} className='background-blackout' /> : ''}

        <div className={`archk-sidebar z-depth-1 ${sidenavOpen ? 'open' : ''}`}>

            <div className="py-3 px-4 border-botto text-center">
                <h2 className='pt-1 text-uppercase'>
                    <div className='text-white' style={{paddingTop: 3}}><img src={PLATFORM.FAVICON} style={{width: 20, position: 'relative', top: -2}} alt="Intuidesk" className="mr-3"/>
                    IntuiDesk </div>
                </h2>
            </div>

            <Calls 
                matter={matter}
            />

            <div className='border-top'>
                {items.map((item, i) => {

                    const active = item.active ? item.active(location) ? 'active' : '' : '';
                    const count1 = counts[item.itemField]
                    const textColor = 
                        count1 < 10 ? 'text-info' :
                        count1 <= 49 ? 'text-warning' :
                        'text-danger';

                    const main = (
                        <div 
                            key={i} 
                            className={`py-3 px-4 border-bottom cursor-pointer ${active ? 'active' : ''} `}
                            onClick={() => onSetSidebar(item.field)} 
                        >
                            {/* {console.log(item)} */}
                            <h4 className='mb-0'>
                                {item.icon && <i style={{display: 'inline-block', width: 20,}} className={item.icon} />}
                                {item.name} 
                                {item.itemField && count1 ? <span style={{textShadow: '1px 1px 1px #333', fontSize: 16, marginTop: -2}} className={`float-right font-weight-bold ${textColor}`}>({counts[item.itemField]}) <i className="fas fa-caret-down ml-2 " /></span> : ''}
                            </h4>
                        </div>
                    )

                    return (
                        <div 
                            // onClick={() => {
                            //     if(sidenavOpen) toggleSidenav();
                            // }} 
                            key={i} 
                            className={active ? 'active' : ''}
                        >
                            {item.link ? <Link to={item.link} >{main}</Link> : main }
                                
                            {item.dropdown && sidebar[item.field] === 'open' && (
                                <div className="py-3 pr-4 pl-5 border-bottom bg-secondary">
                                    {item.dropdown && item.dropdown.map((d, ii) => {

                                        const count2 = counts[d.itemField]
                                        const color = count2 < 10 ? 'text-info' :
                                        count2 <= 49 ? 'text-warning' :
                                        'text-danger';
                                        return (
                                            <Link key={ii} to={d.link}>
                                                <h4 
                                                    className={`py-1 ${d.active ? d.active(location) ? 'active-sub-item' : '' : ''}`} 
                                                    key={ii}
                                                >
                                                    {d.name} 
                                                    {d.itemField !== undefined && count2 ? (
                                                        <span color={color} style={{minWidth: 40, fontSize: 14}} className={`float-right text-right t `}>
                                                            {counts[d.itemField] > 99 ? '99+' : counts[d.itemField]}
                                                        </span>
                                                    ) : ''}
                                                </h4>
                                            </Link>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        
        </div>

        </>
    )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company,
        selected_division: state.state.selected_division,
        divisions: state.divisions,
	    PLATFORM: state.load.PLATFORM,
        matter: state.matters.selected_matter,
	    socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Sidebar);
