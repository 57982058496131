import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import moment from 'moment';

import ObjectFinder from 'components/system/Objects/Finder';

const Payments = ({payment_events, matter}) => {

    return (
        <Card>
            <CardHeader className='py-3'>
                <CardTitle className="mb-0">Payment Events</CardTitle>
            </CardHeader>

            {payment_events.length ? (
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Person</th>
                                <th>Event</th>
                            </tr>
                        </thead>

                        <tbody>
                            {payment_events.map(i => (
                                <tr key={i._id} >
                                   
                                    <td>
                                        <div>{moment.unix(i.created_at).format('MM/DD/YYYY')}</div>
                                        <div>{moment.unix(i.created_at).format('h:mm A')}</div>
                                    </td>
                                    <td>
                                        {i.user ? (
                                            <ObjectFinder collection="users" _id={i.user} />
                                        ) : i.contact ? (
                                            <ObjectFinder collection="contacts" _id={i.contact} />
                                        ) : 'System'}
                                    </td>
                                    <td>
                                        {i.event}
                                    </td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    <CardBody className='py-8'>
                        <p className="text-ss mb-0 text-center text-capitalize ">
                            <i className="fas fa-info-circle mr-3 text-info" /> 
                            No payment events were found for this matter.
                        </p>
                    </CardBody>
                    
                </div>
            )}
        </Card>
    )

}

export default Payments