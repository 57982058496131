import { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import api from 'api'

import Circle from 'components/markup/loading/Circle'
import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';

import { toast } from 'react-toastify'

const DisplayContactName = ({selectedContact}) => {

    const [hideTitle, setHideTitle] = useState(false);

    useEffect(() => {
        setHideTitle(true)
        setTimeout(() => {
            setHideTitle(false)
        }, 500)
    }, [selectedContact])

    if(hideTitle) return <span>Selected Contact:</span>
    if(selectedContact) return <span>Selected Contact: <b className='text-info'><ObjectFinder collection="contacts" _id={selectedContact} /></b></span>;

    return 'No Contact Selected'

}

const ModalSendForm = ({showModal, toggleModal, onRequested,  form_id, matter_id, task_id, contact}) => {
    console.log('her')
    const [selectedContact, setSelectedContact] = useState(contact ? contact : '')
    const [matter, setMatter] = useState(null)
    const [form, setForm] = useState(null)
    const [sendInvitations, setSendInvitations] = useState(false)

    const onRequestForm = useCallback(async () => {
        if(!selectedContact) return toast.info('A contact must be selected before this form can be requested.')


        const requested = await api.forms.request({
            contact           : selectedContact,
            form              : form_id,
            matter            : matter_id,
            task              : task_id,
            send_invitations  : sendInvitations,
        })
        if(!requested.success) return toast.error(`Form failed to be requested, please try again`)
        if(onRequested) onRequested(requested.data)
        toggleModal()

    }, [form_id, selectedContact, matter_id, task_id, sendInvitations, onRequested, toggleModal])

    const fetchData = useCallback(async () => {
        const values = await Promise.all([
            api.forms.findById(form_id),
            api.matters.findById(matter_id),
        ])
        const _form = values[0].data
        const _matter = values[1].data
        
        if(_form) setForm(_form);
        if(_matter) {
            setMatter(_matter);
            if(_matter.contacts.length === 1)
            setSelectedContact(_matter.contacts[0])
        }
    }, [form_id, matter_id])

    useEffect(() => {
        if(showModal) {
            fetchData()
        } else {
            setMatter(null)
            setForm(null)
            setSelectedContact(contact)
            setSendInvitations(false)
        }
      
    }, [fetchData, showModal, contact])

    return (

        <Modal
           className="modal-dialog-centered"
           isOpen={showModal}
           toggle={toggleModal}
           size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Send Form {form ? <b className='text-dark text-underline'>{form.name}</b> : ''}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            {!form || !matter ? <Circle className="py-6" /> : (
                <div>
                    <div className="modal-body">
                        <p className='text-sm mb-0'>
                            <DisplayContactName selectedContact={selectedContact} />
                        </p>
                        <ReactSelect
                            menuPlacement="bottom"
                            hideTitle={true}
                            formGroup={true}
                            placeholder="Search..."
                            onChange={(obj) => setSelectedContact(obj.value)}
                            options={matter.contacts.map(c => {
                                return { label: <ObjectFinder collection="contacts" _id={c} />, value: c }
                            })}
                            value={null}
                        />    
                    </div>

                    <div className="modal-body bg-secondary border-top border-bottom">
                    
                        <div className="custom-control custom-checkbox">
                            <input
                                id="archk-request-form-send-invitations"
                                className="custom-control-input"
                                type="checkbox"
                                onChange={() => setSendInvitations(!sendInvitations)}
                                checked={sendInvitations}
                            />
                            <label className="custom-control-label" htmlFor={'archk-request-form-send-invitations'}>
                                Send Notifications Out Via Text And Email
                            </label>
                        </div>

                    </div>

                    <div className="modal-body">
                        <p className="text-sm mb-0">You are about to request the form <b className='text-underline font-weight-bold'>"{form.name}"</b>. Please select the contact you wish to request it from as well as the form settings from the boxes above.</p>
                    </div>

                </div>
            )}
         

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left" /> Cancel
                </button>
                <button className="btn btn-success" onClick={onRequestForm}>
                    <i className="fas fa-paper-plain" /> Send
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalSendForm);