import { FormGroup } from 'reactstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'

// value is one of:
// moment().toDate() || 
// new Date() || 
// unix timestamp (string or number)

const Day = ({value, onChange, title, popperPlacement, open, showTimeSelect, dateFormat, hideFormGroup}) => {

    const parsed = Number.isNaN(parseInt(value)) ? value : moment.unix(parseInt(value)).toDate()
    const picker = (
        <DatePicker 
            showTimeSelect={showTimeSelect}
            popperPlacement={popperPlacement} 
            open={open}
            selected={parsed} 
            dateFormat={dateFormat} 
            onChange={(date) => onChange(date)} 
        />
    )

    if(hideFormGroup) return picker

    return (
        <FormGroup>
            {title ? <label className='form-control-label'>{title}</label> : ''}
            {picker}
        </FormGroup>
    )
}

export default Day