/*

import this function when looking for query parameters
returns null if its empty or the value of the query variable if found

*/

export const getUrlParameter = (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};


/*

param used here cannot include any other part of the url, can be expanded to due this in the future
ex: this will not work correctly: /contact?contact=

*/
export const setUrlParameter = (param, value) => {

    let url = window.location.href;

    if(url.includes('?')) {

        const splitUrl = url.split(param)

        if(splitUrl[1]) {

            const splitUrl2 = splitUrl[1].split('&')

            let string1 = splitUrl[0];
            let string2 = `${param}=${value}`;


            url = string1  + string2

            if(splitUrl2 && splitUrl2[1]) {

                splitUrl2.forEach((segment, i) => {
                    if(i !== 0) {
                        url = `${url}&${splitUrl2[i]}`
                    }
                })

            }

        } else {

            url = `${url}&${param}=${value}`

        }

    } else {

        url = url + '?' + param + '=' + value

    }

    window.history.pushState(null, null, `${url}`);


}

export const getSoftRefreshLink = () => {
    return `/soft_redirect?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
}
export const getHardRefreshLink = () => {
    return `/redirect?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
}
export const getCustomRefreshLink = (path) => {
    return `/soft_redirect?redirect=${encodeURIComponent(path)}`
}

export const isValidURL = (str)  => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }