import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from 'reactstrap';





const ModuleBKOverview = ({ data, onInputChange }) => {
    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Miscellaneous</CardTitle>
                </CardHeader>

                <CardFooter>
                    <Row>
                        <Col xs={6}>
                        
                            
                            <p className='text-sm mb-0 font-weight-bold'>Other Pending Matters (See Separate Status Sheet)</p>

                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_pending_matters_order_to_show_cause`}

                                    checked={data.pending_matters.includes('order_to_show_cause')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.pending_matters));
                                        if(arr.includes('order_to_show_cause')) {
                                            arr = arr.filter(a => a !== 'order_to_show_cause')
                                        } else {
                                            arr.push('order_to_show_cause')
                                        }
                                        onInputChange('pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_pending_matters_order_to_show_cause`}>
                                    Order To Show Cause
                                </label>
                            </div>

                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_pending_matters_motion_to_dismiss`}

                                    checked={data.pending_matters.includes('motion_to_dismiss')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.pending_matters));
                                        if(arr.includes('motion_to_dismiss')) {
                                            arr = arr.filter(a => a !== 'motion_to_dismiss')
                                        } else {
                                            arr.push('motion_to_dismiss')
                                        }
                                        onInputChange('pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_pending_matters_motion_to_dismiss`}>
                                    Motion To Dismiss
                                </label>
                            </div>
                            
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_pending_matters_objection_to_poc`}

                                    checked={data.pending_matters.includes('objection_to_poc')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.pending_matters));
                                        if(arr.includes('objection_to_poc')) {
                                            arr = arr.filter(a => a !== 'objection_to_poc')
                                        } else {
                                            arr.push('objection_to_poc')
                                        }
                                        onInputChange('pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_pending_matters_objection_to_poc`}>
                                    Objection To POC
                                </label>
                            </div>
                        
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_pending_matters_objection_to_borrow`}

                                    checked={data.pending_matters.includes('objection_to_borrow')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.pending_matters));
                                        if(arr.includes('objection_to_borrow')) {
                                            arr = arr.filter(a => a !== 'objection_to_borrow')
                                        } else {
                                            arr.push('objection_to_borrow')
                                        }
                                        onInputChange('pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_pending_matters_objection_to_borrow`}>
                                    Objection To Borrow
                                </label>
                            </div>
                        
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_pending_matters_motion_for_relief`}

                                    checked={data.pending_matters.includes('motion_for_relief')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.pending_matters));
                                        if(arr.includes('motion_for_relief')) {
                                            arr = arr.filter(a => a !== 'motion_for_relief')
                                        } else {
                                            arr.push('motion_for_relief')
                                        }
                                        onInputChange('pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_pending_matters_motion_for_relief`}>
                                    Motion For Relief
                                </label>
                            </div>
                        
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk_pending_matters_other`}

                                    checked={data.pending_matters.includes('other')}
                                    type="checkbox"
                                    onChange={e => {
                                        let arr = JSON.parse(JSON.stringify(data.pending_matters));
                                        if(arr.includes('other')) {
                                            arr = arr.filter(a => a !== 'other')
                                        } else {
                                            arr.push('other')
                                        }
                                        onInputChange('pending_matters', arr)
                                    }}
                                />
                                <label className="custom-control-label" htmlFor={`archk_pending_matters_other`}>
                                    Other
                                </label>
                            </div>
                        </Col>

                        <Col xs={6}>
                            <FormGroup>
                                <label className='form-control-label'>Pending Matter Notes</label>
                                <Input 
                                    type="textarea"
                                    style={{minHeight: 120}}
                                    value={data.pending_matter_notes}
                                    onChange={e => onInputChange('pending_matter_notes', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


