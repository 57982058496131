import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle } from 'reactstrap';

import moment from 'moment';

import ObjectFinder from 'components/system/Objects/Finder';

const MatterViewMainTasksFinished = ({matter}) => {

    const [dates, setDates] = useState([])

    useEffect(() => {
        const _dates = JSON.parse(JSON.stringify(matter.dates));
        _dates.reverse()
        setDates(_dates)
    }, [matter.dates])


    return (
        <div className="ection">

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Step Dates</CardTitle>
                </CardHeader>

                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Step</th>
                                <th>Category</th>
                                <th>Disposition</th>
                                <th className='text-right'>Moved By</th>
                            </tr>
                        </thead>

                        <tbody>
                            {dates.map(d => (
                                <tr key={d._id}>
                                    <td>{moment.unix(d.timestamp).format('MMM Do, YYYY h:mm A')}</td>
                                    <td>{d.workflow_step_name}</td>
                                    <td>{d.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={d.workflow_step_category} /> : ''}</td>
                                    <td>{d.workflow_disposition ? <ObjectFinder collection="workflow_dispositions" _id={d.workflow_disposition} /> : ''}</td>
                                    <td className='text-right'>{d.user ? <ObjectFinder collection="users" _id={d.user} /> : 'System'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>

        </div>
    );

}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainTasksFinished);