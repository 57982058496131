import { playSound } from 'store/functions/call_center'

/*
Documentation

This page handles updating a users profile

*/

import { useCallback, useEffect, useState } from 'react';

import { Card, CardHeader, CardTitle, CardBody, CardFooter, Form, FormGroup, Row, Col, Input } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';

import api from 'api';

import Circle from 'components/markup/loading/Circle';

import { toast } from 'react-toastify';

import States from 'components/markup/inputs/States'
import Timezones from 'components/markup/inputs/Timezones'

import ApiError from 'components/markup/layout/ApiError'

const UserSettingsMatter = () => {

    return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Test Audio</CardTitle>
            </CardHeader>

            <CardBody>
                <p className='text-sm mb-0'>If your audio is correctly configured, enable audio in the top left of your screen and click the button below. If a sound plays you are setup correctly.</p>
            </CardBody>

            <CardFooter className='text-right'>
                <button onClick={playSound} className='btn btn-success btn-sm'>Test Audio</button>
            </CardFooter>
        </Card>
    );
}

export default UserSettingsMatter