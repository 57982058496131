import { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';

import moment from 'moment';

import Circle from 'components/markup/loading/Circle';
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

import hasPrivilege from 'utils/hasPrivilege'

const DocumentTable = ({documents, positive}) => (
    <div className='table-responsive'>
        <table className='table'>

            <thead>
                <tr>
                    <th>Document</th>
                    <th className='text-right'>Updated By</th>
                </tr>
            </thead>
            <tbody>
                {documents.length ? documents.map((d, i) => (
                    <tr key={i}>
                        <td>
                            <div>{d.name}</div>
                            <div className='text-capitalize'>{d.matter_doc_status}</div>
                        </td>
                        <td className="text-right">
                            {d.matter_doc_status_by ? (
                                <div><ObjectFinder collection="users" _id={d.matter_doc_status_by} /></div>
                            ) : ''}

                            {d.matter_doc_status_at ? (
                                <div className='text-right'>{moment.unix(d.matter_doc_status_at).format('MM/DD/YY h:mm A')}</div>
                            ) : '-'}
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td>{!positive ? <i className="fas fa-check text-success mr-2" /> : ''} No Documents Found</td>
                        <td></td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
)

const TasksTable = ({documents}) => (
    <div className='table-responsive'>
        <table className='table'>

            <thead>
                <tr>
                    <th>Task</th>
                    <th className='text-right'>Assigned To</th>
                </tr>
            </thead>
            <tbody>
                {documents.length ? documents.map((d, i) => (
                    <tr key={i}>
                        <td>
                            <div>{d.name && d.name.length > 40 ? d.name.slice(0, 40) + '...' : d.name}</div>
                        </td>
                        <td className='text-right'>
                            {d.task_assigned_to && d.task_assigned_to.length ? d.task_assigned_to.map((u, ii) => (
                                ii === 0 ? <ObjectFinder key={ii} collection="users" _id={u} /> : <span>, <ObjectFinder key={ii} collection="users" _id={u} /></span>
                            )) : ''}
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td>No Tasks Found</td>
                        <td></td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
)

const FieldsTable = ({documents, positive}) => (
    <div className='table-responsive'>
        <table className='table'>

            <thead>
                <tr>
                    <th>Task</th>
                    {positive ? (
                        <th className='text-right'>Answer</th>
                    ) : ''}
                </tr>
            </thead>
            <tbody>
                {documents.length ? documents.map((d, i) => (
                    <tr key={i}>
                        <td>
                            <div>{d._id ? d._id.name && d._id.name.length > 40 ? d._id.name.slice(0, 40) + '...' : d._id.name : 'FIELD NOT FOUND'}</div>
                        </td>
                        {positive ? (
                            <td className='text-right'>
                                {d.answer}
                            </td>
                        ) : ''}
                    </tr>
                )) : (
                    <tr>
                        <td>No Tasks Found</td>
                        {positive ? <td></td> : ''}
                    </tr>
                )}
            </tbody>
        </table>
    </div>
)

const SigningTable = ({documents, positive}) => (
    <div className='table-responsive'>
        <table className='table'>

            <thead>
                <tr>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                {documents.length ? documents.map((d, i) => (
                    <tr key={i}>
                        <td>
                            <div>{d.name && d.name.length > 40 ? d.name.slice(0, 40) + '...' : d.name}</div>
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td>No Signatures Found</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
)

const FormTable = ({documents, positive}) => (
    <div className='table-responsive'>
        <table className='table'>

            <thead>
                <tr>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                {documents.length ? documents.map((d, i) => (
                    <tr key={i}>
                        <td>
                            <div>{d.name && d.name.length > 40 ? d.name.slice(0, 40) + '...' : d.name}</div>
                        </td>
                    </tr>
                )) : (
                    <tr>
                        <td>No Forms Found</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
)

const Checklist = ({matter}) => {

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await api.matters.step(matter._id)
        if(data.data) setData(data.data)
    }, [matter._id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(!data) return <Circle />

    const finishedDocuments = data.matter_documents.percent === 100
    const finishedTasks = data.tasks.percent === 100
    const finishedFields = data.custom_fields.percent === 100
    const finishedSigning = data.signing_documents.percent === 100
    const finishedForms = data.forms.percent === 100

    return (
        <Container fluid>
            <Row>
                {!finishedDocuments ? (
                <Col lg={data.matter_documents.finished.length ? 6 : 12}>
                    <Card>
                          <CardHeader className='bg-warning'>
                              <CardTitle className='mb-0 text-white'>Documents Needed</CardTitle>
                          </CardHeader>
                          <DocumentTable documents={data.matter_documents.unfinished} positive={false}/>
                      </Card>
                  </Col>
                ) : ''}
              
                {data.matter_documents.finished.length ? (
                    <Col lg={!finishedDocuments ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-success'>
                                <CardTitle className='mb-0 text-white'>Documents Finished</CardTitle>
                            </CardHeader>
                            <DocumentTable documents={data.matter_documents.finished} positive={true} />
                        </Card>
                    </Col>
                ) : ''}
            </Row>

            <Row>
                {!finishedTasks ? (
                    <Col lg={data.tasks.finished.length ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-warning'>
                                <CardTitle className='mb-0 text-white'>Incomplete Tasks</CardTitle>
                            </CardHeader>
                            <TasksTable documents={data.tasks.unfinished} positive={false}/>
                        </Card>
                    </Col>
                ) : ''}
              
                {data.tasks.finished.length ? (
                    <Col lg={!finishedTasks ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-success'>
                                <CardTitle className='mb-0 text-white'>Finished Tasks</CardTitle>
                            </CardHeader>
                            <TasksTable documents={data.tasks.finished} positive={true} />
                        </Card>
                    </Col>
                ) : ''}
            </Row>

            <Row>
                <Col lg={!hasPrivilege('CUSTOM_FIELDS.READ') ? 12 : data.custom_fields.finished.length ? 6 : 12}>
                    <Card>
                        <CardHeader className='bg-warning'>
                            <CardTitle className='mb-0 text-white'>Fields Needed</CardTitle>
                        </CardHeader>
                        <FieldsTable documents={data.custom_fields.unfinished} positive={false}/>
                    </Card>
                </Col>

                {hasPrivilege('CUSTOM_FIELDS.READ') && data.custom_fields.finished.length ? (
                    <Col lg={!finishedFields ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-success'>
                                <CardTitle className='mb-0 text-white'>Finished Fields</CardTitle>
                            </CardHeader>
                            <FieldsTable documents={data.custom_fields.finished} positive={true} />
                        </Card>
                    </Col>
                ) : ''}
            </Row>
          
            <Row>
                {!finishedSigning ? (
                    <Col lg={data.signing_documents.finished.length ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-warning'>
                                <CardTitle className='mb-0 text-white'>Signatures Needed</CardTitle>
                            </CardHeader>
                            <SigningTable documents={data.signing_documents.unfinished} positive={false}/>
                        </Card>
                    </Col>
                ) : ''}

                {data.signing_documents.finished.length ? (
                    <Col lg={!finishedSigning ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-success'>
                                <CardTitle className='mb-0 text-white'>Finalized Signatures</CardTitle>
                            </CardHeader>
                            <SigningTable documents={data.signing_documents.finished} positive={true} />
                        </Card>
                    </Col>
                ) : ''}
            </Row>

            <Row>
                {!finishedForms ? (
                    <Col lg={data.forms.finished.length ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-warning'>
                                <CardTitle className='mb-0 text-white'>Forms Needed</CardTitle>
                            </CardHeader>
                            <FormTable documents={data.forms.unfinished} positive={false}/>
                        </Card>
                    </Col>
                ) : ''}

                {data.forms.finished.length? (
                    <Col lg={!finishedForms ? 6 : 12}>
                        <Card>
                            <CardHeader className='bg-success'>
                                <CardTitle className='mb-0 text-white'>Finalized Forms</CardTitle>
                            </CardHeader>
                            <FormTable documents={data.forms.finished} positive={true} />
                        </Card>
                    </Col>
                ) : ''}
            </Row>

        </Container>
    )
}

const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(Checklist);