import { Col, Row } from "reactstrap";

import NameCircle from 'components/markup/misc/NameCircle';
import Dots from 'components/markup/loading/Dots';
import A from 'components/markup/links/A';

import formatText from 'utils/formatText'

import ModalToggler from 'components/functional/modals/Toggler'
import CallContact from 'components/system/CallContact'
import ObjectFinder from 'components/system/Objects/Finder';
                    
const TextsHeader = ({thread, active}) => {

    if(!thread && !active) return (
        <div className="archk-messaging-main-header z-depth-1">
            <h1 className='display-4 pt-1 text-center'><i className="fa-solid fa-envelope-open-text mr-2 text-info" /> Email Center</h1>
        </div>
    )

    if(!thread) return (
        <div className="archk-messaging-main-header z-depth-1">
            <h1 className='display-4 pt-1'>Loading<Dots /></h1>
        </div>
    )

    return (

        <div className="archk-messaging-main-header z-depth-1">
            <Row>
                <div className="col-auto align-self-center" style={{width: 55}}>
                    <NameCircle width={45} initials={thread.thread.initials} style={{marginRight: 5}} />{' '}
                </div>
                <Col className="align-self-center">
                    <Row>
                        <Col md={9} className="align-self-center">
                        <h2 className="mb-0">
                            {thread.contact.display_name ? thread.contact.display_name : formatText(thread.contact.phone).phone()}{' '}
                            {/* <span className="text-danger"><i className="fas fa-exclamation-triangle mx-2" /> OPTED OUT</span> */}
                        </h2>
                        {thread.matters.length ? (
                            <p className="text-sm mb-0 text-muted"><b className="text-dark"><ObjectFinder collection="divisions" _id={thread.thread.division} /></b> - {thread.matters.length} Open Cases</p> 
                        ) : (
                            <p className="text-sm mb-0 text-warning"><b className="text-dark"><ObjectFinder collection="divisions" _id={thread.thread.division} /></b> - 0 Open Cases</p>

                        )}

                        </Col>
                        <Col md={3} className="align-self-center text-right">
                            
                            <ModalToggler 
                                component={CallContact} 
                                matter={thread.matters && thread.matters.length === 1 ? thread.matters[0]._id : null} 
                                contact={thread.contact._id}
                            >
                                <i className="archk-messaging-main-icon fas fa-phone" />
                            </ModalToggler>

                            <A href={`/contacts/${thread.contact.id}`}>
                                <i className="archk-messaging-main-icon fas fa-user" />
                            </A>

                            <A href={`/billing/${thread.contact.id}`}>
                                <i className="archk-messaging-main-icon fa-solid fa-wallet" />
                            </A>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )

}

export default TextsHeader