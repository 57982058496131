import { useState } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Card, Container } from "reactstrap";
import moment from 'moment';

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation'

import ObjectFinder from 'components/system/Objects/Finder';
import TableIcons from 'components/markup/icons/TableIcons'
import ApiError from "components/markup/layout/ApiError";
import A from 'components/markup/links/A'

import api from 'api';

import hasPrivilege from 'utils/hasPrivilege'

const CommunicationPreview = ({row}) => {

    const [hover, setHover] = useState(false)

    return (
        <div
            onMouseOver={() => setHover(true)} 
            onMouseOut={() => setHover(false)} 
            style={{position: 'relative'}}
        >
            <div>
                {row.incoming_communication_type === 'text' ? (
                    <b><i className="fas fa-mobile text-warning" /> </b>
                ) : row.incoming_communication_type === 'call' ? (
                    <b><i className="fa-solid fa-phone-volume text-success" /> </b>
                ) : (
                    <b><i className="fa-solid fa-envelope-open-text text-info" /> </b>
                )}
                {' '}
                {row.incoming_communication_snippet.length > 24 ? row.incoming_communication_snippet.slice(0,24) + '...' : row.incoming_communication_snippet}
            </div>
            <small>{moment.unix(row.incoming_communication_at).format('MMM Do, YYYY h:mm A')}</small>

            {hover ? (
                <Link to={`/matters/${row.id}`} className="text-dark font-weight-bold">
                <div   
                    className="bg-white z-depth-1 border p-3"
                    style={{
                        zIndex: 2,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 300,

                        borderBottomLeftRadius: 10,
                        borderTopRightRadius: 10,
                    }}
                >
                    {row.incoming_communication_snippet}
                    <div><small>{moment.unix(row.incoming_communication_at).format('MMM Do, YYYY h:mm A')}</small></div>
                </div>
                </Link>
            ) : ''}
            
        </div>
    )

}

const MattersAll = ({match, viewing_user, selected_division}) => {

    const type = match.params.type;
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        if(selected_division._id) params.filter.division = selected_division._id

        const now = Math.floor(new Date() / 1000)

        if(type === 'mine') params.filter['roles.user'] = viewing_user._id
        if(type === 'bookmarked') params.filter['bookmarked_by'] = { $in: [viewing_user._id] }
        if(type === 'new') params.filter.created_at =  { $gte: now - ( 86400 * 3 ) }
        if(type === 'overdue') params.filter.current_step_overdue_at =  { $lt: now, $gt: 0 }

        const query = await api.matters.search({ ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            // headerStyle: { width: 200     },
            headerFormatter: () => {
                return <div><div>Matter</div></div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div><Link to={`/matters/${row.id}`} className="text-dark font-weight-bold">
                            {row.current_step_overdue_at > 0 && row.current_step_overdue_at <= Math.floor(new Date() / 1000) ? <i className="fa-solid fa-clock text-warning mr-2" /> : ''}
                            {row.name}</Link>
                        </div>
                        <div><Link to={`/matters/${row.id}`} className="text-muted">{row.last_note}</Link></div>
                    </div>
                )
            },
        },
        {
            dataField: "incoming_communication_snippet",
            text: 'Name',
            headerFormatter: () => {
                return <div>Last <i className="fas fa-comments ml-2" /></div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return ''
                if(!row.incoming_communication_snippet) return '-'
                return <div style={{width: 140}}><CommunicationPreview row={row} /></div>
            },
        },
        {
            dataField: "contact",
            text: 'Contacts',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : !row.contacts ? '' :
                row.contacts.map((c, i) => (
                    <Link key={c._id + i.toString()} to={`/clients/${row._id}`}>
                        <span> {i !== 0 ? ', ' : ''}<ObjectFinder collection="contacts" _id={c} /></span>
                    </Link>
                ))
            )
        },
        {
            dataField: "current_step_percentage",
            text: 'Step %',
            formatter: (cell, row) => {
                if(row.name === 'no data' ) return ''
                let className = "";
                if(row.current_step_percentage >= 0 && row.current_step_percentage < 19.99) className = 'text-danger'
                if(row.current_step_percentage >= 20 && row.current_step_percentage < 49.99) className = 'text-warning'
                if(row.current_step_percentage >= 50 && row.current_step_percentage < 97.99) className = 'text-info'
                if(row.current_step_percentage >= 98) className = 'text-success'

                return (
                    <div>
                       
                        <span className={className}>{row.current_step_percentage ? row.current_step_percentage + '%' : 0 + '%'}</span>
                    </div>
                )
            }
        },
        {
            dataField: "division_department",
            text: 'Department',
            formatter: (cell, row) => row.name === 'no data'  ? '' :  (
                <div>
                    {row.division_department ? 
                        <ObjectFinder collection="division_departments" _id={row.division_department} /> : 
                        <span className="text-warning">No Department</span>}
                </div>
            )
        },
        {
            dataField: "division",
            text: 'Division / Matter Type',
            headerFormatter: () => (
                <div><div>Division /</div><div>Matter Type</div></div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div><Link to={`/matters/${row.id}`}><ObjectFinder collection="divisions" _id={row.division} /></Link></div>
                        <div><Link to={`/matters/${row.id}`}><ObjectFinder collection="workflows" _id={row.workflow} /></Link></div>
                    </div>
                )
            )
        },
        {
            dataField: "current_step_category",
            text: 'Step Category',
            headerFormatter: () => (
                <div><div>Category /</div><div>Disposition</div></div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>{row.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={row.workflow_step_category} /> : 'No Category'}</div>
                        <div>{row.workflow_disposition ? <ObjectFinder collection="workflow_dispositions" _id={row.workflow_disposition} /> : '-'}</div>
                    </div>
                )
            )
        },
        {
            dataField: "current_step_start",
            text: 'Current Step',
            headerFormatter: () => (
                <div><div>Step /</div><div>Moved</div></div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>
                            {
                                row.current_step_name ? row.current_step_name : 
                                <span>
                                    <i className="fas fa-exclamation-triangle mr-2 text-warning " /> None Assigned
                                </span>
                            }
                        </div>
                        {row.current_step_start ? <div>{moment.unix(row.current_step_start).format('MM/DD/YYYY')}</div> : ''}
                    </div>
                )
            )
        },
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right'},
            style: {textAlign: 'right'},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right" style={{width: 140, marginLeft: 'auto'}}>
                        <TableIcons
                            icons={[
                                { 
                                    icon: 'fas fa-check',
                                    color: 'warning', 
                                    wrapper: A, 
                                    href: `/matters/${row.id}`,
                                },
                                { 
                                    icon: 'fas fa-external-link-alt',
                                    color: 'info', 
                                    wrapper: A, 
                                    href: `/matters/${row.id}`,
                                },
                                { 
                                    icon: 'fas fa-link',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/matters/${row.id}`,
                                },
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    const title = 
        type === 'mine' ? 'Assigned Matters' : 
        type === 'new' ? 'New Matters' : 
        type === 'bookmarked' ? 'Bookmarked Matters' : 
        'All Matters'
    const description = 
        type === 'mine' ? 'All Matters Assigned To Me' : 
        type === 'new' ? 'Matters Created in The Last 72 Hours' :
        type === 'bookmarked' ? 'Showing All My Current Bookmarked Matters' : 
        'Showing All Matter In The Selected Division'

    return (

        <>

        <Helmet>
            <title>{title}</title>
            <meta name="description" content={title} />
        </Helmet>

        <HeaderNavigation 
            title={title}
            description={description}
            leftColSize={6}
            actionComponent={(
                <div>
                    {hasPrivilege('CONTACTS.READ') ? (
                        <Link to="/clients" className="btn btn-info"><i className="fas fa-search mr-2 " /> Search Clients</Link>
                    ) : ''}
                    {hasPrivilege('MATTERS.WRITE') ? (
                        <Link to="/matters/create" className="btn btn-success"><i className="fas fa-plus mr-2 " /> Add New</Link>
                    ) : ''}
                </div>
            )}
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Matters..."
                    query={query}
                    rowClasses={(row) => {
                        if(!row.roles) return ''

                        const isAssignedOnRole = row.roles.some(r => r.user === viewing_user._id)
                        return isAssignedOnRole ? 'row-mine' : '';
                    }}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
