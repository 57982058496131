import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Modal } from "reactstrap";

import SearchCollections from "components/system/Search/Collections";

import { toast } from 'react-toastify';

const TextsSidebarLeftSelecttemplate = ({showModal, toggleModal, onSelect, matters}) => {

    const [matter, setMatter] = useState('')
    const [template, setTextTemplate] = useState({})

    const onMoveOn = useCallback(() => {
        if(!matter) return toast.info(`You must select a matter to proceed.`)
        if(!template._id) return toast.info(`You must select a template to proceed.`)

        onSelect(template, matter);
        toggleModal();

        setTextTemplate({})
    }, [matter, template, onSelect, toggleModal])

    useEffect(() => {
        let _division = matters[0] ? matters[0]._id : ''
        setMatter(_division)
    }, [matters])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Search Templates</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <SearchCollections
                    collection={'text_templates'}
                    title="Select Template Text You Wish To Send"
                    placeholder=""
                    value={template._id}
                    showEnhanced="templates"
                    onChange={(obj) => setTextTemplate(obj.obj)}
                /> 
            </div>

            <div className='modal-body border-top'>
                <Form onSubmit={e => e.preventDefault()}>
                    <p className='text-sm mb-0 font-weight-bold'>Select The Matter You Wish To Associate This Text With:</p>
                    {matters.length  ? matters.map(m => (
                        <div key={m._id} className="custom-control custom-radio mb-3">
                            <input
                                className="custom-control-input"
                                id={`archk-text-message-division-select-${m._id}`}
                                name="archk-text-message-division-select"
                                type="radio"
                                checked={matter === m._id}
                                onClick={e => setMatter(m._id)}
                            />
                            <label className="custom-control-label" htmlFor={`archk-text-message-division-select-${m._id}`}>
                                {m.name}
                            </label>
                        </div>
                    )) : (
                        <p className='text-sm'><i className="fas fa-info-circle mr-2" /> The contact you have selected does not have any matters associated and a template text cannot be generated.</p>
                    )}
                </Form>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onMoveOn}>
                    Select
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
        divisions: state.divisions,
        selected_division: state.state.selected_division,
    };
};

export default connect(mapStateToProps, '')(TextsSidebarLeftSelecttemplate);