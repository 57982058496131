
import { Col, Row } from 'reactstrap';

import moment from 'moment';

import ObjectFinder from 'components/system/Objects/Finder';

const EventsEditHistoryMarkup = ({ foundEvent, history }) => (
    history.length ? history.map((h, i) => {

        let keys = Object.keys(h.old);
        keys = keys.filter(k => k !== 'event')

        return !keys.length ? <></> :  (
            <div key={h._id} className={` mb-0 bo text-itali ${i +1 !== history.length ? '' : ''}`}>

                <Row>
                    <Col md={6}>
                        <p className='mb-0 text-s'>{h.user ? <span>User: <ObjectFinder collection="users" _id={h.user} /></span> : 'Update Origin Unknown'}</p>
                    </Col>
                    <Col md={6}>
                        <p className='mb-0 text-right'>Updated At: {moment.unix(h.updated_at).format('MM/DD/YYYY h:mm A')}</p>
                    </Col>
                </Row>

                <ul className='pl-5 border  p-3 bg-secondary rounded '>
                    {keys.map((key, i) => (
                        <span key={i} >
                            {key === 'description' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Description</div>
                                    <div className='pl-4'>
                                        {h.old.description} <i className="fas fa-arrow-right mx-2" /> {h.new.description}
                                    </div>
                                </li>
                            ) : key === 'location' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Location</div>
                                    <div className='pl-4'>
                                        <ObjectFinder collection="locations" _id={h.old.location} />{' '}
                                        <i className="fas fa-arrow-right mx-2" /> {' '}
                                        <ObjectFinder collection="locations" _id={h.new.location} />{' '}
                                    </div>
                                </li>
                            ) : key === 'name' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Event Name</div>
                                    <div className='pl-4'>
                                        {h.old.name} <i className="fas fa-arrow-right mx-2" /> {h.new.name}
                                    </div>
                                </li>
                            ) : key === 'unix_start' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Start Time</div>
                                    <div className='pl-4'>
                                        {moment.unix(h.old.unix_start).format('MM/DD/YYYY h:mm A')} {' '}
                                        <i className="fas fa-arrow-right mx-2" /> {' '}
                                        {moment.unix(h.new.unix_start).format('MM/DD/YYYY h:mm A')}{' '}
                                    </div>
                                </li>
                            ) : key === 'unix_end' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>End Time</div>
                                    <div className='pl-4'>
                                        {moment.unix(h.old.unix_end).format('MM/DD/YYYY h:mm A')} {' '}
                                        <i className="fas fa-arrow-right mx-2" /> {' '}
                                        {moment.unix(h.new.unix_end).format('MM/DD/YYYY h:mm A')}{' '}
                                    </div>
                                </li>
                            ) : key === 'send_invites_from' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Send Invite From</div>
                                    <div className='pl-4'>
                                        {h.old.send_invites_from ? <ObjectFinder collection="users" _id={h.old.send_invites_from} /> : 'NOT SET'} {' '}
                                        <i className="fas fa-arrow-right mx-2" /> {' '}
                                        {h.new.send_invites_from ? <ObjectFinder collection="users" _id={h.new.send_invites_from} /> : 'NOT SET'} {' '}
                                    </div>

                                </li>
                            )  : key === 'users' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Assigned Users</div>
                                    <div className='pl-4'>
                                        {h.old.users.length ? h.old.users.map(u => <ObjectFinder  key={u} collection="users" _id={u} />) : 'NOT SET'} {' '}
                                        <i className="fas fa-arrow-right mx-2" /> {' '}
                                        {h.new.users.length ? h.new.users.map(u => <ObjectFinder  key={u} collection="users" _id={u} />) : 'NOT SET'}{' '}
                                    </div>

                                </li>
                            ) : key === 'contacts' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Assigned Contacts</div>
                                    <div className='pl-4'>
                                        {h.old.contacts.length ? h.old.contacts.map(u => <ObjectFinder  key={u} collection="contacts" _id={u} />) : 'NOT SET'} {' '}
                                        <i className="fas fa-arrow-right mx-2" /> {' '}
                                        {h.new.contacts.length ? h.new.contacts.map(u => <ObjectFinder  key={u} collection="contacts" _id={u} />) : 'NOT SET'}{' '}
                                    </div>

                                </li>
                            ) : key === 'color' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Color On Calendar</div>
                                    <div className='pl-4'>
                                        <span className='border rounded' style={{position:'relative', top: 2, height: 15, width: 30, display: 'inline-block', background: h.old.color}} /> {' '}
                                        <i className="fas fa-arrow-right mx-2" /> {' '}
                                        <span className='border rounded' style={{position:'relative', top: 2, height: 15, width: 30, display: 'inline-block', background: h.new.color}} /> {' '}
                                    </div>

                                </li>
                            ) : key === 'participants' ? (
                                <li className='mb-0 pb-0'>
                                    <div className={`font-weight-bold text-warning text-uppercase ${i !== 0 ? 'mt-3' : ''}`}>Participants</div>

                                        <div className='text-responsive'>
                                            <table className='table border'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th className='text-right'>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {h.new.participants.map((p, i) => (
                                                    <tr key={i}>
                                                        <td>{p.name ? <span><b>{p.name}</b></span> : <b className='text-warning'>Name Not Found</b>}</td>
                                                        <td>{p.email}</td>
                                                        <td className='text-uppercase text-right'>{p.status === 'yes' ? <span className='text-success'>Confirmed</span> : p.status === 'no' ? <span className='text-danger'>Declined</span> : <span className='text-info'>{p.status}</span>}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>

                                    </div>

                                </li>
                            )  : ''}
                        </span>
                    ))}
                </ul>
            </div>
        )
    }) : (
        <p className='text-sm mb-0'><i className="fas fa-info-circle text-info" /> This event has not been updated since it was created.</p>
    )

)

export default EventsEditHistoryMarkup