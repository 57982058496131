import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Input } from "reactstrap";

import StandardLoader from 'components/markup/layout/StandardLoader';

import Axios from 'axios';

import keys from 'keys';
import api from 'api'

import { toast } from 'react-toastify';

import Transcription from './Transcription'

const ModalPreviewImage = ({showModal, toggleModal, doc, data, setData, onNameUpdated}) => {

    const [err, setErr] = useState(false)
    const [name, setName] = useState(doc ? doc.name : '')

    const onBlur = useCallback(async () => {
        const updated = await api.documents.update(doc._id, { name })
        if(!updated.success) return toast.error(`Could not update document name, please try again.`)

        if(onNameUpdated) onNameUpdated(updated.data)
    }, [doc._id, name, onNameUpdated])

    const getImage = useCallback(async () => {
        const data = await Axios({
            method: 'get',
            url: keys.API_URL + `/v1/documents/${doc._id}/download`,
            responseType:'arraybuffer',
            headers: {
                authorization: `Bearer ${keys.SYSTEM_API_KEY}`
            },
            withCredentials: true,
        })

        if(data.data) {
            const arrayBuffer = data.data;
            const bytes = new Uint8Array(arrayBuffer);
            const blob = new Blob([bytes.buffer]);
            const reader = new FileReader();
    
            reader.onload = function(e) {
                setData(e.target.result)
            };
            reader.readAsDataURL(blob);
        } else {
            setErr(true)
        }

    }, [doc._id, setData])

    useEffect(() => {
        if(!data) getImage()
    }, [getImage, data])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >
            <div className="modal-header " style={{minHeight: 43}}>
                <div className=''>
                    <Input 
                        onBlur={onBlur}
                        value={name}
                        onChange={(e) => setName(e.target.value )}
                        style={{width: 500, maxWidth: '80%'}}
                    />
                </div>

                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body text-center">

                {doc.ai_summary ? (
                    <div className='mb-4'>
                        <div className='text-left border p-3 rounded'>
                            <p className='mb-0 font-weight-bold'>SUMMARY</p>
                            <p className='text-sm mb-0'>{doc.ai_summary}</p>
                        </div>
                        <Transcription doc={doc} background="white" />
                    </div>
                ) : ''}

                {err ? (
                    <p className="mb-0 pt-6 pb-5"><i className="fas fa-info-circle text-info mr-2 " /> No Preview Is Available For This File</p>
                ) : !data ? (
                    <StandardLoader />
                ) : (
                    <img style={{maxWidth: '100%'}} className='rounded border' alt={doc.name} src={data} />
                )}
            </div>

            <div className="modal-footer">
                <button onClick={toggleModal} className="btn btn-warning" >
                    Close
                </button>
            </div>

        </Modal>
    )
}

export default ModalPreviewImage
