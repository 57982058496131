import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ApiError from 'components/markup/layout/ApiError';

import HeaderTabs from 'components/markup/layout/HeaderTabs';

import Categories from './Categories';
import List from './List';
import Missing from './Missing';
import Signing from './Signing';
import WordDocuments from './WordDocuments';

import { getUrlParameter, setUrlParameter } from 'utils/urls';

const tabs = ['List', 'Missing', 'Signing', 'Categories', 'Doc Hunter', 'WordDocuments']

const MatterViewMainDocuments = ({contact, matter}) => {

    const [tab, setTab] = useState('Missing')
    const [err] = useState(false)


    const [signingDocuments, setSigningDocuments] = useState([])

    const [matterDocuments, setMatterDocuments] = useState([])
    const [matterDocumentAssignments, setMatterDocumentAssignments] = useState([])

    const docs = matter.missing_documents.length

    const onSetTab = useCallback((_tab) => {
        setTab(_tab);
        setUrlParameter('docsTab', _tab)
    }, [])

    useEffect(() => {
        const t = getUrlParameter('docsTab')
        if(tabs.includes(t)) setTab(t)
    }, [])

    if(err) return <ApiError err={err} />

    return (
        <div className="section pt-0">

            <div className="section bg-white z-depth- border-top text-white pt-3 borer-top mt-0 pb-0 " style={{borderBottom: 'none'}}>
                <HeaderTabs 
                    className="mb-0"
                    tab={tab}
                    setTab={(tab) => onSetTab(tab)}
                    tabs={[
                        { value: 'Missing', name: <span className="text-whit"><i className="fas fa-search " /> Step Docs {docs ? `(${docs})` : ''}</span> },
                        { value: 'Categories', name: <span className="text-whit"><i className="fas fa-search " /> Categories</span> },
                        { value: 'List', name: <span className="text-whit"><i className="fas fa-list pl-1 " /> List</span> },
                        { value: 'Pinned', name: <span className="text-whit"><i className="fas fa-map-pin pl-1 " /> Pinned</span> },
                        { value: 'Signing', name: <span className="text-whit"><i className="fas fa-signature " /> Signing</span> },
                        { value: 'WordDocuments', name: <span className="text-whit"><i className="fas fa-file " /> Templates</span> },
                        // { value: 'Doc Hunter', name: <span className="text-whit"><i className="fas fa-search-location " /> Doc Hunter</span> },
                    ]}
                />
            </div>

            {tab === 'List' ? (
                <List pinned={false} />
            ) : tab === 'Pinned' ? (
                <List pinned={true} />
            ) : tab === 'Signing' ? (
                <Signing 
                    signingDocuments={signingDocuments}
                    setSigningDocuments={setSigningDocuments}
                />
            ) : tab === 'Missing' ? (
                <Missing 
                    matterDocuments={matterDocuments}
                    setMatterDocuments={setMatterDocuments}
                    matterDocumentAssignments={matterDocumentAssignments}
                    setMatterDocumentAssignments={setMatterDocumentAssignments}
                />
            ) : tab === 'Categories' ? (
                <Categories 
                    matterDocuments={matterDocuments}
                    setMatterDocuments={setMatterDocuments}
                    matterDocumentAssignments={matterDocumentAssignments}
                    setMatterDocumentAssignments={setMatterDocumentAssignments}
                    matter={matter}
                />
            )  : tab === 'WordDocuments' ? (
                <WordDocuments 
                    matterDocuments={matterDocuments}
                    setMatterDocuments={setMatterDocuments}
                    matterDocumentAssignments={matterDocumentAssignments}
                    setMatterDocumentAssignments={setMatterDocumentAssignments}
                    matter={matter}
                />
            )  : ''}

        </div>

    );

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(MatterViewMainDocuments);