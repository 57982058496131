import { connect } from 'react-redux';

import Hearing341Review from './Hearing341Review';
import HearingConfirmationReview from './HearingConfirmationReview';
import PaymentHistory from './PaymentHistory';
import PendingMatters from './PendingMatters';
import FinancialDetails from './FinancialDetails';
import ReviewNotes from './ReviewNotes';
import Trustee from './Trustee';

const ModuleBKOverview = ({ data, onInputChange }) => {

    return (
        <div>
            <Trustee 
                data={data}
                onInputChange={onInputChange}
            />
            <PaymentHistory 
                data={data}
                onInputChange={onInputChange}
            />
            <FinancialDetails 
                data={data}
                onInputChange={onInputChange}
            />
            
            <Hearing341Review 
                data={data}
                onInputChange={onInputChange}
            />
            <HearingConfirmationReview 
                data={data}
                onInputChange={onInputChange}
            />
            <PendingMatters 
                data={data}
                onInputChange={onInputChange}
            />
            <ReviewNotes 
                data={data}
                onInputChange={onInputChange}
            />


        </div>


    )
}

const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


