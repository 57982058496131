
import { useCallback, useState, useReducer } from 'react';
import { useEffect } from "react";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Header from './Header';
import Main from './Main';
import SidebarLeft from './SidebarLeft';
import SidebarRight from './SidebarRight';
import Send from './Send';

import api from 'api'

import Circle from 'components/markup/loading/Circle'

function reducer(state, action) {
    if(action.type === 'add') {
        const all = JSON.parse(JSON.stringify(state))
        action.new.forEach(a => {
            if(!all.some(aa => aa.data === a.data)) all.push(a)
        })
        return all;
    } else {
        return []
    }
}

const Texts = ({socket, match}) => {

    const [allAttachments, setAllAttachments] = useReducer(reducer, { new: [] });

    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(null)
    const [thread, setThread] = useState(null)
    const [to, setTo] = useState({})

    const scrollToBottom = useCallback((tries = 0, behavior) => {
        if(tries > 20) return;

        const bottomDiv = document.getElementById("archk-text-message-end");
        const container = document.querySelector(".archk-messaging-main");

        if(bottomDiv && container) return container.scroll({ top: container.scrollHeight, behavior });

        setTimeout(() => {
            scrollToBottom(tries + 1)
        }, 250)


    }, [])

    const onSetActive = useCallback(async (_active, hideReload) => {
        if(_active && _active._id && active && active._id && _active._id === active._id && !_active.message_ids) return;
        if(active && active._id) api.email_threads.read(active._id, { unread: false })

        if(!hideReload) {
            setThread(null);
            setAllAttachments({ new: [], type: 'reset' })
        }

        if(_active._id) {
            const data = await api.email_threads.findById(_active._id);
            if(data.data) {
                setThread(data.data)
                setActive(_active)
    
                scrollToBottom()
            }
        }
      
    }, [active, scrollToBottom])

    const onThreadUpdated = useCallback((params) => {
        if(thread && thread.thread._id === params._id) {
            onSetActive(params, true)
        }
    }, [thread, onSetActive])

    useEffect(() => {
        if(!match.params.thread_id) return;

        const fetchThread = async () => {
            setLoading(true)
            const data = await api.email_threads.findById(match.params.thread_id);
            setLoading(false)

            if(data.data) {
                setThread(data.data)
                setActive(data.data.thread)

                scrollToBottom()
            }
        }

        fetchThread()
    }, [match.params.thread_id, scrollToBottom])


    useEffect(() => {
        socket.on('EMAIL_THREADS.CREATED', onThreadUpdated)
        socket.on('EMAIL_THREADS.UPDATED', onThreadUpdated)
        return () => {
            socket.off('EMAIL_THREADS.CREATED', onThreadUpdated)
            socket.off('EMAIL_THREADS.UPDATED', onThreadUpdated)
        }

    }, [socket, onThreadUpdated])
    
    useEffect(() => {
        document.body.classList.add('noScroll')

        return () => {
            document.body.classList.remove('noScroll')
        }
    }, [])

    if(loading) return <Circle className="py-6" />

    return (

        <div className="archk-messaging">
            <div className="archk-messaging-wrapper">

                <Helmet>
                    <title>Emails</title>
                    <meta name="description" content="Emails" />
                </Helmet>

                <Header 
                    thread={thread}
                    active={active}
                />

                <SidebarLeft
                    active={active}
                    setActive={onSetActive}
                />
    
                <Main 
                    thread={thread}
                    active={active}
                    to={to.value}
                    setAllAttachments={a => setAllAttachments({ new: a, type: 'add' })}
                />

                <Send 
                    to={to.value}
                    thread={thread}
                />

                <SidebarRight 
                    thread={thread}
                    active={active}
                    to={to}
                    setTo={setTo}
                    allAttachments={allAttachments}

                />
        
            </div>
        </div>
    )

}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Texts);
