import { connect } from 'react-redux';
import { useState, useCallback, useEffect, useRef } from 'react';
import { FormGroup, Input, Row, Col, Badge, Modal } from 'reactstrap';

import { toast } from 'react-toastify'
import Circle from 'components/markup/loading/Circle'

import SearchCollections from 'components/system/Search/Collections'

import api from 'api'

import SelectColor from 'components/markup/inputs/Colors';
import DateTime from 'components/markup/inputs/DateTime';
import ObjectFinder from 'components/system/Objects/Finder';
import A from 'components/markup/links/A'
import ModalToggler from 'components/functional/modals/Toggler'
import CallContact from 'components/system/CallContact'

import moment from 'moment';

import History from './History'
import Finished from './Finished'
import { toggleStandardLoader } from 'store/functions/system/system';

import ReactQuill from 'react-quill'


const EventsEdit = ({ matter, task, eventTemplate, onSave, _id, showModal, toggleModal, closeOnSave, useSaveButtonId, viewing_user }) => {

    const reactQuillRef = useRef();

    const [foundEvent, setFoundEvent] = useState(null)
    const [showHistory, setShowHistory] = useState(false)
    const [saving, setSaving] = useState(false)
    const [foundMatter, setFoundMatter] = useState(false)
    const [foundEventTemplate, setFoundEventTemplate] = useState(false)
    const [templateLocked, setTemplateLocked] = useState(false)
    const [history, setHistory] = useState([])
    const [finished, setFinished] = useState(null)
    const [originator, setOriginator] = useState(viewing_user._id)
    const [email, setEmail] = useState('')
    const [emails, setEmails] = useState([])

    const [err, setErr] = useState(false)
    const [valErr, setValErr] = useState(false)

    const [start, setStart] = useState(parseInt(moment().startOf('day').format('X')) + 43200);
    const [end, setEnd] = useState(parseInt(moment().startOf('day').format('X')) + 45000);
    
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    
    const [users, setUsers] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [location, setLocation] = useState(null);

    const onTimeChange = useCallback((_start, _end, type) => {
        if(_start === start) {
            setEnd(_end)
        } else if(foundEventTemplate && foundEventTemplate.default_length && type !== 'day') {
            setStart(_start)
            setEnd(_start + foundEventTemplate.default_length)
        } else {
            setStart(_start)
            setEnd(_end)
        }
    }, [start, foundEventTemplate])

    const markFinished = useCallback(async () => {

        // find event outcomes
        //      if no event outcomes just mark resolved
        // if event outcomes display outcomes and event resolution text
        // validate these two
        // finish event

        setSaving(true)
        const updated = await api.events.finish(_id, {})
        setSaving(false)
        if(updated.success) return onSave(updated.data)
        toast.error('Please try again')
    }, [_id, onSave])

    const _onSave = useCallback(async () => {

        setValErr(false)
        if(!originator) return setValErr(`You must select the user you are creating this event on behalf of.`)
        if(!foundEventTemplate) return setValErr(`A template must be selected for this event.`)
        if(!contacts.length) return setValErr(`At least one contact must be specified for this event`)
        if(foundEventTemplate.location_required && !location) return setValErr(`A location must be set for this event.`)

        if(start >= end) return setValErr(`Event end time must be greater than the event start time`)

        const data = {
            division          : foundMatter.division,
            matter            : foundMatter._id,
            location          : location,
            event_template    : foundEventTemplate._id,
            task              : task,
            users             : users,
            contacts          : contacts,
            emails            : emails,
            name              : name,
            description       : description,
            color             : color,
            unix_start        : start,
            unix_end          : end,
            originator        : originator,
        }

        setSaving(true)
        if(useSaveButtonId) toggleStandardLoader(true)
        const saved = _id ? await api.events.update(_id, data) : await api.events.create(data)
        setSaving(false)
        if(useSaveButtonId) toggleStandardLoader(false)

        if(!saved.success) {
            // console.log('herer')
            // console.log(saved.message)
            // if(saved.message && saved.message[0]) {
            //     return toast.error(saved.message[0])
            // }
            return toast.error(`Could not save event, please try again or check your email is actively synced.`)
        }
        onSave(saved.data)
        if(closeOnSave) toggleModal()
    }, [
        _id, 
        closeOnSave, 
        color, 
        contacts, 
        description, 
        end, 
        foundEventTemplate, 
        foundMatter, 
        location, 
        name, 
        onSave, 
        originator, 
        start, 
        task, 
        toggleModal, 
        users,
        emails,
        useSaveButtonId
    ])

    const onAddUser = useCallback((user) => {
        const _users = JSON.parse(JSON.stringify(users));

        if(!users.includes(user)) {
            _users.push(user)
            setUsers(_users)
        }
    }, [users])
    
    const onRemoveUser = useCallback((user) => {
        let _users = JSON.parse(JSON.stringify(users));
        _users = _users.filter(u => u !== user)
        setUsers(_users)
    }, [users])

    const onAddEmail = useCallback(() => {
        const _emails = JSON.parse(JSON.stringify(emails));

        if(!emails.includes(email)) {
            _emails.push(email)
            setEmails(_emails)
            setEmail('')
        }
    }, [email, emails])
    
    const onRemoveEmail = useCallback((email) => {
        let _emails = JSON.parse(JSON.stringify(emails));
        _emails = _emails.filter(u => u !== email)
        setEmails(_emails)
    }, [emails])
  
    const onAddContact = useCallback((contact) => {
        const _contacts = JSON.parse(JSON.stringify(contacts));

        if(!contacts.includes(contact)) {
            _contacts.push(contact)
            setContacts(_contacts)
        }
    }, [contacts])
    
    const onRemoveContact = useCallback((contact) => {
        let _contacts = JSON.parse(JSON.stringify(contacts));
        _contacts = _contacts.filter(u => u !== contact)
        setContacts(_contacts)
    }, [contacts])

    const onSetFoundEventTemplate = useCallback(async (template) => {
        const data = await api.event_templates.findById(template)
        if(data.data) {
            setFoundEventTemplate(data.data)
            setName(data.data.name)
            setDescription(data.data.description)
            setColor(data.data.color)
            if(data.data.default_length) setEnd(start + data.data.default_length)

        }
    }, [start])

     //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') onAddEmail()
	}, [onAddEmail])
    

    const fetchData = useCallback(async () => {

        let matter_id = matter;
        let template_id = eventTemplate
        let foundEvent = null

        if(_id) {
            const event = await api.events.findById(_id)
            if(!event.data) return setErr(`The event you have selected either cannot be edited or is an event originating outside of IntuiDesk.`)
            foundEvent = event.data
            template_id = foundEvent.event_template
            matter_id = foundEvent.matter
            setFoundEvent(foundEvent)
        }


        let promiseArr = [api.matters.findById(matter_id)];
        if(template_id) promiseArr.push(api.event_templates.findById(template_id))

        const values = await Promise.all(promiseArr);

        const _matter = values[0].data
        const _template = values[1] ? values[1].data : null

        if(!_matter) return setErr(`Matter could not be found to schedule and event for. Please refresh your page.`)

        setFoundMatter(_matter)

        if(foundEvent) {
            if(_template) setFoundEventTemplate(_template)
            setHistory(foundEvent.history.reverse())
            if(foundEvent.finished_at) return setFinished(foundEvent)
            setName(foundEvent.name)
            setDescription(foundEvent.description)
            setColor(foundEvent.color)
            setLocation(foundEvent.location)
            setContacts(foundEvent.contacts)
            setUsers(foundEvent.users)
            setStart(foundEvent.unix_start)
            setEnd(foundEvent.unix_end)
            setEmails(foundEvent.emails ? foundEvent.emails : [])
        } else if(_template) {
            setFoundEventTemplate(_template)
            setName(_template.name)
            setDescription(_template.description)
            setColor(_template.color)

            setTemplateLocked(true)
        } else {
            setTemplateLocked(false)
        }
    }, [matter, eventTemplate, _id])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if(useSaveButtonId) {
            const el = document.getElementById(useSaveButtonId)
            el.addEventListener('click', _onSave)

            return () => {
                el.removeEventListener('click', _onSave)
            }
        }
    }, [_onSave, useSaveButtonId])

    useEffect(() => {
        if(!showModal) setFoundEventTemplate(false)
    }, [showModal])


    const availableRoles =  foundMatter ? foundMatter.roles.filter(r => !users.includes(r.user)) : []
    const availableContacts =  foundMatter ? foundMatter.parties.filter(r => !contacts.includes(r.contact)) : []

    const mainMarkup = (
        <div className="">

            {foundEvent && foundMatter ? (
                <div>
                    <div className="bg-success border-bottom px-4 rounded py-3 border">
                        <Row>
                            <Col xs={6} className='align-self-center'>
                                <A href={`/matters/${foundMatter.id}`} className="text-white">
                                    <b className='text-white'>Matter: </b> 
                                    <span className='text-white'>{foundMatter.name}</span>
                                </A>
                            </Col>
                            <Col xs={6} className='align-self-center text-right'>
                                <ModalToggler component={CallContact} matter={foundMatter._id} >
                                    <button className='btn btn-sm btn-info' >
                                        Call Contact <i className="fas fa-user ml-2" />
                                    </button>
                                </ModalToggler>
                            </Col>
                        </Row>
                        
                        
                    </div>

                    <hr className='mb-2 mt-3' />
                </div>
            ) : ''}

            <h2 className='display- lead mt-0'> EVENT ORIGINATOR</h2>

            {foundEvent ? (
                <div className='mb-4'>
                    <b className='text-dark font-weight-bold'><ObjectFinder collection="users" _id={originator} /></b>{' '}
                    <i className="fas fa-info-circle text-info pl-2" /> - The event originator cannot be changed after an event has been created.
                    <hr />
                </div>
            ) : (
                <SearchCollections
                    menuPlacement="bottom"
                    formGroup={true}
                    collection="users" 
                    title="Who are you creating this event on behalf of?"
                    value={originator}
                    onChange={(obj) => setOriginator(obj.value)}
                    // disabled={templateLocked}
                    filter={{
                        divisions: { $in: [foundMatter.division] } ,
                    }}
                /> 
            )}

           

            <h2 className='display- lead mt-0'>EVENT INFO</h2>

            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className='form-control-label'>Event Name</label>
                        <Input 
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormGroup>
                    <SelectColor 
                        title="Color On Calendar"
                        value={color}
                        onChange={(c) => setColor(c)}
                        menuPlacement="top"
                    />
                    
                    <FormGroup className="hide-toolbar">
                        <label className='form-control-label'>Event Description</label>
                        {/* <Input 
                            style={{minHeight: 84}}
                            type="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        /> */}
                        <ReactQuill 
                            value={description}
                            ref={reactQuillRef}
                            onChange={(e) => setDescription(e)}
                            theme="snow"
                            modules={{
                                // toolbar: [
                                //     ["bold", "italic"],
                                // ]
                            }}
                        />
                    </FormGroup>
                </Col>
                
                <Col lg={6}>

                    <SearchCollections
                        menuPlacement="bottom"
                        formGroup={true}
                        collection="event_templates" 
                        title="Event Template"
                        value={foundEventTemplate ? foundEventTemplate._id : undefined}
                        onChange={(obj) => onSetFoundEventTemplate(obj.value)}
                        disabled={templateLocked}
                        filter={{
                            division: foundMatter.division,
                        }}
                    /> 

                    <DateTime 
                        title="Select Day"
                        startTime={start} 
                        endTime={end} 
                        onChange={onTimeChange} 
                    />
                   
                </Col>
            </Row>

            <hr />

            <h2 className='display- lead mt-0'>PHYSICAL LOCATION</h2>

            <SearchCollections
                menuPlacement="top"
                formGroup={true}
                collection="locations" 
                title="Select Location"
                value={location}
                onChange={(obj) => setLocation(obj.value)}
                filter={{
                    division: foundMatter.division,
                    ...(foundEventTemplate.location_type && {location_types: foundEventTemplate.location_type})
                }}
            /> 

            <hr />

            <h2 className='display- lead mt-0'>EVENT CONTACTS</h2>

            {contacts.length ? contacts.map(contact => (
                <Badge onClick={() => onRemoveContact(contact)} className='cursor-pointer' key={contact} color="info">
                    <ObjectFinder collection="contacts" _id={contact} /> <i className="fas fa-trash ml-3" />
                </Badge>
            )) : <p className="mb-0 text-sm mt--3"><i className="fas fa-times mr-2 text-danger" /> No Contacts Assigned</p>}

            <h3 className='mb-2 mt-4'>Add Contacts From Matter Roles</h3>
            {availableContacts.length ? availableContacts.map(r => !r.contact ? <></> : (
                <Badge onClick={() => onAddContact(r.contact)} className='cursor-pointer' key={r.workflow_contact} color="purple">
                    <ObjectFinder collection="workflow_contacts" _id={r.workflow_contact} />:{' '}
                    <ObjectFinder collection="contacts" _id={r.contact} /> <i className="fas fa-plus ml-3" />
                </Badge>
            )) : <p className="mb-0 text-sm"><i className="fas fa-info-circle text-warning mr-2" /> No Contacts Found OR All Contacts Assigned</p>}

            <hr />
            
            <h2 className='display- lead mt-0'>ADDITIONAL USERS</h2>

            {users.length ? users.map(user => (
                <Badge onClick={() => onRemoveUser(user)} className='cursor-pointer' key={user} color="info">
                    <ObjectFinder collection="users" _id={user} /> <i className="fas fa-trash ml-3" />
                </Badge>
            )) : <p className="mb-0 text-sm mt--3"><i className="fas fa-times mr-2 text-danger" /> No Users Assigned</p>}
            
            <h3 className='mb-2 mt-4'>Add Users From Matter Roles</h3>
            {availableRoles.length ? availableRoles.map(r => !r.user ? <></> : r.user === originator ? <span key={r.workflow_role} /> : (
                <Badge onClick={() => onAddUser(r.user)} className='cursor-pointer' key={r.workflow_role} color="purple">
                    <ObjectFinder collection="workflow_roles" _id={r.workflow_role} />:{' '}
                    <ObjectFinder collection="users" _id={r.user} /> <i className="fas fa-plus ml-3" />
                </Badge>
            )) : <p className="mb-0 text-sm"><i className="fas fa-info-circle text-warning mr-2" /> No Users Found OR All Users Assigned</p>}


            <hr />
          
            <h2 className='display- lead mt-0'>ADDITIONAL EMAIL ADDRESSES</h2>

            {emails.length ? emails.map(email => (
                <Badge onClick={() => onRemoveEmail(email)} className='cursor-pointer' key={email} color="info">
                    {email} <i className="fas fa-trash ml-3" />
                </Badge>
            )) : <p className="mb-0 text-sm mt--3"><i className="fas fa-times mr-2 text-danger" /> No Users Assigned</p>}
            
            <h3 className='mb-2 mt-4'>Paste Emails Addresses Below and Hit Enter</h3>
            <FormGroup>
                <Input 
                    onKeyDown={_handleKeyDown}
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormGroup>


            <hr />


            {valErr ? (
                <div className='alert alert-warning'>{valErr}</div>
            ) : ''}

            {!toggleModal && !useSaveButtonId ? (
                <div className='my-3'>
                    <button disabled={saving} onClick={_onSave} className='btn btn-success'><i className="fas fa-save mr-2" /> {_id ? 'Save Changes' : 'Create Event'}</button>
                    {_id ? <button disabled={saving} onClick={() => markFinished()} className="btn btn-outline-success"><i className="fas fa-check mr-2" /> Mark Completed</button> : ''}
                    {_id ? <button disabled={saving} onClick={() => setShowHistory(!showHistory)} className="btn btn-outline-info"><i className="fas fa-eye mr-2" /> Event History</button> : ''}
                </div>
            ) : ''}

        </div>
    )
    
    let markup = <></>;
    if(err) {
        markup =  <div className='alert alert-warning my-3'>{err}</div>
    } else if(!foundMatter) {
        markup = <Circle />
    } else if(showHistory) {
        markup = <History foundEvent={foundEvent} history={history} saving={saving} showHistory={showHistory} setShowHistory={setShowHistory} />
    } else if(finished) {
        markup = <Finished foundEvent={foundEvent} history={history}  finished={finished} />
    } else if(foundEventTemplate) {
        markup = mainMarkup
    } else {
        markup = (
            <SearchCollections
                menuPlacement="bottom"
                formGroup={true}
                collection="event_templates" 
                title="Select the type of event to create:"
                value={foundEventTemplate ? foundEventTemplate._id : undefined}
                onChange={(obj) => onSetFoundEventTemplate(obj.value)}
                disabled={templateLocked}
                filter={{
                    division: foundMatter.division,
                }}
            /> 
        )
    }

    if(!toggleModal) return markup

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">View Event</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                {markup}
            </div>

            <div className="modal-footer d-block">
                <Row>
                    <Col md={err ? 12 : 3} className={err ? 'text-right' : '    ' }>
                        <button className="btn btn-outline-warning" onClick={toggleModal}>Close</button>
                    </Col>

                    <Col md={9} className="text-right">
                        {_id && !err ? (
                            <button disabled={saving} onClick={() => setShowHistory(!showHistory)} className="btn btn-outline-info">
                                <i className="fas fa-eye mr-2" /> Event History
                            </button>
                        ) : ''}

                        {_id && !err ? (
                            <button disabled={saving} onClick={() => markFinished()} className="btn btn-outline-success">
                                <i className="fas fa-check mr-2" /> Mark Completed
                            </button>
                        ) : ''}
                
                        {foundEventTemplate ? (
                            <button disabled={saving} onClick={_onSave} className='btn btn-success'>
                                <i className="fas fa-save mr-2" /> {_id ? 'Save Changes' : 'Create Event'}
                            </button>
                        ) : ''}
                    </Col>
                </Row>
            </div>

        </Modal>

    )
    
}


const mapStateToProps = state => {
    return {
        COLORS: state.config.COLORS,
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(EventsEdit);