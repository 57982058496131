import { connect } from 'react-redux';
import { Card, CardFooter, CardHeader, CardTitle, FormGroup, Input } from 'reactstrap';

import ReactSelect from 'components/functional/inputs/ReactSelect';




const ModuleBKOverview = ({ data, onInputChange }) => {
    return (
        <div>
              <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Meeting Of Creditors</CardTitle>
                </CardHeader>

                <CardFooter>

                    <ReactSelect
                        title="341 Held / Concluded?"
                        formGroup={true}
                        onChange={(obj) => onInputChange('hearing_341_accepted', obj.value)}
                        options={[
                            { value: false, label: 'No' },
                            { value: true, label: 'Yes' },
                        ]}
                        value={data.hearing_341_accepted}
                    /> 

                </CardFooter>

                <CardFooter> 
                    <FormGroup>
                        <label className='form-control-label'>341 Not Held / Unmet Condition Notes</label>
                        <Input 
                            type="textarea"
                            style={{minHeight: 60}}
                            value={data.hearing_341_notes}
                            onChange={e => onInputChange('hearing_341_notes', e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>

            </Card>
        </div>


    )
}


const mapStateToProps = state => {
	return {
        matter: state.matters.selected_matter,
	};
};

export default connect(mapStateToProps, '')(ModuleBKOverview);


