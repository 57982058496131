import { useCallback, useState } from 'react';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from "reactstrap";

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';


const baseState = {
    description   : '',
    quantity      : 1,
    amount        : '',
}

const ModalCustomFields = ({invoice, onAddArrayItem, onRemoveArrayItem}) => {

    const [expense, setExpense] = useState(baseState);

    const onAddExpense = useCallback(() => {
        if(expense.amount  === '' || expense.amount < 0) return toast.info('Expense amount must be greater or equal to 0.')
        if(expense.quantity  === '' || expense.quantity < 1) return toast.info('Expense quantity must be greater or equal to 1.')
        if(!expense.description) return toast.info('Expense description cannot be left blank.')

        onAddArrayItem('expenses', { 
            ...expense,
            amount: parseFloat(expense.amount),
            quantity: parseFloat(expense.quantity),
        })
        setExpense(baseState)
    }, [expense, onAddArrayItem])

    const onSetExpense = useCallback((field, val) => {
        const _expense = JSON.parse(JSON.stringify(expense))
        _expense[field] = val
        setExpense(_expense)
    }, [expense])

    const disabled = expense.amount === '' || !expense.quantity || !expense.description;

    return (

        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Add Expenses</CardTitle>
                </CardHeader>
                
                <CardBody>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Item Amount*</label>
                                <Input 
                                    type="number"
                                    value={expense.amount}
                                    onChange={(e) => onSetExpense('amount', e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Item Quantity*</label>
                                <Input 
                                    type="number"
                                    value={expense.quantity}
                                    onChange={(e) => onSetExpense('quantity', e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                  
                    <FormGroup>
                        <label className='form-control-label'>Item Description*</label>
                        <Input 
                            type="text"
                            value={expense.description}
                            onChange={(e) => onSetExpense('description', e.target.value)}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <button 
                        className={`btn btn-${disabled ? 'outline-info' : 'success'} btn-sm`}
                        onClick={onAddExpense}
                        disabled={disabled}
                    >
                        <i className="fas fa-plus mr-2" /> Add Expense
                    </button>
                    {disabled ? <span>* Above Fields Required</span> : ''}
                </CardFooter>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Current Expense Items</CardTitle>
                </CardHeader>
                
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Quantity</th>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        {invoice.expenses.length ? invoice.expenses.map((obj, i) => (
                            <tr key={i}>
                                <td>{obj.description}</td>
                                <td>{formatCurrency(obj.amount)}</td>
                                <td>{obj.quantity}</td>
                                <td>
                                    <i 
                                        className="fas fa-trash text-danger cursor-pointer"  
                                        onClick={() => onRemoveArrayItem('expenses', i)}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>*No Expenses Have Been Added</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Card>

          
        </div>

    )
}

export default ModalCustomFields