import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Modal } from "reactstrap";

import { toast } from 'react-toastify';

import api from 'api';

import ReactQuill from 'react-quill';

const ModalDivisionOverride = ({showModal, toggleModal, obj, onUpdated}) => {

    const ref = useRef(null)
    const [note, setNote] = useState(obj.value)
    const [disabled, setDisabled] = useState('')

    const createNote = useCallback(async () => {
        if(!note) return;
        let val = note;

        const trimEndingBreaks = () => {
            let end = val.slice(val.length - 11, val.length)
            if(end === '<p><br></p>') {
                val = val.slice(0, val.length - 11)
                trimEndingBreaks()
            }
        }

        trimEndingBreaks()

        setDisabled(true)
        const updated = await api.notes.update(obj._id, { value: val })
        setDisabled(false)

        if(!updated.success) return toast.error("Note was not saved, please try again")
        
        onUpdated(updated.data)
        toggleModal()

    }, [note, obj._id, onUpdated, toggleModal])

    useEffect(() => {
        if(note === '') {
            const el = ref.current;
            el.focus()
        }
      }, [note]);

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Edit Note</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <div className="position-relative archk-feed-create">
                    <FormGroup className="hide-toolbar">

                    <ReactQuill 
                        defaultValue={note}
                        value={note}
                        ref={ref}
                        onChange={(value) => setNote(value)} 
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic"],
                            ]
                        }}
                    />
                    </FormGroup>
                 
                </div>

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    <i className="fas fa-arrow-left mr-2" /> Close
                </button>
                <button disabled={disabled} onClick={createNote} className="btn btn-sm btn-success">
                    Save Note
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(ModalDivisionOverride);