import { useCallback, useEffect, useState } from 'react';

import Circle from 'components/markup/loading/Circle'

import moment from 'moment';
import api from 'api'

import reactHTMLParser from 'html-react-parser';

import downloadBkDocument from '../_utils/downloadBkDocument';


const ModuleBKDocketEntries = ({ bkCase }) => {
    const hasInfo = bkCase && bkCase.case_number && bkCase.court_code;

    const [entries, setEntries] = useState(null);
    const [err, setErr] = useState(false);

    const fetchData = useCallback(async () => {
        if(!hasInfo) return;
        const data = await api.modules.bk.docket_entries.find(bkCase.court_code, bkCase.case_number)

        if(!data.success) return setErr(`Internal Error, Please Refresh Your Page`);
        if(data.data) return setEntries(data.data)
        return setEntries([])

    }, [bkCase.case_number, bkCase.court_code, hasInfo])

    useEffect(() => {
        fetchData()
    }, [fetchData])
  
    return (
        <div>

            <h2 className='text-warning'>Docket Entries</h2>

            {!hasInfo ? (
                <div className='alert alert-info'><i className="fas fa-info-circle mr-2" /> A case must have a valid case number and court code to pull in docket entries</div>
            ) : err ? (
                <div className='alert alert-warning'>{err}</div>
            ) : !entries ? (
                <Circle />
            ) : (
                !entries.length ? (
                    <p className='text-sm'>No Docket Entries were found for this case.</p>
                ) : (
                    <div className='table-responsive border'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Filing Date</th>
                                    <th className='text-center' style={{width: 40}}>#</th>
                                    <th>Text</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entries.map(entry => (
                                    <tr className='mb-5' key={entry._id}>
                                        <td style={{verticalAlign: 'top'}}>{moment.unix(entry.timestamp_unix).format('MM/DD/YYYY h:mm A')}</td>
                                        <td  style={{verticalAlign: 'top'}} className='text-center'>{entry.docket_no}</td>
                                        <td>
                                            {reactHTMLParser(entry.docket_text)}
                                            {entry.annotations && entry.annotations.length ? (
                                                <div className='border mt-3 p-3'>
                                                    <h5>Important Dates</h5>
                                                    {entry.annotations.map(d => (
                                                        <div>
                                                        { d.name}
                                                            <p className='text-sm mb-0'>{d.date}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ''}
                                        
                                            {entry.documents && entry.documents.length ? (
                                                <div className='border mt-3 p-3'>
                                                    <h5>Associated Documents</h5>
                                                    {entry.documents.map(d => (
                                                        <div>
                                                            <span className='cursor-pointer' onClick={() => downloadBkDocument(d)}>
                                                            {d.name}

                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ''}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            )}

        </div>


    )
}

export default ModuleBKDocketEntries;


