import { useCallback, useEffect, useState, memo } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

import api from 'api';

import ApiError from "components/markup/layout/ApiError";
import Circle from 'components/markup/loading/Circle';

const MattersCreateWorkflows = ({onSetWorkflow, division}) => {
    const [ workflows, setWorkflows ] = useState(false)
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = useCallback(async () => {
        const query = await api.workflows.search({ filter: { division: division._id, active: true } })
        if(query.data) {
            if(query.data.documents.length === 1) return onSetWorkflow(query.data.documents[0])
            return setWorkflows(query.data.documents)
        }
        setErr(query.message)
    }, [division._id, onSetWorkflow])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    
    if(err) return <ApiError err={err} />
    if(!workflows) return <Circle className="py-6" />

    if(!workflows.length) return (
        <Card>
            <CardBody><p className="text-sm mb-0">No workflows currently exist on this division to create a matter with</p></CardBody>
        </Card>
    )

    return (
        <div>
            <h4>Select A Matter Type</h4>
            <Row>
                {workflows.map(workflow => (
                    <Col lg={3} key={workflow._id}>
                        <Card className="cursor-pointer card-hover" onClick={() => onSetWorkflow(workflow)}>
                            <CardHeader>
                                <CardTitle className="mb-0"><i className="fas fa-route mr-2 " /> Workflow</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <p className="mb-0">{workflow.name}</p>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )

}

export default memo(MattersCreateWorkflows);