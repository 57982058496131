import { connect } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import AddBanner from './AddBanner'

import { toast } from 'react-toastify';

import ModalToggler from 'components/functional/modals/Toggler'
import api from 'api'
import ObjectFinder from 'components/system/Objects/Finder';

import OutstandingItems from './OutstandingItems';

const MattersViewMainComponentsBanners = ({matter, setShowAlerts, onSetTab}) => {

    const [allBanners, setAllBanners] = useState(JSON.parse(JSON.stringify(matter.banners)))
    const [allTags, setAllTags] = useState(JSON.parse(JSON.stringify(matter.tags)))
    const [now, setNow] = useState(Math.floor(new Date() / 1000));

    let hasAlerts = matter.alerts.length;
    let eventOverdue = matter.event_overdue_at && matter.event_overdue_at < now;
    let deadlineOverdue = matter.deadline_overdue_at && matter.deadline_overdue_at < now;
    let hasUpcomingAutomation = matter.next_automation_at >= now;
  
    const onRemoveTag = useCallback(async (tag_id) => {
        const data = await api.matters.tagRemove({ matter: matter._id, tag: tag_id })
        if(!data.data) return toast.error(`Something's not right, please try again.`)


        setAllTags(allTags.filter(t => t !== tag_id))
    }, [matter._id, allTags])
    
    const onRemoveBanner = useCallback(async (banner) => {
        const newBanners = allBanners.filter(b => b !== banner)
        const data = await api.matters.update(matter._id, {banners: newBanners })
        if(!data.data) return toast.error(`Something's not right, please try again.`)


        setAllBanners(newBanners)
    }, [matter._id, allBanners])


    useEffect(() => {
        let interval = setInterval(() => {
            setNow(Math.floor(new Date() / 1000))
        }, 2500);

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className="section" style={{marginTop: -1.5}} >
             <ModalToggler component={AddBanner} banners={allBanners} tags={allTags} allBanners={allBanners} setAllBanners={setAllBanners} setAllTags={setAllTags}>
                <p className="text-sm mb-0 cursor-pointer rounded px-4 border border-info text-info mr-2 my-1" style={styles.banner}>
                    <i className="fas fa-plus " /> Add Banner
                </p>
            </ModalToggler>
            {/* payment_overdue */}
            
            {hasUpcomingAutomation ? (
                <p 
                    id="archk-matter-has-automation" 
                    className="text-sm mb-0 bg-success text-white rounded px-4 border mr-2 my-1" 
                    style={styles.banner}
                >
                    <i className="fas fa-envelope " />
                    <UncontrolledTooltip delay={0} placement="bottom" target="archk-matter-has-automation">
                        Has Future Automation(s)
                    </UncontrolledTooltip>
                </p>
            ) : ''}
            {hasAlerts ? (
                <p 
                    id="archk-matter-has-alerts" 
                    className="text-sm mb-0 bg-warning text-white rounded px-4 border mr-2 my-1 cursor-pointer" 
                    style={styles.banner}
                    onClick={() => setShowAlerts(true)}
                >
                    <i className="fas fa-bell " />
                    <UncontrolledTooltip delay={0} placement="bottom" target="archk-matter-has-alerts">
                        Matter Alerts ({hasAlerts}) 
                    </UncontrolledTooltip>
                </p>
            ) : ''}
            {matter.payment_overdue ? (
                <p 
                    id="archk-matter-payment-delinquent" 
                    className="text-sm mb-0 bg-warning text-white rounded px-4 border mr-2 my-1" 
                    style={styles.banner}
                >
                    <i className="fas fa-usd " />
                    <UncontrolledTooltip delay={0} placement="bottom" target="archk-matter-payment-delinquent">
                        Payment Overdue
                    </UncontrolledTooltip>
                </p>
            ) : ''}
            {eventOverdue ? (
                <p  
                    id="archk-matter-event-overdue" 
                    className="text-sm mb-0 bg-warning text-white rounded px-4 border mr-2 my-1" 
                    style={styles.banner}
                >
                    <i className="fas fa-calendar " />
                    <UncontrolledTooltip delay={0} placement="bottom" target="archk-matter-event-overdue">
                        Event Overdue
                    </UncontrolledTooltip>
                </p>
            ) : ''}
            {deadlineOverdue ? (
                <p  
                    id="archk-matter-deadline-overdue"
                    className="text-sm mb-0 bg-warning text-white rounded px-4 border mr-2 my-1" 
                    style={styles.banner}
                >
                    <i className="fas fa-exclamation mr-1 " />
                    <i className="fas fa-calendar " />
                    <UncontrolledTooltip delay={0} placement="bottom" target="archk-matter-deadline-overdue">
                        Deadline Overdue
                    </UncontrolledTooltip>
                </p>
            ) : ''}

            {allTags.map((tag, i) => (
                <p key={tag}  className="text-sm my-1 bg-purple text-white rounded px-4 border mr-2 my-1" style={styles.banner}>
                    <ObjectFinder collection="tags" _id={tag} /> <i onClick={() => onRemoveTag(tag)} className="fas fa-times pl-2 cursor-pointer" />
                </p>
            ))}
    
            {allBanners.map((banner, i) => (
                <p key={i} className="text-sm my-1 bg-purple text-white rounded px-4 border mr-2 my-1" style={styles.banner}>
                    {banner} <i onClick={() => onRemoveBanner(banner)} className="fas fa-times pl-2 cursor-pointer" />
                </p>
            ))}
    
            <OutstandingItems banner={styles.banner} onSetTab={onSetTab} />
           
            
        </div>
    )
}

const styles = {
    banner: {
        borderBottom: 'none', 
        display: 'inline-block',
    }
}


const mapStateToProps = state => {
    return {
        matter: state.matters.selected_matter,
    };
};

export default connect(mapStateToProps, '')(MattersViewMainComponentsBanners);