import { useState } from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';

import InternalSidebar from 'components/markup/layout/InternalSidebar'

import Details from './Details'
import DailyEmails from './DailyEmails'
import Notifications from './Notifications'
import Documents from './Documents'
import Billing from './Billing'
import SuspiciousActivity from './SuspiciousActivity'
import Registration from './Registration'
import Security from './Security'

const SettingsInfo = ({company, setCompany}) => {

    const [tab, setTab] = useState(0);

    return (

        <Card>
            <InternalSidebar 
                tab={tab}
                onClick={(route, tab) => setTab(tab)}
                routes={[
                    {name: 'Details'},
                    {name: 'Notifications'},
                    {name: 'Daily Emails'},
                    {name: 'Documents'},
                    {name: 'Billing'},
                    {name: 'Suspicious Activity'},
                    {name: 'Registration'},
                    {name: 'Security'},
                ]}
            >
                {tab === 0 ? (
                    <Details company={company} setCompany={setCompany} />
                ) : tab === 1 ? (
                    <Notifications company={company} setCompany={setCompany} />
                ) : tab === 2 ? (
                    <DailyEmails company={company} setCompany={setCompany} />
                ) : tab === 3 ? (
                    <Documents company={company} setCompany={setCompany} />
                ) : tab === 4 ? (
                    <Billing company={company} setCompany={setCompany} />
                ) : tab === 5 ? (
                    <SuspiciousActivity company={company} setCompany={setCompany} />
                ) : tab === 6 ? (
                    <Registration company={company} setCompany={setCompany} />
                ) : tab === 7 ? (
                    <Security company={company} setCompany={setCompany} />
                ) : null}
            </InternalSidebar>
        </Card>
    )

}

const mapStateToProps = state => {
    return {
        company: state.company.company,
    };
};

export default connect(mapStateToProps, '')(SettingsInfo);
