import { useCallback, useState } from 'react';

import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, FormGroup, Input, Row } from "reactstrap";

import { toast } from 'react-toastify';
import { formatCurrency } from 'utils/currency';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import DatePicker from 'react-datepicker';
           
import moment from 'moment';

const baseState = {
    billable      : true,
    description   : '',
    minutes       : '',
    amount        : '',
    date          : new Date(),
}

const ModalCustomFields = ({invoice, onAddArrayItem, onRemoveArrayItem}) => {

    const [fee, setFee] = useState(baseState);

    const onAddFee = useCallback(() => {
        if(fee.amount  === '' || fee.amount < 0) return toast.info('Fee amount must be greater or equal to 0.')
        if(!fee.description) return toast.info('Fee description cannot be left blank.')

        onAddArrayItem('fees', { 
            ...fee,
            amount: parseFloat(fee.amount),
            minutes: parseFloat(fee.minutes),
            date: moment(fee.date).format('YYYY-MM-DD')
        })
        setFee({
            ...baseState,
            date: fee.date
        })
    }, [fee, onAddArrayItem])

    const onSetFee = useCallback((field, val) => {
        const _fee = JSON.parse(JSON.stringify(fee))
        _fee[field] = val
        setFee(_fee)
    }, [fee])

    const disabled = fee.amount === '' || !fee.description || !fee.minutes;

    return (

        <div>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Add Fees</CardTitle>
                </CardHeader>
                
                <CardBody>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Item Amount*</label>
                                <Input 
                                    type="number"
                                    value={fee.amount}
                                    onChange={(e) => onSetFee('amount', e.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Item Date</label>
                                <DatePicker 
                                    selected={moment(fee.date).toDate()} 
                                    onChange={(date) => onSetFee('date', date)} 
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={6}>
                            <ReactSelect
                                title={"Billable*"}
                                formGroup={true}
                                placeholder=""
                                onChange={(obj) => onSetFee('billable', obj.value)}
                                options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                ]}
                                value={fee.billable}
                            />    
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <label className='form-control-label'>Minutes*</label>
                                <Input 
                                    type="number"
                                    value={fee.minutes}
                                    onChange={(e) => onSetFee('minutes', e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                  
                    <FormGroup>
                        <label className='form-control-label'>Item Description*</label>
                        <Input 
                            type="text"
                            value={fee.description}
                            onChange={(e) => onSetFee('description', e.target.value)}
                        />
                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <button 
                        className={`btn btn-${disabled ? 'outline-info' : 'success'} btn-sm`}
                        onClick={onAddFee}
                        disabled={disabled}
                    >
                        <i className="fas fa-plus mr-2" /> Add Fee
                    </button>
                    {disabled ? <span>* Above Fields Required</span> : ''}
                </CardFooter>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Current Fee Items</CardTitle>
                </CardHeader>
                
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Billable</th>
                            <th>Minutes</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <td></td>
                        </tr>
                    </thead>

                    <tbody>
                        {invoice.fees.length ? invoice.fees.map((obj, i) => (
                            <tr key={i}>
                                <td>{obj.description}</td>
                                <td>
                                    {obj.billable ? (
                                        <Badge color="success " style={{width: 60}}>Yes</Badge>
                                    ) : (
                                        <Badge color="info " style={{width: 60}}>No</Badge>
                                    )}
                                </td>
                                <td>{obj.minutes}</td>
                                <td>{formatCurrency(obj.amount)}</td>
                                <td>{moment(obj.date).format('MM/DD/YYYY')}</td>
                                <td>
                                    <i 
                                        className="fas fa-trash text-danger cursor-pointer"  
                                        onClick={() => onRemoveArrayItem('fees', i)}
                                    />
                                </td>
                            </tr>
                        )) : (
                            <tr>
                                <td>*No Fees Have Been Added</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </Card>

          
        </div>

    )
}

export default ModalCustomFields