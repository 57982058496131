import { useState, useEffect, useCallback } from 'react'

import { toast } from 'react-toastify';

import api from 'api';

const FinishTaskConfirmation = ({task, onFinish}) => {
    const [confirmed, setConfirmed] = useState(false)

    const name = task.creating_user ? task.name : task.task_template ? task.task_template.name : task.name

    const description = task.task_template ? task.task_template.description : task.description

    const onSave = useCallback(async () => {
        if(!confirmed) return;
        
        const data = await api.tasks.finish(task._id, {
            matter      : task.matter,
            field       : 'confirmation',
            answer      : `Marked the following as confirmed: ${name}`,
        })

        if(!data.data) return toast.error(`Something's not right, please try again.`)
        onFinish(data.data)
    }, [confirmed, name, onFinish, task._id, task.matter])

    useEffect(() => {
        const el = document.getElementById('archk-tasks-finish-button')
        el.addEventListener('click', onSave)

        return () => {
            el.removeEventListener('click', onSave)
        }
    }, [onSave])


    return (
        <>
       
            {description ? (
                <p className='text-sm bg-secondary border py-3 px-3 my-3'>{description}</p>
            ) : ''}

            <div className="custom-control custom-checkbox">
                <input
                    className="custom-control-input"
                    id="confirm-terms-of-service"
                    type="checkbox"
                    onChange={(e) => setConfirmed(!confirmed)}
                    checked={confirmed}
                />
                <label className="custom-control-label" htmlFor="confirm-terms-of-service">
                    {name}
                </label>

            </div>
         

        </>
    )
}

export default FinishTaskConfirmation